import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box } from '@mui/material';
import * as yup from 'yup';
import clsx from 'clsx';

// Components
import { AddButton } from '../../../../shared/ui/AddButton/AddButton';
import { H2 } from '../../../../shared/ui/H2/H2';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { IconTextButton } from '../../../../shared/ui/IconTextButton/IconTextButton';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useContactsHttp } from '../../hooks/use-contacts-http.hook';

import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useNewsCenter } from '../../../newscenter/hooks/use-news-center.hook';
import { useNewsCenterHttp } from '../../../newscenter/hooks/use-news-center-http.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  RoomRequest,
  RoomType,
} from '../../../newscenter/models/news-center.types';
import {
  ActiveState,
  AddContactsFavoriteToolsType,
  ConnectionType,
  Contact,
  ContactAction,
  ContactCommunity,
  ContactsInvitePostRequest,
  ContactsViewType,
} from '../../models/contacts.types';
import {
  AlertAction,
  ImageFallbackType,
  MenuItem,
  NicknameChange,
  NicknameChangeRequest,
  ResultState,
} from '../../../../shared/models/shared.types';

// Stores
import { useContactsStore } from '../../stores/use-contacts.store';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './ContactDetail.module.scss';
import { Input } from '../../../../shared/ui/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { useContacts } from '../../hooks/use-contacts.hook';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { TFunctionResult } from 'i18next';
import { useNewsCenterStore } from '../../../newscenter/stores/use-news-center.store';
import { ToolFavorite } from '../../../dashboard/models/dashboard.types';
import { FavoriteTool } from '../../../dashboard/components/WidgetFavoriteTools/WidgetFavoriteTools';
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { AddContactsFavoriteTools } from '../AddContactsFavoriteTools/AddContactsFavoriteTools';
import { useContactsMock } from '../../hooks/use-contacts-mock.hook';
import { CommunityUserRole } from '../../../communities/models/communities.types';
import { userInfo } from 'os';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

type ContactsStateProps = {
  state: ActiveState;
};

const ContactsState = (props: ContactsStateProps) => {
  const { activeStateColorsGet } = useContacts();
  const { t } = useTranslation();
  const { mdDown } = useBreakpoints();

  const [tooltip, setTooltip] = useState<string | TFunctionResult>('');
  const [icon, setIcon] = useState<[IconPrefix, IconName] | undefined>(
    undefined
  );

  useEffect(() => {
    switch (props.state) {
      case ActiveState.Active:
        setTooltip(t('contacts.state.active'));
        setIcon(['fal', 'handshake']);
        break;
      case ActiveState.Expired:
        setTooltip(t('contacts.state.expired'));
        setIcon(['fal', 'triangle-exclamation']);
        break;
      case ActiveState.Invited:
        setTooltip(t('contacts.state.invited'));
        setIcon(['fal', 'hourglass-clock']);
        break;
      default:
        setIcon(undefined);
    }
  }, [props.state]);
  return (
    <>
      {icon && (
        <Tooltip title={tooltip}>
          <Box
            className={styles['contact-detail-state']}
            sx={activeStateColorsGet(props.state)}
          >
            <Icon
              icon={icon}
              classes={styles['contact-detail-state-icon']}
              size={mdDown ? 'inherit' : undefined}
            />
          </Box>
        </Tooltip>
      )}
    </>
  );
};

interface ContactsActionsProps {
  row: Contact;
  type?: ContactsViewType;
  contacts: Contact[];
  memberRole?: CommunityUserRole;
  setContacts: (contacts: Contact[]) => void;
  onCommunityUserAdd: (id: string) => void;
}

const ContactsActions = (props: ContactsActionsProps) => {
  const { mdDown } = useBreakpoints();
  const { actionsGet, contactAddEffect } = useContacts();
  const { contactsInvitePost, contactDelete, getContactDeleteType } =
    useContactsHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleError, handleRetry } = useFetch();
  const { directRoomPost } = useNewsCenterHttp();
  const { directRoomPostEffect } = useNewsCenter();
  const { fullNameGet } = useShared();
  const [contactDeleteId, setContactDeleteId] = useState<string | undefined>(
    undefined
  );
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);

  // Contacts store state
  const [
    // contactDeleteId,
    // setContactDeleteId,
    // contacts,
    // setContacts,
    setDataLoading,
    setDialogInvite,
    setSelectedContactId,
    setSelectedContactReinvite,
  ] = useContactsStore((state) => [
    // state.contactDeleteId,
    // state.setContactDeleteId,
    // state.contacts,
    // state.setContacts,
    state.setDataLoading,
    state.setDialogInvite,
    state.setSelectedContactId,
    state.setSelectedContactReinvite,
  ]);

  // Shared store state
  const [alertAction, setDialogContacts, setNotification, setAlert] =
    useSharedStore((state) => [
      state.alertAction,
      state.setDialogContacts,
      state.setNotification,
      state.setAlert,
    ]);

  // News center store state
  const [directRooms, setDirectRooms, setDialogDirectRoomCreate] =
    useNewsCenterStore((state) => [
      state.directRooms,
      state.setDirectRooms,
      state.setDialogDirectRoomCreate,
    ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // DELETE Contact mutation
  const contactDeleteMutation = useMutation(
    (body: { id: string }) => contactDelete(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        setSelectedContactId(undefined);
        setDataLoading(false);

        if (data) {
          // remove contact from contact book
          const updatedContacts = [...props.contacts];
          const contact_index = updatedContacts.findIndex(
            (contact) => contact.id === data.deletedUserId
          );
          if (contact_index > -1) {
            updatedContacts.splice(contact_index, 1);
            props.setContacts(updatedContacts);
          }

          // remove direct room from message center
          if (data.deletedRoomId) {
            const updatedDirectRooms = [...directRooms];
            const room_index = updatedDirectRooms.findIndex(
              (room) => room.id === data.deletedRoomId
            );

            if (room_index > -1) {
              updatedDirectRooms.splice(room_index, 1);
              setDirectRooms(updatedDirectRooms);
            }
          }

          const userName =
            data.deletedUserName !== 'Namenlos'
              ? data.deletedUserName
              : t('contacts.delete.this_contact_big');

          setNotification({
            title: `${t('contacts.delete.notification.result.title', {
              userName: userName,
            })}`,
            state: ResultState.Success,
          });
        }
        if (error) {
          console.log('ERROR on contactDeleteMutation', error);
        }
      },
    }
  );
  // GET delete Contact type mutation
  const getContactDeleteTypeMutation = useMutation(
    (body: { id: string }) => getContactDeleteType(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          setDataLoading(false);
          const notification: string[] = [];
          const userName = fullNameGet(
            props.row.first_name,
            props.row.last_name,
            props.row.nickname
          );

          // remove direct room from message center
          if (data.deletedRoomId) {
            notification.push(
              `${t('contacts.delete.notification.room.title1')}${userName}${t(
                'contacts.delete.notification.room.title2'
              )}`
            );
          }

          // show result
          if (
            data.deletedFromCommunities &&
            data.deletedFromCommunities.length > 0
          ) {
            notification.push(
              `${userName}${t(
                'contacts.delete.notification.community.title1'
              )}${data.deletedFromCommunities.toString()}${t(
                'contacts.delete.notification.community.title2'
              )}`
            );
          }

          setContactDeleteId(props.row.id);
          setAlert({
            title: `${t('contacts.delete.alert.text1')} ${userName} ${t(
              'contacts.delete.alert.text2'
            )}`,
            subtitle: (
              <div>
                {notification.map((text, index) => {
                  return (
                    <Box key={index} className={styles['contacts-alert']}>
                      <Icon
                        icon={['fas', 'times']}
                        color="error"
                        classes={styles['contacts-alert-icon']}
                      />
                      {text}
                    </Box>
                  );
                })}
              </div>
            ),
          });
        }
        if (error) {
          console.log('ERROR on contactDeleteMutation', error);
        }
      },
    }
  );

  // POST Contact add mutation
  const addPostMutation = useMutation(
    (data: ContactsInvitePostRequest) => contactsInvitePost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Add contact to store
        if (data) {
          try {
            // contactAddEffect(props.row.email);
            setNotification({
              title: t('contacts.add.success'),
              state: ResultState.Success,
            });
          } catch (error) {
            console.log('ERROR on adding contact:', error);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
            setNotification({
              title: `${t('contacts.add.error')} ${errRes.status}`,
              state: ResultState.Error,
            });
          }
        }
      },
    }
  );

  // POST direct room mutation
  const directRoomPostMutation = useMutation(
    (data: RoomRequest) => directRoomPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Add new direct room to store
        if (data) {
          try {
            if (!data?.existing) {
              directRoomPostEffect(data);
            }
            setDialogDirectRoomCreate(false);
            setDialogContacts(false);
            setDataLoading(false);
            navigate(`/newscenter/private/${data.id}`);
          } catch (error) {
            console.log('ERROR on creating direct room:', error);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
          }
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    setMenuItems(actionsGet(props.row.state, props.type, props.memberRole));
    // eslint-disable-next-line
  }, [props.row.state, props.type]);

  // Abort on alert submit.
  useEffect(() => {
    if (alertAction === AlertAction.Submit) {
      contactDeleteId && onContactDelete(contactDeleteId);
    }
    // eslint-disable-next-line
  }, [alertAction]);

  // remove data Loading component on unmount
  useEffect(() => {
    return setDataLoading(false);
    // eslint-disable-next-line
  }, []);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handle contact action.
   * @param action ContactAction
   */
  const onAction = useCallback(
    (action: ContactAction) => {
      if (action === ContactAction.Add) {
        props.row.email &&
          addPostMutation.mutate({
            contacts: [{ email: props.row.email }],
          });
      }
      if (action === ContactAction.Community) {
        props.row.id && props.onCommunityUserAdd(props.row.id);
      }
      if (action === ContactAction.View) {
        setSelectedContactId(props.row.id);
      }
      if (action === ContactAction.Reinvite) {
        setSelectedContactReinvite(props.row);
        setDialogInvite(true);
      }
      // if (action === ContactAction.Message) {
      //   onDirectChatUserSubmit(props.row.id);
      //   setDataLoading(true);
      // }
      if (action === ContactAction.Delete) {
        setDataLoading(true);
        getContactDeleteTypeMutation.mutate({ id: props.row.id });
      }

      // eslint-disable-next-line
    },
    [props.row]
  );

  /**
   * Handler to delete contact
   * @param User id
   */
  const onContactDelete = useCallback((id: string) => {
    setDataLoading(true);
    contactDeleteMutation.mutate({ id: id });
    // eslint-disable-next-line
  }, []);

  /**
   * Handler to submit user id and create direct chat.
   * @param User id
   */
  const onDirectChatUserSubmit = useCallback((id: string) => {
    directRoomPostMutation.mutate({
      type: RoomType.Direct,
      participant: [id],
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="flex w-full mt-2"
      // style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
    >
      {menuItems.map((menuItem, index) => {
        return (
          <>
            {menuItem.icon && menuItem.action !== ContactAction.Message && (
              <Box
                className={styles['contact-detail-action']}
                onClick={() => onAction(menuItem.action)}
              >
                <Tooltip title={menuItem.title} key={index}>
                  <IconButton
                    classes={styles['contact-detail-action-icon']}
                    disabled={menuItem.disabled ?? false}
                    icon={menuItem.icon}
                    iconSize={mdDown ? 'inherit' : undefined}
                    sxIcon={{ color: 'text.secondary' }}
                  />
                </Tooltip>
              </Box>
            )}
            {/* #TODO Delete: disabled button by ContactAction.Message after they have functions */}
            {menuItem.icon && menuItem.action == ContactAction.Message && (
              <Box
                className={styles['contact-detail-action']}
                onClick={() => onAction(menuItem.action)}
              >
                <IconButton
                  classes={styles['contact-detail-action-icon']}
                  icon={menuItem.icon}
                  disabled
                  iconSize={mdDown ? 'inherit' : undefined}
                  sxIcon={{ color: 'text.secondary' }}
                />
              </Box>
            )}
          </>
        );
      })}
    </div>
  );
};

type ContactMessageButtonProps = {
  id: string;
};

const ContactMessageButton = (props: ContactMessageButtonProps) => {
  const { handleError, handleRetry } = useFetch();
  const navigate = useNavigate();
  const { directRoomPostEffect } = useNewsCenter();
  const { directRoomPost } = useNewsCenterHttp();
  const { t } = useTranslation();

  // Shared store state
  const [setDialogContacts] = useSharedStore((state) => [
    state.setDialogContacts,
  ]);

  // POST direct room mutation
  const directRoomPostMutation = useMutation((data: RoomRequest) =>
    directRoomPost(data)
  );

  // Add new direct room to store
  useEffect(() => {
    if (directRoomPostMutation.data) {
      try {
        directRoomPostEffect(directRoomPostMutation.data);
        setDialogContacts(false);
        navigate(`/newscenter/private/${directRoomPostMutation.data.id}`);
      } catch (error) {
        console.log('ERROR on creating direct room:', error);
      }
    }
    if (directRoomPostMutation.error) {
      // const errRes = directRoomPostMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
    }
    // eslint-disable-next-line
  }, [directRoomPostMutation.data, directRoomPostMutation.error]);

  /**
   * Handle message contact.
   */
  const onMessageContact = useCallback(() => {
    directRoomPostMutation.mutate({
      type: RoomType.Direct,
      participant: [props.id],
    });
    // eslint-disable-next-line
  }, [props]);

  return (
    <IconTextButton
      classes={styles['contact-detail-info-avatar-button']}
      icon={['fad', 'messages']}
      padding="0.25rem 0.5rem"
      preset="secondary"
      onClick={onMessageContact}
    >
      {t('contacts.actions.message')}
    </IconTextButton>
  );
};

// type ContactDetailToolItemProps = {
//   classes?: string;
//   tool: ContactDetailTool;
// };

// const ContactDetailToolItem = (props: ContactDetailToolItemProps) => {
//   return (
//     <Box
//       className={clsx(
//         styles['contact-detail-tool-item'],
//         props.classes && props.classes
//       )}
//       sx={{ backgroundColor: 'bg.card', borderColor: 'border.app' }}
//     >
//       <img
//         alt={props.tool.name}
//         className={styles['contact-detail-tool-item-image']}
//         src={props.tool.image_preview?.url}
//       />
//       {props.tool.category && (
//         <Box
//           className={styles['contact-detail-tool-item-category']}
//           sx={{ color: 'text.secondary' }}
//         >
//           {props.tool.category}
//         </Box>
//       )}
//       <div className={styles['contact-detail-tool-item-name']}>
//         {props.tool.name}
//       </div>
//     </Box>
//   );
// };

type ContactDetailProps = {
  contactCommunity: ContactCommunity;
  selectedContactId: string;
  contacts: Contact[];
  heightClass: string;
  memberRole?: CommunityUserRole;
  addTool?: boolean;
  setContacts: (contacts: Contact[]) => void;
  onClose: () => void;
};

export const ContactDetail = memo((props: ContactDetailProps) => {
  const { lgDown } = useBreakpoints();
  const { contactGet, nicknameChangePatch } = useContactsHttp();
  const { handleError, handleRetry } = useFetch();
  const { fullNameGet } = useShared();
  const { t } = useTranslation();
  const { contactsToolsGet } = useContactsMock();
  const [account] = useUserStore((state: UserState) => [state.account]);

  // Component state
  const [contact, setContact] = useState<Contact | undefined>(undefined);
  const [nicknameChange, setNicknameChange] = useState<boolean>(false);
  const [nickname, setNickname] = useState<string | undefined>(undefined);
  const [tools, setTools] = useState<ToolFavorite[]>([]);
  const [dialogAddTools, setDialogAddTools] = useState<boolean>(false);

  // ##### //
  // FORMS //
  // ##### //

  // React hook form validation schema
  const inviteValidation = yup.object({
    nickname: yup.string(),
  });

  const { handleSubmit, register } = useForm<NicknameChange>({
    mode: 'onSubmit',
    resolver: yupResolver(inviteValidation),
  });

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET contact mutation
  const contactGetMutation = useMutation(
    (data: { id: string; community_id: string }) => contactGet(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          // setContact(contactMock1Get()); // #TODO delete
          setContact(data);
          setNickname(data.nickname);
          data.tools && setTools(data.tools);
          // setTools(contactsToolsGet()); // #TODO delete
        }
        if (error) {
          const errRes = error?.response;
          // Check if error contains fetch response object
          if (errRes) {
            // Set view error by response status
            // setViewError(handleError(errRes.status));
            console.error('Error getting contact:', handleError(errRes.status));
          }
        }
      },
    }
  );

  const nicknameChangeMutation = useMutation(
    (data: NicknameChangeRequest) => nicknameChangePatch(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          setNickname(data.nickname);
          setNicknameChange(false);
        }
        if (error && error.response) {
          console.error(
            'Error changing nickname:',
            handleError(error.response.status)
          );
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Set contact by id on mount
  useEffect(() => {
    contactGetMutation.mutate({
      id: props.selectedContactId,
      community_id: props.contactCommunity.id,
    });
    // eslint-disable-next-line
  }, [props.selectedContactId]);

  // ######### //
  // CALLBACKS //
  // ######### //

  const onNicknameChange = useCallback(
    (formValues: NicknameChange) => {
      if (contact?.id) {
        nicknameChangeMutation.mutate({
          id: contact.id,
          data: formValues,
        });
      }
    },
    // eslint-disable-next-line
    [contact]
  );

  return (
    <Box
      className={clsx(styles['contact-detail'], props.heightClass)}
      sx={{ backgroundColor: 'bg.card' }}
    >
      {contact && (
        <div className={styles['contact-detail-container']}>
          <div className={styles['contact-detail-info']}>
            <div className={styles['contact-detail-info-avatar']}>
              {contact?.avatar?.url ? (
                <img
                  alt="User Avatar"
                  className={styles['contact-detail-info-avatar-image']}
                  src={contact.avatar.url}
                />
              ) : (
                <ImageFallback
                  iconSize="3em"
                  sizeClassName={styles['contact-detail-info-avatar-image']}
                  type={ImageFallbackType.Profile}
                />
              )}
              {/* {contact && !lgDown && <ContactMessageButton id={contact?.id} />} */}
            </div>
            <div className={styles['contact-detail-info-data']}>
              <Box
                className={clsx(
                  styles['contact-detail-info-data-name'],

                  nicknameChange ? 'flex-col' : 'flex-row'
                )}
              >
                <div className="flex flex-row w-full items-center">
                  <H2
                    classes={styles['contact-detail-info-data-name-full']}
                    marginTop="3px"
                    marginBottom="0px"
                    marginRight="4px"
                  >
                    {fullNameGet(contact?.first_name, contact?.last_name)}{' '}
                    {contact?.connection === ConnectionType.Direct &&
                      !nicknameChange &&
                      nickname &&
                      nickname.length > 0 && <span> {`(${nickname})`}</span>}
                  </H2>
                  {contact?.connection === ConnectionType.Direct &&
                    contact.invitor &&
                    !nicknameChange && (
                      <Tooltip
                        title={t('contacts.detail.tooltip.edit_nickname')}
                      >
                        <IconButton
                          classes="ml-2"
                          sxIcon={{ color: 'text.secondary' }}
                          icon={['fas', 'pen']}
                          onClick={() => setNicknameChange(!nicknameChange)}
                        />
                      </Tooltip>
                    )}
                  {contact.state && <ContactsState state={contact.state} />}

                  {!lgDown && (
                    <IconButton
                      classes={styles['contact-detail-close-hidden']}
                      icon={['fas', 'times']}
                      onClick={props.onClose}
                    />
                  )}
                </div>
                {nicknameChange && (
                  <form onSubmit={handleSubmit(onNicknameChange)}>
                    <Box className={styles['contact-detail-info-data-input']}>
                      <Input
                        padding="8px"
                        inputClassName={
                          styles['contact-detail-info-data-nickname']
                        }
                        placeholder={contact?.nickname}
                        register={register('nickname')}
                      />
                      <Box
                        className={
                          styles['contact-detail-info-data-nickname-icon']
                        }
                      >
                        <IconButton
                          classes={
                            styles[
                              'contact-detail-info-data-nickname-icon-button'
                            ]
                          }
                          sxButton={{
                            bgcolor: 'text.secondary',
                            '& svg': { color: 'white' },
                            '&:hover': { bgcolor: 'text.primary' },
                          }}
                          iconSize="inherit"
                          icon={['fas', 'check']}
                          type="submit"
                        />
                        <IconButton
                          classes={
                            styles[
                              'contact-detail-info-data-nickname-icon-button'
                            ]
                          }
                          sxButton={{
                            bgcolor: 'text.secondary',
                            '& svg': { color: 'white' },
                            '&:hover': { bgcolor: 'text.primary' },
                          }}
                          iconSize="inherit"
                          icon={['fas', 'times']}
                          onClick={() => setNicknameChange(false)}
                        />
                      </Box>
                    </Box>
                  </form>
                )}
              </Box>
              <div
                className={styles['contact-detail-info-data-contact-details']}
              >
                <div>{contact?.email && contact?.email}</div>
                {contact?.phone && <div>{contact.phone}</div>}
              </div>
              {contact?.address?.house_number &&
                contact?.address?.place &&
                contact?.address?.street &&
                contact?.address?.zip_code && (
                  <div
                    className={
                      styles['contact-detail-info-data-contact-address']
                    }
                  >
                    <div>{t('user.profile.form.address')}:</div>
                    <div>{`${contact.address.street} ${contact.address.house_number}`}</div>
                    <div>{`${contact.address.zip_code} ${contact.address.place}`}</div>
                  </div>
                )}
              {/* {contact && lgDown && <ContactMessageButton id={contact.id} />} */}
              {contact && props.addTool && (
                <ContactsActions
                  row={contact}
                  type={ContactsViewType.Direct}
                  contacts={props.contacts}
                  memberRole={props.memberRole}
                  setContacts={props.setContacts}
                  onCommunityUserAdd={(id: string) => {}}
                />
              )}
            </div>
          </div>
          {props.addTool ? (
            <>
              <div className={styles['contact-detail-tools-container']}>
                <Box
                  className={styles['contact-detail-tools-content']}
                  sx={{ backgroundColor: 'background.paper' }}
                >
                  <Box className={styles['contact-detail-favorite-tools']}>
                    <AddButton
                      heightClassName={
                        styles['contact-detail-tools-content-add-height']
                      }
                      widthClassName={
                        styles['contact-detail-tools-content-add-width']
                      }
                      onClick={() => setDialogAddTools(true)}
                    >
                      {t('contacts.detail.tools.add')}
                    </AddButton>
                  </Box>
                  {tools.map((tool, index) => (
                    <FavoriteTool
                      key={index}
                      tool={tool}
                      heightClasses="h-full"
                      classes={styles['contact-detail-favorite-tools']}
                    />
                  ))}
                </Box>
                {!lgDown && (
                  <IconButton
                    classes={styles['contact-detail-close']}
                    icon={['fas', 'times']}
                    onClick={props.onClose}
                  />
                )}
              </div>
              <Dialog
                widthClassName={styles['contacts-dialog-invite']}
                open={dialogAddTools}
                onClose={() => setDialogAddTools(false)}
                title={t('contacts.toolbar.community.add_tool')}
              >
                <AddContactsFavoriteTools
                  type={AddContactsFavoriteToolsType.User}
                  communityId={props.contactCommunity.id}
                  userId={props.selectedContactId}
                  tools={tools}
                  setTools={setTools}
                  onClose={() => setDialogAddTools(false)}
                />
              </Dialog>
            </>
          ) : (
            <>
              <div className={styles['contact-detail-tools-container']}>
                <Box
                  className={styles['contact-detail-tools-content']}
                  sx={{ backgroundColor: 'background.paper' }}
                >
                  {tools.map((tool, index) => (
                    <FavoriteTool
                      key={index}
                      tool={tool}
                      heightClasses="h-full"
                      classes={styles['contact-detail-favorite-tools']}
                    />
                  ))}
                </Box>
                {!lgDown && (
                  <IconButton
                    classes={styles['contact-detail-close']}
                    icon={['fas', 'times']}
                    onClick={props.onClose}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </Box>
  );
});
