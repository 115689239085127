import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import {
  PasswordChange as IPasswordChange,
  ResultState,
  UpdateUserInfoType,
} from '../../shared/models/shared.types';
import { useUsersHttp } from '../../modules/user/hooks/use-users-http.hook';
import { useMutation } from 'react-query';
import { UserInfoData } from '../../modules/user/models/user.types';
import { TFunctionResult } from 'i18next';
import { Notification } from '../../shared/ui/Notification/Notification';
import Typography from '@mui/material/Typography';
import { InputFlat } from '../../shared/ui/InputFlat/InputFlat';
import { Switch } from '../../shared/ui/Switch/Switch';
import { Dialog } from '../../shared/ui/Dialog/Dialog';
import { ResultBox } from '../../shared/ui/ResultBox/ResultBox';
import { PasswordChange } from '../../shared/components/PasswordChange/PasswordChange';
import * as yup from 'yup';

type SecuritySettingsProps = {
  updateUserInfo: (
    type: UpdateUserInfoType,
    value: string | boolean,
    mutate?: boolean
  ) => void;
  userInfoData: UserInfoData | undefined;
};

const SecuritySettings = ({
  updateUserInfo,
  userInfoData,
}: SecuritySettingsProps) => {
  const { t } = useTranslation();
  const { userPasswordUpdate } = useUsersHttp();

  const [passwordDialog, setPasswordDialog] = React.useState(false);
  const [viewError, setViewError] = React.useState<string | null>(null);
  const [notification, setNotification] = React.useState<
    string | TFunctionResult | undefined
  >(undefined);

  // PATCH Password update mutation
  const passwordUpdateMutation = useMutation(
    (passwordUpdateData: IPasswordChange) =>
      userPasswordUpdate(passwordUpdateData),
    {
      onSettled(data, error) {
        if (data) {
          // Reset component state on success. Show success notification
          setPasswordDialog(false);
          setNotification(t('user.security.change.success'));
          setViewError(null);
        }
        if (error) {
          // const errRes = passwordUpdateMutation.error.response;
          // if (errRes) {
          //   setViewError(userPasswordUpdateHandleError(errRes.status));
          // }
        }
      },
    }
  );

  return (
    <Grid container spacing={2} className="pl-4 pt-6">
      <Grid xs={12} className="flex flex-col py-2">
        <Typography className="font-semibold text-lg">
          Sicherheitseinstellungen
        </Typography>
        <Typography className="text-sm">
          Oft unbequemer, aber sicherer.
        </Typography>
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-5">
        <InputFlat
          label="Passwort"
          option="ändern"
          type="password"
          onOptionClick={() => setPasswordDialog(true)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-1">
        <InputFlat
          label="Sicherheitssatz"
          value={userInfoData?.security_word}
          onChange={(value) => updateUserInfo('security_word', value)}
          onBlur={() =>
            updateUserInfo(
              'security_word',
              userInfoData?.security_word || '',
              true
            )
          }
        />
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-5">
        <Switch
          disabled
          swichFirst
          label={{
            label: '2-Faktor SMS',
            optional: false,
          }}
          checked={userInfoData?.sms_factor ?? false}
          onChange={(checked) => updateUserInfo('sms_factor', checked, true)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-1"></Grid>
      <Dialog
        open={passwordDialog}
        onClose={() => setPasswordDialog(false)}
        title={t('user.security.change.title')}
      >
        {viewError && (
          <ResultBox
            classes="mb-4 w-full"
            // size="text-xl"
            state={ResultState.Error}
          >
            {viewError}
          </ResultBox>
        )}
        <PasswordChange
          btnClasses="flex justify-end"
          extraFields={[
            {
              name: 'password',
              placeholder: t('user.security.change.passwordCurrent'),
              type: 'password',
              validation: yup
                .string()
                .required(t('auth.validation.passwordReq')),
            },
          ]}
          loading={passwordUpdateMutation.isLoading}
          submit={t('user.security.change.title')}
          onSubmit={(formValues: IPasswordChange) =>
            passwordUpdateMutation.mutate(formValues)
          }
        />
      </Dialog>
      {notification && (
        <Notification
          message={notification}
          open={notification ? true : false}
          onClose={() => setNotification(undefined)}
        />
      )}
    </Grid>
  );
};

export default SecuritySettings;
