import { useTranslation } from 'react-i18next';

// Models
import {
  AccordionExpandedRequest,
  AccordionExpansionState,
  ActiveState,
  CommunityFolder,
  ConnectionType,
  Contact,
  ContactAction,
  ContactNavGroup,
  ContactsViewType,
  GetContactGroupsFoldersResponse,
} from '../models/contacts.types';
import { MenuItem, Option } from '../../../shared/models/shared.types';

// Stores
import { useShared } from '../../../shared/hooks/use-shared.hook';
import { ContactsState, useContactsStore } from '../stores/use-contacts.store';
import { useMutation } from 'react-query';
import { useNewsCenterHttp } from '../../newscenter/hooks/use-news-center-http.hook';
import { useFetch } from '../../../shared/hooks/use-fetch.hook';
import { NewsCenterResponse } from '../../newscenter/models/news-center.types';
import { unstable_batchedUpdates } from 'react-dom';
import { useNewsCenterStore } from '../../newscenter/stores/use-news-center.store';
import { CommunityUserRole } from '../../communities/models/communities.types';
import { useContactsHttp } from './use-contacts-http.hook';
import { Dispatch, SetStateAction } from 'react';
import { FavoriteLinkType } from '../../dashboard/models/dashboard.types';
import { UserState, useUserStore } from '../../user/stores/use-user.store';

export const useContacts = () => {
  const { t } = useTranslation();
  const { newsCenterGet } = useNewsCenterHttp();
  const {
    contactsNavGet,
    updateNavigationNetwork,
    updateNavigationNewscenter,
  } = useContactsHttp();
  const { handleRetry, handleError } = useFetch();
  const { fullNameGet } = useShared();
  const [account] = useUserStore((state: UserState) => [state.account]);

  // NewsCenter store state
  const [setGroupRooms, setDirectRooms] = useNewsCenterStore((state) => [
    state.setGroupRooms,
    state.setDirectRooms,
  ]);

  // Contacts Store State
  const [
    selectedUsers,
    selectedNavGroups,
    navCommunities,
    groupChats,
    setSelectedUsers,
    setSelectedNavGroups,
    setContacts,
    setNavCommunities,
    setGroupChats,
  ] = useContactsStore((state: ContactsState) => [
    state.selectedUsers,
    state.selectedNavGroups,
    state.navCommunities,
    state.groupChats,
    state.setSelectedUsers,
    state.setSelectedNavGroups,
    state.setContacts,
    state.setNavCommunities,
    state.setGroupChats,
  ]);

  // GET user data mutation
  const newsCenterGetMutation = useMutation(() => newsCenterGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSuccess: (data: NewsCenterResponse | undefined) => {
      if (data) {
        try {
          // Set news center data
          unstable_batchedUpdates(() => {
            data.groupChats && setGroupRooms(data.groupChats);
            data.privateChats && setDirectRooms(data.privateChats);
          });
        } catch (e) {}
      }
    },
    onError: (error) => {
      const errRes = error?.response;
      if (errRes) {
        handleError(errRes.status);
      }
    },
  });

  // GET Contacts nav mutation
  const contactsNavGetMutation = useMutation(() => contactsNavGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSuccess: (data) => {
      if (data) {
        try {
          if (data.groupChats && data.groupChats !== groupChats) {
            setGroupChats(data.groupChats);
            localStorage.setItem(
              `__store_nav_groups_${account?.id}`,
              JSON.stringify(data.groupChats)
            );
          }

          if (data.communities && data.communities !== navCommunities) {
            setNavCommunities(data.communities);
            localStorage.setItem(
              `__store_nav_community_${account?.id}`,
              JSON.stringify(data.communities)
            );
          }
        } catch (error) {
          console.log('ERROR on creating direct room:', error);
        }
      }
    },
    onError: (error) => {
      if (error) {
        const errRes = error?.response;
        if (errRes) {
          handleError(errRes.status);
        }
      }
    },
  });

  const updateNavigationNetworkMutation = useMutation(
    (data: AccordionExpandedRequest) => updateNavigationNetwork(data)
  );

  const updateNavigationNewscenterMutation = useMutation(
    (data: AccordionExpandedRequest) => updateNavigationNewscenter(data)
  );

  const handleAccordionExpand = (
    community_id: string,
    expanded: AccordionExpansionState,
    setExpanded: Dispatch<SetStateAction<AccordionExpansionState>>,
    type: FavoriteLinkType,
    lgDown: boolean,
    useMutation?: boolean
  ) => {
    const updatedExpended = { ...expanded };
    if (updatedExpended[community_id] === false) {
      updatedExpended[community_id] = true;
    } else {
      updatedExpended[community_id] = false;
    }
    setExpanded(updatedExpended);

    const request: AccordionExpandedRequest = {
      id: community_id,
      is_expanded: updatedExpended[community_id],
      is_mobile: lgDown,
    };
    if (useMutation) {
      switch (type) {
        case FavoriteLinkType.Contacts:
          updateNavigationNetworkMutation.mutate(request);
          break;
        case FavoriteLinkType.Newscenter:
          updateNavigationNewscenterMutation.mutate(request);
          break;
      }
    }
  };

  /**
   * GET menu actions.
   * @returns MenuItem array
   */
  const actionsGet = (
    state?: ActiveState,
    type?: ContactsViewType,
    memberRoleCommunity?: CommunityUserRole
  ): MenuItem[] => {
    let actions: MenuItem[] = [];

    if (type === ContactsViewType.Community) {
      actions.push({
        action: ContactAction.Community,
        title: t('community.users.add.title_button'),
      });
    } else {
      if (state === ActiveState.Indirect) {
        actions.push({
          action: ContactAction.Add,
          icon: ['fal', 'user-plus'],
          title: t('contacts.actions.add'),
        });
      } else {
        if (state === ActiveState.Expired || state === ActiveState.Invited) {
          actions.push({
            action: ContactAction.Reinvite,
            icon: ['fal', 'arrows-repeat'],
            title: t('contacts.actions.reinvite'),
          });
        }
        actions.push(
          // {
          //   action: ContactAction.View,
          //   title: t('contacts.actions.view'),
          // },
          {
            action: ContactAction.Message,
            icon: ['fal', 'message-lines'],
            title: t('contacts.actions.message'),
          }
        );
      }
      let disable_delete = true;
      if (
        memberRoleCommunity === CommunityUserRole.Admin ||
        memberRoleCommunity === CommunityUserRole.Employee
      ) {
        disable_delete = false;
      }

      if (type === ContactsViewType.Direct) {
        actions.push({
          action: ContactAction.Upgrade,
          disabled: disable_delete,
          icon: ['fal', 'arrow-up-big-small'],
          title: t('contacts.detail.tooltip.upgrade'),
        });
        actions.push({
          action: ContactAction.Downgrade,
          disabled: disable_delete,
          icon: ['fal', 'arrow-down-big-small'],
          title: t('contacts.detail.tooltip.downgrade'),
        });
      }

      actions.push({
        action: ContactAction.Delete,
        disabled: disable_delete,
        icon: ['fas', 'trash'],
        title: t('app.actions.delete'),
      });
    }

    return actions;
  };

  /**
   * GET Colors by active state.
   * @param activeState ActiveState
   * @returns Colors
   */
  const activeStateColorsGet = (
    activeState: ActiveState
  ): { backgroundColor: string; color: string } => {
    switch (activeState) {
      case ActiveState.Active:
        return { backgroundColor: 'success.light', color: 'success.main' };
      case ActiveState.Declined:
      case ActiveState.Expired:
        return { backgroundColor: 'error.light', color: 'error.main' };
      case ActiveState.Indirect:
        return { backgroundColor: 'bg.card', color: 'text.primary' };
      default:
        return { backgroundColor: 'warning.light', color: 'warning.main' };
    }
  };

  /**
   * Update contact state on successful add.
   * @param email E-Mail
   */
  const contactAddEffect = (email: string, contacts: Contact[]) => {
    const updatedContacts = [...contacts];
    const matchedContact = updatedContacts.find(
      (contact) => contact.email === email
    );
    if (matchedContact) {
      matchedContact.state = ActiveState.Invited;
      matchedContact.connection = ConnectionType.Direct;
      // setContacts(updatedContacts);
    }
    return updatedContacts;
  };

  /**
   * Add invited contacts to list.
   * @param invitedContacts Invited contacts
   */
  const contactsInvitePostEffect = (
    invitedContacts: Contact[],
    contacts: Contact[]
  ) => {
    const prevContacts = [...contacts];
    const newContacts: Contact[] = [];

    for (const invitedContact of invitedContacts) {
      const index = prevContacts.findIndex(
        (prevContact) => prevContact.id === invitedContact.id
      );
      if (index > -1) {
        prevContacts.splice(index, 1);
      }

      const full_name_nick_name = fullNameGet(
        invitedContact.first_name,
        invitedContact.last_name,
        invitedContact.nickname
      );

      newContacts.push({
        name: full_name_nick_name,
        avatar: invitedContact.avatar,
        connection: invitedContact.connection,
        email: invitedContact.email,
        first_name: invitedContact.first_name,
        id: invitedContact.id,
        last_name: invitedContact.last_name,
        nickname: invitedContact.nickname,
        state: invitedContact.state,
        role: invitedContact.role,
      });
    }

    const updatedContacts = [...newContacts]
      .concat([...prevContacts])
      .sort((a, b) => {
        return a.name!.toString().toLowerCase() >
          b.name!.toString().toLowerCase()
          ? 1
          : -1;
      });

    setContacts(updatedContacts);
    newsCenterGetMutation.mutate();
    return updatedContacts;
  };

  const getContactGroupsFoldersEffect = (
    data: GetContactGroupsFoldersResponse
  ) => {
    const options: Option[] = [];

    if (data.groups) {
      const contactGroups = data.groups;

      for (const group of contactGroups) {
        // Change group name for translation
        switch (group.name) {
          case CommunityUserRole.Admin:
            group.name = t('app.admin');
            break;
          case CommunityUserRole.Employee:
            group.name = t('app.employee');
            break;
          case CommunityUserRole.Member:
            group.name = t('app.member');
            break;
        }
        options.push({
          placeholder: group.name,
          value: group.id,
        });
      }
    }

    if (data.folders) {
      const contactFolders = data.folders;
      for (const folder of contactFolders) {
        options.push({
          listHeader: true,
          placeholder: folder.name,
          value: folder.id,
        });

        for (const folder_group of folder.groups) {
          options.push({
            placeholder: folder_group.name,
            value: folder_group.id,
          });
        }
      }
    }
    return options;
  };

  const getContactFoldersEffect = (folders: CommunityFolder[]) => {
    const options: Option[] = [];

    for (const folder of folders) {
      options.push({
        placeholder: folder.name,
        value: folder.id,
      });
    }

    return options;
  };

  const sortContacts = (contacts: Contact[]) => {
    const row = [...contacts];
    // Display nickname if invited by current user, otherwise set by available data
    const newRow: Contact[] = row
      .map((contacts) => {
        const full_name_nick_name = fullNameGet(
          contacts.first_name,
          contacts.last_name,
          contacts.nickname
        );

        return {
          name: full_name_nick_name,
          avatar: contacts.avatar,
          connection: contacts.connection,
          email: contacts.email,
          first_name: contacts.first_name,
          id: contacts.id,
          last_name: contacts.last_name,
          nickname: contacts.nickname,
          state: contacts.state,
          tool_informations: contacts.tool_informations,
        };
      })
      .sort((a, b) => {
        return a.name!.toString().toLowerCase() >
          b.name!.toString().toLowerCase()
          ? 1
          : -1;
      });

    return newRow;
  };

  /*
   * Update NewsCenter Drawer
   */
  const roomDeleteEffect = () => {
    newsCenterGetMutation.mutate();
  };

  /*
   * Update NewsCenter Drawer
   */
  const newContactsNavGet = () => {
    try {
      const navComunities = localStorage.getItem(
        `__store_nav_community_${account?.id}`
      );
      if (navComunities) {
        setNavCommunities(JSON.parse(navComunities));
        setTimeout(() => contactsNavGetMutation.mutate(), 300000);
      } else {
        contactsNavGetMutation.mutate();
      }
      const navGroups = localStorage.getItem(
        `__store_nav_groups_${account?.id}`
      );

      if (navGroups) {
        setGroupChats(JSON.parse(navGroups));
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Handler to delete users selection state.
   * @param selection Selected user ids
   */
  const onToolUsersSelectionDelete = (userId: string) => {
    const updatedToolUsers = [...selectedUsers];
    const index = updatedToolUsers.findIndex((user) => user === userId);
    index > -1 && updatedToolUsers.splice(index, 1);
    setSelectedUsers(updatedToolUsers);
  };

  /**
   * Handler to delete groups selection state.
   * @param group Selected group name
   */
  const onGroupSelectionDelete = (
    navGroup: ContactNavGroup,
    setDeletedNavItem: (
      value: React.SetStateAction<ContactNavGroup | undefined>
    ) => void
  ) => {
    setDeletedNavItem(navGroup);
    const updatedgroups = [...selectedNavGroups];
    const index = updatedgroups.findIndex((group) => group === navGroup);
    index > -1 && updatedgroups.splice(index, 1);
    setSelectedNavGroups(updatedgroups);
  };

  return {
    actionsGet,
    activeStateColorsGet,
    contactAddEffect,
    contactsInvitePostEffect,
    getContactGroupsFoldersEffect,
    getContactFoldersEffect,
    roomDeleteEffect,
    sortContacts,
    newContactsNavGet,
    onToolUsersSelectionDelete,
    onGroupSelectionDelete,
    handleAccordionExpand,
  };
};
