import { TFunctionResult } from 'i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FileWithPath } from 'react-dropzone';

// Models
import {
  Attachment,
  CreateChangeInfo,
  Option,
  ResultState,
} from '../../../shared/models/shared.types';
import { CommunityData } from '../../communities/models/communities.types';
import {
  MultiElementDisplayType,
  ToolActivePermissionSlotInformation,
} from '../../tools/models/tools.types';
import { Group } from '../../dashboard/models/dashboard.types';
import { User } from '../../user/models/user.types';

export enum TemplateCreateEditDetails {
  SectionCreate = 'SECTION_CREATE',
  SectionUpdate = 'SECTION_UPDATE',
  ElementCreate = 'ELEMENT_CREATE',
  ElementUpdate = 'ELEMENT_UPDATE',
  ElementChildCreate = 'ELEMENT_CHILD_CREATE',
  ElementChildUpdate = 'ELEMENT_CHILD_UPDATE',
}

export enum TemplateAction {
  Delete = 'DELETE',
  Finish = 'FINISH',
  Settings = 'SETTINGS',
  Publish = 'PUBLISH',
  No_Publish = 'NO_PUBLISH',
  Print = 'PRINT',
  Update = 'UPDATE',
  Copy = 'COPY',
}

export enum TemplateElementContactType {
  Basic = 'BASIC',
  Card = 'CARD',
  Creator = 'CREATOR',
  CreatorRead = 'CREATOR_READ',
  MultiTable = 'MULTI_TABLE',
}

export enum TemplateElementCreateEditState {
  Content = 'CONTENT',
  Headline = 'HEADLINE',
  InputSetting = 'INPUT_SETTING',
  Image = 'IMAGE',
  Name = 'NAME',
  Notification = 'NOTIFICATION',
  Options = 'OPTIONS',
  Vote = 'VOTE',
  Settings = 'SETTINGS',
  Summary = 'SUMMARY',
  Type = 'TYPE',
  Upload = 'UPLOAD',
}

export enum TemplateElementDatePickerType {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
}

export enum TemplateElementType {
  AutoId = 'AUTOID',
  Checkbox = 'CHECKBOX',
  Contact = 'CONTACT',
  DataElementFromOtherTool = 'DATAELEMENTFROMOTHERTOOL',
  DataFromOtherTool = 'DATAFROMOTHERTOOL',
  DataFromOtherToolContact = 'DATAFROMOTHERTOOL_CONTACT',
  DataFromOtherToolGroup = 'DATAFROMOTHERTOOL_GROUP',
  DatePicker = 'DATEPICKER',
  Day = 'DAY',
  Decision = 'DECISION',
  Duration = 'DURATION',
  EMail = 'EMAIL',
  Grade = 'GRADE',
  Group = 'GROUP',
  Headline = 'HEADLINE',
  Image = 'IMAGE',
  Info = 'INFO',
  Input = 'INPUT',
  Map = 'MAP',
  MultiElement = 'MULTIELEMENT',
  MultiElementCreateRow = 'MULTIELEMENTCREATEROW',
  Number = 'NUMBER',
  PersonFieldWithDataFromOtherTool = 'PERSONFIELDWITHDATAFROMOTHERTOOL',
  RadioButton = 'RADIOBUTTON',
  Reminder = 'REMINDER',
  Select = 'SELECT',
  Star = 'STAR',
  Telephone = 'TELEPHONE',
  Textarea = 'TEXTAREA',
  Time = 'TIME',
  Toggle = 'TOGGLE',
  Upload = 'UPLOAD',
  Url = 'URL',
  User = 'USER',
}

export enum TemplateMultiElementRowType {
  FirstRow = 'FIRSTROW',
  NormalRow = 'NORMALROW',
}

export enum TemplatePurpose {
  Community = 'COMMUNITY',
  Living = 'LIVING',
}

export enum TemplateSectionPositionPatch {
  Down = 'DOWN',
  Up = 'UP',
}

export enum TemplateSettingsState {
  Description = 'DESCRIPTION',
  // Extra = 'EXTRA',
  Name = 'NAME',
  Icon = 'ICON',
  // Purpose = 'PURPOSE',
  Summary = 'SUMMARY',
  Notification = 'NOTIFICATION',
  Interface = 'INTERFACE',
  HeaderImage = 'HEADERIMAGE',
}

export enum TemplateStatus {
  Blueprint = 'BLUEPRINT',
  Community = 'COMMUNITY',
  Published = 'PUBLISHED',
}

export enum TemplateType {
  Community = 'COMMUNITY',
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export enum TemplateUseCase {
  Documentation = 'DOCUMENTATION',
  Other = 'OTHER',
  Project_Management = 'PROJECT_MANAGEMENT',
}

export enum TemplateElementMove {
  Up = 'UP',
  Down = 'DOWN',
  Stay = 'STAY', // Stay in section
  NotAllowed = 'NOT_ALLOWED',
}

export interface TemplateNotification {
  template_id: string;
  notification_template_created: boolean;
  notification_template_changed: boolean;
  notification_community_owner: boolean;
  notification_admins: boolean;
  notification_employees: boolean;
  notification_following_receiver: boolean;
  notification_tool_creator: boolean;
  notification_affected_person: boolean;
}

export interface CommunityFilter {
  myCommunities: CommunityList[];
  adminCommunities: CommunityList[];
}

export interface CommunityList {
  checked: boolean;
  community_id: string;
  community_logo_url: string;
  community_name: string;
}

export interface TemplateDeleteResponse {
  id: string;
  status: ResultState;
  error?: string;
}

export interface TemplateDeleteRequest {
  template: Template;
  delete_force?: boolean;
}

export interface ITemplate {
  id: string;
  sections?: ITemplateSection[];
}

export interface ITemplateSection {
  id: string;
  elements?: TemplateElement[];
  name: string;
}

export interface Template {
  id: string;
  community_id?: CommunityData;
  community_description?: string;
  create_change_info: CreateChangeInfo;
  // description: string | undefined;
  // icon: Attachment | undefined;
  image_header?: Attachment;
  image_market?: Attachment;
  image_preview?: Attachment;
  // name: string | undefined;
  parent_id?: string;
  preview_id: string;
  // purpose: TemplatePurpose | undefined;
  sections?: TemplateSection[];
  // extra: { // = settings
  //   restricted: boolean;
  //   unique: boolean;
  // };
  settings: TemplateSettings; // #TODOHTTP: Change to single attributes later. TemplateSettings component depends on this nested (old) object ...
  status?: TemplateStatus[];
  types?: TemplateType[];
  use_case?: TemplateUseCase;
  tos_date?: Date;
  permission_slots?: TemplateRoleTags[];
  permission_slot_info?: ToolActivePermissionSlotInformation[];
  templates?: Option[];
  printView?: TemplatePrintView[];
}

export interface TemplateRoleTags {
  id: string;
  name: string;
  groups?: Group[];
  group_admin?: boolean;
  group_employee?: boolean;
  group_member?: boolean;
  users?: User[];
}

export interface TemplateElement {
  id: string;
  default_value?: number | string | undefined;
  description?: string;
  elements?: TemplateElement[];
  inputType?: any;
  image?: FileWithPath | null;
  name: string;
  help_text?: string;
  options?: Option[];
  vote?: number;
  settings?: TemplateElementSettings;
  suffix?: string;
  summary?: number | string;
  type: TemplateElementType | null | undefined;
  child_element?: boolean;
  group_id?: string;
  display?: MultiElementDisplayType;
  permission_edit?: Option[];
  permission_view?: Option[];
  template_permission_tags?: Option[];
  can_edit?: boolean;
  can_add_row?: boolean;
  value?: any;
  values_from_other_tool?: boolean;
  printViews?: TemplatePreview[];
}

export interface TemplateElementPostPatchResponse {
  element: TemplateElement;
  section_id: string;
}

export interface TemplateElementDeleteRequest {
  id: string;
  element_id: string;
}

export interface TemplateElementPatchOrderRequest {
  id: string;
  section_id: string;
  elements: TemplateElement[];
  multiid?: string;
}

export interface TemplateSectionElementPatchOrderRequest {
  id: string;
  sections: TemplateSection[];
}

export interface TemplateSectionPatchOrderRequest {
  id: string;
  sections: TemplateSection[];
}

export interface TemplateElementDeleteResponse
  extends TemplateElementDeleteRequest {
  section_id: string;
}

export interface TemplateElementSettings {
  advanced?: {
    autoid_prefix?: boolean;
    autoid_prefix_text?: string;
    autoid_postfix?: boolean;
    autoid_postfix_text?: string;
    autoid_start_value?: number;
    auto_date?: boolean;
    auto_select?: boolean;
    contact_card?: boolean;
    connected_contact?: boolean;
    date_with_time?: boolean;
    duration?: boolean;
    only_edit_own_posts?: boolean;
    only_admin_can_remove_posts?: boolean;
    only_show_employees?: boolean;
    only_show_members?: boolean;
    only_show_own_posts?: boolean;
    only_admin_can_post?: boolean;
    required?: boolean;
    only_image?: boolean;
    direct_download?: boolean;
    only_film?: boolean;
    only_office?: boolean;
    only_archive?: boolean;
    only_one_image?: boolean;
    small_image?: boolean;
    hasSuffix?: boolean;
    thousandPoint?: boolean;
    integer?: boolean;
    n_posts?: number;
    toggle_false_string?: string;
    toggle_true_string?: string;
    value_show_in_other_tool?: boolean;
    show_only_for_admin?: boolean;
    only_admin_can_edit?: boolean;
    show_at_arccordion_header?: boolean;
    single_select?: boolean;
    not_showing_at_table?: boolean;
    template_elements_for_data_from_other_tool?: string;
    template_for_data_from_other_tool?: string;
    folder_for_tool_data?: string;
    show_group_at_table_for_data_from_other_tool?: boolean;
    show_contact_at_table_for_data_from_other_tool?: boolean;
    notification_multielement_created?: boolean;
    notification_multielement_emails?: boolean;
    notification_multielement_changed?: boolean;
    notification_community_owner?: boolean;
    notification_admins?: boolean;
    notification_employees?: boolean;
    notification_tool_creator?: boolean;
    notification_multielement_creator?: boolean;
    notification_multielement_following_receiver?: boolean;
    notification_multielement_affected_person?: boolean;
    notification_element_changed?: boolean;
    notification_element_changed_title?: boolean;
    notification_element_changed_content?: boolean;
    [key: string]: boolean | undefined | string | number;
  };
  list?: {
    employees?: boolean;
    members?: boolean;
  };
  multi_element_table?: TemplateMultiElementTableSettings;
  rights?: {
    employee: boolean;
    member: boolean;
    pengueen_admin: boolean;
  };
  vote?: {
    change: boolean;
    result: boolean;
  };
  export?: {
    export_with_id?: boolean;
    disableExport?: boolean;
    [key: string]: boolean | string | undefined;
  };
}

export interface TemplateElementTypeButton {
  icon: [IconPrefix, IconName];
  subtitle: TFunctionResult;
  title: TFunctionResult;
  type: TemplateElementType;
}

export interface TemplateElementChildPostPatchResponse
  extends TemplateElementPostPatchResponse {
  element_id: string;
}

export interface TemplateElementChildDeleteRequest
  extends TemplateElementDeleteRequest {
  child_id: string;
}

export interface TemplateElementChildDeleteResponse
  extends TemplateElementChildDeleteRequest {
  section_id: string;
  child_id: string;
  element_id: string;
  id: string;
}

export interface TemplateMultiElementTableSettings {
  elements_admin: string[]; // Element ids
  elements_visible: string[]; // Element ids
  sort_by: { id: string; order: 'ASC' | 'DESC' };
  tile_view?: boolean;
  kanban_card_description?: string;
  kanban_card_title?: string;
  kanban_card_user?: string;
  kanban_column?: string;
  kanban_status?: string;
  mfe_create_btn_name?: string;
}

export interface TemplateMultiElementTableSettingsPatchResponse {
  id: string; // Template id
  element_id: string; // Template element id
  settings: TemplateMultiElementTableSettings;
}

export interface TemplatePrint {
  id: string;
  cover?: Attachment;
  name: string;
  sections?: TemplatePrintValues[];
}

export interface TemplatePrintPostRequest {
  cover?: FileWithPath;
  name: string;
  sections?: TemplatePrintValues[];
}

export interface TemplatePrintValues {
  id: string;
  checked: boolean;
  elements?: TemplatePrintValues[];
}

export interface TemplateSection {
  id: string;
  elements?: TemplateElement[];
  name: string;
  description?: string;
  settings: {
    restricted: boolean;
  };
}

export interface TemplateSectionDeleteRequestResponse {
  id: string;
  section_id: string;
}

export interface TemplateSectionElementsGroupRequest {
  elements: string[];
  sectionId: string;
  toolId: string;
}

export interface TemplateSettings {
  description: string | undefined;
  extra: {
    // = settings
    restricted: boolean;
    unique: boolean;
  };
  icon: Attachment | undefined;
  name: string | undefined;
  purpose: TemplatePurpose | undefined;
  notification?: TemplateNotification | undefined;
  interface_url?: string | undefined;
  interface_token?: string | undefined;
  header_image?: FileWithPath | undefined;
  image_header?: Attachment | undefined;
}

export interface TemplatePublishPostRequest {
  community_id: string;
  community_display?: boolean;
  community_description?: string;
  image_market?: FileWithPath | null;
  tos?: boolean;
  types: {
    community: boolean;
    private: boolean;
    public: boolean;
  };
  use_case: TemplateUseCase;
  template_for_users: boolean;
  template_for_groups: boolean;
  template_for_community: boolean;
  employee_can_see_edit_tools: boolean;
  only_admin_can_create: boolean;
  only_one: boolean;
  user_can_create: boolean;
}

export interface TemplatePublishPostResponse {
  id: string;
  template_publish: TemplatePublishPostRequest;
}

export type TemplateElementPostPatchRequest = Omit<TemplateElement, 'id'>;

export type TemplatePreview = Pick<
  Template,
  | 'id'
  | 'image_preview'
  | 'settings'
  | 'status'
  | 'types'
  | 'use_case'
  | 'community_id'
>;

export type TemplateElementPreview = Pick<
  TemplateElement,
  'id' | 'name' | 'type'
>;

export type TemplateSectionPostPatchRequest = Pick<
  TemplateSection,
  'name' | 'settings' | 'description'
>;

export type TemplateSectionPostPatchResponse = Pick<
  TemplateSection,
  'id' | 'name' | 'settings' | 'description'
>;

export interface ElementDrafts {
  id: string; // element id
  section_id: string;
  element?: TemplateElement;
  elementCreateEdit?: TemplateElement;
  elementCreateEditState?: TemplateElementCreateEditState;
  elementCreateEditSteps?: TemplateElementCreateEditState[];
  elementCreateEditSummaryState?: boolean;
  elementCreateEditValid?: boolean;
  elementTempo?: TemplateElement | null;
}

export interface TemplateSectionElementEditState {
  edit: ElementEditType;
}

export type ElementEditType = {
  element: 'ELEMENT';
  child_element: 'CHILD_ELEMENT';
};

export interface VoteValues {
  vote: number;
}

export interface InputTypes {
  text: boolean;
  time: boolean;
  url: boolean;
  email: boolean;
  date: boolean;
  date_time: boolean;
  number: boolean;
}

export type TemplatePrintViewPostRequest = Omit<TemplatePrintView, 'id'>;

export interface TemplatePrintView {
  id: string;
  rows: RowType[];
  name: string;
  horizontal: boolean;
  quer: boolean;
  onlyAdmin: boolean;
}

export interface RowType {
  selectElement: string;
  showLabel: boolean;
  showDescription: boolean;
  showContent: boolean;
  printIfEmpty: boolean;
}
