import { Navigate, Route, Routes } from 'react-router-dom';
import { ContactCommunityGetAction } from '../modules/contacts/models/contacts.types';
import { DashboardViewType } from '../modules/dashboard/models/dashboard.types';
import { Dashboard, DashboardProps } from '../new_modules/dashboard/Dashboard';

export type NewDashboardRouterProps = {
  isNewView: boolean;
  toggleNewView: (checked: boolean) => void;
};

export const NewDashboardRouter = ({
  isNewView,
  toggleNewView,
}: NewDashboardRouterProps) => {
  return (
    <Routes>
      {/* ViewType Default */}
      <>
        <Route
          path="/"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.None}
              viewType={DashboardViewType.Default}
            />
          }
        />
        {/* <Route
          path="/all"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.All}
              viewType={DashboardViewType.Community}
            />
          }
        /> */}
        <Route
          path="/community/:id"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Community}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/:id/admin"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Admin}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/:id/employee"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Employee}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/:id/member"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Member}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/group/:communitygroupid"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Group}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/folder/:forderid"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Folder}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/folder/:forderid/group/:communitygroupid"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.FolderGroup}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/link/:linkid"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.CommunityLink}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/community/tool/:toolid"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.ToolLink}
              viewType={DashboardViewType.Community}
            />
          }
        />
        <Route
          path="/group/:groupid"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Room}
              viewType={DashboardViewType.Message}
            />
          }
        />
      </>
      {/* ViewType Message */}
      <>
        <Route
          path="/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.None}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/all/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.All}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/:id/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Community}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/:id/admin/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Admin}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/:id/employee/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Employee}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/:id/member/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Member}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/group/:communitygroupid/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Group}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/folder/:forderid/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Folder}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/folder/:forderid/group/:communitygroupid/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.FolderGroup}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/link/:linkid/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.CommunityLink}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/community/tool/:toolid/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.ToolLink}
              viewType={DashboardViewType.Message}
            />
          }
        />
        <Route
          path="/group/:groupid/message"
          element={
            <Dashboard
              isNewView={isNewView}
              toggleNewView={toggleNewView}
              action={ContactCommunityGetAction.Room}
              viewType={DashboardViewType.Message}
            />
          }
        />
      </>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
