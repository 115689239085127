import { ReactChild, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { Box, Button, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';

// Components
import { Loader } from '../Loader/Loader';

// Styles
import './TextButton.scss';

type TextButtonProps = {
  active?: boolean;
  borderColor?: string;
  borderRadius?: string;
  children: ReactChild | TFunctionResult;
  classes?: string;
  disabled?: boolean;
  loading?: boolean;
  padding?: string;
  preset?:
    | 'danger'
    | 'gray'
    | 'popover'
    | 'popover-darkgray'
    | 'primary'
    | 'secondary'
    | 'dashboard'
    | 'header'
    | 'underline';
  sx?: SxProps<Theme>;
  textTransform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase';
  textSize?: 'text-xs' | 'text-sm' | 'text-lg' | 'text-xl' | 'text-xxl';
  transform?: string;
  type?: 'reset' | 'submit';
  width?: string;
  onClick?: (event?: any) => void;
};

export const TextButton = (props: TextButtonProps) => {
  // Component state
  const [loaderColor, setLoaderColor] = useState<string | undefined>(undefined);
  const [sx, setSx] = useState<SxProps<Theme> | undefined>(undefined);

  useEffect(() => {
    switch (props.preset) {
      case 'danger':
        setSx({
          ...props.sx,
          borderColor: 'error.main',
          color: 'error.main',
          '&:active': {
            borderColor: 'error.main',
          },
          '&:hover': {
            borderColor: 'error.main',
          },
        });
        setLoaderColor('white');
        break;
      case 'gray':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'primary.main' : 'pengueen.gray',
          borderColor: 'transparent',
          color: 'white',
          '&:disabled': {
            borderColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'primary.main',
            borderColor: 'transparent',
            color: 'white',
          },
        });
        setLoaderColor('white');
        break;
      case 'popover':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'primary.main' : 'bg.card',
          borderColor: 'transparent',
          color: props.active ? 'white' : 'text.primary',
          '&:disabled': {
            borderColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'primary.main',
            borderColor: 'transparent',
            color: 'white',
          },
        });
        setLoaderColor('text.primary');
        break;
      case 'popover-darkgray':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'pengueen.darkgray' : 'bg.card',
          borderColor: 'transparent',
          color: props.active ? 'white' : 'text.primary',
          '&:disabled': {
            borderColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'pengueen.darkgray',
            borderColor: 'transparent',
            color: 'white',
          },
        });
        setLoaderColor('text.primary');
        break;
      case 'primary':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'primary.dark' : 'primary.main',
          borderColor: 'transparent',
          color: 'white',
          '&:active': {
            borderColor: 'transparent',
          },
          '&:disabled': {
            borderColor: 'transparent',
            color: 'white',
          },
          '&:hover': {
            backgroundColor: 'primary.dark',
            borderColor: 'transparent',
          },
        });
        setLoaderColor('white');
        break;
      case 'secondary':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'primary.main' : 'primary.light',
          borderColor: props.borderColor ?? 'transparent',
          color: props.active ? 'white' : 'primary.main',
          '&:disabled': {
            borderColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: 'primary.main',
            borderColor: 'transparent',
            color: 'white',
          },
        });
        break;
      case 'dashboard':
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'bg.card' : 'transparent',
          borderColor: 'border.app',
          border: '0px',
          color: props.active ? 'white' : 'text.primary',
          '&:disabled': {
            boarder: '0px',
          },
          '&:hover': {
            backgroundColor: 'transparent',
            borderTop: '0px',
            borderLeft: '0px',
            borderRight: '0px',
            borderBottom: '2px solid primary.main',
            color: 'primary.main',
            fontWeight: 'bold',
            borderRadius: '0',
          },
        });
        setLoaderColor('text.primary');
        break;
      case 'header':
        break;
      case 'underline':
        setSx({
          ...props.sx,
          color: 'text.primary',
          textDecoration: 'underline',
          border: '0px',
          '&:hover': {
            fontWeight: 600,
            textDecoration: 'underline',
            border: 0,
            backgroundColor: 'background.paper',
          },
        });
        break;

      default:
        setSx({
          ...props.sx,
          backgroundColor: props.active ? 'bg.card' : 'transparent',
          borderColor: 'border.app',
          borderWidth: '1px !important',
          color: 'text.primary',
          '&:disabled': {
            borderColor: 'border.app',
          },
          '&:hover': {
            backgroundColor: 'bg.card',
            borderColor: 'border.app',
          },
        });
        break;
    }
  }, [props]);

  return (
    <Button
      color="primary"
      disabled={props.disabled || props.loading}
      disableElevation
      sx={{
        ...sx,
        padding: props.padding ?? '0.5rem 1rem',
        textTransform: props.textTransform ?? 'uppercase',
        width: props.width ?? 'fit-content',
        borderRadius: props.borderRadius ?? undefined,
      }}
      type={props.type || 'button'}
      variant="outlined"
      onClick={(event) => props.onClick && props.onClick(event)}
      className={clsx('text-button', props.classes && props.classes)}
    >
      <Box
        className={clsx(
          'text-button-content',
          props.textSize && props.textSize
        )}
      >
        {props.children}
      </Box>
      {props.loading && (
        <Box className="text-button-loader">
          <Loader color={loaderColor} />
        </Box>
      )}
    </Button>
  );
};
