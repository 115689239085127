import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Button } from '@mui/material';

// Components
import { AddButton } from '../../../../shared/ui/AddButton/AddButton';
import { useStableNavigate } from '../../../../shared/components/StableNavigateContext/StableNavigateContext';

// Hooks
import { useDashboardHttp } from '../../hooks/use-dashboard-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';

// Models
import {
  ToolFavorite as IToolFavorite,
  ToolChangeType,
} from '../../models/dashboard.types';

// Stores
import { useDashboardStore } from '../../stores/use-dashboard.store';

// Styles
import styles from './WidgetFavoriteTools.module.scss';
import clsx from 'clsx';
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { CommunityUserRole } from '../../../communities/models/communities.types';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { useUsersHttp } from '../../../user/hooks/use-users-http.hook';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

type FavoriteToolProps = {
  tool: IToolFavorite;
  heightClasses?: string;
  disableOnClick?: boolean;
  disableCommunityLogo?: boolean;
  classes?: string;
  onClick?: () => void;
};

export const FavoriteTool = (props: FavoriteToolProps) => {
  const { t } = useTranslation();
  const { changeReadPost } = useUsersHttp();
  const { handleRetry } = useFetch();

  // User store state
  const [changes, setChanges] = useUserStore((state: UserState) => [
    state.changes,
    state.setChanges,
  ]);

  // Dashboard store state
  const [favoriteTools, setFavoriteTools] = useDashboardStore((state) => [
    state.favoriteTools,
    state.setFavoriteTools,
  ]);

  // GET changes mutation
  const changeReadMutation = useMutation((id: string) => changeReadPost(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      if (data) {
        const updatedChanges = [...changes];
        const index = updatedChanges.findIndex(
          (change) => change.id === data.change_id
        );
        if (index > -1) {
          updatedChanges.splice(index, 1);
          setChanges(updatedChanges);
        }
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
        }
      }
    },
  });

  return (
    <Box
      sx={{ color: 'text.primary' }}
      className={clsx(
        props.classes ? props.classes : styles['favorite-tool-container'],
        styles['relative']
      )}
    >
      {!props.disableOnClick && (
        <Link to={`/tools/${props.tool.id}`}>
          <Button
            className={clsx(
              styles['favorite-tool'],
              props.heightClasses
                ? props.heightClasses
                : styles['favorite-tool-height']
            )}
            color="inherit"
            sx={{ backgroundColor: 'bg.card', borderColor: 'border.app' }}
            onClick={() => {
              props.tool.history?.forEach((history) => {
                if (
                  history.tool &&
                  (history.tool.change_type === ToolChangeType.Change ||
                    history.tool.change_type === ToolChangeType.Create ||
                    history.tool.change_type === ToolChangeType.Delete)
                ) {
                  changeReadMutation.mutate(history.id);
                }
              });
              const updatedFavoriteTools = { ...favoriteTools };
              const index = updatedFavoriteTools.findIndex(
                (tool) => tool.id === props.tool.id
              );
              updatedFavoriteTools[index].changes = 0;
              setFavoriteTools(updatedFavoriteTools);
            }}
          >
            {props.tool.changes && props.tool.changes > 0 ? (
              <Box
                sx={{ backgroundColor: 'success.main', color: 'white' }}
                className={styles['favorite-tool-changes']}
              >
                {props.tool.changes}
              </Box>
            ) : (
              <></>
            )}
            <div className={styles['favorite-tool-images']}>
              {props.tool.template?.icon ? (
                <img
                  alt={`${props.tool.name} Icon`}
                  className={styles['favorite-tool-images-template-icon']}
                  src={props.tool.template.icon?.url}
                />
              ) : (
                <div></div>
              )}
            </div>
            <Tooltip title={props.tool.template?.name}>
              <Box
                className={styles['favorite-tool-template-name']}
                sx={{ color: 'text.secondary' }}
              >
                {props.tool.template?.name}
              </Box>
            </Tooltip>

            <Tooltip title={props.tool.name}>
              <Box
                sx={{ color: 'text.primary' }}
                className={styles['favorite-tool-name']}
              >
                {props.tool.name}
              </Box>
            </Tooltip>
            {props.tool.connected_user ? (
              <Tooltip title={props.tool.connected_user}>
                <Box
                  sx={{ color: 'text.secondary' }}
                  className={styles['favorite-tool-template-name']}
                >
                  {props.tool.connected_user}
                </Box>
              </Tooltip>
            ) : props.tool.connected_group ? (
              <Tooltip
                title={
                  props.tool.connected_group.id === CommunityUserRole.Admin
                    ? props.tool.connected_group.name + ': ' + t('app.admin')
                    : props.tool.connected_group.id ===
                      CommunityUserRole.Employee
                    ? props.tool.connected_group.name + ': ' + t('app.employee')
                    : props.tool.connected_group.id === CommunityUserRole.Member
                    ? props.tool.connected_group.name + ': ' + t('app.member')
                    : props.tool.connected_group.name
                }
              >
                <Box
                  sx={{ color: 'text.secondary' }}
                  className={styles['favorite-tool-template-name']}
                >
                  {props.tool.connected_group.id === CommunityUserRole.Admin
                    ? t('app.admin')
                    : props.tool.connected_group.id ===
                      CommunityUserRole.Employee
                    ? t('app.employee')
                    : props.tool.connected_group.id === CommunityUserRole.Member
                    ? t('app.member')
                    : ''}
                </Box>
              </Tooltip>
            ) : (
              <></>
            )}
            {props.tool.community?.data?.logo &&
              !props.disableCommunityLogo && (
                <img
                  alt={props.tool.community.data.name}
                  className={styles['favorite-tool-images-community']}
                  src={props.tool.community.data.logo.url}
                />
              )}
          </Button>
        </Link>
      )}
      {props.disableOnClick && (
        <Button
          className={styles['favorite-tool']}
          color="inherit"
          sx={{ backgroundColor: 'bg.card', borderColor: 'border.app' }}
          onClick={props.onClick && props.onClick}
        >
          {props.tool.changes && props.tool.changes > 0 ? (
            <Box
              sx={{ backgroundColor: 'success.main', color: 'white' }}
              className={styles['favorite-tool-changes']}
            >
              {props.tool.changes}
            </Box>
          ) : (
            <></>
          )}
          <div className={styles['favorite-tool-images']}>
            {props.tool.template?.icon ? (
              <img
                alt={`${props.tool.name} Icon`}
                className={styles['favorite-tool-images-template-icon']}
                src={props.tool.template.icon?.url}
              />
            ) : (
              <div></div>
            )}
          </div>
          <Box
            className={styles['favorite-tool-template-name']}
            sx={{ color: 'text.secondary' }}
          >
            {props.tool.template?.name}
          </Box>
          <Box
            sx={{ color: 'text.primary' }}
            className={styles['favorite-tool-name']}
          >
            {props.tool.name}
          </Box>
          {props.tool.community?.data?.logo && !props.disableCommunityLogo && (
            <img
              alt={props.tool.community.data.name}
              className={styles['favorite-tool-images-community']}
              src={props.tool.community.data.logo.url}
            />
          )}
        </Button>
      )}
    </Box>
  );
};

export const WidgetFavoriteTools = () => {
  const { mdUp, lgUp, xlUp, xxlUp } = useBreakpoints();
  const { favoriteToolsGet } = useDashboardHttp();
  const { handleError, handleRetry } = useFetch();
  const { t } = useTranslation();

  // Component state
  const [showAll, setShowAll] = useState<boolean>(false);
  const [hasTooManyItems, setHasTooManyItems] = useState<boolean | undefined>(
    undefined
  );
  const [visibleItems, setVisibleItems] = useState<IToolFavorite[] | undefined>(
    undefined
  );

  // User store state
  const [account] = useUserStore((state) => [state.account]);

  // Dashboard store state
  const [favoriteTools, setFavoriteTools] = useDashboardStore((state) => [
    state.favoriteTools,
    state.setFavoriteTools,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET Favorite tools mutation
  const favoriteToolsGetMutation = useMutation(() => favoriteToolsGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled: (data, error) => {
      // Handle GET favorite tools
      if (data) {
        if (data !== favoriteTools) {
          setFavoriteTools(data);
          handleShowItems(data);
          if (account) {
            localStorage.setItem(
              `__store_tools_${account?.id}`,
              JSON.stringify(data)
            );
          }
        }
      }
      if (error) {
        const errRes = error?.response;
        // Check if error contains fetch response object
        if (errRes) {
          // Set view error by response status
          // setViewError(handleError(errRes.status));
          console.error(
            'Error getting favorite tools:',
            handleError(errRes.status)
          );
        }
      }
    },
  });

  // ####### //
  // EFFECTS //
  // ####### //

  // Get favorite tools on component mount
  useEffect(() => {
    const savedTools = localStorage.getItem(`__store_tools_${account?.id}`);
    if (savedTools) {
      const data = JSON.parse(savedTools);
      setFavoriteTools(data);
      handleShowItems(data);
      // Update favorite tool after 1 minute
      setTimeout(() => favoriteToolsGetMutation.mutate(), 60000);
    } else {
      favoriteToolsGetMutation.mutate();
    }

    return () => {
      setFavoriteTools([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleShowItems(favoriteTools);
    // eslint-disable-next-line
  }, [mdUp, lgUp, xlUp, xxlUp, showAll]);

  const handleShowItems = useCallback(
    (favoriteTools) => {
      const visibleItems = showAll
        ? favoriteTools
        : xxlUp
        ? favoriteTools.slice(0, 19)
        : xlUp
        ? favoriteTools.slice(0, 15)
        : lgUp
        ? favoriteTools.slice(0, 8)
        : mdUp
        ? favoriteTools.slice(0, 15)
        : favoriteTools.slice(0, 7);

      const hasTooManyItems = xxlUp
        ? favoriteTools.length > 19
        : xlUp
        ? favoriteTools.length > 15
        : lgUp
        ? favoriteTools.length > 8
        : mdUp
        ? favoriteTools.length > 15
        : favoriteTools.length > 7;

      setVisibleItems(visibleItems);
      setHasTooManyItems(hasTooManyItems);
    },
    [mdUp, lgUp, xlUp, xxlUp, showAll]
  );

  return (
    <div className={styles['widget-favorite-tools']}>
      {visibleItems !== undefined && hasTooManyItems !== undefined && (
        <>
          {visibleItems.length === 0 && !favoriteToolsGetMutation.isLoading && (
            <div className={styles['widget-favorite-tools-empty']}>
              <Box
                className={styles['widget-favorite-tools-empty-paragraph']}
                sx={{ color: 'text.secondary' }}
              >
                {t('dashboard.widgets.favorite_tools.empty.text')}
              </Box>
              {/* <Box className="w-[180px]">
                <AddButton
                  classes={styles['widget-favorite-tools-add']}
                  onClick={() => navigate('/market')}
                  smallFont
                >
                  {t('dashboard.widgets.favorite_tools.add').toUpperCase()}
                </AddButton>
              </Box> */}
            </div>
          )}
          {visibleItems.length > 0 && (
            <>
              {/* <Box className="w-1/2 px-2 sm:w-1/3 md:w-1/4 lg:w-1/3 xl:w-1/4 2xl:w-1/5">
                <AddButton
                  classes={styles['widget-favorite-tools-add']}
                  widthClassName={styles['widget-favorite-tools-add']}
                  heightClassName={styles['widget-favorite-tools-add']}
                  onClick={() => navigate('/market')}
                  smallFont
                >
                  {t('dashboard.widgets.favorite_tools.add').toUpperCase()}
                </AddButton>
              </Box> */}

              {visibleItems.map((favoriteTool) => {
                return (
                  <FavoriteTool key={favoriteTool.id} tool={favoriteTool} />
                );
              })}
            </>
          )}
          {hasTooManyItems && (
            <Box
              className={styles['widget-favorite-tools-show']}
              sx={{ color: 'primary.main' }}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll
                ? t('dashboard.widgets.hide_items')
                : t('dashboard.widgets.show_more')}
            </Box>
          )}
        </>
      )}
    </div>
  );
};
