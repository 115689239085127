import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import {
  IconButtonChevronLeft,
  IconButtonChevronRight,
} from '../../new_shared/components/DesktopView/DesktopView';
import { toolAuraMock } from './mocks/tool.mock';
import balloons from '../../assets/temp/balloons.png';
import {
  KanbanInfo,
  Tool as ITool,
  ToolPermission,
  ToolUser,
} from '../../modules/tools/models/tools.types';
import { useTranslation } from 'react-i18next';
import { H2 } from '../../shared/ui/H2/H2';
import {
  TemplateElement,
  TemplateSection,
} from '../../modules/templates/models/templates.types';
import { useToolsStore } from '../../modules/tools/stores/use-tools.store';
import ToolSection from './ToolSection';
import { thinScroll } from '../../shared/models/shared.types';
import { useUserStore } from '../../modules/user/stores/use-user.store';

const Tool = () => {
  const { t } = useTranslation();

  const [tool, setTool] = React.useState<ITool | any>(toolAuraMock);
  const [leftSidebarOpen, setLeftSidebarOpen] = React.useState(true);
  const [rightSideBarOpen, setRightSideBarOpen] = React.useState(true);
  const [expandedSectionAccordions, setExpandedSectionAccordions] =
    React.useState<any[]>([]);

  const [account] = useUserStore((state) => [state.account]);

  const toggleSidebarLeft = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };
  const toggleSidebarRight = () => {
    setRightSideBarOpen(!rightSideBarOpen);
  };

  const accordionSectionClicked = (section_id: any) => {
    if (!tool?.template.sections || tool?.template.sections.length < 2) {
      return; //If there is only one accordion, it must not be closed.
    }
    let updateItems = [...expandedSectionAccordions];
    if (expandedSectionAccordions.includes(section_id)) {
      updateItems = updateItems.filter((item) => item !== section_id);
      setExpandedSectionAccordions(updateItems);
    } else {
      updateItems.push(section_id);
      setExpandedSectionAccordions(updateItems);
    }
    // Save state only when not in preview.
    // if (props.tool?.id && !props.preview) {
    //   toolAccordionExpansionPostMutation.mutate({
    //     tool_id: props.tool.id,
    //     expandedSection: updateItems,
    //     is_mobile: lgDown,
    //   });
    // }
  };

  const checkIfAdmin = (): boolean => {
    if (tool && account) {
      if (
        tool.users.find((users: ToolUser) => {
          return (
            users.id === account?.id &&
            users.permission === ToolPermission.Admin
          );
        })
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <Grid className="h-full overflow-y-hidden">
      {/****************************/}
      {/*          Header          */}
      {/****************************/}
      <Box
        className="w-full h-[112px] overflow-y-hidden"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundImage: `url(${tool.image_header?.url || balloons})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box className="flex flex-col px-8">
          <Box className="flex font-semibold text-lg" color="white">
            {tool?.name}
          </Box>
          <Box className="flex text-base" color="white">
            {tool?.template.settings.name}
          </Box>
        </Box>
      </Box>
      <Grid container className="relative h-[calc(100vh-216px)]">
        {/****************************/}
        {/* Navigation (Width fixed) */}
        {/****************************/}
        {leftSidebarOpen && (
          <Grid
            item
            sx={{ backgroundColor: '#F0F1F2', ...thinScroll }}
            className="h-full p-4 w-tool-nav-width overflow-y-auto" // fixed width in tailwind.config
          >
            <H2>{t('tools.detail.navigation.title')}</H2>
            {tool.template.sections?.map((section: TemplateSection) => (
              <NavSection key={section.id} section={section} />
            ))}
          </Grid>
        )}
        {/****************************/}
        {/*       Toggle Button      */}
        {/****************************/}
        {leftSidebarOpen ? (
          <IconButtonChevronLeft
            onClick={toggleSidebarLeft}
            left={`${300 - 24 / 2}px`} // $dashboard-nav-width - iconWidth(w-6) / 2
          />
        ) : (
          <IconButtonChevronRight onClick={toggleSidebarLeft} />
        )}
        {/****************************/}
        {/*       Main Content       */}
        {/****************************/}
        <Grid
          item
          xs
          className="flex flex-col h-full overflow-x-hidden "
          sx={{
            transition: 'margin-left 0.3s ease',
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0,
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
              minHeight: '48px',
            },
            '& .MuiAccordion-root.Mui-expanded': {
              margin: 0,
            },
            '& .MuiPaper-root-MuiAccordion-root.Mui-expanded': {
              margin: 0,
            },
          }}
        >
          <Grid
            className="flex flex-col py-6 px-8 overflow-y-auto grow"
            sx={{ ...thinScroll }}
          >
            <Typography className="font-semibold text-lg pb-4">
              {tool.name}
            </Typography>
            {tool &&
              tool.template.sections &&
              tool.template.sections.length > 0 &&
              tool.template.sections.map(
                (section: TemplateSection, index: number) => {
                  return (
                    <ToolSection
                      isAdmin={checkIfAdmin()}
                      toolName={tool.name}
                      toolChat={tool.chat}
                      toolId={tool.id}
                      kanbanInfo={tool.kanbans}
                      section={section}
                      accordionSectionClicked={accordionSectionClicked}
                      expandedSectionAccordions={expandedSectionAccordions}
                    />
                  );
                }
              )}
          </Grid>
          <Grid
            className="flex flex-row justify-between w-full h-12 px-6 pt-2 pb-4"
            sx={{ bgcolor: '#D9DBDD' }}
          >
            <Box />
            <Box>
              <Button sx={{ bgcolor: '#309EED', color: 'white' }}>
                Speichern
              </Button>
            </Box>
          </Grid>
        </Grid>
        {/* Toggle Button Right*/}
        {rightSideBarOpen ? (
          <IconButtonChevronRight
            onClick={toggleSidebarRight}
            right={`${300 - 24 / 2}px`} // $tool-nav-width - iconWidth(w-6) / 2
          />
        ) : (
          <IconButtonChevronLeft onClick={toggleSidebarRight} right="0" />
        )}
        {rightSideBarOpen && (
          <Grid
            item
            className="h-full p-4 w-tool-nav-width" // fixed width in tailwind.config
          >
            Einstellungsbereich
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Tool;

const NavBar = () => {
  return (
    <Box
      className="mr-1 self-stretch shrink-0 w-0.5"
      sx={{ backgroundColor: '#DEE0E2' }}
    />
  );
};

type NavElementProps = {
  element: TemplateElement;
  onClick: () => void;
};

const NavElement = ({ element, onClick }: NavElementProps) => {
  return (
    <div className="flex items-center w-full cursor-pointer">
      <NavBar />
      <div className="flex flex-col w-full">
        <Box
          className="flex h-fit items-center flex-wrap w-[240px] pl-3 py-1"
          onClick={onClick}
        >
          {element.name}
        </Box>
      </div>
    </div>
  );
};

type NavSectionProps = {
  section: TemplateSection;
};

const NavSection = ({ section }: NavSectionProps) => {
  // Tools store state
  const [setSectionScrollInto, setSectionElementScrollInto] = useToolsStore(
    (state) => [state.setSectionScrollInto, state.setSectionElementScrollInto]
  );

  return (
    <div className="flex items-center mb-4 w-full">
      <NavBar />
      <div className="flex flex-col w-full">
        <div className="flex grow items-center justify-between w-full">
          <Box
            className="normal-case"
            onClick={() => setSectionScrollInto(section.id)}
          >
            <div className="font-semibold truncate pl-2">{section.name}</div>
          </Box>
        </div>
        <div className="flex flex-col ml-1 w-full">
          {section.elements?.map((element) => (
            <NavElement
              key={element.id}
              element={element}
              onClick={() =>
                setSectionElementScrollInto({
                  section_id: section.id,
                  element_id: element.id,
                })
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
