import { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { TFunctionResult } from 'i18next';
import { Masonry } from '@mui/lab';
import { Box, Button, Divider } from '@mui/material';
import clsx from 'clsx';

// Components
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { Icon } from '../../../../shared/ui/Icon/Icon';
// import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { IconTextButton } from '../../../../shared/ui/IconTextButton/IconTextButton';
import {
  WidgetChanges,
  WidgetChangesActions,
} from '../../components/WidgetChanges/WidgetChanges';
import { WidgetFavoriteTools } from '../../components/WidgetFavoriteTools/WidgetFavoriteTools';
import { WidgetFavoriteGroupsUsers } from '../../components/WidgetFavoriteGroupsUsers/WidgetFavoriteGroupsUsers';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useDashboardMock } from '../../hooks/use-dashboard-mock.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useToolsHttp } from '../../../tools/hooks/use-tools-http.hook';
import { useToolsStore } from '../../../tools/stores/use-tools.store';
import { useUsersHttp } from '../../../user/hooks/use-users-http.hook';

// Stores
import { usePublicStore } from '../../../public/stores/use-public.store';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './Dashboard.module.scss';
import { MarketTemplate as IMarketTemplate } from '../../../market/models/market.types';
import { MarketTemplatesGrid } from '../../../market/components/MarketSummary/MarketSummary';
import { useMarketStore } from '../../../market/stores/use-market.store';
import MarketTemplatePreview from '../../../market/components/MarketTemplatePreview/MarketTemplatePreview';
import { TemplateUseCase } from '../../../templates/models/templates.types';
import {
  CommunityState,
  useCommunityStore,
} from '../../../communities/store/use-community.store';
import { useNewsCenterStore } from '../../../newscenter/stores/use-news-center.store';
import { GodparentsChat } from '../../../godparentschat/components/GodParentsChat/GodparentsChat';
import { useContacts } from '../../../contacts/hooks/use-contacts.hook';
import { Change } from '../../models/dashboard.types';

// Lazy-load components
// const CommunityCreate = lazy(
//   () =>
//     import('../../../communities/components/CommunityCreate/CommunityCreate')
// );
// const WidgetCommunityNextSteps = lazy(
//   () => import('../../components/WidgetCommunityTodos/WidgetCommunityTodos')
// );

type WidgetProps = {
  actions?: ReactNode;
  children: ReactNode;
  show_all?: boolean;
  title: TFunctionResult | string;
  subtitle?: TFunctionResult | string;
  onShowAll?: () => void;
};

const Widget = (props: WidgetProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['widget-container']}>
      <Box
        className={styles['widget']}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <div className={styles['widget-header']}>
          <div className={styles['widget-header-title']}>
            <div className={styles['widget-header-title-name']}>
              {props.title}
            </div>

            {props.show_all && props.onShowAll && (
              <Button
                className={styles['widget-header-title-show-all']}
                color="inherit"
                sx={{
                  color: 'text.secondary',
                  '&:hover': {
                    backgroundColor: 'orange.light',
                    color: 'orange.main',
                    '& svg': {
                      color: 'orange.main',
                    },
                  },
                }}
                onClick={props.onShowAll}
              >
                <div>{t('dashboard.widgets.show_all')}</div>
                <Icon
                  classes={styles['widget-header-title-show-all-icon']}
                  icon={['fas', 'right-long']}
                />
              </Button>
            )}
          </div>
          {props.subtitle && (
            <div className={styles['widget-header-subtitle']}>
              {props.subtitle}
            </div>
          )}
          {/* {props.actions && (
            <div className={styles['widget-header-actions']}>
              {props.actions}
            </div>
          )} */}
        </div>
        <div className={styles['widget-content']}>{props.children}</div>
      </Box>
    </div>
  );
};

const Dashboard = () => {
  const { lgDown } = useBreakpoints();
  // const { favoriteGroupsUsersGet } = useDashboardMock();
  const { handleError, handleRetry } = useFetch();
  const { toolPreviewGet } = useToolsHttp();
  const { userChangesGet, userCommunitiesGet } = useUsersHttp();
  const { RecommendationTemplatesMockGet } = useDashboardMock();
  const { t } = useTranslation();
  const { newContactsNavGet } = useContacts();

  // Component state
  const [dialogChangesAll, setDialogChangesAll] = useState<boolean>(false);
  const [templates, setTemplates] = useState<IMarketTemplate[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [changes, setChanges] = useState<Change[]>([]);
  // Community Store State
  const [setDialogCommunityCreate] = useCommunityStore(
    (state: CommunityState) => [state.setDialogCommunityCreate]
  );
  // Public store state
  const [toolPreviewId, setToolPreviewId] = usePublicStore((state) => [
    state.toolPreviewId,
    state.setToolPreviewId,
  ]);

  // Shared store state
  const [setNotification, setDashboard] = useSharedStore((state) => [
    state.setNotification,
    state.setDashboard,
  ]);

  // User store state
  const [profile, communityText, account, setCommunities, setCommunityText] =
    useUserStore((state: UserState) => [
      state.profile,
      state.communityText,
      state.account,
      state.setCommunities,
      state.setCommunityText,
    ]);

  // Tool store state
  const [resetToolsStoreData] = useToolsStore((state) => [
    state.resetToolsStoreData,
  ]);

  const [selectedTemplate, setSelectedTemplate] = useMarketStore((state) => [
    state.selectedTemplate,
    state.setSelectedTemplate,
  ]);

  const [setSelectedRoom, godparentsChat] = useNewsCenterStore((state) => [
    state.setSelectedRoom,
    state.godparentsChat,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // POST Tool preview use mutation
  const toolPreviewUsePostMutation = useMutation((id: string) =>
    toolPreviewGet(id)
  );
  // GET changes mutation
  const changesGetMutation = useMutation(() => userChangesGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),

    onSettled(data, error) {
      if (data) {
        setChanges(data);
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
        }
      }
    },
  });

  // GET communities mutation
  const communitiesGetMutation = useMutation(() => userCommunitiesGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      if (data) {
        setCommunities(data);

        // Update community Text
        if (data.length > 0 && data[0].data.widget_text) {
          if (data[0].data.widget_text !== communityText) {
            setCommunityText(data[0].data.widget_text);
            localStorage.setItem(
              `__store_community_text_${account?.id}`,
              JSON.stringify(data[0].data.widget_text)
            );
          }
        }
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
          // setCommunityError(handleError(errRes.status));
        }
      }
    },
  });

  // Set Contacts Data
  useEffect(() => {
    changesGetMutation.mutate();

    // Update contact navigation after login
    setTimeout(() => newContactsNavGet(), 1000);

    // Get community Text
    const savedCommunityText = localStorage.getItem(
      `__store_community_text_${account?.id}`
    );
    if (savedCommunityText) {
      setCommunityText(JSON.parse(savedCommunityText));
      // Update favorite tool after 2 minute
      setTimeout(() => communitiesGetMutation.mutate(), 120000);
    } else {
      communitiesGetMutation.mutate();
    }

    return () => {
      setChanges([]);
      setCommunityText(undefined);
      setSelectedRoom(undefined);
    };
    // eslint-disable-next-line
  }, [account]);

  // Handle POST Tool preview use
  useEffect(() => {
    if (toolPreviewUsePostMutation.data) {
      setToolPreviewId(undefined);
      setNotification({
        title: t('tools.preview.popup.buttons.try.notification'),
      });
    }
    if (toolPreviewUsePostMutation.error) {
      // const errRes = toolPreviewUsePostMutation.error?.response;
      // // Check if error contains fetch response object
      // if (errRes) {
      //   // #TODO: Remove
      //   setToolPreviewId(undefined);

      // }
      setNotification({
        title: t('tools.preview.popup.buttons.try.notification'),
      });
      console.error('Error on using tool:');
    }
    // eslint-disable-next-line
  }, [toolPreviewUsePostMutation.data, toolPreviewUsePostMutation.error]);

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    // Change Tooltip color in Header on mounted
    setDashboard(true);
    resetToolsStoreData();
    // set Editor's recommendation Templates on mount
    setTemplates(RecommendationTemplatesMockGet());

    return () => {
      setDashboard(false);
    };
    // eslint-disable-next-line
  }, []);

  // Use tool by preview id
  useEffect(() => {
    toolPreviewId && toolPreviewUsePostMutation.mutate(toolPreviewId);
    // eslint-disable-next-line
  }, [toolPreviewId]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to deselect template. Remove template from string id array.
   */
  const onTemplateDeselect = useCallback(
    (id: string) => {
      const index = selectedTemplates.findIndex(
        (templateId) => templateId === id
      );
      if (index > -1) {
        const updatedSelectedTemplates = [...selectedTemplates];
        updatedSelectedTemplates.splice(index, 1);
        setSelectedTemplates(updatedSelectedTemplates);
      }
    },
    [selectedTemplates]
  );

  /**
   * Handler to select template. Add template to string id array.
   */
  const onTemplateSelect = useCallback(
    (id: string) => {
      const index = selectedTemplates.findIndex(
        (templateId) => templateId === id
      );
      if (index < 0) {
        const updatedSelectedTemplates = [...selectedTemplates];
        updatedSelectedTemplates.push(id);
        setSelectedTemplates(updatedSelectedTemplates);
      }
    },
    [selectedTemplates]
  );

  return (
    <div className={styles['dashboard']}>
      {godparentsChat && <GodparentsChat />}
      <div className={clsx(styles['dashboard-header'])}>
        <div className={styles['dashboard-header-welcome']}>
          <div className={styles['dashboard-header-welcome-main']}>
            <div className={styles['dashboard-header-welcome-main-icon']}>
              &#x1F44B;
            </div>
            <div className={styles['dashboard-header-welcome-main-title']}>
              {t('app.hello')}
              {`${
                profile &&
                profile.personal_data &&
                profile.personal_data.first_name
                  ? ` ${profile.personal_data.first_name}`
                  : ``
              }`}
              !
            </div>
          </div>
          <Box
            className={styles['dashboard-header-welcome-subtitle']}
            sx={{ color: 'text.secondary' }}
          >
            {t('dashboard.header.subtitle')}
          </Box>
        </div>
        <div className={styles['dashboard-header-explore']}>
          <div className={styles['dashboard-header-explore-buttons']}>
            <IconTextButton
              borderStyle="dashed"
              classes={styles['dashboard-header-explore-buttons-item']}
              icon={['fal', 'group-arrows-rotate']}
              padding={lgDown ? '0.5rem 0.75rem' : undefined}
              preset="paper-secondary"
              onClick={() => setDialogCommunityCreate(true)}
            >
              {t('community.create.title_button')}
            </IconTextButton>
          </div>
        </div>
      </div>

      {/* dashboard-content */}
      <div className={clsx(styles['dashboard-content'])}>
        <Masonry columns={{ xs: 1, lg: 2, xxxxl: 3 }} spacing={0}>
          <Widget
            actions={<WidgetChangesActions />}
            show_all={changes.length > 5}
            title={t('dashboard.widgets.changes.title')}
            onShowAll={() => setDialogChangesAll(true)}
          >
            <WidgetChanges
              changes={changes}
              communityText={communityText}
              isLoading={changesGetMutation.isLoading}
            />
          </Widget>
          <Widget title={t('dashboard.widgets.favorite_tools.title')}>
            <WidgetFavoriteTools />
          </Widget>
          <Widget title={t('dashboard.widgets.favorite_users.title')}>
            <WidgetFavoriteGroupsUsers />
          </Widget>
          <div className={styles['dashboard-content-add-widget']}>
            <Button
              className={styles['dashboard-content-add-widget-button']}
              color="inherit"
              disabled
              sx={{ borderColor: 'border.app' }}
            >
              <Icon
                classes={styles['dashboard-content-add-widget-button-icon']}
                icon={['fas', 'plus-circle']}
              />
              <div>{t('dashboard.widgets.add')}</div>
            </Button>
          </div>
        </Masonry>
      </div>

      {/* dashboard-footer */}
      <div className={styles['dashboard-footer']}>
        <Divider />
        <Masonry columns={1} spacing={0}>
          <Widget
            title={t('dashboard.recommendation.title')}
            subtitle={t('dashboard.recommendation.subtitle')}
          >
            <MarketTemplatesGrid
              fullWidth
              disabledTitle
              templates={templates}
              selectedTemplates={selectedTemplates}
              use_case={TemplateUseCase.Other}
              onTemplateDeselect={onTemplateDeselect}
              onTemplateSelect={onTemplateSelect}
            />
          </Widget>
        </Masonry>
      </div>
      <Dialog
        open={dialogChangesAll}
        title={t('dashboard.widgets.changes.title')}
        onClose={() => setDialogChangesAll(false)}
      >
        <WidgetChanges modal changes={changes} />
      </Dialog>
      <Dialog
        back={t('market.preview.back')}
        classes={styles['dashboard-market']}
        disableDividers={selectedTemplate ? true : false}
        open={selectedTemplate ? true : false}
        paddingContent={'0'}
        title={selectedTemplate ? selectedTemplate.settings.name : ''}
        titleSize={lgDown ? 'medium' : 'big'}
        widthClassName={styles['dashboard-market-width']}
        onBack={() => setSelectedTemplate(undefined)}
        onClose={() => setSelectedTemplate(undefined)}
      >
        <MarketTemplatePreview />
      </Dialog>
    </div>
  );
};

export default memo(Dashboard);
