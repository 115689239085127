// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolContactModal_column-name__Q4e4D {\n    display: flex;\n    align-items: center\n}\n  .ToolContactModal_column-name-avatar__iNdkz {\n    position: relative;\n    display: flex;\n    align-items: center\n}\n    .ToolContactModal_column-name-avatar-icon__92mY0 {\n    height: 2.25rem;\n    width: 2.25rem;\n    border-radius: 0.375rem;\n    object-fit: cover\n}\n    .ToolContactModal_column-name-avatar-online-state__kJv1X {\n    position: absolute;\n    right: -0.25rem;\n    top: -0.25rem;\n    height: 0.75rem;\n    width: 0.75rem;\n    border-radius: 9999px\n}\n  .ToolContactModal_column-name-text__XTFbv {\n    margin-left: 1rem;\n    font-weight: 700\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA,mBAAA;IAAA,cAAA;IAAA;CAAA;IAAA;IAAA,gBAAA;IAAA,eAAA;IAAA,wBAAA;IAAA;CAAA;IAAA;IAAA,mBAAA;IAAA,gBAAA;IAAA,cAAA;IAAA,gBAAA;IAAA,eAAA;IAAA;CAAA;EAAA;IAAA,kBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"column-name": "ToolContactModal_column-name__Q4e4D",
	"column-name-avatar": "ToolContactModal_column-name-avatar__iNdkz",
	"column-name-avatar-icon": "ToolContactModal_column-name-avatar-icon__92mY0",
	"column-name-avatar-online-state": "ToolContactModal_column-name-avatar-online-state__kJv1X",
	"column-name-text": "ToolContactModal_column-name-text__XTFbv"
};
export default ___CSS_LOADER_EXPORT___;
