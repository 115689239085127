import { Box, Typography, useTheme } from '@mui/material';
import { TFunctionResult } from 'i18next';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KanBanCardChangedLaneRequest,
  KanbanDto,
  MultiElementDisplayType,
  ToolElementDisplayRequest,
  ToolValuesMultiElementRow,
} from '../../../modules/tools/models/tools.types';
import {
  CustomCardProps,
  MenuItem,
  Theme,
} from '../../../shared/models/shared.types';
import clsx from 'clsx';
import { FormFieldLabel } from '../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { IconTextButton } from '../../../shared/ui/IconTextButton/IconTextButton';
import { IconButton } from '../../../shared/ui/IconButton/IconButton';
import { TemplateElement } from '../../../modules/templates/models/templates.types';
import { InputFlat } from '../../../shared/ui/InputFlat/InputFlat';
import { Icon } from '../../../shared/ui/Icon/Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolMultiElementTable } from './ToolMultiElementTable';
import { useMutation } from 'react-query';
import { useToolsHttp } from '../../../modules/tools/hooks/use-tools-http.hook';
import { useFetch } from '../../../shared/hooks/use-fetch.hook';
import { ToolMultiElementTile } from '../../../modules/tools/components/ToolMultiElementTile/ToolMultiElementTile';
import { Loader } from '../../../shared/ui/Loader/Loader';
import Board from 'react-trello';
import {
  AuthState,
  useAuthStore,
} from '../../../modules/public/stores/use-auth.store';
import { getColumns } from './ToolMultiElementTableCells';
import {
  SharedState,
  useSharedStore,
} from '../../../shared/stores/use-shared.store';

type ToolMultiElementProps = {
  classes?: string;
  disabled?: boolean;
  element: TemplateElement;
  help_text?: string;
  label?: string | TFunctionResult;
  padding?: string;
  placeholder?: string;
  required?: boolean;
  sublabel?: string | TFunctionResult;
  value: ToolValuesMultiElementRow[];
  sectionId: string;
  toolChat?: boolean;
  toolId: string;
  toolName: string;
  isAdmin?: boolean;
  kanban?: KanbanDto | undefined;
  onCancel: (value: string) => void;
  onChange?: (value: string) => void;
  onSave: (value: string) => void;
};
const ToolMultiElement = (props: ToolMultiElementProps) => {
  const theme = useTheme();
  const [userTheme] = useSharedStore((state: SharedState) => [state.theme]);

  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();

  const {
    toolMultiElmentDisplayGet,
    toolMultiElementKanBanCardLaneChangedPost,
  } = useToolsHttp();

  const [display, setDisplay] = useState(MultiElementDisplayType.Table);
  const [boardData, setBoardData] = useState(props.kanban);

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  // ######### //
  // MUTATIONS //
  // ######### //
  const toolMultiElmentDisplayGetMutation = useMutation(
    (data: ToolElementDisplayRequest) => toolMultiElmentDisplayGet(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (
            data.display === MultiElementDisplayType.Tile &&
            !props.element.settings?.multi_element_table?.tile_view
          ) {
            onSetDisplay(MultiElementDisplayType.Table);
          } else {
            data.display && onSetDisplay(data.display);
          }
        }
        if (error) {
          handleError(error.response.status);
        }
      },
    }
  );

  const toolMultiElmentKanBanCardChangedLaneMutation = useMutation(
    (data: KanBanCardChangedLaneRequest) =>
      toolMultiElementKanBanCardLaneChangedPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          //nothing to do
        }
        if (error) {
          handleError(error.response.status);
        }
      },
    }
  );

  // Set display type on mount
  useEffect(() => {
    toolMultiElmentDisplayGetMutation.mutate({ id: props.element.id });
    // eslint-disable-next-line
  }, []);

  const actionsGet = useCallback(() => {
    let actions: MenuItem[] = [];
    actions.push(
      {
        action: MultiElementDisplayType.Read,
        title: t('tools.display.read'),
        icon: ['fal', 'book-open'],
      },
      {
        action: MultiElementDisplayType.Table,
        title: t('tools.display.table'),
        icon: ['fal', 'table-layout'],
      },
      {
        action: MultiElementDisplayType.Tile,
        title: t('tools.display.tile'),
        icon: ['fal', 'gallery-thumbnails'],
      },
      {
        action: MultiElementDisplayType.Kanban,
        title: t('tools.display.kanban'),
        icon: ['fal', 'square-kanban'],
      }
    );
    return actions;
    // eslint-disable-next-line
  }, []);

  const onAction = useCallback((action: MultiElementDisplayType) => {
    if (action === MultiElementDisplayType.Read) {
      onSetDisplay(MultiElementDisplayType.Read);
    }
    if (action === MultiElementDisplayType.Table) {
      onSetDisplay(MultiElementDisplayType.Table);
    }
    if (action === MultiElementDisplayType.Kanban) {
      onSetDisplay(MultiElementDisplayType.Kanban);
    }
    if (action === MultiElementDisplayType.Tile) {
      onSetDisplay(MultiElementDisplayType.Tile);
    }
  }, []);

  const onSetDisplay = useCallback(
    (selectedDisplay: MultiElementDisplayType) => {
      setDisplay(selectedDisplay);
    },
    // eslint-disable-next-line
    []
  );

  const filterSx = {
    padding: '0.4rem 0.75rem',
    border: '0px !important',
    borderRadius: '6px',
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px !important',
    },
  };
  const handleCardClick = (cardId: string) => {};

  const handleCardMove = (
    fromLaneId: string,
    toLaneId: string,
    cardId: string,
    index: number
  ) => {
    if (boardData && boardData.lanes) {
      const updatedData = {
        ...boardData,
        lanes: boardData.lanes.map((lane) => {
          // Karte aus der Quellspalte entfernen
          if (lane.id === fromLaneId && cardId) {
            lane.cards = lane.cards.filter((card) => card.id !== cardId);
          }

          // Karte in die Zielspalte einfügen
          if (lane.id === toLaneId && cardId) {
            const card = boardData.lanes
              .find((lane) => lane.id === fromLaneId)
              ?.cards.find((card) => card.id === cardId); // Hole die Karte basierend auf der ID

            if (card) {
              lane.cards.splice(index, 0, card); // Karte an die angegebene Position einfügen
            }
          }

          return lane;
        }),
      };

      setBoardData(updatedData);
    }
  };

  const handleDragEnd = (
    cardId: string, //rowID
    sourceLaneId: string, //optionID von Select-Field (in den Settings)
    targetLaneId: string, //optionID von Select-Field (in den Settings)
    position: number, //sort
    cardDetails: any //card-Object
  ) => {
    cardId &&
      toolMultiElmentKanBanCardChangedLaneMutation.mutate({
        tool_id: props.toolId ?? '',
        row_id: cardId,
        new_lane: cardDetails.laneId,
      });
  };

  const CustomCard: React.FC<CustomCardProps> = ({
    title,
    description,
    user,
    autoid,
    id,
  }) => (
    <Box
      onClick={() => handleCardClick(id)}
      className="rounded-md p-2.5 mb-2.5 cursor-pointer"
      style={{
        fontSize: '12px',
        backgroundColor: userTheme === Theme.Light ? '#e9e9f0' : '#3a3a3a', //border.app
        maxWidth: '300px',
      }}
    >
      <span
        style={{
          color: '#309eed',
          fontWeight: 'bolder',
          paddingTop: '5px',
          marginBottom: '10px',
          display: 'block',
        }}
      >
        {title}
      </span>
      <span
        style={{ paddingBottom: '10px', display: 'block' }}
        dangerouslySetInnerHTML={{ __html: description }}
      ></span>
      <div
        className="flex flex-row items-center justify-between mt-1 pt-2"
        style={{
          borderTop: 'solid 1px #ccc',
        }}
      >
        <div></div>
        <div className="flex flex-row items-center">
          <div>24. 12. 2024 </div>
          {user && user.avatar && (
            <>
              <img
                src={user.avatar}
                alt={user.name}
                className="rounded-full w-6 h-6 mr-2 ml-1"
              />
            </>
          )}
        </div>
      </div>
    </Box>
  );
  const CustomLaneHeader: React.FC<any> = ({ title, color }) => {
    return (
      <Box
        className="p-2 font-semibold text-sm rounded-md"
        style={{
          color: '#fff',
          backgroundColor: color,
        }}
      >
        {title}
      </Box>
    );
  };

  const columns = React.useMemo(
    () =>
      getColumns(
        accessToken || '',
        props.element,
        props.isAdmin || false,
        props.toolId,
        props.sectionId,
        props.toolChat || false,
        props.value
      ),
    [accessToken, props]
  );

  return (
    <Box className={clsx(props.classes && props.classes, 'w-full')}>
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
      <Box
        className="py-2 border-solid border-y border-x-0 flex flex-row justify-between "
        sx={{ borderColor: 'border.app' }}
      >
        <IconTextButton
          disabled={props.disabled}
          icon={['fal', 'plus']}
          padding={'0.25rem 0.5rem'}
          preset="primary"
          onClick={() => {}}
        >
          {t('tools.detail.multi_element.add.button')}
        </IconTextButton>
        <Box
          className="flex flex-row items-center"
          sx={{ color: 'text.secondary' }}
        >
          <InputFlat
            className="flex flex-row items-center mx-1"
            startAdornmentElement={
              <FontAwesomeIcon
                className="mx-1 p-2 pr-0"
                style={{ color: theme.palette.text.secondary }}
                icon={['fas', 'search']}
              />
            }
            padding="0.5rem 0.25rem"
            placeholder={t('app.search')}
          />
          <IconButton
            classes="mx-1 text-base"
            icon={['far', 'filter']}
            preset="tool-icon"
            iconSize="inherit"
            padding="0.4rem"
          />
          <IconButton
            classes="mx-1 text-base"
            icon={['fas', 'arrow-down-to-line']}
            preset="tool-icon"
            iconSize="inherit"
            padding="0.4rem"
          />
        </Box>
        <Box className="flex flex-row items-center">
          <Typography>Ansicht: </Typography>
          <IconButton
            active={display === MultiElementDisplayType.Tile}
            disabled={!props.element.settings?.multi_element_table?.tile_view}
            classes="mx-1 text-base"
            icon={['fal', 'gallery-thumbnails']}
            preset="tool-icon"
            iconSize="inherit"
            padding="0.4rem"
            onClick={() => onSetDisplay(MultiElementDisplayType.Tile)}
          />
          <IconButton
            active={display === MultiElementDisplayType.Read}
            classes="mr-1 text-base"
            icon={['fal', 'book-open']}
            preset="tool-icon"
            iconSize="inherit"
            padding="0.4rem"
            onClick={() => onSetDisplay(MultiElementDisplayType.Read)}
          />
          <IconButton
            active={display === MultiElementDisplayType.Table}
            classes="mr-1 text-base"
            icon={['fal', 'table-layout']}
            preset="tool-icon"
            iconSize="inherit"
            padding="0.4rem"
            onClick={() => onSetDisplay(MultiElementDisplayType.Table)}
          />
          <IconButton
            active={display === MultiElementDisplayType.Kanban}
            classes="text-base"
            icon={['fal', 'square-kanban']}
            preset="tool-icon"
            iconSize="inherit"
            padding="0.4rem"
            onClick={() => onSetDisplay(MultiElementDisplayType.Kanban)}
          />
        </Box>
      </Box>
      <Box>
        {display === MultiElementDisplayType.Table && (
          <ToolMultiElementTable
            element={props.element}
            sectionId={props.sectionId}
            toolChat={props.toolChat}
            toolId={props.toolId}
            toolName={props.toolName}
            values={props.value}
            isAdmin={props.isAdmin || false}
            columns={columns}
            // onCancel={() => setSelectedMultiElementRowCreate(undefined)}
          />
        )}
        {display === MultiElementDisplayType.Tile && (
          <ToolMultiElementTile
            element={props.element}
            sectionId={props.sectionId}
            toolId={props.toolId}
            toolName={props.toolName}
            value={props.value ?? []}
            // value={valuesMock}
            isAdmin={props.isAdmin || false}
            // onCancel={() => setSelectedMultiElementRowCreate(undefined)}
          />
        )}
        {display === MultiElementDisplayType.Kanban && (
          <>
            {props.kanban &&
            props.kanban.lanes &&
            props.kanban.lanes.length > 0 ? (
              <Board
                style={{
                  height: 'fit-content',
                  maxHeight: '100vh',
                  backgroundColor:
                    userTheme === Theme.Light ? '#ffffff' : '#202020',
                }}
                laneStyle={{
                  backgroundColor:
                    userTheme === Theme.Light ? '#ffffff' : '#202020',
                }}
                data={boardData}
                draggable
                components={{ Card: CustomCard, LaneHeader: CustomLaneHeader }}
                onCardMoveAcrossLanes={handleCardMove}
                handleDragEnd={handleDragEnd}
              />
            ) : (
              <>
                Die KanBan-Einstellung ist fehlerhaft oder Ihr ausgewähltes
                Element in der Werkbank hat keine Optionen!
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ToolMultiElement;
