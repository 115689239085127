import { DashboardTranslations } from '../models/dashboard.translations.interface';

export const dashboardTranslationsIT: DashboardTranslations = {
  recommendation: {
    title: 'Strumenti dal marketplace',
    subtitle:
      'Nel marketplace trovi strumenti ai teme differente per branche diverse. Strumenti, che troviamo proprio utili, ti presentiamo qui.',
  },
  communities: {
    title: 'Novità da',
  },
  header: {
    explore: 'Fai un giro d`esplorazione',
    subtitle: 'Guardiamo insieme, che c`è attualmente',
  },
  widgets: {
    show_more: 'Mostra tutti gli strumenti',
    hide_items: 'Mostra Meno Strumenti',
    add: 'Aggiungere un widget nuovo (viene presto)',
    delete: {
      check: 'Sei sicuro? sei sicura?',
    },
    community: {
      title: 'Testo di benvenuto',
      tooltip:
        'Testo di benvenuto al dashboard. Da il benvenuto alli´membri nuovi a collaboratori/e  della tua comunità.',
    },
    changes: {
      answer: 'Scrivi un messaggino',
      room: {
        invite: '{{user1}}ti ha invitato al gruppo {{groupName}}, ',
        invite_others:
          '{{user1}} ha invitato al gruppo  {{user2}} {groupName}}, ',
        close:
          '{{user1}} ha chiuso il gruppo {{groupName}}. non puoi scrivere un messaggio al gruppo, ',
        remove: '{{user1}} ha rimosso dal gruppo {{user2}} {{groupName}}, ',
        remove_me: '{{user1}} ti ha rimosso dal gruppo {{groupName}}, ',
        sticky: 'Messaggi nuovi da {{user1}}, ',
        new_message:
          '{{user1}} ha scritto un nuovo messaggio nello strumento {{toolName}}.',

        leave: '{{user1}} ha uscito il vostro messaggio diretto, ',
        mention: '{{user1}}ti ha rivolto personale, ',
        alert: {
          close: {
            title: 'Abbandona i gruppi',
            subtitle:
              'Sei sicuro? I tuoi messaggi verranno eliminati irrevocabilmente!',
          },
          leave: {
            title: 'Lascia la stanza dei messaggi diretti',
            subtitle:
              'Sei sicuro? Tutti i tuoi messaggi verranno irrimediabilmente eliminati',
          },
          invite_me: {
            subtitle: 'Sei sicuro?',
          },
        },
      },
      tool: {
        add_tool_chat_group:
          '{{user1}} ha avviato uno scambio per lo strumento {{toolName}}',
        add_tool_chat_multi_row:
          '{{user1}} ha avviato uno scambio per la voce {{rowName}}',
        remove_tool_chat_group:
          '{{user1}} ha terminato lo scambio per lo strumento {{toolName}}',
        create:
          '{{user1}} ha creato il strumento {{toolName}} per {{communityName}}',
        create_no_community: '{{user1}} ha creato lo strumento {{toolName}}',
        change:
          '{{user1}} ha modificato qualcosa al strumento {{toolName}} per {communityName}}',
        change_no_community:
          '{{user1}} ha apportato alcune modifiche allo strumento {{toolName}}',
        change_duplicate:
          '{{user1}} ha apportato {{count}} modifiche a {{toolName}} per {{communityName}}',
        change_duplicate_no_community:
          '{{user1}} ha apportato {{count}} modifiche a {{toolName}}',
        delete:
          '{{user1}} ha cancellato il strumento{{toolName}} da {{communityName}}',
        delete_no_community: '{{user1}} ha eliminato lo strumento {{toolName}}',
        right_assigned:
          'Nel strumento {{toolName}} ti veniva dato il diritto da {{user1}} {{right}} {{role}}',
        right_removed:
          'Nel strumento {{toolName}} ti veniva preso il diritto da {{user1}}{{right}} {{role}}',
        removed:
          'Tu veniva rimosso da {{user1}} dal un voce nel strumento{{toolName}}',
        selected:
          'Tu veniva indicato da {{user1}} ad un voce nel strumento {{toolName}},',
        multi_seleted: 'Sei stato selezionato in un MFE {{rowName}}',
        multi_assigned: 'Sei stato assegnato a un MFE {{rowName}}',
        multi_removed: 'Sei stato rimosso da un MFE {{rowName}}',
        multi_row_add:
          '{{user1}} ha aggiunto una nuova voce allo strumento {{toolName}} in {{tableName}} per {{communityName}}, ',
        multi_rows_add:
          '{{user1}} ha aggiunto nuove voci allo strumento {{toolName}} in {{tableName}} per {{communityName}}, ',
        multi_row_add_no_community:
          '{{user1}} ha aggiunto una nuova voce allo strumento {{toolName}} in {{tableName}}, ',
        multi_row_update:
          '{{user1}} ha modificato una voce sullo strumento {{toolName}} in {{multiElementName}}. {{columnName}} è stato modificato da {{oldValue}} a {{newValue}}.',
        multi_row_update_no_old_value:
          '{{user1}} ha modificato una voce sullo strumento {{toolName}} in {{multiElementName}}. {{columnName}} è stato modificato a {{newValue}}.',
      },
      template: {
        publish:
          '{{userName}} ha pubblicato il modello di risorsa {{toolName}}',
        delete:
          '{{userName}} ha eliminato il modello di strumento {{toolName}}',
        change:
          '{{userName}} ha modificato il modello di strumento {{toolName}}',
      },
      contact: {
        rejected: '{{user1}} non ha accettato il tuo invito',
        accepted:
          '{{user1}} ha accettato il tuo invito e diventa un membro a Pengueen',
        accepted_link:
          '{{user1}} ha accettato il tuo invito tramite il collegamento al {{group}}.',
        accepted_link_group:
          '{{user1}} ha accettato il tuo invito tramite il link al gruppo {{group}} della comunità {{community}}',
        accepted_link_community:
          '{{user1}} hat deine Einladung zur Gemeinschaft {{community}} über den Link angenommen',
        accepted_link_admin:
          '{{user1}} ha accettato il tuo invito alla comunità {{community}} come amministratore tramite il collegamento',
        accepted_link_employee:
          '{{user1}} ha accettato il tuo invito alla community {{community}} come dipendente tramite il link',
        accepted_link_member:
          '{{user1}} ha accettato il tuo invito a unirti alla community {{community}} come membro tramite il link',
        community_accepted: 'Invito da {{user1}} accettato da {{user2}}',
        community_invited:
          '{{user1}} ti ha invitato nella community {{community}}.',
        community_rejected: 'Invito da {{user1}} scaduto da {{user2}}',
        delete_contact: 'Möchtest du {{nickname}} aus deinem Netzwerk löschen?',
      },
      create_change: {
        at: 'per',
        room: {
          direct: 'Messaggi nuovi da',
          group: 'Messaggi(o) nel',
          text: 'Scorso messaggio al',
        },
        tool: {
          new: {
            text1: 'Strumenti nuovi',
            text2: 'Eseguito al',
          },
          updated: {
            text1: 'Cambiamenti ai strumenti',
            text2: 'Modificato da ultimo',
          },
        },
      },
      empty: {
        buttons: {
          market: 'Al marketplace di strumenti',
          workbench: 'Al banco da lavoro',
        },
        text1: 'Sembra abbia un giorno libero',
        text2:
          'Abbiamo un consiglio favoloso, come puoi occuparti. Che ne pensi del seguito: Crea un strumento per un tema passionata di te o uno, qualle che fa il tuo lavoro piu facile',
        text3:
          'Il tuo vantaggio? Avessi un strumento preciso per i tui bisogni. Quando condividi nel nostro marketplace, puoi aiutare gli altri, che vogliono usare strumenti similli.',
        text4: 'o vai al giro d`esplorazione di Pengueen🔭',
      },
      filters: {
        title: 'Filtri',
      },
      title: 'Che successo dal tuo scorso Log-in?',
    },
    community_todos: {
      title: 'I tui passi prossimi nella tua comunità',
      users: {
        add: {
          button: 'Si comincia!',
          text: 'Aggiungi personne alla tua comunità!',
        },
      },
    },
    favorite_tools: {
      add: 'Aggiungere strumenti',
      empty: {
        button: 'Fammi vedere, come posso selezionare strumenti ai preferiti',
        text: 'Qui puoi affiggere strumenti importanti o usati spessi',
      },
      title: 'I tui strumenti',
    },
    favorite_users: {
      empty: {
        button: 'Fammi vedere, come posso segnare personne come favoriti',
        text: 'Tira  le personne  piu importante e gruppi direttamente al tuo Dashboard. Segnali come favoriti. 🤩',
      },
      title: 'I tui favoriti - personne e gruppi',
    },
    show_all: 'Mostra tutti',
  },
  title: 'Dashboard',
};
