import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

// Components
import { Accordion } from '../../../../shared/ui/Accordion/Accordion';
import { ChatItem } from '../../../newscenter/components/ChatItem/ChatItem';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { H3 } from '../../../../shared/ui/H3/H3';
import { Checkbox } from '../../../../shared/ui/Checkbox/Checkbox';
import { CommunityUserRole } from '../../../communities/models/communities.types';

// Hooks

// Models
import {
  CommunityFolder,
  AccordionExpansionState,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactCommunityIdType,
  ContactNavGroup,
  ContactsGetFilters,
  ContactsViewType,
  AccordionExpandedGet,
  ContactsViewNavType,
} from '../../models/contacts.types';
import { RoomType } from '../../../newscenter/models/news-center.types';

// Stores
import {
  CommunityState,
  useCommunityStore,
} from '../../../communities/store/use-community.store';
import {
  ContactsState,
  useContactsStore,
} from '../../stores/use-contacts.store';

// Styles
import styles from './ContactsNav.module.scss';
import pageStyles from '../../../../shared/components/PageLayout/PageLayout.module.scss';
import { useNavigate } from 'react-router-dom';
import { useContacts } from '../../hooks/use-contacts.hook';
import { IconTextButton } from '../../../../shared/ui/IconTextButton/IconTextButton';
import { useMutation } from 'react-query';
import { useContactsHttp } from '../../hooks/use-contacts-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { FavoriteLinkType } from '../../../dashboard/models/dashboard.types';
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';

type FolderCheckBoxProps = {
  itemName?: string;
  deletedNavItem?: ContactNavGroup | undefined;
  setDeletedNavItem?: Dispatch<SetStateAction<ContactNavGroup | undefined>>;
  onNavItemCheck?: (checked: boolean) => void;
};

const FolderCheckBox = memo((props: FolderCheckBoxProps) => {
  // Component state
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (
      props.deletedNavItem &&
      props.deletedNavItem.itemName === props.itemName
    ) {
      setChecked(false);
    }
    props.setDeletedNavItem && props.setDeletedNavItem(undefined);

    // eslint-disable-next-line
  }, [props.deletedNavItem]);

  return (
    <Checkbox
      classes="mr-1"
      checked={checked}
      onToggle={(checked: boolean) => {
        setChecked(checked);
        props.onNavItemCheck && props.onNavItemCheck(checked);
      }}
    />
  );
});

type ContactsNavCommunityButtonProps = {
  active?: boolean;
  activeId?: string;
  classes?: string;
  checked?: boolean;
  main?: boolean;
  title: TFunctionResult | string;
  type?: ContactsViewType;
  icon?: [IconPrefix, IconName];
  itemName?: string;
  deletedNavItem?: ContactNavGroup | undefined;
  setDeletedNavItem?: Dispatch<SetStateAction<ContactNavGroup | undefined>>;
  onClick: () => void;
  onNavItemCheck?: (checked: boolean) => void;
  setChecked?: (checked: boolean, itemName: string) => void;
};

const ContactsNavCommunityButton = memo(
  (props: ContactsNavCommunityButtonProps) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
      if (
        props.deletedNavItem &&
        props.deletedNavItem.itemName === props.itemName
      ) {
        setChecked(false);
      }
      props.setDeletedNavItem && props.setDeletedNavItem(undefined);

      // eslint-disable-next-line
    }, [props.deletedNavItem]);

    return (
      <Box
        sx={{
          backgroundColor: props.active ? 'bg.card' : 'initial',
          '&:hover': {
            backgroundColor:
              props.type && props.type === ContactsViewType.ToolEdit
                ? undefined
                : 'bg.hover',
          },
        }}
        // onClick={onChatClick}
        className={
          props.main
            ? styles['contacts-nav-community-button-main']
            : props.type && props.type === ContactsViewType.ToolEdit
            ? styles['contacts-nav-community-button-tool-edit']
            : styles['contacts-nav-community-button']
        }
        onClick={
          props.type && props.type === ContactsViewType.ToolEdit
            ? undefined
            : props.onClick
        }
      >
        {props.type && props.type === ContactsViewType.ToolEdit && (
          <Checkbox
            checked={checked}
            onToggle={(checked: boolean) => {
              setChecked(checked);
              props.onNavItemCheck && props.onNavItemCheck(checked);
            }}
          />
        )}
        {props.icon && (
          <Box
            bgcolor="bg.card"
            className={styles['contacts-nav-community-button-image']}
          >
            <Icon
              icon={props.icon}
              size="inherit"
              sx={{ color: 'text.secondary' }}
            />
          </Box>
        )}
        <Box
          color={props.main ? 'text.primary' : 'text.secondary'}
          className={clsx(
            props.main && styles['contacts-nav-community-button-main-title'],
            props.classes && props.classes
          )}
        >
          {props.title}
        </Box>
      </Box>
    );
  }
);

type ContactsNavProps = {
  activeId?: string;
  deletedNavItem?: ContactNavGroup | undefined;
  include_me?: boolean;
  type?: ContactsViewType;
  navType?: ContactsViewNavType;
  useNavigation?: boolean;
  onContactsGet: (params?: ContactsGetFilters) => void;
  onNavItemCheck?: (checked: boolean) => void;
  onNavItemClick?: (params?: ContactsGetFilters) => void;
  setActiveNav?: Dispatch<SetStateAction<boolean>>;
  setContactCommunity: Dispatch<SetStateAction<ContactCommunity>>;
  setMemberRoleCommunity: Dispatch<SetStateAction<CommunityUserRole>>;
  setSelectedFolder: Dispatch<SetStateAction<CommunityFolder[]>>;
  setDeletedNavItem?: Dispatch<SetStateAction<ContactNavGroup | undefined>>;
};

export const ContactsNav = memo((props: ContactsNavProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { newContactsNavGet, handleAccordionExpand } = useContacts();
  const { handleRetry } = useFetch();
  const { getNavigationNetwork } = useContactsHttp();
  const { lgDown } = useBreakpoints();

  // Community Store State
  const [setDialogCommunityCreate] = useCommunityStore(
    (state: CommunityState) => [state.setDialogCommunityCreate]
  );

  // Contacts Store State
  const [navCommunities, groupChats, selectedNavGroups, setSelectedNavGroups] =
    useContactsStore((state: ContactsState) => [
      state.navCommunities,
      state.groupChats,
      state.selectedNavGroups,
      state.setSelectedNavGroups,
    ]);

  // Component State
  const [expanded, setExpanded] = useState<AccordionExpansionState>({});

  // ######### //
  // MUTATIONS //
  // ######### //

  const getNavigationNetworkMutation = useMutation(
    (body: AccordionExpandedGet) => getNavigationNetwork(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data) {
        if (data) {
          setExpanded(data);
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    newContactsNavGet();
    return () => {
      setSelectedNavGroups([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lgDown && props.navType === ContactsViewNavType.NetworkNavRight) {
      getNavigationNetworkMutation.mutate({
        is_mobile: true,
      });
    } else if (
      !lgDown &&
      props.navType === ContactsViewNavType.NetworkNavLeft
    ) {
      getNavigationNetworkMutation.mutate({
        is_mobile: false,
      });
    }
    // eslint-disable-next-line
  }, [lgDown]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler on group chat item click.
   * @param id Group id
   */
  const onGroupChatItemClick = useCallback(
    (id: string) => {
      if (props.useNavigation) {
        navigate(`/contacts/group/${id}`);
      } else {
        props.onContactsGet({
          group_id: id,
          include_me: false,
        });
      }
      props.setActiveNav && props.setActiveNav(false);
    },
    // eslint-disable-next-line
    [props]
  );

  const onNavItemCheck = useCallback(
    (
      checked: boolean,
      groupName: string,
      id: string,
      type: ContactCommunityGetAction,
      folder_id?: string
    ) => {
      let updatedGroups = [...selectedNavGroups];
      if (checked) {
        let contactNavGroup: ContactNavGroup = {
          itemName: groupName,
          params: {},
          type: type,
        };
        switch (type) {
          case ContactCommunityGetAction.Member:
            contactNavGroup.params = {
              community_id_member: id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.Employee:
            contactNavGroup.params = {
              community_id_employee: id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.Admin:
            contactNavGroup.params = {
              community_id_admin: id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.Group:
            contactNavGroup.params = {
              community_id_group: id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.Folder:
            contactNavGroup.params = {
              community_id_folder: folder_id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.FolderGroup:
            contactNavGroup.params = {
              community_id_group: id,
              community_id_folder: folder_id,
              include_me: props.include_me,
            };
            break;

          case ContactCommunityGetAction.CommunityLink:
            contactNavGroup.params = {
              community_id_community_link: id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.ToolLink:
            contactNavGroup.params = {
              community_id_tool_link: id,
              include_me: props.include_me,
            };
            break;
          case ContactCommunityGetAction.Room:
            contactNavGroup.params = {
              group_id: id,
              include_me: false,
            };
        }
        updatedGroups.push(contactNavGroup);
        // updatedGroups.push(groupName);
      } else {
        switch (type) {
          case ContactCommunityGetAction.Member:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_member !== id
            );
            break;
          case ContactCommunityGetAction.Employee:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_employee !== id
            );
            break;
          case ContactCommunityGetAction.Admin:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_admin !== id
            );
            break;
          case ContactCommunityGetAction.Group:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_group !== id
            );
            break;
          case ContactCommunityGetAction.Folder:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_folder !== folder_id
            );
            break;
          case ContactCommunityGetAction.FolderGroup:
            updatedGroups = updatedGroups.filter((group) => {
              return (
                group.params.community_id_group !== id ||
                group.params.community_id_folder !== folder_id
              );
            });
            break;
          case ContactCommunityGetAction.CommunityLink:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_community_link !== id
            );
            break;
          case ContactCommunityGetAction.ToolLink:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.community_id_tool_link !== id
            );
            break;
          case ContactCommunityGetAction.Room:
            updatedGroups = updatedGroups.filter(
              (group) => group.params.group_id !== id
            );
            break;
        }
      }
      setSelectedNavGroups(updatedGroups);
    },
    [props.include_me, selectedNavGroups, setSelectedNavGroups]
  );

  /**
   * Handler on community member item click.
   * @param id Group id
   */
  const onCommunityMemberItemClick = useCallback(
    (id: string, type: ContactCommunityGetAction, folder_id?: string) => {
      if (props.useNavigation) {
        switch (type) {
          case ContactCommunityGetAction.Member:
            navigate(`/contacts/community/${id}/member`);
            break;
          case ContactCommunityGetAction.Employee:
            navigate(`/contacts/community/${id}/employee`);
            break;
          case ContactCommunityGetAction.Admin:
            navigate(`/contacts/community/${id}/admin`);
            break;
          case ContactCommunityGetAction.Group:
            navigate(`/contacts/community/group/${id}`);
            break;
          case ContactCommunityGetAction.Folder:
            navigate(`/contacts/community/folder/${id}`);
            break;
          case ContactCommunityGetAction.FolderGroup:
            navigate(`/contacts/community/folder/${folder_id}/group/${id}`);
            break;
          case ContactCommunityGetAction.CommunityLink:
            navigate(`/contacts/community/link/${id}`);
            break;
          case ContactCommunityGetAction.ToolLink:
            navigate(`/contacts/community/tool/${id}`);
            break;
        }
      } else {
        switch (type) {
          case ContactCommunityGetAction.Member:
            props.onContactsGet({
              community_id_member: id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.Employee:
            props.onContactsGet({
              community_id_employee: id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.Admin:
            props.onContactsGet({
              community_id_admin: id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.Group:
            props.onContactsGet({
              community_id_group: id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.Folder:
            props.onContactsGet({
              community_id_folder: folder_id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.FolderGroup:
            props.onContactsGet({
              community_id_group: id,
              community_id_folder: folder_id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.CommunityLink:
            props.onContactsGet({
              community_id_community_link: id,
              include_me: props.include_me,
            });
            break;
          case ContactCommunityGetAction.ToolLink:
            props.onContactsGet({
              community_id_tool_link: id,
              include_me: props.include_me,
            });
            break;
        }
      }

      props.setSelectedFolder([]);
      props.setActiveNav && props.setActiveNav(false);
    },
    // eslint-disable-next-line
    [props]
  );

  /**
   * Handler on community title click.
   * @param id Community id
   */
  const onCommunityTitleClick = useCallback(
    (id: string) => {
      props.setSelectedFolder([]);
      props.setActiveNav && props.setActiveNav(false);
      if (props.useNavigation) {
        if (id === ContactCommunityIdType.All) {
          navigate(`/contacts/all`);
        } else {
          navigate(`/contacts/community/${id}`);
        }
      } else {
        if (id === ContactCommunityIdType.All) {
          props.onContactsGet();
        }
        props.onContactsGet({
          community_id: id,
          include_me: props.include_me,
        });
      }
    },
    // eslint-disable-next-line
    [props]
  );

  return (
    <Stack className={styles['contacts-nav']}>
      <IconTextButton
        hideIcon
        classes={styles['contacts-toolbar-button']}
        fontSizeClasses={styles['contacts-toolbar-button-text']}
        icon={['fal', 'plus']}
        padding="0.5rem"
        preset="primary"
        onClick={() =>
          props.type !== ContactsViewType.ToolEdit &&
          onCommunityTitleClick(ContactCommunityIdType.All)
        }
      >
        {t('contacts.all_loading').toUpperCase()}
      </IconTextButton>
      {(navCommunities || []).map((community) => (
        <div key={community.id} className={styles['contacts-nav-community']}>
          <Accordion
            active={props.activeId === community.id}
            detailsClassName={styles['filter-group-by-navCommunities']}
            defaultExpanded
            detailsMarginTop="0rem"
            expanded
            onSummaryClick={() =>
              props.type !== ContactsViewType.ToolEdit &&
              onCommunityTitleClick(community.id)
            }
            title={
              <div
                className={styles['contacts-nav-community-summary']}
                // onClick={}
              >
                {community.avatar?.url ? (
                  <img alt={'Gemeinschaftsname'} src={community.avatar?.url} />
                ) : (
                  <Icon icon={['fas', 'users-rectangle']} />
                )}

                <div className={styles['contacts-nav-community-button-title']}>
                  {community.name}
                </div>
              </div>
            }
          >
            {/* For Admin */}
            {community.member_role === CommunityUserRole.Admin && (
              <>
                <ContactsNavCommunityButton
                  deletedNavItem={props.deletedNavItem}
                  setDeletedNavItem={props.setDeletedNavItem}
                  type={props.type}
                  title={t('app.admin')}
                  icon={['fal', 'user-group-crown']}
                  classes={styles['contacts-nav-community-button-title']}
                  active={
                    'Admin' + community.id === props.activeId ? true : false
                  }
                  itemName={`${community.name}: ${t('app.admin')}`}
                  onNavItemCheck={(checked: boolean) =>
                    onNavItemCheck(
                      checked,
                      `${community.name}: ${t('app.admin')}`,
                      community.id,
                      ContactCommunityGetAction.Admin
                    )
                  }
                  onClick={() =>
                    onCommunityMemberItemClick(
                      community.id,
                      ContactCommunityGetAction.Admin
                    )
                  }
                />
              </>
            )}
            {/* For Employee */}
            {community.member_role !== CommunityUserRole.Member && (
              <>
                <ContactsNavCommunityButton
                  deletedNavItem={props.deletedNavItem}
                  setDeletedNavItem={props.setDeletedNavItem}
                  type={props.type}
                  title={
                    community.employee_name
                      ? community.employee_name
                      : t('app.employee')
                  }
                  icon={['fal', 'user-group']}
                  itemName={`${community.name}: ${
                    community.employee_name
                      ? community.employee_name
                      : t('app.employee')
                  }`}
                  active={
                    'Employee' + community.id === props.activeId ? true : false
                  }
                  classes={styles['contacts-nav-community-button-title']}
                  onNavItemCheck={(checked: boolean) =>
                    onNavItemCheck(
                      checked,
                      `${community.name}: ${
                        community.employee_name
                          ? community.employee_name
                          : t('app.employee')
                      }`,
                      community.id,
                      ContactCommunityGetAction.Employee
                    )
                  }
                  onClick={() =>
                    onCommunityMemberItemClick(
                      community.id,
                      ContactCommunityGetAction.Employee
                    )
                  }
                />
              </>
            )}
            {/* For Member */}
            {(community.member_role === CommunityUserRole.Member ||
              community.member_role === CommunityUserRole.Employee ||
              community.member_role === CommunityUserRole.Admin) && (
              <>
                <ContactsNavCommunityButton
                  deletedNavItem={props.deletedNavItem}
                  setDeletedNavItem={props.setDeletedNavItem}
                  type={props.type}
                  title={
                    community.member_name
                      ? community.member_name
                      : t('app.member')
                  }
                  icon={['fal', 'users']}
                  itemName={`${community.name}: ${
                    community.member_name
                      ? community.member_name
                      : t('app.member')
                  }`}
                  active={
                    'Member' + community.id === props.activeId ? true : false
                  }
                  classes={styles['contacts-nav-community-button-title']}
                  onNavItemCheck={(checked: boolean) =>
                    onNavItemCheck(
                      checked,
                      `${community.name}: ${
                        community.member_name
                          ? community.member_name
                          : t('app.member')
                      }`,
                      community.id,
                      ContactCommunityGetAction.Member
                    )
                  }
                  onClick={() =>
                    onCommunityMemberItemClick(
                      community.id,
                      ContactCommunityGetAction.Member
                    )
                  }
                />
              </>
            )}

            {/* Group not in folder */}
            {community.groups &&
              community.groups.map((group) => {
                return (
                  <ContactsNavCommunityButton
                    deletedNavItem={props.deletedNavItem}
                    setDeletedNavItem={props.setDeletedNavItem}
                    type={props.type}
                    key={group.id}
                    title={
                      group.name
                        ? group.name
                        : t('community.tools.visibility.group')
                    }
                    icon={
                      group.chat_room
                        ? ['fal', 'hashtag']
                        : ['fal', 'users-rectangle']
                    }
                    itemName={`${community.name}: ${
                      group.name
                        ? group.name
                        : t('community.tools.visibility.group')
                    }`}
                    active={
                      'Group' + group.id === props.activeId ? true : false
                    }
                    classes={styles['contacts-nav-community-button-title']}
                    onNavItemCheck={(checked: boolean) =>
                      onNavItemCheck(
                        checked,
                        `${community.name}: ${
                          group.name
                            ? group.name
                            : t('community.tools.visibility.group')
                        }`,
                        group.id,
                        ContactCommunityGetAction.Group
                      )
                    }
                    onClick={() =>
                      onCommunityMemberItemClick(
                        group.id,
                        ContactCommunityGetAction.Group,
                        undefined
                      )
                    }
                  />
                );
              })}
            {/* Group in folder */}
            {community.folders &&
              community.folders.map((folder) => {
                return (
                  <Accordion
                    active={props.activeId === folder.id}
                    key={folder.id}
                    detailsClassName={styles['filter-group-by-navCommunities']}
                    detailsMarginTop="0rem"
                    defaultExpanded
                    expanded={expanded[folder.id] ?? true}
                    onSummaryClick={() => {
                      if (props.type !== ContactsViewType.ToolEdit) {
                        props.setSelectedFolder([folder]);
                        props.onContactsGet({
                          folder_id: folder.id,
                        });

                        const contactCommunity: ContactCommunity = {
                          id: community.id,
                          name: community.name,
                          selected_folder: folder,
                        };

                        props.setContactCommunity(contactCommunity);
                        props.setMemberRoleCommunity(
                          community.member_role ?? CommunityUserRole.Member
                        );
                      }
                      handleAccordionExpand(
                        folder.id,
                        expanded,
                        setExpanded,
                        FavoriteLinkType.Contacts,
                        lgDown,
                        props.navType === ContactsViewNavType.NetworkNavLeft ||
                          props.navType === ContactsViewNavType.NetworkNavRight
                      );
                    }}
                    title={
                      <div
                        className="flex flex-row items-center"
                        // className={styles['contacts-nav-community-summary']}
                      >
                        {props.type === ContactsViewType.ToolEdit && (
                          <FolderCheckBox
                            deletedNavItem={props.deletedNavItem}
                            setDeletedNavItem={props.setDeletedNavItem}
                            itemName={`${community.name}: ${
                              folder.name ? folder.name : 'Folder'
                            }`}
                            onNavItemCheck={(checked: boolean) =>
                              onNavItemCheck(
                                checked,
                                `${community.name}: ${
                                  folder.name ? folder.name : 'Folder'
                                }`,
                                community.id,
                                ContactCommunityGetAction.Folder,
                                folder.id
                              )
                            }
                          />
                        )}

                        {folder.name}
                      </div>
                    }
                  >
                    {folder.groups &&
                      folder.groups.map((group) => {
                        return (
                          <ContactsNavCommunityButton
                            deletedNavItem={props.deletedNavItem}
                            setDeletedNavItem={props.setDeletedNavItem}
                            type={props.type}
                            key={group.id}
                            title={
                              group.name
                                ? group.name
                                : t('community.tools.visibility.group')
                            }
                            icon={
                              group.chat_room
                                ? ['fal', 'hashtag']
                                : ['fal', 'users-rectangle']
                            }
                            itemName={`${community.name}: ${
                              group.name
                                ? group.name
                                : t('community.tools.visibility.group')
                            }`}
                            active={
                              'Group' + group.id === props.activeId
                                ? true
                                : false
                            }
                            classes={
                              styles['contacts-nav-community-button-title']
                            }
                            onNavItemCheck={(checked: boolean) =>
                              onNavItemCheck(
                                checked,
                                `${community.name}: ${
                                  group.name
                                    ? group.name
                                    : t('community.tools.visibility.group')
                                }`,
                                group.id,
                                ContactCommunityGetAction.FolderGroup,
                                folder.id
                              )
                            }
                            onClick={() => {
                              onCommunityMemberItemClick(
                                group.id,
                                ContactCommunityGetAction.FolderGroup,
                                folder.id
                              );
                              props.setSelectedFolder([]);
                            }}
                          />
                        );
                      })}
                  </Accordion>
                );
              })}

            {/* Community links */}
            {community.community_links &&
              community.community_links.map((com_link) => {
                const itemName = `${community.name}: ${
                  com_link.name ? com_link.name : 'Community Link'
                }`;
                return (
                  <div key={com_link.url} className="mt-2">
                    <ContactsNavCommunityButton
                      deletedNavItem={props.deletedNavItem}
                      setDeletedNavItem={props.setDeletedNavItem}
                      type={props.type}
                      title={com_link.name ? com_link.name : 'Community Link'}
                      icon={['fas', 'link']}
                      itemName={itemName}
                      classes={styles['contacts-nav-community-button-title']}
                      onNavItemCheck={(checked: boolean) =>
                        onNavItemCheck(
                          checked,
                          itemName,
                          community.id,
                          ContactCommunityGetAction.CommunityLink
                        )
                      }
                      onClick={() =>
                        onCommunityMemberItemClick(
                          com_link.url,
                          ContactCommunityGetAction.CommunityLink
                        )
                      }
                    />
                  </div>
                );
              })}

            {/* Community tool links */}
            {community.tool_links &&
              community.tool_links.map((tool_link) => {
                const itemName = `${community.name}: ${
                  tool_link.name ? tool_link.name : 'Community Tool Link'
                }}`;
                return (
                  <ContactsNavCommunityButton
                    deletedNavItem={props.deletedNavItem}
                    setDeletedNavItem={props.setDeletedNavItem}
                    type={props.type}
                    key={tool_link.url}
                    title={
                      tool_link.name ? tool_link.name : 'Community Tool Link'
                    }
                    icon={['fas', 'link']}
                    itemName={itemName}
                    classes={styles['contacts-nav-community-button-title']}
                    onNavItemCheck={(checked: boolean) =>
                      onNavItemCheck(
                        checked,
                        itemName,
                        community.id,
                        ContactCommunityGetAction.ToolLink
                      )
                    }
                    onClick={() =>
                      onCommunityMemberItemClick(
                        tool_link.url,
                        ContactCommunityGetAction.ToolLink
                      )
                    }
                  />
                );
              })}
          </Accordion>
        </div>
      ))}
      <Accordion
        detailsClassName={styles['filter-group-by-navCommunities']}
        // classes={styles['contacts-nav-accordion']}
        defaultExpanded
        expanded={expanded['NO_COMMUNITY'] ?? true}
        title={t('contacts.nav.no_community.group.title')}
        onSummaryClick={() =>
          handleAccordionExpand(
            'NO_COMMUNITY',
            expanded,
            setExpanded,
            FavoriteLinkType.Contacts,
            lgDown,
            props.navType === ContactsViewNavType.NetworkNavLeft ||
              props.navType === ContactsViewNavType.NetworkNavRight
          )
        }
      >
        {groupChats.map((groupChat) => (
          <ChatItem
            key={groupChat.id}
            active={props.activeId === groupChat.id}
            groupChat={groupChat}
            hideCursorPointer={props.type === ContactsViewType.ToolEdit}
            showCheckbox={props.type === ContactsViewType.ToolEdit}
            type={RoomType.Group}
            unread_msgs={groupChat.unread_msgs}
            onClick={() =>
              props.type !== ContactsViewType.ToolEdit &&
              onGroupChatItemClick(groupChat.id)
            }
            onNavItemCheck={(checked: boolean) =>
              onNavItemCheck(
                checked,
                groupChat.settings?.name
                  ? groupChat.settings.name
                  : 'Group Chat',
                groupChat.id,
                ContactCommunityGetAction.Room
              )
            }
            deletedNavItem={props.deletedNavItem}
            setDeletedNavItem={props.setDeletedNavItem}
          />
        ))}
      </Accordion>
      <Box className={pageStyles['page-nav-footer']}>
        <Box
          sx={{ borderColor: 'border.strong' }}
          className={pageStyles['page-nav-footer-main']}
        >
          <Box className={pageStyles['page-nav-footer-title']}>
            <H3> {t('contacts.nav.footer.title')}</H3>
          </Box>
          <Box
            sx={{ color: 'text.secondary' }}
            className={pageStyles['page-nav-footer-content']}
          >
            {t('contacts.nav.footer.content')}
          </Box>
        </Box>
      </Box>
    </Stack>
  );
});
