import { Box } from '@mui/system';
import { memo } from 'react';
import clsx from 'clsx';

type NaviTitleProps = {
  title: string;
  classes?: string;
};

export const NaviTitle = memo(({ title, classes }: NaviTitleProps) => {
  return <Box className={clsx(classes, 'py-2 text-xs')}>{title}</Box>;
});
