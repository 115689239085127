import { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import clsx from 'clsx';

// Assets
import pengueen from '../../../../assets/logo/pengueen_small.png';
import favoriten_markieren from '../../../../assets/pictures/dashboard/favoriten_markieren.png';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';

// Hooks
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  Favorite,
  FavoriteLinkType,
  GroupFavorite,
  UserFavorite,
} from '../../models/dashboard.types';

// Stores
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './WidgetFavoriteGroupsUsers.module.scss';
import { useMutation } from 'react-query';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useDashboardHttp } from '../../hooks/use-dashboard-http.hook';
import { MessageRoomEntity } from '../../../newscenter/models/news-center.types';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { CommunityEntity } from '../../../communities/models/communities.types';
import { useUserStore } from '../../../user/stores/use-user.store';

type FavoriteGroupPreviewProps = {
  first_name?: string;
  last_name?: string;
  overlap?: boolean;
  src: string;
};

const FavoriteGroupPreview = (props: FavoriteGroupPreviewProps) => {
  const { fullNameGet } = useShared();

  // Component state
  const [name] = useState<string | TFunctionResult>(
    fullNameGet(props.first_name, props.last_name)
  );

  return (
    <li
      className={clsx(
        props.overlap && styles['favorite-group-preview-overlap']
      )}
    >
      <Box
        className={styles['favorite-group-preview']}
        sx={{
          '& img ': { backgroundColor: 'background.paper' },
        }}
      >
        <img
          alt={name?.toString()}
          src={props.src}
          className={styles['favorite-group-preview-image']}
        />
      </Box>
    </li>
  );
};

type FavoriteGroupUserProps = {
  group?: GroupFavorite;
  user?: UserFavorite;
  community?: CommunityEntity;
  networkGroup?: MessageRoomEntity;
  for_commuity?: boolean;
  for_admin?: boolean;
  for_employee?: boolean;
  for_member?: boolean;
  // #DOTO expand
  // links?:
  // folders?:
};

const FavoriteGroupUser = (props: FavoriteGroupUserProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [unreadMsg, setUnreadMsg] = useState<number | undefined>(undefined);
  const [icon, setIcon] = useState<[IconPrefix, IconName]>(['fal', 'messages']);
  const [communityType, setCommunityType] = useState<
    TFunctionResult | undefined
  >(undefined);

  useEffect(() => {
    // Set Unread Message
    if (props.user?.unread_msgs && props.user.unread_msgs > 0) {
      setUnreadMsg(props.user.unread_msgs);
    } else if (props.group?.unread_msgs && props.group.unread_msgs > 0) {
      setUnreadMsg(props.group.unread_msgs);
    } else {
      setUnreadMsg(undefined);
    }

    // Set Favorite Icon
    if (props.user) {
    } else if (props.group) {
    } else if (props.community) {
      setIcon(['fal', 'chart-network']);
    } else if (props.networkGroup) {
      setIcon(['fal', 'chart-network']);
    }

    // Set community type
    if (props.for_admin) {
      setCommunityType(t('app.admin'));
    } else if (props.for_employee) {
      setCommunityType(t('app.employee'));
    } else if (props.for_member) {
      setCommunityType(t('app.member'));
    } else {
      setCommunityType(undefined);
    }
  }, [props]);
  /**
   * Handler to navigate to correct screen.
   */
  const onNavigate = useCallback(() => {
    props.group &&
      props.group.link === FavoriteLinkType.Newscenter &&
      navigate(`/newscenter/groups/${props.group.id}`);
    props.user &&
      props.user.link === FavoriteLinkType.Newscenter &&
      navigate(`/newscenter/private/${props.user.id}`);
    props.networkGroup &&
      props.networkGroup.community_id &&
      navigate(`/contacts/community/group/${props.networkGroup.id}`);
    props.networkGroup &&
      !props.networkGroup.community_id &&
      navigate(`/contacts/group/${props.networkGroup.id}`);
    props.community &&
      props.for_commuity &&
      navigate(`/contacts/community/${props.community.id}`);
    props.community &&
      props.for_admin &&
      navigate(`/contacts/community/${props.community.id}/admin`);
    props.community &&
      props.for_employee &&
      navigate(`/contacts/community/${props.community.id}/employee`);
    props.community &&
      props.for_member &&
      navigate(`/contacts/community/${props.community.id}/member`);
    // eslint-disable-next-line
  }, [props]);

  return (
    <Box className={styles['favorite-group-box']}>
      {props.group && (
        <Button
          className={clsx(styles['favorite'], styles['favorite-group'])}
          color="inherit"
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'border.app',
          }}
          onClick={onNavigate}
        >
          <Box
            className={styles['favorite-icon']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <Icon
              icon={icon}
              sx={{ color: 'text.secondary', padding: '2px' }}
            />
          </Box>
          {unreadMsg && (
            <Box
              className={styles['favorite-unread']}
              sx={{ backgroundColor: 'success.main', color: 'white' }}
            >
              {unreadMsg}
            </Box>
          )}
          <Box
            className={styles['favorite-group-users']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <ul className={styles['favorite-group-users-preview']}>
              {props.group.users && props.group.users.length > 0 && (
                <FavoriteGroupPreview
                  first_name={props.group.users[0].first_name}
                  last_name={props.group.users[0].last_name}
                  src={props.group.users[0].avatar?.url ?? pengueen}
                />
              )}
              {props.group.users && props.group.users.length > 1 && (
                <FavoriteGroupPreview
                  first_name={props.group.users[1].first_name}
                  last_name={props.group.users[1].last_name}
                  overlap
                  src={props.group.users[1].avatar?.url ?? pengueen}
                />
              )}
              {props.group.users && props.group.users.length === 3 && (
                <FavoriteGroupPreview
                  first_name={props.group.users[2].first_name}
                  last_name={props.group.users[2].last_name}
                  overlap
                  src={props.group.users[2].avatar?.url ?? pengueen}
                />
              )}
              {props.group.users && props.group.users.length > 3 && (
                <li>
                  <Box
                    sx={{
                      backgroundColor: '#444d58',
                      borderColor: 'background.paper',
                      color: 'white',
                    }}
                    className={styles['favorite-group-users-preview-more']}
                  >
                    {`+ ${props.group.users.length - 2}`}
                  </Box>
                </li>
              )}
            </ul>
          </Box>
          <div className={styles['favorite-group-name']}>
            <Box className={styles['favorite-group-name-content']}>
              {props.group.name}
            </Box>
            {props.group?.community_id?.logo && (
              <img
                alt={props.group?.community_id?.logo.filename}
                className={styles['favorite-community-images-community']}
                src={`https://files.pengueen.de/api/download/${props.group?.community_id?.logo.path}`}
              />
            )}
          </div>
        </Button>
      )}
      {props.user && (
        <Button
          className={clsx(styles['favorite'], styles['favorite-user'])}
          color="inherit"
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'border.app',
          }}
          onClick={onNavigate}
        >
          <Box
            className={styles['favorite-icon']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <Icon
              icon={icon}
              sx={{ color: 'text.secondary', padding: '2px' }}
            />
          </Box>
          {unreadMsg && (
            <Box
              className={styles['favorite-unread']}
              sx={{ backgroundColor: 'success.main', color: 'white' }}
            >
              {unreadMsg}
            </Box>
          )}
          <img
            alt={props.user.first_name}
            className={styles['favorite-user-avatar']}
            src={props.user.avatar?.url ?? pengueen}
          />
          <div className={styles['favorite-user-name']}>
            <div className={styles['favorite-user-name-first']}>
              {props.user.first_name ?? t('app.nameless')}
            </div>
            <div className={styles['favorite-user-name-last']}>
              {props.user.last_name ?? ''}
            </div>
          </div>
        </Button>
      )}
      {props.networkGroup && (
        <Button
          className={clsx(styles['favorite'], styles['favorite-group'])}
          color="inherit"
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'border.app',
          }}
          onClick={onNavigate}
        >
          <Box
            className={styles['favorite-icon']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <Icon
              icon={icon}
              sx={{ color: 'text.secondary', padding: '2px' }}
            />
          </Box>
          {unreadMsg && (
            <Box
              className={styles['favorite-unread']}
              sx={{ backgroundColor: 'success.main', color: 'white' }}
            >
              {unreadMsg}
            </Box>
          )}
          <Box
            className={styles['favorite-group-users']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <ul className={styles['favorite-group-users-preview']}>
              {props.networkGroup.participant &&
                props.networkGroup.participant.length > 0 && (
                  <FavoriteGroupPreview
                    first_name={props.networkGroup.participant[0].first_name}
                    last_name={props.networkGroup.participant[0].last_name}
                    src={
                      props.networkGroup.participant[0].avatar_id
                        ? `https://files.pengueen.de/api/download/${props.networkGroup.participant[0].avatar_id?.path}`
                        : pengueen
                    }
                  />
                )}
              {props.networkGroup.participant &&
                props.networkGroup.participant.length > 1 && (
                  <FavoriteGroupPreview
                    first_name={props.networkGroup.participant[1].first_name}
                    last_name={props.networkGroup.participant[1].last_name}
                    overlap
                    src={
                      props.networkGroup.participant[1].avatar_id
                        ? `https://files.pengueen.de/api/download/${props.networkGroup.participant[1].avatar_id?.path}`
                        : pengueen
                    }
                  />
                )}
              {props.networkGroup.participant &&
                props.networkGroup.participant.length === 3 && (
                  <FavoriteGroupPreview
                    first_name={props.networkGroup.participant[2].first_name}
                    last_name={props.networkGroup.participant[2].last_name}
                    overlap
                    src={
                      props.networkGroup.participant[2].avatar_id
                        ? `https://files.pengueen.de/api/download/${props.networkGroup.participant[2].avatar_id?.path}`
                        : pengueen
                    }
                  />
                )}
              {props.networkGroup.participant &&
                props.networkGroup.participant.length > 3 && (
                  <li>
                    <Box
                      sx={{
                        backgroundColor: '#444d58',
                        borderColor: 'background.paper',
                        color: 'white',
                      }}
                      className={styles['favorite-group-users-preview-more']}
                    >
                      {`+ ${props.networkGroup.participant.length - 2}`}
                    </Box>
                  </li>
                )}
            </ul>
          </Box>

          <div className={styles['favorite-group-name']}>
            <Box className={styles['favorite-group-name-content']}>
              {t('community.tools.visibility.group')}
            </Box>
            <Box className={styles['favorite-group-name-content']}>
              {props.networkGroup.name}
            </Box>
            {props.networkGroup?.community_id?.logo && (
              <img
                alt={props.networkGroup?.community_id?.logo.filename}
                className={styles['favorite-community-images-community']}
                src={`https://files.pengueen.de/api/download/${props.networkGroup?.community_id?.logo.path}`}
              />
            )}
          </div>
        </Button>
      )}
      {props.community && (
        <Button
          className={clsx(styles['favorite'], styles['favorite-group'])}
          color="inherit"
          sx={{
            backgroundColor: 'background.paper',
            borderColor: 'border.app',
          }}
          onClick={onNavigate}
        >
          <Box
            className={styles['favorite-icon']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <Icon
              icon={icon}
              sx={{ color: 'text.secondary', padding: '2px' }}
            />
          </Box>
          {unreadMsg && (
            <Box
              className={styles['favorite-unread']}
              sx={{ backgroundColor: 'success.main', color: 'white' }}
            >
              {unreadMsg}
            </Box>
          )}
          <Box
            className={styles['favorite-group-users']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            {props.community?.logo && (
              <img
                alt={props.community?.logo.filename}
                className={styles['favorite-group-users-preview']}
                src={`https://files.pengueen.de/api/download/${props.community?.logo.path}`}
              />
            )}
            {!props.community?.logo && (
              <Icon
                icon={['fal', 'building']}
                classes={styles['favorite-group-users-icon']}
                sx={{ color: 'text.secondary', padding: '2px' }}
              />
            )}
          </Box>
          <div className={styles['favorite-group-name']}>
            <Box className={styles['favorite-group-name-content']}>
              {t('contacts.community.title')}
            </Box>
            <Box className={styles['favorite-group-name-content']}>
              {`${props.community.name}${
                communityType ? `- ${communityType}` : ''
              }`}
            </Box>
          </div>
        </Button>
      )}
    </Box>
  );
};

type FavoriteCommunityProps = {
  community: CommunityEntity;
  for_commuity?: boolean;
  for_admin?: boolean;
  for_employee?: boolean;
  for_member?: boolean;
};

const FavoriteCommunity = (props: FavoriteCommunityProps) => {
  return (
    <>
      {props.for_commuity && (
        <FavoriteGroupUser community={props.community} for_commuity />
      )}
      {props.for_admin && (
        <FavoriteGroupUser community={props.community} for_admin />
      )}
      {props.for_employee && (
        <FavoriteGroupUser community={props.community} for_employee />
      )}
      {props.for_member && (
        <FavoriteGroupUser community={props.community} for_member />
      )}
    </>
  );
};

// type WidgetFavoriteGroupsUsersProps = {
// groupsUsers: Favorite[];
// };

export const WidgetFavoriteGroupsUsers = () =>
  // props: WidgetFavoriteGroupsUsersProps
  {
    const { t } = useTranslation();
    const { handleRetry } = useFetch();
    const { favariteContactsGet } = useDashboardHttp();

    // User store state
    const [account] = useUserStore((state) => [state.account]);

    // Shared store state
    const [setDialogContacts] = useSharedStore((state: SharedState) => [
      state.setDialogContacts,
    ]);

    // Component state
    const [favoriteGroupsUsers, setFavoriteGroupsUsers] = useState<
      Favorite | undefined
    >(undefined);
    const [hasFavorites, setHasFavorites] = useState<boolean>(true);

    // ######### //
    // MUTATIONS //
    // ######### //

    const favariteContactsGetMutation = useMutation(
      () => favariteContactsGet(),
      {
        retry: (failureCount, error: any) => handleRetry(failureCount, error),
        onSettled: (data, error) => {
          if (data) {
            const favorites = data;
            if (favorites !== favoriteGroupsUsers) {
              setFavoriteGroupsUsers(favorites);

              if (account) {
                localStorage.setItem(
                  `__store_favorite_contacts_${account?.id}`,
                  JSON.stringify(favorites)
                );
              }
            }
            let countFavorites =
              (favorites.groups?.length ?? 0) +
              (favorites.users?.length ?? 0) +
              (favorites.network?.communities?.length ?? 0) +
              (favorites.network?.groups?.length ?? 0) +
              (favorites.network?.folders?.length ?? 0) +
              (favorites.network?.links?.length ?? 0);

            // Change has Favorites if it necessary
            if (
              hasFavorites &&
              (countFavorites === 0 || Number.isNaN(countFavorites))
            ) {
              setHasFavorites(false);
            } else if (!hasFavorites && countFavorites > 0) {
              setHasFavorites(true);
            }
          }
          if (error) {
            setHasFavorites(false);
          }
        },
      }
    );

    // ####### //
    // EFFECTS //
    // ####### //

    useEffect(() => {
      const savedTools = localStorage.getItem(
        `__store_favorite_contacts_${account?.id}`
      );
      if (savedTools) {
        setFavoriteGroupsUsers(JSON.parse(savedTools));
        // Update favorite tool after 1 minute
        setTimeout(() => favariteContactsGetMutation.mutate(), 60000);
      } else {
        favariteContactsGetMutation.mutate();
      }

      return () => {
        setFavoriteGroupsUsers(undefined);
      };
      // eslint-disable-next-line
    }, []);

    return (
      <div className={styles['widget-favorite-groups-users']}>
        {favoriteGroupsUsers && hasFavorites ? (
          <Box className="flex flex-row flex-wrap w-full">
            {/* <Box className={styles['widget-favorite-groups-users-container']}> */}
            {favoriteGroupsUsers.groups?.map((group) => {
              return <FavoriteGroupUser key={group.id} group={group} />;
            })}
            {favoriteGroupsUsers.users?.map((user) => {
              return <FavoriteGroupUser key={user.id} user={user} />;
            })}
            {favoriteGroupsUsers.network?.communities?.map((favo) => {
              return (
                <FavoriteCommunity
                  key={favo.community.id}
                  community={favo.community}
                  for_commuity={favo.for_commuity}
                  for_admin={favo.for_admin}
                  for_employee={favo.for_employee}
                  for_member={favo.for_member}
                />
              );
            })}
            {favoriteGroupsUsers.network?.groups?.map((group) => {
              return <FavoriteGroupUser key={group.id} networkGroup={group} />;
            })}
          </Box>
        ) : (
          <div className={styles['widget-favorite-groups-users-empty']}>
            <Box
              className={styles['widget-favorite-groups-users-empty-paragraph']}
              sx={{ color: 'text.secondary' }}
            >
              {t('dashboard.widgets.favorite_users.empty.text')}
            </Box>
            <Box
              className={
                styles['widget-favorite-groups-users-empty-image-container']
              }
            >
              <img
                alt="widget-favorite-groups-users-empty-image"
                className={styles['widget-favorite-groups-users-empty-image']}
                src={favoriten_markieren}
                onClick={() => setDialogContacts(true)}
              />
            </Box>
          </div>
        )}
      </div>
    );
  };
