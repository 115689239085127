import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  TemplateElement,
  TemplateElementContactType,
  TemplateElementType,
} from '../../modules/templates/models/templates.types';
import ToolElementContact from '../../modules/tools/components/ToolElementContact/ToolElementContact';
import ToolElementDatePicker from '../../modules/tools/components/ToolElementDatePicker/ToolElementDatePicker';
import ToolElementInfo from '../../modules/tools/components/ToolElementInfo/ToolElementInfo';
import ToolElementSelect from '../../modules/tools/components/ToolElementSelect/ToolElementSelect';
import ToolElementToggle from '../../modules/tools/components/ToolElementToggle/ToolElementToggle';
import ToolElementUpload from '../../modules/tools/components/ToolElementUpload/ToolElementUpload';
import { ToolElementVoteGrade } from '../../modules/tools/components/ToolElementVoteGrade/ToolElementVoteGrade';
import { ToolElementVoteStar } from '../../modules/tools/components/ToolElementVoteStar/ToolElementVoteStar';
import { KanbanDto, KanbanInfo } from '../../modules/tools/models/tools.types';
import { elementValueGet } from '../../modules/tools/utils/tools.utils';
import RadioButtonGroup from '../../shared/ui/RadioButtonGroup/RadioButtonGroup';
import { ToolElementHeadline } from './components/ToolElementHeadline';
import ToolElementInput from './components/ToolElementInput';
import ToolElementTextarea from './components/ToolElementTextarea';
import ToolElemetnCheckbox from './components/ToolElemetnCheckbox';
import ToolMultiElement from './components/ToolMultiElement';

type ElementProps = {
  elem: TemplateElement;
  sectionId: string;
  preview?: boolean;
  toolChat?: boolean;
  toolId: string;
  toolName: string;
  isAdmin: boolean;
  kanbanInfo?: KanbanInfo[];
  // onToolFieldChange: (
  //   sectionId: string,
  //   elem: any,
  //   value: any,
  //   flag: boolean
  // ) => void;
  // nextElemHalf?: boolean;
};

const ToolElementRenderer = ({
  elem,
  sectionId,
  preview,
  toolChat,
  toolId,
  toolName,
  isAdmin,
  kanbanInfo,
}: ElementProps) => {
  var nextElemHalf = false;
  const { t } = useTranslation();
  const onToolFieldChange = React.useCallback(
    (
      sectionId: string,
      templateElement: TemplateElement,
      value: any,
      save?: boolean
    ) => {},
    []
  );

  const onToolInputCancel = React.useCallback(
    (sectionId: string, field: string, value: string) => {},
    []
  );

  const primaryProps = {
    classes: nextElemHalf ? 'w-1/2 inline-grid float-left' : '',
    help_text: elem.help_text,
    label: elem.name,
    sublabel: elem.description === '<p></p>\n' ? undefined : elem.description,
  };

  const commonProps: any = {
    ...primaryProps,
    disabled: elem.can_edit ? false : true,
    required: elem.settings?.advanced?.required,
    value: preview ? undefined : elem.value,
    onChange: (value: any) =>
      !preview && onToolFieldChange(sectionId, elem, value, true),
    onSave: (value: any) => onToolFieldChange(sectionId, elem, value, true),
    onCancel: (value: any) => onToolInputCancel(sectionId, elem.id, value),
  };

  const optionProps = {
    options: elem.options || [],
  };

  switch (elem.type) {
    case TemplateElementType.EMail:
    case TemplateElementType.Input:
    case TemplateElementType.Number:
    case TemplateElementType.Telephone:
    case TemplateElementType.Url:
      return (
        <ToolElementInput
          {...commonProps}
          type={elem.type}
          // initValue={elementValueGet(formValuesInitial, sectionId, elem.id)}
        />
      );
    case TemplateElementType.AutoId:
      return null;
    case TemplateElementType.Checkbox:
      return <ToolElemetnCheckbox {...commonProps} {...optionProps} />;
    case TemplateElementType.Contact:
      return (
        <ToolElementContact
          {...commonProps}
          type={
            elem.settings?.advanced?.contact_card
              ? TemplateElementContactType.Card
              : TemplateElementContactType.Basic
          }
          onDelete={() => onToolFieldChange(sectionId, elem, undefined, true)}
        />
      );
    case TemplateElementType.DataElementFromOtherTool:
    case TemplateElementType.DataFromOtherTool:
    case TemplateElementType.DataFromOtherToolContact:
    case TemplateElementType.DataFromOtherToolGroup:
    case TemplateElementType.DatePicker:
    case TemplateElementType.Day:
    case TemplateElementType.Decision:
    case TemplateElementType.Duration:
      return null;
    case TemplateElementType.Grade:
      return <ToolElementVoteGrade {...commonProps} />;
    case TemplateElementType.Group:
      return null;
    case TemplateElementType.Headline:
      return <ToolElementHeadline {...primaryProps} />;
    case TemplateElementType.Image:
      return null;
    case TemplateElementType.Info:
      return <ToolElementInfo {...commonProps} />;
    case TemplateElementType.Map:
      return null;
    case TemplateElementType.MultiElement:
      const multiElementProps = {
        element: elem,
        sectionId,
        toolChat,
        toolId,
        toolName,
        isAdmin,
        kanban: kanbanInfo?.find((mfe: KanbanInfo) => mfe.mfeid === elem.id)
          ?.kanban,
      };
      return <ToolMultiElement {...commonProps} {...multiElementProps} />;
    case TemplateElementType.MultiElementCreateRow:
    case TemplateElementType.PersonFieldWithDataFromOtherTool:
      return null;
    case TemplateElementType.RadioButton:
      return <RadioButtonGroup {...commonProps} {...optionProps} />;
    case TemplateElementType.Reminder:
      return null;
    case TemplateElementType.Select:
      return <ToolElementSelect {...commonProps} {...optionProps} />;
    case TemplateElementType.Star:
      return <ToolElementVoteStar {...commonProps} />;
    case TemplateElementType.Textarea:
      return <ToolElementTextarea {...commonProps} />;
    case TemplateElementType.Time:
      return <ToolElementDatePicker {...commonProps} />;
    case TemplateElementType.Toggle:
      return <ToolElementToggle {...commonProps} />;
    case TemplateElementType.Upload:
      return <ToolElementUpload {...commonProps} />;
    case TemplateElementType.User:
      return null;
    default:
      return null;
  }
};

export default React.memo(ToolElementRenderer);
