import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { Box, Button, Divider, Drawer } from '@mui/material';
import clsx from 'clsx';

// Components
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { FormInviteIndirect } from '../../../../shared/components/FormInviteIndirect/FormInviteIndirect';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { Loader } from '../../../../shared/ui/Loader/Loader';

// Hooks
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';
import { useContactsMock } from '../../../contacts/hooks/use-contacts-mock.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useNewsCenterHttp } from '../../../newscenter/hooks/use-news-center-http.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';
// Models
import {
  ActiveState,
  ConnectionType,
  Contact,
  ContactsInvitePostRequest,
  ContactsViewType,
} from '../../../contacts/models/contacts.types';
import {
  RoomRequest,
  RoomType,
} from '../../../newscenter/models/news-center.types';
import { ResultState } from '../../../../shared/models/shared.types';
import {
  TemplateElementContactType,
  TemplateElementSettings,
} from '../../../templates/models/templates.types';

// Stores
import { useSharedStore } from '../../../../shared/stores/use-shared.store';

// Styles
import styles from './ToolElementContact.module.scss';
import {
  ColumnContactType,
  ToolValuesMultiElementRow,
} from '../../models/tools.types';
import NewsCenterChat from '../../../newscenter/components/NewsCenterChat/NewsCenterChat';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';
import { ToolsState, useToolsStore } from '../../stores/use-tools.store';
import ToolContactModal from '../ToolContactModal/ToolContactModal';

// Lazy-load components
const ContactsContent = lazy(
  () => import('../../../contacts/components/ContactsContent/ContactsContent')
);

type ContactCreatorProps = {
  contact: Contact;
  disabled?: boolean;
  fullName: string | TFunctionResult;
  multiElementChild?: boolean;
  type?: string;
  onMessage: () => void;
  onSelect: () => void;
};

export const ContactCreator = (props: ContactCreatorProps) => {
  return (
    <div className={clsx(styles['contact-creator'])}>
      <Box
        className={
          props.type === 'READ'
            ? styles['contact-creator-read']
            : styles['contact-creator-info']
        }
      >
        {props.fullName}
      </Box>
    </div>
  );
};

type ContactBasicProps = {
  readonly?: boolean;
  contact: Contact;
  disabled?: boolean;
  fullName: string | TFunctionResult;
  multiElementChild?: boolean;
  onMessage: () => void;
  onSelect: () => void;
  onDelete: () => void;
};

export const ContactBasic = (props: ContactBasicProps) => {
  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);

  return (
    <div
      className={clsx(
        styles['contact-basic'],
        !props.multiElementChild && styles['contact-basic-margin']
      )}
    >
      <Box
        className={styles['contact-basic-info']}
        sx={{ backgroundColor: 'bg.card' }}
      >
        <div className={styles['contact-basic-info-data']}>
          {props.contact.avatar?.url ? (
            <img
              alt={props.fullName?.toString()}
              className={styles['contact-basic-info-data-image']}
              src={props.contact.avatar?.url}
            />
          ) : (
            <ImageFallback
              sizeClassName={styles['contact-basic-info-data-image']}
            />
          )}
          <div className={styles['contact-basic-info-data-name']}>
            {props.fullName}
          </div>
        </div>
      </Box>
      {!props.readonly && (
        <>
          {!props.multiElementChild && (
            <IconButton
              classes={styles['contact-basic-button']}
              disabled={props.disabled}
              icon={['fas', 'pen']}
              preset="secondary"
              onClick={props.onSelect}
            />
          )}
          {!props.multiElementChild && (
            <IconButton
              classes={styles['contact-basic-button']}
              disabled={props.disabled}
              icon={['fas', 'trash']}
              preset="secondary"
              onClick={props.onDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

type ContactCardProps = {
  contact: Contact;
  disabled?: boolean;
  fullName: string | TFunctionResult;
  onMessage: () => void;
  onSelect: () => void;
  onDelete: () => void;
};

const ContactCard = (props: ContactCardProps) => {
  const { t } = useTranslation();

  const [canShowEmail, setCanShowEmail] = useState<boolean>(true);

  useEffect(() => {
    switch (props.contact.state) {
      case ActiveState.Indirect:
      case ActiveState.Active:
      case ActiveState.ToolLink:
        setCanShowEmail(true);
        break;
      default:
        setCanShowEmail(false);
        break;
    }
  }, [props.contact.state]);

  return (
    <Box
      className={styles['contact-card']}
      sx={{ borderColor: 'pengueen.gray' }}
    >
      <div className={styles['contact-card-header']}>
        <div className={styles['contact-card-header-profile']}>
          <span className={styles['contact-card-header-profile-name']}>
            {props.fullName}
          </span>
          {props.contact.email &&
            canShowEmail &&
            !props.contact.email.includes('ghostAccount_') && (
              <span>{props.contact.email}</span>
            )}
          {props.contact.phone && <span>{props.contact.phone}</span>}
        </div>
        {props.contact.avatar?.url ? (
          <img
            alt={props.fullName?.toString()}
            className={styles['contact-card-header-image']}
            src={props.contact.avatar?.url}
          />
        ) : (
          <ImageFallback sizeClassName={styles['contact-card-header-image']} />
        )}
      </div>
      <div className={styles['contact-card-footer']}>
        <div className={styles['contact-card-footer-address']}>
          {((props.contact.address?.street &&
            props.contact.address?.house_number) ||
            (props.contact.address?.zip_code &&
              props.contact.address.place)) && (
            <span>{t('user.profile.form.address')}:</span>
          )}
          {props.contact.address?.street &&
            props.contact.address?.house_number && (
              <span>{`${props.contact.address.street} ${props.contact.address.house_number}`}</span>
            )}
          {props.contact.address?.zip_code && props.contact.address.place && (
            <span>{`${props.contact.address.zip_code} ${props.contact.address.place}`}</span>
          )}
        </div>
        <div className={styles['contact-card-footer-buttons']}>
          <IconButton
            classes={styles['contact-card-footer-buttons-item']}
            disabled={props.disabled}
            icon={['fas', 'pen']}
            preset="secondary"
            onClick={props.onSelect}
          />
          <IconButton
            classes={styles['contact-basic-button']}
            disabled={props.disabled}
            icon={['fas', 'trash']}
            preset="secondary"
            onClick={props.onDelete}
          />
        </div>
      </div>
    </Box>
  );
};

type ToolElementContactProps = {
  readonly?: boolean;
  classes?: string;
  disabled?: boolean;
  label?: string;
  sublabel?: string | TFunctionResult;
  help_text?: string;
  multiElementChild?: boolean;
  type: TemplateElementContactType;
  value?: ColumnContactType;
  values?: ToolValuesMultiElementRow;
  required?: boolean;
  settings?: TemplateElementSettings | undefined;
  onChange: (value: string) => void;
  onDelete: (value: string) => void;
};

export const ToolElementContact = (props: ToolElementContactProps) => {
  const { contactsInvitePost, contactGet } = useContactsHttp();
  const { contactMockGet } = useContactsMock();
  const { handleError, handleRetry } = useFetch();
  const navigate = useNavigate();
  const { directRoomPost } = useNewsCenterHttp();
  const { fullNameGet } = useShared();
  const { t } = useTranslation();

  // Component state
  const [contact, setContact] = useState<Contact | undefined>(undefined);
  const [drawer, setDrawer] = useState<boolean>(false);
  const [allreadySet, setAllreadySet] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string | undefined>(undefined);
  const [dialogContactSelect, setDialogContactSelect] =
    useState<boolean>(false);
  const [fullName, setFullName] = useState<string | TFunctionResult>('');
  const [selectedContactIndirectToInvite, setSelectedContactIndirectToInvite] =
    useState<Contact | undefined>(undefined);

  // User store state
  const [account, profile] = useUserStore((state: UserState) => [
    state.account,
    state.profile,
  ]);

  const [tool] = useToolsStore((state: ToolsState) => [state.tool]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET contact mutation
  const contactGetMutation = useMutation(
    (id: string) => contactGet({ id: id }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data, error) {
        if (data) {
          setContact(data as any);
          setFullName(
            fullNameGet(data.first_name, data.last_name, data.nickname)
          );
        }
        if (error) {
          const errRes = error?.response;
          // Check if error contains fetch response object
          if (errRes) {
            // Set view error by response status
            // setViewError(handleError(errRes.status));
            setContact(contactMockGet());
            console.error('Error getting contact:', handleError(errRes.status));
          }
        }
      },
    }
  );

  // POST Direct room mutation
  const directRoomPostMutation = useMutation(
    (data: RoomRequest) => directRoomPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data, error) {
        if (data) {
          try {
            if (
              props.type === TemplateElementContactType.Creator ||
              props.type === TemplateElementContactType.CreatorRead
            ) {
              setDrawer(true);
              setRoomId(data.id);
            } else {
              navigate(`/newscenter/private/${data.id}`);
            }
          } catch (error) {
            console.log('ERROR on creating direct room:', error);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
          }
        }
      },
    }
  );

  // POST Contact invite mutation
  const invitePostMutation = useMutation((data: ContactsInvitePostRequest) =>
    contactsInvitePost(data)
  );

  // Add contact to store
  useEffect(() => {
    if (invitePostMutation.data) {
      try {
        setNotification({
          title: t('app.users.invite.success'),
          state: ResultState.Success,
        });
        setSelectedContactIndirectToInvite(undefined);
      } catch (error) {
        console.log('ERROR on inviting contact:', error);
      }
    }
    if (invitePostMutation.error) {
      setNotification({
        title: `${t('app.users.invite.error')} `,
        state: ResultState.Error,
      });
    }
    // eslint-disable-next-line
  }, [invitePostMutation.data, invitePostMutation.error]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Get contact by id
  useEffect(() => {
    if (
      props.settings?.advanced?.only_admin_can_edit &&
      props.value &&
      !allreadySet &&
      !props.values
    ) {
      props.value.id && props.onChange(props.value.id);
      setAllreadySet(true);
    } else if (props.value) {
      props.value && contactGetMutation.mutate(props.value.id);
    }

    // eslint-disable-next-line
  }, [props.settings, props.value, props.values]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to invite indirect contact.
   */
  const onInviteIndirect = useCallback(() => {
    contact?.email &&
      invitePostMutation.mutate({
        contacts: [{ email: contact.email }],
      });
    // eslint-disable-next-line
  }, [contact]);

  /**
   * Handler to continue / start direct chat with direct contact or invite indirect contact.
   */
  const onMessage = useCallback(() => {
    if (
      contact &&
      (props.type === TemplateElementContactType.Creator ||
        props.type === TemplateElementContactType.CreatorRead)
    ) {
      if (contact.direct_chat_id) {
        setDrawer(true);
        setRoomId(contact.direct_chat_id);
      } else {
        directRoomPostMutation.mutate({
          participant: [contact.id],
          type: RoomType.Direct,
        });
      }
    } else if (contact && contact.connection === ConnectionType.Direct) {
      if (contact.direct_chat_id) {
        navigate(`/newscenter/private/${contact.direct_chat_id}`);
      } else {
        directRoomPostMutation.mutate({
          participant: [contact.id],
          type: RoomType.Direct,
        });
      }
    } else if (contact && contact.connection === ConnectionType.Indirect) {
      setSelectedContactIndirectToInvite(contact);
    }

    // eslint-disable-next-line
  }, [contact, props.type]);

  /**
   * Handler to select another contact.
   * @param selectedContact Contact
   */
  const onSelect = useCallback(
    (selectedContact: Contact) => {
      // Set contact
      selectedContact.id &&
        selectedContact.connection &&
        selectedContact.state &&
        setContact(selectedContact);

      // Set fullname
      selectedContact &&
        setFullName(
          fullNameGet(
            selectedContact.first_name,
            selectedContact.last_name,
            selectedContact.nickname
          )
        );

      selectedContact.id && props.onChange(selectedContact.id);
      setDialogContactSelect(false);
    },
    [props]
  );

  const onDelete = useCallback(() => {
    contact?.id && props.onDelete(contact.id);
    setContact(undefined);
    setAllreadySet(false);
  }, [props.onDelete, contact]);

  return (
    <div
      className={clsx(
        styles['tool-element-contact'],
        props.classes && props.classes
      )}
    >
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          paddingClassName={styles['tool-element-contact-label-padding']}
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
      {!contact && contactGetMutation.isLoading && (
        <Loader classes={styles['tool-element-contact-loading']} />
      )}
      {!contact &&
        !contactGetMutation.isLoading &&
        props.type !== TemplateElementContactType.CreatorRead &&
        props.type !== TemplateElementContactType.MultiTable && (
          // <Box></Box>
          <Button
            className={clsx(styles['tool-element-contact-button-empty'])}
            color="inherit"
            disabled={props.disabled || props.readonly}
            sx={{
              // color: props.disabled ? 'disabled !important' : 'text.secondary',
              color: 'text.secondary',
              backgroundColor: 'bg.card',
              '&:hover': { backgroundColor: 'bg.card' },
              '&.Mui-disabled': {
                color: 'text.disabled',
              },
            }}
            onClick={() => setDialogContactSelect(true)}
          >
            {t('tools.detail.elements.choose')}
          </Button>
        )}
      {!contact &&
        !contactGetMutation.isLoading &&
        props.type === TemplateElementContactType.CreatorRead && (
          <div className="p-2">
            {fullNameGet(
              profile?.personal_data.first_name,
              profile?.personal_data.last_name
            )}
          </div>
        )}
      {contact &&
        (props.type === TemplateElementContactType.Basic ||
          props.type === TemplateElementContactType.MultiTable) && (
          <ContactBasic
            readonly={props.readonly}
            contact={contact}
            disabled={props.disabled}
            fullName={fullName}
            multiElementChild={props.multiElementChild}
            onMessage={onMessage}
            onDelete={onDelete}
            onSelect={() => setDialogContactSelect(true)}
          />
        )}
      {contact && props.type === TemplateElementContactType.Card && (
        <ContactCard
          contact={contact}
          disabled={props.disabled}
          fullName={fullName}
          onMessage={onMessage}
          onDelete={onDelete}
          onSelect={() => setDialogContactSelect(true)}
        />
      )}
      {contact && props.type === TemplateElementContactType.Creator && (
        <ContactCreator
          contact={contact}
          disabled={props.disabled || contact.id === account?.id}
          fullName={fullName}
          onMessage={onMessage}
          onSelect={() => setDialogContactSelect(true)}
        />
      )}
      {contact && props.type === TemplateElementContactType.CreatorRead && (
        <ContactCreator
          type="READ"
          contact={contact}
          disabled={props.disabled || contact.id === account?.id}
          fullName={fullName}
          onMessage={onMessage}
          onSelect={() => setDialogContactSelect(true)}
        />
      )}
      <Dialog
        classes={styles['contact-select']}
        open={dialogContactSelect}
        title={t('tools.detail.elements.contact')}
        onClose={() => setDialogContactSelect(false)}
      >
        <>
          <ToolContactModal
            toolid={tool?.id ?? ''}
            onDirectContactClick={onSelect}
          />
        </>
      </Dialog>
      <Dialog
        open={selectedContactIndirectToInvite ? true : false}
        subtitle={`${t('app.users.invite.subtitle1')} ${fullName} ${t(
          'app.users.invite.subtitle2'
        )} ${fullName} ${t('app.users.invite.subtitle3')}`}
        title={t('app.users.invite.title')}
        onClose={() => setSelectedContactIndirectToInvite(undefined)}
      >
        <FormInviteIndirect
          onClose={() => setSelectedContactIndirectToInvite(undefined)}
          onSubmit={onInviteIndirect}
        />
      </Dialog>
      <Drawer
        anchor="right"
        classes={{
          paper: styles['tool-contact-chat'],
        }}
        open={drawer}
        onClose={() => setDrawer(false)}
      >
        <NewsCenterChat type={RoomType.ToolChatContact} roomId={roomId} />
      </Drawer>
    </div>
  );
};

export default memo(ToolElementContact);
