// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolMultiElementTile_simple-gallery-image__Lg4lH {\n\n  width: 50%;\n\n  flex-shrink: 0 }\n\n@media (min-width: 768px) {\n  .ToolMultiElementTile_simple-gallery-image__Lg4lH {\n\n    width: 33.333333% } }\n\n@media (min-width: 1024px) {\n  .ToolMultiElementTile_simple-gallery-image__Lg4lH {\n\n    width: 25% } }\n\n@media (min-width: 1280px) {\n  .ToolMultiElementTile_simple-gallery-image__Lg4lH {\n\n    width: 20% } }\n", "",{"version":3,"sources":["<no source>","webpack://./src/modules/tools/components/ToolMultiElementTile/ToolMultiElementTile.module.scss"],"names":[],"mappings":"AAAA;;EAAA,WAAA;;EAAA,gBAAA;;ACIA;EDJA;;IAAA,mBAAA,CCOG;;AAEH;EDTA;;IAAA,YAAA,CCYG;;AAEH;EDdA;;IAAA,YAAA,CCiBG","sourcesContent":[null,"@import '../../../../styles/breakpoints.scss';\r\n.simple-gallery-image {\r\n  @apply flex-shrink-0 w-1/2;\r\n}\r\n@media (min-width: $md) {\r\n  .simple-gallery-image {\r\n    @apply w-1/3;\r\n  }\r\n}\r\n@media (min-width: $lg) {\r\n  .simple-gallery-image {\r\n    @apply w-1/4;\r\n  }\r\n}\r\n@media (min-width: $xl) {\r\n  .simple-gallery-image {\r\n    @apply w-1/5;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"simple-gallery-image": "ToolMultiElementTile_simple-gallery-image__Lg4lH"
};
export default ___CSS_LOADER_EXPORT___;
