import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { Box, MenuItem, Select as SelectMui } from '@mui/material';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';

// Models
import { Option, Theme } from '../../../../shared/models/shared.types';

// Styles
import styles from './ToolElementSelect.module.scss';

// Utils
import { selectOptionColorGet } from '../../utils/tools.utils';
import {
  SharedState,
  useSharedStore,
} from '../../../../shared/stores/use-shared.store';

const CustomExpand = () => {
  return <div></div>;
};

type ToolElementSelectProps = {
  classes?: string;
  disabled?: boolean;
  label?: TFunctionResult | string;
  sublabel?: string | TFunctionResult;
  help_text?: string;
  options: Option[];
  value: any;
  width?: string;
  widthClassName?: string;
  required?: boolean;
  single_select?: boolean;
  onChange: (value: any) => void;
};

export const ToolElementSelect = (props: ToolElementSelectProps) => {
  const { t } = useTranslation();

  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  // Component state
  const [color, setColor] = useState<string | undefined>(undefined);
  const [value, setValue] = useState<string[]>(['']);

  // Set value
  useEffect(() => {
    // Convert the previous value to an array, because the select has been changed to multiple
    if (props.value === undefined) {
      setValue(['']);
    } else if (!Array.isArray(props.value)) {
      setValue([props.value]);
    } else {
      setValue(props.value);
    }

  // eslint-disable-next-line
  }, [props.options, props.value]);

  // Set value color by select options
  useEffect(() => {
    setColor(selectOptionColorGet(props.options, value, theme));
  // eslint-disable-next-line
  }, [value, theme]);

  return (
    <Box
      sx={{
        width: props.width,
        '& .MuiOutlinedInput-root': {
          color: 'text.secondary',
          // backgroundColor: color ?? 'bg.card',
          backgroundImage: `linear-gradient(to right, ${color} 0.75rem, ${
            theme === Theme.Dark ? '#2b2b2b' : '#f3f6f9'
          } 0.75rem)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          // transitionProperty: 'background-color',
          // transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          // transitionDuration: '150ms',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 0,
          },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '& .MuiSelect-select': {
          fontSize: '14px',
          paddingBottom: '0.5rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem !important',
          paddingTop: '0.75rem',
          minWidth: '240px',
        },
      }}
      className={clsx(
        styles['tool-element-select'],
        props.classes && props.classes,
        props.widthClassName
          ? props.widthClassName
          : props.width
          ? undefined
          : 'w-full'
      )}
    >
      <FormFieldLabel
        required={props.required}
        label={props.label ?? 'Dropdown'}
        sublabel={props.sublabel}
        help_text={props.help_text}
        paddingClassName={
          styles['tool-element-select-form-field-label-padding']
        }
      />
      <SelectMui
        disabled={props.disabled}
        displayEmpty
        IconComponent={CustomExpand}
        MenuProps={{
          classes: {
            list: styles['tool-element-select-list'],
          },
          sx: {
            '& .Mui-selected': {
              backgroundColor: color && color,
              color: 'text.primary',
              '&:hover': {
                backgroundColor: !props.disabled ? 'bg.card' : undefined,
                color: !props.disabled ? 'text.primary' : undefined,
              },
            },
            '& .MuiMenuItem-root': {
              borderRadius: '0.375rem',
              minWidth: '240px',
            },
            '& .MuiMenuItem-root:not(:last-of-type)': {
              marginBottom: '4px',
            },
          },
        }}
        multiple={props.single_select ? false : true}
        sx={{
          marginLeft: props.value !== undefined && color ? '0.5rem' : 0,
          paddingLeft: props.value !== undefined && color ? '0.5rem' : 0,
          color:
            props.value === undefined
              ? 'text.secondary'
              : color
              ? 'white'
              : 'text.primary',
        }}
        value={value ?? ['']}
        onChange={(event) => {
          let selectedValues = event.target.value;

          if (Array.isArray(selectedValues)) {
            // Remove default value as placeholder
            selectedValues = selectedValues.filter(
              (value: string) => value !== ''
            );
            // If selectedValues is empty array
            if (!selectedValues.length) {
              selectedValues = [''];
            }
          }
          setValue(
            Array.isArray(selectedValues) ? selectedValues : [selectedValues]
          );
          props.onChange(selectedValues);
        }}
      >
        {/* {props.options[0].value !== undefined && props.value === undefined && (
          <Box
            className={styles['tool-element-select-choose']}
            sx={{ color: 'text.secondary' }}
          >
            {t('tools.detail.elements.choose')}
          </Box>
        )} */}
        <MenuItem
          value=""
          sx={{
            color: 'text.secondary',
          }}
          className={styles['tool-element-select-option']}
        >
          <em className="not-italic">{t('tools.detail.elements.choose')}</em>
        </MenuItem>
        {props.options.map((option, index: number) => (
          <MenuItem
            key={index}
            value={option.value}
            sx={{
              backgroundImage: `linear-gradient(to right, ${
                option.color ?? ''
              } 0.75rem, ${
                theme === Theme.Dark ? '#202020' : 'white'
              } 0.75rem)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              color:
                option.value === undefined ? 'text.secondary' : 'text.primary',
              '&:hover': {
                backgroundColor: !props.disabled ? 'bg.card' : undefined,
                color: !props.disabled ? 'text.primary' : undefined,
              },
            }}
            className={styles['tool-element-select-option']}
          >
            {option.placeholder}
          </MenuItem>
        ))}
      </SelectMui>
    </Box>
  );
};

export default memo(ToolElementSelect);
