import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// Models
import {
  Contact,
  ContactsViewType,
} from '../../contacts/models/contacts.types';
import { Group } from '../../dashboard/models/dashboard.types';
import { Template } from '../../templates/models/templates.types';
import {
  Address,
  Attachment,
  Avatar,
} from '../../../shared/models/shared.types';
import {
  Account,
  CommunityContact,
  User,
  UserEntity,
} from '../../user/models/user.types';

export enum CommunityItemAction {
  Demote = 'DEMOTE',
  Message = 'MESSAGE',
  Remove = 'REMOVE',
  View = 'VIEW',
}

export enum CommunitySector {
  Care = 'CARE',
  Club = 'CLUB',
  Coaching = 'COACHING',
  Community = 'COMMUNITY',
  Company = 'COMPANY',
  Craft = 'CRAFT',
  Network = 'NETWORK',
  Other = 'OTHER',
  Project = 'PROJECT',
  School = 'SCHOOL',
}

export enum CommunityUserRole {
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  Member = 'MEMBER',
}

export enum DataDisplay {
  Gallery = 'GALLERY',
  List = 'LIST',
}

export interface Community {
  contact?: CommunityContact;
  data: CommunityData;
  role: CommunityUserRole;
  changes?: number;
}
// Same as Community Entity in Backend
export interface CommunityEntity {
  id: string;
  address_id: Address;
  contact_id: UserEntity;
  employees: UserEntity[];
  members: UserEntity[];
  admins: UserEntity[];
  logo: Avatar;
  name: string;
  favorite: string;
  widget_text: string;
  sector: CommunitySector[];
  groups: string[];
  // create_change_id: CreateChangeInfo;
  // invoice_address: InvoiceAddress;
  license: License;
  member_string: string;
  employee_string: string;
  link: string;
  // msg_rooms: MessageRoom[];
  // tags: FolderTagsEntity[];
  // template_role_tags: TemplateRoleTags[];
  // permission_slots_connected: ToolSetRoleTags[];
  betreff_einladung_email: string;
  headline_einladung_email: string;
  text_einladung_email: string;
  text_einladung_bestaetigung_email: string;
  betreff_einladung_bestaetigung_email: string;
  show_name_formular_at_registration: boolean;
  admin_link: string;
  employee_link: string;
  member_link: string;
  // tool_permission: ToolPermissions[];
}

export interface CommunityUser {
  id: string;
  avatar?: Attachment;
  email: string;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  role: CommunityUserRole;
}

export interface CommunityToolInformationParams extends GridRenderCellParams {
  information?: string;
}

export interface CommunityUserActionsParams extends GridRenderCellParams {
  type?: ContactsViewType;
  contacts: Contact[];
  memberRoleCommunity?: CommunityUserRole;
  setContacts: (contacts: any[]) => void;
  setSelectedContactId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  // #TODO: id: string --> CommunityUser
  onCommunityUserAdd: (id: string) => void;
}

export interface CommunityData {
  id: string;
  address?: Address;
  creator?: Partial<User>;
  admins?: Account[]; // Array of user ids
  employees?: CommunityUsers;
  groups?: Group[];
  license?: License;
  link?: string;
  logo?: Attachment;
  members?: CommunityUsers;
  employeename?: string;
  membername?: string;
  name: string;
  sector?: string[];
  tools_employees?: {
    tools: Template[];
    tags?: Tag[] | undefined;
  };
  tools_living?: {
    tools: Template[];
    tags?: Tag[] | undefined;
  };
  tools_members?: {
    tools: Template[];
    tags?: Tag[] | undefined;
  };
  widget_text?: string;
  betreff_einladung_email?: string;
  headline_einladung_email?: string;
  text_einladung_email?: string;
  text_einladung_bestaetigung_email?: string;
  betreff_einladung_bestaetigung_email?: string;
  show_name_formular_at_registration?: boolean;
}

export type CommunityInfo = Pick<
  CommunityData,
  | 'id'
  | 'address'
  | 'logo'
  | 'name'
  | 'sector'
  | 'employeename'
  | 'membername'
  | 'link'
  | 'widget_text'
  | 'betreff_einladung_email'
  | 'headline_einladung_email'
  | 'text_einladung_email'
  | 'text_einladung_bestaetigung_email'
  | 'betreff_einladung_bestaetigung_email'
  | 'show_name_formular_at_registration'
>;

export type CommunityLicense = Pick<CommunityData, 'id' | 'license'>;

export interface CommunityNotification {
  community_id: string;
  notification_invite_users: boolean;
  notification_invitation_accepted: boolean;
  notification_registration_link: boolean;
  notification_template_changed: boolean;
  notification_community_owner: boolean;
  notification_admin: boolean;
  notification_employees: boolean;
  notification_following_receiver: boolean;
}

export type LicenseNumberPatchRequest = {
  community_id: string;
  cost: number;
  current_license: number;
  current_team_license: number;
};

export interface CommunityLicenseSummary {
  cost: number;
  payment_date: string;
  current_license: number;
  current_team_license: number;
}

export interface CommunityPostRequest {
  logo?: File;
  name: string;
  sector?: any;
  employeename?: string;
  membername?: string;
}

export interface CommunityUsers {
  name?: string;
  users: CommunityUser[];
}

export interface License {
  id: string;
  address: Address;
  contact: {
    email: string;
    first_name: string;
    last_name: string;
  };
  company: string;
  vat_id: string;
  license_summary: CommunityLicenseSummary;
}

export interface Tag {
  id: string;
  img: string;
  name: string;
}
