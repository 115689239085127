import { Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Change,
  ChangeType,
  ContactChangeType,
  RoomChangeType,
  TemplateChangeType,
  ToolChangeType,
} from '../../../modules/dashboard/models/dashboard.types';
import { Icon } from '../../../shared/ui/Icon/Icon';
import { Loader } from '../../../shared/ui/Loader/Loader';
import dayjs from 'dayjs';
import styles from './Changes.module.scss';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';
import { useNewsCenter } from '../../../modules/newscenter/hooks/use-news-center.hook';

export const Changes = ({
  username,
  changes,
  isLoading,
}: {
  username: string;
  changes: Change[];
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box className="w-full p-4">
      {/* Header */}
      <Box className="flex flex-col mb-4">
        <Box className="flex flex-row items-center font-semibold">
          <Icon icon={['fas', 'hand-wave']} classes="mr-2" /> {t('app.hello')},{' '}
          {username}
        </Box>
        <Box className="flex pl-6">{t('dashboard.header.subtitle')}</Box>
      </Box>

      {/* Content */}
      {/* {
        isLoading ? (
        <Loader />
      ) : (
        changes.map((change) => <ChangeItem key={change.id} change={change} />)
      )} */}
    </Box>
  );
};

const ChangeItem = ({ change }: { change: Change }) => {
  const { t } = useTranslation();
  const {
    onInfoMessageSubmit,
    onMessageSubmit,
    removeHtmlTags,
    translateBoolean,
  } = useNewsCenter();

  const date = `${dayjs(change.create_change_info.created_at).format(
    'L'
  )} ${dayjs(change.create_change_info.created_at).format('LT')}`;

  const [notification, setNotification] = useState<boolean>(true);

  const getChangeType = useCallback((change: Change) => {
    if (change.contact) {
      return {
        change_type: ChangeType.Contact,
        change_type_detail: change.contact?.change_type,
        sender_avatar: change.contact?.user_1_avatar,
      };
    } else if (change.room) {
      return {
        change_type: ChangeType.Room,
        change_type_detail: change.room?.change_type,
        sender_avatar: change.room?.user_1_avatar,
      };
    } else if (change.template) {
      return {
        change_type: ChangeType.Template,
        change_type_detail: change.template?.change_type,
        sender_avatar: change.template?.user_1_avatar,
      };
    } else if (change.tool) {
      return {
        change_type: ChangeType.Tool,
        change_type_detail: change.tool?.change_type,
        sender_avatar: change.tool?.user_1_avatar,
        change_content: '',
      };
    }
  }, []);

  const getChangeContent = useCallback(
    (change: Change, changeTypeDetail: any, date: string) => {
      // const fullName = fullNameGet(
      //   change.create_change_info.created_by.profile?.personal_data.first_name,
      //   change.create_change_info.created_by.profile?.personal_data.last_name
      // );
      const fullName = change.contact?.user_1_name ?? t('app.nameless');
      switch (changeTypeDetail) {
        /******************/
        /* Contact Change */
        /******************/
        case ContactChangeType.Accepted:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted', {
                user1: fullName ?? '',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.AcceptedLink:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted_link', {
                user1: fullName ?? '',
                group: change.contact?.user_2_name ?? 'Gruppenname',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.AcceptedLinkAdmin:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted_link_admin', {
                user1: fullName ?? '',
                community: change.contact?.community?.name ?? 'Comminity',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.AcceptedLinkCommunity:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted_link_community', {
                user1: fullName ?? '',
                community: change.contact?.community?.name ?? 'Comminity',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.AcceptedLinkEmployee:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted_link_employee', {
                user1: fullName ?? '',
                community: change.contact?.community?.name ?? 'Comminity',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.AcceptedLinkGroup:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted_link_group', {
                user1: fullName ?? '',
                community: change.contact?.community?.name ?? 'Comminity',
                group: change.contact?.user_2_name ?? 'Gruppenname',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.AcceptedLinkMember:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.accepted_link_member', {
                user1: fullName ?? '',
                community: change.contact?.community?.name ?? 'Comminity',
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.CommunityAccepted:
          return (
            <div className={styles['change-item-text']}>
              {t('dashboard.widgets.changes.contact.community_accepted', {
                user1: change.contact?.user_1_name ?? `(${t('app.nameless')})`,
                user2: change.contact?.user_2_name ?? `(${t('app.nameless')})`,
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.CommunityInvited:
          return (
            <div className={styles['change-item-text']}>
              {!change.contact?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.community_invited', {
                user1: change.contact?.user_1_name ?? '',
                community: change.contact?.user_2_name ?? t('app.nameless'),
              })}{' '}
              {date}
            </div>
          );
        case ContactChangeType.CommunityRejected:
          break;
        case ContactChangeType.Rejected:
          return (
            <div className={styles['change-item-text']}>
              {!fullName && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.contact.rejected', {
                user1: fullName ?? '',
              })}{' '}
              {date}
            </div>
          );
        /***************/
        /* Room Change */
        /***************/
        case RoomChangeType.Close:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.close', {
                user1: change.room?.user_1_name ?? '',
                groupName: change.room?.message_room_name,
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.InviteMe:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.invite', {
                user1: change.room?.user_1_name ?? '',
                groupName: change.room?.message_room_name,
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.InviteOthers:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.invite_others', {
                user1: change.room?.user_1_name ?? '',
                user2: change.room?.user_2_name
                  ? JSON.parse(change.room?.user_2_name).join(', ').toString()
                  : undefined,
                groupName: change.room?.message_room_name,
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.Leave:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.leave', {
                user1: change.room?.user_1_name ?? '',
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.Mention:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.mention', {
                user1: change.room?.user_1_name ?? '',
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.NewMessage:
          return;
        case RoomChangeType.Removed:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.remove', {
                user1: change.room?.user_1_name ?? '',
                user2: change.room?.user_2_name ?? t('app.nameless'),
                groupName: change.room?.message_room_name,
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.RemovedMe:
          return (
            <div className={styles['change-item-text']}>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.remove_me', {
                user1: change.room?.user_1_name ?? '',
                groupName: change.room?.message_room_name,
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.Sticky:
          return (
            <div className={styles['change-item-text']}>
              {t('dashboard.widgets.changes.room.sticky', {
                user1: change.room?.user_1_name ?? `(${t('app.nameless')})`,
              })}{' '}
              {date}
            </div>
          );
        case RoomChangeType.ToolMultiRowNewMessage:

        case RoomChangeType.ToolNewMessage:
          return (
            <div>
              {!change.room?.user_1_name && (
                <span style={{ fontStyle: 'italic' }}>{t('app.nameless')}</span>
              )}{' '}
              {t('dashboard.widgets.changes.room.new_message', {
                user1: change.room?.user_1_name ?? '',
                toolName: change.room?.tool_name,
              })}{' '}
              {date}
            </div>
          );

        /*******************/
        /* Template Change */
        /*******************/
        case TemplateChangeType.Approved:
          return;
        case TemplateChangeType.Change:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.template.change', {
                userName:
                  change.template?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.template?.tool_name,
              })}${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.template.change', {
                  userName:
                    change.template?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.template?.tool_name,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case TemplateChangeType.Create:
          return;
        case TemplateChangeType.Delete:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.template.delete', {
                userName:
                  change.template?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.template?.tool_name,
              })}${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.template.delete', {
                  userName:
                    change.template?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.template?.tool_name,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case TemplateChangeType.Deployed:
          return;
        case TemplateChangeType.Publish:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.template.publish', {
                userName:
                  change.template?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.template?.tool_name,
              })}${' '}
                    ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.template.publish', {
                  userName:
                    change.template?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.template?.tool_name,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case TemplateChangeType.Question:
          return;
        /***************/
        /* Tool Change */
        /***************/
        case ToolChangeType.Add:
          return;
        case ToolChangeType.Change:
          if (change.tool?.count && change.tool?.count > 1) {
            return (
              <Tooltip
                title={`${
                  change.tool?.community?.name
                    ? t('dashboard.widgets.changes.tool.change_duplicate', {
                        user1: change.tool?.user_1_name ?? t('app.nameless'),
                        toolName: change.tool?.tool_name ?? '',
                        count: change.tool?.count ?? '',
                        communityName: change.tool?.community?.name ?? '',
                      })
                    : t(
                        'dashboard.widgets.changes.tool.change_duplicate_no_community',
                        {
                          user1: change.tool?.user_1_name ?? t('app.nameless'),
                          toolName: change.tool?.tool_name ?? '',
                          count: change.tool?.count ?? '',
                        }
                      )
                }${' '}${date}`}
              >
                <div className={styles['change-item-text']}>
                  {!change.tool?.user_1_name && (
                    <span style={{ fontStyle: 'italic' }}>
                      {t('app.nameless')}
                    </span>
                  )}{' '}
                  {change.tool?.community?.name
                    ? t('dashboard.widgets.changes.tool.change_duplicate', {
                        user1: change.tool?.user_1_name ?? '',
                        toolName: change.tool?.tool_name ?? '',
                        count: change.tool?.count ?? '',
                        communityName: change.tool?.community?.name ?? '',
                      })
                    : t(
                        'dashboard.widgets.changes.tool.change_duplicate_no_community',
                        {
                          user1: change.tool?.user_1_name ?? '',
                          toolName: change.tool?.tool_name ?? '',
                          count: change.tool?.count ?? '',
                        }
                      )}{' '}
                  {date}
                </div>
              </Tooltip>
            );
          }
          return;
        case ToolChangeType.Create:
          return (
            <Tooltip
              title={`${
                change.tool?.community?.name
                  ? t('dashboard.widgets.changes.tool.create', {
                      user1: change.tool?.user_1_name ?? t('app.nameless'),
                      toolName: change.tool?.tool_name,
                      communityName: change.tool?.community?.name,
                    })
                  : t('dashboard.widgets.changes.tool.create_no_community', {
                      user1: change.tool?.user_1_name ?? t('app.nameless'),
                      toolName: change.tool?.tool_name,
                    })
              }${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {!change.tool?.user_1_name && (
                  <span style={{ fontStyle: 'italic' }}>
                    {t('app.nameless')}
                  </span>
                )}{' '}
                {change.tool?.community?.name
                  ? t('dashboard.widgets.changes.tool.create', {
                      user1: change.tool?.user_1_name ?? '',
                      toolName: change.tool?.tool_name,
                      communityName: change.tool?.community?.name,
                    })
                  : t('dashboard.widgets.changes.tool.create_no_community', {
                      user1: change.tool?.user_1_name ?? '',
                      toolName: change.tool?.tool_name,
                    })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.Delete:
          return (
            <Tooltip
              title={`${
                change.tool?.community?.name
                  ? t('dashboard.widgets.changes.tool.delete', {
                      user1: change.tool?.user_1_name ?? t('app.nameless'),
                      toolName: change.tool?.tool_name,
                      communityName: change.tool?.community?.name,
                    })
                  : t('dashboard.widgets.changes.tool.delete_no_community', {
                      user1: change.tool?.user_1_name ?? t('app.nameless'),
                      toolName: change.tool?.tool_name,
                    })
              }${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {!change.tool?.user_1_name && (
                  <span style={{ fontStyle: 'italic' }}>
                    {t('app.nameless')}
                  </span>
                )}{' '}
                {change.tool?.community?.name
                  ? t('dashboard.widgets.changes.tool.delete', {
                      user1: change.tool?.user_1_name ?? '',
                      toolName: change.tool?.tool_name,
                      communityName: change.tool?.community?.name,
                    })
                  : t('dashboard.widgets.changes.tool.delete_no_community', {
                      user1: change.tool?.user_1_name ?? '',
                      toolName: change.tool?.tool_name,
                    })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.MultiAssigned:
          return (
            <Tooltip>
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.multi_assigned', {
                  rowName: change.tool?.changed_row?.new_value,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.MultiRemoved:
          return (
            <Tooltip>
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.multi_removed', {
                  rowName: change.tool?.changed_row?.new_value,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.MultiRowAdd:
          if (
            change.tool?.count &&
            change.tool?.count > 1 &&
            change.tool?.community?.name
          ) {
            return (
              <Tooltip>
                <div className={styles['change-item-text']}>
                  {!change.tool?.user_1_name && (
                    <span style={{ fontStyle: 'italic' }}>
                      {t('app.nameless')}{' '}
                    </span>
                  )}
                  {t('dashboard.widgets.changes.tool.multi_rows_add', {
                    user1: change.tool?.user_1_name ?? '',
                    toolName: change.tool?.tool_name,
                    tableName: change.tool?.table_name,
                    communityName: change.tool?.community?.name,
                  })}{' '}
                  {date}
                </div>
              </Tooltip>
            );
          }
          return;
        case ToolChangeType.MultiRowUpdate:
          // if (
          //   change.tool?.changed_row?.old_value &&
          //   change.tool?.changed_row?.old_value !== '' &&
          //   change.tool?.changed_row?.column_name &&
          //   change.tool?.changed_row?.multi_element_name
          // ) {
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.tool.multi_row_update', {
                user1: change.tool?.user_1_name ?? t('app.nameless'),
                toolName: change.tool?.tool_name,
                columnName: change.tool?.changed_row?.column_name,
                multiElementName: change.tool?.changed_row?.multi_element_name,
                oldValue: removeHtmlTags(change.tool?.changed_row?.old_value),
                newValue: removeHtmlTags(change.tool?.changed_row?.new_value),
              })}${' '}
              ${date}`}
            >
              <div className={styles['change-item-text']}>
                {!change.tool?.user_1_name && (
                  <span style={{ fontStyle: 'italic' }}>
                    {t('app.nameless')}
                  </span>
                )}{' '}
                {t('dashboard.widgets.changes.tool.multi_row_update', {
                  user1: change.tool?.user_1_name ?? '',
                  toolName: change.tool?.tool_name,
                  // communityName: change.tool?.community?.name,
                  columnName: change.tool?.changed_row?.column_name,
                  multiElementName:
                    change.tool?.changed_row?.multi_element_name,
                  oldValue: removeHtmlTags(
                    translateBoolean(change.tool?.changed_row?.old_value ?? '')
                  ),
                  newValue: removeHtmlTags(
                    translateBoolean(change.tool?.changed_row?.new_value ?? '')
                  ),
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        // }
        // return;
        case ToolChangeType.MultiSeleted:
          return (
            <Tooltip>
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.multi_seleted', {
                  rowName: change.tool?.changed_row?.new_value,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.Publish:
          return;
        case ToolChangeType.Removed:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.tool.removed', {
                user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.tool?.tool_name,
              })}${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.removed', {
                  user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.tool?.tool_name,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.RightAssigned:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.tool.right_assigned', {
                user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.tool?.tool_name,
                right: change.tool?.right?.name,
                role: change.tool?.right?.role,
              })}${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.right_assigned', {
                  user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.tool?.tool_name,
                  right: change.tool?.right?.name,
                  role: change.tool?.right?.role,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.RightRemoved:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.tool.right_removed', {
                user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.tool?.tool_name,
                right: change.tool?.right?.name,
                role: change.tool?.right?.role,
              })}${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.right_removed', {
                  user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.tool?.tool_name,
                  right: change.tool?.right?.name,
                  role: change.tool?.right?.role,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.Selected:
          return (
            <Tooltip
              title={`${t('dashboard.widgets.changes.tool.selected', {
                user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                toolName: change.tool?.tool_name,
              })}${' '}
          ${date}`}
            >
              <div className={styles['change-item-text']}>
                {t('dashboard.widgets.changes.tool.selected', {
                  user1: change.tool?.user_1_name ?? `(${t('app.nameless')})`,
                  toolName: change.tool?.tool_name,
                })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.Settings:
          return;
        case ToolChangeType.ToolChatGroupAdd:
          return (
            <Tooltip
              title={`${
                change.tool?.tool_name &&
                t('dashboard.widgets.changes.tool.add_tool_chat_group', {
                  user1: change.tool?.user_1_name,
                  toolName: change.tool?.tool_name,
                })
              }${' '}
          ${date}`}
            >
              <div>
                {!change.tool?.user_1_name && (
                  <span style={{ fontStyle: 'italic' }}>
                    {t('app.nameless')}
                  </span>
                )}{' '}
                {change.tool?.tool_name &&
                  t('dashboard.widgets.changes.tool.add_tool_chat_group', {
                    user1: change.tool?.user_1_name,
                    toolName: change.tool?.tool_name,
                  })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.ToolChatGroupRemove:
          return (
            <Tooltip
              title={`${
                change.tool?.tool_name &&
                t('dashboard.widgets.changes.tool.remove_tool_chat_group', {
                  user1: change.tool?.user_1_name,
                  toolName: change.tool?.tool_name,
                })
              }${' '}
          ${date}`}
            >
              <div>
                {!change.tool?.user_1_name && (
                  <span style={{ fontStyle: 'italic' }}>
                    {t('app.nameless')}
                  </span>
                )}{' '}
                {change.tool?.tool_name &&
                  t('dashboard.widgets.changes.tool.remove_tool_chat_group', {
                    user1: change.tool?.user_1_name,
                    toolName: change.tool?.tool_name,
                  })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.ToolChatMultiRowAdd:
          return (
            <Tooltip>
              <div>
                {!change.tool?.user_1_name && (
                  <span style={{ fontStyle: 'italic' }}>
                    {t('app.nameless')}
                  </span>
                )}{' '}
                {change.tool?.tool_name &&
                  t('dashboard.widgets.changes.tool.add_tool_chat_multi_row', {
                    user1: change.tool?.user_1_name,
                    rowName: change.tool?.changed_row?.new_value,
                  })}{' '}
                {date}
              </div>
            </Tooltip>
          );
        case ToolChangeType.Update:
          return;

        default:
          break;
      }
      return 'Content';
    },
    []
  );

  const iconHeight = '16px';
  const changeType = getChangeType(change)?.change_type;
  const changeTypeDetail = getChangeType(change)?.change_type_detail;
  const changeContent = getChangeContent(change, changeTypeDetail, date);
  const senderName =
    change.create_change_info.created_by.profile?.personal_data.first_name ||
    'Avatar';
  const senderAvatar =
    getChangeType(change)?.sender_avatar ||
    '6b0dfc56-6d62-4376-aff4-ae380f5487d5.png'; // Pengueen Logo

  return (
    <Box
      className="rounded-md px-4 py-2"
      sx={{ backgroundColor: 'change.light', color: 'change.text' }}
    >
      {/* Header */}
      <Box className="flex flex-row justify-between p-1">
        <Box sx={{ borderColor: 'text.primary' }}>
          <img
            alt={senderName}
            className="rounded-full h-6 w-6 border-2"
            src={`https://files.pengueen.de/api/download/${senderAvatar}`}
          />
        </Box>
        <Box className="flex flex-row items-center h-4">
          {change.create_change_info.created_at}
          <Icon icon={['fas', 'thumbtack']} classes="ml-2 mr-1" />
          <Icon icon={['fas', 'times']} size="medium" />
          <Icon
            icon={['fas', 'chevron-down']}
            classes="w-4 ml-1"
            size="medium"
          />
        </Box>
      </Box>
      {/* Content */}
      <Box
        className="p-2 mt-1 mb-4 rounded-md"
        sx={{ backgroundColor: 'background.paper', color: 'text.primary' }}
      >
        {changeContent}
        {changeType} {changeTypeDetail}
      </Box>
      {/* Footer */}
      <Box
        className="p-1 flex flex-row justfy-end"
        sx={{ backgroundColor: 'change.medium', color: 'white' }}
      >
        Footer
      </Box>
      {/* Notification */}
      {notification && (
        <Box
          className="p-4"
          sx={{ backgroundColor: 'change.dark', color: 'white' }}
        >
          Notification
        </Box>
      )}
    </Box>
  );
};
