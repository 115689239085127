// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StartPage_start-page-container__ckZAk {\n\n    position: relative;\n\n    height: 100%;\n\n    width: 100%;\n\n    padding: 1rem\n}\n\n.StartPage_start-page-background__BYs8- {\n\n    position: fixed;\n\n    bottom: 0px;\n\n    width: 100%\n}\n  .StartPage_start-page-background__BYs8- img {\n\n    overflow: hidden;\n\n    background-size: cover\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;;IAAA,mBAAA;;IAAA,aAAA;;IAAA,YAAA;;IAAA;CAAA;;AAAA;;IAAA,gBAAA;;IAAA,YAAA;;IAAA;CAAA;EAAA;;IAAA,iBAAA;;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"start-page-container": "StartPage_start-page-container__ckZAk",
	"start-page-background": "StartPage_start-page-background__BYs8-"
};
export default ___CSS_LOADER_EXPORT___;
