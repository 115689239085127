import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { TemplateSection } from '../../modules/templates/models/templates.types';
import { messageHandleContent } from '../../new_shared/utils/shared.utils';

import Typography from '@mui/material/Typography';
import ToolElementRenderer from './ToolElementRenderer';
import { KanbanDto, KanbanInfo } from '../../modules/tools/models/tools.types';

type ToolSectionProps = {
  expandedSectionAccordions: any[];
  isAdmin: boolean;
  section: TemplateSection;
  toolChat?: boolean;
  toolId: string;
  toolName: string;
  kanbanInfo?: KanbanInfo[] | undefined;
  accordionSectionClicked: (section_id: any) => void;
};
const ToolSection = ({
  expandedSectionAccordions,
  isAdmin,
  section,
  toolChat,
  toolId,
  toolName,
  kanbanInfo,
  accordionSectionClicked,
}: ToolSectionProps) => {
  return (
    <Accordion
      key={section.id}
      className="w-full mb-2.5 shadow-none border-solid rounded-none"
      sx={{
        border: expandedSectionAccordions.includes(section.id) ? '1px' : 0,
        borderColor: expandedSectionAccordions.includes(section.id)
          ? '#A3D3F7'
          : undefined,
      }}
      title={section.name}
      expanded={expandedSectionAccordions.includes(section.id)}
      onChange={() => accordionSectionClicked(section.id)}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
        sx={{
          backgroundColor: expandedSectionAccordions.includes(section.id)
            ? '#A3D3F7'
            : '#E6E6E6',
        }}
        className="w-full"
      >
        <Typography className="text-base font-semibold">
          {section.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="shadow-none">
        {section.description && (
          <Box
            sx={{
              color: 'text.primary',
              '& a': {
                color: 'text.primary',
                textDecoration: 'underline',
              },
            }}
            className="py-2"
            dangerouslySetInnerHTML={{
              __html: `${messageHandleContent(section.description)}`,
            }}
          />
        )}
        {section.id &&
          section.elements?.map((elem) => (
            // <ToolElement elem={elem} sectionId={section.id} toolId={toolId} />
            <div key={elem.id} className="py-4">
              <ToolElementRenderer
                elem={elem}
                sectionId={section.id}
                toolChat={toolChat}
                toolId={toolId}
                toolName={toolName}
                isAdmin={isAdmin}
                kanbanInfo={kanbanInfo}
              />
            </div>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default ToolSection;
