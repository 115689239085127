// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolElementVoteStar_vote-star__bXtRG {\n    display: flex;\n    flex-direction: column\n}\n  .ToolElementVoteStar_vote-star-form-field-label-padding__C1in7 {\n    padding-left: 0.5rem;\n    padding-right: 0.5rem\n}\n  .ToolElementVoteStar_vote-star-row__\\+g80m {\n    display: flex;\n    flex-direction: row\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA,qBAAA;IAAA;CAAA;EAAA;IAAA,cAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vote-star": "ToolElementVoteStar_vote-star__bXtRG",
	"vote-star-form-field-label-padding": "ToolElementVoteStar_vote-star-form-field-label-padding__C1in7",
	"vote-star-row": "ToolElementVoteStar_vote-star-row__+g80m"
};
export default ___CSS_LOADER_EXPORT___;
