import { Grid } from '@mui/material';
import styles from '../Layout/Layout.module.scss'; // Share scss files
import SwipeableViews from 'react-swipeable-views';
import { memo, ReactNode } from 'react';
import { thinScroll } from '../../../shared/models/shared.types';

export type MobileViewProps = {
  leftComponent: ReactNode;
  rightComponent1: ReactNode;
  rightComponent2: ReactNode;
  rightComponent3: ReactNode;
};

export const MobileView = memo(
  ({
    leftComponent,
    rightComponent1,
    rightComponent2,
    rightComponent3,
  }: MobileViewProps) => {
    return (
      <Grid item xs={12} className={styles['page-layout-grid-mobile']}>
        <SwipeableViews enableMouseEvents style={{ height: '100%' }} index={1}>
          <Grid item xs className={styles['page-layout-grid-left']}>
            {leftComponent}
          </Grid>
          <Grid
            item
            xs
            className={styles['page-layout-grid-right-mobile']}
            sx={{
              ...thinScroll,
            }}
          >
            {rightComponent1}
          </Grid>
          <Grid
            item
            xs
            className={styles['page-layout-grid-right-mobile']}
            sx={{
              ...thinScroll,
            }}
          >
            {rightComponent2}
          </Grid>
          <Grid item xs className={styles['page-layout-grid-right-mobile']}>
            {rightComponent3}
          </Grid>
        </SwipeableViews>
      </Grid>
    );
  }
);
