import { memo, useEffect, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

// Styles
import styles from './ToolMultiElementTable.module.scss';

// Utils
// import {
//   columnPropsAreEqual,
// } from '../../utils/tools.utils';

// Hooks
import { useNewsCenter } from '../../../newscenter/hooks/use-news-center.hook';
import { useTranslation } from 'react-i18next';

const ColumnTextArea = (props: GridRenderCellParams) => {
  const { t } = useTranslation();

  const { messageHandleContent } = useNewsCenter();
  const [value, setValue] = useState<any | undefined>(undefined);

  // Change true or false as string
  useEffect(() => {
    let newValue = props.value;

    if (typeof newValue === 'boolean') {
      if (newValue === true) {
        newValue = t('app.yes');
      } else if (newValue === false) {
        newValue = t('app.no');
      }
    }

    setValue(newValue);
  }, [props.value]);

  return (
    <>
      {value && (
        <div
          className={styles['column-text']}
          dangerouslySetInnerHTML={{
            __html: `${messageHandleContent(value)}`,
          }}
        />
      )}
    </>
  );
};

export default memo(ColumnTextArea);
