import { memo } from 'react';
import { Box, Radio } from '@mui/material';
import clsx from 'clsx';

// Styles
import styles from './RadioButton.module.scss';
import { SharedState, useSharedStore } from '../../stores/use-shared.store';
import { Theme } from '../../models/shared.types';

type RadioButtonProps = {
  classes?: string;
  checked: boolean;
  color?: string;
  disabled?: boolean;
  subtitle?: string;
  title: string;
  onClick: () => void;
};

const RadioButton = (props: RadioButtonProps) => {
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);
  return (
    <div
      className={clsx(
        styles['radio-button'],
        props.classes && props.classes,
        !props.subtitle && styles['radio-button-center']
      )}
    >
      <Radio
        checked={props.checked}
        className={styles['radio-button-elem']}
        disabled={props.disabled}
        inputProps={{ 'aria-label': 'RadioButton' }}
        name="radio-button"
        onClick={props.onClick}
      />
      <Box className={styles['radio-button-content']} sx={{
              backgroundImage: `linear-gradient(to right, ${props.color} 0.75rem, ${theme === Theme.Dark ? '#202020': 'white'} 0.75rem)`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',}}>
        <div className={clsx(styles['radio-button-content-title'], props.color && 'ml-4' )}>
          {props.title}
        </div>
        {props.subtitle && (
          <Box
            className={styles['radio-button-content-subtitle']}
            sx={{ color: 'text.secondary' }}
          >
            {props.subtitle}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default memo(RadioButton);
