// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import {
  FileTypeErrorSendRequest,
  GroupRoomRequest,
  NewsCenterResponse,
  RemovePersonRequest,
  Room,
  roomAsFavoriteRequest,
  RoomRequest,
} from '../models/news-center.types';

// Stores
import { AuthState, useAuthStore } from '../../public/stores/use-auth.store';
import { DeleteInfo } from '../../user/models/user.types';

export const useNewsCenterHttp = () => {
  const { fetchData } = useFetch();

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  /**
   * POST Direct room by body data.
   * @param body RoomRequest
   * @returns Room
   */
  const directRoomPost = async (
    body: RoomRequest
  ): Promise<Room | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/private`, {
        method: 'POST',
        body,
      });
    }
    return undefined;
  };

  /**
   * Get Direct room by body data.
   * @param body RoomRequest
   * @returns Room
   */
  const directRoomGet = async (
    body: RoomRequest
  ): Promise<Room | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/private`, {
        method: 'GET',
        body,
      });
    }
    return undefined;
  };

  /**
   * DELETE Direct room by id.
   * @param id Direct room id
   * @returns Message string
   */
  const directRoomDelete = async (id: string): Promise<string | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/private/${id}`, {
        method: 'PATCH',
      });
    }
  };

  /**
   * POST Group room by body data.
   * @param body GroupRoomRequest
   * @returns Room
   */
  const groupRoomPost = async (
    body: GroupRoomRequest
  ): Promise<Room | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/groups`, {
        method: 'POST',
        body,
      });
    }
    return undefined;
  };

  /**
   * DELETE Group room by id.
   * @param id Group id
   * @returns Message string
   */
  const groupRoomDelete = async (id: string): Promise<any | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/groups/${id}`, {
        method: 'DELETE',
      });
    }
  };

  /**
   * DELETE Group room by id.
   * @param id Group id
   * @returns Message string
   */
  const groupRoomDeleteFromNetwork = async (
    id: string
  ): Promise<any | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/groups/${id}`, {
        method: 'POST',
      });
    }
  };

  /**
   * GET News center page data.
   * @returns Direct and group rooms data
   */
  const newsCenterGet = async (): Promise<NewsCenterResponse | undefined> => {
    if (accessToken) {
      return await fetchData('newscenter/', {
        method: 'GET',
      });
    }
  };

  /**
   * GET News center unread messages.
   * @returns Unread messages number
   */
  const newsCenterUnreadGet = async (): Promise<
    { unread: number; groups: { id: string; number: number }[] } | undefined
  > => {
    if (accessToken) {
      return await fetchData('newscenter/unread');
    }
  };

  const addRoomAsFavorite = async (
    body: roomAsFavoriteRequest
  ): Promise<string | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/${body.room_id}/favorite`, {
        method: 'PATCH',
        body,
      });
    }
  };

  const removeRoomAsFavorite = async (
    body: roomAsFavoriteRequest
  ): Promise<string | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/${body.room_id}/favorite`, {
        method: 'DELETE',
        body,
      });
    }
  };

  /**
   * POST Send unsupported file types.
   */
  const fileTypeErrorSend = async (
    body: FileTypeErrorSendRequest
  ): Promise<FileTypeErrorSendRequest | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/file`, {
        method: 'POST',
        body,
      });
    }
  };

  const newsCenterRemovePersonDeleteInfoGet = async (
    data: RemovePersonRequest
  ): Promise<DeleteInfo | undefined> => {
    if (accessToken) {
      return await fetchData(
        `newscenter/${data.room_id}/deleteinformation/${data.uid}`,
        {
          method: 'GET',
        }
      );
    }
  };

  const newsCenterRoomDeleteInfoGet = async (
    id: string
  ): Promise<DeleteInfo | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/${id}/deleteinformation`, {
        method: 'GET',
      });
    }
  };

  const acceptInviteLink = async (body: any): Promise<any | undefined> => {
    if (accessToken) {
      return await fetchData(`newscenter/grouplinkinvite/${body.link}`, {
        method: 'POST',
        body,
      });
    }
  };

  return {
    directRoomPost,
    directRoomGet,
    directRoomDelete,
    groupRoomDeleteFromNetwork,
    groupRoomPost,
    groupRoomDelete,
    newsCenterGet,
    newsCenterUnreadGet,
    addRoomAsFavorite,
    removeRoomAsFavorite,
    fileTypeErrorSend,
    newsCenterRemovePersonDeleteInfoGet,
    newsCenterRoomDeleteInfoGet,
    acceptInviteLink,
  };
};
