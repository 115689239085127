import { CircularProgress, TextField } from '@mui/material';
import {
  DataGridPro,
  GridCellParams,
  GridColumns,
  GridEventListener,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useToolsHttp } from '../../hooks/use-tools-http.hook';
import { useMutation } from 'react-query';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { ImageFallbackType } from '../../../../shared/models/shared.types';
import styles from './ToolContactModal.module.scss';
import {
  ActiveState,
  ConnectionType,
  Contact,
} from '../../../contacts/models/contacts.types';
import { uniqueId } from 'lodash';
import { ChangedByCellProps } from '../../models/tools.types';

type ToolContactModalProps = {
  toolid: string;
  onDirectContactClick?: (contact: Contact) => void;
};

export const ToolContactModal = (props: ToolContactModalProps) => {
  const { handleError, handleRetry } = useFetch();
  const { getToolContactModalContacts } = useToolsHttp();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    props.toolid && contactMutation.mutate({ id: props.toolid });
  };

  const contactMutation = useMutation(
    (body: { id: string }) => getToolContactModalContacts(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          const cols = data.columns.map((column) => {
            if (column.field === 'name') {
              return {
                ...column,

                renderCell: (params: any) => <ColumnName {...params} />,
              };
            } else {
              return {
                ...column,
                renderCell: (params: GridRenderCellParams) => (
                  <NormalCell params={params} />
                ),
              };
            }
          });
          setColumns(cols);
          setRows(data.rows);
          setLoading(false);
          //setError(null);
        }
        if (error) {
          setError('Es ist ein Fehler aufgetreten!');
          console.log('ERROR on contactDeleteMutation', error);
        }
      },
    }
  );

  // Gefilterte Zeilen basierend auf der Suchanfrage
  const filteredRows = useMemo(() => {
    if (!searchQuery.trim()) return rows;

    return rows.filter((row: any) =>
      Object.values(row)
        .filter((value) => {
          return typeof value === 'string' || typeof value === 'object';
        })
        .some((value: any) =>
          value && value.name
            ? value.name.toLowerCase().includes(searchQuery.toLowerCase())
            : value.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );
  }, [rows, searchQuery]);

  const ColumnName = memo((props: any) => {
    return (
      <>
        <div className={styles['column-name']}>
          <div className={styles['column-name-avatar']}>
            {props.value.avatar !== '' ? (
              <img
                alt={props.value?.name}
                className={styles['column-name-avatar-icon']}
                src={props.value.avatar}
              />
            ) : (
              <ImageFallback
                sizeClassName={styles['column-name-avatar-icon']}
                type={ImageFallbackType.Profile}
              />
            )}
            {/* {props.row?.online_state && (
          <Box
            className={styles['column-name-avatar-online-state']}
            sx={{ backgroundColor: colorOnlineState }}
          />
          )}  */}
          </div>
          <div className={styles['column-name-text']}>{props.value.name}</div>
        </div>
      </>
    );
  });

  const NormalCell = React.memo(({ params }: ChangedByCellProps) => (
    <>
      {params.value && (
        <div
          className={styles['column-text']}
          dangerouslySetInnerHTML={{
            __html: `${params.value}`,
          }}
        />
      )}
    </>
  ));

  const onCellClick = useCallback((params: GridCellParams<any, any, any>) => {
    params.row?.id &&
      props.onDirectContactClick &&
      props.onDirectContactClick({
        ...params.row,
        first_name: params.row.name.name,
        avatar: { id: uniqueId, url: params.row.name.avatar },
        connection: ConnectionType.Community,
        state: ActiveState.Active,
      });
    // eslint-disable-next-line
  }, []);

  const onRowClick = useCallback((params: GridRowParams<any>) => {
    params.row?.id &&
      props.onDirectContactClick &&
      props.onDirectContactClick({
        ...params.row,
        first_name: params.row.name.name,
        avatar: { id: uniqueId, url: params.row.name.avatar },
        connection: ConnectionType.Community,
        state: ActiveState.Active,
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <TextField
        label="Suchen"
        variant="outlined"
        margin="normal"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Suchen nach Name, Spitzname, etc."
      />{' '}
      <br />
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <DataGridPro
          rows={filteredRows}
          columns={columns}
          autoHeight
          disableColumnMenu
          pageSize={30}
          pagination={true}
          onCellClick={onCellClick}
          onRowClick={onRowClick}
        />
      )}
    </>
  );
};

export default memo(ToolContactModal);
