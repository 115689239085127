// Models
import {
  ActiveState,
  ContactCommunityGetAction,
} from '../../contacts/models/contacts.types';
import { Message } from './message.types';
import { Attachment } from '../../../shared/models/shared.types';
import { Tool } from '../../tools/models/tools.types';
import { User, UserEntity } from '../../user/models/user.types';
import {
  CommunityEntity,
  CommunityUserRole,
} from '../../communities/models/communities.types';
import { ToolFavorite } from '../../dashboard/models/dashboard.types';

export interface DrawerChatItem {
  id: string;
  settings?: GroupRoomSettings;
  type: RoomType;
  users?: Partial<User>[];
}

export interface GroupRoomRequest extends RoomRequest {
  room_has_msgs?: boolean;
  folder_option?: any;
  community_id?: string;
  description: string;
  name: string;
  tools?: Tool[];
}

export interface GroupRoomSettings {
  room_has_msgs?: boolean;
  no_include_creator?: boolean;
  folder_option?: any; // { placeholder: string , value: string }[]
  community_id?: string;
  group_id?: string | CommunityUserRole;
  description: string;
  name: string;
  latest?: Date;
  tool_id?: string;
}

export interface GroupUser {
  id: string;
  avatar?: Attachment;
  email: string;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  state?: ActiveState;
}

export interface GroupRoomUsersPatchResponse {
  room_id: string;
  users: Partial<User>[];
}

export interface IsTypingRequest {
  accessToken: string;
  isTyping: boolean;
  room_id: string;
}

export interface IsTypingResponse {
  client_id?: string;
  isTyping: boolean;
  room_id: string;
  user?: {
    id: string;
    first_name: string;
    last_name: string;
  };
}

export interface NewsCenterResponse {
  groupChats: Room[];
  privateChats: Room[];
  favoriteChats: Room[];
  unreadChats: Room[];
  invitorChat: Room;
}

export interface Room {
  id: string;
  existing?: boolean;
  join?: boolean;
  messages: Message[];
  permissions: {
    can_add_user: boolean;
    can_delete_group: boolean;
    can_edit_settings: boolean;
    can_link_community: boolean;
  };
  settings?: GroupRoomSettings;
  tools?: Tool[];
  tools_options?: ToolFavorite[];
  type: RoomType;
  unread_msgs?: number;
  users: Partial<User>[];
  favorite?: boolean;
  inactive_room?: boolean;
  has_msgs?: boolean;
  room_creator?: UserEntity;
}
// Same as MessageRoom entity in Backend
export interface MessageRoomEntity {
  id: string;
  creator_id: UserEntity;
  community_id: CommunityEntity;
  description: string;
  type: RoomType;
  name: string;
  inactive_room: boolean;
  participant: UserEntity[];
  inactive_users: UserEntity[];
  favorite_user: UserEntity[];
  // create_change_id: CreateChangeInfo;
  // field_rights: ToolField[];
  // connected_tool: Tool;
  // room_has_msgs: boolean;
  // invite_link: string;
  // tags: FolderTagsEntity[];
  // tools: Tool[];
  // tool_permission: ToolPermissions[];
}

export interface RoomRequest {
  type: RoomType;
  participant: string[];
}

export interface RemovePersonRequest {
  room_id: string;
  uid: string;
}

export interface roomAsFavoriteRequest {
  room_id: string;
}

export interface NetworkFavoritesRequest {
  get_type?: ContactCommunityGetAction;
  community_id?: string;
  group_id?: string;
  folder_id?: string;
  tool_link_id?: string;
  community_link_id?: string;
}

export enum GroupCreateEditState {
  Common = 'Common',
  Members = 'Members',
}

export enum RoomAction {
  User = 'USER',
  Add = 'ADD',
  Delete = 'DELETE',
  Link = 'LINK',
  Settings = 'SETTINGS',
  PrintView = 'PRINTVIEW',
}

export enum RoomType {
  Favarite = 'FAVORITE',
  Latest = 'LATEST',
  LatestRead = 'LATEST_READ',
  Direct = 'DIRECT',
  Broadcast = 'BROADCAST',
  Group = 'GROUP',
  Current = 'CURRENT',
  Network = 'NETWORK',
  ToolChatContact = 'TOOLCHAT_CONTACT',
  ToolChatGroup = 'TOOLCHAT_GROUP',
  GodParent = 'GODPARENT',
  ToolChatMultiRow = 'TOOLCHAT_MULTIROW',
  // MultiElement = 'MultiElement',
}

export enum FileType {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  XLS = 'application/vnd.ms-excel',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
  MP4 = 'video/mp4',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  DOT = 'application/msword',
  AI = 'application/postscript',
  EPS = 'application/postscript',
  PS = 'application/postscript',
  PSD = 'image/vnd.adobe.photoshop',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  PPT = 'application/vnd.ms-powerpoint',
  PPS = 'application/vnd.ms-powerpoint',
  MP3 = 'audio/mpeg',
  M4A = 'audio/m4a',
  WAV = 'audio/wav',
  CSV = 'text/csv',
  ICS = 'text/calendar',
  TXT = 'text/plain',
  RTX = 'text/richtext',
  MPEG = 'video/mpeg',
  MPG = 'video/mpeg',
  MPE = 'video/mpeg',
  QT = 'video/quicktime',
  MOV = 'video/quicktime',
  XLA = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  RAR = 'application/x-rar-compressed',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  ODP = 'application/vnd.oasis.opendocument.presentation',
  ODT = 'application/vnd.oasis.opendocument.text',
}

export interface FileTypeErrorSendRequest {
  date: Date;
  fileType: string;
}
