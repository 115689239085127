// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolElementVoteGrade_vote-grade__3zlUe {\n    display: flex;\n    flex-direction: column\n}\n  .ToolElementVoteGrade_vote-grade-add__m\\+VvE {\n    display: flex;\n    flex-direction: row;\n    align-items: center\n}\n  .ToolElementVoteGrade_vote-grade-form-field-label-padding__UcukL {\n    padding-left: 0.5rem;\n    padding-right: 0.5rem\n}\n  .ToolElementVoteGrade_vote-grade-row__0SJhO {\n    margin-left: 0.5rem;\n    display: flex;\n    flex-direction: row\n}\n    .ToolElementVoteGrade_vote-grade-row-input__ozARU {\n    height: 2rem;\n    width: 4rem;\n    border-radius: 0.375rem;\n    border-width: 1px;\n    border-style: solid;\n    --tw-border-opacity: 1;\n    border-color: rgb(209 213 219 / var(--tw-border-opacity))\n}\n  .ToolElementVoteGrade_vote-grade-input__1YRmc {\n    height: 2rem;\n    width: 4rem;\n    border-radius: 0.375rem;\n    border-width: 1px;\n    border-style: solid;\n    --tw-border-opacity: 1;\n    border-color: rgb(209 213 219 / var(--tw-border-opacity))\n}\n    .ToolElementVoteGrade_vote-grade-input-default__S9IXe {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    font-weight: 700\n}\n    .ToolElementVoteGrade_vote-grade-input-number__ueHaB {\n    margin-left: 0.25rem;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    font-weight: 700\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA,cAAA;IAAA,oBAAA;IAAA;CAAA;EAAA;IAAA,qBAAA;IAAA;CAAA;EAAA;IAAA,oBAAA;IAAA,cAAA;IAAA;CAAA;IAAA;IAAA,aAAA;IAAA,YAAA;IAAA,wBAAA;IAAA,kBAAA;IAAA,oBAAA;IAAA,uBAAA;IAAA;CAAA;EAAA;IAAA,aAAA;IAAA,YAAA;IAAA,wBAAA;IAAA,kBAAA;IAAA,oBAAA;IAAA,uBAAA;IAAA;CAAA;IAAA;IAAA,cAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;CAAA;IAAA;IAAA,qBAAA;IAAA,cAAA;IAAA,oBAAA;IAAA,oBAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vote-grade": "ToolElementVoteGrade_vote-grade__3zlUe",
	"vote-grade-add": "ToolElementVoteGrade_vote-grade-add__m+VvE",
	"vote-grade-form-field-label-padding": "ToolElementVoteGrade_vote-grade-form-field-label-padding__UcukL",
	"vote-grade-row": "ToolElementVoteGrade_vote-grade-row__0SJhO",
	"vote-grade-row-input": "ToolElementVoteGrade_vote-grade-row-input__ozARU",
	"vote-grade-input": "ToolElementVoteGrade_vote-grade-input__1YRmc",
	"vote-grade-input-default": "ToolElementVoteGrade_vote-grade-input-default__S9IXe",
	"vote-grade-input-number": "ToolElementVoteGrade_vote-grade-input-number__ueHaB"
};
export default ___CSS_LOADER_EXPORT___;
