import { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useMutation } from 'react-query';
import jwt_decode from 'jwt-decode';

// Hooks
import { useAuth } from '../modules/public/hooks/use-auth.hook';
import { useFetch } from '../shared/hooks/use-fetch.hook';
import { useUsersHttp } from '../modules/user/hooks/use-users-http.hook';

// Models
import { Attachment, JwtPayload, Theme } from '../shared/models/shared.types';

// Pages
import AuthPage from '../modules/public/pages/AuthPage/AuthPage';
import { EmailConfirm } from '../modules/user/components/EmailConfirm/EmailConfirm';
import PublicPage from '../modules/public/pages/PublicPages/PublicPage';
import ProtectedContainer from '../shared/components/ProtectedContainer/ProtectedContainer';
import UserSettingsPage from '../modules/user/components/UserSettingsContainer/UserSettingsContainer';

// Router
import { ContactsRouter } from './ContactsRouter';
import { NewsCenterRouter } from './NewsCenterRouter';
import { MarketRouter } from './MarketRouter';
import { ProtectedRoute } from './ProtectedRoute';
import { PublicRouter } from './PublicRouter';
import { ToolsRouter } from './ToolsRouter';

// Stores
import { UserState, useUserStore } from '../modules/user/stores/use-user.store';
import { Loader } from '../shared/ui/Loader/Loader';
import {
  ContactsState,
  useContactsStore,
} from '../modules/contacts/stores/use-contacts.store';
import { SharedState, useSharedStore } from '../shared/stores/use-shared.store';
import { unstable_batchedUpdates } from 'react-dom';
import { DashboardRouter } from './DashboardRouter';
import { NewDashboardRouter } from '../new_router/AppRouter';

// Lazy-load pages
const AccountSetup = lazy(
  () => import('../modules/public/pages/AccountSetup/AccountSetup')
);
const Dashboard = lazy(
  () => import('../modules/dashboard/pages/Dashboard/Dashboard')
);
const Login = lazy(() => import('../modules/public/pages/Login/Login'));
const PasswordReset = lazy(
  () => import('../modules/public/pages/PasswordReset/PasswordReset')
);
const Registration = lazy(
  () => import('../modules/public/pages/Registration/Registration')
);
const UI = lazy(() => import('../shared/pages/UI/UI'));

export const AppRouter = () => {
  const { isAuthenticated } = useAuth();
  const { handleError, handleRetry } = useFetch();
  const { userGet } = useUsersHttp();

  // Component State
  const [isNewView, setIsNewView] = useState(false);
  const [toolCommunityLogo, setToolCommunityLogo] = useState<
    Attachment | undefined
  >(undefined);

  const [setAccount, setProfile, setTrail, setCommunities] = useUserStore(
    (state: UserState) => [
      state.setAccount,
      state.setProfile,
      state.setTrial,
      state.setCommunities,
    ]
  );

  // Contacts Store State
  const [setNavCommunities, setGroupChats] = useContactsStore(
    (state: ContactsState) => [state.setNavCommunities, state.setGroupChats]
  );

  // Shared store state
  const [setTheme] = useSharedStore((state: SharedState) => [state.setTheme]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET user data mutation
  const userGetMutation = useMutation((id: string) => userGet(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled: (data, error) => {
      if (data) {
        unstable_batchedUpdates(() => {
          setAccount(data.account);
          setProfile(data.profile);
          setTheme(data.theme ?? Theme.Light);
          if (data?.communities && data?.communities?.length > 0) {
            setTrail(30);
            setCommunities(data?.communities);
          }
        });
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
          handleError(errRes.status);
        }
      }
    },
  });

  // ####### //
  // EFFECTS //
  // ####### //

  // Check for valid access token on application init
  useEffect(() => {
    const accessToken = localStorage.getItem('frontend:accessToken');
    if (accessToken) {
      unstable_batchedUpdates(() => {
        const accessToken = localStorage.getItem('frontend:accessToken');
        if (accessToken) {
          const decodedJWT: JwtPayload = jwt_decode(accessToken);
          decodedJWT && userGetMutation.mutate(decodedJWT.id);
        }
      });
    }
    return () => {
      setGroupChats([]);
      setNavCommunities([]);
    };
    // eslint-disable-next-line
  }, []);

  const toggleNewView = (checked: boolean) => {
    setIsNewView(checked);
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated() ? (
            <Navigate to="/dashboard" />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path="/login"
        element={
          <AuthPage
            page={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/login/:state"
        element={
          <AuthPage
            page={
              <Suspense fallback={<Loader />}>
                <Login />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/account-setup/:id"
        element={
          <AuthPage
            page={
              <Suspense fallback={null}>
                <AccountSetup />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/account-setup/:id/:state"
        element={
          <PublicPage>
            <Suspense fallback={null}>
              <AccountSetup />
            </Suspense>
          </PublicPage>
        }
      />
      <Route
        path="/password-reset"
        element={
          <AuthPage
            page={
              <Suspense fallback={<Loader />}>
                <PasswordReset />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/password-reset/:id"
        element={
          <AuthPage
            page={
              <Suspense fallback={<Loader />}>
                <PasswordReset />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/registration"
        element={
          <AuthPage
            page={
              <Suspense fallback={null}>
                <Registration />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/tools/private/:id"
        element={
          <AuthPage
            logo={toolCommunityLogo}
            page={
              <Suspense fallback={<Loader />}>
                <Registration
                  logo={toolCommunityLogo}
                  setLogo={setToolCommunityLogo}
                />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/community/invite/:cid"
        element={
          <AuthPage
            logo={toolCommunityLogo}
            page={
              <Suspense fallback={<Loader />}>
                <Registration
                  logo={toolCommunityLogo}
                  setLogo={setToolCommunityLogo}
                />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/group/invite/:inviteid"
        element={
          <AuthPage
            logo={toolCommunityLogo}
            page={
              <Suspense fallback={<Loader />}>
                <Registration
                  logo={toolCommunityLogo}
                  setLogo={setToolCommunityLogo}
                />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/registration/:state"
        element={
          <AuthPage
            logo={toolCommunityLogo}
            page={
              <Suspense fallback={<Loader />}>
                <Registration
                  logo={toolCommunityLogo}
                  setLogo={setToolCommunityLogo}
                />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/registration/:id/:state"
        element={
          <AuthPage
            logo={toolCommunityLogo}
            page={
              <Suspense fallback={<Loader />}>
                <Registration
                  logo={toolCommunityLogo}
                  setLogo={setToolCommunityLogo}
                />
              </Suspense>
            }
          />
        }
      />
      <Route
        path="/dashboard/*"
        element={
          <ProtectedRoute>
            {isNewView ? (
              <NewDashboardRouter
                isNewView={isNewView}
                toggleNewView={toggleNewView}
              />
            ) : (
              <ProtectedContainer
                toggleNewView={toggleNewView}
                isNewView={isNewView}
                page={
                  <Suspense fallback={<Loader />}>
                    <DashboardRouter />
                  </Suspense>
                }
              />
            )}
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/new/*"
        element={
          <ProtectedRoute>
            <NewAppRouter isNewView toggleNewView={() => {}} />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/newscenter/*"
        element={
          <ProtectedRoute>
            <ProtectedContainer
              page={
                <Suspense fallback={<Loader />}>
                  <NewsCenterRouter />
                </Suspense>
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contacts/*"
        element={
          <ProtectedRoute>
            <ProtectedContainer
              page={
                <Suspense fallback={<Loader />}>
                  <ContactsRouter />
                </Suspense>
              }
            />
          </ProtectedRoute>
        }
      />
      <Route path="/public/*" element={<PublicRouter />} />
      <Route
        path="/market/*"
        element={
          <ProtectedRoute>
            <ProtectedContainer page={<MarketRouter />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tools/*"
        element={
          <ProtectedRoute>
            <ProtectedContainer page={<ToolsRouter />} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/ui/*"
        element={
          <ProtectedRoute>
            <ProtectedContainer
              page={
                <Suspense fallback={<Loader />}>
                  <UI />
                </Suspense>
              }
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user/account-update/:id/:email/:language"
        element={<EmailConfirm />}
      />
      <Route
        path="/user/*"
        element={
          <ProtectedRoute>
            <ProtectedContainer page={<UserSettingsPage />} />
          </ProtectedRoute>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
