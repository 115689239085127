import { useCallback } from 'react';
import { Snackbar, useTheme } from '@mui/material';

// Components
import { IconButton } from '../IconButton/IconButton';
import { TFunctionResult } from 'i18next';

type NotificationProps = {
  open: boolean;
  message: string | TFunctionResult;
  onClose: () => void;
};

export const Notification = (props: NotificationProps) => {
  const theme = useTheme();

  const onClose = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      props.onClose();
    },
    [props]
  );

  return (
    <Snackbar
      open={props.open}
      autoHideDuration={5000}
      onClose={onClose}
      message={props.message}
      action={
        <>
          <IconButton
            icon={['fas', 'times']}
            sxIcon={{ color: theme.palette.background.default }}
            onClick={onClose}
          />
        </>
      }
    />
  );
};
