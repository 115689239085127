import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import { ContactCommunityGetAction } from '../modules/contacts/models/contacts.types';
import { DashboardViewType } from '../modules/dashboard/models/dashboard.types';
import Dashboard from '../modules/dashboard/pages/Dashboard/Dashboard';
import NewDashboard from '../modules/dashboard/pages/Dashboard/NewDashboard';
import { NewDashboardRouter } from '../new_router/AppRouter';

export const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
