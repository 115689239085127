import React from 'react';
import { Box, Button, Grid, Tab } from '@mui/material';
import clsx from 'clsx';
import {
  IconButtonChevronLeft,
  IconButtonChevronRight,
} from '../../new_shared/components/DesktopView/DesktopView';

const Tool = () => {
  const [leftSidebarOpen, setLeftSidebarOpen] = React.useState(true);
  const [rightSideBarOpen, setRightSideBarOpen] = React.useState(true);

  const toggleSidebarLeft = () => {
    setLeftSidebarOpen(!leftSidebarOpen);
  };
  const toggleSidebarRight = () => {
    setRightSideBarOpen(!rightSideBarOpen);
  };

  return (
    <Grid className="h-full">
      <Box className="h-[112px] p-4">Header</Box>
      <Grid container className="relative">
        {/* Navigation (Width fixed) */}
        {leftSidebarOpen && (
          <Grid
            item
            className="h-full p-4 w-tool-nav-width" // fixed width in tailwind.config
          >
            navigation
          </Grid>
        )}
        {/* Toggle Button */}
        {leftSidebarOpen ? (
          <IconButtonChevronLeft
            onClick={toggleSidebarLeft}
            left={`${300 - 24 / 2}px`} // $dashboard-nav-width - iconWidth(w-6) / 2
          />
        ) : (
          <IconButtonChevronRight onClick={toggleSidebarLeft} />
        )}
        {/* Right Component*/}
        <Grid
          item
          xs
          className="flex flex-row overflow-x-hidden p-4"
          style={{
            transition: 'margin-left 0.3s ease',
          }}
        >
          Main Content
        </Grid>
        {/* Toggle Button */}
        {rightSideBarOpen ? (
          <IconButtonChevronRight
            onClick={toggleSidebarRight}
            right={`${300 - 24 / 2}px`} // $tool-nav-width - iconWidth(w-6) / 2
          />
        ) : (
          <IconButtonChevronLeft onClick={toggleSidebarRight} right="0" />
        )}
        {rightSideBarOpen && (
          <Grid
            item
            className="h-full p-4 w-tool-nav-width" // fixed width in tailwind.config
          >
            Einstellungsbereich
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Tool;
