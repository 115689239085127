import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { OptionalObjectSchema } from 'yup/lib/object';
import { RequiredStringSchema } from 'yup/lib/string';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Components
import { Input } from '../../ui/Input/Input';
import { PrimaryLink } from '../../ui/PrimaryLink/PrimaryLink';
import { TextButton } from '../../ui/TextButton/TextButton';

// Hooks
import {
  regExpNumber,
  regExpSpecial,
  regExpUpper,
  useAuth,
} from '../../../modules/public/hooks/use-auth.hook';

// Models
import {
  PasswordChange as IPasswordChange,
  ResultState,
} from '../../models/shared.types';

// Styles
import styles from './PasswordChange.module.scss';
import { Icon } from '../../ui/Icon/Icon';

type ExtraField = {
  name: string;
  placeholder: string;
  type: string;
  validation: RequiredStringSchema<string | undefined, Record<string, any>>;
};

type PasswordChangeProps = {
  btnClasses?: string;
  extraFields?: ExtraField[];
  loading?: boolean;
  // success?: boolean | null;
  submit: string | TFunctionResult;
  // onReset?: () => void;
  onSubmit: (passwordUpdateData: IPasswordChange) => void;
};

export const PasswordChange = (props: PasswordChangeProps) => {
  const { fieldErrorMatchCheck, passwordStrengthCalc } = useAuth();
  const { t } = useTranslation();

  // React hook form validation schemas
  const resetChangeValidation = yup.object({
    passwordNew: yup
      .string()
      .min(8, 'minLength8')
      .matches(regExpUpper, 'upper')
      .matches(regExpNumber, 'number')
      .matches(regExpSpecial, 'special')
      .oneOf(
        [yup.ref('passwordNewConfirm'), null],
        t('auth.validation.passwordConfirm')
      )
      .required(t('auth.validation.passwordReq')),
    // .matches(
    //   /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //   t('auth.validation.passwordChar')
    // ),
    passwordNewConfirm: yup
      .string()
      .notOneOf(
        [yup.ref('password'), null],
        t('user.security.change.error.conflict')
      )
      .oneOf(
        [yup.ref('passwordNew'), null],
        t('auth.validation.passwordConfirm')
      )
      .required(t('auth.validation.passwordReq')),
  });

  const {
    formState: { dirtyFields, errors, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<IPasswordChange>({
    criteriaMode: 'all',
    mode: 'onChange',
    resolver: yupResolver(resetChangeValidation),
  });
  const passwordNew = watch('passwordNew');
  const passwordNewConfirm = watch('passwordNewConfirm');

  let passwordStrength = '0%';
  if (dirtyFields.passwordNew && errors?.passwordNew?.types) {
    passwordStrength = passwordStrengthCalc(errors.passwordNew?.types);
  }
  if (dirtyFields.passwordNew && !errors.passwordNew) {
    passwordStrength = '100%';
  }

  // Component state
  const [validation, setValidation] = useState<OptionalObjectSchema<any>>(
    resetChangeValidation
  );
  const [passwordNewConfrimFocus, setPasswordNewConfrimFocus] =
    useState<boolean>(false);

  // Assign extra fields to validation
  useEffect(() => {
    if (props.extraFields && props.extraFields.length > 0) {
      let extraFieldsObject = yup.object();
      for (let extraField of props.extraFields) {
        const extraFieldObject = yup.object({
          [extraField.name]: extraField.validation,
        });
        extraFieldsObject = extraFieldsObject.concat(extraFieldObject);
      }
      extraFieldsObject = extraFieldsObject.concat(validation);
      setValidation(extraFieldsObject);
    }
    // eslint-disable-next-line
  }, [props.extraFields]);

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      {/* #TODO: Reset form after success
      #TODO: index.js:1 Warning: unstable_flushDiscreteUpdates: Cannot flush updates when React is already rendering.
      if (isSubmitSuccessful && props.success) {
        reset();
        props.onReset && props.onReset();
      } */}
      {props.extraFields &&
        props.extraFields?.length > 0 &&
        props.extraFields.map((extraField: ExtraField) => (
          <Fragment key={extraField.name}>
            <Input
              placeholder={extraField.placeholder}
              message={
                errors[extraField.name] &&
                errors[extraField.name]?.types?.required?.toString()
              }
              register={register(extraField.name)}
              state={errors[extraField.name] && ResultState.Error}
              type={extraField.type}
              classes={styles['password-change-extra-field']}
            />
          </Fragment>
        ))}
      {props.extraFields && props.extraFields?.length > 0 && (
        <div className={styles['password-change-link']}>
          <PrimaryLink to="/password-reset">
            {t('auth.password.forgot')}
          </PrimaryLink>
        </div>
      )}
      <div className={styles['password-change-text']}>
        {t('auth.password.change.text')}
      </div>
      <div className={styles['password-change-strength']}>
        <span className={styles['password-change-strength-text']}>
          {t('auth.validation.passwordStrength')}
        </span>
        <Box
          sx={{ backgroundColor: 'primary.light' }}
          className={styles['password-change-strength-progress']}
        >
          <Box
            sx={{
              backgroundColor: 'primary.main',
              width: passwordStrength,
            }}
            className={styles['password-change-strength-progress-inner']}
          ></Box>
        </Box>
      </div>
      <Input
        classes="mt-4"
        placeholder={t('auth.password.change.passwordNew')}
        register={register('passwordNew')}
        type={'password'}
        message={
          errors?.passwordNew
            ? errors.passwordNew.types?.required?.toString()
            : undefined
        }
      />
      <div className={styles['password-change-new']}>
        <p className={styles['password-change-new-text']}>
          {t('auth.validation.passwordChar1')}
        </p>
        <p className={styles['password-change-check']}>
          <span className={styles['password-change-check']}>
            {dirtyFields.passwordNew &&
            errors &&
            !errors?.passwordNew?.types?.min ? (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['fas', 'check']}
                sx={{ color: 'success.main' }}
                size="inherit"
              />
            ) : (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['far', 'circle']}
                sx={{ color: 'text-primary' }}
                size="inherit"
              />
            )}
          </span>
          <span className={styles['password-change-check-match']}>
            {t('auth.validation.passwordChar2')}
          </span>
        </p>
        <p className={styles['password-change-check']}>
          <span className={styles['password-change-check']}>
            {dirtyFields.passwordNew &&
            errors &&
            !fieldErrorMatchCheck(
              errors?.passwordNew?.types?.matches,
              'upper'
            ) ? (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['fas', 'check']}
                sx={{ color: 'success.main' }}
                size="inherit"
              />
            ) : (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['far', 'circle']}
                sx={{ color: 'text-primary' }}
                size="inherit"
              />
            )}
          </span>
          <span className={styles['password-change-check']}>
            {t('auth.validation.passwordChar3')}
          </span>
          <span className={styles['password-change-check-match']}>
            {t('auth.validation.passwordChar4')}
          </span>
        </p>
        <p className={styles['password-change-check']}>
          <span className={styles['password-change-check']}>
            {dirtyFields.passwordNew &&
            errors &&
            !fieldErrorMatchCheck(
              errors?.passwordNew?.types?.matches,
              'number'
            ) ? (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['fas', 'check']}
                sx={{ color: 'success.main' }}
                size="inherit"
              />
            ) : (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['far', 'circle']}
                sx={{ color: 'text-primary' }}
                size="inherit"
              />
            )}
          </span>
          <span className={styles['password-change-check']}>
            {t('auth.validation.passwordChar5')}
          </span>
          <span className={styles['password-change-check-match']}>
            {t('auth.validation.passwordChar6')}
          </span>
        </p>
        <p className={styles['password-change-check']}>
          <span className={styles['password-change-check']}>
            {dirtyFields.passwordNew &&
            errors &&
            !fieldErrorMatchCheck(
              errors?.passwordNew?.types?.matches,
              'special'
            ) ? (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['fas', 'check']}
                sx={{ color: 'success.main' }}
                size="inherit"
              />
            ) : (
              <Icon
                classes={styles['password-change-check-icon']}
                icon={['far', 'circle']}
                sx={{ color: 'text-primary' }}
                size="inherit"
              />
            )}
          </span>
          <span className={styles['password-change-check']}>
            {t('auth.validation.passwordChar7')}
          </span>
          <span className={styles['password-change-check-match']}>
            {t('auth.validation.passwordChar8')}
          </span>
        </p>
      </div>
      <Input
        register={register('passwordNewConfirm')}
        classes={styles['password-change-new-confirm']}
        state={
          !passwordNewConfrimFocus
            ? ((passwordNewConfirm && passwordNewConfirm !== passwordNew) ||
                errors?.passwordNew?.types?.min ||
                fieldErrorMatchCheck(
                  errors?.passwordNew?.types?.matches,
                  'number'
                ) ||
                fieldErrorMatchCheck(
                  errors?.passwordNew?.types?.matches,
                  'upper'
                ) ||
                fieldErrorMatchCheck(
                  errors?.passwordNew?.types?.matches,
                  'special'
                )) &&
              dirtyFields.passwordNewConfirm &&
              dirtyFields.passwordNew
              ? ResultState.Error
              : undefined
            : undefined
        }
        placeholder={t('auth.password.change.passwordNewConfirm')}
        type={'password'}
        onBlur={() => setPasswordNewConfrimFocus(false)}
        onFocus={() => setPasswordNewConfrimFocus(true)}
        message={
          !passwordNewConfrimFocus
            ? passwordNewConfirm && passwordNewConfirm !== passwordNew
              ? t('auth.validation.passwordConfirm')
              : (errors?.passwordNew?.types?.min ||
                  fieldErrorMatchCheck(
                    errors?.passwordNew?.types?.matches,
                    'number'
                  ) ||
                  fieldErrorMatchCheck(
                    errors?.passwordNew?.types?.matches,
                    'upper'
                  ) ||
                  fieldErrorMatchCheck(
                    errors?.passwordNew?.types?.matches,
                    'special'
                  )) &&
                dirtyFields.passwordNewConfirm &&
                dirtyFields.passwordNew
              ? t('auth.validation.passwordCondition')
              : undefined
            : undefined
        }
      />
      <div className={clsx(props.btnClasses && props.btnClasses)}>
        <TextButton
          disabled={!isValid}
          loading={props.loading}
          preset="primary"
          type="submit"
          classes={styles['password-change-button']}
        >
          {props.submit}
        </TextButton>
      </div>
    </form>
  );
};
