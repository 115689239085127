// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioButton_radio-button__3Ddfd {\n    display: flex\n}\n  .RadioButton_radio-button-center__rR\\+y7 {\n    align-items: center\n}\n  .RadioButton_radio-button-content__9jmsW {\n    margin-left: 0.5rem;\n    display: flex;\n    flex-direction: column;\n    border-radius: 0.125rem\n}\n    .RadioButton_radio-button-content-subtitle__CDY1v {\n    font-size: 0.875rem;\n    line-height: 1.25rem\n}\n    .RadioButton_radio-button-content-title__-L37d {\n    font-weight: 600\n}\n  .RadioButton_radio-button-elem__Kx6MI {\n    height: 2rem;\n    width: 2rem;\n    flex-shrink: 0;\n    padding: 0.125rem\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA;CAAA;EAAA;IAAA;CAAA;EAAA;IAAA,oBAAA;IAAA,cAAA;IAAA,uBAAA;IAAA;CAAA;IAAA;IAAA,oBAAA;IAAA;CAAA;IAAA;IAAA;CAAA;EAAA;IAAA,aAAA;IAAA,YAAA;IAAA,eAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio-button": "RadioButton_radio-button__3Ddfd",
	"radio-button-center": "RadioButton_radio-button-center__rR+y7",
	"radio-button-content": "RadioButton_radio-button-content__9jmsW",
	"radio-button-content-subtitle": "RadioButton_radio-button-content-subtitle__CDY1v",
	"radio-button-content-title": "RadioButton_radio-button-content-title__-L37d",
	"radio-button-elem": "RadioButton_radio-button-elem__Kx6MI"
};
export default ___CSS_LOADER_EXPORT___;
