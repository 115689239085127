// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit__M2Xxh {\n    display: flex;\n    width: 100%;\n    flex-direction: column\n}\n  .ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit-buttons__pTZqc {\n    margin-top: 1rem;\n    display: flex;\n    align-items: center;\n    align-self: flex-end\n}\n  @media (min-width: 1024px) {\n    .ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit-buttons__pTZqc {\n        margin-top: 0px\n    }\n}\n    .ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit-buttons__pTZqc button {\n    margin-left: 0.5rem\n}\n\n.ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit-children__cnVTm {\n    display: flex;\n    flex-direction: column\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA,YAAA;IAAA;CAAA;EAAA;IAAA,iBAAA;IAAA,cAAA;IAAA,oBAAA;IAAA;CAAA;EAAA;IAAA;QAAA;KAAA;CAAA;IAAA;IAAA;CAAA;;AAAA;IAAA,cAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tool-multi-element-row-create-edit": "ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit__M2Xxh",
	"tool-multi-element-row-create-edit-buttons": "ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit-buttons__pTZqc",
	"tool-multi-element-row-create-edit-children": "ToolMultiElementRowCreateEdit_tool-multi-element-row-create-edit-children__cnVTm"
};
export default ___CSS_LOADER_EXPORT___;
