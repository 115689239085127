import { TFunctionResult } from 'i18next';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

// Models
import {
  CrudState,
  MenuItem,
  Option,
} from '../../../shared/models/shared.types';
import {
  Template,
  TemplateAction,
  TemplateElementCreateEditState,
  TemplateElementType,
  TemplatePreview,
  TemplateSettingsState,
  TemplateStatus,
} from '../../templates/models/templates.types';
import {
  CreateRowRequest,
  ToolDetailAction,
  ToolPermission,
  ToolUser,
} from '../models/tools.types';

// Stores
import { useToolsStore } from '../stores/use-tools.store';
import { useUserStore } from '../../user/stores/use-user.store';
import { useTemplatesStore } from '../../templates/stores/use-templates.store';
import { MarketTemplate } from '../../market/models/market.types';
import { useMutation } from 'react-query';
import { useToolsHttp } from './use-tools-http.hook';
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

export const useTools = () => {
  const { t } = useTranslation();
  const { handleRetry } = useFetch();
  const { toolGet } = useToolsHttp();

  // Templates store state
  const [selectedTemplate] = useTemplatesStore((state) => [
    state.selectedTemplate,
  ]);

  // Tools store state
  const [tool, selectedTool, setSelectedTool, setTool, setIsToolLoading] =
    useToolsStore((state) => [
      state.tool,
      state.selectedTool,
      state.setSelectedTool,
      state.setTool,
      state.setIsToolLoading,
    ]);

  // User store state
  const [account] = useUserStore((state) => [state.account]);

  // GET Tool mutation
  const toolGetMutation = useMutation((id: string) => toolGet(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled: (data) => {
      setIsToolLoading(false);
      if (data) {
        setTool(data);
      }
    },
  });

  /**
   * Returns menu action items.
   * @returns PopupMenuItem array
   */
  const actionsGet = (): MenuItem[] => {
    return [
      // {
      //   action: TemplateAction.Update,
      //   title: t('templates.actions.update.title'),
      // },
      // {
      //   action: TemplateAction.Finish,
      //   title: t('templates.actions.finish'),
      // },
      {
        action: TemplateAction.Settings,
        title: t('templates.actions.settings'),
      },
      {
        action: TemplateAction.Publish,
        disabled: selectedTemplate?.status?.includes(TemplateStatus.Published),
        title: t('templates.actions.publish'),
      },
      {
        action: TemplateAction.No_Publish,
        disabled: !selectedTemplate?.status?.includes(TemplateStatus.Published),
        title: t('templates.actions.no_publish.title'),
      },
      // {
      //   action: TemplateAction.Print,
      //   title: t('templates.actions.print'),
      // },
      {
        action: TemplateAction.Delete,
        disabled: selectedTemplate?.status?.includes(TemplateStatus.Published),
        title: t('templates.actions.delete'),
      },
      {
        action: TemplateAction.Copy,
        title: 'Vorlage duplizieren',
      },
    ];
  };

  /**
   * GET Tool detail menu items by breakpoint.
   * @param lgDown LgDown
   * @param users ToolUser array
   * @returns Tool detail menu items
   */
  const detailActionsGet = (
    lgDown: boolean,
    users?: ToolUser[]
  ): MenuItem[] => {
    // Get own tool permission type
    let permission: ToolPermission | undefined = ToolPermission.Read;
    if (users) {
      permission = permissionGet(users);
    }

    const menuItems = [
      {
        action: ToolDetailAction.Settings,
        disabled: permission !== ToolPermission.Admin,
        title: t('tools.detail.actions.settings'),
      },
      {
        action: ToolDetailAction.Delete,
        disabled: permission !== ToolPermission.Admin,
        title: t('tools.detail.actions.delete.title_menu'),
      },
    ];
    if (lgDown) {
      return menuItems.concat({
        action: ToolDetailAction.Share,
        disabled: false,
        title: t('tools.detail.actions.share'),
      });
    } else {
      return menuItems;
    }
  };

  /**
   * GET Tool detail user permission options.
   * @returns User permission options.
   */
  const detailUserPermissionsGet = (): Option[] => {
    return [
      {
        placeholder: t('tools.detail.users.permissions.admin'),
        value: ToolPermission.Admin,
      },
      {
        placeholder: t('tools.detail.users.permissions.write'),
        value: ToolPermission.Write,
      },
      {
        placeholder: t('tools.detail.users.permissions.read'),
        value: ToolPermission.Read,
      },
    ];
  };

  /**
   * Returns element create edit button title by state.
   * @param state ToolElementCreateEditState
   * @returns Button title
   */
  const elementCreateEditButtonTitleGet = (
    state: TemplateElementCreateEditState
  ): TFunctionResult => {
    switch (state) {
      case TemplateElementCreateEditState.Content:
        return t('templates.elements.create.content.title');
      case TemplateElementCreateEditState.Name:
        return t('templates.elements.create.name.title');
      case TemplateElementCreateEditState.Image:
        return t('templates.elements.create.image.title');
      case TemplateElementCreateEditState.Options:
        return t('templates.elements.create.options.title');
      case TemplateElementCreateEditState.Vote:
        return t('templates.elements.create.vote.title');
      case TemplateElementCreateEditState.Settings:
        return t('templates.elements.create.settings.title');
      case TemplateElementCreateEditState.InputSetting:
        return 'Auswahl des Eingabetyps';
      case TemplateElementCreateEditState.Summary:
        return t('templates.elements.create.summary');
    }
  };

  /**
   * GET Tool permission by tool users.
   * @param users Tool users
   * @returns ToolPermission
   */
  const permissionGet = (users: ToolUser[]): ToolPermission => {
    if (account?.id) {
      const matchedUser = users.find((user) => user.id === account.id);
      if (matchedUser) {
        return matchedUser.permission;
      }
    }
    return ToolPermission.Read;
  };

  /**
   * Adds a new section to selected tool.
   * @param selectedTool Selected tool
   * @returns Selected tool with new section.
   */
  const sectionAdd = (selectedTool: Template): Template => {
    const sections = selectedTool.sections;
    sections?.push({
      id: uuidv4(),
      name: 'Abschnitt neu',
      elements: [
        {
          id: '79a57955-4ea1-42be-b7b8-f2ffb9489487',
          name: t('templates.sections.elements.example.name'),
          settings: {
            rights: {
              employee: true,
              member: true,
              pengueen_admin: true,
            },
          },
          type: TemplateElementType.Input,
        },
      ],
      settings: {
        restricted: false,
      },
    });

    return {
      ...selectedTool,
      sections,
    };
  };

  /**
   * Returns tool setting subtitle by settings state.
   * @param settingsState Settings state
   * @returns I18n string
   */
  const settingsSubtitleGet = (
    settingsState: TemplateSettingsState
  ): TFunctionResult => {
    switch (settingsState) {
      case TemplateSettingsState.Interface:
        return t('templates.settings.interface.subtitle');
      case TemplateSettingsState.Notification:
        return t('templates.settings.notification.subtitle');
      case TemplateSettingsState.Description:
        return t('templates.settings.description.subtitle');
      // case TemplateSettingsState.Extra:
      //   return t('templates.settings.extra.subtitle');
      case TemplateSettingsState.Icon:
        return t('templates.settings.icon.subtitle');
      // case TemplateSettingsState.Purpose:
      //   return t('templates.settings.purpose.subtitle');
      case TemplateSettingsState.Summary:
        return t('templates.settings.summary.subtitle');
      default:
        return t('templates.settings.name.subtitle');
    }
  };

  /**
   * Returns tool setting title by settings state.
   * @param settingsCrudState CRUD State
   * @param settingsState Settings state
   * @returns I18n string
   */
  const settingsTitleGet = (
    settingsCrudState: CrudState,
    settingsState: TemplateSettingsState
  ): TFunctionResult => {
    switch (settingsState) {
      case TemplateSettingsState.Interface:
        return t('templates.settings.interface.title');
      case TemplateSettingsState.Notification:
        return t('templates.settings.notification.title');
      case TemplateSettingsState.Description:
        return t('templates.settings.description.title');
      // case TemplateSettingsState.Extra:
      //   return t('templates.settings.extra.title');
      case TemplateSettingsState.Icon:
        return t('templates.settings.icon.title');
      // case TemplateSettingsState.Purpose:
      //   return t('templates.settings.purpose.title');
      case TemplateSettingsState.Summary:
        if (settingsCrudState === CrudState.Create) {
          return t('templates.settings.summary.title.create');
        } else {
          return t('templates.settings.summary.title.edit');
        }
      default:
        return t('templates.settings.name.title');
    }
  };

  /**
   * PATCH Tool users.
   * @param users ToolUser
   */
  const usersPatchEffect = (users: ToolUser[]) => {
    if (selectedTool && selectedTool.users) {
      const updatedTool = {
        ...selectedTool,
        users: selectedTool.users?.concat(users),
      };
      setSelectedTool(updatedTool);
    }
  };

  /**
   * REMOVE Tool user by id.
   * @param id User id
   */
  const userRemoveEffect = (id: string) => {
    if (selectedTool && selectedTool.users) {
      const updatedTool = {
        ...selectedTool,
        users: selectedTool.users?.filter((user) => user.id !== id),
      };
      setSelectedTool(updatedTool);
    }
  };

  const filterdTemplatePush = (
    templates: any[],
    template: TemplatePreview | MarketTemplate
  ): any[] => {
    !templates.some((template_) => template_.id === template.id) &&
      templates.push(template);

    return templates;
  };

  // #TODO optimization
  const updateTool = (id: string): void => {
    toolGetMutation.mutate(id);
  };

  const afterRowCreate = (data: CreateRowRequest): void => {
    if (tool) {
      const updatedTool = { ...tool };
      if (updatedTool?.values) {
        const template_section = updatedTool?.template.sections?.find(
          (sectionTemplate) => sectionTemplate.id === data.section_id
        );
        const template_MFE = template_section?.elements?.find(
          (element) => element.id === data.element_id
        );
        const section_value = updatedTool?.values.find(
          (sectionValues) => sectionValues.id === data.section_id
        );
        if (section_value) {
          const rowValues = section_value.elements.find(
            (element) => element.id === data.element_id
          );
          if (rowValues && rowValues.rows && data.row) {
            const newRows = [...rowValues.rows, data.row];
            rowValues.rows = newRows;

            if (template_MFE) {
              const values = [...template_MFE.value, data.row];
              template_MFE.value = values;
            }
          }
        }
        const updatedValues = [...updatedTool.values];
        updatedTool.values = updatedValues;
        updatedTool && setTool(updatedTool);
      }
    }
  };

  return {
    actionsGet,
    detailActionsGet,
    detailUserPermissionsGet,
    elementCreateEditButtonTitleGet,
    permissionGet,
    sectionAdd,
    settingsSubtitleGet,
    settingsTitleGet,
    usersPatchEffect,
    userRemoveEffect,
    filterdTemplatePush,
    updateTool,
    afterRowCreate,
  };
};
