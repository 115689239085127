import React, { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Drawer, Grid } from '@mui/material';
import { IconButton } from '../../shared/ui/IconButton/IconButton';
import {
  UserState,
  useUserStore,
} from '../../modules/user/stores/use-user.store';
import {
  ImageFallbackType,
  Theme,
  thinScroll,
  UpdateUserInfoType,
} from '../../shared/models/shared.types';
import { TextButton } from '../../shared/ui/TextButton/TextButton';
import { ImageFallback } from '../../shared/components/ImageFallback/ImageFallback';
import { useLogout } from '../../modules/public/hooks/use-logout.hook';
import { useUsersHttp } from '../../modules/user/hooks/use-users-http.hook';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useFetch } from '../../shared/hooks/use-fetch.hook';
import { useFilePicker } from 'use-file-picker';
import { useUsers } from '../../modules/user/hooks/use-users.hook';
import {
  Account,
  Profile as IProfile,
  UserInfoData,
} from '../../modules/user/models/user.types';
import { useForm } from 'react-hook-form';
import { TFunctionResult } from 'i18next';
import { Notification } from '../../shared/ui/Notification/Notification';
import Typography from '@mui/material/Typography';
import IconSwitchButton from '../../shared/ui/IconSwitchButton/IconSwitchButton';
import {
  SharedState,
  useSharedStore,
} from '../../shared/stores/use-shared.store';
import { Select } from '../../shared/ui/Select/Select';
import { InputFlat } from '../../shared/ui/InputFlat/InputFlat';
import pdf from '../../assets/pictures/pdf.png';
import { Icon } from '../../shared/ui/Icon/Icon';
import SecuritySettings from './SecuritySettings';
import NotificationSettings from './NotificationSettings';

const ProfileDrawer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { logout } = useLogout();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const {
    userThemePost,
    logoutPost,
    userInfoUpdate,
    userInfoGet,
    avatarPost,
    userProfileGet,
    userProfileUpdate,
  } = useUsersHttp();
  const { avatarPostEffect } = useUsers();

  const [openFileSelector, { plainFiles }] = useFilePicker({
    accept: ['.jpg', '.jpeg', '.png'],
  });

  // Component state
  const [notification, setNotification] = useState<
    string | TFunctionResult | undefined
  >(undefined);
  const [viewError, setViewError] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  // const [profile, setprofile] = useState(second)
  const [changedValue, setChangedValue] = useState<UserInfoData | undefined>(
    undefined
  );
  const [userInfoData, setUserInfoData] = useState<UserInfoData | undefined>(
    undefined
  );
  // Shared store state
  const [theme, setTheme] = useSharedStore((state: SharedState) => [
    state.theme,
    state.setTheme,
  ]);

  // User store state
  const [account, setAccount, setActiveCommunity, setSettingsActive] =
    useUserStore((state: UserState) => [
      state.account,
      state.setAccount,
      state.setActiveCommunity,
      state.setSettingsActive,
    ]);
  // #### //
  // FORM //
  // #### //

  const { handleSubmit, register, reset, setValue, watch } =
    useForm<IProfile>();
  const addressCountry = watch('address.country') ?? '';
  //   const watchLanguage = watch('language') ?? account?.language;
  //   const { setValue, watch } = useForm<LanguageUpdate>();

  // ######### //
  // MUTATIONS //
  // ######### //

  // POST Avatar mutation
  const avatarPostMutation = useMutation((file: File) => avatarPost(file), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
  });

  // Handle POST avatar
  useEffect(() => {
    if (avatarPostMutation.data) {
      // setAvatarData(avatarUploadMutation.data);

      avatarPostEffect(avatarPostMutation.data);
      // setAvatarData
      viewError && setViewError(null);
    }
    if (avatarPostMutation.error) {
      const errRes = avatarPostMutation.error?.response;
      if (errRes) {
        setViewError(handleError(errRes.status));
      }
    }
    // eslint-disable-next-line
  }, [avatarPostMutation.data, avatarPostMutation.error]);

  // PATCH account mutation
  const userInfoUpdateMutation = useMutation(
    (data: UserInfoData) => userInfoUpdate(data),
    {
      onSuccess(data, variables) {
        if (variables.email) {
          if (account) {
            const updatedAccount: Account = { ...account };
            updatedAccount.email = variables.email;
            setAccount(updatedAccount);
          }
        }
      },
    }
  );

  const userInfoGetMutation = useMutation(() => userInfoGet(), {
    onSuccess(data) {
      if (data) {
        setUserInfoData(data);
      }
    },
  });

  // GET contacts mutation
  const logoutPostMutation = useMutation(() => logoutPost(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
  });

  // Detect selected file change
  useEffect(() => {
    if (plainFiles && plainFiles[0]) {
      if (plainFiles[0].name !== selectedFile?.name) {
        setSelectedFile(plainFiles[0]);
      }
    }
    // eslint-disable-next-line
  }, [plainFiles]);

  // Upload / Update avatar on selected file change
  useEffect(() => {
    if (selectedFile) {
      avatarPostMutation.mutate(selectedFile);
    }
    // eslint-disable-next-line
  }, [selectedFile]);

  useEffect(() => {
    userInfoGetMutation.mutate();
  }, []);

  const onDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onLogout = useCallback(() => {
    logout();
    logoutPostMutation.mutate();
    setOpen(false);
    // #TODO: Blacklist token?
    navigate('/');
    // eslint-disable-next-line
  }, []);

  const updateUserInfo = useCallback(
    (type: UpdateUserInfoType, value: string | boolean, mutate?: boolean) => {
      if (userInfoData) {
        const userInfo: UserInfoData = { ...userInfoData };

        if (type === 'theme') {
          const selectedTheme = value ? Theme.Light : Theme.Dark;
          userInfo[type] = selectedTheme;
          setTheme(selectedTheme);
        } else if (type === 'sms_factor') {
          userInfo[type] = value ? true : false;
        } else if (typeof value === 'string') {
          userInfo[type] = value;
        }

        setUserInfoData(userInfo);

        if (mutate) {
          const changedValue: UserInfoData = {
            [type]:
              type === 'theme' ? (value ? Theme.Light : Theme.Dark) : value,
          };
          userInfoUpdateMutation.mutate(changedValue);
        }
      }
    },
    [userInfoData, setUserInfoData, setChangedValue]
  );

  const handelUserNotifications = useCallback(
    (
      type:
        | 'email'
        | 'sms'
        | 'web'
        | 'community'
        | 'tool'
        | 'group'
        | 'message'
        | 'network',
      checked: boolean
    ) => {
      if (userInfoData?.notification) {
        const userInfo: UserInfoData = { ...userInfoData };
        userInfo.notification![type] = checked;
        setUserInfoData(userInfo);

        userInfoUpdateMutation.mutate({
          notification: userInfo.notification,
        });
      }
    },
    []
  );

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: 'pl-4 md:pl-6 w-[650px]',
      }}
      sx={{ backgroundImage: 'none', ...thinScroll }}
      open={open}
      onClose={onDrawerClose}
    >
      {/********************/}
      {/*      HEADER      */}
      {/********************/}
      <Box className="flex items-center justify-between pt-4 pb-6">
        <Box className="font-semibold text-sm lg:text-lg">
          {t('user.title')}
        </Box>
        <IconButton icon={['fas', 'times']} onClick={onDrawerClose} />
      </Box>
      {/********************/}
      {/*      AVATAR      */}
      {/********************/}
      <Box
        className=" flex flex-wrap"
        sx={{
          borderColor: 'border.app',
        }}
      >
        <Box className="relative mr-4">
          {userInfoData?.avatar?.url ? (
            <img
              alt="User Avatar"
              className="h-20 w-20 md:h-24 md:w-24 object-cover rounded-md "
              src={`https://files.pengueen.de/api/download/${userInfoData.avatar.url}`}
            />
          ) : (
            <ImageFallback
              iconSize={'3em'}
              sizeClassName="h-20 w-20 md:h-24 md:w-24 object-cover rounded-md "
              type={ImageFallbackType.Profile}
            />
          )}
          <IconButton
            classes="absolute w-6 h-6 box-border flex items-center justify-center p-2 rounded-full shadow -right-3 -top-3"
            icon={['fas', 'pen']}
            iconColor="text-app"
            preset="popover-app"
            sxIcon={{ padding: 0.25 }}
            onClick={() => openFileSelector()}
          />
          {userInfoData?.avatar &&
            Object.keys(userInfoData?.avatar).length !== 0 && (
              <IconButton
                classes="absolute w-6 h-6 flex items-center justify-center p-2 rounded-full shadow -right-3 -bottom-1"
                icon={['fas', 'times']}
                iconColor="text-app"
                preset="popover-app"
                sxIcon={{ padding: 0.25 }}
                // onClick={() =>
                //   profile?.avatar?.id &&
                //   avatarDeleteMutation.mutate(profile.avatar.id)
                // }
              />
            )}
        </Box>
        <Box className="flex flex-col justify-between py-1">
          <Box>
            <Box className="flex font-semibold text-sm mr-1">
              {userInfoData
                ? `${userInfoData.first_name ?? ''} ${
                    userInfoData.last_name ?? ''
                  }`
                : null}
            </Box>
            <Box className="flex font-semibold text-sm mr-1 underline decoration-double">
              {account?.email}
            </Box>
          </Box>
          <TextButton
            textSize={'text-sm'}
            padding={'0.4rem 0.75rem'}
            preset="secondary"
            onClick={onLogout}
          >
            {t('user.logout')}
          </TextButton>
        </Box>
      </Box>
      <Box className="flex flex-col py-4 ">
        {/*********************/}
        {/*      PROFILE      */}
        {/*********************/}
        <Grid container spacing={2} className="pl-4 pt-4">
          <Grid xs={12} className="flex flex-col py-2">
            <Typography className="font-semibold text-lg">
              Persönliche Daten
            </Typography>
            <Typography className="text-sm">
              Diese Angaben werden anderen Nutzer:Innen mit denen du bei
              Pengueen verbunden bist angezeigt.
            </Typography>
          </Grid>
          <Grid xs={12} md={6} className="pr-5 pt-2">
            <InputFlat
              label={t('form.profile.first_name.title')}
              placeholder={t('form.profile.first_name.placeholder')}
              value={userInfoData?.first_name}
              onChange={(value) => updateUserInfo('first_name', value)}
              onBlur={() =>
                updateUserInfo(
                  'first_name',
                  userInfoData?.first_name || '',
                  true
                )
              }
            />
          </Grid>
          <Grid xs={12} md={6} className="pt-2 pr-1">
            <InputFlat
              label={t('form.profile.last_name.title')}
              placeholder={t('form.profile.last_name.placeholder')}
              value={userInfoData?.last_name}
              onChange={(value) => updateUserInfo('last_name', value)}
              onBlur={() =>
                updateUserInfo('last_name', userInfoData?.last_name || '', true)
              }
            />
          </Grid>
          <Grid xs={12} className="pt-2 pr-5">
            <InputFlat
              label="Anschrift"
              value={userInfoData?.address_all}
              onChange={(value) => updateUserInfo('address_all', value)}
              onBlur={() =>
                updateUserInfo(
                  'address_all',
                  userInfoData?.address_all || '',
                  true
                )
              }
            />
          </Grid>
          <Grid xs={12} md={6} className="pt-2 pr-5">
            <InputFlat
              label="Email"
              value={userInfoData?.email_for_public}
              onChange={(value) => updateUserInfo('email_for_public', value)}
              onBlur={() =>
                updateUserInfo(
                  'email_for_public',
                  userInfoData?.email_for_public || '',
                  true
                )
              }
            />
          </Grid>
          <Grid xs={12} md={6} className="pt-2 pr-1">
            <InputFlat
              label="Handy"
              value={userInfoData?.phone_for_public}
              onChange={(value) => updateUserInfo('phone_for_public', value)}
              onBlur={() =>
                updateUserInfo(
                  'phone_for_public',
                  userInfoData?.phone_for_public || '',
                  true
                )
              }
            />
          </Grid>
          <Grid xs={12} md={6} className="pt-2 pr-5"></Grid>
        </Grid>

        {/********************************/}
        {/*      Benachrichtigungen      */}
        {/********************************/}
        <NotificationSettings
          handelUserNotifications={handelUserNotifications}
          userInfoData={userInfoData}
          updateUserInfo={updateUserInfo}
        />
        {/**************************************/}
        {/*      Sicherheitseinstellungen      */}
        {/**************************************/}
        <SecuritySettings
          updateUserInfo={updateUserInfo}
          userInfoData={userInfoData}
        />
        {/***********************/}
        {/*      Dokumente      */}
        {/***********************/}
        <Grid container spacing={2} className="pl-4 pt-6">
          <Grid xs={12} className="flex flex-col py-2">
            <Typography className="font-semibold text-lg">
              Wichtige Dokumente
            </Typography>
            <Grid xs={12} className="pr-1 pt-2 flex flex-row items-center">
              <Box className="pr-2">
                <img src={pdf} className="w-8" />
              </Box>
              <Box className="flex flex-col">
                <Typography className="text-sm">Allgemeine</Typography>
                <Typography className="text-sm">Nutzungsbedingungen</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/**************************************/}
        {/*      Theme-einstellungen      */}
        {/**************************************/}
        <Grid container spacing={2} className="pl-4 pt-6">
          <Grid xs={12} className="flex flex-col py-2">
            <Typography className="font-semibold text-lg">
              {t('user.theme.title')}
            </Typography>
            <Typography className="text-sm">
              Wähle die Sprache der Oberfläche und entscheide, ob du den
              Darkmode verwenden möchtest.
            </Typography>
          </Grid>
          <Grid xs={12} md={6} className="pt-2 pr-5">
            <Typography className="font-semibold text-base">
              {t('app.language')}
            </Typography>
            <Box sx={{ color: 'text.secondary ' }}>
              <Select
                options={[
                  {
                    placeholder: t('user.account.language.lng.de'),
                    value: 'de',
                  },
                  {
                    placeholder: t('user.account.language.lng.en'),
                    value: 'en',
                  },
                  {
                    placeholder: t('user.account.language.lng.it'),
                    value: 'it',
                  },
                ]}
                value={userInfoData?.language}
                onChange={(value) => updateUserInfo('language', value)}
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} className="pt-2 pr-1 flex flex-col">
            <Typography className="font-semibold text-base">
              Darkmode
            </Typography>
            <Box className="flex flex-row items-center py-2">
              <Box className="font-semibold text-xs md:text-sm">
                {t('user.theme.dark')}
              </Box>
              <IconSwitchButton
                checked={userInfoData?.theme === Theme.Light ? true : false}
                classes="mx-2 h-fit"
                iconOff={['fas', 'moon']}
                iconOn={['fas', 'sun']}
                sameColor
                onChange={(value) => updateUserInfo('theme', value)}
              />
              <Box className="font-semibold text-xs md:text-sm">
                {t('user.theme.light')}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {notification && (
        <Notification
          message={notification}
          open={notification ? true : false}
          onClose={() => setNotification(undefined)}
        />
      )}
    </Drawer>
  );
};

export default memo(ProfileDrawer);
