import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../../../shared/ui/Switch/Switch';
// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';

// Styles
import styles from './ToolElementToggle.module.scss';
import { TFunctionResult } from 'i18next';

type ToolElementToggleProps = {
  classes?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  sublabel?: string | TFunctionResult;
  help_text?: string;
  value?: any;
  required?: boolean;
  onChange: (value: string | undefined, id?: string) => void;
};

export const ToolElementToggle = (props: ToolElementToggleProps) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<any | null>(null);

  /**
   * Handler on date picker change
   * @param newValue Date
   * @param keyboardInputValue Keyboard input value
   */
  const onChange = useCallback(
    (newValue: any, keyboardInputValue?: string | undefined) => {
      setValue(newValue);
      props.onChange(newValue, props.id && props.id);
    },
    [props]
  );

  useEffect(() => {
    //props.value && setValue(props.value);
  }, [props.value]);

  return (
    <>
      <FormFieldLabel
        required={props.required}
        label={props.label ? props.label : ''}
        sublabel={props.sublabel}
        help_text={props.help_text}
        paddingClassName={
          styles['tool-element-date-picker-form-field-label-padding']
        }
      />
      <Switch
        disabled={props.disabled ?? false}
        classes={styles['template-publish-type-next']}
        checked={value}
        onChange={onChange}
      ></Switch>
    </>
  );
};

export default memo(ToolElementToggle);
