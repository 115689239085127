import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';

// Models
import { Theme } from '../../models/shared.types';

// Stores
import { SharedState, useSharedStore } from '../../stores/use-shared.store';

//Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';
import { Community } from '../../../modules/communities/models/communities.types';

type LogoProps = {
  classes?: string;
  imgClasses?: string;
  imgStyle?: CSSProperties;
  style?: CSSProperties;
  showBigLogo?: boolean;
  communities?: Community[];
};

export const Logo = (props: LogoProps) => {
  const { mdUp } = useBreakpoints();

  const [logo, setLogo] = useState<string | undefined>(undefined);

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  const updateLogo = () => {
    if (props.communities && props.communities.length === 1) {
      setLogo(
        props.communities[0].data.logo?.url ??
          require('../../../assets/logo/pengueen_light.svg').default.toString()
      );
    } else {
      if (props.showBigLogo) {
        setLogo(
          theme === Theme.Light
            ? require('../../../assets/logo/pengueen_light.svg').default.toString()
            : require('../../../assets/logo/pengueen_dark.svg').default.toString()
        );
      } else {
        setLogo(
          mdUp
            ? theme === Theme.Light
              ? require('../../../assets/logo/pengueen_light.svg').default.toString()
              : require('../../../assets/logo/pengueen_dark.svg').default.toString()
            : theme === Theme.Light
            ? require('../../../assets/logo/pengueen_mobile_light.svg').default.toString()
            : require('../../../assets/logo/pengueen_mobile_dark.svg').default.toString()
        );
      }
    }
  };

  const debouncedUpdateLogo = debounce(updateLogo, 1000);

  useEffect(() => {
    debouncedUpdateLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mdUp, theme, props.communities]);

  return (
    <Link to="/" className={props.classes} style={props.style}>
      {logo && (
        <img
          alt="Pengueen Logo"
          src={logo}
          style={props.imgStyle}
          className={props.imgClasses}
        />
      )}
    </Link>
  );
};
