import { Suspense, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Divider } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

// Components
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';
import { ImageFallback } from '../ImageFallback/ImageFallback';
import { IconButton } from '../../ui/IconButton/IconButton';
import { Logo } from '../Logo/Logo';
import { Tooltip } from '../../ui/Tooltip/Tooltip';

// Hooks
import { useBreakpoints } from '../../hooks/use-breakpoints.hook';
import { useFetch } from '../../hooks/use-fetch.hook';
import { useNewsCenterHttp } from '../../../modules/newscenter/hooks/use-news-center-http.hook';

// Models
import { ImageFallbackType, ResendEmail } from '../../models/shared.types';
import { initialNewsCenterFilterData } from '../../../modules/newscenter/models/news-center-filters.interface';
import { NewsCenterResponse } from '../../../modules/newscenter/models/news-center.types';

// Stores
import {
  UserState,
  useUserStore,
} from '../../../modules/user/stores/use-user.store';
import {
  NewsCenterState,
  useNewsCenterStore,
} from '../../../modules/newscenter/stores/use-news-center.store';
import { useSharedStore } from '../../stores/use-shared.store';

// Styles
import styles from './Header.module.scss';
import { TextButton } from '../../ui/TextButton/TextButton';
import {
  AuthState,
  useAuthStore,
} from '../../../modules/public/stores/use-auth.store';
import { ToggleButton } from '../../ui/ToggleButton/ToggleButton';
import { Switch } from '../../ui/Switch/Switch';
import { NavigationToggle } from '../../../new_shared/components/NavigationToggle/NavigationToggle';
import { useContactsHttp } from '../../../modules/contacts/hooks/use-contacts-http.hook';
import { Icon } from '../../../shared/ui/Icon/Icon';
import { Dialog } from '../../ui/Dialog/Dialog';
import { Loader } from '../../ui/Loader/Loader';
import { Input } from '../../ui/Input/Input';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAuth } from '../../../modules/public/hooks/use-auth.hook';

type HeaderProps = {
  public?: boolean;
  toggleNewView?: (checked: boolean) => void;
  isNewView?: boolean;
};

export const Header = (props: HeaderProps) => {
  const { smUp, lgDown, lgUp } = useBreakpoints();
  const { handleError, handleRetry } = useFetch();
  const { resendEmail } = useAuth();
  const { newsCenterGet, newsCenterUnreadGet } = useNewsCenterHttp();
  const { getPermissionForNewVersion } = useContactsHttp();
  const navigate = useNavigate();
  const [resendDialog, setResendDialog] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { t } = useTranslation();

  // Auth store state
  const [accessToken, setAccessToken] = useAuthStore((state: AuthState) => [
    state.accessToken,
    state.setAccessToken,
  ]);

  // News center store state
  const [
    // latestRooms,
    selectedRoom,
    directRooms,
    favoriteRooms,
    groupRooms,
    latestUnreadRooms,
    setDirectRooms,
    setFavoriteRooms,
    setFiltersData,
    setGroupRooms,
    setLatestUnreadRooms,
    setGodparentChat,
  ] = useNewsCenterStore((state: NewsCenterState) => [
    // state.latestRooms,
    state.selectedRoom,
    state.directRooms,
    state.favoriteRooms,
    state.groupRooms,
    state.latestUnreadRooms,
    state.setDirectRooms,
    state.setFavoriteRooms,
    state.setFiltersData,
    state.setGroupRooms,
    state.setLatestUnreadRooms,
    state.setGodparentsChat,
  ]);

  // Shared store state
  const [
    breadcrumbs,
    network,
    dashboard,
    newsCenter,
    workbench,
    userRouter,
    market,
  ] = useSharedStore((state) => [
    state.breadcrumbs,
    state.network,
    state.dashboard,
    state.newsCenter,
    state.workbench,
    state.userRouter,
    state.market,
  ]);

  // User store state
  const [
    account,
    setAccount,
    profile,
    // trial,
    unreadMessages,
    setDrawer,
    setUnreadMessages,
    communities,
  ] = useUserStore((state: UserState) => [
    state.account,
    state.setAccount,
    state.profile,
    // state.trial,
    state.unreadMessages,
    state.setDrawer,
    state.setUnreadMessages,
    state.communities,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET user data mutation
  const newsCenterGetMutation = useMutation(() => newsCenterGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSuccess: (data: NewsCenterResponse | undefined) => {
      if (data) {
        try {
          // Set news center data
          unstable_batchedUpdates(() => {
            data.groupChats && setGroupRooms(data.groupChats);
            data.privateChats && setDirectRooms(data.privateChats);
            data.invitorChat && setGodparentChat(data.invitorChat);
            data.unreadChats && setLatestUnreadRooms(data.unreadChats);
            data.favoriteChats && setFavoriteRooms(data.favoriteChats);

            let unread = 0;
            data.groupChats.map((chat) => {
              if (chat.unread_msgs && chat.unread_msgs > 0) {
                unread += chat.unread_msgs;
              }
              return chat;
            });
            data.privateChats.map((chat) => {
              if (chat.unread_msgs && chat.unread_msgs > 0) {
                unread += chat.unread_msgs;
              }
              return chat;
            });
            setUnreadMessages(unread);
          });
        } catch (e) {}
      }
    },
    onError: (error) => {
      const errRes = error?.response;
      if (errRes) {
        handleError(errRes.status);
      }
    },
  });

  const unreadGetMutation = useMutation(() => newsCenterUnreadGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSuccess: (
      data:
        | { unread: number; groups: { id: string; number: number }[] }
        | undefined
    ) => {
      if (data) {
        try {
          // Set news center data
          unstable_batchedUpdates(() => {
            let directs = directRooms;
            let favorites = favoriteRooms;
            let groups = groupRooms;
            let unreadRooms = latestUnreadRooms;
            unreadRooms.map((r) => {
              const index = data.groups.map((e) => e.id).indexOf(r.id);
              if (index > -1) {
                r.unread_msgs = data.groups[index].number;
              }
              return r;
            });
            directs.map((r) => {
              const index = data.groups.map((e) => e.id).indexOf(r.id);
              if (index > -1) {
                r.unread_msgs = data.groups[index].number;
                const i = favoriteRooms.map((e) => e.id).indexOf(r.id);
                if (i < 0) {
                  if (!unreadRooms.some((room) => room.id === r.id)) {
                    if (r.unread_msgs > 0) {
                      unreadRooms.push(r);
                    }
                  }
                }
              }
              return r;
            });
            favorites.map((r) => {
              const index = data.groups.map((e) => e.id).indexOf(r.id);
              if (index > -1) {
                r.unread_msgs = data.groups[index].number;
              }
              return r;
            });
            groups.map((r) => {
              const index = data.groups.map((e) => e.id).indexOf(r.id);
              if (index > -1) {
                r.unread_msgs = data.groups[index].number;
                const i = favoriteRooms.map((e) => e.id).indexOf(r.id);
                if (i < 0 && !unreadRooms.some((room) => room.id === r.id)) {
                  if (r.unread_msgs > 0) {
                    unreadRooms.push(r);
                  }
                }
              }
              return r;
            });
            // setFavoriteRooms(favorites);
            // setDirectRooms(directs);
            // setGroupRooms(groups);
            setUnreadMessages(data.unread);
            setLatestUnreadRooms(unreadRooms);
          });
        } catch (e) {}
      }
    },
    onError: (error) => {
      const errRes = error?.response;
      if (errRes) {
        handleError(errRes.status);
      }
    },
  });

  const getPermissionForNewVersionMutation = useMutation(
    () => getPermissionForNewVersion(),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onError: (error) => {
        const errRes = error?.response;
        if (errRes) {
          handleError(errRes.status);
        }
      },
    }
  );

  // ######### //
  // EFFECTS //
  // ######### //

  useEffect(() => {
    getPermissionForNewVersionMutation.mutate();
    // eslint-disable-next-line
  }, []);

  // return to the login page after the session has expired
  useEffect(() => {
    if (!accessToken && !props.public) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [accessToken, props.public]);

  // set values on mount
  useEffect(() => {
    setFiltersData(initialNewsCenterFilterData());
    const newsCenterGetInterval = setInterval(() => {
      unreadGetMutation.mutate();
    }, 180000);

    return () => {
      // remove interval function
      clearInterval(newsCenterGetInterval);
      // setGroupRooms([]);
      // setDirectRooms([]);
      setGodparentChat(undefined);
      setLatestUnreadRooms([]);
      // setFavoriteRooms([]);
      setUnreadMessages(0);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    newsCenterGetMutation.mutate();
    // eslint-disable-next-line
  }, [
    //unreadMessages,
    selectedRoom,
    selectedRoom?.favorite,
    selectedRoom?.inactive_room,
  ]);

  /**
   * Change the favicon when receiving a message
   */
  useEffect(() => {
    // setInterval(() => {
    const head = document.head || document.getElementsByTagName('head')[0];
    let link = document.createElement('link');
    let oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.type = 'image/svg+xml';
    link.rel = 'shortcut icon';
    if (unreadMessages && unreadMessages > 0) {
      link.href =
        'https://files.pengueen.de/api/assets/svgs/favicon_neue_nachricht.png';
    } else {
      link.href = 'https://p4.pengueen.de/favicon.ico';
    }
    if (oldLink) {
      head.removeChild(oldLink);
    }
    head.appendChild(link);
    // }, 3000);
  }, [unreadMessages]);

  const toggleNewView = (checked: boolean) => {
    if (props.toggleNewView) {
      props.toggleNewView(checked);
    }
  };

  const handleResendClick = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setResendDialog(true);
  };

  const createValidation = yup.object({
    email: yup.string().required(t('community.create.form.name.error')),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ResendEmail>({
    resolver: yupResolver(createValidation),
  });

  const onResendMailSubmit = useCallback(
    (data: ResendEmail) => {
      setDisableButton(true);
      data.email && resendEmailMutation.mutate(data.email);
    },
    [props]
  );

  const resendEmailMutation = useMutation(
    (email: string) => resendEmail({ email: email }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data, error) {
        if (data) {
          // Set init state to prevent infinite loop
          setAccessToken(data.accessToken);
          setAccount(data.user.account);
          setResendDialog(false);
          setDisableButton(false);
        }
        if (error) {
          const errRes = error?.response;
          // Check if error contains fetch response object
          if (errRes) {
            // Set view error by response status
            console.log(handleError(errRes.status));
          }
        }
      },
    }
  );

  return (
    <>
      <Box
        bgcolor='bg.header'
        borderColor='border.header'
        className={styles['header']}
      >
        <Box className={styles['header-container']}>
          <Box className={styles['header-container-logo']}>
            {communities && communities.length === 1 ? (
              <>
                <Logo
                  communities={communities}
                  imgStyle={{
                    width: '60px',
                    height: undefined,
                  }}
                  style={{
                    width: '60px',
                    height: undefined,
                  }}
                />
                <div className={styles['powered-by']}>
                  <Box
                    sx={{ color: 'text.primary', fontSize: '9px!important' }}
                  >
                    Powered by
                  </Box>
                  <Logo
                    showBigLogo
                    classes={styles['auth-page-logo-small']}
                    imgClasses={styles['auth-page-logo-small']}
                  />
                </div>
              </>
            ) : (
              <>
                <Logo
                  communities={communities}
                  imgStyle={{
                    width: !smUp ? '80px' : lgDown ? '150px' : '180px',
                    height: !smUp ? '26px' : lgDown ? '50px' : undefined,
                  }}
                  style={{
                    width: !smUp ? '80px' : lgDown ? '150px' : '180px',
                    height: !smUp ? '26px' : lgDown ? '50px' : undefined,
                  }}
                />
              </>
            )}

            {smUp && breadcrumbs && (
              <div className={styles['header-container-logo-breadcrumbs']}>
                <Breadcrumbs path={breadcrumbs} />
              </div>
            )}
          </Box>
          <Box className={styles['header-container-navbar']}>
            {getPermissionForNewVersionMutation.data && dashboard && (
              <NavigationToggle
                isNewView={props.isNewView}
                toggleNewView={props.toggleNewView}
              />
            )}
            {!props.public && (
              <>
                {!props.isNewView && (
                  <Box className={styles['header-container-navbar-buttons']}>
                    <Link to='/dashboard'>
                      <Tooltip title={<>{t('dashboard.title')}</>}>
                        <IconButton
                          classes={
                            styles['header-container-navbar-buttons-iconbutton']
                          }
                          sxButton={{
                            '& svg': {
                              color: !dashboard ? 'text.secondary' : 'white',
                            },
                            bgcolor: !dashboard ? 'bg.card' : 'primary.main',
                            '&:hover': {
                              '& svg': { color: 'white' },
                              bgcolor: 'primary.main',
                            },
                          }}
                          icon={['fal', 'objects-column']}
                          iconSize='small'
                        />
                      </Tooltip>
                    </Link>
                    <Link to='/newscenter'>
                      <Tooltip title={<>{t('newscenter.title')}</>}>
                        <IconButton
                          classes={
                            styles[
                              'header-container-navbar-buttons-news-center'
                            ]
                          }
                          sxButton={{
                            '& svg': {
                              color: !newsCenter ? 'text.secondary' : 'white',
                            },
                            bgcolor: !newsCenter ? 'bg.card' : 'primary.main',
                            '&:hover': {
                              '& svg': { color: 'white' },
                              bgcolor: 'primary.main',
                              // title: 'Nachrichtencenter',
                            },
                          }}
                          icon={['fal', 'messages']}
                          iconSize='small'
                        >
                          {unreadMessages > 0 && (
                            <Box
                              sx={{
                                backgroundColor: 'success.main',
                                color: 'white',
                              }}
                              className={
                                styles[
                                  'header-container-navbar-buttons-news-center-count'
                                ]
                              }
                            >
                              {unreadMessages}
                            </Box>
                          )}
                        </IconButton>
                      </Tooltip>
                    </Link>
                    <Link to='/contacts'>
                      <Tooltip title={t('contacts.title')}>
                        <IconButton
                          classes={
                            styles['header-container-navbar-buttons-iconbutton']
                          }
                          sxButton={{
                            '& svg': {
                              color: !network ? 'text.secondary' : 'white',
                            },
                            bgcolor: !network ? 'bg.card' : 'primary.main',
                            '&:hover': {
                              '& svg': { color: 'white' },
                              bgcolor: 'primary.main',
                            },
                          }}
                          iconSize='small'
                          icon={['fal', 'chart-network']}
                        />
                      </Tooltip>
                    </Link>
                    <Link to='/market'>
                      <Tooltip title={t('market.title')}>
                        <IconButton
                          classes={
                            styles['header-container-navbar-buttons-iconbutton']
                          }
                          sxButton={{
                            '& svg': {
                              color: !market ? 'text.secondary' : 'white',
                            },
                            bgcolor: !market ? 'bg.card' : 'primary.main',
                            '&:hover': {
                              '& svg': { color: 'white' },
                              bgcolor: 'primary.main',
                            },
                          }}
                          iconSize='small'
                          icon={['fal', 'store']}
                        />
                      </Tooltip>
                    </Link>
                    {!lgDown && (
                      <Link to='/tools/workbench'>
                        <Tooltip title={<>{t('templates.workbench.title')}</>}>
                          <IconButton
                            classes={
                              styles[
                                'header-container-navbar-buttons-iconbutton'
                              ]
                            }
                            sxButton={{
                              '& svg': {
                                color: !workbench ? 'text.secondary' : 'white',
                              },
                              bgcolor: !workbench ? 'bg.card' : 'primary.main',
                              '&:hover': {
                                '& svg': { color: 'white' },
                                bgcolor: 'primary.main',
                              },
                            }}
                            iconSize='small'
                            icon={['fal', 'screwdriver-wrench']}
                          />
                        </Tooltip>
                      </Link>
                    )}
                  </Box>
                )}
                <Divider orientation='vertical' variant='middle' flexItem />
                <Box
                  color='inherit'
                  onClick={() => setDrawer(true)}
                  sx={{
                    '&:hover div': {
                      '& svg': { color: 'white' },
                      bgcolor: 'bg.card',
                    },
                  }}
                  className={styles['header-container-navbar-drawer']}
                >
                  {lgUp && profile?.personal_data?.first_name && (
                    <>
                      <Box
                        className={
                          styles['header-container-navbar-drawer-hello']
                        }
                      >
                        {t('app.hello')}
                      </Box>
                      <Box
                        className={
                          styles['header-container-navbar-drawer-name']
                        }
                      >
                        {profile.personal_data.first_name}
                      </Box>
                    </>
                  )}
                  {profile?.avatar?.url ? (
                    <img src={profile.avatar.url} alt='Profile' />
                  ) : (
                    <ImageFallback
                      // iconSize={!smUp ? '12px' : undefined}
                      // sizeClassName={!smUp ? 'w-8 h-8' : undefined}
                      type={ImageFallbackType.Profile}
                    />
                  )}{' '}
                  <IconButton
                    onClick={() => {}}
                    classes={clsx(
                      styles['header-container-navbar-buttons-iconbutton'],
                      styles['setting-icon']
                    )}
                    sxButton={{
                      '& svg': {
                        color: !userRouter ? 'text.secondary' : 'white',
                      },
                      bgcolor: !userRouter ? 'bg.card' : 'primary.main',
                      '&:hover': {
                        '& svg': { color: 'white' },
                        bgcolor: 'primary.main',
                      },
                    }}
                    iconSize='small'
                    icon={['fal', 'gear']}
                  />
                </Box>
              </>
            )}
            {props.public && (
              <Box>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://www.pengueen.de/'
                >
                  <TextButton preset='gray'>{t('app.website.to')}</TextButton>
                </a>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {account && !account?.email_check && (
        <>
          <Box
            borderColor='border.header'
            className={styles['header-email-check']}
          >
            <div className={styles['email-info']}>
              <div className={styles['dashboard-header-welcome-main-icon']}>
                &#x1F44B;
              </div>{' '}
              Herzlich willkommen bei Pengueen! Bitte bestätige noch deine
              E-Mail-Adresse!{' '}
              <a
                href='#'
                onClick={handleResendClick}
                style={{
                  textDecoration: 'underline',
                  color: 'inherit',
                  paddingLeft: '5px',
                }}
              >
                {' '}
                Bestätigungslink erneut senden!
              </a>
            </div>
          </Box>
        </>
      )}

      <div className={styles['hedaer-background']}></div>

      <Dialog
        paddingContent='0px'
        open={resendDialog}
        subtitle={
          'Schaue bitte vorsichtshalber auch in deinem Spam-Ordner nach '
        }
        title={'Bestätigungslink erneut senden'}
        onClose={() => setResendDialog(false)}
      >
        <Suspense fallback={<Loader />}>
          <form
            className={styles['resend']}
            onSubmit={handleSubmit(onResendMailSubmit)}
          >
            <Box
              className={styles['resend-form']}
              // className={styles['community-create-form-no_test']}
              sx={{ borderColor: 'border.app' }}
            >
              <Input
                classes='mb-4'
                label={'E-Mail'}
                defaultValue={account?.email ?? ''}
                placeholder={t('form.profile.email.placeholder')}
                register={register('email')}
                widthClassName={styles['resend-form-field-width']}
              />

              <TextButton
                classes={styles['resend-info-submit']}
                preset='primary'
                type='submit'
                disabled={disableButton}
              >
                {'Bestätigungslink senden'}
              </TextButton>
            </Box>
          </form>
        </Suspense>
      </Dialog>
    </>
  );
};
