// Models
import {
  Community,
  CommunityData,
  CommunityEntity,
  Tag,
} from '../../communities/models/communities.types';
import {
  GroupRoomSettings,
  MessageRoomEntity,
  RoomType,
} from '../../newscenter/models/news-center.types';
import { Template } from '../../templates/models/templates.types';
import {
  Attachment,
  CreateChangeInfo,
  ImageFallbackType,
} from '../../../shared/models/shared.types';
import { Account, User } from '../../user/models/user.types';
import {
  CommunityFolder,
  ContactCommunity,
  ToolLink,
} from '../../contacts/models/contacts.types';
import { Message } from '../../newscenter/models/message.types';

export enum DashboardViewType {
  Default = 'DEFAULT',
  Message = 'MESSAGE',
  Tool = 'TOOL',
  Contact = 'CONTACT',
  Setting = 'SETTING',
  License = 'LICENSE',
  Community = 'COMMUNITY',
}
export enum FavoriteLinkType {
  Contacts = 'CONTACTS',
  Newscenter = 'NEWSCENTER',
}

export enum ChangeType {
  Room = 'ROOM',
  Tool = 'Tool',
  Template = 'Teamplte',
  Contact = 'CONTACT',
}

export interface Change {
  id: string;
  read: boolean;
  room?: RoomChange;
  tool?: ToolChange;
  template?: TemplateChange;
  contact?: ContactChange;
  create_change_info: CreateChangeInfo;
  duplicated_ids: string;
}

export interface ReplyMessageRequest {
  id: string;
  message: string;
}

export interface DashboardCommunity {
  changes: number;
  community: Pick<CommunityData, 'id' | 'logo' | 'name'>;
}

export interface DashboardData {
  favorites_communities: string[] | null | undefined;
  favorites_tools: Template[] | undefined;
  show_favorites_tools: boolean;
}

export interface Favorite {
  network: NetworkFavorite;
  groups: GroupFavorite[];
  users: UserFavorite[];
}

export interface NetworkFavorite {
  id: string;
  user: Account;
  communities: NetworkFavorite[];
  groups: MessageRoomEntity[];
  links: ToolLink[];
  folders: CommunityFolder[];
}

export interface NetworkFavorite {
  id: string;
  community: CommunityEntity;
  for_commuity: boolean;
  for_admin: boolean;
  for_employee: boolean;
  for_member: boolean;
}

export interface Group {
  id: string;
  community?: Partial<Community>;
  description: string;
  name: string;
  tools?: Template[];
  users: Partial<User>[];
}

export interface GroupFavorite {
  id: string;
  name: string;
  link: FavoriteLinkType;
  unread_msgs?: number;
  community_id?: ContactCommunity;
  tags?: Tag[];
  type?: ImageFallbackType;
  users: {
    id: string;
    avatar?: Attachment;
    first_name?: string;
    last_name?: string;
  }[];
}

export interface ToolFavorite {
  id: string;
  changes?: number; // Changes since last login
  community?: { data: { id: string; name: string; logo?: Attachment } }; // #TODO: TBA
  name: string;
  connected_user?: string;
  connected_group?: ToolFavoriteGroup;
  template: {
    icon: Attachment | undefined;
    name: string;
  };
  history?: Change[];
}

export interface ToolFavoriteGroup {
  id: string;
  name: string;
}

export interface UserFavorite {
  id: string;
  avatar?: Attachment;
  first_name?: string;
  last_name?: string;
  link: FavoriteLinkType;
  unread_msgs?: number;
}

export type RoomChange = {
  id: string;
  messages: string[];
  message_room_name: string;
  settings?: GroupRoomSettings;
  room_type: RoomType;
  change_type?: RoomChangeType;
  // unread_msgs?: number;
  // users: Partial<User>[];
  user_1_avatar?: string;
  user_1_name?: string;
  user_2_name?: string;
  user_2_id?: string;
  tool_name?: string;
  tool_id?: string;
  row_chat_id?: string;
  row_id?: string;
  sectionid?: string;
  multiid?: string;
};

export type ToolChange = {
  id: string;
  count?: number;
  tool_name: string;
  user_1_avatar?: string;
  user_1_name?: string;
  table_name?: string;
  change_type?: ToolChangeType;
  community?: {
    id: string;
    name: string;
    logo?: {
      id: string;
      url: string;
    };
  };
  right?: {
    name: string;
    role: string;
  };
  changed_row?: {
    multi_element_name: string;
    column_name: string;
    old_value: string;
    new_value: string;
  };
  create_change_info: CreateChangeInfo;
  section_id?: string;
  mfe_id?: string;
  row_id?: string;
};

export type ContactChange = {
  id: string;
  user_1_name: string | undefined;
  user_2_name: string | undefined;
  user_1_avatar?: string;
  // state: ActiveState;
  change_type?: ContactChangeType;
  direct_room_id?: string;
  community?: {
    id: string;
    name: string;
    logo?: {
      id: string;
      url: string;
    };
  };
  // avatar: {
  //   id: string;
  //   url: string;
  // };
};

export enum ToolChangeType {
  ToolChatMultiRowAdd = 'TOOLCHAT_MULTIROW_ADD',
  ToolChatGroupAdd = 'TOOLCHAT_GROUP_ADD',
  ToolChatGroupRemove = 'TOOLCHAT_GROUP_REMOVE',
  Delete = 'DELETE',
  Change = 'CHANGE',
  Create = 'CREATE',
  // Accepted = 'ACCEPTED',
  // Rejected = 'REJECTED',
  Selected = 'SELECTED',
  Removed = 'REMOVED',
  RightAssigned = 'RIGHTASSIGNED',
  RightRemoved = 'RIGHTREMOVED',
  MultiSeleted = 'MULTISELETED',
  MultiAssigned = 'MULTIASSIGNED',
  MultiRemoved = 'MULTIREMOVED',
  MultiRowAdd = 'MULTIROWADD',
  MultiRowUpdate = 'MULTIROWUPDATE',
  Settings = 'SETTINGS',
  Publish = 'PUBLISH',
  Update = 'UPDATE',
  Add = 'ADD',
}

export enum TemplateChangeType {
  Approved = 'APPROVED',
  Change = 'CHANGE',
  Create = 'CREATE',
  Delete = 'DELETE',
  Deployed = 'DEPLOYED',
  Publish = 'PUBLISH',
  Question = 'QUESTION',
}

export enum ContactChangeType { // Delete = 'DELETE',
  // Change = 'CHANGE',
  // Create = 'CREATE',
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  CommunityAccepted = 'COMMUNITY_ACCEPTED',
  CommunityInvited = 'COMMUNITY_INVITED',
  CommunityRejected = 'COMMUNITY_REJECTED',
  AcceptedLink = 'ACCEPTED_LINK',
  AcceptedLinkCommunity = 'ACCEPTED_LINK_COMMUNITY',
  AcceptedLinkGroup = 'ACCEPTED_LINK_GRUOP',
  AcceptedLinkAdmin = 'ACCEPTED_LINK_ADMIN',
  AcceptedLinkEmployee = 'ACCEPTED_LINK_EMPLOYEE',
  AcceptedLinkMember = 'ACCEPTED_LINK_MEMBER',
  // Selected = 'SELECTED',
  // Removed = 'REMOVED',
  // RightAssigned = 'RIGHTASSIGNED',
  // RightRemoved = 'RIGHTREMOVED',
}

export enum RoomChangeType {
  Close = 'CLOSE',
  Leave = 'LEAVE',
  InviteMe = 'INVITE_ME',
  InviteOthers = 'INVITE_OTHERS',
  NewMessage = 'NEW_MESSAGE',
  Removed = 'REMOVED',
  RemovedMe = 'REMOVED_ME',
  Mention = 'MENTION',
  Sticky = 'STICKY',
  ToolNewMessage = 'TOOL_NEW_MESSAGE',
  ToolMultiRowNewMessage = 'TOOL_MULTIROW_NEW_MESSAGE',
}

export type TemplateChange = {
  id: string;
  count?: number;
  tool_name: string;
  user_1_avatar?: string;
  user_1_name?: string;
  table_name?: string;
  change_type?: TemplateChangeType;
  community?: {
    id: string;
    name: string;
    logo?: {
      id: string;
      url: string;
    };
  };
  right?: {
    name: string;
    role: string;
  };
  changed_row?: {
    multi_element_name: string;
    column_name: string;
    old_value: string;
    new_value: string;
  };
  create_change_info: CreateChangeInfo;
  section_id?: string;
  mfe_id?: string;
  row_id?: string;
};
