import { memo, useCallback, useEffect, useState } from 'react';
import { Box, Input, OutlinedInput } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

// Components
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { ResultState } from '../../../../shared/models/shared.types';
import { ResultMessage } from '../../../../shared/ui/ResultMessage/ResultMessage';
import { Loader } from '../../../../shared/ui/Loader/Loader';

// Models
import {
  MultiElementColumnGradeParams,
  ToolValuesElement,
} from '../../models/tools.types';

// Hooks
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useToolsHttp } from '../../hooks/use-tools-http.hook';
import { useTranslation } from 'react-i18next';
import { ToolsState, useToolsStore } from '../../stores/use-tools.store';

// Styles
import styles from './ToolMultiElementTable.module.scss';

const ColumnGrade = (props: MultiElementColumnGradeParams) => {
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const { toolVoteSubmitMultiRow } = useToolsHttp();

  // Component state
  const [toolValue, setToolValue] = useState<ToolValuesElement | undefined>(
    undefined
  );
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showThanks, setShowThanks] = useState<boolean>(false);
  const [showChangeButton, setShowChangeButton] = useState<boolean>(false);

  // Tools store state
  const [hasFullAccess] = useToolsStore((state: ToolsState) => [
    state.hasFullAccess,
  ]);

  // React hook form validation schema
  const voteValueseValidation = yup.object({
    vote: yup
      .number()
      .min(0)
      .max(props.vote_element?.vote ?? 0),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<{ vote: number }>({
    mode: 'onChange',
    resolver: yupResolver(voteValueseValidation),
  });
  const vote = watch('vote');

  // ######### //
  // MUTATIONS //
  // ######### //

  const submitVoteMultiRowMutation = useMutation(
    (data: any) => toolVoteSubmitMultiRow(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (toolValue) {
            let updatedToolValue: ToolValuesElement = { ...toolValue };
            updatedToolValue.value = data.vote;
            updatedToolValue.change = data.change;
            updatedToolValue.submit = data.submit;
            updatedToolValue.result = data.result;
            setToolValue(updatedToolValue);
          } else {
            let updatedToolValue: ToolValuesElement = {
              id: data.id,
              value: data.vote,
              change: data.change,
              submit: data.submit,
              result: data.result,
            };
            setToolValue(updatedToolValue);
          }
        }
        if (error && error.response) {
          console.error(
            'Error submit vote value:',
            handleError(error.response.status)
          );
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Effects on component mount
  useEffect(() => {
    // Reset editor state on escape press
    const onKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        reset();
      }
    };
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
    // eslint-disable-next-line
  }, []);

  // Set contact value by props
  useEffect(() => {
    if (props.value) {
      setToolValue(props.value);
    }
  }, [props.value]);

  // Set show vote submit button
  useEffect(() => {
    if (
      // vote &&
      // vote !== toolValue?.value &&
      vote?.toString() &&
      vote > -1 &&
      props.vote_element?.vote &&
      vote <= props.vote_element?.vote
    ) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [vote, toolValue, props]);

  // Set show components
  useEffect(() => {
    if (props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowResult(true);
        setShowForm(false);
      } else {
        setShowResult(false);
        setShowForm(true);
      }
    }

    if (!props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowThanks(true);
        setShowForm(false);
      } else {
        setShowThanks(false);
        setShowForm(true);
      }
    }

    props.vote_element?.settings?.vote?.change
      ? setShowChangeButton(true)
      : setShowChangeButton(false);
  }, [props.vote_element?.settings?.vote, toolValue]);

  // ######### //
  // CALLBACK //
  // ######### //

  const onChange = useCallback(() => {
    setShowForm(true);
    setShowButtons(true);
    setShowChangeButton(false);
    setShowThanks(false);
    setShowResult(false);
  }, []);

  const onCancel = useCallback(() => {
    reset();
    // if (toolValue) {
    //   const updatedToolValues = { ...toolValue };
    //   updatedToolValues.value = 0;
    //   setToolValue(updatedToolValues);
    // }
  }, [reset]);

  const onSave = useCallback(
    (formValues) => {
      submitVoteMultiRowMutation.mutate({
        id: props.toolId,
        body: {
          id: props.vote_element?.id,
          value: formValues.vote,
        },
        element_id: props.element?.id,
        section_id: props.sectionId,
        row_id: props.rowId,
      });
    },
    // eslint-disable-next-line
    [props]
  );

  return (
    <>
      {hasFullAccess ? (
        <>{toolValue?.value}</>
      ) : (
        <>
          <Box className={styles['column-grade']}>
            {!submitVoteMultiRowMutation.isLoading && (
              <>
                {showResult && (
                  <div className={styles['column-grade-add']}>
                    <div className="mr-4">
                      <div className={styles['column-grade-input-default']}>
                        <Input
                          className={styles['column-grade-input']}
                          type="number"
                          inputProps={{
                            style: { alignItems: 'center', textAlign: 'right' },
                          }}
                          disableUnderline
                          style={{ fontSize: 'small' }}
                          value={toolValue?.value}
                          readOnly
                        />
                        <span className="ml-1">/</span>
                        {props.vote_element?.vote}
                      </div>
                    </div>
                    {showResult && showChangeButton && (
                      <div>
                        <TextButton
                          preset="popover"
                          textSize="text-sm"
                          onClick={onChange}
                        >
                          {t('tools.vote.change')}
                        </TextButton>
                      </div>
                    )}
                  </div>
                )}
                {showThanks && (
                  <div className={styles['column-grade-add']}>
                    <div className="mr-4">{t('tools.vote.thanks')}</div>
                    {showThanks && showChangeButton && (
                      <div>
                        <TextButton
                          preset="popover"
                          textSize="text-sm"
                          onClick={onChange}
                        >
                          {t('tools.vote.change')}
                        </TextButton>
                      </div>
                    )}
                  </div>
                )}
                {showForm && (
                  <form onSubmit={handleSubmit(onSave)}>
                    <div className={styles['column-grade-input-default']}>
                      <Input
                        className={styles['column-grade-input']}
                        type="number"
                        disableUnderline
                        style={{ alignItems: 'center', fontSize: 'small' }}
                        inputProps={{
                          style: { textAlign: 'right' },
                        }}
                        defaultValue={toolValue?.value}
                        readOnly={hasFullAccess}
                        min="0"
                        max={props.vote_element?.vote}
                        {...register('vote', {
                          valueAsNumber: true,
                        })}
                      />
                      <span className="ml-1">/</span> {props.vote_element?.vote}
                      {showButtons && (
                        <div className={styles['column-grade-input-button']}>
                          <IconButton
                            classes="mr-1"
                            icon={['fas', 'times']}
                            preset="popover"
                            iconSize="small"
                            onClick={onCancel}
                          />
                          <IconButton
                            icon={['fas', 'check']}
                            preset="popover"
                            iconSize="small"
                            type="submit"
                          />
                        </div>
                      )}
                    </div>
                    {errors.vote && (
                      <ResultMessage
                        message={t('tools.vote.error.point')}
                        state={ResultState.Error}
                        padding="p-0"
                      />
                    )}
                  </form>
                )}
              </>
            )}

            {submitVoteMultiRowMutation.isLoading && <Loader />}
          </Box>
        </>
      )}
    </>
  );
};

export default memo(ColumnGrade);
