import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

// Components
import { Icon } from '../../../shared/ui/Icon/Icon';

// Styles
import styles from './NavCommunityButton.module.scss';

type NavCommunityButtonProps = {
  active?: boolean;
  classes?: string;
  main?: boolean;
  title: TFunctionResult | string;
  icon?: [IconPrefix, IconName];
  onClick: () => void;
};

export const NavCommunityButton = memo(
  ({
    active,
    classes,
    main,
    title,
    icon,
    onClick,
  }: NavCommunityButtonProps) => {
    return (
      <Box
        sx={{
          fontWeight: active ? 600 : undefined,
          backgroundColor: 'initial',
          '&:hover': {
            backgroundColor: 'bg.hover',
          },
        }}
        className={
          main
            ? styles['contacts-nav-community-button-main']
            : styles['contacts-nav-community-button']
        }
        onClick={onClick}
      >
        {icon && (
          <Box className={styles['contacts-nav-community-button-image']}>
            <Icon
              icon={icon}
              size="inherit"
              sx={{
                color: active ? 'primary.main' : 'text.primary',
                fontWeight: active ? 600 : undefined,
              }}
            />
          </Box>
        )}
        <Box
          color={
            main ? 'text.primary' : active ? 'primary.main' : 'text.primary'
          }
          className={clsx(
            main && styles['contacts-nav-community-button-main-title'],
            classes && classes
          )}
        >
          {title}
        </Box>
      </Box>
    );
  }
);
