import React from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridColumns,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridRenderCellParams,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
  gridPaginationSelector,
  useGridSelector,
} from '@mui/x-data-grid-pro';
import { Box, Pagination } from '@mui/material';
// Utils
import {
  dateOptions,
  dateTimeOptions,
  newMultiElementDataGridSxGet,
  timeOptions,
} from '../../../modules/tools/utils/tools.utils';

// Models
import {
  TemplateElementDatePickerType,
  TemplateElement,
  TemplateElementType,
} from '../../../modules/templates/models/templates.types';
import {
  MultiElementRowActions,
  MultiElementColumnHeaderParams,
  ToolSectionElementRowIds,
  ColumnWidth,
  ToolValuesMultiElementRow,
  SummaryValue,
  CustomGridColDef,
  ToolFilterModelRequest,
  ToolTableSetting,
  GetToolChatIdRequest,
} from '../../../modules/tools/models/tools.types';

// Styles
import styles from './ToolMultiElementTable.module.scss';

import { flushSync } from 'react-dom';

import { Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Loader } from '../../../shared/ui/Loader/Loader';
import { useTools } from '../../../modules/tools/hooks/use-tools.hook';
import { useBreakpoints } from '../../../shared/hooks/use-breakpoints.hook';
import {
  SharedState,
  useSharedStore,
} from '../../../shared/stores/use-shared.store';
import { useDataGrid } from '../../../shared/hooks/use-data-grid.hook';
import { useToolsHttp } from '../../../modules/tools/hooks/use-tools-http.hook';
import { useFetch } from '../../../shared/hooks/use-fetch.hook';
import { useNewsCenter } from '../../../modules/newscenter/hooks/use-news-center.hook';
import { useShared } from '../../../shared/hooks/use-shared.hook';
import { useUserStore } from '../../../modules/user/stores/use-user.store';
import {
  AuthState,
  useAuthStore,
} from '../../../modules/public/stores/use-auth.store';
import { useToolsStore } from '../../../modules/tools/stores/use-tools.store';
import { useMutation } from 'react-query';
import {
  CrudState,
  ResultState,
  Theme,
} from '../../../shared/models/shared.types';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';
import { IconButton } from '../../../shared/ui/IconButton/IconButton';
import { Dialog } from '../../../shared/ui/Dialog/Dialog';
import NewsCenterChat from '../../../modules/newscenter/components/NewsCenterChat/NewsCenterChat';
import { RoomType } from '../../../modules/newscenter/models/news-center.types';
import ToolMultiElementRowCreateEdit from '../../../modules/tools/components/ToolMultiElementRowCreateEdit/ToolMultiElementRowCreateEdit';
import { IconTextButton } from '../../../shared/ui/IconTextButton/IconTextButton';
import { RemoveButton } from '../../../shared/ui/RemoveButton/RemoveButton';
import { Input } from '../../../shared/ui/Input/Input';
import { TextButton } from '../../../shared/ui/TextButton/TextButton';
import { makeStyles } from '@mui/styles';
import {
  ActionCell,
  getColumnForDataFromOtherTool,
  getColumnForTableInTool,
  getColumnsForAdmin,
} from './ToolMultiElementTableCells';

const DataGridLoader = memo(() => {
  return (
    <div className={styles['data-grid-loader']}>
      <Loader size={12} classes={styles['data-grid-loader-circle']} />
    </div>
  );
});

const useStyles: any = makeStyles((theme: any) => ({
  oddRow: {
    backgroundColor: theme.palette.bg.card, //Light gray for odd rows
  },
  evenRow: {
    backgroundColor: theme.palette.background.paper, // White for even rows
  },
}));

type ToolMultiElementTableProps = {
  columns: GridColumns;
  disabled?: boolean;
  element: TemplateElement;
  sectionId: string;
  toolChat?: boolean;
  toolId: string;
  toolName: string;
  values?: ToolValuesMultiElementRow[];
  isAdmin?: boolean;
  onCancel?: () => void;
};

export const ToolMultiElementTable = React.memo(
  (props: ToolMultiElementTableProps) => {
    const [theme] = useSharedStore((state: SharedState) => [state.theme]);
    const location = useLocation();
    const getSearchParams = (search: string) => {
      return new URLSearchParams(search);
    };
    const searchParams = getSearchParams(location.search);
    const [selectedMultiElementRowEdit, setSelectedMultiElementRowEdit] =
      useState<ToolSectionElementRowIds | undefined>(undefined);
    const [roomId, setRoomId] = useState<string | undefined>(undefined);
    const [rowId, setRowId] = useState<string | undefined>(undefined);
    const { lgDown } = useBreakpoints();
    const { localeTextGet } = useDataGrid();
    const { t } = useTranslation();
    const { handleError, handleRetry } = useFetch();
    const { voteSummaryGet } = useToolsHttp();
    const { updateTool } = useTools();
    const { localeGet } = useShared();
    const [columnSums, setColumnSums] = useState<Record<string, number>>({});
    const [drawerChat, setDrawerChat] = useState<boolean>(false);
    const {
      toolToolbarSettingPost,
      toolToolbarSettingGet,
      toolMultiElementRowDelete,
      createToolChatId,
    } = useToolsHttp();
    const [setNotification] = useSharedStore((state) => [
      state.setNotification,
    ]);

    // Component state
    // const [columns, setColumns] = useState<GridColumns>([]);
    const [numberSummary, setNumberSummary] = useState<SummaryValue[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(
      []
    );
    const [Rows, setRow] = useState<ToolValuesMultiElementRow[]>(
      props.values || []
    );
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
      items: [],
    });
    const [density, setDensity] = useState<GridDensity | undefined>(undefined);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<
      GridColumnVisibilityModel | undefined
    >(undefined);
    const [tableSettings, setTableSettings] = useState<ToolTableSetting[]>([]);
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [selectedMultiElementRowDelete, setSelectedMultiElementRowDelete] =
      useState<ToolSectionElementRowIds | undefined>(undefined);
    const apiRef = useGridApiRef();

    // Auth store state
    const [accessToken] = useAuthStore((state: AuthState) => [
      state.accessToken,
    ]);

    // Tools store state
    const [setTool, isToolLoading, setVoteSummary] = useToolsStore((state) => [
      state.setTool,
      state.isToolLoading,
      state.setVoteSummary,
    ]);

    // ######### //
    // MUTATIONS //
    // ######### //

    const voteSummaryGetMutation = useMutation(
      (data: any) => voteSummaryGet(data),
      {
        retry: (failureCount, error: any) => handleRetry(failureCount, error),
        onSettled: (data, error) => {
          if (data) {
            setVoteSummary(data);
          }
          if (error) {
            handleError(error.response.status);
          }
        },
      }
    );

    const toolToolbarSettingGetMutation = useMutation(
      (data: any) => toolToolbarSettingGet(data),
      {
        retry: (failureCount, error: any) => handleRetry(failureCount, error),
        onSettled: (data, error) => {
          if (data && data.table_settings && data.table_settings.length > 0) {
            setTableSettings(data.table_settings);
            setFilterModel(data.table_settings[0].filterModel);
            setColumnVisibilityModel(
              data.table_settings[0].columnVisibilityModel
            );
            setTimeout(() => {
              setDensity(data.table_settings[0].density);
            }, 2000);
          }
          if (error) {
            handleError(error.response.status);
          }
        },
      }
    );

    const toolToolbarSettingPostMutation = useMutation(
      (data: any) => toolToolbarSettingPost(data),
      {
        retry: (failureCount, error: any) => handleRetry(failureCount, error),
      }
    );

    // ####### //
    // EFFECTS //
    // ####### //

    // useEffect(() => {
    //   toolToolbarSettingGetMutation.mutate({
    //     tool_id: props.toolId,
    //     element_id: props.element.id,
    //   });
    // }, []);

    /*
     *Update Row, wenn value changed
     */
    // useEffect(() => {
    //   props.values && setRow(props.values);
    // }, [JSON.stringify(props.values), numberSummary]);

    const classes = useStyles();

    // add classname for a new row containing the total
    const getRowClassName = (params: any) => {
      if (params.id === 'total') {
        return 'total';
      } else {
        return params.row.sort % 2 === 0 ? classes.evenRow : classes.oddRow;
      }
    };

    // useEffect(() => {
    //   let voteElements: string[] = [];
    //   let isVoteElementExist = false;
    //   if (props.element.elements) {
    //     props.element.elements.map((template_element) => {
    //       if (
    //         template_element.type === TemplateElementType.Star ||
    //         template_element.type === TemplateElementType.Grade
    //       ) {
    //         if (
    //           !voteElements.find((element) => element === template_element.id)
    //         ) {
    //           voteElements.push(template_element.id);
    //           isVoteElementExist = true;
    //         }
    //       }
    //       return template_element;
    //     });
    //   }
    //   accessToken &&
    //     isVoteElementExist &&
    //     voteSummaryGetMutation.mutate({
    //       id: props.toolId,
    //       element_id: props.element.id,
    //       section_id: props.sectionId,
    //       body: { selected_row: selectionModel, vote_elements: voteElements },
    //     });
    //   // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //   const summary: SummaryValue[] = [];
    //   props.element.elements?.map((element) => {
    //     element.summary &&
    //       typeof element.summary === 'number' &&
    //       summary.push({
    //         vote_element_name: element.name,
    //         vote_summary_value: element.summary!.toFixed(2),
    //       });
    //   });
    //   setNumberSummary(summary);
    // }, []);

    const handleDeleteRow = (rowId: string) => {
      // apiRef.current.updateRows([{ id: rowId, _action: 'delete' }]);
      setRow((prevRows) => prevRows.filter((row) => row.id !== rowId));
    };

    const handleDeleteRowModal = (rowId: string) => {
      setSelectedMultiElementRowDelete({
        rowId: rowId,
        toolId: props.toolId ?? '',
        elementId: props.element.id,
        sectionId: props.sectionId,
      });
    };

    const createToolChatIdMutation = useMutation(
      (data: GetToolChatIdRequest) => createToolChatId(data),
      {
        retry: (failureCount, error: any) => handleRetry(failureCount, error),
        onSettled: (data, error) => {
          if (data) {
            if (data.room_id) {
              flushSync(() => {
                setRoomId(data.room_id);
                setDrawerChat(true);
                // if (tool) {
                //   const updateTool_ = {
                //     ...tool,
                //     values: tool.values.map((section) =>
                //       section.id === props.sectionId
                //         ? {
                //             ...section,
                //             elements: section.elements.map((element) =>
                //               element.id === props.element.id
                //                 ? {
                //                     ...element,
                //                     rows:
                //                       element && element.rows
                //                         ? element.rows.map((row) =>
                //                             row.id === rowId
                //                               ? {
                //                                   ...row,
                //                                   roomId: data.room_id,
                //                                 }
                //                               : row
                //                           )
                //                         : [],
                //                   }
                //                 : element
                //             ),
                //           }
                //         : section
                //     ),
                //   };

                //   setTool(updateTool_);
                //   updateTool(props.toolId ?? '');
                // } TODO ADD?
                setRow((prevRows) =>
                  prevRows.map((row) =>
                    row.id === rowId ? { ...row, roomId: data.room_id } : row
                  )
                );
              });
            }
          }
          if (error) {
            handleError(error.response.status);
          }
        },
      }
    );

    const onRowShowChat = useCallback(
      (event: any) => {
        setRowId(event.rowId);
        if (event.roomId) {
          setRoomId(event.roomId);
          setDrawerChat(true);
        } else {
          createToolChatIdMutation.mutate({
            tool_id: props.toolId,
            mfe_id: event.rowId,
          });
        }
      },
      [roomId, drawerChat]
    );

    const drawerChatClose = useCallback(() => {
      setRowId(undefined);
      setRoomId(undefined);
      setDrawerChat(false);
    }, []);

    // ######### //
    // CALLBACKS //
    // ######### //

    const onSelectionChange = useCallback(
      (selectionModel: GridSelectionModel) => {
        setSelectionModel(selectionModel);
      },
      []
    );

    const handleColumnWidthChange = useCallback(
      (params) => {
        const { colDef, width } = params;

        let updatedColumnWidths: ColumnWidth[];
        const savedColumnWidths = localStorage.getItem(
          `${accessToken}columnWidths${props.toolId}${props.element.id}`
        );
        if (savedColumnWidths) {
          updatedColumnWidths = JSON.parse(savedColumnWidths);
          const index = updatedColumnWidths.findIndex(
            (col) => col.field === colDef.field
          );
          if (index > -1) {
            updatedColumnWidths[index].width = width;
          } else {
            updatedColumnWidths.push({ field: colDef.field, width: width });
          }
        } else {
          updatedColumnWidths = [];
          updatedColumnWidths.push({ field: colDef.field, width: width });
        }

        localStorage.setItem(
          `${accessToken}columnWidths${props.toolId}${props.element.id}`,
          JSON.stringify(updatedColumnWidths)
        );
      },
      [accessToken, props.element.id, props.toolId]
    );

    const getColumnValues = (colField: any) => {
      const visibleRows = apiRef.current.getVisibleRowModels();
      let value = 0;
      visibleRows.forEach((row, key) => {
        if (key !== 'total' && row[colField]) {
          const numberFloat = parseFloat(row[colField]);
          value += Number(numberFloat.toFixed(2));
        }
      });
      return value;
    };

    // set summary for number field
    // useEffect(() => {
    //   if (props.element.elements) {
    //     const numberCols = props.element.elements.filter(
    //       (item) => item.type === TemplateElementType.Number
    //     );

    //     numberCols.forEach((element: TemplateElement) => {
    //       const name = element.name;
    //       // const colValue = getColumnValues(name);
    //       let colValue: number | string | undefined = undefined;
    //       if (element.settings?.advanced?.duration) {
    //         colValue = element.summary;
    //       } else {
    //         colValue = Number(element.summary).toFixed(2);
    //       }

    //       const rowToUpdate = { id: 'total', [name]: colValue };
    //       console.log(rowToUpdate);
    //       //apiRef.current.updateRows([rowToUpdate]);
    //     });
    //   }
    // }, [Row]);

    const handleFilterModelChange = () => {
      if (props.element.elements) {
        const numberCols = props.element.elements.filter(
          (item) => item.type === TemplateElementType.Number
        );

        if (apiRef.current) {
          const visibleRowIds = apiRef.current.getRowModels(); // IDs aller sichtbaren Zeilen
          const rows = Array.from(visibleRowIds.values());
          // Filterlogik umsetzen
          const filteredRows = rows.filter((row) => {
            if (filterModel.items.length === 0) return true; // Kein Filter -> alle Zeilen bleiben sichtbar

            const filterResults = filterModel.items.map((filter) => {
              const value = row[filter.columnField];
              const filterValue = filter.value;

              if (filter.operatorValue === 'contains') {
                if (typeof filterValue !== 'undefined' && filterValue !== '') {
                  return (
                    value?.toString().includes(filterValue) ||
                    value?.name?.toString().includes(filterValue) ||
                    value?.contactname?.toString().includes(filterValue)
                  );
                } else {
                  return true;
                }
              }
              if (filter.operatorValue === 'equals') {
                return value === filterValue;
              }
              if (filter.operatorValue === 'startsWith') {
                return value?.toString().startsWith(filterValue);
              }
              if (filter.operatorValue === 'endsWith') {
                return value?.toString().endsWith(filterValue);
              }
              if (filter.operatorValue === 'greaterThan') {
                return value > Number(filterValue);
              }
              if (filter.operatorValue === 'lessThan') {
                return value < Number(filterValue);
              }
              return true; // Standard: keine Filterbedingung
            });

            // Wende logischen Operator (AND / OR) auf die Filterergebnisse an
            if (filterModel.linkOperator === 'and') {
              return filterResults.every(Boolean); // Alle Bedingungen müssen erfüllt sein
            }
            if (filterModel.linkOperator === 'or') {
              return filterResults.some(Boolean); // Eine Bedingung muss erfüllt sein
            }
            return filterResults.every(Boolean); // Standard: AND
          });

          // Definiere, welche Spalten summiert werden sollen
          const newSums: Record<string, number> = {};

          if (visibleRowIds.size === 0) {
            numberCols.forEach((field) => {
              newSums[field.name] = Number(field.summary ?? 0.0);
            });
          } else {
            numberCols.forEach((field) => {
              newSums[field.name] = filteredRows.reduce((acc, row) => {
                return Number(acc) + (Number(row?.[field.name]) || 0);
              }, 0);
            });
          }

          // Summen aktualisieren
          setColumnSums(newSums);

          // Dynamische Header-Updates (mit Summe)
          const updatedColumns = numberCols.map((field) => {
            apiRef.current.updateColumn({
              field: field.name,

              headerName: field.name,
              renderHeader: (params: GridColumnHeaderParams) => {
                const extendedParams: MultiElementColumnHeaderParams = {
                  ...params,
                  checked: true,
                  title: t('tools.detail.multi_element.changed_by'),
                  onToggle: (checked) => {},
                };
                const sum =
                  field.suffix === '€'
                    ? new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format(Number(newSums[field.name]))
                    : Number(newSums[field.name]);

                return (
                  <>
                    <div className={styles['column-text-sum-title']}>
                      {field.name}
                    </div>
                    <div className={styles['column-text-sum']}>
                      {newSums[field.name] != null && <>{'(' + sum + ')'}</>}
                      {field.settings?.advanced?.hasSuffix &&
                        '€' !== field.suffix &&
                        newSums[field.name] && (
                          <div className="ml-1">{field.suffix}</div>
                        )}
                    </div>
                  </>
                );
              },
            });
          });
        }
      }
    };

    const handleSaveToolTableSettings = () => {
      if (!filterName) return;

      const newSetting = [...tableSettings];
      newSetting.unshift({
        name: filterName,
        filterModel: filterModel,
        density: density ?? 'standard',
        columnVisibilityModel: columnVisibilityModel,
      });

      const newRequest: ToolFilterModelRequest = {
        tool_id: props.toolId,
        element_id: props.element.id,
        table_settings: newSetting,
      };

      setTableSettings(newSetting);
      toolToolbarSettingPostMutation.mutate(newRequest);
      setFilterName(undefined);
    };

    const handleFilterRemove = (setting: ToolTableSetting, index: number) => {
      const updatedTableSettings = [...tableSettings];
      const updatedSettings = updatedTableSettings.filter(
        (filter) => filter.name !== setting.name
      );

      // Post save changed filter
      const newRequest: ToolFilterModelRequest = {
        tool_id: props.toolId,
        element_id: props.element.id,
        table_settings: updatedSettings,
      };

      setTableSettings(updatedSettings);
      toolToolbarSettingPostMutation.mutate(newRequest);

      if (index === 0) {
        setFilterModel({ items: [] });
        setDensity('standard');
        setColumnVisibilityModel(undefined);
      }
    };

    const handleChangeSortSettings = (setting: ToolTableSetting) => {
      const updatedTableSettings = [...tableSettings];
      const updatedSettings = updatedTableSettings.filter(
        (filter) => filter.name !== setting.name
      );

      // new setting first
      updatedSettings.unshift(setting);
      setTableSettings(updatedSettings);

      // Post save changed filter
      const newRequest: ToolFilterModelRequest = {
        tool_id: props.toolId,
        element_id: props.element.id,
        table_settings: updatedSettings,
      };

      toolToolbarSettingPostMutation.mutate(newRequest);
    };

    const handleDensityChange = (state: any) => {
      state?.density?.value && setDensity(state.density.value);
    };

    const handleColumnVisibilityModelChange = (state: any) => {
      setColumnVisibilityModel(state);
    };

    const toolbarProps = useMemo(
      () => ({
        disabled: props.disabled,
        element: props.element,
        sectionId: props.sectionId,
        toolId: props.toolId,
        quickFilterProps: { debounceMs: 250 },
        toolName: props.toolName,
        row: Rows,
        firstRow: Rows.length === 1,
        filterModel: filterModel,
        tableSettings: tableSettings,
        filterName: filterName,
        setColumnVisibilityModel,
        setFilterModel,
        setDensity,
        setFilterName,
        handleSaveToolTableSettings,
        handleDensityChange,
        handleFilterRemove,
        handleChangeSortSettings,
      }),
      [props, Rows, filterModel, tableSettings, filterName]
    );

    const panelProps = useMemo(
      () => ({
        sx: {
          right: '150px !important',
          left: 'auto !important',
        },
      }),
      []
    );

    const footerProps = useMemo(
      () => ({
        element: props.element,
        sectionId: props.sectionId,
        toolId: props.toolId,
        firstRow: Rows.length === 1,
      }),
      []
    );

    // DELETE Multi element row mutation
    const multiElementRowDeleteMutation = useMutation(
      (data: ToolSectionElementRowIds) => toolMultiElementRowDelete(data),
      {
        retry: (failureCount, error: any) => handleRetry(failureCount, error),
        onSettled(data, error) {
          if (data) {
            flushSync(() => {
              handleDeleteRow(data.rowId ?? '');

              setNotification({
                subtitle: 'Der Eintrag wurde gelöscht!',
                title: 'Der Eintrag wurde gelöscht!',
                state: ResultState.Success,
              });
              setSelectedMultiElementRowDelete(undefined);
            });
          }
          if (error) {
            const errRes = error?.response;
            if (errRes) {
              handleError(errRes.status);
            }
          }
        },
      }
    );

    useEffect(() => {
      handleFilterModelChange(); // Initial berechnen
    }, [filterModel, Rows]);

    return (
      <div className="h-full relative">
        {isToolLoading && <DataGridLoader />}
        <DataGridPro
          apiRef={apiRef}
          autoHeight
          // checkboxSelection={true}
          // classes={multiElementDataGridClassesGet()}
          sx={newMultiElementDataGridSxGet()}
          columns={props.columns}
          disableSelectionOnClick
          disableColumnMenu
          disableVirtualization={false}
          columnBuffer={15}
          localeText={localeTextGet()}
          rows={Rows}
          getRowId={(row) => row.id}
          pageSize={25}
          pagination={true}
          getRowClassName={getRowClassName}
          initialState={{ pinnedColumns: { right: ['actions'] } }}
          components={{
            Footer: MultiElementTableFooter,
            // Toolbar: MultiElemetTableToolbar,
          }}
          componentsProps={{
            footer: footerProps,
            // toolbar: toolbarProps,
            panel: panelProps,
          }}
          onSelectionModelChange={onSelectionChange}
          onColumnWidthChange={handleColumnWidthChange}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => setFilterModel(newModel)}
          onStateChange={handleDensityChange}
          density={density ?? 'standard'}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        />
        {props.toolId && (
          <Drawer
            anchor="right"
            classes={{
              paper: styles['tool-multi-row-chat'],
            }}
            open={drawerChat}
            onClose={() => drawerChatClose()}
          >
            <NewsCenterChat
              type={RoomType.ToolChatMultiRow}
              roomId={roomId}
              tool_id={props.toolId}
              section_id={props.sectionId}
              multi_id={props.element.id}
              row_id={rowId}
              disable_header={true}
              // participant_group_id={tool.tool_chat_group_id}
            />
          </Drawer>
        )}
      </div>
    );
  }
);

// export default memo(ToolMultiElementTable);

type MultiElementTableFooterProps = {
  element: TemplateElement;
  sectionId: string;
  toolId: string;
  firstRow: boolean;
};

export const MultiElementTableFooter = (
  props: MultiElementTableFooterProps
) => {
  const { lgDown } = useBreakpoints();
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const { page, pageCount } = useGridSelector(apiRef, gridPaginationSelector);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    apiRef.current.setPage(value - 1); // setPage erwartet nullbasierten Index
  };

  // Tools store state
  const [canIEditTool, hasFullAccess, voteSummary] = useToolsStore((state) => [
    state.canIEditTool,
    state.hasFullAccess,
    state.voteSummary,
  ]);

  // Component state
  const [selectedMultiElementRowCreate, setSelectedMultiElementRowCreate] =
    useState<ToolSectionElementRowIds | undefined>(undefined);

  return (
    <>
      <Box
        className={styles['table-footer']}
        sx={{ borderColor: 'border.app' }}
      >
        {canIEditTool && lgDown && (
          <>
            <IconTextButton
              icon={['fal', 'plus']}
              padding={'0.5rem 0.75rem'}
              preset="popover"
              onClick={() =>
                setSelectedMultiElementRowCreate({
                  elementId: props.element.id,
                  sectionId: props.sectionId,
                  toolId: props.toolId,
                })
              }
            >
              {t('tools.detail.multi_element.add.button')}
            </IconTextButton>
            <Dialog
              open={selectedMultiElementRowCreate ? true : false}
              title={t('tools.detail.multi_element.add.title')}
              onClose={() => setSelectedMultiElementRowCreate(undefined)}
            >
              <ToolMultiElementRowCreateEdit
                element={props.element}
                toolId={props.toolId}
                elementId={props.element.id}
                sectionId={props.sectionId}
                firstRow={props.firstRow}
                state={CrudState.Create}
                // setModalopen={setSelectedMultiElementRowCreate}
                onCancel={() => setSelectedMultiElementRowCreate(undefined)}
              />
            </Dialog>
          </>
        )}
      </Box>
      {hasFullAccess && voteSummary && voteSummary.length > 0 && (
        <Box
          className={styles['summary-footer']}
          sx={{ borderColor: 'border.app' }}
        >
          <Box
            className={styles['summary-footer-title']}
            sx={{ borderColor: 'border.app' }}
          >
            <Box className="flex flex-row items-center">
              {t('tools.vote.sum')}
              <IconButton
                iconSize="inherit"
                classes="ml-2 p-0 text-sm"
                icon={['fal', 'info-circle']}
                sxIcon={{ color: 'text.secondary' }}
              />
            </Box>
            <Box className="flex flex-row items-center">
              {t('tools.vote.sum_total')}
              <IconButton
                iconSize="inherit"
                classes="ml-2 p-0 text-sm"
                icon={['fal', 'info-circle']}
                sxIcon={{ color: 'text.secondary' }}
              />
            </Box>
          </Box>
          {voteSummary?.map((value, index) => {
            return (
              <Box className="flex flex-col p-2 pl-8 items-end" key={index}>
                {value.vote_selected_value ? (
                  <Box>{value.vote_selected_value}</Box>
                ) : (
                  <Box> - </Box>
                )}
                <Box>{value.vote_summary_value}</Box>
              </Box>
            );
          })}
        </Box>
      )}
      <Pagination
        count={pageCount} // Anzahl der Seiten
        page={page + 1} // 1-basiert für Pagination
        onChange={handlePageChange}
        color="primary"
      />
    </>
  );
};

type MultiElemetTableToolbarProps = {
  disabled?: boolean;
  element: TemplateElement;
  sectionId: string;
  toolId: string;
  row: ToolValuesMultiElementRow[];
  firstRow: boolean;
  toolName: string;
  filterModel: GridFilterModel;
  tableSettings: ToolTableSetting[];
  filterName: string | undefined;
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>;
  setDensity: React.Dispatch<React.SetStateAction<GridDensity | undefined>>;
  setColumnVisibilityModel: React.Dispatch<
    React.SetStateAction<GridColumnVisibilityModel | undefined>
  >;
  setFilterName: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleShowFilter: (event: any) => void;
  handleSaveToolTableSettings: () => void;
  handleDensityChange: (newDensity: any) => void;
  handleFilterRemove: (item: ToolTableSetting, inx: number) => void;
  handleChangeSortSettings: (item?: ToolTableSetting) => void;
};

const MultiElemetTableToolbar = (props: MultiElemetTableToolbarProps) => {
  const { lgDown } = useBreakpoints();
  const { t } = useTranslation();
  const { removeHtmlTags, addCommasToNumber } = useNewsCenter();
  const { localeGet } = useShared();

  const apiRef = useGridApiContext();

  // Component state
  const [selectedMultiElementRowCreate, setSelectedMultiElementRowCreate] =
    useState<ToolSectionElementRowIds | undefined>(undefined);
  const [dialogSaveFilter, setDialogSaveFilter] = useState<boolean>(false);

  // Tools store state
  const [canIEditTool] = useToolsStore((state) => [state.canIEditTool]);

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  const [locale, setLocale] = useState<string | undefined>(undefined);

  // User store state
  const [account] = useUserStore((state) => [state.account]);

  const filterSx = {
    padding: '0.4rem 0.75rem',
    background:
      theme === Theme.Light ? '#f3f6f9 !important' : '#2b2b2b !important',
    border: '0px !important',
    borderRadius: '6px',
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px !important',
    },
  };
  const iconSx = {
    color: 'text.primary',
    backgroundColor: 'bg.card',
    padding: '0.3rem',
    minWidth: '44px !important',
    width: '44px !important',
    height: '44px',
    marginRight: '0.5rem',
    '&:hover': { backgroundColor: 'primary.main' },
    '&:hover .icon-text-button-text': { color: 'white' },
    '&:hover .MuiSvgIcon-root': { color: 'white' },
    '& .MuiButton-startIcon': {
      marginRight: '0px !important',
    },
  };

  const buttonSx = {
    color: 'text.primary',
    backgroundColor: 'bg.card',
    padding: '0.3rem',
    minWidth: '44px !important',
    width: '44px !important',
    height: '44px',
    marginRight: '0.5rem',
    '&:hover': { backgroundColor: 'primary.main' },
    '&:hover .icon-text-button-text': { color: 'white' },
    '&:hover .MuiSvgIcon-root': { color: 'white' },
    '& .MuiButton-startIcon': {
      marginRight: '0px !important',
    },
  };

  // Set date format locale by account language
  useEffect(() => {
    account && setLocale(localeGet(account?.language));
    // eslint-disable-next-line
  }, [account]);

  const handleExport = () => {
    const fileName: string = `${formatDateToYYYYMMDD(new Date())}__${
      props.toolName
    }__${props.element.name}`;
    const csvRows = [];
    const columns: CustomGridColDef[] = apiRef.current.getAllColumns();
    const headers: string[] = [];

    // Remove column actions and state
    columns
      .filter((column) => !column.disableExport)
      .map((column) => {
        switch (column.type) {
          case TemplateElementType.DataFromOtherToolGroup:
            headers.push('Gruppe');
            break;
          case TemplateElementType.DataFromOtherToolContact:
            headers.push(column.field.substring(0, column.field.length - 1));
            break;
          case TemplateElementType.DataFromOtherTool:
            headers.push(column.tableHeaderName ?? '');
            break;
          default:
            if (column.field !== 'actions' && column.field) {
              headers.push(column.field);
            }
            break;
        }
      });

    // Export row id
    if (props.element.settings?.export?.export_with_id) {
      headers.unshift('Row Id');
    }

    // Add headers
    csvRows.push(headers.join(';'));

    // Add rows
    props.row.forEach((row) => {
      // Summary will not be exported
      if (row.id === 'total') {
        return;
      }
      const values = columns
        .filter((column) => !column.disableExport)
        .map((column) => {
          const value = row[column.field];
          if (value === null || value === undefined) {
            return '';
          }
          switch (column.type) {
            case TemplateElementType.Headline:
            case TemplateElementType.Info:
              return '';
            case 'date':
              return getDateOrTime(value, TemplateElementDatePickerType.Date);
            case 'dateTime':
              return getDateOrTime(
                value,
                TemplateElementDatePickerType.DateTime
              );
            case 'time':
              return getDateOrTime(value, TemplateElementDatePickerType.Time);
            case 'boolean':
              return value === 'true' ? 'Ja' : 'Nein';
            case 'number':
              let returnValue: number | string = value;
              if (row.id === 'total') {
                returnValue = Number(returnValue).toFixed(2);
              }
              if (column.thousandPoint) {
                returnValue = addCommasToNumber(returnValue?.toString());
              }
              if (column.hasSuffix && column.suffix) {
                return `${returnValue} ${column.suffix}`;
              }
              return returnValue;
            case TemplateElementType.Contact:
            case TemplateElementType.Checkbox:
            case TemplateElementType.Select:
            case TemplateElementType.RadioButton:
              return value.name ?? '';
            case TemplateElementType.Upload:
              const fileNames: string[] = [];
              for (const file of value) {
                fileNames.push(file.name);
              }
              return fileNames;
            case TemplateElementType.DataFromOtherToolContact:
              return value.contactname ? value.contactname : value.nameid;
            case TemplateElementType.DataFromOtherToolGroup:
              return value.group_name ?? '';
            case TemplateElementType.DataFromOtherTool:
              var lastChar = column.field.slice(-1);
              var lastNum = parseInt(lastChar);
              const realValue = value && value.value ? value.value : undefined;
              if (realValue) {
                return removeHtmlTags(realValue);
              } else {
                return '';
              }
            default:
              if (typeof value === 'string') {
                if (column.cellClassName === 'super-app-theme--cell') {
                  try {
                    const value_objct = JSON.parse(value);
                    if (value_objct && typeof value_objct === 'object') {
                      return removeHtmlTags(value_objct?.show_value);
                    }
                    return removeHtmlTags(value);
                  } catch {}
                }
                return removeHtmlTags(value);
              } else {
                if (column.cellClassName === 'super-app-theme--cell') {
                  return removeHtmlTags(value.show_value);
                }
                return value;
              }
          }
        });

      // Export row id
      if (props.element.settings?.export?.export_with_id) {
        values.unshift(row.id);
      }

      csvRows.push(values.join(';'));
    });

    const bom = '\uFEFF'; // UTF-8 BOM
    const csvString = bom + csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.csv`;
    link.click();
  };

  const getDateOrTime = (
    value: string,
    type: TemplateElementDatePickerType
  ) => {
    dayjs.extend(LocalizedFormat);
    switch (type) {
      case TemplateElementDatePickerType.Time:
        return `${new Intl.DateTimeFormat(locale, timeOptions).format(
          new Date(value)
        )}${' Uhr'}`;
      case TemplateElementDatePickerType.DateTime:
        return `${new Intl.DateTimeFormat(locale, dateTimeOptions).format(
          new Date(value)
        )}${' Uhr'}`;
      case TemplateElementDatePickerType.Date:
        return `${new Intl.DateTimeFormat(locale, dateOptions).format(
          new Date(value)
        )}`;
    }
  };

  const formatDateToYYYYMMDD = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`; // YYYYMMDD
  };

  const handleItemRemove = (setting: ToolTableSetting, index: number) => {
    props.handleFilterRemove(setting, index);
  };

  const handleUpdateFilter = (setting: ToolTableSetting) => {
    props.setFilterModel(setting.filterModel);
    props.setDensity(setting.density);
    props.setColumnVisibilityModel(setting.columnVisibilityModel);
    props.setFilterName(undefined);
    props.handleChangeSortSettings(setting);
  };

  return (
    <>
      <div className={styles['tool-multi-element-content-filters']}>
        <div className={styles['tool-multi-element-content-filters-section']}>
          <GridToolbarQuickFilter
            disabled={props.disabled}
            sx={{ ...filterSx }}
            className={
              styles['tool-multi-element-content-filters-section-search']
            }
          />
          {/* <Search
            classes={
              styles['tool-multi-element-content-filters-section-search']
            }
            disabled={props.disabled}
            padding={'0.7rem'}
            placeholder={t('tools.detail.multi_element.search')}
            value={search}
            onChange={setSearch}
          /> */}
          {canIEditTool && props.element.can_add_row && (
            <>
              <IconTextButton
                disabled={props.disabled}
                icon={['fal', 'plus']}
                padding={lgDown ? '0.5rem 0.75rem' : '0.5rem'}
                preset="paper-secondary"
                textTransform="uppercase"
                onClick={() =>
                  setSelectedMultiElementRowCreate({
                    elementId: props.element.id,
                    sectionId: props.sectionId,
                    toolId: props.toolId,
                  })
                }
              >
                {t('tools.detail.multi_element.add.button')}
              </IconTextButton>
            </>
          )}
        </div>

        <div className={styles['tool-multi-element-content-filters-section']}>
          <Tooltip title={t('tools.detail.multi_element.download')}>
            <IconButton
              classes="mr-2"
              icon={['fal', 'download']}
              padding="0.75rem"
              preset="popover"
              onClick={handleExport}
            />
          </Tooltip>
          <Box
            className={
              styles['tool-multi-element-content-filters-section-owner']
            }
            sx={{ backgroundColor: 'bg.card' }}
          >
            <IconTextButton
              // active={owner === MultiElementOwnerType.Me}
              classes={
                styles['tool-multi-element-content-filters-section-owner-me']
              }
              disabled
              icon={['fal', 'user']}
              padding={lgDown ? '0.5rem 0.75rem' : '0.35rem'}
              preset="card-paper"
              // onClick={() => setOwner(MultiElementOwnerType.Me)}
            >
              {t('tools.detail.multi_element.onwer.me')}
            </IconTextButton>
            <IconTextButton
              // active={owner === MultiElementOwnerType.All}
              disabled
              icon={['fal', 'user-group']}
              padding={lgDown ? '0.5rem 0.75rem' : '0.35rem'}
              preset="card-paper"
              // onClick={() => setOwner(MultiElementOwnerType.All)}
            >
              {t('tools.detail.multi_element.onwer.all')}
            </IconTextButton>
          </Box>
          <Tooltip title={t('tools.filters.filterPanelColumns')}>
            <GridToolbarColumnsButton
              aria-labelledby=""
              onResize={() => {}}
              nonce={() => {}}
              onResizeCapture={() => {}}
              sx={{ ...iconSx }}
            />
          </Tooltip>
          <Tooltip title={t('tools.filters.toolbarDensity')}>
            <GridToolbarDensitySelector
              onResize={() => {}}
              nonce={() => {}}
              onResizeCapture={() => {}}
              onChange={props.handleDensityChange}
              sx={{ ...iconSx }}
            />
          </Tooltip>
          <GridToolbarFilterButton
            onResize={() => {}}
            nonce={() => {}}
            onResizeCapture={() => {}}
            sx={{ ...buttonSx }}
          />{' '}
          {canIEditTool && (
            <Tooltip title="save filter settings">
              <IconButton
                classes="mr-2 text-xs"
                icon={['fal', 'floppy-disk']}
                padding="0.3rem"
                preset="primary"
                onClick={() => setDialogSaveFilter(true)}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {props.tableSettings.length > 0 && (
        <div className={styles['tool-multi-element-content-tags']}>
          {props.tableSettings.map((setting, index) => (
            <RemoveButton
              key={index}
              classes={styles['tool-multi-element-content-tags-removebutton']}
              fontClasses="text-xs"
              paddingClasses="py-0 pl-1 pr-0"
              preset="primary"
              onRemove={() => handleItemRemove(setting, index)}
              onClick={() => handleUpdateFilter(setting)}
            >
              {setting.name}
            </RemoveButton>
          ))}
        </div>
      )}
      <Dialog
        open={selectedMultiElementRowCreate ? true : false}
        title={t('tools.detail.multi_element.add.title')}
        onClose={() => setSelectedMultiElementRowCreate(undefined)}
      >
        <ToolMultiElementRowCreateEdit
          element={props.element}
          toolId={props.toolId}
          elementId={props.element.id}
          sectionId={props.sectionId}
          selectedMultiElementRowCreate={selectedMultiElementRowCreate}
          firstRow={props.firstRow}
          state={CrudState.Create}
          // setModalopen={setSelectedMultiElementRowCreate}
          onCancel={() => setSelectedMultiElementRowCreate(undefined)}
        />
      </Dialog>
      <Dialog
        open={dialogSaveFilter}
        classes="w-1/3"
        title={t('tools.detail.multi_element.filters.name_save')}
        onClose={() => setDialogSaveFilter(false)}
      >
        <form className="flex flex-col">
          <Input
            value={props.filterName}
            onChange={(value) => props.setFilterName(value)}
          />
          <TextButton
            classes="mt-4 self-end"
            preset="primary"
            onClick={() => {
              props.handleSaveToolTableSettings();
              setDialogSaveFilter(false);
            }}
          >
            {t('tools.detail.multi_element.filters.name_submit')}
          </TextButton>
        </form>
      </Dialog>
    </>
  );
};
export default MultiElemetTableToolbar;
