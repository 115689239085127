import { Fragment, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from 'react-query';

// Components
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { Input } from '../../../../shared/ui/Input/Input';
import { RemoveButton } from '../../../../shared/ui/RemoveButton/RemoveButton';
import { ResultBox } from '../../../../shared/ui/ResultBox/ResultBox';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { Select } from '../../../../shared/ui/Select/Select';
import { ToolFavorite } from '../../../dashboard/models/dashboard.types';
import { ContactsToolsCard } from '../ContactsToolsCard/ContactsToolsCard';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useContacts } from '../../hooks/use-contacts.hook';
import { useContactsHttp } from '../../hooks/use-contacts-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';

// Models
import {
  CommunityGroup,
  Contact,
  ContactCommunity,
  ContactCommunityIdType,
  ContactInvite,
  ContactsInvitePostBadRequestResponse,
  ContactsInvitePostRequest,
} from '../../models/contacts.types';
import { Option, ResultState } from '../../../../shared/models/shared.types';

// Stores
import { useContactsStore } from '../../stores/use-contacts.store';

// Styles
import styles from './ContactsInvite.module.scss';
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { CommunityUserRole } from '../../../communities/models/communities.types';

type ContactsInviteProps = {
  selectedCommunity?: ContactCommunity;
  selectedGroup?: CommunityGroup;
  contacts: Contact[];
  setContacts: (contacts: Contact[]) => void;
  onClose: () => void;
};

export const ContactsInvite = (props: ContactsInviteProps) => {
  const { smDown, lgDown } = useBreakpoints();
  const { contactsInvitePostEffect, getContactGroupsFoldersEffect } =
    useContacts();
  const {
    contactsInvitePost,
    getContactCommunities,
    getContactGroupsFolders,
    getContactTools,
  } = useContactsHttp();
  const { handleError, handleRetry } = useFetch();
  const { t } = useTranslation();

  // Component state
  const [inviteContacts, setInviteContacts] = useState<ContactInvite[]>([]);
  const [errorEmails, setErrorEmails] = useState<string[]>([]);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [communityOptions, setCommunityOptions] = useState<Option[]>([]);
  const [groupsOptions, setGroupsOptions] = useState<Option[]>([]);
  const [contactsTools, setContactsTools] = useState<ToolFavorite[]>([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  // Contacts store state
  const [selectedTools, selectedContactReinvite] = useContactsStore((state) => [
    state.seletedTools,
    state.selectedContactReinvite,
  ]);

  // ##### //
  // FORMS //
  // ##### //

  // React hook form validation schema
  const inviteValidation = yup.object({
    email: yup.string().email(t('form.profile.email.validation.schema')),
    nickname: yup.string(),
    communities: yup.mixed(),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<ContactInvite>({
    mode: 'onSubmit',
    resolver: yupResolver(inviteValidation),
  });
  const watchCommunity = watch('community') ?? '';
  const watchGroups = watch('groups') ?? [];

  // ####### //
  // EFFECTS //
  // ####### //

  // set Contact Communities on mount
  useEffect(() => {
    setCommunityOptions([
      {
        placeholder: t('contacts.toolbar.invite.community'),
        value: undefined,
      },
    ]);
    getContactCommunitiesMutation.mutate();
    getContactGroupsMutation.mutate(watchCommunity);
    getContactToolsMutation.mutate(watchCommunity);
  }, [watchCommunity]);

  // set Options
  useEffect(() => {
    getContactGroupsMutation.mutate(watchCommunity);
    getContactToolsMutation.mutate(watchCommunity);
  }, [watchCommunity]);

  // Set nickname if reinvite on mount
  useEffect(() => {
    if (selectedContactReinvite && selectedContactReinvite.nickname) {
      setValue('nickname', selectedContactReinvite.nickname);
    }
    // eslint-disable-next-line
  }, [selectedContactReinvite]);

  // Control submit button
  useEffect(() => {
    if (isButtonDisabled) {
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
      }, 60000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isButtonDisabled]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // POST Contacts invite mutation
  const contactsInvitePostMutation = useMutation(
    (data: ContactsInvitePostRequest) => contactsInvitePost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Add invited contacts to store
        if (data) {
          try {
            props.setContacts(contactsInvitePostEffect(data, props.contacts));
            props.onClose();
          } catch (error) {
            console.log('ERROR on inviting contacts:', error);
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            handleError(errRes.status);
            if (errRes.status === 400) {
              try {
                errRes
                  .json()
                  .then((body: ContactsInvitePostBadRequestResponse) =>
                    setErrorEmails(body.error_emails)
                  );
              } catch (error) {
                console.error('ERROR setting emails error:', error);
              }
            }
          }
        }
      },
    }
  );

  const getContactCommunitiesMutation = useMutation(
    () => getContactCommunities(),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        const options: Option[] = [
          {
            placeholder: t('contacts.toolbar.invite.community'),
            value: undefined,
          },
        ];

        if (data) {
          const contactCommunities = data;

          for (const community of contactCommunities) {
            options.push({
              placeholder: community.name,
              value: community.id,
            });

            if (community.id === props.selectedCommunity?.id) {
              setValue('community', community.id);
            }
          }

          setCommunityOptions(options);
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error getting contact community options:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  const getContactGroupsMutation = useMutation(
    (id: string | undefined) => getContactGroupsFolders({ id: id }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          const newGroupOptions = getContactGroupsFoldersEffect(data);
          setGroupsOptions(newGroupOptions);

          for (const groupOption of newGroupOptions) {
            if (groupOption.value === props.selectedGroup?.id) {
              setValue('groups', [groupOption.value!]);
            }
          }
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error getting contact group:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  const getContactToolsMutation = useMutation(
    (id: string | undefined) => getContactTools({ id: id }),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          setContactsTools(data);
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error getting contact tools:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handle add contact (check if unique).
   */
  const onContactAdd = useCallback(
    (data: ContactInvite) => {
      setErrorEmails([]);
      const index = inviteContacts.findIndex(
        (contact) => contact.email === data.email
      );
      if (index < 0 && data.email) {
        data.email = data.email.toLowerCase();
        if (selectedContactReinvite) {
          const existingContactIndex = inviteContacts.findIndex(
            (contact) => contact.id === selectedContactReinvite.id
          );
          if (existingContactIndex < 0) {
            const contactReinvited: ContactInvite = {
              ...data,
              id: selectedContactReinvite.id,
            };
            setInviteContacts([...inviteContacts, contactReinvited]);
          } else {
            setInviteContacts([...inviteContacts, data]);
          }
        } else {
          setInviteContacts([...inviteContacts, data]);
        }
        // reset();
        setValue('email', '');
        setValue('nickname', undefined);
      }
    },
    // eslint-disable-next-line
    [inviteContacts, selectedContactReinvite]
  );

  /**
   * Handle remove contact.
   */
  const onContactRemove = useCallback(
    (emailToRemove: string) => {
      const index = inviteContacts.findIndex(
        (contact) => contact.email === emailToRemove
      );
      if (index > -1) {
        const updatedContacts = [...inviteContacts];
        updatedContacts.splice(index, 1);
        setInviteContacts(updatedContacts);
      }
    },
    [inviteContacts]
  );

  /**
   * Handler to submit invitation.
   */
  const onSubmit = useCallback(() => {
    setErrorEmails([]);
    contactsInvitePostMutation.mutate({
      contacts: inviteContacts,
      communities: watchCommunity,
      groups: watchGroups,
      tools: selectedTools,
      message,
    });

    //handle button click
    setButtonDisabled(true);

    // eslint-disable-next-line
  }, [inviteContacts, watchCommunity, watchGroups, selectedTools, message]);

  return (
    <>
      <DialogTitle
        className={styles['contacts-dialog-invite-title']}
        sx={{
          padding: lgDown ? '1rem' : '1.5rem',
        }}
      >
        <div>
          {selectedContactReinvite
            ? t('contacts.actions.reinvite')
            : t('contacts.invite.title')}
        </div>
        <IconButton icon={['fas', 'times']} onClick={props.onClose} />
      </DialogTitle>
      <DialogContent
        dividers
        className={styles['contacts-dialog-invite-content']}
      >
        <div className={styles['contacts-invite']}>
          <FormFieldLabel classes='mb-2' label={t('contacts.invite.text')} />
          {/* <Box className="mb-4" sx={{ color: 'text.primary' }}>
            {t('contacts.invite.text')}
          </Box> */}
          <form
            className={styles['contacts-invite-form']}
            onSubmit={handleSubmit(onContactAdd)}
          >
            <div className='flex flex-col w-full'>
              <div className='flex flex-row mb-2'>
                <div className={styles['contacts-invite-form-data']}>
                  <Input
                    classes={styles['contacts-invite-form-data-input']}
                    padding='0.75rem'
                    placeholder={t('app.emailAddress')}
                    register={register('email')}
                    state={errors?.email && ResultState.Error}
                  />
                  <Input
                    classes={styles['contacts-invite-form-data-input']}
                    padding='0.75rem'
                    placeholder={t('app.nickname')}
                    register={register('nickname')}
                  />
                </div>
                <div className='shrink-0'>
                  <IconButton
                    classes={styles['contacts-invite-form-button']}
                    disabled={
                      selectedContactReinvite &&
                      inviteContacts &&
                      inviteContacts.length > 0
                        ? true
                        : false
                    }
                    icon={['fas', 'plus']}
                    preset='primary'
                    type='submit'
                  />
                </div>
              </div>
              <div className={styles['contacts-invite-list']}>
                {inviteContacts.length > 0 &&
                  inviteContacts.map((contact: ContactInvite) => (
                    <RemoveButton
                      key={contact.email}
                      classes={styles['contacts-invite-list-item']}
                      icon={['fal', 'envelope']}
                      width={smDown ? '100%' : undefined}
                      onRemove={() => onContactRemove(contact.email)}
                    >
                      {contact.email}
                      {contact.nickname && `(${contact.nickname})`}
                    </RemoveButton>
                  ))}
              </div>
              {errorEmails.length > 0 && (
                <ResultBox
                  classes={styles['contacts-invite-error']}
                  state={ResultState.Error}
                >
                  <>
                    {errorEmails.length > 1
                      ? t('contacts.invite.error.emails.multi')
                      : t('contacts.invite.error.emails.single')}
                    {errorEmails.map((email, index) => (
                      <Fragment key={index}>{`${email}${
                        index < errorEmails.length - 1 ? ', ' : ' '
                      }`}</Fragment>
                    ))}
                    {t('contacts.invite.error.emails.text')}
                  </>
                </ResultBox>
              )}
              <div className='mt-2 '>
                <Select
                  disabled={
                    !props.selectedCommunity ||
                    props.selectedCommunity.id === ContactCommunityIdType.All
                      ? false
                      : true
                  }
                  classes={styles['contacts-invite-select']}
                  label={t('contacts.toolbar.label.community')}
                  labelColor='text.primary'
                  labelFontWeightClasses='font-semibold'
                  value={watchCommunity}
                  options={communityOptions}
                  onChange={(value) => setValue('community', value)}
                />
                <Select
                  disabled={props.selectedGroup ? true : false}
                  classes={styles['contacts-invite-select']}
                  multiple
                  label={t('contacts.toolbar.label.group')}
                  labelColor='text.primary'
                  labelFontWeightClasses='font-semibold'
                  value={watchGroups}
                  options={groupsOptions}
                  onChange={(value) => setValue('groups', value)}
                />
                <ContactsToolsCard
                  label={t('contacts.toolbar.label.tool')}
                  labelColor='text.primary'
                  labelFontWeightClasses='font-semibold'
                  tools={contactsTools}
                />
              </div>
            </div>
          </form>
          <Input
            classes='mb-4'
            placeholder='Optionale Nachricht hinzufügen...'
            multiline={smDown ? 2 : 4}
            onChange={(value) => setMessage(value)}
          />
          {/* <Box sx={{ color: 'text.primary' }}>
            {t('contacts.invite.info')}
          </Box> */}
          <FormFieldLabel classes='mb-4' label={t('contacts.invite.info')} />
        </div>
      </DialogContent>
      <DialogActions>
        <TextButton
          classes={styles['contacts-invite-submit']}
          disabled={inviteContacts.length < 1 || isButtonDisabled}
          preset='primary'
          onClick={onSubmit}
        >
          {selectedContactReinvite
            ? t('contacts.actions.reinvite')
            : t('contacts.invite.submit')}
        </TextButton>
      </DialogActions>
    </>
  );
};
