// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import {
  Contact,
  ContactDeleteResponse,
  ContactsGetResponse,
  ContactsGetFilters,
  ContactsInvitePostRequest,
  ContactsNavGetResponse,
  ContactCommunity,
  GetContactGroupsFoldersResponse,
  CommunityFolder,
  ContactsAddMemberPost,
  CommunityFavoriteToolsPostRequest,
  ContactCommunityIdType,
  AccordionExpandedRequest,
  AccordionExpansionState,
  AccordionExpandedGet,
} from '../models/contacts.types';
import { NicknameChangeRequest } from '../../../shared/models/shared.types';

// Stores
import { AuthState, useAuthStore } from '../../public/stores/use-auth.store';
import {
  NetworkFavorite,
  ToolFavorite,
} from '../../dashboard/models/dashboard.types';
import {
  GroupRoomSettings,
  NetworkFavoritesRequest,
} from '../../newscenter/models/news-center.types';
import { DeleteInfo } from '../../user/models/user.types';

export const useContactsHttp = () => {
  const { fetchData } = useFetch();

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  /**
   * GET Contacts.
   * @returns Contacts
   */
  const contactsGet = async (
    params?: ContactsGetFilters
  ): Promise<ContactsGetResponse | undefined> => {
    if (accessToken) {
      return await fetchData('contacts', {
        params,
      });
    }
    return undefined;
  };

  /**
   * POST Invite contacts.
   * @param inviteData Invite data
   * @returns any
   */
  const contactsInvitePost = async (
    inviteData: ContactsInvitePostRequest
  ): Promise<Contact[] | undefined> => {
    if (accessToken) {
      return await fetchData('contacts/invite', {
        method: 'POST',
        body: inviteData,
      });
    }
  };

  /**
   * GET Contacts nav list.
   * @returns Contacts nav list
   */
  const contactsNavGet = async (): Promise<
    ContactsNavGetResponse | undefined
  > => {
    return await fetchData('contacts/nav');
  };

  /**
   * GET Contact by id
   * @param id User id
   * @returns Contact
   */
  const contactGet = async (data: {
    id: string;
    community_id?: string;
  }): Promise<Contact | undefined> => {
    if (accessToken) {
      if (
        data.community_id !== undefined &&
        data.community_id !== ContactCommunityIdType.All
      ) {
        return await fetchData(
          `contacts/${data.id}/community/${data.community_id}`
        );
      } else {
        return await fetchData(`contacts/${data.id}`);
      }
    }
    return undefined;
  };

  const nicknameChangePatch = async (
    body: NicknameChangeRequest
  ): Promise<{ nickname: string } | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/${body.id}/nickname`, {
        method: 'PATCH',
        body: body,
      });
    }
    return undefined;
  };

  /**
   * Delete Contact by id
   * @param id Contact id
   * @returns Contact
   */
  const contactDelete = async (body: {
    id: string;
  }): Promise<ContactDeleteResponse | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/${body.id}`, {
        method: 'DELETE',
      });
    }
    return undefined;
  };

  const contactDeleteFromGroup = async (body: {
    id: string;
    groupid: string;
  }): Promise<ContactDeleteResponse | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/${body.id}/group/${body.groupid}`, {
        method: 'DELETE',
      });
    }
    return undefined;
  };

  const contactDeleteFromCommunity = async (body: {
    id: string;
    communityid: string;
  }): Promise<ContactDeleteResponse | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/${body.communityid}/${body.id}/ghost`, {
        method: 'DELETE',
      });
    }
    return undefined;
  };

  /**
   * Delete Contact by id
   * @param id Contact id
   * @returns Contact
   */
  const getContactDeleteType = async (body: {
    id: string;
  }): Promise<ContactDeleteResponse | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/request/${body.id}`, {
        method: 'GET',
      });
    }
    return undefined;
  };

  const getCommunityContactDeleteInformation = async (body: {
    id: string;
    uid: string;
  }): Promise<DeleteInfo | undefined> => {
    if (accessToken) {
      return await fetchData(
        `communities/${body.id}/remove/connected/person/${body.uid}/deleteinformation`,
        {
          method: 'GET',
        }
      );
    }
    return undefined;
  };

  /**
   * Get all communities belonging to the user.
   * @returns ContactCommunity[]
   */
  const getContactCommunities = async (): Promise<
    ContactCommunity[] | undefined
  > => {
    if (accessToken) {
      return await fetchData(`contacts/option/community/`, {
        method: 'GET',
      });
    }
    return undefined;
  };

  /**
   * If there is an ID, get groups belonging to the community.
   * If there is no ID, get all other groups not belonging to the community.
   * @param id community_id
   * @returns CommunityGroup[]
   */
  const getContactGroupsFolders = async (body: {
    id: string | undefined;
  }): Promise<GetContactGroupsFoldersResponse | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/option/group/${body.id}`, {
        method: 'GET',
      });
    }
    return undefined;
  };

  /**
   * If there is an ID, get folders belonging to the community.
   * If there is no ID, get all other folders not belonging to the community.
   * @param id community_id
   * @returns CommunityFolder[]
   */
  const getContactFolders = async (body: {
    id: string | undefined;
  }): Promise<CommunityFolder[] | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/option/folder/${body.id}`, {
        method: 'GET',
      });
    }
    return undefined;
  };
  const getContactMsgRoomHasMsgs = async (body: {
    id: string | undefined;
  }): Promise<boolean | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/group/hasmsg/${body.id}`, {
        method: 'GET',
      });
    }
    return undefined;
  };

  /**
   * set group invite link
   */
  const setGroupInviteLink = async (body: {
    group_id: string | undefined;
    cid: string | undefined;
    link: string | undefined;
  }): Promise<string> => {
    if (accessToken) {
      return await fetchData(`contacts/group/invitelink`, {
        method: 'POST',
        body: body,
      });
    }
    return '';
  };

  /**
   * If there is an ID, get tools belonging to the community.
   * If there is no ID, get all other tools not belonging to the community.
   * @param id community_id
   * @returns ToolFavorite[]
   */
  const getContactTools = async (body: {
    id: string | undefined;
  }): Promise<ToolFavorite[] | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/option/tool/${body.id}`, {
        method: 'GET',
      });
    }
    return undefined;
  };

  /**
   * Create a group first without members
   * @param settings GroupRoomSettings
   * @returns CommunityGroup | Room
   */
  const contactGroupPost = async (
    settings: GroupRoomSettings
    // ): Promise<CommunityGroup | Room | undefined> => {
  ): Promise<any> => {
    if (accessToken) {
      return await fetchData(`contacts/group/`, {
        method: 'POST',
        body: settings,
      });
    }
    return undefined;
  };

  /**
   * Update a group first without members
   * @param settings GroupRoomSettings
   * @returns ToolFavorite[]
   */
  const contactGroupPatch = async (
    settings: GroupRoomSettings
  ): Promise<any> => {
    if (accessToken) {
      return await fetchData(`contacts/group/`, {
        method: 'PATCH',
        body: settings,
      });
    }
    return undefined;
  };

  /**
   * add Member to Community or Group
   * @param data ContactsAddMemberPost
   * @returns ContactsGetResponse
   */
  const contactsAddMember = async (
    data: ContactsAddMemberPost
  ): Promise<ContactsGetResponse | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/member/`, {
        method: 'POST',
        body: data,
      });
    }
    return undefined;
  };

  /**
   * get tools community's tools
   * @param id id Community's id
   * @returns ToolFavorite[]
   */
  const communityToolsGet = async (
    id: string
  ): Promise<ToolFavorite[] | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/tools/${id}`);
    }
    return undefined;
  };

  /**
   * post community's favorite tools
   * @param data.id Community's id
   * @returns ToolFavorite[]
   */
  const communityFavoriteToolsPost = async (
    data: CommunityFavoriteToolsPostRequest
  ): Promise<ToolFavorite[] | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/tools/${data.id}`, {
        method: 'POST',
        body: {
          user_id: data.user_id,
          group_id: data.group_id,
          templates: data.tools,
        },
      });
    }
    return undefined;
  };

  /**
   */
  const contactGroupDelete = async (id: string): Promise<any | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/group/${id}`, {
        method: 'DELETE',
      });
    }
    return undefined;
  };

  /**
   * Delete ToolElementContact
   */
  const contactToolValueDelete = async (
    data: any
  ): Promise<any | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/tool/delete/`, {
        method: 'DELETE',
        body: data,
      });
    }
    return undefined;
  };

  const addNetworkFavorites = async (
    data: NetworkFavoritesRequest
  ): Promise<NetworkFavorite | undefined> => {
    if (accessToken) {
      return await fetchData(`contacts/favorite`, {
        method: 'POST',
        body: data,
      });
    }
    return undefined;
  };

  const removeNetworkFavorites = async (
    data: NetworkFavoritesRequest
  ): Promise<NetworkFavorite | undefined> => {
    if (accessToken) {
      return await fetchData(
        `contacts/favorite/${data.get_type}/${data.community_id}/${data.group_id}`,
        { method: 'DELETE' }
      );
    }
    return undefined;
  };

  const updateNavigationNetwork = async (
    data: AccordionExpandedRequest
  ): Promise<AccordionExpandedRequest | undefined> => {
    if (accessToken) {
      return await fetchData(`navigation-state/network`, {
        method: 'PATCH',
        body: data,
      });
    }
    return undefined;
  };

  const getNavigationNetwork = async (
    data: AccordionExpandedGet
  ): Promise<AccordionExpansionState | undefined> => {
    if (accessToken) {
      if (data.is_mobile) {
        return await fetchData(`navigation-state/network/mobile`, {
          method: 'GET',
        });
      } else {
        return await fetchData(`navigation-state/network`, {
          method: 'GET',
        });
      }
    }
    return undefined;
  };

  const updateNavigationNewscenter = async (
    data: AccordionExpandedRequest
  ): Promise<AccordionExpandedRequest | undefined> => {
    if (accessToken) {
      return await fetchData(`navigation-state/newscenter`, {
        method: 'PATCH',
        body: data,
      });
    }
    return undefined;
  };

  const getNavigationNewscenter = async (
    data: AccordionExpandedGet
  ): Promise<AccordionExpansionState | undefined> => {
    if (accessToken) {
      if (data.is_mobile) {
        return await fetchData(`navigation-state/newscenter/mobile`, {
          method: 'GET',
        });
      } else {
        return await fetchData(`navigation-state/newscenter`, {
          method: 'GET',
        });
      }
    }
    return undefined;
  };

  const getPermissionForNewVersion = async (): Promise<boolean | undefined> => {
    return await fetchData(`contacts/permission/new`);
  };

  return {
    contactDelete,
    contactDeleteFromGroup,
    contactDeleteFromCommunity,
    getContactDeleteType,
    getContactCommunities,
    getContactGroupsFolders,
    getContactFolders,
    setGroupInviteLink,
    getContactTools,
    contactGet,
    contactsGet,
    communityFavoriteToolsPost,
    contactsInvitePost,
    contactsNavGet,
    contactGroupPost,
    contactGroupPatch,
    nicknameChangePatch,
    contactsAddMember,
    communityToolsGet,
    contactGroupDelete,
    contactToolValueDelete,
    addNetworkFavorites,
    removeNetworkFavorites,
    getContactMsgRoomHasMsgs,
    updateNavigationNetwork,
    getNavigationNetwork,
    updateNavigationNewscenter,
    getNavigationNewscenter,
    getPermissionForNewVersion,
    getCommunityContactDeleteInformation,
  };
};
