import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { useMutation } from 'react-query';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Box, Button, Popover } from '@mui/material';
import clsx from 'clsx';

// Component
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { FormDelete } from '../../../../shared/components/FormDelete/FormDelete';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { PopoverImagePreviewItem } from '../../../../shared/components/PopoverImagePreviewItem/PopoverImagePreviewItem';

// Contexts
import { SocketContext } from '../../../../shared/context/socket.context';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';
import { useNewsCenter } from '../../hooks/use-news-center.hook';
import { useNewsCenterHttp } from '../../hooks/use-news-center-http.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  ActiveState,
  ContactsInvitePostRequest,
} from '../../../contacts/models/contacts.types';
import {
  GroupUser,
  RemovePersonRequest,
  RoomRequest,
  RoomType,
} from '../../models/news-center.types';
import {
  DeleteForm,
  ImageFallbackType,
  RemoveRoomUsersSocketResponse,
  ResultState,
} from '../../../../shared/models/shared.types';
import { InfoMessageType } from '../../models/message.types';

// Stores
import { useAuthStore } from '../../../public/stores/use-auth.store';
import { useNewsCenterStore } from '../../stores/use-news-center.store';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';
import { useUserStore } from '../../../../modules/user/stores/use-user.store';

// Styles
import styles from './GroupUsers.module.scss';
import { FormInviteIndirect } from '../../../../shared/components/FormInviteIndirect/FormInviteIndirect';
import { DeleteInfo, UserEntity } from '../../../user/models/user.types';
import { FormDeleteDialog } from '../../../../new_shared/components/FormDeleteDialog/FormDeleteDialog';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';

type GroupUserItemProps = {
  user: GroupUser;
  useUnderline?: boolean;
  setAnchorEl?: React.Dispatch<
    React.SetStateAction<Element | null | undefined>
  >;
};

export const GroupUserItem = (props: GroupUserItemProps) => {
  // Context
  const { handleError, handleRetry } = useFetch();
  const socket = useContext(SocketContext);
  const { contactsInvitePost } = useContactsHttp();
  const { fullNameGet } = useShared();
  const navigate = useNavigate();
  const { directRoomIdByUserGet, onInfoMessageSubmit } = useNewsCenter();
  const { directRoomPost, newsCenterRemovePersonDeleteInfoGet } =
    useNewsCenterHttp();
  const { t } = useTranslation();

  // Component state
  const [fullName, setFullName] = useState<string | TFunctionResult>('');
  const [selectedUserIndirectToInvite, setSelectedUserIndirectToInvite] =
    useState<GroupUser | undefined>(undefined);
  const [selectedUserIndirectToRemove, setSelectedIndirectUserToRemove] =
    useState<GroupUser | undefined>(undefined);

  // Auth store state
  const [accessToken] = useAuthStore((state) => [state.accessToken]);

  // News center store state
  const [selectedRoom] = useNewsCenterStore((state) => [state.selectedRoom]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // User store state
  const [account] = useUserStore((state) => [state.account]);
  const [deleInformation, setDeleInformation] = useState<
    DeleteInfo | undefined
  >(undefined);

  // ######### //
  // MUTATIONS //
  // ######### //

  // POST Direct room mutation
  const directRoomPostMutation = useMutation((data: RoomRequest) =>
    directRoomPost(data)
  );

  // Add new direct room to store
  useEffect(() => {
    if (directRoomPostMutation.data) {
      try {
        navigate(`/newscenter/private/${directRoomPostMutation.data.id}`);
      } catch (error) {
        console.log('ERROR on creating direct room:', error);
      }
    }
    if (directRoomPostMutation.error) {
      // const errRes = directRoomPostMutation.error?.response;
      // if (errRes) {
      //   handleError(errRes.status);
      // }
    }
    // eslint-disable-next-line
  }, [directRoomPostMutation.data, directRoomPostMutation.error]);

  // POST Contact invite mutation
  const invitePostMutation = useMutation((data: ContactsInvitePostRequest) =>
    contactsInvitePost(data)
  );

  // Add contact to store
  useEffect(() => {
    if (invitePostMutation.data) {
      try {
        setNotification({
          title: t('app.users.invite.success'),
          state: ResultState.Success,
        });
        setSelectedUserIndirectToInvite(undefined);
      } catch (error) {
        console.log('ERROR on inviting contact:', error);
      }
    }
    if (invitePostMutation.error) {
      setNotification({
        title: `${t('app.users.invite.error')} `,
        state: ResultState.Error,
      });
    }
    // eslint-disable-next-line
  }, [invitePostMutation.data, invitePostMutation.error]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Set user full name on mount
  useEffect(() => {
    props.user &&
      setFullName(
        fullNameGet(
          props.user.first_name,
          props.user.last_name,
          props.user.nickname
        )
      );
    // eslint-disable-next-line
  }, [props.user]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to invite indirect contact.
   */
  const onInviteIndirect = useCallback(() => {
    props.user.email &&
      invitePostMutation.mutate({
        contacts: [{ email: props.user.email }],
      });
    // eslint-disable-next-line
  }, [props.user]);

  /**
   * Handler to open or create direct room.
   * @param id User id
   */
  const onMessage = useCallback(() => {
    if (props.user.state === ActiveState.Indirect) {
      setSelectedUserIndirectToInvite(props.user);
    } else {
      // Find active direct room by user id
      const pChatId = directRoomIdByUserGet(props.user.id);
      if (pChatId) {
        navigate(`/newscenter/private/${pChatId}`);
      } else {
        directRoomPostMutation.mutate({
          participant: [props.user.id],
          type: RoomType.Direct,
        });
      }
    }
    // eslint-disable-next-line
  }, [props.user]);

  /**
   * Handler to init user removal from group.
   * @param user User
   */
  const onRemoveInit = useCallback(
    () => {
      selectedRoom &&
        userDeleteInformationMutation.mutate({
          room_id: selectedRoom?.id,
          uid: props.user.id,
        });
      setSelectedIndirectUserToRemove(props.user);
    },
    // eslint-disable-next-line
    [props.user]
  );

  /**
   * Handler to remove user from group.
   */
  const onRemoveSubmit = useCallback(
    () => {
      if (
        selectedRoom &&
        selectedUserIndirectToRemove &&
        selectedUserIndirectToRemove.id
      ) {
        const user_name = fullNameGet(
          selectedUserIndirectToRemove.first_name,
          selectedUserIndirectToRemove.last_name
        )?.toString();
        // send info message
        if (user_name) {
          let object: string[] = [];
          object.push(user_name);
          onInfoMessageSubmit(InfoMessageType.remove, object);
        }

        socket.emit(
          'deleteRoomUser',
          {
            accessToken,
            room_id: selectedRoom.id,
            user_id: selectedUserIndirectToRemove.id,
          },
          (response: RemoveRoomUsersSocketResponse) => {
            if (response.status === ResultState.Success) {
              setSelectedIndirectUserToRemove(undefined);
            }
          }
        );
        setSelectedIndirectUserToRemove(undefined);
        props.setAnchorEl && props.setAnchorEl(null);
      }
    },
    // eslint-disable-next-line
    [selectedRoom, selectedUserIndirectToRemove]
  );

  const userDeleteInformationMutation = useMutation(
    (data: RemovePersonRequest) => newsCenterRemovePersonDeleteInfoGet(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data) => {
        if (data) {
          data && setDeleInformation(data);
        }
      },
    }
  );

  return (
    <Box className={styles['group-user-item']}>
      <div className={styles['group-user-item-title']}>
        <>
          {props.user.avatar?.url ? (
            <img
              alt={fullName as string}
              className={styles['group-user-item-title-image']}
              src={props.user.avatar.url}
            />
          ) : (
            <ImageFallback
              iconSize="0.8em"
              sizeClassName={styles['group-user-item-title-image']}
              type={ImageFallbackType.Profile}
            />
          )}
        </>
        <Box
          sx={{
            color: 'text.secondary',
            textDecoration: props.useUnderline ? 'underline' : undefined,
          }}
          className={styles['group-user-item-title-text']}
        >
          {fullName}
        </Box>
      </div>
      <div className={styles['group-user-item-actions']}>
        <IconButton
          classes={styles['group-user-item-actions-message']}
          disabled={props.user.id === account?.id}
          icon={['fas', 'message']}
          iconColor="primary.main"
          onClick={onMessage}
        />
        <IconButton
          disabled={
            props.user.id === account?.id ||
            !selectedRoom?.permissions.can_add_user
          }
          icon={['fas', 'trash']}
          iconColor="text.secondary"
          onClick={onRemoveInit}
        />
      </div>
      <Dialog
        open={selectedUserIndirectToInvite ? true : false}
        subtitle={`${t('app.users.invite.subtitle1')} ${fullName} ${t(
          'app.users.invite.subtitle2'
        )} ${fullName} ${t('app.users.invite.subtitle3')}`}
        title={t('app.users.invite.title')}
        onClose={() => setSelectedUserIndirectToInvite(undefined)}
      >
        <FormInviteIndirect
          onClose={() => setSelectedUserIndirectToInvite(undefined)}
          onSubmit={onInviteIndirect}
        />
        {/* <div className={styles['group-user-item-invite']}>
          <TextButton
            classes={styles['group-user-item-invite-cancel']}
            preset="secondary"
            onClick={() => setSelectedUserIndirectToInvite(undefined)}
          >
            {t('app.cancel')}
          </TextButton>
          <TextButton preset="primary" onClick={onInviteIndirect}>
            {t('app.users.invite.button')}
          </TextButton>
        </div> */}
      </Dialog>
      <FormDeleteDialog
        open={selectedUserIndirectToRemove ? true : false}
        title={
          <Box sx={{ fontSize: '1.25rem', lineHeight: '1.125rem' }}>
            <Box>
              Möchtest du diese Person wirklich aus der Gruppe entfernen?
            </Box>
          </Box>
        }
        whichDelete={DeleteForm.RemovePersonGroup}
        deleteInformation={deleInformation}
        onClose={() => setSelectedIndirectUserToRemove(undefined)}
        onSubmit={onRemoveSubmit}
      />
    </Box>
  );
};

type GroupUsersProps = {
  classes?: string;
  icon?: [IconPrefix, IconName];
  users: GroupUser[];
  groupCreator?: UserEntity;
};

export const GroupUsers = (props: GroupUsersProps) => {
  const { t } = useTranslation();
  const { smUp } = useBreakpoints();

  // Component state
  const [anchorEl, setAnchorEl] = useState<Element | null | undefined>(null);
  const open = Boolean(anchorEl);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Close menu.
   */
  const onClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <>
        {props.icon ? (
          <IconButton
            classes={props.classes && props.classes}
            icon={props.icon}
            preset="popover"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
        ) : (
          <Button
            color="inherit"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className={clsx(
              styles['group-users-button'],
              props.classes && props.classes
            )}
          >
            <ul className={styles['group-users-button-preview']}>
              {props.users.length > 0 && (
                <PopoverImagePreviewItem
                  name={props.users[0].first_name ?? 'Avatar'}
                  src={props.users[0].avatar?.url}
                />
              )}
              {props.users.length > 1 && (
                <PopoverImagePreviewItem
                  name={props.users[1].first_name ?? 'Avatar'}
                  overlap
                  src={props.users[1].avatar?.url}
                />
              )}
              {props.users.length > 2 && (
                <PopoverImagePreviewItem
                  name={props.users[2].first_name ?? 'Avatar'}
                  overlap
                  src={props.users[2].avatar?.url}
                />
              )}
              {props.users.length === 4 && (
                <PopoverImagePreviewItem
                  name={props.users[3].first_name ?? 'Avatar'}
                  overlap
                  src={props.users[3].avatar?.url}
                />
              )}
              {props.users.length > 4 && (
                <li>
                  <Box
                    className={styles['group-users-button-preview-more']}
                    sx={{ backgroundColor: 'background.paper' }}
                  >
                    <Box
                      className={
                        styles['group-users-button-preview-more-content']
                      }
                      sx={{
                        backgroundColor: '#444d58',
                        color: 'white',
                      }}
                    >
                      {`+ ${props.users.length - 3}`}
                    </Box>
                  </Box>
                </li>
              )}
            </ul>
          </Button>
        )}
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: smUp ? 'right' : 'center',
            vertical: 'bottom',
          }}
          open={open}
          onClose={onClose}
          sx={{
            '& .MuiPaper-root': {
              borderRadius: '0.5rem',
              boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.15)',
              height: 'fit-content',
              margin: smUp ? '0.5rem' : '7%',
              overflowY: 'auto',
              width: smUp ? '400px' : '80%',
            },
            '& .MuiList-root': {
              padding: 0,
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          className={styles['group-users-container']}
        >
          <Box
            sx={{ borderColor: 'border.app' }}
            className={styles['group-users-container-title']}
          >
            {t('newscenter.room.groups.users.title')}
          </Box>
          <div className={styles['group-users-container-content']}>
            {props.users &&
              props.users.map((user) => (
                <GroupUserItem
                  key={user.id}
                  user={user}
                  setAnchorEl={setAnchorEl}
                  useUnderline={user.id === props.groupCreator?.id}
                />
              ))}
          </div>
        </Popover>
      </>
    </>
  );
};
