import { Box, Checkbox, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../new_shared/hooks/use-responsive ';
import { Icon } from '../../shared/ui/Icon/Icon';
import nav_background from '../../assets/pictures/dashboard/navBackground.png';
import icon1 from '../../assets/pictures/icon1.png';
import icon2 from '../../assets/pictures/icon2.png';
import icon3 from '../../assets/pictures/icon3.png';
import icon4 from '../../assets/pictures/icon4.png';
import icon1_hover from '../../assets/pictures/icon1_hover.png';
import icon2_hover from '../../assets/pictures/icon2_hover.png';
import icon3_hover from '../../assets/pictures/icon3_hover.png';

import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import {
  ContactsState,
  useContactsStore,
} from '../../modules/contacts/stores/use-contacts.store';
import { NavCommunity } from './NavCommunity/NavCommunity';
import {
  AccordionExpandedGet,
  AccordionExpansionState,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactsGetFilters,
} from '../../modules/contacts/models/contacts.types';
import { CommunityUserRole } from '../../modules/communities/models/communities.types';
import { useContacts } from '../../modules/contacts/hooks/use-contacts.hook';
import {
  ResultState,
  Theme,
  thinScroll,
} from '../../shared/models/shared.types';
import { NaviTitle } from './NaviTitle/NaviTitle';
import { useMutation } from 'react-query';
import { useFetch } from '../../shared/hooks/use-fetch.hook';
import { useContactsHttp } from '../../modules/contacts/hooks/use-contacts-http.hook';
import { Loader } from '../../shared/ui/Loader/Loader';
import {
  UserState,
  useUserStore,
} from '../../modules/user/stores/use-user.store';
import {
  SharedState,
  useSharedStore,
} from '../../shared/stores/use-shared.store';
import {
  CommunityState,
  useCommunityStore,
} from '../../modules/communities/store/use-community.store';
import { FormDialog } from '../../new_shared/components/FormDialog/FormDialog';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputFlat } from '../../shared/ui/InputFlat/InputFlat';
import { CheckboxWithLabel } from '../../shared/ui/CheckboxWithLabel/CheckboxWithLabel';
import { FormFieldLabel } from '../../shared/ui/FormFieldLabel/FormFieldLabel';
import { Select } from '../../shared/ui/Select/Select';
import { IconButton } from '../../shared/ui/IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type NavigationProps = {
  isLoading: boolean;
  selectedItem?: string;
  expanded: AccordionExpansionState;
  setExpanded: React.Dispatch<React.SetStateAction<AccordionExpansionState>>;
  onContactsGet: (
    action: ContactCommunityGetAction,
    params: ContactsGetFilters
  ) => void;
  // expanded: AccordionExpansionState;
  // setExpanded: Dispatch<SetStateAction<AccordionExpansionState>>;
  setContactCommunity: Dispatch<SetStateAction<ContactCommunity>>;
  setMemberRoleCommunity: Dispatch<SetStateAction<CommunityUserRole>>;
};

export const Navigation = memo(
  ({
    isLoading,
    expanded,
    selectedItem,
    onContactsGet,
    setExpanded,
    setContactCommunity,
    setMemberRoleCommunity,
  }: NavigationProps) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const { newContactsNavGet } = useContacts();
    const { handleRetry } = useFetch();
    const [account] = useUserStore((state: UserState) => [state.account]);
    const [navLoading, setNavLoading] = useState(false);
    const [dialogCreateFirstCommunity, setDialogCreateFirstCommunity] =
      useState(false);
    // Contacts Store State
    const [navCommunities, setNavCommunities, setGroupChats] = useContactsStore(
      (state: ContactsState) => [
        state.navCommunities,
        state.setNavCommunities,
        state.setGroupChats,
      ]
    );
    // Community Store State
    const [setDialogCommunityCreate] = useCommunityStore(
      (state: CommunityState) => [state.setDialogCommunityCreate]
    );
    // Shared store state
    const [theme] = useSharedStore((state: SharedState) => [state.theme]);

    // Get Nav items
    useEffect(() => {
      if (navCommunities.length === 0) {
        setTimeout(() => newContactsNavGet(), 500); // after get changes
      }
    }, []);

    // Set show Loader
    useEffect(() => {
      if (navCommunities.length === 0) {
        setNavLoading(true);
        setTimeout(() => {
          setNavLoading(false);
        }, 3000);
      } else {
        setNavLoading(false);
      }
    }, [navCommunities.length]);

    return (
      <Box
        className="relative h-full -mb-40 md:mb-0"
        navigation-containe
        sx={{ backgroundColor: 'bg.nav' }}
      >
        <div className="relative flex flex-col justify-between h-full">
          <Box className="p-6 pr-4 overflow-y-auto" sx={{ ...thinScroll }}>
            <NaviTitle title="Meine Gemeinschaften" />

            {/****************************/}
            {/*   Navigation Contents    */}
            {/****************************/}
            {navLoading ? (
              <Loader />
            ) : (
              (navCommunities || []).map((community) => (
                <NavCommunity
                  key={community.id}
                  community={community}
                  expanded={expanded}
                  selectedItem={selectedItem}
                  onContactsGet={onContactsGet}
                  setExpanded={setExpanded}
                  setContactCommunity={setContactCommunity}
                  setMemberRoleCommunity={setMemberRoleCommunity}
                />
              ))
            )}
          </Box>

          {/****************************/}
          {/*          Footer          */}
          {/****************************/}
          <Box className="flex flex-col justify-end pt-3 lg:pt-0 h-fit min-h-[80px] md:min-h-[325px] w-full md:w-dashboard-nav-width ">
            {/* Background image container */}
            {theme === Theme.Light && (
              <Box
                className="relative w-full h-20 md:h-fit"
                sx={{
                  backgroundColor: isMobile ? 'bg.newNavImage' : undefined,
                }}
              >
                <img
                  className="hidden lg:flex w-full"
                  src={nav_background}
                  alt="background"
                />
              </Box>
            )}

            {/*     Button       */}
            <Box
              sx={{
                backgroundColor: isMobile ? 'bg.nav' : undefined,
              }}
              className="fixed p-4 bottom-0 w-dashboard-nav-width"
            >
              <Box
                className="flex justify-center w-full p-3 rounded-md cursor-pointer mb-2"
                sx={{
                  color: 'white',
                  backgroundColor: 'black',
                  borderColor: 'white',
                  border: '1px solid',
                }}
                onClick={() => setDialogCreateFirstCommunity(true)}
              >
                <Icon
                  classes="mr-2"
                  icon={['fas', 'users']}
                  sx={{ color: 'white' }}
                />
                <span>
                  {navCommunities.length === 0
                    ? 'Meine erste Unternehmung'.toUpperCase()
                    : 'Eine weitere Unternehmung'.toUpperCase()}
                </span>
              </Box>
              <Box
                className="flex justify-center w-full p-3 rounded-md cursor-pointer"
                sx={{
                  color: 'white',
                  backgroundColor: 'bg.navButton',
                  borderColor: 'white',
                  border: '1px solid',
                }}
              >
                <Icon
                  classes="mr-2"
                  icon={['fas', 'tools']}
                  sx={{ color: 'white' }}
                />
                <span>{'Meine Online Tools'.toUpperCase()}</span>
              </Box>
            </Box>
          </Box>
        </div>
        <CommunityCreate
          firstCommunity={navCommunities.length === 0}
          dialogCreateFirstCommunity={dialogCreateFirstCommunity}
          setDialogCreateFirstCommunity={setDialogCreateFirstCommunity}
        />
      </Box>
    );
  }
);

export interface CommunityPostRequest {
  logo?: File;
  name: string;
  sector?: any;
  employeename?: string;
  membername?: string;
}
type CommunityCreateProps = {
  firstCommunity: boolean;
  dialogCreateFirstCommunity: boolean;
  setDialogCreateFirstCommunity: Dispatch<SetStateAction<boolean>>;
};
const CommunityCreate = ({
  firstCommunity,
  dialogCreateFirstCommunity,
  setDialogCreateFirstCommunity,
}: CommunityCreateProps) => {
  const { t } = useTranslation();

  // React hook form validation schema
  const createValidation = yup.object({
    logo: yup.mixed(),
    name: yup.string().required(t('community.create.form.name.error')),
    sector: yup.mixed(),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<CommunityPostRequest>({
    resolver: yupResolver(createValidation),
  });

  return (
    <FormDialog
      open={dialogCreateFirstCommunity}
      onClose={() => setDialogCreateFirstCommunity(false)}
      onSubmit={() => {}}
      title={
        <Box sx={{ fontSize: '1.25rem', lineHeight: '1.125rem' }}>
          {firstCommunity ? (
            <Box>lass uns mit deiner ersten</Box>
          ) : (
            <Box>lass uns mit einer weiteren</Box>
          )}
          <Box>Unternehmung starten.</Box>
        </Box>
      }
      content={<CommunityCreateContent />}
      footer={<CommunityCreateFooter firstCommunity={firstCommunity} />}
    />
  );
};

const CommunityCreateContent = () => {
  const { t } = useTranslation();

  const [icon1Hover, setIcon1Hover] = useState(false);
  const [icon2Hover, setIcon2Hover] = useState(false);
  const [icon3Hover, setIcon3Hover] = useState(false);

  return (
    <Box>
      <InputFlat
        preset="White"
        widthClassName="w-2/3"
        label="Gib' deiner Unternehmung einen Namen"
        placeholder="Bezeichnung"
      />
      <Box className="flex flex-row mt-6">
        <Box className="w-fit">
          <Box className="font-semibold">Wie groß ist deine Unternehmung?</Box>
          <Box className="flex flex-row h-24 mt-2">
            <Box
              component="img"
              src={icon1Hover ? icon1_hover : icon1}
              className="mr-3"
              onMouseOver={() => setIcon1Hover(true)}
              onMouseOut={() => setIcon1Hover(false)}
            />
            <Box
              component="img"
              src={icon2Hover ? icon2_hover : icon2}
              onMouseOver={() => setIcon2Hover(true)}
              onMouseOut={() => setIcon2Hover(false)}
              className="mr-3"
            />
            <Box
              component="img"
              src={icon3Hover ? icon3_hover : icon3}
              onMouseOver={() => setIcon3Hover(true)}
              onMouseOut={() => setIcon3Hover(false)}
              className="mr-5"
            />
          </Box>
        </Box>
        <Box className="flex flex-col">
          <Box className="font-semibold">Oder starte vorkonfiguriert</Box>
          <Box
            className="mt-2 rounded-lg"
            sx={{ backgroundColor: 'background.paper' }}
          >
            <Select
              state={ResultState.Standard}
              padding="12px 24px 12px 12px !important"
              defaultPlaceholder="Wähle eine Vorlage"
              options={[]}
              value={''}
              onChange={() => {}}
            />
          </Box>
          <Box className="mt-2" sx={{ fontSize: '10px !important' }}>
            Hier kannst du eine fertige Konfiguration als Basis deiner
            Unternehmung auswählen und nach deinen Bedurinissen anpass
          </Box>
        </Box>
      </Box>
      <Box className="font-semibold mt-6 mb-1">
        Hilft dir jemand beim Einrichten von Pengueen?
      </Box>
      <Box className="flex flex-row">
        <InputFlat
          preset="White"
          className="pr-2"
          widthClassName="w-1/4"
          caption="Vorname"
          placeholder={t('tools.detail.form.input.placeholder')}
        />
        <InputFlat
          preset="White"
          className="pr-2"
          widthClassName="w-1/4"
          caption="Nachname"
          placeholder={t('tools.detail.form.input.placeholder')}
        />
        <InputFlat
          preset="White"
          widthClassName="w-1/2"
          caption="E-Mail"
          placeholder={t('tools.detail.form.input.placeholder')}
        />
      </Box>
    </Box>
  );
};
type CommunityCreateFooterProps = {
  firstCommunity: boolean;
};
const CommunityCreateFooter = ({
  firstCommunity,
}: CommunityCreateFooterProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      {firstCommunity && (
        <Box className="footer-visit-card">
          <Box>
            <Box component="span" className="text-lg font-semibold">
              Deine Visitenkarte{' '}
            </Box>
            <Box component="span">So sehen dich andere.</Box>
          </Box>
          <Box className="flex flex-row w-full rounded-lg pt-4">
            <Box className="w-fit mr-5 relative">
              <Box component="img" src={icon4} className="h-[92px]" />
              <IconButton
                classes="absolute -top-3 -right-3 h-6 w-6 rounded-full text-xs"
                preset="primary"
                icon={['fas', 'pen']}
                iconColor="text-app"
                iconSize="inherit"
                // sxIcon={{ padding: 0.25 }}
                onClick={() => {}}
              />
            </Box>
            <Box className="flex flex-col grow">
              <Box className="flex flex-row">
                <InputFlat
                  className="pr-2"
                  widthClassName="w-1/2"
                  placeholder={t('form.profile.first_name.title')}
                />
                <InputFlat
                  widthClassName="w-1/2"
                  placeholder={t('form.profile.last_name.title')}
                />
              </Box>
              <Box className="mt-2">
                <InputFlat placeholder={t('app.email')} />
              </Box>
            </Box>
          </Box>
          <Box className="mt-4">
            <Box className="font-semibold text-base">Dein Sicherheitssatz</Box>
            <Box sx={{ color: 'text.secondary' }}>
              Diesen Satz findest du in allen E-Mails, die du von uns erhältst.
            </Box>
            <Box sx={{ color: 'text.secondary' }}>
              So bist du sicher vor Phishing.
            </Box>
            <InputFlat
              className="mt-4"
              placeholder={t('tools.detail.form.input.placeholder')}
            />
          </Box>
        </Box>
      )}

      <Box className="footer-description">
        <Box className="mt-4 font-semibold text-base">
          Klasse! Das ist für deinen Start erst einmal genug.
        </Box>
        <Box>
          In den nächsten Schritten kannst du weitere Personen zu deiner
          Unternehmung einladen, erste Tools, die dich unterstützen anlegen und
          vieles mehr.
        </Box>
      </Box>
    </Box>
  );
};
