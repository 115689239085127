import { Box } from '@mui/material';
import styles from './StartPage.module.scss';

export const StartPage = () => {
  return (
    <div className={styles['start-page-container']}>
      <div className="text-center"></div>
      {/* Background image container */}
      <Box className={styles['start-page-background']}>
        {/* <img src={start_page_background} alt="background" /> */}
      </Box>
    </div>
  );
};
