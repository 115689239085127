import { useNavigate } from 'react-router-dom';

// Stores
import { useAuthStore } from '../stores/use-auth.store';
import { useDashboardStore } from '../../dashboard/stores/use-dashboard.store';
import { useUserStore } from '../../user/stores/use-user.store';
import { useSharedStore } from '../../../shared/stores/use-shared.store';
import { useNewsCenterStore } from '../../newscenter/stores/use-news-center.store';
import { useToolsStore } from '../../tools/stores/use-tools.store';
import { useContactsStore } from '../../contacts/stores/use-contacts.store';

export const useLogout = () => {
  const navigate = useNavigate();

  // Auth store state
  const [resetAuthStoreData] = useAuthStore((state) => [
    state.resetAuthStoreData,
  ]);

  const [resetContactStoreData] = useContactsStore((state) => [
    state.resetContactStoreData,
  ]);

  // Dashboard store state
  const [resetDashboardStoreData] = useDashboardStore((state) => [
    state.resetDashboardStoreData,
  ]);

  // Shared store state
  const [setDialogContacts] = useSharedStore((state) => [
    state.setDialogContacts,
  ]);

  // User store state
  const [resetUserStoreData] = useUserStore((state) => [
    state.resetUserStoreData,
  ]);

  // Newscenter store state
  const [resetStoreData, setGroupRoomCreateEditSettings] = useNewsCenterStore(
    (state) => [state.resetStoreData, state.setGroupRoomCreateEditSettings]
  );

  // Tool store state
  const [resetToolsStoreData] = useToolsStore((state) => [
    state.resetToolsStoreData,
  ]);

  /**
   * Handler to logout.
   */
  const logout = () => {
    // #TODO: Blacklist token?

    // Remove access token from local storage
    localStorage.removeItem('frontend:accessToken');

    // Reset store states
    resetAuthStoreData();
    resetContactStoreData();
    resetDashboardStoreData();
    resetUserStoreData();
    resetStoreData();
    setGroupRoomCreateEditSettings(undefined);
    resetToolsStoreData();

    // Close dialogs
    setDialogContacts(false);

    // Navigate to home
    navigate('/');
  };

  return {
    logout,
  };
};
