import React, { useCallback, useEffect, useState } from 'react';

// Components
import { ContactsToolsCard } from '../ContactsToolsCard/ContactsToolsCard';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { ToolFavorite } from '../../../dashboard/models/dashboard.types';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useContactsHttp } from '../../hooks/use-contacts-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';

// Store
import { useContactsStore } from '../../stores/use-contacts.store';

// Styles
import styles from './AddContactsFavoriteTools.module.scss';
import {
  AddContactsFavoriteToolsType,
  CommunityFavoriteToolsPostRequest,
} from '../../models/contacts.types';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

type AddContactsFavoriteToolsProps = {
  type?: AddContactsFavoriteToolsType;
  communityId: string;
  groupId?: string;
  userId?: string;
  onClose: () => void;
  tools?: ToolFavorite[];
  setTools?: React.Dispatch<React.SetStateAction<ToolFavorite[]>>;
};

export const AddContactsFavoriteTools = (
  props: AddContactsFavoriteToolsProps
) => {
  const { t } = useTranslation();
  const { communityToolsGet, communityFavoriteToolsPost } = useContactsHttp();
  const { handleError, handleRetry } = useFetch();

  const [tools, setTools] = useState<ToolFavorite[]>([]);

  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);

  // Contacts store state
  const [communityTools, selectedTools, setCommunityTools, setSelectedTools] =
    useContactsStore((state) => [
      state.communityTools,
      state.seletedTools,
      state.setCommunityTools,
      state.setSelectedTools,
    ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET community tools mutation
  const communityToolsGetMutation = useMutation(
    (body: {
      id: string;
      person: boolean;
      community: boolean;
      group: boolean;
    }) => communityToolsGet(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        // Handle GET contacts
        if (data) {
          setTools(data);
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            console.error(
              'Error getting contact tools:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  // GET community tools mutation
  const communityFavoriteToolsPostMutation = useMutation(
    (data: CommunityFavoriteToolsPostRequest) =>
      communityFavoriteToolsPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error, varibales) => {
        // Handle GET contacts
        if (data) {
          if (
            // for ContactDetail
            props.type === AddContactsFavoriteToolsType.User &&
            props.tools &&
            props.setTools
          ) {
            const updatedTools = [...data];
            updatedTools.push(...props.tools);
            props.setTools(updatedTools);

            // for ContactsContent(group)
          } else {
            const updatedTools = [...data];
            updatedTools.push(...communityTools);

            setCommunityTools(updatedTools);
          }
        }
        if (error) {
          const errRes = error?.response;

          if (errRes) {
            console.error(
              'Error getting contact tools:',
              handleError(errRes.status)
            );
          }
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    if (props.type === AddContactsFavoriteToolsType.User) {
      communityToolsGetMutation.mutate({
        id: props.communityId,
        person: true,
        community: false,
        group: false,
      });
    } else {
      if (props.groupId) {
        communityToolsGetMutation.mutate({
          id: props.communityId,
          person: true,
          community: false,
          group: true,
        });
      } else {
        communityToolsGetMutation.mutate({
          id: props.communityId,
          person: true,
          community: true,
          group: false,
        });
      }
    }
  }, [props.communityId]);

  // ######### //
  // CALLBACKS //
  // ######### //

  const onAddFavoriteTools = useCallback(() => {
    if (props.type === AddContactsFavoriteToolsType.User) {
      // for ContactDetail
      communityFavoriteToolsPostMutation.mutate({
        id: props.communityId,
        user_id: props.userId,
        group_id: undefined,
        tools: selectedTools,
      });
    } else {
      // for ContactsContent(group)
      communityFavoriteToolsPostMutation.mutate({
        id: props.communityId,
        group_id: props.groupId,
        tools: selectedTools,
      });
    }
  }, [props.communityId, props.groupId, selectedTools]);

  return (
    <div className={styles['contacts-favorite-tools']}>
      <ContactsToolsCard
        label={t('contacts.toolbar.community.select_tool')}
        tools={tools}
      />
      <TextButton
        classes={styles['contacts-favorite-tools-submit']}
        disabled={selectedTools.length < 1}
        preset='primary'
        onClick={() => {
          onAddFavoriteTools();
          props.onClose();
        }}
      >
        {t('app.select')}
      </TextButton>
    </div>
  );
};
