import create, { State } from 'zustand';

interface PublicState extends State {
  toolPreviewId: string | undefined;
  setToolPreviewId: (toolPreviewId: string | undefined) => void;
}

export const usePublicStore = create<PublicState>((set) => ({
  toolPreviewId: undefined,
  setToolPreviewId: (toolPreviewId: string | undefined) =>
    set({ toolPreviewId }),
}));
