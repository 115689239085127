import { memo, ReactNode } from 'react';
import { Box } from '@mui/material';

// Components
import { Drawer } from '../Drawer/Drawer';
import { Header } from '../Header/Header';
// import { TrialExpired } from '../common/TrialExpired';

// Stores
// import { SharedState, useSharedStore } from '../../store/useSharedStore';

// Styles
import styles from './ProtectedContainer.module.scss';

type ProtectedContainerProps = {
  page: ReactNode;
  toggleNewView?: (checked: boolean) => void;
  isNewView?: boolean;
};

const ProtectedContainer = (props: ProtectedContainerProps) => {
  const currentUrl = window.location.href;

  return (
    <Box className={styles['container-box']}>
      {currentUrl.includes('/printview') ? (
        <>
          <Box className={styles['protected-container']}>{props.page}</Box>
        </>
      ) : (
        <>
          <Header
            toggleNewView={props.toggleNewView}
            isNewView={props.isNewView}
          />
          <Drawer />
          <Box className={styles['protected-container']}>{props.page}</Box>
        </>
      )}
    </Box>
  );
};

export default memo(ProtectedContainer);
