// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RadioButtonGroup_radio-button-group__ggsn2 {\n    display: flex;\n    flex-direction: column\n}\n  .RadioButtonGroup_radio-button-group-form-field-label-padding__4K5k0 {\n    padding-left: 0.5rem;\n    padding-right: 0.5rem\n}\n  .RadioButtonGroup_radio-button-group-option__ciVjo {\n    margin-left: 0.125rem;\n    border-radius: 0.375rem\n}\n    .RadioButtonGroup_radio-button-group-option__ciVjo:not(:last-of-type) {\n    margin-bottom: 0.25rem\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;IAAA,cAAA;IAAA;CAAA;EAAA;IAAA,qBAAA;IAAA;CAAA;EAAA;IAAA,sBAAA;IAAA;CAAA;IAAA;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radio-button-group": "RadioButtonGroup_radio-button-group__ggsn2",
	"radio-button-group-form-field-label-padding": "RadioButtonGroup_radio-button-group-form-field-label-padding__4K5k0",
	"radio-button-group-option": "RadioButtonGroup_radio-button-group-option__ciVjo"
};
export default ___CSS_LOADER_EXPORT___;
