import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, SxProps, Theme } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';

// Context
import { SocketContext } from '../../../../shared/context/socket.context';

// Components
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { MessageQuote } from '../MessageQuote/MessageQuote';
import { Menu } from '../../../../shared/ui/Menu/Menu';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useNewsCenter } from '../../hooks/use-news-center.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  InfoMessage,
  InfoMessageType,
  Message as IMessage,
  MessageAction,
} from '../../models/message.types';
import {
  ImageFallbackType,
  MenuItem,
  ResultState,
  SocketEventSubscriptionResponse,
} from '../../../../shared/models/shared.types';
import { User } from '../../../user/models/user.types';

// Stores
import { useAuthStore } from '../../../public/stores/use-auth.store';
import { useNewsCenterStore } from '../../stores/use-news-center.store';
import { useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './Message.module.scss';
import { useUtils } from '../../../../shared/hooks/use-utils.hook';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';

type InfoMessageProps = {
  info: InfoMessage;
  content?: string;
};

export const InfoMessageLine = (props: InfoMessageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dividerColor, setDividerColor] = useState<string | undefined>(
    'primary.main'
  );
  // News center store state
  const [selectedRoom] = useNewsCenterStore((state) => [state.selectedRoom]);

  useEffect(() => {
    switch (props.info.type) {
      case InfoMessageType.ToolChatGroup:
      case InfoMessageType.ToolChatMultiRow:
        setDividerColor('orange.main');
        break;
      default:
        setDividerColor('primary.main');
        break;
    }
  }, [props.info.type]);

  return (
    <>
      {props.info.type ? (
        <div className={styles['news-center-chat-content-selected-divider']}>
          <Box
            borderColor={dividerColor}
            className={
              styles['news-center-chat-content-selected-divider-border']
            }
          ></Box>
          <Box
            color={dividerColor}
            className={styles['news-center-chat-content-selected-divider-text']}
          >
            {props.info.type === InfoMessageType.leave && (
              <>
                <span className="font-bold">{props.info.sub}</span>
                <span>{t('newscenter.leave.direct')}</span>
              </>
            )}
            {props.info.type === InfoMessageType.close && (
              <>
                <span className="font-bold">{props.info.sub}</span>
                <span>{t('newscenter.close.group')}</span>
              </>
            )}
            {props.info.type === InfoMessageType.invite && (
              <>
                <span className="font-bold">{props.info.sub}</span>
                <span>{t('newscenter.invite.text1')}</span>
                <span className="font-bold">{props.info.obj}</span>
                <span>{t('newscenter.invite.text2')}</span>
              </>
            )}
            {props.info.type === InfoMessageType.remove && (
              <>
                <span className="font-bold">{props.info.sub}</span>
                <span>{t('newscenter.invite.text1')}</span>
                <span className="font-bold">{props.info.obj}</span>
                <span>{t('newscenter.remove.text1')}</span>
                <span>{t('newscenter.remove.text2')}</span>
              </>
            )}
            {props.info.type === InfoMessageType.join && (
              <>
                <span className="font-bold">{props.info.sub}</span>
                <span>{t('newscenter.info.join.title')}</span>
              </>
            )}
            {props.info.type === InfoMessageType.ToolChatGroup && (
              <div
                className="flex flex-row cursor-pointer"
                onClick={() => navigate(`/tools/${props.info.tool_id}/haschat`)}
              >
                <span className="font-bold mr-1">{props.info.sub}:</span>
                <span
                  // sx={{ ...sxContentTextHtmlSize }}
                  dangerouslySetInnerHTML={{
                    __html: `${props.content}`,
                  }}
                />
              </div>
            )}
            {props.info.type === InfoMessageType.ToolChatMultiRow && (
              <div
                className="flex flex-row cursor-pointer"
                onClick={() => navigate(`/tools/${props.info.tool_id}/haschat`)}
              >
                <span className="font-bold mr-1">{props.info.sub}:</span>
                <span
                  // sx={{ ...sxContentTextHtmlSize }}
                  dangerouslySetInnerHTML={{
                    __html: `${props.content}`,
                  }}
                />
              </div>
            )}
          </Box>
          <Box
            borderColor={dividerColor}
            className={
              styles['news-center-chat-content-selected-divider-border']
            }
          ></Box>
        </div>
      ) : (
        <div
          className={styles['news-center-chat-content-selected-divider-empty']}
        ></div>
      )}
    </>
  );
};

type MessageProps = {
  index: number;
  message: IMessage;
  showUserData?: boolean;
  users: Partial<User>[];
  onMessageEdit: () => void;
  onMessageQuote: () => void;
};

export const Message = (props: MessageProps) => {
  // Context
  const socket = useContext(SocketContext);

  const { smUp, lgDown } = useBreakpoints();
  const { messageHandleContent, messageHandleEmojis } = useNewsCenter();
  const { t } = useTranslation();
  const { fullNameGet } = useShared();
  const { iconPropByFileTypeGet } = useUtils();

  // Refs
  const buttonRef = useRef<any>();

  // Component state
  const [sxContentTextHtmlColor, setSxContentTextHtmlColor] = useState<
    SxProps<Theme> | undefined
  >(undefined);
  const [sxContentTextHtmlSize, setSxContentTextHtmlSize] = useState<
    SxProps<Theme> | undefined
  >(undefined);
  const [dialogDelete, setDialogDelete] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const [messageContent, setMessageContent] = useState<string>('');
  const [ownership, setOwnership] = useState<boolean>(false);
  const [user, setUser] = useState<Partial<User> | undefined>(undefined);
  const [usersRead, setUsersRead] = useState<Partial<User>[]>([]);
  const [showUserForRead, setShowUserForRead] = useState<boolean>(false);
  // const [showUnreadUser, setShowUnreadUser] = useState<boolean>(false);

  // Auth store state
  const [accessToken] = useAuthStore((state) => [state.accessToken]);

  // News center store state
  const [selectedRoom] = useNewsCenterStore((state) => [state.selectedRoom]);

  // User store state
  const [account] = useUserStore((state) => [state.account]);

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    usersRead.some((user) => user.read_message_id === props.message.id) &&
      setShowUserForRead(true);
  }, [props.message, usersRead]);

  // Set message content styles by message content (check for emojis only)
  useEffect(() => {
    if (props.message.content && props.message.content.length > 0) {
      const size = messageHandleEmojis(props.message.content);
      if (size === 'small') {
        setSxContentTextHtmlColor({
          backgroundColor: ownership ? 'bg.darkgray' : 'bg.card',
          padding: lgDown ? '0.5rem' : '1rem',
        });
      } else if (size === 'middle') {
        setSxContentTextHtmlColor({
          padding: 0,
        });
      } else if (size === 'big') {
        setSxContentTextHtmlColor({
          padding: 0,
        });
      }
    }
    // eslint-disable-next-line
  }, [lgDown, ownership, props.message.content]);

  // Set message content font-size by message content (check for emojis only)
  useEffect(() => {
    if (props.message.content && props.message.content.length > 0) {
      const size = messageHandleEmojis(props.message.content);
      if (size === 'middle') {
        setSxContentTextHtmlSize({
          '& p': {
            fontSize: '2rem',
          },
        });
      } else if (size === 'big') {
        setSxContentTextHtmlSize({
          '& p': {
            fontSize: '4rem',
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [lgDown, ownership, props.message.content]);

  // Set message content (santize, replace links)
  useEffect(() => {
    if (props.message.content && props.message.content.length > 0) {
      setMessageContent(messageHandleContent(props.message.content));
    }
    // eslint-disable-next-line
  }, [props.message.content]);

  // Set message edited state on mount
  useEffect(() => {
    if (
      props.message.create_change_info.created_at !==
      props.message.create_change_info.changed_at
    ) {
      setEdited(true);
    }
  }, [props.message.create_change_info]);

  // Set more menu options by message ownership
  useEffect(() => {
    let items: MenuItem[] = [
      {
        action: MessageAction.Edit,
        // disabled: props.message.read.length > 1,
        tooltip:
          props.message.read.length > 1
            ? t('newscenter.room.message.tooltip')
            : undefined,
        tooltip_placement: 'top-end',
        title: t('app.actions.edit'),
      },
      {
        action: MessageAction.Quote,
        title: t('newscenter.room.quote.title'),
      },
      {
        action: MessageAction.Delete,
        title: t('app.actions.delete'),
      },
    ];
    if (!ownership) {
      items = items.filter((item) => item.action === MessageAction.Quote);
    }
    setMenuItems(items);
    // eslint-disable-next-line
  }, [ownership]);

  // Set message data on mount.
  useEffect(() => {
    // Set message ownership
    setOwnership(
      props.message.create_change_info.created_by.account?.id === account?.id
    );

    // Set message user data
    setUser(props.message.creator_id);

    // Set message read by users
    const usersRead: Partial<User>[] = [];
    for (let messageReadUser of props.message.read) {
      if (messageReadUser && messageReadUser.account?.id !== account?.id) {
        const user = props.users.find(
          (user) => user.account?.id === messageReadUser.account?.id
        );
        user && usersRead.push(user);
      }
    }
    setUsersRead(usersRead);
  }, [account, props.message, props.users]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handle message action.
   */
  const onMessageAction = useCallback(
    (action: MessageAction) => {
      buttonRef.current?.click();
      action === MessageAction.Delete && setDialogDelete(true);
      action === MessageAction.Edit && props.onMessageEdit();
      action === MessageAction.Quote && props.onMessageQuote();
    },
    [props]
  );

  /**
   * Handler to delete message by id.
   */
  const onMessageDelete = useCallback(
    () => {
      selectedRoom &&
        socket.emit(
          'deleteMessage',
          {
            accessToken,
            message_id: props.message.id,
            room_id: selectedRoom.id,
          },
          (response: SocketEventSubscriptionResponse) => {
            if (response.status === ResultState.Success) {
              setDialogDelete(false);
            }
          }
        );
    },
    // eslint-disable-next-line
    [props.message, selectedRoom]
  );

  return (
    <>
      {props.message.info &&
        props.message.info.type !== InfoMessageType.ToolChatMultiRow &&
        props.message.info.type !== InfoMessageType.ToolChatGroup && (
          <InfoMessageLine info={props.message.info} content={messageContent} />
        )}
      {(!props.message.info ||
        props.message.info.type === InfoMessageType.ToolChatMultiRow ||
        props.message.info.type === InfoMessageType.ToolChatGroup) && (
        <Box
          sx={{
            justifyContent: ownership ? 'flex-end' : undefined,
            '& a': {
              color: 'inherit',
            },
            '& a:visited': {
              color: 'inherit',
            },
          }}
          className={clsx(
            styles['message'],
            props.showUserData
              ? styles['message-padding-with-user-data']
              : styles['message-padding-without-user-data']
          )}
        >
          {console.log(user)}
          <Box
            sx={{ flexDirection: ownership ? 'row-reverse' : undefined }}
            className={styles['message-container']}
          >
            <Box className={styles['message-container-user']}>
              {(showUserForRead || props.showUserData) && user && (
                <>
                  {user.profile?.avatar?.url ? (
                    <img
                      alt="Avatar"
                      className={styles['message-container-user-image']}
                      src={user.profile?.avatar?.url}
                    />
                  ) : (
                    <ImageFallback
                      sizeClassName={styles['message-container-user-image']}
                      type={ImageFallbackType.Profile}
                    />
                  )}
                </>
              )}
            </Box>
            <Box
              sx={{ alignItems: ownership ? 'flex-end' : undefined }}
              className={styles['message-container-content']}
            >
              <Box
                color="text.secondary"
                className={styles['message-container-content-info']}
              >
                {ownership && usersRead.length > 0 && (
                  <div
                    className={styles['message-container-content-info-read']}
                  >
                    {usersRead.map((user, index) => {
                      if (user.read_message_id === props.message.id) {
                        return (
                          <Tooltip
                            title={fullNameGet(
                              user.profile?.personal_data.first_name,
                              user.profile?.personal_data.last_name
                            )}
                            placement="top"
                          >
                            <div
                              key={`${user.account?.id}${index}`}
                              className={
                                styles[
                                  'message-container-content-info-read-user'
                                ]
                              }
                            >
                              {user.profile?.avatar?.url ? (
                                <img
                                  alt="Avatar"
                                  className={
                                    styles[
                                      'message-container-content-info-read-user-image'
                                    ]
                                  }
                                  src={user.profile.avatar.url}
                                />
                              ) : (
                                <ImageFallback
                                  iconSize="0.5em"
                                  sizeClassName={
                                    styles[
                                      'message-container-content-info-read-user-image'
                                    ]
                                  }
                                  type={ImageFallbackType.Profile}
                                />
                              )}
                            </div>
                          </Tooltip>
                        );
                      }
                    })}
                  </div>
                )}
                {(showUserForRead || props.showUserData) && user && (
                  <>
                    <div className="ml-2">
                      {ownership
                        ? t('app.you')
                        : fullNameGet(
                            user.profile?.personal_data.first_name,
                            user.profile?.personal_data.last_name,
                            user.profile?.nickname
                          )}
                    </div>
                    <div>
                      ,
                      {dayjs(
                        props.message.create_change_info.created_at
                      ).format('LT')}
                    </div>
                  </>
                )}
              </Box>

              <Box className={styles['message-container-content-massage']}>
                <Box
                  className={
                    ownership ? 'flex flex-row-reverse' : 'flex flex-row'
                  }
                >
                  {props.message.quote ? (
                    <Box
                      sx={{
                        flexDirection: ownership ? 'row-reverse' : undefined,
                      }}
                      className={styles['message-container-content-text']}
                    >
                      <Box
                        className={
                          styles['message-container-content-text-html']
                        }
                        color={ownership ? 'white' : 'text.primary'}
                        sx={{
                          backgroundColor: ownership
                            ? 'bg.darkgray'
                            : 'bg.card',
                          padding: lgDown ? '0.5rem' : '1rem',
                          '& a': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        <MessageQuote border message={props.message.quote} />

                        {/* Sanitise raw HTML to prevent cross-site scripting (XSS) attack*/}
                        <Box
                          sx={{ ...sxContentTextHtmlSize }}
                          dangerouslySetInnerHTML={{
                            __html: `${messageContent} ${
                              edited
                                ? '<span style="font-size: 12px;">(' +
                                  t('newscenter.room.message.edited') +
                                  ')</span>'
                                : ''
                            }`,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{ opacity: menuOpen ? 1 : '0' }}
                        className={
                          styles['message-container-content-text-menu']
                        }
                      >
                        <Menu
                          buttonIcon={['fas', 'chevron-down']}
                          buttonPadding="0.25rem"
                          items={menuItems}
                          onClick={onMessageAction}
                          onOpen={(open) => setMenuOpen(open)}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        flexDirection: ownership ? 'row-reverse' : undefined,
                      }}
                      className={styles['message-container-content-text']}
                    >
                      <Box
                        className={
                          styles['message-container-content-text-html']
                        }
                        color={ownership ? 'white' : 'text.primary'}
                        sx={{
                          ...sxContentTextHtmlColor,
                          '& a': {
                            textDecoration: 'underline',
                          },
                        }}
                      >
                        {/* Sanitise raw HTML to prevent cross-site scripting (XSS) attack*/}
                        <Box
                          sx={{ ...sxContentTextHtmlSize }}
                          dangerouslySetInnerHTML={{
                            __html: `${messageContent} ${
                              edited
                                ? '<span style="font-size: 12px;">(' +
                                  t('newscenter.room.message.edited') +
                                  ')</span>'
                                : ''
                            }`,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{ opacity: menuOpen ? 1 : '0' }}
                        className={
                          styles['message-container-content-text-menu']
                        }
                      >
                        <Menu
                          buttonIcon={['fas', 'chevron-down']}
                          buttonPadding="0.25rem"
                          items={menuItems}
                          onClick={onMessageAction}
                          onOpen={(open) => setMenuOpen(open)}
                        />
                      </Box>
                    </Box>
                  )}
                  {(props.message.send_email || props.message.send_sms) && (
                    <Box
                      className={styles['message-container-content-text-alarm']}
                    >
                      <Icon
                        classes={styles['']}
                        icon={['fal', 'bell-exclamation']}
                        sx={{ color: 'text.secondary' }}
                      />
                    </Box>
                  )}
                </Box>
                {props.message.images && props.message.images.length > 0 && (
                  <Box
                    className={clsx(
                      styles['message-container-content-data'],
                      ownership ? 'justify-end' : 'justify-start'
                    )}
                  >
                    {props.message.images.map((image, index) => (
                      <a
                        key={`${image.id}${index}`}
                        href={image.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          alt="Preview"
                          src={image.url}
                          className={clsx(
                            styles['message-container-content-data-image'],
                            ownership
                              ? styles['message-container-content-data-owner']
                              : styles['message-container-content-data-partner']
                          )}
                        />
                      </a>
                    ))}
                  </Box>
                )}
              </Box>

              {props.message.files && props.message.files.length > 0 && (
                <div className={styles['message-container-content-data']}>
                  {props.message.files.map((file, index) => (
                    <a
                      key={`${file.name}${index}`}
                      download
                      href={file.url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Box
                        sx={{ borderColor: 'border.app' }}
                        className={clsx(
                          styles['message-container-content-data-file'],
                          ownership
                            ? styles['message-container-content-data-owner']
                            : styles['message-container-content-data-partner']
                        )}
                      >
                        <Icon
                          classes={
                            styles['message-container-content-data-file-icon']
                          }
                          icon={iconPropByFileTypeGet(file.type)}
                          sx={{ color: 'text.secondary' }}
                        />
                        <Box
                          color="text.secondary"
                          className={
                            styles['message-container-content-data-file-name']
                          }
                        >
                          {file.name}
                        </Box>
                      </Box>
                    </a>
                  ))}
                </div>
              )}
            </Box>
          </Box>
          <Dialog
            open={dialogDelete}
            subtitle={t('newscenter.room.message.delete.subtitle')}
            title={t('newscenter.room.message.delete.title')}
            width={smUp ? '480px' : undefined}
            onClose={() => setDialogDelete(false)}
          >
            <Box className={styles['message-delete-text']}>
              {t('newscenter.room.message.delete.text')}
            </Box>
            <Box className={styles['message-delete-buttons']}>
              <TextButton
                classes={styles['message-delete-buttons-cancel']}
                preset="secondary"
                onClick={() => setDialogDelete(false)}
              >
                {t('app.cancel')}
              </TextButton>
              <TextButton preset="primary" onClick={onMessageDelete}>
                {t('app.actions.delete')}
              </TextButton>
            </Box>
          </Dialog>
        </Box>
      )}
    </>
  );
};
