import { ReactNode, useEffect, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import {
  Box,
  InputAdornment,
  InputBaseComponentProps,
  OutlinedInput,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import { IconButton } from '../IconButton/IconButton';
import { ResultMessage } from '../ResultMessage/ResultMessage';

// Models
import { ResultState, Theme as ETheme } from '../../models/shared.types';

// Stores
import { SharedState, useSharedStore } from '../../stores/use-shared.store';

type InputProps = {
  autoFocus?: boolean;
  caption?: string | TFunctionResult;
  className?: string;
  defaultValue?: string | TFunctionResult | number;
  disabled?: boolean;
  endAdornment?: [IconPrefix, IconName];
  inputClassName?: string;
  inputSize?: 'small' | 'medium' | undefined;
  label?: string | TFunctionResult;
  message?: string | TFunctionResult;
  multiline?: number;
  option?: string;
  optional?: boolean;
  padding?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  startAdornmentElement?: ReactNode;
  state?: ResultState;
  sublabel?: string | TFunctionResult;
  type?: string;
  value?: string | TFunctionResult;
  widthClassName?: string;
  maxWidth?: string;
  inputProps?: InputBaseComponentProps;
  preset?: 'White';
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onEndAdornmentClick?: () => void;
  onOptionClick?: () => void;
};

export const InputFlat = (props: InputProps) => {
  // Component state
  const [sxColors, setSxColors] = useState<SxProps<Theme> | undefined>(
    undefined
  );

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  // Set color by result state
  useEffect(() => {
    let color: string | undefined = undefined;
    switch (props.state) {
      case ResultState.Error:
        color = 'error.main';
        break;
      case ResultState.Info:
        color = 'info.main';
        break;
      case ResultState.Success:
        color = 'success.main';
        break;
      case ResultState.Warning:
        color = 'warning.main';
        break;
      default:
        break;
    }

    // Set input colors by result state
    if (props.preset === 'White') {
      setSxColors({
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'background.paper',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'text.secondary',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'text.secondary',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: 'text.secondary',
          },
      });
    } else {
      setSxColors({
        '& .MuiOutlinedInput-root': {
          backgroundColor: theme === ETheme.Dark ? 'bg.card' : 'inherit',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: color
            ? color
            : theme === ETheme.Dark
            ? 'transparent'
            : 'border.app',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: color
            ? color
            : theme === ETheme.Dark
            ? 'transparent'
            : 'border.app',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: color ? color : 'primary.main',
          },
      });
    }
  }, [props, theme]);

  return (
    <Box
      sx={{
        ...sxColors,
        '& .MuiOutlinedInput-input': {
          padding: props.padding ?? '0.75rem',
        },
        maxWidth: props.maxWidth,
      }}
      className={clsx(
        props.className && props.className,
        props.widthClassName ? props.widthClassName : 'w-full'
      )}
    >
      {props.label && (
        <Box className="flex flex-row justify-between items-center">
          <FormFieldLabel
            label={props.label}
            optional={props.optional}
            sublabel={props.sublabel}
          />
          {props.option && (
            <Typography
              className="text-xs cursor-pointer"
              sx={{ color: 'primary.main', textDecoration: 'underline' }}
              onClick={props.onOptionClick}
            >
              {props.option}
            </Typography>
          )}
        </Box>
      )}
      <OutlinedInput
        autoFocus={props.autoFocus}
        value={props.value}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        endAdornment={
          props.endAdornment ? (
            <InputAdornment className="mr-2" position="end">
              <IconButton
                icon={props.endAdornment}
                onClick={props.onEndAdornmentClick && props.onEndAdornmentClick}
              />
            </InputAdornment>
          ) : undefined
        }
        minRows={props.multiline && props.multiline}
        multiline={props.multiline ? true : false}
        placeholder={props.placeholder}
        size={props.inputSize ? props.inputSize : 'small'}
        startAdornment={
          props.startAdornmentElement && props.startAdornmentElement
        }
        sx={{ padding: 0 }}
        type={props.type}
        inputProps={props.inputProps}
        onChange={(event) =>
          props.onChange && props.onChange(event.target.value)
        }
        className={
          props.inputClassName ? props.inputClassName : 'text-xs w-full'
        }
        {...props.register}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
      />
      {props.caption && (
        <ResultMessage message={props.caption} state={ResultState.Standard} />
      )}
      {props.message && (
        <ResultMessage message={props.message} state={ResultState.Error} />
      )}
    </Box>
  );
};
