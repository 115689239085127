import { memo, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useToolsHttp } from '../../hooks/use-tools-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { ToolsState, useToolsStore } from '../../stores/use-tools.store';

// Components
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';

// Models
import {
  MultiElementColumnStarParams,
  ToolValuesElement,
} from '../../models/tools.types';

// Styles
import styles from './ToolMultiElementTable.module.scss';

// Utils
import { multiElementChildValueGet } from '../../utils/tools.utils';

const ColumnStar = (props: MultiElementColumnStarParams) => {
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const { toolVoteSubmitMultiRow } = useToolsHttp();

  // Component state
  const [toolValue, setToolValue] = useState<ToolValuesElement | undefined>(
    undefined
  );
  const [valuesStar, setValuesStar] = useState<number[]>([]);
  const [selectedStar, setSelectedStar] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showThanks, setShowThanks] = useState<boolean>(false);
  const [showChangeButton, setShowChangeButton] = useState<boolean>(false);

  // Tools store state
  const [hasFullAccess] = useToolsStore((state: ToolsState) => [
    state.hasFullAccess,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  const submitVoteMultiRowMutation = useMutation(
    (data: any) => toolVoteSubmitMultiRow(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (toolValue) {
            let updatedToolValue: ToolValuesElement = { ...toolValue };
            updatedToolValue.value = data.vote;
            updatedToolValue.change = data.change;
            updatedToolValue.submit = data.submit;
            updatedToolValue.result = data.result;
            setToolValue(updatedToolValue);
            setSelectedStar(data.vote);
          } else {
            let updatedToolValue: ToolValuesElement = {
              id: data.id,
              value: data.vote,
              change: data.change,
              submit: data.submit,
              result: data.result,
            };
            setToolValue(updatedToolValue);
            setSelectedStar(data.vote);
          }
        }
        if (error && error.response) {
          console.error(
            'Error submit vote value:',
            handleError(error.response.status)
          );
        }
      },
    }
  );

  // ######### //
  // EFFECTS //
  // ######### //

  // Set number of stars by vote on mount
  useEffect(() => {
    if (props.value) {
      if (props.value) {
        setToolValue(props.value);
        props.value.value && setSelectedStar(Number(props.value.value));
      }
    }
  }, [props.value]);

  // Set show components
  useEffect(() => {
    if (props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowResult(true);
        setShowForm(false);
      } else {
        setShowResult(false);
        setShowForm(true);
      }
    }

    if (!props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowThanks(true);
        setShowForm(false);
      } else {
        setShowThanks(false);
        setShowForm(true);
      }
    }

    props.vote_element?.settings?.vote?.change
      ? setShowChangeButton(true)
      : setShowChangeButton(false);
  }, [props.vote_element?.settings?.vote, toolValue]);

  useEffect(() => {
    if (props.vote_element?.vote) {
      const starArray = [];
      for (let i = 0; i < props.vote_element.vote; i++) {
        starArray.push(1);
      }
      setValuesStar(starArray);
    }
  }, [props.vote_element?.vote]);

  // ######### //
  // CALLBACK //
  // ######### //

  const onChange = useCallback(() => {
    setShowForm(true);
    setShowChangeButton(false);
    setShowThanks(false);
    setShowResult(false);
  }, []);

  // Save selected number of stars and change color
  const onStarClick = useCallback(
    (index: number) => {
      !hasFullAccess &&
        submitVoteMultiRowMutation.mutate({
          id: props.toolId,
          body: {
            id: props.vote_element?.id,
            value: index + 1,
          },
          element_id: props.element?.id,
          section_id: props.sectionId,
          row_id: props.rowId,
        });
      // eslint-disable-next-line
    },
    [hasFullAccess, props, submitVoteMultiRowMutation]
  );

  return (
    <>
      {hasFullAccess ? (
        <>
          {selectedStar}
          {toolValue ? ' (' + toolValue.counter + ')' : ' (0)'}
        </>
      ) : (
        <>
          <Box className={styles['column-star']}>
            {!submitVoteMultiRowMutation.isLoading && (
              <>
                {showResult && (
                  <div className={styles['column-star-add']}>
                    <div className={styles['column-star-add']}>
                      {valuesStar.map((value, index) => (
                        <IconButton
                          key={uuidv4()}
                          sxIcon={{
                            color:
                              index < selectedStar
                                ? '#FFDA3E'
                                : 'text.secondary',
                          }}
                          icon={
                            index < selectedStar
                              ? ['fas', 'star']
                              : ['fal', 'star']
                          }
                          padding="0.25rem"
                        />
                      ))}
                    </div>
                    {showResult && showChangeButton && (
                      <div>
                        <TextButton
                          preset="popover"
                          textSize="text-sm"
                          onClick={onChange}
                        >
                          {t('tools.vote.change')}
                        </TextButton>
                      </div>
                    )}
                  </div>
                )}
                {showThanks && (
                  <div className={styles['column-star-add']}>
                    <div className="mr-4">{t('tools.vote.thanks')}</div>
                    {showThanks && showChangeButton && (
                      <div>
                        <TextButton
                          preset="popover"
                          textSize="text-sm"
                          onClick={onChange}
                        >
                          {t('tools.vote.change')}
                        </TextButton>
                      </div>
                    )}
                  </div>
                )}
                {showForm && (
                  <div className={styles['column-star-add']}>
                    {!hasFullAccess && selectedStar > 0 && (
                      <IconButton
                        classes="text-sm mr-1"
                        icon={['fas', 'times']}
                        iconSize="inherit"
                        padding="0.25rem"
                        sxIcon={{
                          color: 'error.main',
                          bgcolor: 'error.light',
                          // '&:hover': { color: 'error.main', bgcolor: 'error.light' },
                        }}
                        onClick={() => onStarClick(-1)}
                      />
                    )}
                    {valuesStar.map((value, index) => (
                      <IconButton
                        key={uuidv4()}
                        sxIcon={{
                          color:
                            index < selectedStar ? '#FFDA3E' : 'text.secondary',
                        }}
                        icon={
                          index < selectedStar
                            ? ['fas', 'star']
                            : ['fal', 'star']
                        }
                        padding="0.25rem"
                        onClick={() => onStarClick(index)}
                      />
                    ))}
                  </div>
                )}
              </>
            )}

            {submitVoteMultiRowMutation.isLoading && <Loader />}
          </Box>
        </>
      )}
    </>
  );
};

export default memo(ColumnStar);
