import { ReactNode } from 'react';
import { Box, Grid } from '@mui/material';
import styles from './Layout.module.scss';
import { Header } from '../Header/Header';
import useResponsive from '../../hooks/use-responsive ';
import { PrintView } from '../PrintView/PrintView';
import { MobileView } from '../MobileView/MobileView';
import { DesktopView } from '../DesktopView/DesktopView';
import { DashboardViewType } from '../../../modules/dashboard/models/dashboard.types';
import { ContactCommunity } from '../../../modules/contacts/models/contacts.types';

export type LayoutProps = {
  leftComponent: ReactNode;
  rightComponent1: ReactNode;
  rightComponent2: ReactNode;
  rightComponent3: ReactNode;
  rightComponentName1: string;
  rightComponentName2: string;
  selectedCommunity: ContactCommunity;
  isNewView: boolean;
  viewType: DashboardViewType;
  toggleNewView: (checked: boolean) => void;
};

export const Layout = ({
  leftComponent,
  rightComponent1,
  rightComponent2,
  rightComponent3,
  rightComponentName1,
  rightComponentName2,
  selectedCommunity,
  isNewView,
  viewType,
  toggleNewView,
}: LayoutProps) => {
  const currentUrl = window.location.href;
  const { isMobile, isDesktop } = useResponsive();

  return (
    <Box className={styles['container-box']}>
      {currentUrl.includes('/printview') ? (
        <PrintView />
      ) : (
        <>
          <Header isNewView={isNewView} toggleNewView={toggleNewView} />
          <Box className={styles['protected-container']}>
            <Grid container className={styles['page-layout-grid']}>
              {isMobile && (
                <MobileView
                  leftComponent={leftComponent}
                  rightComponent1={rightComponent1}
                  rightComponent2={rightComponent2}
                  rightComponent3={rightComponent3}
                />
              )}
              {isDesktop && (
                <DesktopView
                  leftComponent={leftComponent}
                  rightComponent1={rightComponent1}
                  rightComponent2={rightComponent2}
                  rightComponent3={rightComponent3}
                  rightComponentName1={rightComponentName1}
                  rightComponentName2={rightComponentName2}
                  viewType={viewType}
                  selectedCommunity={selectedCommunity}
                />
              )}
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};
