import create from 'zustand';

// Models
import { CrudState, Option } from '../../../shared/models/shared.types';
import { Tool } from '../../tools/models/tools.types';
import {
  CommunityFilter,
  Template,
  TemplateElement,
  TemplateElementCreateEditState,
  TemplatePreview,
  TemplatePrintPostRequest,
  TemplateSection,
  TemplateSettings,
  TemplateSettingsState,
} from '../models/templates.types';

export interface TemplatesState {
  communityFilter: CommunityFilter;
  dialogSettings: boolean;
  dialogShare: boolean;
  elementCreateEdit: TemplateElement | null;
  elementCreateEditState: TemplateElementCreateEditState;
  elementCreateEditSteps: TemplateElementCreateEditState[];
  elementCreateEditSummaryState: boolean;
  elementCreateEditValid: boolean;
  elementCreateEditValidValues: boolean[];
  print: TemplatePrintPostRequest | undefined;
  selectedElement: TemplateElement | null;
  selectedSection: TemplateSection | null;
  selectedTemplate: Template | null;
  selectedPermissionView: Option[] | null;
  selectedPermissionEdit: Option[] | null;
  settingsCrudState: CrudState;
  settingsState: TemplateSettingsState;
  settingsSummaryState: boolean;
  storeFilterData: ToolsStoreFilterData;
  elementTempo: TemplateElement | null;
  templates: TemplatePreview[];
  templateSettings: TemplateSettings;
  toolPreview: Tool | undefined;
  dialogPrintSettings: boolean;
  resetSettingsData: () => void;
  setCommunityFilter: (communityFilter: CommunityFilter) => void;
  setDialogSettings: (dialogSettings: boolean) => void;
  setDialogShare: (dialogShare: boolean) => void;
  setElementCreateEdit: (elementCreateEdit: TemplateElement | null) => void;
  resetElementCreateEditData: () => void;
  setElementCreateEditState: (
    elementEditState: TemplateElementCreateEditState
  ) => void;
  setElementCreateEditSteps: (
    elementCreateEditSteps: TemplateElementCreateEditState[]
  ) => void;
  setElementCreateEditSummaryState: (
    elementCreateEditSummaryState: boolean
  ) => void;
  setElementCreateEditValid: (elementCreateEditValid: boolean) => void;
  setElementCreateEditValidValues: (
    elementCreateEditValidValues: boolean[]
  ) => void;
  setPrint: (print: TemplatePrintPostRequest | undefined) => void;
  setSelectedElement: (selectedElement: TemplateElement | null) => void;
  setSelectedSection: (selectedSection: TemplateSection | null) => void;
  setSelectedTemplate: (selectedTemplate: Template | null) => void;
  setSelectedPermissionView: (selectedPermissionView: Option[] | null) => void;
  setSelectedPermissionEdit: (selectedPermissionEdit: Option[] | null) => void;
  setSettingsCrudState: (settingsCrudState: CrudState) => void;
  setSettingsState: (settingsState: TemplateSettingsState) => void;
  setSettingsSummaryState: (settingsSummaryState: boolean) => void;
  setStoreFilterData: (storeFilterData: ToolsStoreFilterData) => void;
  setElementTempo: (elementTempo: TemplateElement | null) => void;
  setTemplates: (templates: TemplatePreview[]) => void;
  setTemplateSettings: (templateSettings: TemplateSettings) => void;
  setToolPreview: (toolPreview: Tool | undefined) => void;
  setDialogPrintSettings: (dialogPrintSettings: boolean) => void;
}

export const elementCreateEditInitialData = {
  elementCreateEdit: null,
  elementCreateEditState: TemplateElementCreateEditState.Type,
  elementCreateEditSteps: [
    TemplateElementCreateEditState.Name,
    TemplateElementCreateEditState.Type,
    TemplateElementCreateEditState.Settings,
  ],
  elementCreateEditSummaryState: false,
  elementCreateEditValid: false,
  elementCreateEditValidValues: [],
};

export const templateInitialData = {
  dialogSettings: false,
  dialogPrintSettings: false,
  settingsCrudState: CrudState.Create,
  settingsState: TemplateSettingsState.Name,
  settingsSummaryState: false,
};

export const templateSettingsInitialData = {
  dialogSettings: false,
  dialogPrintSettings: false,
  print: undefined,
  templateSettings: {
    description: undefined,
    extra: {
      restricted: false,
      unique: false,
    },
    icon: undefined,
    name: undefined,
    purpose: undefined,
  },
  settingsCrudState: CrudState.Create,
  settingsState: TemplateSettingsState.Name,
  settingsSummaryState: false,
};

export interface ToolsStoreFilterData {
  categories: {
    community: boolean;
    my: boolean;
    pengueen: boolean;
  };
  levels: {
    advanced: boolean;
    beginner: boolean;
    expert: boolean;
  };
  templates: {
    all: boolean;
    newest: boolean;
    popular: boolean;
  };
}

const initialToolsStoreFilterData = (): ToolsStoreFilterData => {
  return {
    categories: {
      community: true,
      my: true,
      pengueen: true,
    },
    levels: {
      advanced: false,
      beginner: false,
      expert: false,
    },
    templates: {
      all: false,
      newest: false,
      popular: false,
    },
  };
};

export const useTemplatesStore = create<TemplatesState>((set) => ({
  ...elementCreateEditInitialData,
  communityFilter: {
    myCommunities: [],
    adminCommunities: [],
  },
  dialogShare: false,
  selectedElement: null,
  selectedSection: null,
  selectedTemplate: null,
  selectedPermissionView: null,
  selectedPermissionEdit: null,
  storeFilterData: initialToolsStoreFilterData(),
  elementTempo: null,
  templates: [],
  ...templateInitialData,
  ...templateSettingsInitialData,
  toolPreview: undefined,
  resetElementCreateEditData: () =>
    set((state: TemplatesState) => ({
      ...state,
      ...elementCreateEditInitialData,
    })),
  resetSettingsData: () =>
    set((state: TemplatesState) => ({
      ...state,
      ...templateSettingsInitialData,
    })),
  setCommunityFilter: (communityFilter: CommunityFilter) =>
    set({ communityFilter }),
  setDialogSettings: (dialogSettings: boolean) => set({ dialogSettings }),
  setDialogShare: (dialogShare: boolean) => set({ dialogShare }),
  setElementCreateEdit: (elementCreateEdit: TemplateElement | null) =>
    set({ elementCreateEdit }),
  setElementCreateEditState: (
    elementCreateEditState: TemplateElementCreateEditState
  ) => set({ elementCreateEditState }),
  setElementCreateEditSteps: (
    elementCreateEditSteps: TemplateElementCreateEditState[]
  ) => set({ elementCreateEditSteps }),
  setElementCreateEditSummaryState: (elementCreateEditSummaryState: boolean) =>
    set({ elementCreateEditSummaryState }),
  setElementCreateEditValid: (elementCreateEditValid: boolean) =>
    set({ elementCreateEditValid }),
  setElementCreateEditValidValues: (elementCreateEditValidValues: boolean[]) =>
    set({ elementCreateEditValidValues }),
  setPrint: (print: TemplatePrintPostRequest | undefined) => set({ print }),
  setSelectedElement: (selectedElement: TemplateElement | null) =>
    set({ selectedElement }),
  setSelectedSection: (selectedSection: TemplateSection | null) =>
    set({ selectedSection }),
  setSelectedTemplate: (selectedTemplate: Template | null) =>
    set({ selectedTemplate }),
  setSelectedPermissionView: (selectedPermissionView: Option[] | null) =>
    set({ selectedPermissionView }),
  setSelectedPermissionEdit: (selectedPermissionEdit: Option[] | null) =>
    set({ selectedPermissionEdit }),
  setSettingsCrudState: (settingsCrudState: CrudState) =>
    set({ settingsCrudState }),
  setSettingsState: (settingsState: TemplateSettingsState) =>
    set({ settingsState }),
  setSettingsSummaryState: (settingsSummaryState: boolean) =>
    set({ settingsSummaryState }),
  setStoreFilterData: (storeFilterData: ToolsStoreFilterData) =>
    set({ storeFilterData }),
  setElementTempo: (elementTempo: TemplateElement | null) =>
    set({ elementTempo }),
  setTemplates: (templates: TemplatePreview[]) => set({ templates }),
  setTemplateSettings: (templateSettings: TemplateSettings) =>
    set({ templateSettings }),
  setToolPreview: (toolPreview: Tool | undefined) => set({ toolPreview }),
  setDialogPrintSettings: (dialogPrintSettings: boolean) =>
    set({ dialogPrintSettings }),
}));
