// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputFlat_input-end-adornment__qOj52 {\n\n    margin-right: 0.5rem\n}\n\n.InputFlat_input-outlined__E0daG {\n\n    width: 100%;\n\n    font-size: 0.875rem;\n\n    line-height: 1.25rem\n}\n\n.InputFlat_input__wdUjm .InputFlat_MuiInputBase-multiline__3OlQt {\n\n    padding: 0px\n}\n", "",{"version":3,"sources":["<no source>"],"names":[],"mappings":"AAAA;;IAAA;CAAA;;AAAA;;IAAA,YAAA;;IAAA,oBAAA;;IAAA;CAAA;;AAAA;;IAAA;CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-end-adornment": "InputFlat_input-end-adornment__qOj52",
	"input-outlined": "InputFlat_input-outlined__E0daG",
	"input": "InputFlat_input__wdUjm",
	"MuiInputBase-multiline": "InputFlat_MuiInputBase-multiline__3OlQt"
};
export default ___CSS_LOADER_EXPORT___;
