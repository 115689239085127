// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Changes_change-item-text__R218Q {\n  width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical\n}\n", "",{"version":3,"sources":["<no source>","webpack://./src/new_modules/dashboard/Changes/Changes.module.scss"],"names":[],"mappings":"AAAA;EAAA,YAAA;ECIE,gBAAgB;EAChB,uBAAuB;EACvB,oBAAoB;EACpB,qBAAqB;EACrB;CDRF","sourcesContent":[null,"@import '../../../styles/breakpoints.scss';\r\n\r\n.change-item-text {\r\n  @apply w-full;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 2;\r\n  -webkit-box-orient: vertical;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"change-item-text": "Changes_change-item-text__R218Q"
};
export default ___CSS_LOADER_EXPORT___;
