export const toolAuraMock = {
  id: '22b8511b-11f6-472a-8743-337f33e242d7',
  kanbans: [
    {
      mfeid: 'c1d0fcf3-5cb7-4113-8405-439050f3135d',
      kanban: {
        lanes: [],
      },
    },
    {
      mfeid: '7ad7ee47-c281-48da-89ca-74bce89997fd',
      kanban: {
        lanes: [],
      },
    },
    {
      mfeid: '35608d47-8503-495a-92a7-fcf6fd7a386c',
      kanban: {
        lanes: [
          {
            id: '4fd563bd-ab85-4378-af9f-96aa25593e91',
            title: 'Backlog',
            color: '#667784',
            cards: [
              {
                description: '<p>Sales Funnel Sprachmittler (Q3)</p>',
                title: 'TI-4711',
                id: '223fa7ab-5e2c-4e1a-b36e-e821505ecd6e',
                status: {
                  color: '#FFD973',
                  name: 'hoch',
                  placeholder: 'hoch',
                  value: '713b926c-1129-41da-bd1f-6e949a70419c',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                description:
                  '<p>Artikel: Schritt für Schritt wie gehe ich vor</p>',
                title: 'TI-0815',
                id: 'ee51775b-76a3-47fc-851d-0b1cd36f6ab2',
                status: {
                  color: '#FFD973',
                  name: 'hoch',
                  placeholder: 'hoch',
                  value: '713b926c-1129-41da-bd1f-6e949a70419c',
                },
              },
            ],
          },
          {
            id: '08d8c870-19ca-4edb-a9f4-eff13225fa33',
            title: 'Fehler',
            color: '#F06A6A',
            cards: [
              {
                user: {
                  name: 'Hyejin Kim',
                  avatar:
                    'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
                },
                title: 'TI-1234',
                description:
                  '<p>Navigation im Nachrichtencenter: "ältere laden" ist weg</p>',
                id: '5409aca2-fa81-4a90-b5d6-8df401d891b3',
                status: {
                  color: '#A8EB94',
                  name: 'normal',
                  placeholder: 'normal',
                  value: 'faa3017c-4474-4268-80fe-a5dc63534d30',
                },
              },
              {
                user: {
                  name: 'Hyejin Kim',
                  avatar:
                    'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
                },
                description:
                  '<p>wenn ich eine Gruppe aus dem Netzwerk als Favorit markiere, wird diese nicht auf dem Dashboard angezeigt</p>',
                title: 'TI-2578',
                id: 'b5578ef2-a90d-4003-882d-9913dcebf88c',
                status: {
                  color: '#A8EB94',
                  name: 'normal',
                  placeholder: 'normal',
                  value: 'faa3017c-4474-4268-80fe-a5dc63534d30',
                },
              },
            ],
          },
          {
            id: '018ed124-8e70-4873-97e8-372dae5dc632',

            title: 'Neu',
            color: '#FFD973',
            cards: [
              {
                user: {
                  name: 'Daniel Dziamski',
                  avatar:
                    'https://files.pengueen.de/api/download/97f88503-f8d6-4b9b-ae3c-9c527b79276a.jpg',
                },
                description:
                  '<p>Super-Element: Liste der Elemente aus Hilfsmittelvorlage entsprechend Anordnung der Elemente sortieren (Freiburg)</p>',
                title: 'TI-2579',
                id: 'e10c17af-39c7-47a9-ae53-ea60b176a11b',
                status: {
                  color: '#80E0FD',
                  name: 'niedrig',
                  placeholder: 'niedrig',
                  value: 'd7bd71c8-5a30-45f6-8c9c-e0c87490831c',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                description: '<p>Noch ein zu bearbeitender Punkt</p>',
                title: 'TI-0816',
                id: '225705d4-3261-4ea3-a319-1f989a710de3',
                status: {
                  color: '#A8EB94',
                  name: 'normal',
                  placeholder: 'normal',
                  value: 'faa3017c-4474-4268-80fe-a5dc63534d30',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                title: 'TI-0818',
                description:
                  '<p>Noch ein zu bearbeitender Punkt .. es fehlt eine Kopierfunktion 😉</p>',
                id: 'f560e80f-49c5-4207-908d-b5ec2912896b',
                status: {
                  color: '#80E0FD',
                  name: 'niedrig',
                  placeholder: 'niedrig',
                  value: 'd7bd71c8-5a30-45f6-8c9c-e0c87490831c',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                description: '<p>Noch ein zu bearbeitender Punkt</p>',
                title: 'TI-0817',
                id: '0df8d93d-9fba-44ee-a08e-afdff73f1dc7',
                status: {
                  color: '#80E0FD',
                  name: 'niedrig',
                  placeholder: 'niedrig',
                  value: 'd7bd71c8-5a30-45f6-8c9c-e0c87490831c',
                },
              },
            ],
          },
          {
            id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
            title: 'in Bearbeitung',
            color: '#4998F9',
            cards: [
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                title: 'TI-5711',
                description: '<p>Marktplatz Wünsche an Sascha</p>',
                id: '0a07f49a-79fa-4fbf-9c06-eff7c3815f05',
                status: {
                  color: '#A8EB94',
                  name: 'normal',
                  placeholder: 'normal',
                  value: 'faa3017c-4474-4268-80fe-a5dc63534d30',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                title: 'TI-8711',
                description:
                  '<p>2 Artikel: Personen zu Pengueen einladen (verschieden Wege)</p>',
                id: '31c9ea15-6697-4363-a0ac-7474d7f91081',
                status: {
                  color: '#A8EB94',
                  name: 'normal',
                  placeholder: 'normal',
                  value: 'faa3017c-4474-4268-80fe-a5dc63534d30',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                description:
                  '<p>Arbeitspaket Pengueen goes SAAS (Separate Word)</p>',
                title: 'TI-0819',
                id: '624e3d67-5a57-4483-bd3f-f390357a376e',
                status: {
                  color: '#80E0FD',
                  name: 'niedrig',
                  placeholder: 'niedrig',
                  value: 'd7bd71c8-5a30-45f6-8c9c-e0c87490831c',
                },
              },
              {
                user: {
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                description:
                  '<p>2 Artikel: Person zu Pengueen einladen (verschiedene Wege)</p>',
                title: 'TI-6711',
                id: 'c99e2d2c-526a-41e5-b3c0-a946c40e17fa',
                status: {
                  color: '#A8EB94',
                  name: 'normal',
                  placeholder: 'normal',
                  value: 'faa3017c-4474-4268-80fe-a5dc63534d30',
                },
              },
            ],
          },
          {
            id: 'f3e72589-e4d4-44b3-a0f6-a0fa07264bf0',
            title: 'zur Abnahme',
            color: '#FFD973',
            cards: [],
          },
          {
            id: '99468606-1f0f-4a1f-abcf-020cae358012',
            title: 'abgeschlossen',
            color: '#50D09E',
            cards: [],
          },
        ],
      },
    },
  ],
  autoSave: null,
  chat: true,
  permission_slot_info: [],
  name: 'Aura Layout',
  preview_id: 'f8cab5da-7cc4-405a-93f1-b7f1f5acc966',
  public: false,
  tool_comment_group_id: 'b50ccbe2-f556-4557-adcd-7a13c6954890',
  template: {
    id: '2af5bbba-8661-4b37-847b-b7d7e6aef917',
    types: ['PRIVATE'],
    community_id: {
      id: '8e6ea06b-0b0a-40f9-ba0f-a64746d1d51a',
      employees: [],
      invoice_address: '327edd72-eea4-4a55-ae91-2138880ae53e',
      license: {
        address: {},
        contact: {},
        license_summary: {
          cost: 0,
          current_license: 0,
          current_team_license: 0,
        },
      },
      logo: {
        id: '4a40e586-9bbf-4ee7-a550-0da24e8f22fa',
        url: 'https://files.pengueen.de/api/download/6b0dfc56-6d62-4376-aff4-ae380f5487d5.png',
      },
      members: [],
      name: 'M.F.G. Pengueen UG',
      sector: [],
      link: null,
      employeename: '',
      membername: '',
      widget_text: null,
      betreff_einladung_email: null,
      headline_einladung_email: null,
      text_einladung_email: null,
      text_einladung_bestaetigung_email: null,
      betreff_einladung_bestaetigung_email: null,
      show_name_formular_at_registration: true,
      address: {},
    },
    permission_slots: [],
    create_change_info: {
      changed_at: '2025-01-09T14:44:01.114Z',
      created_at: '2025-01-09T14:44:01.114Z',
      created_by: {
        communities: [],
        account: {
          email: 'sascha.landowski@pengueen.de',
          language: 'de',
          phone: '+4915172611407',
          id: 'e133245d-2065-4aec-ba80-73dba975af01',
          roles: ['user'],
          notifications: {
            email: false,
            sms: false,
            web: false,
          },
        },
        profile: {
          address: {
            country: 'de',
            house_number: '32',
            place: 'Berlin',
            street: 'Flemmingstraße',
            zip_code: '12555',
          },
          personal_data: {
            birthday: null,
            first_name: 'Sascha',
            last_name: 'Landowski',
            telephone: '015172611407',
          },
          avatar: {
            url: 'https://files.pengueen.de/api/download/undefined',
          },
        },
      },
    },
    image_preview: {
      id: 'ec54c3c9-a4c6-4b81-b0b1-817d04a5c942',
      url: 'https://files.pengueen.de/api/download/f50335f4-1caf-4b06-8741-9818ac664300.jpg',
    },
    image_market: {
      id: 'ec54c3c9-a4c6-4b81-b0b1-817d04a5c942',
      url: 'https://files.pengueen.de/api/download/f50335f4-1caf-4b06-8741-9818ac664300.jpg',
    },
    use_case: 'DOCUMENTATION',
    settings: {
      description: 'Hilfsmittel zur Validierung des programmierten Frontends',
      extra: {
        restricted: false,
        unique: false,
      },
      icon: {
        id: '160',
        url: '/static/media/ka_theater.2489432d85e0b68d05b22deccd1911c9.svg',
      },
      name: 'Aura Layout',
      purpose: null,
      interface_token: '',
      interface_url: '',
    },
    status: ['PUBLISHED', 'COMMUNITY'],
    sections: [
      {
        id: '8504f9b3-cced-4ed5-a85f-7794885d4c48',
        elements: [
          {
            id: '04d2005f-50f2-4d10-8d2c-1130103fb67c',
            name: 'Mein erstes Textelement',
            default_value: null,
            description: null,
            help_text: null,
            type: 'INPUT',
            sort: 1,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: '',
            settings: {
              advanced: {
                not_showing_at_table: false,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                only_admin_can_edit: false,
                required: false,
                show_at_arccordion_header: false,
                show_only_for_admin: false,
                value_show_in_other_tool: false,
              },
              list: {
                employees: false,
                members: false,
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: 'b71b769a-dd4f-4166-a3e8-029b06b61fe9',
            name: '1.1 Angaben zum Träger des Projektes',
            default_value: null,
            description: '<p></p>\n',
            help_text: '',
            type: 'HEADLINE',
            sort: 2,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: '',
            settings: {
              advanced: {
                required: false,
                disableExport: true,
                not_showing_at_table: true,
              },
              list: {},
              rights: {},
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: 'a01176ae-5a05-4225-b403-c1e430531e05',
            name: '1.1.2 Informationen zum Träger',
            default_value: null,
            description: '<p></p>\n',
            help_text: 'Text für ein Hilfe-Icon',
            type: 'INPUT',
            sort: 3,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: '',
            settings: {
              advanced: {
                not_showing_at_table: false,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                only_admin_can_edit: false,
                required: false,
                show_at_arccordion_header: false,
                show_only_for_admin: false,
                value_show_in_other_tool: false,
              },
              list: {
                employees: false,
                members: false,
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: '597f0b2c-9bbb-441e-a0d8-cbf9814465a1',
            name: '1.2 Projektname',
            default_value: null,
            description: '<p>Wie soll euer Projekt heißen?</p>\n',
            help_text: '',
            type: 'INPUT',
            sort: 4,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: '',
            settings: {
              advanced: {
                not_showing_at_table: false,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                only_admin_can_edit: false,
                required: false,
                show_at_arccordion_header: false,
                show_only_for_admin: false,
                value_show_in_other_tool: false,
              },
              list: {
                employees: false,
                members: false,
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: 'e9eed9ce-a5b1-4523-a40d-8625860f3839',
            name: '1.2.4 Kommune in der das Projekt stattfinden soll',
            default_value: null,
            description:
              '<p>Zutreffendes anklicken - Mehrfachnennung möglich</p>\n',
            help_text: '',
            type: 'SELECT',
            sort: 5,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: '',
            options: [
              {
                placeholder: 'Stadt Bad Belzig',
                value: '5af3a5af-1de5-48ae-805b-6b83ca2dec0a',
              },
              {
                placeholder: 'Amt Brück',
                value: '2400f87e-3245-4c13-879d-a2a6076b3951',
              },
              {
                placeholder: 'Amt Niemegk',
                value: 'c02543f4-2dec-4ce3-9b70-ddb26a2e96ad',
              },
              {
                placeholder: 'Stadt Treunbrietzen',
                value: '4d83bf21-991e-4b91-ae44-db93e4ae524a',
              },
            ],
            settings: {
              advanced: {
                connected_contact: false,
                not_showing_at_table: false,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                only_admin_can_edit: false,
                required: false,
                show_only_for_admin: false,
                single_select: false,
                value_show_in_other_tool: false,
              },
              list: {
                employees: false,
                members: false,
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: 'd0fd8f75-369c-4ae7-839e-9a9ab82085f9',
            name: '1.2.7 geplante Anzahl der Teilnehmenden',
            default_value: null,
            description: '<p>Bitte Anzahl eintragen</p>\n',
            help_text: '',
            type: 'NUMBER',
            sort: 6,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: '',
            settings: {
              advanced: {
                duration: false,
                hasSuffix: false,
                integer: false,
                not_showing_at_table: false,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                only_admin_can_edit: false,
                required: false,
                show_only_for_admin: false,
                thousandPoint: false,
                value_show_in_other_tool: false,
              },
              list: {
                employees: false,
                members: false,
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: '7ad7ee47-c281-48da-89ca-74bce89997fd',
            name: '4.2 Projektverlauf',
            default_value: null,
            description:
              '<p>Wir bitten dich, in regelmäßigen Abständen die Projektentwicklung zu dokumentieren.<br>Hierzu gehören z.B. Werbeflyer, Links, Fotos von der Veranstaltung.</p>\n',
            help_text: '',
            type: 'MULTIELEMENT',
            sort: 7,
            suffix: null,
            vote: null,
            display: null,
            can_add_row: true,
            can_edit: true,
            value: [
              {
                roomId: '4e36843c-570e-454d-afb0-af049cefff8f',
                id: 'b9f32bf4-a3df-4e84-beaf-c0a869f64db2',
                sort: 1,
                created_at: '2024-12-14T07:57:29.221Z',
                changed_at: '2024-12-14T07:57:29.221Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Bezeichnung:
                  'Das hier ist jetzt ein längerer Titel und wird verkürzt',
                Beschreibung: '<p>Logo für social Media</p>',
                Datei: [
                  {
                    id: '2a9c822c-7082-4260-b953-a4f7a6bb28e4',
                    type: 'image/jpeg',
                    name: 'HUK-COBURG_Logo--canvas-x_705-y_369.jpg',
                    url: 'https://files.pengueen.de/api/download/195cf041-7f9a-4b2c-99f4-09d04dce23e7.jpg',
                  },
                  {
                    id: '241c15bd-0395-4d77-ba3b-560bbdc11045',
                    type: 'image/jpeg',
                    name: 'BMW Logo.jpg',
                    url: 'https://files.pengueen.de/api/download/8c88afde-992d-4bbc-9bc7-1019fcd62a0d.jpg',
                  },
                ],
              },
              {
                roomId: null,
                id: 'd1f916c9-f8f3-46ea-afb0-19ff412c885d',
                sort: 2,
                created_at: '2024-12-14T08:00:53.163Z',
                changed_at: '2024-12-16T12:02:46.615Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Bezeichnung: 'Kurzer Titel',
                Beschreibung:
                  '<p>At vero eos et accusam et justo duo Dolores et Ea Rebum. Stet Clita kasd gugergren, no sea takimata sanct no sea takimata sanct no sea takimata sanct</p>',
                Datei: [
                  {
                    id: '92f9d3c8-b45b-4814-a103-fa8b04a0710a',
                    type: 'image/jpeg',
                    name: '9323c686-979a-4e54-8136-82c79ba709ea.jpg',
                    url: 'https://files.pengueen.de/api/download/ca9560ac-88ca-478f-bc4b-35e68f747cb1.jpg',
                  },
                  {
                    id: '5747511e-dc8b-44d9-be6e-4e071afd68a4',
                    type: 'image/jpeg',
                    name: 'b90c474c-0a3b-4b06-b9ea-f38a00f606b2.jpg',
                    url: 'https://files.pengueen.de/api/download/9ff3d73c-14d0-4026-80ab-68dc2c436b3c.jpg',
                  },
                  {
                    id: '1494c257-2e15-4e5d-97b3-4f4eed3ff4b5',
                    type: 'image/jpeg',
                    name: 'dd96f36b-b8a2-4f11-8693-2112dd6c58b6.jpg',
                    url: 'https://files.pengueen.de/api/download/e29822d6-cc1a-4a05-a491-5d55a9ad5359.jpg',
                  },
                ],
              },
              {
                roomId: null,
                id: 'a95817ea-f095-481d-b25e-93c590994d83',
                sort: 3,
                created_at: '2024-12-14T08:02:58.697Z',
                changed_at: '2024-12-14T08:04:09.087Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung:
                  '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, des diam</p>',
                Bezeichnung: 'Eintrag mit Dateien',
                Datei: [
                  {
                    id: 'd86d81ed-a8be-4e1f-851a-266b041f315f',
                    type: 'application/pdf',
                    name: 'Dashboard Aura.pdf',
                    url: 'https://files.pengueen.de/api/download/ef93dafd-9aed-4059-8456-d22691ea04d1.pdf',
                  },
                  {
                    id: 'f24af363-b0e0-4eac-be1d-3470f5354358',
                    type: 'application/pdf',
                    name: 'Willkommen zu ChatGPT für Alle – Ein Leitfaden für Einsteiger!.pdf',
                    url: 'https://files.pengueen.de/api/download/52dd44d0-1ffb-4d82-a162-6a81d91e606e.pdf',
                  },
                  {
                    id: 'bc36d7aa-2373-4594-b6bb-aba343c9ee11',
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    name: 'Süßholzwurzel.docx',
                    url: 'https://files.pengueen.de/api/download/6ff7cfe5-2d7e-4c66-a32f-2f956dff4071.docx',
                  },
                ],
              },
              {
                roomId: null,
                id: '407b46e1-a707-492f-bfab-f1f9a22f70f7',
                sort: 4,
                created_at: '2024-12-14T08:03:45.663Z',
                changed_at: '2024-12-14T08:03:45.663Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Bezeichnung: 'Logo PfD Hoher Fläming',
                Datei: [
                  {
                    id: '442e9ccb-f329-4051-bf84-c3c72d3b7d43',
                    type: 'application/pdf',
                    name: 'Dashboard Aura.pdf',
                    url: 'https://files.pengueen.de/api/download/5f2dda48-9306-4a0b-93a2-c699a332e7d6.pdf',
                  },
                ],
                Dropdown: {
                  name: 'Kunde für Kunde, Ministerium..., Netzwerk',
                  values: [
                    'dba116df-796a-494a-85a9-39ffc26bdc3f',
                    'c2b965e2-c318-4a50-aa56-68af47fa2209',
                    '3a332b29-7ce0-4c1f-903f-48b7fc66ea7a',
                  ],
                  options: [
                    {
                      id: 'dba116df-796a-494a-85a9-39ffc26bdc3f',
                      name: 'Kunde für Kunde',
                      color: '#FFD973',
                      sort: 1,
                    },
                    {
                      id: '3a332b29-7ce0-4c1f-903f-48b7fc66ea7a',
                      name: 'Ministerium...',
                      color: '#50D09E',
                      sort: 3,
                    },
                    {
                      id: 'c2b965e2-c318-4a50-aa56-68af47fa2209',
                      name: 'Netzwerk',
                      color: '#A8EB94',
                      sort: 2,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: 'e31bd3e7-e598-4af9-84a9-c76381e335f6',
                sort: 5,
                created_at: '2024-12-14T08:04:30.431Z',
                changed_at: '2024-12-14T08:04:30.431Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Bezeichnung: 'Mehrfacheintrag',
                Dropdown: {
                  name: 'Kunde für Kunde, Netzwerk',
                  values: [
                    'dba116df-796a-494a-85a9-39ffc26bdc3f',
                    'c2b965e2-c318-4a50-aa56-68af47fa2209',
                  ],
                  options: [
                    {
                      id: 'dba116df-796a-494a-85a9-39ffc26bdc3f',
                      name: 'Kunde für Kunde',
                      color: '#FFD973',
                      sort: 1,
                    },
                    {
                      id: 'c2b965e2-c318-4a50-aa56-68af47fa2209',
                      name: 'Netzwerk',
                      color: '#A8EB94',
                      sort: 2,
                    },
                  ],
                },
              },
            ],
            elements: [
              {
                id: '409fa49e-febd-45c8-a228-5cddc7df6e30',
                name: 'Bezeichnung',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'INPUT',
                sort: 1,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_at_arccordion_header: false,
                    show_only_for_admin: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '2f164ae0-d5de-4900-b4c3-cd63d953082e',
                name: 'Datei',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'UPLOAD',
                sort: 2,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    only_archive: false,
                    only_film: false,
                    only_image: false,
                    direct_download: false,
                    only_office: false,
                    only_one_image: false,
                    required: false,
                    show_only_for_admin: false,
                    small_image: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '69617703-6957-4f38-9b16-670d3933af9f',
                name: 'Beschreibung',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'TEXTAREA',
                sort: 3,
                suffix: null,
                vote: null,
                display: null,
                can_edit: false,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: true,
                    required: false,
                    show_at_arccordion_header: false,
                    show_only_for_admin: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '9c2365d8-4dc7-476a-8bd3-0b19f0ea9057',
                name: 'Dropdown',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'SELECT',
                sort: 4,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                options: [
                  {
                    color: '#FFD973',
                    placeholder: 'Kunde für Kunde',
                    value: 'dba116df-796a-494a-85a9-39ffc26bdc3f',
                  },
                  {
                    color: '#A8EB94',
                    placeholder: 'Netzwerk',
                    value: 'c2b965e2-c318-4a50-aa56-68af47fa2209',
                  },
                  {
                    color: '#50D09E',
                    placeholder: 'Ministerium...',
                    value: '3a332b29-7ce0-4c1f-903f-48b7fc66ea7a',
                  },
                ],
                settings: {
                  advanced: {
                    connected_contact: false,
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_only_for_admin: false,
                    single_select: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
            ],
            settings: {
              advanced: {
                folder_for_tool_data: '',
                notification_admins: true,
                notification_community_owner: true,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                notification_employees: true,
                notification_multielement_affected_person: false,
                notification_multielement_changed: true,
                notification_multielement_created: true,
                notification_multielement_creator: true,
                notification_multielement_emails: false,
                notification_multielement_following_receiver: false,
                notification_tool_creator: true,
                n_posts: 0,
                only_admin_can_edit: false,
                only_admin_can_post: false,
                only_admin_can_remove_posts: false,
                only_edit_own_posts: false,
                only_show_own_posts: false,
                required: false,
                show_only_for_admin: false,
                template_for_data_from_other_tool: '',
              },
              list: {
                employees: false,
                members: false,
              },
              multi_element_table: {
                elements_admin: [],
                elements_visible: [],
                sort_by: {
                  id: '',
                  order: 'ASC',
                },
                tile_view: true,
                kanban_card_description: '',
                kanban_card_title: '',
                kanban_card_user: '',
                kanban_column: '',
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: 'c1d0fcf3-5cb7-4113-8405-439050f3135d',
            name: '5.6 Fotogalerie',
            default_value: null,
            description: '<p>Unser Sommerfest.</p>\n',
            help_text: '',
            type: 'MULTIELEMENT',
            sort: 8,
            suffix: null,
            vote: null,
            display: 'TILE',
            can_add_row: true,
            can_edit: true,
            value: [
              {
                roomId: null,
                id: '7b072182-20c5-4040-817d-83177b3ac6f5',
                sort: 1,
                created_at: '2024-12-14T08:31:20.526Z',
                changed_at: '2024-12-16T12:02:26.348Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Bezeichnung: 'Registrieren',
                Fotos: [
                  {
                    id: 'b33aefef-a72f-4519-a3a8-72743d99d05c',
                    type: 'image/jpeg',
                    name: '2ce2f2d2-dff2-4693-ad82-bd466ddcfc79.jpg',
                    url: 'https://files.pengueen.de/api/download/a59f5544-0d15-4f6d-a631-4e88731fad77.jpg',
                  },
                  {
                    id: 'afd75528-de80-4bbf-9125-fac81ba97ef8',
                    type: 'image/jpeg',
                    name: 'd3701d16-fe97-4373-b5f7-fb6104faacb0.jpg',
                    url: 'https://files.pengueen.de/api/download/2863edd2-efa7-4c99-af48-a4d0e34eae19.jpg',
                  },
                  {
                    id: 'e08ea1e6-640e-48b3-b641-22f225cdbaec',
                    type: 'image/jpeg',
                    name: 'd516e727-18f0-4aa7-872c-ba083412582f.jpg',
                    url: 'https://files.pengueen.de/api/download/e9ea1a41-88ec-48e4-82df-dc27ac16177c.jpg',
                  },
                  {
                    id: '96c0e7fa-1503-4bec-bb7d-12a53a460ce2',
                    type: 'image/jpeg',
                    name: '5f1261d8-1940-40c6-80c0-1069092c3b45.jpg',
                    url: 'https://files.pengueen.de/api/download/e26bc8ac-dcec-4c78-b544-c46c7503c1d8.jpg',
                  },
                  {
                    id: '01cb99f2-6a6d-44df-993c-57b5217b239c',
                    type: 'image/jpeg',
                    name: '9a5a6da1-2cd4-493c-960b-7c7fbdc5dd37.jpg',
                    url: 'https://files.pengueen.de/api/download/3d340894-78a7-4a84-bec5-30c2c4973c50.jpg',
                  },
                  {
                    id: '0558bf60-2235-4743-b1bb-357891a41aac',
                    type: 'image/jpeg',
                    name: '3b3cdf1a-0c55-41b2-9c04-8c13a251d8b8.jpg',
                    url: 'https://files.pengueen.de/api/download/d619d168-c41b-43e3-9ff7-20e08ea42a71.jpg',
                  },
                  {
                    id: '837a1a50-b363-4c41-a897-37c5ec04f314',
                    type: 'image/jpeg',
                    name: '24a56459-da89-4b8d-9e99-3dfc2bb73755.jpg',
                    url: 'https://files.pengueen.de/api/download/bae8cef4-2660-40e0-974c-146d013c9f38.jpg',
                  },
                ],
              },
            ],
            elements: [
              {
                id: '32867a04-5e22-4e0e-8604-a867e0726fa6',
                name: 'Bezeichnung',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'INPUT',
                sort: 1,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_at_arccordion_header: false,
                    show_only_for_admin: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: 'cbce0976-c564-4745-95db-ed190ae71aae',
                name: 'Fotos',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'UPLOAD',
                sort: 2,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    only_archive: false,
                    only_film: false,
                    only_image: false,
                    direct_download: false,
                    only_office: false,
                    only_one_image: false,
                    required: false,
                    show_only_for_admin: false,
                    small_image: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '9b4f0ff8-35af-40e5-9bd9-889843989483',
                name: 'Fotograf:In',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'INPUT',
                sort: 3,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_at_arccordion_header: false,
                    show_only_for_admin: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
            ],
            settings: {
              advanced: {
                folder_for_tool_data: '',
                notification_admins: true,
                notification_community_owner: true,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                notification_employees: true,
                notification_multielement_affected_person: false,
                notification_multielement_changed: true,
                notification_multielement_created: true,
                notification_multielement_creator: true,
                notification_multielement_emails: false,
                notification_multielement_following_receiver: false,
                notification_tool_creator: true,
                n_posts: 0,
                only_admin_can_edit: false,
                only_admin_can_post: false,
                only_admin_can_remove_posts: false,
                only_edit_own_posts: false,
                only_show_own_posts: false,
                required: false,
                show_only_for_admin: false,
                template_for_data_from_other_tool: '',
              },
              list: {
                employees: false,
                members: false,
              },
              multi_element_table: {
                elements_admin: [],
                elements_visible: [],
                sort_by: {
                  id: '',
                  order: 'ASC',
                },
                tile_view: true,
                kanban_card_description: '',
                kanban_card_title: '',
                kanban_card_user: '',
                kanban_column: '',
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
        ],
        name: 'Antragstellung',
        description:
          '<p>Hier können Sie den Projektantrag mit einer Fördersumme über 500€ stellen.</p>\n',
        settings: {
          restricted: false,
        },
      },
      {
        id: '95491ccb-0ef8-465c-85b1-9c6faf244afe',
        elements: [
          {
            id: '03ada12b-6485-482f-bf3b-e0a966f36e4c',
            name: '7.4.1 Checkbox horizontal',
            default_value: null,
            description:
              '<p>Beim Anlegen von Checkboxen und auch Radio Buttons kann gewählt werden, ob die einzelnen Felder neben- oder untereinander angezeigt werden sollen. Bei sehr vielen Feldern macht eine  horizontale Anordnung u.U. Sinn.</p>\n',
            help_text: '',
            type: 'CHECKBOX',
            sort: 1,
            suffix: null,
            vote: null,
            display: null,
            can_edit: true,
            value: ['', '284c566f-03a2-4214-98c2-0c4e72b3ffba'],
            options: [
              {
                placeholder: 'Informationsmaterial',
                value: '284c566f-03a2-4214-98c2-0c4e72b3ffba',
              },
              {
                placeholder: 'Marketingbroschüren',
                value: '94ab8b10-425b-432e-9b41-5d0f818b8b28',
              },
              {
                placeholder: 'Preislisten',
                value: 'e601b1bc-9748-441c-b9b9-7ee56a47efe3',
              },
              {
                placeholder: 'Werbetassen',
                value: '23239751-d9fa-4858-9b07-6306fa7865b6',
              },
              {
                placeholder: 'Werbetaschen',
                value: '9a27a3f0-6eff-4d74-917b-600555535105',
              },
              {
                placeholder: 'Prospektständer',
                value: 'cc3f7e7a-4ca0-4c77-99ac-19a770863fb9',
              },
              {
                placeholder: 'Messestand',
                value: '0cd2218f-13de-44c4-a807-b45091b3db8a',
              },
              {
                placeholder: 'Getränke',
                value: '2ff9351d-0e56-48a8-b433-f3c498752daa',
              },
            ],
            settings: {
              advanced: {
                not_showing_at_table: false,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                only_admin_can_edit: false,
                required: false,
                show_at_arccordion_header: false,
                show_only_for_admin: false,
                value_show_in_other_tool: false,
              },
              list: {
                employees: false,
                members: false,
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
          {
            id: '35608d47-8503-495a-92a7-fcf6fd7a386c',
            name: '7.4.2 Ticket-System (Kanban)',
            default_value: null,
            description: '<p>Übersicht Tickets</p>\n',
            help_text: '',
            type: 'MULTIELEMENT',
            sort: 2,
            suffix: null,
            vote: null,
            display: 'KANBAN',
            can_add_row: true,
            can_edit: true,
            value: [
              {
                roomId: null,
                id: '223fa7ab-5e2c-4e1a-b36e-e821505ecd6e',
                sort: 1,
                created_at: '2024-12-14T08:08:55.145Z',
                changed_at: '2024-12-14T08:08:55.145Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung: '<p>Sales Funnel Sprachmittler (Q3)</p>',
                'Ticket-ID': 'TI-4711',
                Status: {
                  name: 'Backlog',
                  values: ['4fd563bd-ab85-4378-af9f-96aa25593e91'],
                  options: [
                    {
                      id: '4fd563bd-ab85-4378-af9f-96aa25593e91',
                      name: 'Backlog',
                      color: '#667784',
                      sort: 1,
                    },
                  ],
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: 'ee51775b-76a3-47fc-851d-0b1cd36f6ab2',
                sort: 2,
                created_at: '2024-12-14T08:11:16.698Z',
                changed_at: '2024-12-14T08:18:37.300Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung:
                  '<p>Artikel: Schritt für Schritt wie gehe ich vor</p>',
                'Ticket-ID': 'TI-0815',
                'Dateien/Bilder': [
                  {
                    id: '643ef54f-05b8-4d2c-8406-bd6816c7bb5c',
                    type: 'image/jpeg',
                    name: '18455485-8dcc-4275-bc55-7f83e844061d.jpg',
                    url: 'https://files.pengueen.de/api/download/98402957-511b-4745-954d-3349fe16ce4b.jpg',
                  },
                ],
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Status: {
                  name: 'Backlog',
                  values: ['4fd563bd-ab85-4378-af9f-96aa25593e91'],
                  options: [
                    {
                      id: '4fd563bd-ab85-4378-af9f-96aa25593e91',
                      name: 'Backlog',
                      color: '#667784',
                      sort: 1,
                    },
                  ],
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: '5409aca2-fa81-4a90-b5d6-8df401d891b3',
                sort: 3,
                created_at: '2024-12-14T08:17:36.163Z',
                changed_at: '2024-12-14T08:20:24.879Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                'Ticket-ID': 'TI-1234',
                Beschreibung:
                  '<p>Navigation im Nachrichtencenter: "ältere laden" ist weg</p>',
                'Dateien/Bilder': [
                  {
                    id: 'db1c2668-9f18-4c78-81d0-ae17d960db24',
                    type: 'image/png',
                    name: '43d2f1a5-36e7-4aed-bc42-0d5d9aea4b20.png',
                    url: 'https://files.pengueen.de/api/download/649f6ed0-2a8d-4d63-9b12-303d8fe1cbfc.png',
                  },
                ],
                bearbeitet: {
                  id: '841ba1e1-565e-4623-aebe-848b9a75d114',
                  name: 'Hyejin Kim',
                  avatar:
                    'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
                },
                Status: {
                  name: 'Fehler',
                  values: ['08d8c870-19ca-4edb-a9f4-eff13225fa33'],
                  options: [
                    {
                      id: '08d8c870-19ca-4edb-a9f4-eff13225fa33',
                      name: 'Fehler',
                      color: '#F06A6A',
                      sort: 2,
                    },
                  ],
                },
                Priorität: {
                  name: 'mittel',
                  values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                  options: [
                    {
                      id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                      name: 'mittel',
                      color: '#FFD973',
                      sort: 2,
                    },
                  ],
                },
                Epic: {
                  name: 'Nachrichtencenter',
                  values: ['db78ccee-926f-4751-a852-606b1e937bfe'],
                  options: [
                    {
                      id: 'db78ccee-926f-4751-a852-606b1e937bfe',
                      name: 'Nachrichtencenter',
                      color: null,
                      sort: 1,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: 'b5578ef2-a90d-4003-882d-9913dcebf88c',
                sort: 4,
                created_at: '2024-12-14T08:19:51.956Z',
                changed_at: '2024-12-14T08:20:38.925Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung:
                  '<p>wenn ich eine Gruppe aus dem Netzwerk als Favorit markiere, wird diese nicht auf dem Dashboard angezeigt</p>',
                'Ticket-ID': 'TI-2578',
                bearbeitet: {
                  id: '841ba1e1-565e-4623-aebe-848b9a75d114',
                  name: 'Hyejin Kim',
                  avatar:
                    'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
                },
                Status: {
                  name: 'Fehler',
                  values: ['08d8c870-19ca-4edb-a9f4-eff13225fa33'],
                  options: [
                    {
                      id: '08d8c870-19ca-4edb-a9f4-eff13225fa33',
                      name: 'Fehler',
                      color: '#F06A6A',
                      sort: 2,
                    },
                  ],
                },
                Epic: {
                  name: 'Dashboard',
                  values: ['83f84995-2008-4c26-9df7-a563d495b431'],
                  options: [
                    {
                      id: '83f84995-2008-4c26-9df7-a563d495b431',
                      name: 'Dashboard',
                      color: null,
                      sort: 2,
                    },
                  ],
                },
                Priorität: {
                  name: 'mittel',
                  values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                  options: [
                    {
                      id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                      name: 'mittel',
                      color: '#FFD973',
                      sort: 2,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: 'e10c17af-39c7-47a9-ae53-ea60b176a11b',
                sort: 5,
                created_at: '2024-12-14T08:21:48.228Z',
                changed_at: '2024-12-14T08:21:48.228Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung:
                  '<p>Super-Element: Liste der Elemente aus Hilfsmittelvorlage entsprechend Anordnung der Elemente sortieren (Freiburg)</p>',
                'Ticket-ID': 'TI-2579',
                bearbeitet: {
                  id: 'ae4bdb65-43bb-4ad4-9810-49a28ac64a01',
                  name: 'Daniel Dziamski',
                  avatar:
                    'https://files.pengueen.de/api/download/97f88503-f8d6-4b9b-ae3c-9c527b79276a.jpg',
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
                Epic: {
                  name: 'PfD',
                  values: ['5507f61a-cdc2-42e5-92e1-e4db319cecd7'],
                  options: [
                    {
                      id: '5507f61a-cdc2-42e5-92e1-e4db319cecd7',
                      name: 'PfD',
                      color: null,
                      sort: 3,
                    },
                  ],
                },
                Status: {
                  name: 'Neu',
                  values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                  options: [
                    {
                      id: '018ed124-8e70-4873-97e8-372dae5dc632',
                      name: 'Neu',
                      color: '#FFD973',
                      sort: 3,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: '225705d4-3261-4ea3-a319-1f989a710de3',
                sort: 6,
                created_at: '2024-12-14T08:22:47.852Z',
                changed_at: '2024-12-14T08:22:47.852Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung: '<p>Noch ein zu bearbeitender Punkt</p>',
                'Ticket-ID': 'TI-0816',
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Epic: {
                  name: 'Epicname',
                  values: ['6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1'],
                  options: [
                    {
                      id: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                      name: 'Epicname',
                      color: null,
                      sort: 4,
                    },
                  ],
                },
                Status: {
                  name: 'Neu',
                  values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                  options: [
                    {
                      id: '018ed124-8e70-4873-97e8-372dae5dc632',
                      name: 'Neu',
                      color: '#FFD973',
                      sort: 3,
                    },
                  ],
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: '0df8d93d-9fba-44ee-a08e-afdff73f1dc7',
                sort: 7,
                created_at: '2024-12-14T08:23:38.176Z',
                changed_at: '2024-12-14T08:23:38.176Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung: '<p>Noch ein zu bearbeitender Punkt</p>',
                'Ticket-ID': 'TI-0817',
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Epic: {
                  name: 'Epicname',
                  values: ['6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1'],
                  options: [
                    {
                      id: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                      name: 'Epicname',
                      color: null,
                      sort: 4,
                    },
                  ],
                },
                Status: {
                  name: 'Neu',
                  values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                  options: [
                    {
                      id: '018ed124-8e70-4873-97e8-372dae5dc632',
                      name: 'Neu',
                      color: '#FFD973',
                      sort: 3,
                    },
                  ],
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: 'f560e80f-49c5-4207-908d-b5ec2912896b',
                sort: 8,
                created_at: '2024-12-14T08:24:32.529Z',
                changed_at: '2024-12-14T08:24:32.529Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                'Ticket-ID': 'TI-0818',
                Beschreibung:
                  '<p>Noch ein zu bearbeitender Punkt .. es fehlt eine Kopierfunktion 😉</p>',
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
                Status: {
                  name: 'Neu',
                  values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                  options: [
                    {
                      id: '018ed124-8e70-4873-97e8-372dae5dc632',
                      name: 'Neu',
                      color: '#FFD973',
                      sort: 3,
                    },
                  ],
                },
                Epic: {
                  name: 'Epicname',
                  values: ['6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1'],
                  options: [
                    {
                      id: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                      name: 'Epicname',
                      color: null,
                      sort: 4,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: '0a07f49a-79fa-4fbf-9c06-eff7c3815f05',
                sort: 9,
                created_at: '2024-12-14T08:25:26.681Z',
                changed_at: '2024-12-14T08:25:26.681Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                'Ticket-ID': 'TI-5711',
                Beschreibung: '<p>Marktplatz Wünsche an Sascha</p>',
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Status: {
                  name: 'in Bearbeitung',
                  values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                  options: [
                    {
                      id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                      name: 'in Bearbeitung',
                      color: '#4998F9',
                      sort: 4,
                    },
                  ],
                },
                Priorität: {
                  name: 'mittel',
                  values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                  options: [
                    {
                      id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                      name: 'mittel',
                      color: '#FFD973',
                      sort: 2,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: '624e3d67-5a57-4483-bd3f-f390357a376e',
                sort: 10,
                created_at: '2024-12-14T08:26:26.003Z',
                changed_at: '2024-12-14T08:26:26.003Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung:
                  '<p>Arbeitspaket Pengueen goes SAAS (Separate Word)</p>',
                'Ticket-ID': 'TI-0819',
                'Dateien/Bilder': [
                  {
                    id: '1993f0bc-1768-48de-b298-26ae6a5a5e49',
                    type: 'image/png',
                    name: '62c51853-ad39-4683-88d3-b34ca0683c40.png',
                    url: 'https://files.pengueen.de/api/download/2c324bf8-c48f-4680-bf18-3540b6f8bc50.png',
                  },
                ],
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Priorität: {
                  name: 'mittel',
                  values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                  options: [
                    {
                      id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                      name: 'mittel',
                      color: '#FFD973',
                      sort: 2,
                    },
                  ],
                },
                Status: {
                  name: 'in Bearbeitung',
                  values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                  options: [
                    {
                      id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                      name: 'in Bearbeitung',
                      color: '#4998F9',
                      sort: 4,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: 'c99e2d2c-526a-41e5-b3c0-a946c40e17fa',
                sort: 11,
                created_at: '2024-12-14T08:27:42.058Z',
                changed_at: '2024-12-14T08:27:42.058Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                Beschreibung:
                  '<p>2 Artikel: Person zu Pengueen einladen (verschiedene Wege)</p>',
                'Ticket-ID': 'TI-6711',
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Status: {
                  name: 'in Bearbeitung',
                  values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                  options: [
                    {
                      id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                      name: 'in Bearbeitung',
                      color: '#4998F9',
                      sort: 4,
                    },
                  ],
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
              },
              {
                roomId: null,
                id: '31c9ea15-6697-4363-a0ac-7474d7f91081',
                sort: 12,
                created_at: '2024-12-14T08:28:41.512Z',
                changed_at: '2024-12-14T08:28:41.512Z',
                created_by: 'Sascha Landowski',
                created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
                changed_by: 'Sascha Landowski',
                can_edit: true,
                can_delete: true,
                'Ticket-ID': 'TI-8711',
                Beschreibung:
                  '<p>2 Artikel: Personen zu Pengueen einladen (verschieden Wege)</p>',
                bearbeitet: {
                  id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                  name: 'Mira Hertle',
                  avatar:
                    'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
                },
                Status: {
                  name: 'in Bearbeitung',
                  values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                  options: [
                    {
                      id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                      name: 'in Bearbeitung',
                      color: '#4998F9',
                      sort: 4,
                    },
                  ],
                },
                Priorität: {
                  name: 'niedrig',
                  values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                  options: [
                    {
                      id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                      name: 'niedrig',
                      color: '#50D09E',
                      sort: 1,
                    },
                  ],
                },
              },
            ],
            elements: [
              {
                id: 'bfbd0261-e9fa-4037-9c57-9ab91bf7d8ef',
                name: 'Ticket-ID',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'INPUT',
                sort: 1,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_at_arccordion_header: false,
                    show_only_for_admin: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: 'e7526081-2ca6-4dee-995a-1910ba6d84c4',
                name: 'Beschreibung',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'TEXTAREA',
                sort: 2,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_at_arccordion_header: false,
                    show_only_for_admin: false,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '71491c7b-7949-4c8e-af41-bf79bd92aa61',
                name: 'Dateien/Bilder',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'UPLOAD',
                sort: 3,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    only_archive: false,
                    only_film: false,
                    only_image: false,
                    direct_download: false,
                    only_office: false,
                    only_one_image: false,
                    required: false,
                    show_only_for_admin: false,
                    small_image: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '894549b1-d50d-400f-b3f0-2862f45b8108',
                name: 'Priorität',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'SELECT',
                sort: 4,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                options: [
                  {
                    color: '#50D09E',
                    placeholder: 'niedrig',
                    value: 'b5d31b75-aabe-4e5b-824e-617130105750',
                  },
                  {
                    color: '#FFD973',
                    placeholder: 'mittel',
                    value: '27695f6a-8740-4f75-ac9e-b334d405579b',
                  },
                  {
                    color: '#F06A6A',
                    placeholder: 'hoch',
                    value: '57cc0cf7-5e77-42ac-91c2-cbcdaef5491f',
                  },
                ],
                settings: {
                  advanced: {
                    connected_contact: false,
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_only_for_admin: false,
                    single_select: true,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: 'f8ab509c-3495-4b2d-9822-7d8443a66526',
                name: 'Status',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'SELECT',
                sort: 5,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                options: [
                  {
                    color: '#667784',
                    placeholder: 'Backlog',
                    value: '4fd563bd-ab85-4378-af9f-96aa25593e91',
                  },
                  {
                    color: '#F06A6A',
                    placeholder: 'Fehler',
                    value: '08d8c870-19ca-4edb-a9f4-eff13225fa33',
                  },
                  {
                    color: '#FFD973',
                    placeholder: 'Neu',
                    value: '018ed124-8e70-4873-97e8-372dae5dc632',
                  },
                  {
                    color: '#4998F9',
                    placeholder: 'in Bearbeitung',
                    value: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                  },
                  {
                    color: '#FFD973',
                    placeholder: 'zur Abnahme',
                    value: 'f3e72589-e4d4-44b3-a0f6-a0fa07264bf0',
                  },
                  {
                    color: '#50D09E',
                    placeholder: 'abgeschlossen',
                    value: '99468606-1f0f-4a1f-abcf-020cae358012',
                  },
                ],
                settings: {
                  advanced: {
                    connected_contact: false,
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_only_for_admin: false,
                    single_select: true,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '316dd8bc-ea51-444b-adc4-542cffcd9a28',
                name: 'bearbeitet',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'CONTACT',
                sort: 6,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                settings: {
                  advanced: {
                    auto_select: false,
                    contact_card: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    not_showing_at_table: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_only_for_admin: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
              {
                id: '3568a1b2-c5b1-40df-8e06-3cf3c914ddb4',
                name: 'Epic',
                default_value: null,
                description: '<p></p>\n',
                help_text: '',
                type: 'SELECT',
                sort: 7,
                suffix: null,
                vote: null,
                display: null,
                can_edit: true,
                child_element: true,
                options: [
                  {
                    placeholder: 'Nachrichtencenter',
                    value: 'db78ccee-926f-4751-a852-606b1e937bfe',
                  },
                  {
                    placeholder: 'Dashboard',
                    value: '83f84995-2008-4c26-9df7-a563d495b431',
                  },
                  {
                    placeholder: 'PfD',
                    value: '5507f61a-cdc2-42e5-92e1-e4db319cecd7',
                  },
                  {
                    placeholder: 'Epicname',
                    value: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                  },
                ],
                settings: {
                  advanced: {
                    connected_contact: false,
                    not_showing_at_table: false,
                    notification_element_changed: false,
                    notification_element_changed_content: false,
                    notification_element_changed_title: false,
                    only_admin_can_edit: false,
                    required: false,
                    show_only_for_admin: false,
                    single_select: true,
                    value_show_in_other_tool: false,
                  },
                  list: {
                    employees: false,
                    members: false,
                  },
                  rights: {
                    employee: false,
                    member: false,
                    pengueen_admin: false,
                  },
                  export: {
                    disableExport: false,
                    export_with_id: false,
                  },
                },
                permission_edit: [],
                permission_view: [],
              },
            ],
            settings: {
              advanced: {
                folder_for_tool_data: '',
                notification_admins: true,
                notification_community_owner: true,
                notification_element_changed: false,
                notification_element_changed_content: false,
                notification_element_changed_title: false,
                notification_employees: true,
                notification_multielement_affected_person: false,
                notification_multielement_changed: true,
                notification_multielement_created: true,
                notification_multielement_creator: true,
                notification_multielement_emails: false,
                notification_multielement_following_receiver: false,
                notification_tool_creator: true,
                n_posts: 0,
                only_admin_can_edit: false,
                only_admin_can_post: false,
                only_admin_can_remove_posts: false,
                only_edit_own_posts: false,
                only_show_own_posts: false,
                required: false,
                show_only_for_admin: false,
                template_for_data_from_other_tool: '',
              },
              list: {
                employees: false,
                members: false,
              },
              multi_element_table: {
                elements_admin: [],
                elements_visible: [],
                sort_by: {
                  id: '',
                  order: 'ASC',
                },
                tile_view: true,
                kanban_card_description: 'e7526081-2ca6-4dee-995a-1910ba6d84c4',
                kanban_card_title: 'bfbd0261-e9fa-4037-9c57-9ab91bf7d8ef',
                kanban_card_user: '316dd8bc-ea51-444b-adc4-542cffcd9a28',
                kanban_column: 'f8ab509c-3495-4b2d-9822-7d8443a66526',
              },
              rights: {
                employee: false,
                member: false,
                pengueen_admin: false,
              },
              export: {
                disableExport: false,
                export_with_id: false,
              },
            },
            permission_edit: [],
            permission_view: [],
          },
        ],
        name: 'Und noch ein zweiter Abschnitt',
        description: '',
        settings: {
          restricted: false,
        },
      },
      {
        id: '2dc0b5d0-7622-4049-9b5d-33f4fbe2ec6a',
        elements: [],
        name: 'Ein dritter Abschnitt',
        description: '',
        settings: {
          restricted: false,
        },
      },
      {
        id: '500da66f-c0f0-4009-b1c8-cb64cefb00a1',
        elements: [],
        name: 'Ein 4. Abschnitt',
        description: '',
        settings: {
          restricted: false,
        },
      },
    ],
  },
  users: [
    {
      id: '841ba1e1-565e-4623-aebe-848b9a75d114',
      first_name: 'Hyejin',
      last_name: 'Kim',
      permission: 'EDIT',
      avatar: {
        id: 'f5952654-8886-49d0-b82e-6b4c6b5bc5c9',
        url: 'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
      },
    },
    {
      id: 'e133245d-2065-4aec-ba80-73dba975af01',
      first_name: 'Sascha',
      last_name: 'Landowski',
      permission: 'WRITE',
      avatar: {
        id: 'e7a59b5b-45ce-4477-997f-12c21ac9de5f',
        url: 'https://files.pengueen.de/api/download/c5449ee2-b2e8-475f-a49a-5539c2d528bd.jpg',
      },
    },
  ],
  values: [
    {
      id: '2dc0b5d0-7622-4049-9b5d-33f4fbe2ec6a',
      elements: [],
    },
    {
      id: '500da66f-c0f0-4009-b1c8-cb64cefb00a1',
      elements: [],
    },
    {
      id: '8504f9b3-cced-4ed5-a85f-7794885d4c48',
      elements: [
        {
          id: '04d2005f-50f2-4d10-8d2c-1130103fb67c',
          value: '',
          required: false,
          names: 'Mein erstes Textelement',
          child_element: false,
        },
        {
          id: 'd0fd8f75-369c-4ae7-839e-9a9ab82085f9',
          value: '',
          required: false,
          names: '1.2.7 geplante Anzahl der Teilnehmenden',
          child_element: false,
        },
        {
          id: 'b71b769a-dd4f-4166-a3e8-029b06b61fe9',
          value: '',
          names: '1.1 Angaben zum Träger des Projektes',
          child_element: false,
        },
        {
          id: 'e9eed9ce-a5b1-4523-a40d-8625860f3839',
          value: '',
          required: false,
          names: '1.2.4 Kommune in der das Projekt stattfinden soll',
          child_element: false,
        },
        {
          id: '597f0b2c-9bbb-441e-a0d8-cbf9814465a1',
          value: '',
          required: false,
          names: '1.2 Projektname',
          child_element: false,
        },
        {
          id: 'a01176ae-5a05-4225-b403-c1e430531e05',
          value: '',
          required: false,
          names: '1.1.2 Informationen zum Träger',
          child_element: false,
        },
        {
          id: 'c1d0fcf3-5cb7-4113-8405-439050f3135d',
          rows: [
            {
              roomId: null,
              id: '7b072182-20c5-4040-817d-83177b3ac6f5',
              sort: 1,
              created_at: '2024-12-14T08:31:20.526Z',
              changed_at: '2024-12-16T12:02:26.348Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Bezeichnung: 'Registrieren',
              Fotos: [
                {
                  id: 'b33aefef-a72f-4519-a3a8-72743d99d05c',
                  type: 'image/jpeg',
                  name: '2ce2f2d2-dff2-4693-ad82-bd466ddcfc79.jpg',
                  url: 'https://files.pengueen.de/api/download/a59f5544-0d15-4f6d-a631-4e88731fad77.jpg',
                },
                {
                  id: 'afd75528-de80-4bbf-9125-fac81ba97ef8',
                  type: 'image/jpeg',
                  name: 'd3701d16-fe97-4373-b5f7-fb6104faacb0.jpg',
                  url: 'https://files.pengueen.de/api/download/2863edd2-efa7-4c99-af48-a4d0e34eae19.jpg',
                },
                {
                  id: 'e08ea1e6-640e-48b3-b641-22f225cdbaec',
                  type: 'image/jpeg',
                  name: 'd516e727-18f0-4aa7-872c-ba083412582f.jpg',
                  url: 'https://files.pengueen.de/api/download/e9ea1a41-88ec-48e4-82df-dc27ac16177c.jpg',
                },
                {
                  id: '96c0e7fa-1503-4bec-bb7d-12a53a460ce2',
                  type: 'image/jpeg',
                  name: '5f1261d8-1940-40c6-80c0-1069092c3b45.jpg',
                  url: 'https://files.pengueen.de/api/download/e26bc8ac-dcec-4c78-b544-c46c7503c1d8.jpg',
                },
                {
                  id: '01cb99f2-6a6d-44df-993c-57b5217b239c',
                  type: 'image/jpeg',
                  name: '9a5a6da1-2cd4-493c-960b-7c7fbdc5dd37.jpg',
                  url: 'https://files.pengueen.de/api/download/3d340894-78a7-4a84-bec5-30c2c4973c50.jpg',
                },
                {
                  id: '0558bf60-2235-4743-b1bb-357891a41aac',
                  type: 'image/jpeg',
                  name: '3b3cdf1a-0c55-41b2-9c04-8c13a251d8b8.jpg',
                  url: 'https://files.pengueen.de/api/download/d619d168-c41b-43e3-9ff7-20e08ea42a71.jpg',
                },
                {
                  id: '837a1a50-b363-4c41-a897-37c5ec04f314',
                  type: 'image/jpeg',
                  name: '24a56459-da89-4b8d-9e99-3dfc2bb73755.jpg',
                  url: 'https://files.pengueen.de/api/download/bae8cef4-2660-40e0-974c-146d013c9f38.jpg',
                },
              ],
            },
          ],
        },
        {
          id: '7ad7ee47-c281-48da-89ca-74bce89997fd',
          rows: [
            {
              roomId: '4e36843c-570e-454d-afb0-af049cefff8f',
              id: 'b9f32bf4-a3df-4e84-beaf-c0a869f64db2',
              sort: 1,
              created_at: '2024-12-14T07:57:29.221Z',
              changed_at: '2024-12-14T07:57:29.221Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Bezeichnung:
                'Das hier ist jetzt ein längerer Titel und wird verkürzt',
              Beschreibung: '<p>Logo für social Media</p>',
              Datei: [
                {
                  id: '2a9c822c-7082-4260-b953-a4f7a6bb28e4',
                  type: 'image/jpeg',
                  name: 'HUK-COBURG_Logo--canvas-x_705-y_369.jpg',
                  url: 'https://files.pengueen.de/api/download/195cf041-7f9a-4b2c-99f4-09d04dce23e7.jpg',
                },
                {
                  id: '241c15bd-0395-4d77-ba3b-560bbdc11045',
                  type: 'image/jpeg',
                  name: 'BMW Logo.jpg',
                  url: 'https://files.pengueen.de/api/download/8c88afde-992d-4bbc-9bc7-1019fcd62a0d.jpg',
                },
              ],
            },
            {
              roomId: null,
              id: 'd1f916c9-f8f3-46ea-afb0-19ff412c885d',
              sort: 2,
              created_at: '2024-12-14T08:00:53.163Z',
              changed_at: '2024-12-16T12:02:46.615Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Bezeichnung: 'Kurzer Titel',
              Beschreibung:
                '<p>At vero eos et accusam et justo duo Dolores et Ea Rebum. Stet Clita kasd gugergren, no sea takimata sanct no sea takimata sanct no sea takimata sanct</p>',
              Datei: [
                {
                  id: '92f9d3c8-b45b-4814-a103-fa8b04a0710a',
                  type: 'image/jpeg',
                  name: '9323c686-979a-4e54-8136-82c79ba709ea.jpg',
                  url: 'https://files.pengueen.de/api/download/ca9560ac-88ca-478f-bc4b-35e68f747cb1.jpg',
                },
                {
                  id: '5747511e-dc8b-44d9-be6e-4e071afd68a4',
                  type: 'image/jpeg',
                  name: 'b90c474c-0a3b-4b06-b9ea-f38a00f606b2.jpg',
                  url: 'https://files.pengueen.de/api/download/9ff3d73c-14d0-4026-80ab-68dc2c436b3c.jpg',
                },
                {
                  id: '1494c257-2e15-4e5d-97b3-4f4eed3ff4b5',
                  type: 'image/jpeg',
                  name: 'dd96f36b-b8a2-4f11-8693-2112dd6c58b6.jpg',
                  url: 'https://files.pengueen.de/api/download/e29822d6-cc1a-4a05-a491-5d55a9ad5359.jpg',
                },
              ],
            },
            {
              roomId: null,
              id: 'a95817ea-f095-481d-b25e-93c590994d83',
              sort: 3,
              created_at: '2024-12-14T08:02:58.697Z',
              changed_at: '2024-12-14T08:04:09.087Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung:
                '<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, des diam</p>',
              Bezeichnung: 'Eintrag mit Dateien',
              Datei: [
                {
                  id: 'd86d81ed-a8be-4e1f-851a-266b041f315f',
                  type: 'application/pdf',
                  name: 'Dashboard Aura.pdf',
                  url: 'https://files.pengueen.de/api/download/ef93dafd-9aed-4059-8456-d22691ea04d1.pdf',
                },
                {
                  id: 'f24af363-b0e0-4eac-be1d-3470f5354358',
                  type: 'application/pdf',
                  name: 'Willkommen zu ChatGPT für Alle – Ein Leitfaden für Einsteiger!.pdf',
                  url: 'https://files.pengueen.de/api/download/52dd44d0-1ffb-4d82-a162-6a81d91e606e.pdf',
                },
                {
                  id: 'bc36d7aa-2373-4594-b6bb-aba343c9ee11',
                  type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  name: 'Süßholzwurzel.docx',
                  url: 'https://files.pengueen.de/api/download/6ff7cfe5-2d7e-4c66-a32f-2f956dff4071.docx',
                },
              ],
            },
            {
              roomId: null,
              id: '407b46e1-a707-492f-bfab-f1f9a22f70f7',
              sort: 4,
              created_at: '2024-12-14T08:03:45.663Z',
              changed_at: '2024-12-14T08:03:45.663Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Bezeichnung: 'Logo PfD Hoher Fläming',
              Datei: [
                {
                  id: '442e9ccb-f329-4051-bf84-c3c72d3b7d43',
                  type: 'application/pdf',
                  name: 'Dashboard Aura.pdf',
                  url: 'https://files.pengueen.de/api/download/5f2dda48-9306-4a0b-93a2-c699a332e7d6.pdf',
                },
              ],
              Dropdown: {
                name: 'Kunde für Kunde, Ministerium..., Netzwerk',
                values: [
                  'dba116df-796a-494a-85a9-39ffc26bdc3f',
                  'c2b965e2-c318-4a50-aa56-68af47fa2209',
                  '3a332b29-7ce0-4c1f-903f-48b7fc66ea7a',
                ],
                options: [
                  {
                    id: 'dba116df-796a-494a-85a9-39ffc26bdc3f',
                    name: 'Kunde für Kunde',
                    color: '#FFD973',
                    sort: 1,
                  },
                  {
                    id: '3a332b29-7ce0-4c1f-903f-48b7fc66ea7a',
                    name: 'Ministerium...',
                    color: '#50D09E',
                    sort: 3,
                  },
                  {
                    id: 'c2b965e2-c318-4a50-aa56-68af47fa2209',
                    name: 'Netzwerk',
                    color: '#A8EB94',
                    sort: 2,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: 'e31bd3e7-e598-4af9-84a9-c76381e335f6',
              sort: 5,
              created_at: '2024-12-14T08:04:30.431Z',
              changed_at: '2024-12-14T08:04:30.431Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Bezeichnung: 'Mehrfacheintrag',
              Dropdown: {
                name: 'Kunde für Kunde, Netzwerk',
                values: [
                  'dba116df-796a-494a-85a9-39ffc26bdc3f',
                  'c2b965e2-c318-4a50-aa56-68af47fa2209',
                ],
                options: [
                  {
                    id: 'dba116df-796a-494a-85a9-39ffc26bdc3f',
                    name: 'Kunde für Kunde',
                    color: '#FFD973',
                    sort: 1,
                  },
                  {
                    id: 'c2b965e2-c318-4a50-aa56-68af47fa2209',
                    name: 'Netzwerk',
                    color: '#A8EB94',
                    sort: 2,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      id: '95491ccb-0ef8-465c-85b1-9c6faf244afe',
      elements: [
        {
          id: '03ada12b-6485-482f-bf3b-e0a966f36e4c',
          value: ['', '284c566f-03a2-4214-98c2-0c4e72b3ffba'],
          required: false,
          names: '7.4.1 Checkbox horizontal',
          child_element: false,
        },
        {
          id: '35608d47-8503-495a-92a7-fcf6fd7a386c',
          rows: [
            {
              roomId: null,
              id: '223fa7ab-5e2c-4e1a-b36e-e821505ecd6e',
              sort: 1,
              created_at: '2024-12-14T08:08:55.145Z',
              changed_at: '2024-12-14T08:08:55.145Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung: '<p>Sales Funnel Sprachmittler (Q3)</p>',
              'Ticket-ID': 'TI-4711',
              Status: {
                name: 'Backlog',
                values: ['4fd563bd-ab85-4378-af9f-96aa25593e91'],
                options: [
                  {
                    id: '4fd563bd-ab85-4378-af9f-96aa25593e91',
                    name: 'Backlog',
                    color: '#667784',
                    sort: 1,
                  },
                ],
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: 'ee51775b-76a3-47fc-851d-0b1cd36f6ab2',
              sort: 2,
              created_at: '2024-12-14T08:11:16.698Z',
              changed_at: '2024-12-14T08:18:37.300Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung:
                '<p>Artikel: Schritt für Schritt wie gehe ich vor</p>',
              'Ticket-ID': 'TI-0815',
              'Dateien/Bilder': [
                {
                  id: '643ef54f-05b8-4d2c-8406-bd6816c7bb5c',
                  type: 'image/jpeg',
                  name: '18455485-8dcc-4275-bc55-7f83e844061d.jpg',
                  url: 'https://files.pengueen.de/api/download/98402957-511b-4745-954d-3349fe16ce4b.jpg',
                },
              ],
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Status: {
                name: 'Backlog',
                values: ['4fd563bd-ab85-4378-af9f-96aa25593e91'],
                options: [
                  {
                    id: '4fd563bd-ab85-4378-af9f-96aa25593e91',
                    name: 'Backlog',
                    color: '#667784',
                    sort: 1,
                  },
                ],
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: '5409aca2-fa81-4a90-b5d6-8df401d891b3',
              sort: 3,
              created_at: '2024-12-14T08:17:36.163Z',
              changed_at: '2024-12-14T08:20:24.879Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              'Ticket-ID': 'TI-1234',
              Beschreibung:
                '<p>Navigation im Nachrichtencenter: "ältere laden" ist weg</p>',
              'Dateien/Bilder': [
                {
                  id: 'db1c2668-9f18-4c78-81d0-ae17d960db24',
                  type: 'image/png',
                  name: '43d2f1a5-36e7-4aed-bc42-0d5d9aea4b20.png',
                  url: 'https://files.pengueen.de/api/download/649f6ed0-2a8d-4d63-9b12-303d8fe1cbfc.png',
                },
              ],
              bearbeitet: {
                id: '841ba1e1-565e-4623-aebe-848b9a75d114',
                name: 'Hyejin Kim',
                avatar:
                  'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
              },
              Status: {
                name: 'Fehler',
                values: ['08d8c870-19ca-4edb-a9f4-eff13225fa33'],
                options: [
                  {
                    id: '08d8c870-19ca-4edb-a9f4-eff13225fa33',
                    name: 'Fehler',
                    color: '#F06A6A',
                    sort: 2,
                  },
                ],
              },
              Priorität: {
                name: 'mittel',
                values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                options: [
                  {
                    id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                    name: 'mittel',
                    color: '#FFD973',
                    sort: 2,
                  },
                ],
              },
              Epic: {
                name: 'Nachrichtencenter',
                values: ['db78ccee-926f-4751-a852-606b1e937bfe'],
                options: [
                  {
                    id: 'db78ccee-926f-4751-a852-606b1e937bfe',
                    name: 'Nachrichtencenter',
                    color: null,
                    sort: 1,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: 'b5578ef2-a90d-4003-882d-9913dcebf88c',
              sort: 4,
              created_at: '2024-12-14T08:19:51.956Z',
              changed_at: '2024-12-14T08:20:38.925Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung:
                '<p>wenn ich eine Gruppe aus dem Netzwerk als Favorit markiere, wird diese nicht auf dem Dashboard angezeigt</p>',
              'Ticket-ID': 'TI-2578',
              bearbeitet: {
                id: '841ba1e1-565e-4623-aebe-848b9a75d114',
                name: 'Hyejin Kim',
                avatar:
                  'https://files.pengueen.de/api/download/f17f0785-868a-4a9b-bd3f-5ac60318b3b6.png',
              },
              Status: {
                name: 'Fehler',
                values: ['08d8c870-19ca-4edb-a9f4-eff13225fa33'],
                options: [
                  {
                    id: '08d8c870-19ca-4edb-a9f4-eff13225fa33',
                    name: 'Fehler',
                    color: '#F06A6A',
                    sort: 2,
                  },
                ],
              },
              Epic: {
                name: 'Dashboard',
                values: ['83f84995-2008-4c26-9df7-a563d495b431'],
                options: [
                  {
                    id: '83f84995-2008-4c26-9df7-a563d495b431',
                    name: 'Dashboard',
                    color: null,
                    sort: 2,
                  },
                ],
              },
              Priorität: {
                name: 'mittel',
                values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                options: [
                  {
                    id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                    name: 'mittel',
                    color: '#FFD973',
                    sort: 2,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: 'e10c17af-39c7-47a9-ae53-ea60b176a11b',
              sort: 5,
              created_at: '2024-12-14T08:21:48.228Z',
              changed_at: '2024-12-14T08:21:48.228Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung:
                '<p>Super-Element: Liste der Elemente aus Hilfsmittelvorlage entsprechend Anordnung der Elemente sortieren (Freiburg)</p>',
              'Ticket-ID': 'TI-2579',
              bearbeitet: {
                id: 'ae4bdb65-43bb-4ad4-9810-49a28ac64a01',
                name: 'Daniel Dziamski',
                avatar:
                  'https://files.pengueen.de/api/download/97f88503-f8d6-4b9b-ae3c-9c527b79276a.jpg',
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
              Epic: {
                name: 'PfD',
                values: ['5507f61a-cdc2-42e5-92e1-e4db319cecd7'],
                options: [
                  {
                    id: '5507f61a-cdc2-42e5-92e1-e4db319cecd7',
                    name: 'PfD',
                    color: null,
                    sort: 3,
                  },
                ],
              },
              Status: {
                name: 'Neu',
                values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                options: [
                  {
                    id: '018ed124-8e70-4873-97e8-372dae5dc632',
                    name: 'Neu',
                    color: '#FFD973',
                    sort: 3,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: '225705d4-3261-4ea3-a319-1f989a710de3',
              sort: 6,
              created_at: '2024-12-14T08:22:47.852Z',
              changed_at: '2024-12-14T08:22:47.852Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung: '<p>Noch ein zu bearbeitender Punkt</p>',
              'Ticket-ID': 'TI-0816',
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Epic: {
                name: 'Epicname',
                values: ['6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1'],
                options: [
                  {
                    id: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                    name: 'Epicname',
                    color: null,
                    sort: 4,
                  },
                ],
              },
              Status: {
                name: 'Neu',
                values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                options: [
                  {
                    id: '018ed124-8e70-4873-97e8-372dae5dc632',
                    name: 'Neu',
                    color: '#FFD973',
                    sort: 3,
                  },
                ],
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: '0df8d93d-9fba-44ee-a08e-afdff73f1dc7',
              sort: 7,
              created_at: '2024-12-14T08:23:38.176Z',
              changed_at: '2024-12-14T08:23:38.176Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung: '<p>Noch ein zu bearbeitender Punkt</p>',
              'Ticket-ID': 'TI-0817',
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Epic: {
                name: 'Epicname',
                values: ['6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1'],
                options: [
                  {
                    id: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                    name: 'Epicname',
                    color: null,
                    sort: 4,
                  },
                ],
              },
              Status: {
                name: 'Neu',
                values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                options: [
                  {
                    id: '018ed124-8e70-4873-97e8-372dae5dc632',
                    name: 'Neu',
                    color: '#FFD973',
                    sort: 3,
                  },
                ],
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: 'f560e80f-49c5-4207-908d-b5ec2912896b',
              sort: 8,
              created_at: '2024-12-14T08:24:32.529Z',
              changed_at: '2024-12-14T08:24:32.529Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              'Ticket-ID': 'TI-0818',
              Beschreibung:
                '<p>Noch ein zu bearbeitender Punkt .. es fehlt eine Kopierfunktion 😉</p>',
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
              Status: {
                name: 'Neu',
                values: ['018ed124-8e70-4873-97e8-372dae5dc632'],
                options: [
                  {
                    id: '018ed124-8e70-4873-97e8-372dae5dc632',
                    name: 'Neu',
                    color: '#FFD973',
                    sort: 3,
                  },
                ],
              },
              Epic: {
                name: 'Epicname',
                values: ['6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1'],
                options: [
                  {
                    id: '6d83f722-1b8e-4ce7-ba6a-0d4e6d077aa1',
                    name: 'Epicname',
                    color: null,
                    sort: 4,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: '0a07f49a-79fa-4fbf-9c06-eff7c3815f05',
              sort: 9,
              created_at: '2024-12-14T08:25:26.681Z',
              changed_at: '2024-12-14T08:25:26.681Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              'Ticket-ID': 'TI-5711',
              Beschreibung: '<p>Marktplatz Wünsche an Sascha</p>',
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Status: {
                name: 'in Bearbeitung',
                values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                options: [
                  {
                    id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                    name: 'in Bearbeitung',
                    color: '#4998F9',
                    sort: 4,
                  },
                ],
              },
              Priorität: {
                name: 'mittel',
                values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                options: [
                  {
                    id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                    name: 'mittel',
                    color: '#FFD973',
                    sort: 2,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: '624e3d67-5a57-4483-bd3f-f390357a376e',
              sort: 10,
              created_at: '2024-12-14T08:26:26.003Z',
              changed_at: '2024-12-14T08:26:26.003Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung:
                '<p>Arbeitspaket Pengueen goes SAAS (Separate Word)</p>',
              'Ticket-ID': 'TI-0819',
              'Dateien/Bilder': [
                {
                  id: '1993f0bc-1768-48de-b298-26ae6a5a5e49',
                  type: 'image/png',
                  name: '62c51853-ad39-4683-88d3-b34ca0683c40.png',
                  url: 'https://files.pengueen.de/api/download/2c324bf8-c48f-4680-bf18-3540b6f8bc50.png',
                },
              ],
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Priorität: {
                name: 'mittel',
                values: ['27695f6a-8740-4f75-ac9e-b334d405579b'],
                options: [
                  {
                    id: '27695f6a-8740-4f75-ac9e-b334d405579b',
                    name: 'mittel',
                    color: '#FFD973',
                    sort: 2,
                  },
                ],
              },
              Status: {
                name: 'in Bearbeitung',
                values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                options: [
                  {
                    id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                    name: 'in Bearbeitung',
                    color: '#4998F9',
                    sort: 4,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: 'c99e2d2c-526a-41e5-b3c0-a946c40e17fa',
              sort: 11,
              created_at: '2024-12-14T08:27:42.058Z',
              changed_at: '2024-12-14T08:27:42.058Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              Beschreibung:
                '<p>2 Artikel: Person zu Pengueen einladen (verschiedene Wege)</p>',
              'Ticket-ID': 'TI-6711',
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Status: {
                name: 'in Bearbeitung',
                values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                options: [
                  {
                    id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                    name: 'in Bearbeitung',
                    color: '#4998F9',
                    sort: 4,
                  },
                ],
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
            },
            {
              roomId: null,
              id: '31c9ea15-6697-4363-a0ac-7474d7f91081',
              sort: 12,
              created_at: '2024-12-14T08:28:41.512Z',
              changed_at: '2024-12-14T08:28:41.512Z',
              created_by: 'Sascha Landowski',
              created_by_id: 'e133245d-2065-4aec-ba80-73dba975af01',
              changed_by: 'Sascha Landowski',
              can_edit: true,
              can_delete: true,
              'Ticket-ID': 'TI-8711',
              Beschreibung:
                '<p>2 Artikel: Personen zu Pengueen einladen (verschieden Wege)</p>',
              bearbeitet: {
                id: '957457cc-36ea-46e9-8134-c7a7be0b5772',
                name: 'Mira Hertle',
                avatar:
                  'https://files.pengueen.de/api/download/a38fa411-356a-475d-aeb5-73d70fffa9f7.jpg',
              },
              Status: {
                name: 'in Bearbeitung',
                values: ['f3a7b388-a2cb-408d-81de-90df9fe95e65'],
                options: [
                  {
                    id: 'f3a7b388-a2cb-408d-81de-90df9fe95e65',
                    name: 'in Bearbeitung',
                    color: '#4998F9',
                    sort: 4,
                  },
                ],
              },
              Priorität: {
                name: 'niedrig',
                values: ['b5d31b75-aabe-4e5b-824e-617130105750'],
                options: [
                  {
                    id: 'b5d31b75-aabe-4e5b-824e-617130105750',
                    name: 'niedrig',
                    color: '#50D09E',
                    sort: 1,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
  tool_permissions: [],
};
