import { useTranslation } from 'react-i18next';

// Models
import {
  CommunityInfo,
  CommunityItemAction,
  CommunityLicense,
  CommunitySector,
  CommunityUserRole,
} from '../models/communities.types';
import {
  BreadcrumbPath,
  MenuItem,
  Option,
} from '../../../shared/models/shared.types';
import {
  CommunityContact,
  PersonCommunity,
} from '../../user/models/user.types';

export const useCommunities = () => {
  const { t } = useTranslation();

  /**
   * Update community info in store.
   * @param communityInfo CommunityInfo
   */
  const communityInfoUpdateEffect = (communityInfo: CommunityInfo) => {
    // Get a communities copy from store
    // const storeCommunites = communities && [...communities];
    // Find matching community by id
    // const index =
    //   storeCommunites?.findIndex(
    //     community => community.data.id === communityInfo.id
    //   ) ?? -1;
    // Update info
    // if (index > -1 && storeCommunites) {
    //   storeCommunites[index] = {
    //     ...storeCommunites[index],
    //     data: {
    //       ...storeCommunites[index].data,
    //       address: communityInfo.address,
    //       name: communityInfo.name,
    //       sector: communityInfo.sector,
    //     }
    //   };
    //   // Set data in store
    //   setCommunities(storeCommunites);
    // }
  };

  /**
   * Update community license in store.
   * @param communityLicense Community license
   */
  const communityLicenseUpdateEffect = (communityLicense: CommunityLicense) => {
    // Get a communities copy from store
    // const storeCommunites = communities && [...communities];
    // Find matching community by id
    // const index =
    //   storeCommunites?.findIndex(
    //     community => community.data.id === communityLicense.id
    //   ) ?? -1;
    // Update info
    // if (index > -1 && storeCommunites) {
    //   storeCommunites[index] = {
    //     ...storeCommunites[index],
    //     data: {
    //       ...storeCommunites[index].data,
    //       license: communityLicense.license
    //     }
    //   };
    //   // Set data in store
    //   setCommunities(storeCommunites);
    // }
  };

  /**
   * Returns contact data by given community id.
   * @param communities Communities
   * @param id Community id
   * @returns Contact data
   */
  const getContactByCommunityId = (
    communities: PersonCommunity[],
    id: string
  ): CommunityContact | null => {
    const communityMatch = communities.find(
      (community) => community?.id === id
    );
    if (communityMatch?.contact) {
      return communityMatch.contact;
    }
    return null;
  };

  /**
   * Get community users breadcrumb path by community id and name, community user id, name and role and page title.
   * @param comId Community id
   * @param comName Community name
   * @param userId Community user id
   * @param username Community user name
   * @param title Page title
   * @param type Community user role
   * @returns Breadcrumb path
   */
  const getCommunityUsersBreadcrumbPath = (
    comId: string,
    comName: string,
    userId: string | undefined,
    username: string,
    title: string | null,
    type: CommunityUserRole
  ): BreadcrumbPath[] => {
    const path: BreadcrumbPath[] = [];
    if (comId) {
      path.push({
        title: t('dashboard.title') + ': ' + comName,
        location: '/dashboard',
      });
    }
    if (userId) {
      path.push({
        title,
        location: `/communities/${comId}/${type.toLowerCase()}s`,
      });
      path.push({
        title: username,
      });
    } else {
      path.push({ title });
    }
    return path;
  };

  /**
   * Get tools breadcrumb path by community id and name, tool id, name and role and page title.
   * @param comId Community id
   * @param comName Community name
   * @param tId Tool id
   * @param tName Tool name
   * @param title Page title
   * @param type Tool role
   * @returns Breadcrumb path
   */
  const getToolsBreadcrumbPath = (
    comId: string,
    comName: string,
    tId: string | undefined,
    tName: string,
    title: string | null,
    type: CommunityUserRole
  ): BreadcrumbPath[] => {
    const path: BreadcrumbPath[] = [];
    if (comId) {
      path.push({
        title: t('dashboard.title') + ': ' + comName,
        location: '/dashboard',
      });
    }
    if (tId) {
      path.push({
        title,
        location: `/communities/${comId}/tools/${type.toLowerCase()}s`,
      });
      path.push({
        title: tName,
      });
    } else {
      path.push({ title });
    }
    return path;
  };

  /**
   * Returns community sector options.
   * @returns SelectOption array
   */
  const sectorsGet = (): Option[] => {
    return [
      {
        placeholder: t('app.select'),
        value: undefined,
      },
      {
        placeholder: t('community.sectors.community'),
        value: CommunitySector.Community,
      },
      {
        placeholder: t('community.sectors.craft_business'),
        value: CommunitySector.Craft,
      },
      {
        placeholder: t('community.sectors.care_facility'),
        value: CommunitySector.Care,
      },
      {
        placeholder: t('community.sectors.school'),
        value: CommunitySector.School,
      },
      {
        placeholder: t('community.sectors.company'),
        value: CommunitySector.Company,
      },
      {
        placeholder: t('community.sectors.club'),
        value: CommunitySector.Club,
      },
      {
        placeholder: t('community.sectors.coaching'),
        value: CommunitySector.Coaching,
      },
      {
        placeholder: t('community.sectors.project'),
        value: CommunitySector.Project,
      },
      {
        placeholder: t('community.sectors.network'),
        value: CommunitySector.Network,
      },
      {
        placeholder: t('community.sectors.other'),
        value: CommunitySector.Other,
      },
    ];
  };

  /**
   * GET Community user role options.
   * @returns Community user role options.
   */
  const userRoleOptionsGet = (): Option[] => {
    return [
      {
        placeholder: t('community.users.roles.admin'),
        value: CommunityUserRole.Admin,
      },
      {
        placeholder: t('community.users.roles.employee'),
        value: CommunityUserRole.Employee,
      },
      {
        placeholder: t('community.users.roles.member'),
        value: CommunityUserRole.Member,
      },
    ];
  };

  /**
   * Returns user table action items by community user role.
   * @param role CommunityUserRole
   * @returns FlyoutMenuItem array
   */
  const userTableActionItemsGet = (role: CommunityUserRole): MenuItem[] => {
    const items: MenuItem[] = [
      {
        action: CommunityItemAction.View,
        title: t('community.table.actions.view'),
      },
      {
        action: CommunityItemAction.Message,
        title: t('community.users.table.actions.message'),
      },
    ];
    if (role === CommunityUserRole.Employee) {
      items.push({
        action: CommunityItemAction.Demote,
        title: t('community.users.table.actions.demote'),
      });
    }
    items.push({
      action: CommunityItemAction.Remove,
      title: t('community.users.table.actions.remove'),
    });

    return items;
  };

  return {
    communityInfoUpdateEffect,
    communityLicenseUpdateEffect,
    getCommunityUsersBreadcrumbPath,
    getContactByCommunityId,
    getToolsBreadcrumbPath,
    sectorsGet,
    userRoleOptionsGet,
    userTableActionItemsGet,
  };
};
