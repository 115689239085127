import React, { memo } from 'react';
import { Switch } from '../../../shared/ui/Switch/Switch';

type NavigationToggleProps = {
  isNewView?: boolean;
  toggleNewView?: (checked: boolean) => void;
};

export const NavigationToggle = memo(
  ({ isNewView, toggleNewView }: NavigationToggleProps) => {
    return (
      <Switch
        classes="pt-1 mr-8 align-middle"
        label={{
          label: 'Vorschau der neuen Oberfläche ',
          sublabel: '',
          optional: false,
        }}
        checked={isNewView ?? false}
        onChange={(checked: boolean) =>
          toggleNewView ? toggleNewView(checked) : {}
        }
      />
    );
  }
);
