import { Dispatch, memo, SetStateAction, useCallback, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Box } from '@mui/system';
import { Accordion } from '../../../shared/ui/Accordion/Accordion';
import {
  AccordionExpansionState,
  CommunityFolder,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactsGetFilters,
} from '../../../modules/contacts/models/contacts.types';
import { useContacts } from '../../../modules/contacts/hooks/use-contacts.hook';
import { CommunityUserRole } from '../../../modules/communities/models/communities.types';
import { FavoriteLinkType } from '../../../modules/dashboard/models/dashboard.types';
import { NavCommunityButton } from '../NavCommunityButton/NavCommunityButton';
import { Icon } from '../../../shared/ui/Icon/Icon';
import styles from './NavFolder.module.scss';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../../new_shared/hooks/use-responsive ';
import { faChevronUp } from '@fortawesome/pro-duotone-svg-icons';

interface NavFolderProps {
  community: ContactCommunity;
  expanded: AccordionExpansionState;
  selectedItem?: string;
  onContactsGet: (
    action: ContactCommunityGetAction,
    params: ContactsGetFilters
  ) => void;
  setExpanded: Dispatch<SetStateAction<AccordionExpansionState>>;
  setMemberRoleCommunity: Dispatch<SetStateAction<CommunityUserRole>>;
}
const NavFolder = memo(
  ({
    community,
    expanded,
    selectedItem,
    onContactsGet,
    setExpanded,
    setMemberRoleCommunity,
  }: NavFolderProps) => {
    const { t } = useTranslation();
    const { handleAccordionExpand } = useContacts();
    const { isMobile } = useResponsive();

    const onFolderClick = useCallback(
      (folder: CommunityFolder) => {
        handleAccordionExpand(
          folder.id,
          expanded,
          setExpanded,
          FavoriteLinkType.Contacts,
          isMobile,
          true
        );

        setMemberRoleCommunity(
          community.member_role ?? CommunityUserRole.Member
        );

        onContactsGet(ContactCommunityGetAction.Folder, {
          community_id_folder: folder.id,
        });
        // const contactCommunity: ContactCommunity = {
        //   id: community.id,
        //   name: community.name,
        //   selected_folder: folder,
        // };
        // setContactCommunity(contactCommunity);
      },
      [isMobile, expanded, setExpanded]
    );
    return (
      <>
        {(community.folders || []).map((folder) => {
          return (
            <Accordion
              active={selectedItem === folder.id}
              key={folder.id}
              classes="ml-2.5 py-0"
              detailsClassName={styles['filter-group-by-navCommunities']}
              detailsMarginTop="0rem"
              expanded={expanded[folder.id] ?? true}
              expandIcon={
                expanded[folder.id] ? (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    style={{
                      color: '#444D58',
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-right']}
                    style={{
                      color: '#7E8C9A',
                      fontSize: '0.75rem',
                      lineHeight: '1rem',
                    }}
                  />
                )
              }
              onSummaryClick={() => onFolderClick(folder)}
              disableTitleBold
              summaryPadding="py-0 px-1 mb-1"
              title={
                <Box className="flex flex-row items-center font-normal ml-0.5 text-xs">
                  {folder.name}
                  <Box
                    className="flex flex-row items-center "
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    <Icon
                      icon={['far', 'pen']}
                      classes="ml-2 font-light"
                      size="inherit"
                    />
                  </Box>
                </Box>
              }
            >
              {folder.groups &&
                folder.groups.map((group) => {
                  return (
                    <NavCommunityButton
                      key={group.id}
                      icon={['fal', 'users']}
                      title={
                        group.name
                          ? group.name
                          : t('community.tools.visibility.group')
                      }
                      // itemName={`${community.name}: ${
                      //   group.name
                      //     ? group.name
                      //     : t('community.tools.visibility.group')
                      // }`}
                      active={'Group' + group.id === selectedItem}
                      classes={styles['contacts-nav-community-button-title']}
                      onClick={() => {
                        onContactsGet(ContactCommunityGetAction.FolderGroup, {
                          community_id_group: group.id,
                          community_id_folder: folder.id,
                          include_me: true,
                        });
                      }}
                    />
                  );
                })}
            </Accordion>
          );
        })}
      </>
    );
  }
);

export default NavFolder;
