import { useEffect, useState } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

import {
  TemplateElement,
  TemplateElementType,
} from '../../../templates/models/templates.types';
import { Masonry } from '@mui/lab';
import { AttachmentFile } from '../../../../shared/models/shared.types';
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';

import styles from './ToolMultiElementTile.module.scss';

type SimpleGalleryProps = { images: string[]; galleryID: string };

const SimpleGallery = (props: SimpleGalleryProps) => {
  const { mdDown, lgDown, xlDown } = useBreakpoints();

  const [images, setImages] = useState<
    {
      src: string;
      width: number;
      height: number;
    }[]
  >([]);

  useEffect(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryID,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });

    lightbox.init();

    return () => {
      if (lightbox) {
        lightbox.destroy();
      }
    };
  }, [props.galleryID]);

  useEffect(() => {
    const loadImages = async () => {
      const loadedImages: { src: string; width: number; height: number }[] =
        await Promise.all(
          props.images.map(async (image) => {
            return new Promise((resolve) => {
              const img = new Image();
              img.src = image;
              img.onload = () => {
                resolve({
                  src: image,
                  width: img.width,
                  height: img.height,
                });
              };
            });
          })
        );
      setImages(loadedImages);
    };

    loadImages();
  }, [props.images]);

  return (
    <div className="gallery w-full" id={props.galleryID}>
      <Masonry columns={mdDown ? 2 : lgDown ? 3 : xlDown ? 4 : 5} spacing={0}>
        {images.map((image: any, index: number) => {
          const isPortrait = image.height > image.width;

          const adjustedWidth = isPortrait
            ? window.innerWidth * 0.8 * (image.width / image.height)
            : window.innerWidth * 0.8;
          const adjustedHeight = isPortrait
            ? window.innerWidth * 0.8
            : window.innerWidth * 0.8 * (image.height / image.width);
          return (
            <a
              className={styles['simple-gallery-image']}
              href={image.src}
              data-pswp-width={adjustedWidth}
              data-pswp-height={adjustedHeight}
              key={props.galleryID + '-' + index}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={image.src}
                alt=""
                className="w-full h-auto object-cover p-2"
              />
            </a>
          );
        })}
      </Masonry>
    </div>
  );
};

type ToolMultiElementTileProps = {
  disabled?: boolean;
  element: TemplateElement;
  sectionId: string;
  toolId: string;
  toolName: string;
  value?: any[];
  //   values?: ToolValuesMultiElementRow[]; TODO fix
  isAdmin?: boolean;
  onCancel?: () => void;
};

export const ToolMultiElementTile = (props: ToolMultiElementTileProps) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const imageNames: string[] = [];
    const imageUrls: string[] = [];

    // get image names
    props.element.elements?.forEach((ele) => {
      if (ele.type === TemplateElementType.Upload) {
        imageNames.push(ele.name);
      }
    });

    // get image urls
    imageNames.forEach((imageName) => {
      props.value?.forEach((row) => {
        const imagesToShow: AttachmentFile[] = row[imageName];
        imagesToShow?.forEach((img) => imageUrls.push(img.url));
      });
    });

    setImages(imageUrls);
  }, [props.element, props.value]);

  return (
    <div>
      <SimpleGallery galleryID="my-test-gallery" images={images} />
    </div>
  );
};
