import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import ReactQuill from 'react-quill';
import 'quill-paste-smart';
import 'react-quill/dist/quill.snow.css';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../../../shared/ui/FormFieldLabel/FormFieldLabel';

// Hooks
import { themeDark, themeLight } from '../../../shared/hooks/use-theme.hook';
import { useNewsCenter } from '../../../modules/newscenter/hooks/use-news-center.hook';

// Models
import { Theme } from '../../../shared/models/shared.types';

// Stores
import {
  SharedState,
  useSharedStore,
} from '../../../shared/stores/use-shared.store';
import useResponsive from '../../../new_shared/hooks/use-responsive ';

type ToolElementITextareaProps = {
  classes?: string;
  disabled?: boolean;
  initValue?: string;
  label?: string | TFunctionResult;
  optional?: boolean;
  sublabel?: string | TFunctionResult;
  help_text?: string;
  value?: string;
  required?: boolean;
  onCancel: (value: string) => void;
  onChange: (value: string) => void;
  onSave: (value: string) => void;
};

const ToolElementTextarea = (props: ToolElementITextareaProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();
  // const { messageHandleContent } = useNewsCenter();

  // Component state
  const [focus, setFocus] = useState<boolean>(false);
  // const [isHovered, setIsHovered] = useState(false);
  const [initValue, setInitValue] = useState<string>('');
  const [value, setValue] = useState<string>('');

  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);

  return (
    <Box className={clsx('flex flex-col', props.classes && props.classes)}>
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          optional={props.optional}
          paddingClassName="px-2"
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
      <Box
        sx={{
          '& .ql-container.ql-snow': {
            border: 'none',
          },
          '& .ql-editor': {
            padding: '10.5px',
            border: focus ? '2px' : '1px',
            borderStyle: 'solid',
            borderRadius: '6px',
            borderColor: focus ? 'primary.main' : 'border.app',
          },
          // For placeholder
          '& .ql-editor.ql-blank::before': {
            paddingLeft: '10px',
            color: !props.disabled ? '#C4C4C4' : 'text.disabled',
            fontFamily: "'Open Sans', 'sans-serif'",
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 300,
            transform: 'translateX(-8px)',
          },
          // For toolbar icon
          '& .ql-snow .ql-fill': {
            // Can't access mui theme colors directly here
            fill:
              theme === Theme.Light
                ? !props.disabled
                  ? themeLight.palette.text.secondary
                  : themeLight.palette.text.disabled
                : !props.disabled
                ? themeDark.palette.text.secondary
                : themeDark.palette.text.disabled,
          },
          // For toolbar icon
          '& .ql-snow .ql-stroke': {
            // Can't access mui theme colors directly here
            stroke:
              theme === Theme.Light
                ? !props.disabled
                  ? themeLight.palette.text.secondary
                  : themeLight.palette.text.disabled
                : !props.disabled
                ? themeDark.palette.text.secondary
                : themeDark.palette.text.disabled,
          },
        }}
      >
        <div id="editor"></div>
        <ReactQuill
          className={clsx('rounded-md', isMobile ? 'w-full' : 'w-2/3')}
          style={{ maxWidth: '600px' }}
          modules={{
            toolbar: props.disabled
              ? false
              : [
                  [
                    'bold',
                    'italic',
                    'underline',
                    { link: 'url' },
                    { list: 'ordered' },
                    { list: 'bullet' },
                  ],
                ],
          }}
          placeholder={t('tools.detail.form.input.placeholder')}
          readOnly={props.disabled}
          value={value}
          onChange={(value) => {
            setValue(value);
            props.onChange(value);
          }}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </Box>
    </Box>
  );
};

export default memo(ToolElementTextarea);
