import { TFunctionResult } from 'i18next';
import { memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { Checkbox } from '../../../shared/ui/Checkbox/Checkbox';

// Models
import { Option } from '../../../shared/models/shared.types';

type ToolElemetnCheckboxProps = {
  classes?: string;
  disabled?: boolean;
  label?: TFunctionResult | string;
  options: Option[];
  sublabel?: string;
  help_text?: string;
  value?: any[];
  required?: boolean;
  onChange: (value: any[]) => void;
};

const ToolElemetnCheckbox = (props: ToolElemetnCheckboxProps) => {
  const [value, setValue] = useState<any[]>([]);

  useEffect(() => {
    Array.isArray(props.value)
      ? setValue(props.value)
      : setValue([props.value]);
  }, [props.value]);

  /**
   * Handler on checkbox change.
   * @param checked Checked state
   * @param newValue Value
   */
  const onChange = useCallback(
    (checked: boolean, newValue: any) => {
      if (checked) {
        if (props.value) {
          const matchedPropValue = props.value.find(
            (pVal) => pVal === newValue
          );
          if (!matchedPropValue) {
            const updatedPropValue = [...props.value];
            updatedPropValue.push(newValue);
            props.onChange(updatedPropValue);
          }
        }
        if (value) {
          const matchedComponentValue = value.find((cVal) => cVal === newValue);
          if (!matchedComponentValue) {
            const updatedComponentValue = [...value];
            updatedComponentValue.push(newValue);
            setValue(updatedComponentValue);
            props.onChange(updatedComponentValue);
          }
        }
        if (value.length === 0 && !props.value) {
          const updatedComponentValue = [];
          updatedComponentValue.push(newValue);
          setValue(updatedComponentValue);
          props.onChange(updatedComponentValue);
        }
      } else {
        if (props.value) {
          const updatedPropValue = props.value?.filter(
            (pVal: any) => pVal !== newValue
          );
          props.onChange(updatedPropValue);
        }
        if (value) {
          const updatedValue = value?.filter((cVal) => cVal !== newValue);
          setValue(updatedValue);
        }
      }
    },
    [props, value]
  );

  return (
    <div
      className={clsx('flex flex-col w-full', props.classes && props.classes)}
    >
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          paddingClassName="px-2"
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
      <div className="flex flex-wrap">
        {props.options.map((option, i) => {
          return (
            <Checkbox
              key={i}
              checked={value.includes(option.value)}
              classes="ml-1 mb-1 last:mb-0"
              backgroundColor={option.color ?? ''}
              content={option.placeholder}
              disabled={props.disabled}
              onToggle={(checked) => onChange(checked, option.value)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(ToolElemetnCheckbox);
