import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Dialog as MuiDialog } from '@mui/material';
import * as yup from 'yup';
import dayjs from 'dayjs';

// Assets
import pengueen from '../../../../assets/logo/pengueen_small.png';

// Components
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { Input } from '../../../../shared/ui/Input/Input';
import { Popover } from '../../../../shared/ui/Popover/Popover';

// Hooks
import { useContactsStore } from '../../../contacts/stores/use-contacts.store';
import { useContacts } from '../../../contacts/hooks/use-contacts.hook';
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';
import { useUsersHttp } from '../../../user/hooks/use-users-http.hook';

// Models
import {
  Change,
  ContactChangeType,
  RoomChangeType,
  TemplateChangeType,
  ToolChangeType,
} from '../../models/dashboard.types';

// Stores
import { useNewsCenter } from '../../../newscenter/hooks/use-news-center.hook';

// Styles
import styles from './WidgetChanges.module.scss';

import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { useMutation } from 'react-query';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';
import {
  Alert,
  AlertAction,
  ResultState,
} from '../../../../shared/models/shared.types';
import { ContactsInvite } from '../../../contacts/components/ContactsInvite/ContactsInvite';

import {
  ActiveState,
  ConnectionType,
} from '../../../contacts/models/contacts.types';
import { useNewsCenterHttp } from '../../../newscenter/hooks/use-news-center-http.hook';
import { InfoMessageType } from '../../../newscenter/models/message.types';
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { AlertLocal } from '../../../../shared/ui/AlertLocal/AlertLocal';

type ChangeItemProps = {
  // id: string;
  fullName: string; // #TODO delete fullName
  change: Change;
};

const ChangeItem = (props: ChangeItemProps) => {
  const navigate = useNavigate();
  const { rowid } = useParams<'rowid'>();
  const { t } = useTranslation();
  const { handleRetry } = useFetch();
  const { changeReadPost } = useUsersHttp();
  const { contactDelete } = useContactsHttp();
  const { directRoomDelete, groupRoomDelete } = useNewsCenterHttp();
  const {
    onInfoMessageSubmit,
    onMessageSubmit,
    removeHtmlTags,
    translateBoolean,
  } = useNewsCenter();
  const { roomDeleteEffect } = useContacts();
  const date = `${dayjs(props.change.create_change_info.created_at).format(
    'L'
  )} ${dayjs(props.change.create_change_info.created_at).format('LT')}`;
  const { messageHandleContent } = useNewsCenter();
  // Component state
  const [input, setInput] = useState<boolean>(false);
  const [roomId, setRoomId] = useState<string | undefined>(undefined);
  const [dialogInvite, setDialogInvite] = useState<boolean>(false);
  const [showProfileImage, setShowProfileImage] = useState<boolean>(false);
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const [alertAction, setAlertAction] = useState<AlertAction | undefined>(
    undefined
  );

  // User store state
  const [changes, setChanges] = useUserStore((state: UserState) => [
    state.changes,
    state.setChanges,
  ]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // Contacts store state
  const [setSelectedContactReinvite] = useContactsStore((state) => [
    state.setSelectedContactReinvite,
  ]);

  const inputValidation = yup.object({
    message: yup.string(),
  });

  const { register, reset, watch } = useForm<{ message: string }>({
    resolver: yupResolver(inputValidation),
  });
  const messageContent = watch('message');

  // ######### //
  // MUTATIONS //
  // ######### //

  // GET changes mutation
  const changeReadMutation = useMutation((id: string) => changeReadPost(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      if (data) {
        const updatedChanges = [...changes];
        const index = updatedChanges.findIndex(
          (change) => change.id === data.change_id
        );
        if (index > -1) {
          updatedChanges.splice(index, 1);
          setChanges(updatedChanges);
        }
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
        }
      }
    },
  });

  // leave Group mutation
  const leaveGroupMutation = useMutation((id: string) => directRoomDelete(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      if (data) {
        // #TODO
        changeReadMutation.mutate(props.change.id);
        roomDeleteEffect();
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
          // #TODO
        }
      }
    },
  });

  // close Group mutation
  const closeGroupMutation = useMutation((id: string) => groupRoomDelete(id), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      if (data) {
        // #TODO

        changeReadMutation.mutate(props.change.id);
        roomDeleteEffect();
      }
      if (error) {
        const errRes = error?.response;
        if (errRes) {
          // #TODO
        }
      }
    },
  });

  const contactDeleteMutation = useMutation(
    (body: { id: string }) => contactDelete(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        changeReadMutation.mutate(props.change.id);
        if (data) {
          const userName =
            data.deletedUserName !== 'Namenlos'
              ? data.deletedUserName
              : t('contacts.delete.this_contact_big');

          setNotification({
            title: `${t('contacts.delete.notification.result.title', {
              userName: userName,
            })}`,
            state: ResultState.Success,
          });
        }
        if (error) {
          console.log('ERROR on contactDeleteMutation', error);
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    let showProfileImage = false;

    // Show profile image, if user has it
    if (
      props.change.room?.user_1_avatar ||
      props.change.tool?.user_1_avatar ||
      props.change.contact?.user_1_avatar ||
      props.change.template?.user_1_avatar
    ) {
      showProfileImage = true;
    }

    // Hide profile image according to Change Type
    if (props.change.room) {
      switch (props.change.room.change_type) {
        case RoomChangeType.NewMessage:
        case RoomChangeType.ToolNewMessage:
          showProfileImage = true;
          break;
      }
    } else if (props.change.tool) {
      showProfileImage = true;
      // switch (props.change.tool.change_type) {
      //   case ToolChangeType.MultiSeleted:
      //   case ToolChangeType.MultiRemoved:
      //   case ToolChangeType.MultiAssigned:
      //   case ToolChangeType.Selected:
      //   case ToolChangeType.RightRemoved:
      //   case ToolChangeType.RightAssigned:
      //   case ToolChangeType.Removed:
      //     showProfileImage = true;
      //     break;
      // }
    } else if (props.change.contact) {
      switch (props.change.contact.change_type) {
        case ContactChangeType.CommunityInvited:
        case ContactChangeType.AcceptedLinkCommunity:
        case ContactChangeType.AcceptedLinkAdmin:
        case ContactChangeType.AcceptedLinkEmployee:
        case ContactChangeType.AcceptedLinkMember:
          showProfileImage = true;
          break;
      }
    } else if (props.change.template) {
    }

    setShowProfileImage(showProfileImage);
  }, [props]);

  // Abort on alert submit.
  useEffect(() => {
    if (props.change.room && alertAction === AlertAction.Submit) {
      switch (props.change.room.change_type) {
        case RoomChangeType.Leave:
          leaveGroupMutation.mutate(props.change.room.id);
          break;
        case RoomChangeType.InviteMe:
          onInfoMessageSubmit(
            InfoMessageType.leave,
            undefined,
            props.change.room.id
          );
          leaveGroupMutation.mutate(props.change.room.id);
          break;
        case RoomChangeType.Close:
          closeGroupMutation.mutate(props.change.room.id);
          break;
      }
    }
    if (props.change.contact && alertAction === AlertAction.Submit) {
      switch (props.change.contact.change_type) {
        case ContactChangeType.Rejected:
          contactDeleteMutation.mutate({ id: props.change.contact!.id });
          break;
      }
    }
    // eslint-disable-next-line
  }, [alertAction, props.change]);

  // Set room id
  useEffect(() => {
    if (props.change.room) {
      setRoomId(props.change.room.id);
    } else if (props.change.contact) {
      setRoomId(props.change.contact.direct_room_id);
    }
  }, [props]);

  /**
   * Handler to answer with a short message.
   */
  // const onRoomAnswer = useCallback((formValues: { message: string }) => {
  //   if (props.change.room) {
  //     replyMessageMutation.mutate({
  //       id: props.change.room.id,
  //       message: formValues.message,
  //     });
  //   }

  //   if (props.change.contact?.direct_room_id) {
  //     replyMessageMutation.mutate({
  //       id: props.change.contact.direct_room_id,
  //       message: formValues.message,
  //     });
  //   }

  //   changeReadMutation.mutate(props.change.id);
  //   reset();

  //   // setValue(undefined);
  // }, []);

  /**
   * Handler to view change(s) in detail.
   */
  const onView = useCallback(() => {
    changeReadMutation.mutate(props.change.id);
    // eslint-disable-next-line
  }, [props]);

  /**
   * Handler to view change(s) in detail.
   */
  const onChangeClick = useCallback(() => {
    changeReadMutation.mutate(props.change.id);
    if (
      props.change.tool &&
      props.change.tool.change_type !== ToolChangeType.Delete
    ) {
      let url = '';
      if (
        props.change.tool.mfe_id !== '' &&
        props.change.tool.mfe_id !== null
      ) {
        url = `/tools/${props.change.tool.id}?mfe=${props.change.tool.row_id}&s=${props.change.tool.section_id}&e=${props.change.tool.mfe_id}&t=${props.change.tool.id}`;
      } else {
        url = `/tools/${props.change.tool.id}`;
      }
      window.open(url, '_blank');
    } else if (props.change.room) {
      if (
        props.change.room.change_type === RoomChangeType.ToolNewMessage &&
        props.change.room.tool_id
      ) {
        navigate(`/tools/${props.change.room.tool_id}/haschat`);
      } else if (
        props.change.room.change_type ===
          RoomChangeType.ToolMultiRowNewMessage &&
        props.change.room.tool_id
      ) {
        navigate(
          `/tools/${props.change.room.tool_id}/haschat/${props.change.room.row_chat_id}/row/${props.change.room.row_id}/section/${props.change.room.sectionid}/multi/${props.change.room.multiid}`
        );
      } else {
        navigate(`/newscenter/groups/${props.change.room.id}`);
      }
    } else if (props.change.template) {
      if (props.change.template.change_type !== TemplateChangeType.Delete) {
        let url = `/tools/workbench/${props.change.template.id}`;
        window.open(url, '_blank');
      }
    }
    props.change.contact &&
      props.change.contact.change_type === ContactChangeType.Accepted &&
      navigate(`/newscenter/private/${props.change.contact.direct_room_id}`);
    // eslint-disable-next-line
  }, [props]);

  const onHanldeClick = (event: {
    button: number;
    ctrlKey: any;
    preventDefault: () => void;
  }) => {
    // Überprüfen ob mittlere Maustaste oder Strg+Linksklick
    if (
      event.button === 1 ||
      (event.ctrlKey && event.button === 0) ||
      event.button === 0
    ) {
      // Verhindern des Standardverhaltens (z.B. für Linksklick)
      event.preventDefault();

      onChangeClick();
    }
  };

  /**
   * Handler to leave or close group.
   */
  const onDeleteGroup = useCallback(() => {
    switch (props.change.room?.change_type) {
      case RoomChangeType.InviteMe:
        setAlert({
          title: t('dashboard.widgets.changes.room.alert.close.title'),
          subtitle: t(
            'dashboard.widgets.changes.room.alert.invite_me.subtitle'
          ),
        });
        break;
      case RoomChangeType.Leave:
        setAlert({
          title: t('dashboard.widgets.changes.room.alert.leave.title'),
          subtitle: t('dashboard.widgets.changes.room.alert.leave.subtitle'),
        });
        break;
      case RoomChangeType.Close:
        setAlert({
          title: t('dashboard.widgets.changes.room.alert.close.title'),
          subtitle: t('dashboard.widgets.changes.room.alert.close.subtitle'),
        });
        break;
    }
  }, []);

  return (
    <Box
      className={styles['change-item']}
      sx={{ backgroundColor: 'bg.card', borderColor: 'border.app' }}
    >
      <div className={styles['change-item-main']} onMouseDown={onHanldeClick}>
        {props.change.room && (
          <>
            <div className={styles['change-item-main-image']}>
              {/* {props.change.room.messages[0]?.images && (
                <Box
                  bgcolor="bg.card"
                  // className={styles['change-item-main-image-icon']}
                >
                  <img
                    alt="message image"
                    className={styles['change-item-main-image-image']}
                    // src={props.change.room.messages[0]?.images[0].url}
                    src={stock2}
                  />
                </Box>
              )} */}
              <Box
                bgcolor="bg.card"
                className={styles['change-item-main-image-icon']}
              >
                {showProfileImage && props.change.room.user_1_avatar && (
                  <img
                    alt={
                      props.change.create_change_info.created_by.profile
                        ?.personal_data.first_name
                        ? props.change.create_change_info.created_by.profile
                            ?.personal_data.first_name
                        : 'Avatar'
                    }
                    className={styles['change-item-main-image-image']}
                    src={`https://files.pengueen.de/api/download/${props.change.room.user_1_avatar}`}
                  />
                )}
                {(!showProfileImage || !props.change.room.user_1_avatar) &&
                  props.change.room?.change_type ===
                    RoomChangeType.ToolNewMessage && (
                    <Icon
                      classes={styles['change-item-main-image-icon-profile']}
                      icon={['fal', 'message-lines']}
                      // icon={['far', 'thumbtack']}
                      sx={{
                        color: 'text.secondary',
                        height: '34px',
                        width: '34px',
                      }}
                    />
                  )}
                {!showProfileImage &&
                  props.change.room?.change_type !==
                    RoomChangeType.ToolNewMessage && (
                    <Icon
                      classes={styles['change-item-main-image-icon-profile']}
                      icon={['fal', 'hashtag']}
                      sx={{
                        color: 'text.secondary',
                        height: '34px',
                        width: '34px',
                      }}
                    />
                  )}
              </Box>
            </div>
            <Tooltip>
              <div className={styles['change-item-main-content']}>
                <div className={styles['change-item-main-content-header']}>
                  <>
                    {props.change.room.change_type ===
                      RoomChangeType.InviteMe && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.invite', {
                          user1: props.change.room.user_1_name ?? '',
                          groupName: props.change.room.message_room_name,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type ===
                      RoomChangeType.InviteOthers && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.invite_others', {
                          user1: props.change.room.user_1_name ?? '',
                          user2: props.change.room.user_2_name
                            ? JSON.parse(props.change.room.user_2_name)
                                .join(', ')
                                .toString()
                            : undefined,
                          groupName: props.change.room.message_room_name,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type === RoomChangeType.Close && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.close', {
                          user1: props.change.room.user_1_name ?? '',
                          groupName: props.change.room.message_room_name,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type ===
                      RoomChangeType.Removed && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.remove', {
                          user1: props.change.room.user_1_name ?? '',
                          user2:
                            props.change.room.user_2_name ?? t('app.nameless'),
                          groupName: props.change.room.message_room_name,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type ===
                      RoomChangeType.RemovedMe && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.remove_me', {
                          user1: props.change.room.user_1_name ?? '',
                          groupName: props.change.room.message_room_name,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type ===
                      RoomChangeType.Sticky && (
                      <div className={styles['change-item-text']}>
                        {t('dashboard.widgets.changes.room.sticky', {
                          user1:
                            props.change.room.user_1_name ??
                            `(${t('app.nameless')})`,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {(props.change.room.change_type ===
                      RoomChangeType.ToolNewMessage ||
                      props.change.room.change_type ===
                        RoomChangeType.ToolMultiRowNewMessage) && (
                      <div>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.new_message', {
                          user1: props.change.room.user_1_name ?? '',
                          toolName: props.change.room.tool_name,
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type === RoomChangeType.Leave && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.leave', {
                          user1: props.change.room.user_1_name ?? '',
                        })}{' '}
                        {date}
                      </div>
                    )}
                    {props.change.room.change_type ===
                      RoomChangeType.Mention && (
                      <div className={styles['change-item-text']}>
                        {!props.change.room.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.room.mention', {
                          user1: props.change.room.user_1_name ?? '',
                        })}{' '}
                        {date}
                      </div>
                    )}
                  </>
                </div>
                {(props.change.room.change_type ===
                  RoomChangeType.ToolNewMessage ||
                  props.change.room.change_type ===
                    RoomChangeType.ToolMultiRowNewMessage) && (
                  <Box
                    className={styles['change-item-room-content-message']}
                    sx={{ color: 'text.secondary' }}
                    dangerouslySetInnerHTML={{
                      __html: `${messageHandleContent(
                        props.change.room.messages[0]
                      )}`,
                    }}
                  />
                )}
              </div>
            </Tooltip>
          </>
        )}
        {props.change.template && (
          <>
            <div className={styles['change-item-main-image']}>
              <Box
                bgcolor="bg.card"
                className={styles['change-item-main-image-icon']}
              >
                {showProfileImage &&
                  (props.change.template.user_1_avatar ||
                    props.change.template.community?.logo?.url) && (
                    <img
                      alt={
                        props.change.create_change_info.created_by.profile
                          ?.personal_data.first_name
                          ? props.change.create_change_info.created_by.profile
                              ?.personal_data.first_name
                          : 'Avatar'
                      }
                      className={styles['change-item-main-image-image']}
                      src={`https://files.pengueen.de/api/download/${
                        props.change.template.user_1_avatar ??
                        props.change.template.community?.logo?.url
                      }`}
                    />
                  )}
                {(!showProfileImage ||
                  (!props.change.template.user_1_avatar &&
                    !props.change.template.community?.logo?.url)) && (
                  <Icon
                    classes={styles['change-item-main-image-icon-profile']}
                    icon={['fal', 'pen']}
                    sx={{
                      color: 'text.secondary',
                      height: '34px',
                      width: '34px',
                    }}
                  />
                )}
              </Box>
            </div>
            <div className={styles['change-item-main-content']}>
              <div className={styles['change-item-main-content-header']}>
                {props.change.template.change_type ===
                  TemplateChangeType.Publish && (
                  <Tooltip
                    title={`${t('dashboard.widgets.changes.template.publish', {
                      userName:
                        props.change.template.user_1_name ??
                        `(${t('app.nameless')})`,
                      toolName: props.change.template.tool_name,
                    })}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.template.publish', {
                        userName:
                          props.change.template.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.template.tool_name,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.template.change_type ===
                  TemplateChangeType.Delete && (
                  <Tooltip
                    title={`${t('dashboard.widgets.changes.template.delete', {
                      userName:
                        props.change.template.user_1_name ??
                        `(${t('app.nameless')})`,
                      toolName: props.change.template.tool_name,
                    })}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.template.delete', {
                        userName:
                          props.change.template.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.template.tool_name,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.template.change_type ===
                  TemplateChangeType.Change && (
                  <Tooltip
                    title={`${t('dashboard.widgets.changes.template.change', {
                      userName:
                        props.change.template.user_1_name ??
                        `(${t('app.nameless')})`,
                      toolName: props.change.template.tool_name,
                    })}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.template.change', {
                        userName:
                          props.change.template.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.template.tool_name,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </>
        )}
        {props.change.tool && (
          <>
            <div className={styles['change-item-main-image']}>
              <Box
                bgcolor="bg.card"
                className={styles['change-item-main-image-icon']}
              >
                {showProfileImage &&
                  (props.change.tool.user_1_avatar ||
                    props.change.tool.community?.logo?.url) && (
                    <img
                      alt={
                        props.change.create_change_info.created_by.profile
                          ?.personal_data.first_name
                          ? props.change.create_change_info.created_by.profile
                              ?.personal_data.first_name
                          : 'Avatar'
                      }
                      className={styles['change-item-main-image-image']}
                      src={`https://files.pengueen.de/api/download/${
                        props.change.tool.user_1_avatar ??
                        props.change.tool.community?.logo?.url
                      }`}
                    />
                  )}
                {(!showProfileImage ||
                  (!props.change.tool.user_1_avatar &&
                    !props.change.tool.community?.logo?.url)) && (
                  <Icon
                    classes={styles['change-item-main-image-icon-profile']}
                    icon={['fal', 'pen']}
                    sx={{
                      color: 'text.secondary',
                      height: '34px',
                      width: '34px',
                    }}
                  />
                )}
              </Box>

              {/* <img
                alt={props.change.tool.tool_name}
                className={styles['change-item-main-image-image']}
                src={props.change.tool.settings.icon?.url ?? pengueen}
              /> */}
            </div>
            <div className={styles['change-item-main-content']}>
              <div className={styles['change-item-main-content-header']}>
                {props.change.tool.change_type === ToolChangeType.Selected && (
                  <Tooltip
                    title={`${t('dashboard.widgets.changes.tool.selected', {
                      user1:
                        props.change.tool.user_1_name ??
                        `(${t('app.nameless')})`,
                      toolName: props.change.tool.tool_name,
                    })}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.selected', {
                        user1:
                          props.change.tool.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.tool.tool_name,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type === ToolChangeType.Removed && (
                  <Tooltip
                    title={`${t('dashboard.widgets.changes.tool.removed', {
                      user1:
                        props.change.tool.user_1_name ??
                        `(${t('app.nameless')})`,
                      toolName: props.change.tool.tool_name,
                    })}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.removed', {
                        user1:
                          props.change.tool.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.tool.tool_name,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.RightAssigned && (
                  <Tooltip
                    title={`${t(
                      'dashboard.widgets.changes.tool.right_assigned',
                      {
                        user1:
                          props.change.tool.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.tool.tool_name,
                        right: props.change.tool.right?.name,
                        role: props.change.tool.right?.role,
                      }
                    )}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.right_assigned', {
                        user1:
                          props.change.tool.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.tool.tool_name,
                        right: props.change.tool.right?.name,
                        role: props.change.tool.right?.role,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.RightRemoved && (
                  <Tooltip
                    title={`${t(
                      'dashboard.widgets.changes.tool.right_removed',
                      {
                        user1:
                          props.change.tool.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.tool.tool_name,
                        right: props.change.tool.right?.name,
                        role: props.change.tool.right?.role,
                      }
                    )}${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.right_removed', {
                        user1:
                          props.change.tool.user_1_name ??
                          `(${t('app.nameless')})`,
                        toolName: props.change.tool.tool_name,
                        right: props.change.tool.right?.name,
                        role: props.change.tool.right?.role,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.MultiSeleted && (
                  <Tooltip>
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.multi_seleted', {
                        rowName: props.change.tool.changed_row?.new_value,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.MultiAssigned && (
                  <Tooltip>
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.multi_assigned', {
                        rowName: props.change.tool.changed_row?.new_value,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.MultiRemoved && (
                  <Tooltip>
                    <div className={styles['change-item-text']}>
                      {t('dashboard.widgets.changes.tool.multi_removed', {
                        rowName: props.change.tool.changed_row?.new_value,
                      })}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type === ToolChangeType.MultiRowAdd &&
                  props.change.tool.count &&
                  props.change.tool.count > 1 &&
                  props.change.tool.community?.name && (
                    <Tooltip>
                      <div className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}{' '}
                          </span>
                        )}
                        {t('dashboard.widgets.changes.tool.multi_rows_add', {
                          user1: props.change.tool.user_1_name ?? '',
                          toolName: props.change.tool.tool_name,
                          tableName: props.change.tool.table_name,
                          communityName: props.change.tool.community?.name,
                        })}{' '}
                        {date}
                      </div>
                    </Tooltip>
                  )}
                {props.change.tool.change_type === ToolChangeType.MultiRowAdd &&
                  (!props.change.tool.count || props.change.tool.count < 2) &&
                  props.change.tool.community?.name && (
                    <Tooltip
                      title={`${t(
                        'dashboard.widgets.changes.tool.multi_row_add_no_community',
                        {
                          user1:
                            props.change.tool.user_1_name ?? t('app.nameless'),
                          toolName: props.change.tool.tool_name,
                          tableName: props.change.tool.table_name,
                          communityName: props.change.tool.community?.name,
                        }
                      )}${' '}
                      ${date}`}
                    >
                      <div className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}{' '}
                          </span>
                        )}
                        {t('dashboard.widgets.changes.tool.multi_row_add', {
                          user1: props.change.tool.user_1_name ?? '',
                          toolName: props.change.tool.tool_name,
                          tableName: props.change.tool.table_name,
                          communityName: props.change.tool.community?.name,
                        })}{' '}
                        {date}
                      </div>
                    </Tooltip>
                  )}
                {props.change.tool.change_type ===
                  ToolChangeType.MultiRowUpdate &&
                  props.change.tool.changed_row?.old_value &&
                  props.change.tool.changed_row?.old_value !== '' &&
                  props.change.tool.changed_row?.column_name &&
                  props.change.tool.changed_row?.multi_element_name && (
                    <Tooltip
                      title={`${t(
                        'dashboard.widgets.changes.tool.multi_row_update',
                        {
                          user1:
                            props.change.tool.user_1_name ?? t('app.nameless'),
                          toolName: props.change.tool.tool_name,
                          columnName:
                            props.change.tool.changed_row?.column_name,
                          multiElementName:
                            props.change.tool.changed_row?.multi_element_name,
                          oldValue: removeHtmlTags(
                            props.change.tool.changed_row?.old_value
                          ),
                          newValue: removeHtmlTags(
                            props.change.tool.changed_row?.new_value
                          ),
                        }
                      )}${' '}
                      ${date}`}
                    >
                      <div className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t('dashboard.widgets.changes.tool.multi_row_update', {
                          user1: props.change.tool.user_1_name ?? '',
                          toolName: props.change.tool.tool_name,
                          // communityName: props.change.tool.community?.name,
                          columnName:
                            props.change.tool.changed_row?.column_name,
                          multiElementName:
                            props.change.tool.changed_row?.multi_element_name,
                          oldValue: removeHtmlTags(
                            translateBoolean(
                              props.change.tool.changed_row?.old_value
                            )
                          ),
                          newValue: removeHtmlTags(
                            translateBoolean(
                              props.change.tool.changed_row?.new_value
                            )
                          ),
                        })}{' '}
                        {date}
                      </div>
                    </Tooltip>
                  )}
                {props.change.tool.change_type ===
                  ToolChangeType.MultiRowUpdate &&
                  !props.change.tool.changed_row?.old_value &&
                  props.change.tool.changed_row?.old_value === '' &&
                  // props.change.tool.community?.name &&
                  props.change.tool.changed_row?.column_name &&
                  props.change.tool.changed_row?.multi_element_name && (
                    <Tooltip
                      title={`${t(
                        'dashboard.widgets.changes.tool.multi_row_update_no_old_value',
                        {
                          user1: props.change.tool.user_1_name ?? '',
                          toolName: props.change.tool.tool_name,
                          // communityName: props.change.tool.community?.name,
                          columnName:
                            props.change.tool.changed_row?.column_name,
                          multiElementName:
                            props.change.tool.changed_row?.multi_element_name,
                          oldValue: removeHtmlTags(
                            translateBoolean(
                              props.change.tool.changed_row?.old_value
                            )
                          ),
                          newValue: removeHtmlTags(
                            translateBoolean(
                              props.change.tool.changed_row?.new_value
                            )
                          ),
                        }
                      )}${' '}
                      ${date}`}
                    >
                      <span className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t(
                          'dashboard.widgets.changes.tool.multi_row_update_no_old_value',
                          {
                            user1: props.change.tool.user_1_name ?? '',
                            toolName: props.change.tool.tool_name,
                            // communityName: props.change.tool.community?.name,
                            columnName:
                              props.change.tool.changed_row?.column_name,
                            multiElementName:
                              props.change.tool.changed_row?.multi_element_name,
                            oldValue: removeHtmlTags(
                              translateBoolean(
                                props.change.tool.changed_row?.old_value
                              )
                            ),
                            newValue: removeHtmlTags(
                              translateBoolean(
                                props.change.tool.changed_row?.new_value
                              )
                            ),
                          }
                        )}{' '}
                        {date}
                      </span>
                    </Tooltip>
                  )}
                {props.change.tool.change_type === ToolChangeType.MultiRowAdd &&
                  !props.change.tool.community?.name && (
                    <Tooltip
                      title={`${t(
                        'dashboard.widgets.changes.tool.multi_row_add_no_community',
                        {
                          user1:
                            props.change.tool.user_1_name ?? t('app.nameless'),
                          toolName: props.change.tool.tool_name,
                          tableName: props.change.tool.table_name,
                        }
                      )}${' '}
                      ${date}`}
                    >
                      <div className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {t(
                          'dashboard.widgets.changes.tool.multi_row_add_no_community',
                          {
                            user1: props.change.tool.user_1_name ?? '',
                            toolName: props.change.tool.tool_name,
                            tableName: props.change.tool.table_name,
                          }
                        )}{' '}
                        {date}
                      </div>
                    </Tooltip>
                  )}
                {/* #TODO delete _no_community */}
                {props.change.tool.change_type === ToolChangeType.Create && (
                  <Tooltip
                    title={`${
                      props.change.tool.community?.name
                        ? t('dashboard.widgets.changes.tool.create', {
                            user1:
                              props.change.tool.user_1_name ??
                              t('app.nameless'),
                            toolName: props.change.tool.tool_name,
                            communityName: props.change.tool.community?.name,
                          })
                        : t(
                            'dashboard.widgets.changes.tool.create_no_community',
                            {
                              user1:
                                props.change.tool.user_1_name ??
                                t('app.nameless'),
                              toolName: props.change.tool.tool_name,
                            }
                          )
                    }${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {!props.change.tool.user_1_name && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {props.change.tool.community?.name
                        ? t('dashboard.widgets.changes.tool.create', {
                            user1: props.change.tool.user_1_name ?? '',
                            toolName: props.change.tool.tool_name,
                            communityName: props.change.tool.community?.name,
                          })
                        : t(
                            'dashboard.widgets.changes.tool.create_no_community',
                            {
                              user1: props.change.tool.user_1_name ?? '',
                              toolName: props.change.tool.tool_name,
                            }
                          )}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type === ToolChangeType.Change &&
                  (!props.change.tool.count || props.change.tool.count < 2) && (
                    <Tooltip
                      title={`${
                        props.change.tool.community?.name
                          ? t('dashboard.widgets.changes.tool.change', {
                              user1:
                                props.change.tool.user_1_name ??
                                t('app.nameless'),
                              toolName: props.change.tool.tool_name ?? '',
                              communityName:
                                props.change.tool.community?.name ?? '',
                            }).replace(
                              /app.nameless/g,
                              '<i style="font-style: italic;">$&</i>'
                            )
                          : t(
                              'dashboard.widgets.changes.tool.change_no_community',
                              {
                                user1:
                                  props.change.tool.user_1_name ??
                                  t('app.nameless'),
                                toolName: props.change.tool.tool_name ?? '',
                              }
                            )
                      }${' '}
                      ${date}`}
                    >
                      <div className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {props.change.tool.community?.name
                          ? t('dashboard.widgets.changes.tool.change', {
                              user1: props.change.tool.user_1_name ?? '',
                              toolName: props.change.tool.tool_name ?? '',
                              communityName:
                                props.change.tool.community?.name ?? '',
                            }).replace(
                              /app.nameless/g,
                              '<i style="font-style: italic;">$&</i>'
                            )
                          : t(
                              'dashboard.widgets.changes.tool.change_no_community',
                              {
                                user1: props.change.tool.user_1_name ?? '',
                                toolName: props.change.tool.tool_name ?? '',
                              }
                            )}{' '}
                        {date}
                      </div>
                    </Tooltip>
                  )}
                {props.change.tool.change_type === ToolChangeType.Change &&
                  props.change.tool.count &&
                  props.change.tool.count > 1 && (
                    <Tooltip
                      title={`${
                        props.change.tool.community?.name
                          ? t(
                              'dashboard.widgets.changes.tool.change_duplicate',
                              {
                                user1:
                                  props.change.tool.user_1_name ??
                                  t('app.nameless'),
                                toolName: props.change.tool.tool_name ?? '',
                                count: props.change.tool.count ?? '',
                                communityName:
                                  props.change.tool.community?.name ?? '',
                              }
                            )
                          : t(
                              'dashboard.widgets.changes.tool.change_duplicate_no_community',
                              {
                                user1:
                                  props.change.tool.user_1_name ??
                                  t('app.nameless'),
                                toolName: props.change.tool.tool_name ?? '',
                                count: props.change.tool.count ?? '',
                              }
                            )
                      }${' '}
                      ${date}`}
                    >
                      <div className={styles['change-item-text']}>
                        {!props.change.tool.user_1_name && (
                          <span style={{ fontStyle: 'italic' }}>
                            {t('app.nameless')}
                          </span>
                        )}{' '}
                        {props.change.tool.community?.name
                          ? t(
                              'dashboard.widgets.changes.tool.change_duplicate',
                              {
                                user1: props.change.tool.user_1_name ?? '',
                                toolName: props.change.tool.tool_name ?? '',
                                count: props.change.tool.count ?? '',
                                communityName:
                                  props.change.tool.community?.name ?? '',
                              }
                            )
                          : t(
                              'dashboard.widgets.changes.tool.change_duplicate_no_community',
                              {
                                user1: props.change.tool.user_1_name ?? '',
                                toolName: props.change.tool.tool_name ?? '',
                                count: props.change.tool.count ?? '',
                              }
                            )}{' '}
                        {date}
                      </div>
                    </Tooltip>
                  )}
                {props.change.tool.change_type === ToolChangeType.Delete && (
                  <Tooltip
                    title={`${
                      props.change.tool.community?.name
                        ? t('dashboard.widgets.changes.tool.delete', {
                            user1:
                              props.change.tool.user_1_name ??
                              t('app.nameless'),
                            toolName: props.change.tool.tool_name,
                            communityName: props.change.tool.community?.name,
                          })
                        : t(
                            'dashboard.widgets.changes.tool.delete_no_community',
                            {
                              user1:
                                props.change.tool.user_1_name ??
                                t('app.nameless'),
                              toolName: props.change.tool.tool_name,
                            }
                          )
                    }${' '}
                    ${date}`}
                  >
                    <div className={styles['change-item-text']}>
                      {!props.change.tool.user_1_name && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {props.change.tool.community?.name
                        ? t('dashboard.widgets.changes.tool.delete', {
                            user1: props.change.tool.user_1_name ?? '',
                            toolName: props.change.tool.tool_name,
                            communityName: props.change.tool.community?.name,
                          })
                        : t(
                            'dashboard.widgets.changes.tool.delete_no_community',
                            {
                              user1: props.change.tool.user_1_name ?? '',
                              toolName: props.change.tool.tool_name,
                            }
                          )}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.ToolChatGroupAdd && (
                  <Tooltip
                    title={`${
                      props.change.tool.tool_name &&
                      t('dashboard.widgets.changes.tool.add_tool_chat_group', {
                        user1: props.change.tool.user_1_name,
                        toolName: props.change.tool.tool_name,
                      })
                    }${' '}
                    ${date}`}
                  >
                    <div>
                      {!props.change.tool.user_1_name && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {props.change.tool.tool_name &&
                        t(
                          'dashboard.widgets.changes.tool.add_tool_chat_group',
                          {
                            user1: props.change.tool.user_1_name,
                            toolName: props.change.tool.tool_name,
                          }
                        )}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.ToolChatGroupRemove && (
                  <Tooltip
                    title={`${
                      props.change.tool.tool_name &&
                      t(
                        'dashboard.widgets.changes.tool.remove_tool_chat_group',
                        {
                          user1: props.change.tool.user_1_name,
                          toolName: props.change.tool.tool_name,
                        }
                      )
                    }${' '}
                    ${date}`}
                  >
                    <div>
                      {!props.change.tool.user_1_name && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {props.change.tool.tool_name &&
                        t(
                          'dashboard.widgets.changes.tool.remove_tool_chat_group',
                          {
                            user1: props.change.tool.user_1_name,
                            toolName: props.change.tool.tool_name,
                          }
                        )}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
                {props.change.tool.change_type ===
                  ToolChangeType.ToolChatMultiRowAdd && (
                  <Tooltip>
                    <div>
                      {!props.change.tool.user_1_name && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {props.change.tool.tool_name &&
                        t(
                          'dashboard.widgets.changes.tool.add_tool_chat_multi_row',
                          {
                            user1: props.change.tool.user_1_name,
                            rowName: props.change.tool.changed_row?.new_value,
                          }
                        )}{' '}
                      {date}
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          </>
        )}
        {props.change.contact && (
          <>
            <div className={styles['change-item-main-image']}>
              <img
                alt={props.fullName}
                className={styles['change-item-main-image-image']}
                src={
                  showProfileImage && props.change.contact.community?.logo?.url
                    ? `https://files.pengueen.de/api/download/${props.change.contact.community?.logo?.url}`
                    : pengueen
                }
              />
            </div>
            <Tooltip>
              <div className={styles['change-item-main-content']}>
                <div className={styles['change-item-main-content-header']}>
                  {props.change.contact.change_type ===
                    ContactChangeType.Rejected && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t('dashboard.widgets.changes.contact.rejected', {
                        user1: props.fullName ?? '',
                      })}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.AcceptedLink && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t('dashboard.widgets.changes.contact.accepted_link', {
                        user1: props.fullName ?? '',
                        group:
                          props.change.contact.user_2_name ?? 'Gruppenname',
                      })}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.AcceptedLinkCommunity && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t(
                        'dashboard.widgets.changes.contact.accepted_link_community',
                        {
                          user1: props.fullName ?? '',
                          community:
                            props.change.contact.community?.name ?? 'Comminity',
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.AcceptedLinkAdmin && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t(
                        'dashboard.widgets.changes.contact.accepted_link_admin',
                        {
                          user1: props.fullName ?? '',
                          community:
                            props.change.contact.community?.name ?? 'Comminity',
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.AcceptedLinkEmployee && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t(
                        'dashboard.widgets.changes.contact.accepted_link_employee',
                        {
                          user1: props.fullName ?? '',
                          community:
                            props.change.contact.community?.name ?? 'Comminity',
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.AcceptedLinkMember && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t(
                        'dashboard.widgets.changes.contact.accepted_link_member',
                        {
                          user1: props.fullName ?? '',
                          community:
                            props.change.contact.community?.name ?? 'Comminity',
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.AcceptedLinkGroup && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t(
                        'dashboard.widgets.changes.contact.accepted_link_group',
                        {
                          user1: props.fullName ?? '',
                          community:
                            props.change.contact.community?.name ?? 'Comminity',
                          group:
                            props.change.contact.user_2_name ?? 'Gruppenname',
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.Accepted && (
                    <div className={styles['change-item-text']}>
                      {!props.fullName && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t('dashboard.widgets.changes.contact.accepted', {
                        user1: props.fullName ?? '',
                      })}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.CommunityAccepted && (
                    <div className={styles['change-item-text']}>
                      {t(
                        'dashboard.widgets.changes.contact.community_accepted',
                        {
                          user1:
                            props.change.contact.user_1_name ??
                            `(${t('app.nameless')})`,
                          user2:
                            props.change.contact.user_2_name ??
                            `(${t('app.nameless')})`,
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                  {props.change.contact.change_type ===
                    ContactChangeType.CommunityInvited && (
                    <div className={styles['change-item-text']}>
                      {!props.change.contact.user_1_name && (
                        <span style={{ fontStyle: 'italic' }}>
                          {t('app.nameless')}
                        </span>
                      )}{' '}
                      {t(
                        'dashboard.widgets.changes.contact.community_invited',
                        {
                          user1: props.change.contact.user_1_name ?? '',
                          community:
                            props.change.contact.user_2_name ??
                            t('app.nameless'),
                        }
                      )}{' '}
                      {date}
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
          </>
        )}
        <div
          className={styles['change-item-main-buttons']}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          {((props.change.room &&
            props.change.room.change_type !== RoomChangeType.Close &&
            props.change.room.change_type !== RoomChangeType.RemovedMe &&
            props.change.room.change_type !== RoomChangeType.Leave) ||
            (props.change.contact &&
              props.change.contact.change_type ===
                ContactChangeType.Accepted)) && (
            <IconButton
              icon={['fal', 'reply']}
              sxIcon={{ color: 'text.secondary' }}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setInput(!input);
              }}
            />
          )}
          {props.change.room &&
            (props.change.room.change_type === RoomChangeType.InviteMe ||
              props.change.room.change_type === RoomChangeType.Leave ||
              props.change.room.change_type === RoomChangeType.Close) && (
              <>
                <Tooltip title="Gruppe verlassen">
                  <IconButton
                    icon={['fas', 'trash']}
                    sxIcon={{ color: 'text.secondary' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      onDeleteGroup();
                    }}
                  />
                </Tooltip>
              </>
            )}

          {props.change.contact &&
            props.change.contact.change_type === ContactChangeType.Rejected && (
              <>
                <Tooltip title={t('contacts.actions.reinvite')}>
                  <IconButton
                    icon={['fal', 'arrows-repeat']}
                    sxIcon={{ color: 'text.secondary' }}
                    onClick={() => {
                      setDialogInvite(true);
                      props.change.contact?.id &&
                        setSelectedContactReinvite({
                          id: props.change.contact?.id,
                          nickname: props.fullName,
                          connection: ConnectionType.Direct,
                          state: ActiveState.Declined,
                        });
                    }}
                  />
                </Tooltip>
                <Tooltip title="Kontakt löschen">
                  <IconButton
                    icon={['fas', 'trash']}
                    sxIcon={{ color: 'text.secondary' }}
                    onClick={() => {
                      setAlert({
                        title: t(
                          'dashboard.widgets.changes.contact.delete_contact',
                          { nickname: props.fullName }
                        ),
                      });
                    }}
                  />
                </Tooltip>
              </>
            )}
          <IconButton
            icon={['fal', 'eye']}
            sxIcon={{ color: 'text.secondary' }}
            onClick={onView}
          />
        </div>
      </div>
      {input && roomId && (
        <form
          className={styles['change-item-room-content-answer']}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          // onSubmit={handleSubmit(onRoomAnswer)}
        >
          <Input
            autoFocus
            padding="0.5rem 0.75rem"
            placeholder="Schreibe eine Kurzantwort"
            preset="white"
            register={register('message')}
            // onChange={setValue}
          />
          <IconButton
            classes={styles['change-item-room-content-answer-button']}
            icon={['fas', 'paper-plane']}
            preset="primary"
            type="submit"
            onClick={() => {
              setInput(false);
              onMessageSubmit(messageContent, roomId);
              changeReadMutation.mutate(props.change.id);
              reset();
            }}
          />
        </form>
      )}
      <MuiDialog open={dialogInvite} onClose={() => setDialogInvite(false)}>
        <ContactsInvite
          contacts={[]}
          setContacts={() => {}}
          onClose={() => {
            setDialogInvite(false);
            setSelectedContactReinvite(undefined);
          }}
        />
      </MuiDialog>
      <AlertLocal alert={alert} setAlertAction={setAlertAction} />
    </Box>
  );
};

export const WidgetChangesActions = () => {
  const { t } = useTranslation();

  // Component state
  const [anchorTags, setAnchorTags] = useState<Element | undefined>(undefined);

  return (
    <Popover
      anchor={anchorTags}
      buttonTitle={t('dashboard.widgets.changes.filters.title')}
      contentTitle={t('dashboard.widgets.changes.filters.title')}
      icon={['fal', 'bars-filter']}
      onOpen={(anchor) => setAnchorTags(anchor)}
    >
      <div>...</div>
    </Popover>
  );
};

type WidgetChangesProps = {
  changes: Change[];
  communityText?: string;
  modal?: boolean;
  isLoading?: boolean;
};

export const WidgetChanges = (props: WidgetChangesProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles['widget-changes']}>
      {/* #TODO: To be removed. */}
      {/* <IconButton
        classes={styles['widget-changes-debug']}
        icon={['fas', changes.length > 0 ? 'times' : 'check']}
        preset="popover"
        onClick={() => setChanges(changes.length > 0 ? [] : props.changes)}
      /> */}
      {props.isLoading && <Loader />}
      {!props.isLoading && (
        <>
          {props.changes.length > 0 && (
            <>
              {props.changes
                .slice(
                  0,
                  props.modal
                    ? props.changes.length
                    : props.changes.length > 5
                    ? 5
                    : props.changes.length
                )
                .map((change) => {
                  let name = '';
                  if (change.tool) {
                    // #TODO delete fullNameGet
                    // name = fullNameGet(
                    //   change.create_change_info.created_by.profile?.personal_data
                    //     .first_name,
                    //   change.create_change_info.created_by.profile?.personal_data
                    //     .last_name
                    // );
                  } else if (change.room) {
                    // name = fullNameGet(
                    //   change.create_change_info.created_by.profile?.personal_data
                    //     .first_name,
                    //   change.create_change_info.created_by.profile?.personal_data
                    //     .last_name
                    // );
                  } else if (change.contact) {
                    name = change.contact.user_1_name ?? t('app.nameless');
                  }
                  return (
                    <ChangeItem
                      key={change.id}
                      change={change}
                      fullName={name}
                    />
                  );
                })}
            </>
          )}
          {props.changes.length === 0 &&
            props.communityText &&
            props.communityText !== '<p><br></p>' && (
              <CommunityText communityText={props.communityText} />
            )}
          {(props.changes.length === 0 || props.changes.length === undefined) &&
            (!props.communityText ||
              props.communityText === '<p><br></p>' ||
              props.communityText === '<p></p>') && <CommunityTextEmpty />}
        </>
      )}
    </div>
  );
};

const CommunityText = memo((props: { communityText: string }) => {
  const { messageHandleContent } = useNewsCenter();

  return (
    <>
      <Box
        sx={{
          color: 'text.primary',
          '& a': {
            color: 'text.primary',
            textDecoration: 'underline',
          },
        }}
        className={styles['widget-changes-empty-community']}
        dangerouslySetInnerHTML={{
          __html: `${messageHandleContent(props.communityText)}`,
        }}
      />
    </>
  );
});

const CommunityTextEmpty = memo(() => {
  const { t } = useTranslation();

  return (
    <div className={styles['widget-changes-empty']}>
      <Box
        className={styles['widget-changes-empty-paragraph']}
        sx={{ color: 'text.secondary' }}
      >
        {t('dashboard.widgets.changes.empty.text1')}
      </Box>
      <Box
        className={styles['widget-changes-empty-paragraph']}
        sx={{ color: 'text.secondary' }}
      >
        {t('dashboard.widgets.changes.empty.text2')}
      </Box>
      <Box
        className={styles['widget-changes-empty-paragraph']}
        sx={{ color: 'text.secondary' }}
      >
        {t('dashboard.widgets.changes.empty.text3')}
      </Box>
    </div>
  );
});
