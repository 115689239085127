import React, { ChangeEvent, useState } from 'react';
import { Box, Checkbox, Grid } from '@mui/material';
import {
  UserState,
  useUserStore,
} from '../../modules/user/stores/use-user.store';
import { UserInfoData } from '../../modules/user/models/user.types';
import { TFunctionResult } from 'i18next';
import Typography from '@mui/material/Typography';

import { InputFlat } from '../../shared/ui/InputFlat/InputFlat';
import { Switch } from '../../shared/ui/Switch/Switch';

import { Dialog } from '../../shared/ui/Dialog/Dialog';
import { Icon } from '../../shared/ui/Icon/Icon';
import { TextButton } from '../../shared/ui/TextButton/TextButton';
import { useTranslation } from 'react-i18next';
import { UpdateUserInfoType } from '../../shared/models/shared.types';

type CheckboxWithLabelProps = {
  checked?: boolean;
  disabled?: boolean;
  label: TFunctionResult | string;
  text?: string;
  onChange?: (checked: boolean) => void;
};

const CheckboxWithLabel = React.memo((props: CheckboxWithLabelProps) => {
  return (
    <Box
      className="flex flex-col"
      sx={{
        '& .MuiCheckbox-root': {
          padding: '0.25rem',
        },
      }}
    >
      <div className="flex flex-row items-center">
        <Checkbox
          checked={props.checked && props.checked}
          checkedIcon={<Icon icon={['fas', 'check-square']} size="small" />}
          color={'primary'}
          disabled={props.disabled}
          icon={<Icon icon={['fal', 'square']} size="small" />}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            props.onChange && props.onChange(event.target.checked)
          }
        />
        <Typography fontSize="1em" className="font-semibold pt-1">
          {props.label}
        </Typography>
        <Typography className="pl-2 text-xs">{props.text}</Typography>
      </div>
    </Box>
  );
});

type NotificationSettingsProps = {
  userInfoData: UserInfoData | undefined;
  handelUserNotifications: (
    type:
      | 'email'
      | 'sms'
      | 'web'
      | 'community'
      | 'tool'
      | 'group'
      | 'message'
      | 'network',
    checked: boolean
  ) => void;
  updateUserInfo: (
    type: UpdateUserInfoType,
    value: string | boolean,
    mutate?: boolean
  ) => void;
};

const NotificationSettings = ({
  userInfoData,
  handelUserNotifications,
  updateUserInfo,
}: NotificationSettingsProps) => {
  const { t } = useTranslation();
  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);

  const [accountDialog, setAccountDialog] = useState(false);
  const [phoneDialog, setPhoneDialog] = useState(false);
  const [accountEmail, setAccountEmail] = useState(userInfoData?.email);
  const [phone, setPhone] = useState(userInfoData?.phone);

  const [isValid, setIsValid] = useState(false);

  return (
    <Grid container spacing={2} className="pl-4 pt-6">
      <Grid xs={12} className="flex flex-col py-2">
        <Typography className="font-semibold text-lg">
          Benachrichtigungen
        </Typography>
        <Typography className="text-sm">
          Benachrichtigungen von Pengueen erhältst du nur über folgende Wege.
        </Typography>
        <Typography className="text-sm">
          Diese Daten sind nicht für andere einsehbar.
        </Typography>
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-5">
        <InputFlat
          label="(Account) E-Mail"
          option="ändern"
          value={userInfoData?.email}
          onOptionClick={() => setAccountDialog(true)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-1">
        <InputFlat
          label="Handy"
          option="validieren"
          type="tel"
          value={userInfoData?.phone}
          onOptionClick={() => setPhoneDialog(true)}
        />
      </Grid>
      <Grid xs={12} className="pt-4 pr-1">
        <Typography className="text-sm">
          Du entscheidest, worüber und über welchen Kanal wir dich informieren
        </Typography>
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-5">
        <Switch
          disabled
          swichFirst
          label={{
            label: 'Gemeinschaften',
            optional: false,
          }}
          checked={userInfoData?.notification?.community ?? true}
          onChange={(checked) => handelUserNotifications('community', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-1">
        <Switch
          disabled
          swichFirst
          label={{
            label: 'Tool',

            optional: false,
          }}
          checked={userInfoData?.notification?.tool ?? true}
          onChange={(checked) => handelUserNotifications('tool', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pr-5">
        <Switch
          disabled
          swichFirst
          label={{
            label: 'Gruppen',
            optional: false,
          }}
          checked={userInfoData?.notification?.group ?? true}
          onChange={(checked) => handelUserNotifications('group', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pr-1">
        <Switch
          disabled
          swichFirst
          label={{
            label: 'Nachrichten',
            optional: false,
          }}
          checked={userInfoData?.notification?.message ?? true}
          onChange={(checked) => handelUserNotifications('message', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pr-5">
        <Switch
          disabled
          swichFirst
          label={{
            label: 'Netzwerk',
            optional: false,
          }}
          checked={userInfoData?.notification?.network ?? true}
          onChange={(checked) => handelUserNotifications('community', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pr-1"></Grid>
      <Grid xs={12} md={6} className="pt-2 pr-5">
        <CheckboxWithLabel
          checked={true}
          label="In-App Benachrichtigungen"
          disabled
        />
      </Grid>
      <Grid xs={12} md={6} className="pt-2 pr-1">
        <CheckboxWithLabel
          label="Web-Push (in Entwicklung)"
          checked={userInfoData?.notification?.web || false}
          disabled
          onChange={(checked) => handelUserNotifications('web', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pr-5">
        <CheckboxWithLabel
          label="E-Mail"
          text={account?.email}
          disabled={!account?.email}
          checked={userInfoData?.notification?.email || false}
          onChange={(checked) => handelUserNotifications('email', checked)}
        />
      </Grid>
      <Grid xs={12} md={6} className="pr-1">
        <CheckboxWithLabel
          label="SMS"
          text={account?.phone}
          disabled={!account?.phone}
          checked={userInfoData?.notification?.sms || false}
          onChange={(checked) => handelUserNotifications('sms', checked)}
        />
      </Grid>
      {/********************/}
      {/*      Dialog      */}
      {/********************/}
      <Dialog
        open={accountDialog}
        onClose={() => setAccountDialog(false)}
        title="Account Email Ändern"
        classes="w-4/5 md:w-1/2 lg:w-1/3"
      >
        <InputFlat
          label="(Account) E-Mail"
          disabled
          value={accountEmail}
          onChange={(value) => setAccountEmail(value)}
        />
        <div className="w-full flex flex-row justify-end">
          <TextButton
            disabled={!isValid}
            preset="primary"
            type="submit"
            classes="mt-8"
            onClick={() =>
              accountEmail && updateUserInfo('email', accountEmail, true)
            }
          >
            {t('tools.vote.change')}
          </TextButton>
        </div>
      </Dialog>
      <Dialog
        open={phoneDialog}
        onClose={() => setPhoneDialog(false)}
        title="Handy Ändern"
        classes="w-4/5 md:w-1/2 lg:w-1/3"
      >
        <InputFlat
          label="Handy"
          type="tel"
          disabled
          onChange={(value) => setPhone(value)}
        />
        <div className="w-full flex flex-row justify-end">
          <TextButton
            disabled={!isValid}
            preset="primary"
            type="submit"
            classes="mt-8"
            onClick={() => phone && updateUserInfo('phone', phone, true)}
          >
            {t('tools.vote.change')}
          </TextButton>
        </div>
      </Dialog>
    </Grid>
  );
};

export default NotificationSettings;
