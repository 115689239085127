import {
  memo,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { TFunctionResult } from 'i18next';
import { Box, useTheme } from '@mui/material';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import dayjs from 'dayjs';

// Components
import { H2 } from '../../../../shared/ui/H2/H2';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import ImageChanger from '../../../../shared/ui/ImageChanger/ImageChanger';
import { Input } from '../../../../shared/ui/Input/Input';
import { Select } from '../../../../shared/ui/Select/Select';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { AccordionCard } from '../../../../shared/ui/AccordionCard/AccordionCard';
import { ResultBox } from '../../../../shared/ui/ResultBox/ResultBox';
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { Loader } from '../../../../shared/ui/Loader/Loader';

// Hooks
import { useUsersMock } from '../../../user/hooks/use-users-mock.hook';
import { useCommunitiesHttp } from '../../hooks/use-communities-http.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';

// Models
import {
  CommunityPostRequest,
  CommunitySector,
} from '../../models/communities.types';
import {
  ImageFallbackType,
  ResultState,
} from '../../../../shared/models/shared.types';

// Stores
import {
  CommunityState,
  useCommunityStore,
} from '../../store/use-community.store';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './CommunityCreate.module.scss';
import { useNavigate } from 'react-router-dom';
import { useContacts } from '../../../contacts/hooks/use-contacts.hook';
import { useNewsCenter } from '../../../newscenter/hooks/use-news-center.hook';
import { NavigationCommunityResponse } from '../../../user/models/user.types';
import { useCommunities } from '../../hooks/use-communities.hook';

type CommunityCreateInfoTimelineStepProps = {
  bg?: string;
  bgColor?: string;
  classes?: string;
  heightClass?: string;
  icon: [IconPrefix, IconName];
  overlay?: ReactNode;
  text: TFunctionResult;
  translateY?: string;
  title: TFunctionResult;
};

const CommunityCreateInfoTimelineStep = (
  props: CommunityCreateInfoTimelineStepProps
) => {
  return (
    <Box
      className={styles['community-create-info-timeline-step']}
      sx={{
        transform: props.translateY
          ? `translateY(${props.translateY})`
          : undefined,
      }}
    >
      <Box
        className={clsx(
          styles['community-create-info-timeline-step-bar'],
          props.classes && props.classes,
          props.heightClass
            ? props.heightClass
            : styles['community-create-info-timeline-step-bar-height']
        )}
        sx={{
          background: props.bg ?? undefined,
          backgroundColor: props.bgColor ?? undefined,
        }}
      >
        {props.overlay && props.overlay}
        <Icon
          classes={styles['community-create-info-timeline-step-bar-icon']}
          icon={props.icon}
          sx={{ color: 'white' }}
        />
      </Box>
      <div className={styles['community-create-info-timeline-step-content']}>
        <H2 marginBottom="0">{props.title}</H2>
        <div>{props.text}</div>
      </div>
    </Box>
  );
};

type CommunityCreateProps = {
  // onSubmit: () => void;
};

const CommunityCreate = (props: CommunityCreateProps) => {
  const { handleError, handleRetry } = useFetch();
  const { newContactsNavGet } = useContacts();
  const theme = useTheme();
  const { t } = useTranslation();
  const { communityLicenseSummaryMockGet } = useUsersMock();
  const { communityPost } = useCommunitiesHttp();
  const { sectorsGet } = useCommunities();
  const { newsCenterGetMutation } = useNewsCenter();
  const navigate = useNavigate();
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  // Community Store State
  const [dialogCommunityCreate, setDialogCommunityCreate] = useCommunityStore(
    (state: CommunityState) => [
      state.dialogCommunityCreate,
      state.setDialogCommunityCreate,
    ]
  );

  // User store state
  const [navigationCommunities, trial, setNavigationCommunities, setTrial] =
    useUserStore((state: UserState) => [
      state.navigationCommunities,
      state.trial,
      state.setNavigationCommunities,
      state.setTrial,
    ]);

  // React hook form validation schema
  const createValidation = yup.object({
    logo: yup.mixed(),
    name: yup.string().required(t('community.create.form.name.error')),
    sector: yup.mixed(),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<CommunityPostRequest>({
    resolver: yupResolver(createValidation),
  });
  const watchSector = watch('sector') ?? [];

  // ####### //
  // EFFECTS //
  // ####### //

  // Control submit button
  useEffect(() => {
    if (isButtonDisabled) {
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
      }, 60000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isButtonDisabled]);

  // ######### //
  // MUTATIION //
  // ######### //

  // POST Template use mutation
  const createCommunityPostMutation = useMutation(
    (data: CommunityPostRequest) => communityPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (navigationCommunities) {
            const updatedCommunities: NavigationCommunityResponse = {
              ...navigationCommunities,
            };

            if (updatedCommunities.myCommunities) {
              updatedCommunities.myCommunities.push(data);
            } else {
              updatedCommunities.myCommunities = [data];
            }

            setNavigationCommunities(updatedCommunities);
          } else {
            const newCommunities: NavigationCommunityResponse = {
              myCommunities: [],
              adminCommunities: [],
            };
            newCommunities.myCommunities.push(data);
            setNavigationCommunities(newCommunities);
          }

          navigate(`/user/community/${data.id}`);
          reset();
          newContactsNavGet();
          newsCenterGetMutation.mutate();
        }
      },
      onError: (error) => {
        const errRes = error?.response;
        if (errRes) {
          handleError(errRes.status);
        }
      },
    }
  );

  // ######### //
  // CALLBACKS //
  // ######### //

  const onCommunityCreateInit = useCallback(
    (data: CommunityPostRequest) => {
      createCommunityPostMutation.mutate(data);
      // #TODO: Submit after http post success
      onCommunityCreate();

      //handle button click
      setButtonDisabled(true);
    },
    [props]
  );

  const onCommunityCreate = useCallback(() => {
    setTrial(30);
    setDialogCommunityCreate(false);
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      paddingContent="0px"
      open={dialogCommunityCreate}
      subtitle={t('community.create.subtitle')}
      title={t('community.create.title')}
      onClose={() => setDialogCommunityCreate(false)}
    >
      <Suspense fallback={<Loader />}>
        <form
          className={styles['community-create']}
          onSubmit={handleSubmit(onCommunityCreateInit)}
        >
          <Box
            className={styles['community-create-form']}
            // className={styles['community-create-form-no_test']}
            sx={{ borderColor: 'border.app' }}
          >
            <Input
              classes="mb-4"
              label={t('community.create.form.name.label')}
              message={errors?.name && errors.name.message}
              placeholder={t('community.create.form.name.placeholder')}
              register={register('name')}
              state={errors?.name && ResultState.Error}
              widthClassName={styles['community-create-form-field-width']}
            />
            <Select
              classes="mb-8"
              label={t('community.create.form.sector')}
              multiple
              optional
              options={sectorsGet()}
              value={watchSector}
              widthClassName={styles['community-create-form-field-width']}
              onChange={(value) => setValue('sector', value)}
            />
            <ImageChanger
              type={ImageFallbackType.Community}
              label={t('community.create.form.logo')}
              optional
              onChange={(file) => setValue('logo', file)}
              onClear={() => setValue('logo', undefined)}
            />
          </Box>
          <div className={styles['community-create-info']}>
            <H2 classes="mt-0">{t('community.create.info.title')}</H2>
            <div className={styles['community-create-info-timeline']}>
              <CommunityCreateInfoTimelineStep
                bgColor="primary.main"
                classes={
                  styles['community-create-info-timeline-step-bar-start']
                }
                icon={['fal', 'unlock']}
                text={t('community.create.info.start.text')}
                title={t('community.create.info.start.title')}
              />
              <CommunityCreateInfoTimelineStep
                bgColor="primary.main"
                icon={['fal', 'bell']}
                text={t('community.create.info.notification.text')}
                title={t('community.create.info.notification.title')}
                translateY="-3px"
              />
              <CommunityCreateInfoTimelineStep
                // @ts-ignore:next-line
                bg={`linear-gradient(${theme.palette.orange.main}, ${theme.palette.background.paper})`}
                classes={styles['community-create-info-timeline-step-bar-end']}
                heightClass="h-28"
                icon={['fal', 'star']}
                overlay={
                  <Box
                    className={clsx(
                      styles['community-create-info-timeline-step-bar'],
                      styles['community-create-info-timeline-step-bar-end'],
                      styles[
                        'community-create-info-timeline-step-bar-end-overlay'
                      ]
                    )}
                    sx={{
                      backgroundColor: 'primary.main',
                      transform: 'translateY(-2px)',
                    }}
                  />
                }
                text={t('community.create.info.end.text')}
                title={t('community.create.info.end.title')}
                translateY="-6px"
              />
            </div>
            <p className={styles['community-create-info-text']}>
              {t('community.create.info.text1', {
                date: dayjs().add(1, 'month').format('L'),
                cost: communityLicenseSummaryMockGet().cost.toFixed(2),
              })}
            </p>
            <p className={styles['community-create-info-text-end']}>
              {t('community.create.info.text4')}
              <a
                href="https://www.pengueen.de/preise"
                target="_blank"
                rel="noreferrer"
              >
                <Box
                  component="span"
                  className={styles['community-create-info-text-link']}
                  style={{ display: 'inline-block' }}
                  sx={{ color: 'primary.main' }}
                >
                  {t('community.create.info.text5')}
                </Box>
              </a>
            </p>
            <TextButton
              classes={styles['community-create-info-submit']}
              disabled={isButtonDisabled}
              preset="primary"
              type="submit"
            >
              {t('community.create.title_button')}
            </TextButton>
          </div>
        </form>
      </Suspense>
    </Dialog>
  );
};

export default memo(CommunityCreate);
