import { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Theme } from '../../../shared/models/shared.types';

type LogoProps = {
  theme: Theme;
  isMobile?: boolean;
  imgClasses?: string;
};

export const Logo = memo(({ theme, isMobile, imgClasses }: LogoProps) => {
  const logoSize = isMobile ? 'small' : 'big';

  const logoSrc: string = useMemo(
    () =>
      logoSize === 'big'
        ? theme === Theme.Light
          ? require('../../../assets/logo/pengueen_beta_light.svg').default.toString()
          : require('../../../assets/logo/pengueen_beta_dark.svg').default.toString()
        : theme === Theme.Light
        ? require('../../../assets/logo/pengueen_beta_mobile_light.svg').default.toString()
        : require('../../../assets/logo/pengueen_beta_mobile_dark.svg').default.toString(),
    [theme, logoSize]
  );

  return (
    <Link to="/">
      <img alt="Pengueen Logo" src={logoSrc} className={imgClasses} />
    </Link>
  );
});
