import { ToolsTranslations } from '../models/tools.translations.interface';

export const toolsTranslationsEN: ToolsTranslations = {
  tool_user: {
    subtitle:
      'Owners and administrators of {{community_name}} can edit the content without restrictions.',
    rights_read: 'Reading rights',
    rights_read_text: 'These users see the resource in reading view',
    rights_edit: 'Editing rights',
    rights_edit_text: 'These users can edit the resource',
    rights_slots: 'Rights slots',
    rights_slots_text:
      'These users have the viewing and editing rights of the rights slot',
  },
  chat: {
    select: {
      title: 'Your tool chat',
      subtitle:
        'Use this chat to send notifications about the resource. If this is activated, all assigned users can exchange information and do not have to go to the message center.',
      info: 'This chat will also be displayed in the message center.',
    },
    group: {
      title:
        'Use this chat to send notifications about the resource. If this is activated, all assigned users can write to each other',
      subtitle:
        'Select the users with whom you would like to exchange information about this resource:',
      info: 'Existing groups in your community',
      notice:
        'Note: You have not currently assigned your resource to any community.',
    },
    room: {
      title:
        'Notifications about the resource are published here and you can talk to the people in the selected group.',
    },
  },
  footer: {
    dashboard: 'Zum Dashboard',
    workbench: 'Zur Werkbank',
    auto_save: 'Auto speichern',
    link_preview: 'Link auf Detailansicht',
    sign_up: 'Jetzt bei Pengueen anmelden und nutzen',
  },
  vote: {
    thanks: 'Thanks',
    change: 'Change',
    error: {
      star: '',
      point: 'Bitte geben Sie einen geeigneten Wert ein.',
    },
    sum: 'Summe',
    sum_total: 'Summe (gesamt) ',
  },
  display: {
    read: 'Leseansicht',
    table: 'Tabelle',
    kanban: 'Kanban',
    tile: 'Tile',
  },
  drawer: {
    footer: {
      title: 'Finde Vorlagen aus der Community',
      content:
        'Wusstest du schon, dass wir in unserem Marktplatz eine Vielzahl an Vorlagen bieten. Mache dir den Start einfach und schaue in unserem Marktplatz nach Vorlagen 🚀',
      market: 'Zum Marktplatz',
    },
  },
  required_field: {
    text: 'Bitte fülle "{{tool_name}}" vor dem Speichern aus.',
  },
  filters: {
    filterOperatorContains: 'contains',
    filterOperatorEquals: 'equals',
    filterOperatorStartsWith: 'starts with',
    filterOperatorEndsWith: 'ends with',
    filterOperatorIsEmpty: 'is empty',
    filterOperatorIsNotEmpty: 'is not empty',
    filterOperatorIsAnyOf: 'is any of',
    filterOperatorIs: 'is',
    filterOperatorNot: 'is not',
    filterOperatorAfter: 'after',
    filterOperatorOnOrAfter: 'on or after',
    filterOperatorBefore: 'before',
    filterOperatorOnOrBefore: 'on or before',
    filterValueAny: 'all',
    filterValueFalse: 'no',
    filterValueTrue: 'yes',
    filterPanelOperatorOr: 'OR',
    filterPanelOperatorAnd: 'AND',
    toolbarColumns: 'Column',
    toolbarDensity: 'Density',
    filterPanelAddFilter: 'Add filter',
    filterPanelColumns: 'Columns',
    filterPanelInputLabel: 'Value',
    filterPanelInputPlaceholder: 'Write something',
    toolbarFiltersTooltipShow: 'Show filter',
    toolbarFiltersTooltipHide: 'Hide filter',
    toolbarDensityCompact: 'Compact',
    toolbarDensityComfortable: 'Comfortable',
    columnsPanelShowAllButton: 'Show all',
    columnsPanelHideAllButton: 'Hide all',
    columnsPanelTextFieldPlaceholder: 'Column title',
    columnsPanelTextFieldLabel: 'Search Column',
    status: {
      draft: 'Entwurf',
      publish: 'Veröffentlicht',
      community: 'Für Community',
      title: 'Status',
    },
    workbench: {
      public: 'My communities',
      community: 'Pengueen Community',
      personally: 'Persönliche',
      title: 'Werkbank',
      admin: 'I as admin',
    },
    use_cases: 'Anwendungsfälle',
  },
  settings: {
    title: 'Settings',
    submit: 'Save',
    description: 'Description',
    description_placeholder: 'e.g. project summer party',
    delete_image: 'Image has been deleted.',
  },
  detail: {
    link: {
      delete: {
        subtitle: 'This private link will be deleted.',
        text: 'Now delete this private link?',
        title: 'Delete private link',
        title_menu: 'Delete',
      },
    },
    actions: {
      add: 'Teilnehmer:innen hinzufügen',
      delete: {
        subtitle:
          'Das Hilfsmittel wird gelöscht und kann nicht wieder hergestellt werden.',
        text: 'Jetzt Hilfsmittel löschen?',
        title: 'Delete tool',
        title_menu: 'Delete',
      },
      edit: 'In Werkbank bearbeiten',
      settings: 'Settings',
      share: 'Share',
    },
    elements: {
      choose: 'Choose ...',
      contact: 'Select contact',
      upload: {
        add: 'Add document',
      },
    },
    form: {
      input: {
        placeholder: 'Write something ...',
      },
    },
    multi_element: {
      add: {
        button: 'Add entry',
        title: 'New entry',
      },
      created_at: 'Created',
      created_by: 'Created by',
      changed_by: 'Changed',
      delete: {
        subtitle:
          'Der Eintrag wird gelöscht und kann nicht wieder hergestellt werden.',
        text: 'Jetzt Eintrag löschen?',
        title: 'Eintrag löschen',
      },
      download:
        'Click here to download all downloaded items of the multiple entry as .csv.',
      edit: 'Edit entry',
      onwer: {
        all: 'All',
        me: 'Me',
      },
      search: 'Search entry',
      filters: {
        title: 'New filter',
        name_save: 'What should your filter be called?',
        name_submit: 'Create filter',
      },
    },
    navigation: {
      title: 'Content',
    },
    read_edit: {
      edit: 'Bearbeiten',
      link: {
        subtitle: 'Die zugewiesenen Nutzer:innen werden entfernt.',
        title: 'Privaten Link erstellen',
      },
      read: 'Lesen',
      select: 'Nutzer:innen oder Gruppen auswählen',
    },
    slots: {
      title: 'Weitere Slots',
    },
    users: {
      permissions: {
        admin: 'Voller Zugriff',
        read: 'Kann einsehen',
        write: 'Kann bearbeiten',
      },
      remove: {
        subtitle:
          'wird aus dem Hilfsmittel entfernt und verliert seine / ihre Zugriffsrechte',
      },
      title: 'Nutzer:innen mit Zugriff',
    },
  },
  preview: {
    info: {
      created_by: 'Erstellt durch',
      share: {
        button: 'Link zum Teilen kopieren',
        notification: 'Link in die Zwischenablage kopiert.',
        text: 'Du kennst jemanden, den dieses Hilfsmittel interessieren könnte? Dann schicke ihm doch einen Link zur Vorschau und hole ihn mit in die Pengueen Community 🐧',
        title: 'Du kennst jemanden, der genau danach sucht?',
      },
      title: 'Marktplatz von Pengueen',
      text1:
        'Du befindest dich im Marktplatz von Pengueen, deinem Baukasten für Fachsoftware.',
      text2:
        'Die Digitalisierung hat uns fest im Griff. Nur, wo anfangen? Und wie? Monate lang eine Branchensoftware einführen oder doch gleich ein Jahr lang eine maßgeschneiderte Lösung entwickeln lassen? Wie nehme ich meine Mitarbeiter:Innen mit? Was sollte / möchte ich eigentlich digitalisieren?',
      text3:
        'Software etabliert keine Prozesse. Übernimmt keine Verantwortung und schafft keine Mehrwerte. Das tun Menschen. ABER eine gute Software kann – wie ein digitales Werkzeug – Fachkräfte entlasten.',
      text4:
        'Hier bieten unsere Nutzer ihre selbstgebauten digitalen Werkzeuge (Hilfsmittel) an, um dich bei deiner Arbeit zu unterstützen. ',
      text5: 'Schau dich doch mal um.',
      text6: 'Du kriegst das hin. Mit ',
      text7: 'Pengueen!',
    },
    popup: {
      buttons: {
        info: 'Mehr Informationen',
        try: {
          notification: 'Hilfsmittel hinzugefügt.',
          title: 'Jetzt ausprobieren und bei Pengueen anmelden',
          title_mobile: 'Jetzt ausprobieren',
        },
      },
      text1: 'Dies ist eine Vorschau auf das Hilfsmittel',
      text2: 'Du kannst es nutzen, indem Du Dich bei Pengueen anmeldest.',
      text3: 'Dies ist eine Vorschau auf unseren Marktplatz',
      title: 'Vorschau',
    },
  },
  title: 'Tools',
  error:
    'Sie haben keinen Zugriff auf dieses Hilfsmittel! Sie werden in 3 Sekunden auf das Dashboard geleitet.',
  error_title: 'Kein Zugriff',
};
