import { ChangeEvent, ReactNode } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Switch as MuiSwitch, useTheme } from '@mui/material';
import clsx from 'clsx';

// Styles
import styles from './Switch.module.scss';
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
type Label = {
  label: string;
  optional: boolean;
  sublabel?: string;
};

type SwitchProps = {
  checked: boolean;
  children?: ReactNode;
  label?: Label;
  classes?: string;
  disabled?: boolean;
  register?: UseFormRegisterReturn;
  swichFirst?: boolean;
  onChange: (checked: boolean) => void;
};

export const Switch = (props: SwitchProps) => {
  const theme = useTheme();
  return (
    <div className={clsx(styles['switch'], props.classes && props.classes)}>
      {props.swichFirst && (
        <MuiSwitch
          checked={props.checked}
          disabled={props.disabled}
          focusVisibleClassName='.Mui-focusVisible'
          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
            props.onChange(checked)
          }
          // disableRipple
          sx={{
            width: 36,
            height: 20,
            padding: 0,
            marginRight: 2,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: '2px',
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  backgroundColor: 'primary.main',
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '4px solid #fff',
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 16,
              height: 16,
            },
            '& .MuiSwitch-track': {
              borderRadius: 20 / 2,
              backgroundColor:
                theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          }}
          {...props.register}
        />
      )}
      {props.label && (
        <FormFieldLabel
          label={props.label.label}
          optional={props.label.optional}
          sublabel={props.label.sublabel}
        />
      )}
      {!props.swichFirst && (
        <MuiSwitch
          checked={props.checked}
          disabled={props.disabled}
          focusVisibleClassName='.Mui-focusVisible'
          onChange={(event: ChangeEvent<HTMLInputElement>, checked: boolean) =>
            props.onChange(checked)
          }
          // disableRipple
          sx={{
            width: 36,
            height: 20,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: '2px',
              transitionDuration: '300ms',
              '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                  backgroundColor: 'primary.main',
                  opacity: 1,
                  border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '4px solid #fff',
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
              },
            },
            '& .MuiSwitch-thumb': {
              boxSizing: 'border-box',
              width: 16,
              height: 16,
            },
            '& .MuiSwitch-track': {
              borderRadius: 20 / 2,
              backgroundColor:
                theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
          }}
          {...props.register}
        />
      )}
      {props.children && (
        <span className={styles['switch-children']}>{props.children}</span>
      )}
    </div>
  );
};
