import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunctionResult } from 'i18next';
import { useFilePicker } from 'use-file-picker';
import { Box, Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { IconTextButton } from '../../../../shared/ui/IconTextButton/IconTextButton';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';

// Hooks
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  AttachmentFile,
  FilePreview,
  ResultState,
} from '../../../../shared/models/shared.types';
import { TemplateElement } from '../../../templates/models/templates.types';
import {
  allowedExtensions,
  ToolElementImageDeleteRequest,
} from '../../models/tools.types';

// Styles
import styles from './ToolElementUpload.module.scss';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';

type DocumentProps = {
  file: AttachmentFile;
  small_image?: boolean;
  only_image?: boolean;
  disabled?: boolean;
  can_edit?: boolean;
  onImageDelete: (file: AttachmentFile) => void;
};

const Document = (props: DocumentProps) => {
  const { filePreviewByAttachmentFileGet } = useShared();
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  // Component state
  const [filePreview, setFilePreview] = useState<FilePreview | undefined>(
    undefined
  );

  // Set file preview data on mount
  useEffect(() => {
    props && setFilePreview(filePreviewByAttachmentFileGet(props.file));
    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      {true === props.only_image ? (
        <>
          <Box
            className={styles['document-only-image']}
            onClick={() => openInNewTab(props.file.url)}
          >
            <div className={styles['document-only-image-info']}>
              {!filePreview?.icon && (
                <Box
                  className={styles['document-only-image-info-image-container']}
                >
                  <img
                    alt={filePreview?.name}
                    className={styles['document-only-image-info-image']}
                    src={props.file.url}
                  />
                  {(!props.disabled || props.can_edit) && (
                    <IconButton
                      classes={styles['document-only-image-info-image-delete']}
                      preset="card-paper"
                      icon={['fas', 'times']}
                      onClick={(event) => {
                        //Prevent overlapping part click event
                        event.stopPropagation();
                        props.onImageDelete(props.file);
                      }}
                    />
                  )}
                </Box>
              )}
            </div>
          </Box>
        </>
      ) : (
        <>
          <Box
            className={styles['document']}
            sx={{
              padding: '0.5rem',
              borderWidth: 1,
              borderColor: 'pengueen.gray',
              borderStyle: 'solid',
              color: 'text.secondary',
            }}
            onClick={() => openInNewTab(props.file.url)}
          >
            <div className={styles['document-info']}>
              {!filePreview?.icon && (
                <img
                  alt={filePreview?.name}
                  className={styles['document-info-image']}
                  src={props.file.url}
                />
              )}
              {filePreview?.icon && !filePreview?.image && (
                <Icon
                  icon={filePreview.icon}
                  sx={{ color: 'text.secondary' }}
                />
              )}
              <div className={styles['document-info-name']}>
                {props.file.name}
              </div>
            </div>
            <div>
              <Icon
                classes={styles['document-download']}
                icon={['fas', 'download']}
              />
              <IconButton
                classes={styles['document-cancel']}
                icon={['fas', 'times']}
                preset="text.secondary"
                padding="0.25rem"
                iconSize="inherit"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  props.onImageDelete(props.file);
                }}
              />
            </div>
          </Box>
        </>
      )}
    </>
  );
};

type ToolElementUploadProps = {
  classes?: string;
  disabled?: boolean;
  multiElement?: boolean;
  label: TFunctionResult | string;
  sublabel?: string;
  help_text?: string;
  value: AttachmentFile[];
  element?: TemplateElement;
  sectionId?: string;
  width?: string;
  widthClassName?: string;
  required?: boolean;
  onlyOneImage?: boolean;
  smallImage?: boolean;
  only_image?: boolean;
  no_child?: boolean;
  onFileSelect: (file: File) => void;
  onImageDelete?: (data: ToolElementImageDeleteRequest) => void;
};

export const ToolElementUpload = (props: ToolElementUploadProps) => {
  const [openFileSelector, { plainFiles }] = useFilePicker({
    accept:
      props.only_image || props.onlyOneImage
        ? ['.jpg', '.jpeg', '.png']
        : allowedExtensions,
    multiple: false,
  });

  const { t } = useTranslation();

  // Component State
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewFiles, setPreviewFiles] = useState<AttachmentFile[]>([]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // Watch selected file changes
  useEffect(() => {
    if (props.no_child && !props.no_child) {
      if (plainFiles && plainFiles.length > 0) {
        const selectedFile = plainFiles[0];

        // Check if the selected file is allowed
        const fileExtension = selectedFile.name.slice(
          ((selectedFile.name.lastIndexOf('.') - 1) >>> 0) + 2
        );
        if (allowedExtensions.includes('.' + fileExtension)) {
          props.onFileSelect(selectedFile);

          const duplicate = selectedFiles.find(
            (file) => file.name === selectedFile.name
          );

          // Add file to selectedFiles if it is not a duplicate
          if (!duplicate) {
            selectedFiles.push(selectedFile);
            setSelectedFiles(selectedFiles);

            const updatedFiles = [...previewFiles];

            selectedFiles.forEach((file) => {
              const document: AttachmentFile = {
                id: uuidv4(),
                name: file.name,
                type: file.type,
                url: URL.createObjectURL(file),
              };

              updatedFiles.push(document);
            });

            setPreviewFiles(updatedFiles);
          }
        } else {
          setNotification({
            subtitle: 'Dieser Dateityp ist nicht erlaubt',
            title: props.element?.name,
            state: ResultState.Error,
          });
        }
      }
    } else {
      if (plainFiles && plainFiles.length > 0) {
        const newFile = plainFiles[0];

        // Check if the new file is allowed
        const fileExtension = newFile.name.slice(
          ((newFile.name.lastIndexOf('.') - 1) >>> 0) + 2
        );
        if (allowedExtensions.includes('.' + fileExtension)) {
          props.onFileSelect(newFile);
          setSelectedFiles((prevFiles) => {
            const duplicate = prevFiles.find(
              (file) => file.name === newFile.name
            );
            // Add newFile to selectedFiles if it is not a duplicate
            if (!duplicate) {
              const updatedFiles = [...prevFiles, newFile];
              updatePreviewFiles(updatedFiles);
              return updatedFiles;
            }
            return prevFiles;
          });
        } else {
          setNotification({
            subtitle: 'Dieser Dateityp ist nicht erlaubt',
            title: props.element?.name,
            state: ResultState.Error,
          });
        }
      }
    }
  }, [plainFiles]);

  useEffect(() => {
    if (props.value) {
      setPreviewFiles(props.value);
    }
  }, [props.value]);

  // Update preview files based on selected files
  const updatePreviewFiles = (files: File[]) => {
    const newPreviewFiles = files.map((file) => ({
      id: uuidv4(),
      name: file.name,
      type: file.type,
      url: URL.createObjectURL(file),
    }));
    setPreviewFiles(newPreviewFiles);
  };

  // Delete an image when there is only one image
  const onImageDelete = useCallback(
    (file: AttachmentFile) => {
      setPreviewFiles((prevFiles) =>
        prevFiles.filter((item) => item.name !== file.name)
      );

      if (props.onImageDelete) {
        props.onImageDelete({
          id: file.id,
          sectionId: props.sectionId,
          element: props.element,
        });
      }
    },
    [props]
  );

  return (
    <Box
      sx={{
        width: props.width,
      }}
      className={clsx(
        styles['tool-element-upload'],
        props.classes && props.classes,
        props.widthClassName
          ? props.widthClassName
          : props.width
          ? undefined
          : 'w-full'
      )}
    >
      <FormFieldLabel
        required={props.required}
        label={props.label}
        paddingClassName={
          styles['tool-element-upload-form-field-label-padding']
        }
        sublabel={props.sublabel}
        help_text={props.help_text}
      />
      <div
        className={clsx(
          styles['tool-element-upload-content'],
          props.onlyOneImage && styles['tool-element-upload-only-one-image']
        )}
      >
        {props.multiElement
          ? previewFiles.map((file) => {
              return (
                <Document
                  key={file.id}
                  file={file}
                  small_image={props.smallImage}
                  only_image={props.only_image}
                  onImageDelete={onImageDelete}
                  can_edit={!props.disabled}
                  disabled={props.disabled}
                />
              );
            })
          : !props.multiElement && props.value
          ? props.value.map((document) => (
              <Document
                key={document.id}
                file={document}
                small_image={props.smallImage}
                only_image={props.only_image}
                onImageDelete={onImageDelete}
              />
            ))
          : ''}

        {props.onlyOneImage && props.value?.length > 0 ? (
          ''
        ) : (
          <IconTextButton
            borderStyle="dotted"
            classes={styles['tool-element-upload-content-add']}
            disabled={props.disabled}
            icon={['fas', 'plus-circle']}
            sx={{ borderWidth: 1, borderColor: 'border.app', height: '70px' }}
            onClick={() => openFileSelector()}
          >
            {t('tools.detail.elements.upload.add')}
          </IconTextButton>
        )}
      </div>
    </Box>
  );
};
export default memo(ToolElementUpload);
