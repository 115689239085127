// Hooks
import { useFetch } from '../../../shared/hooks/use-fetch.hook';

// Models
import { Community } from '../../communities/models/communities.types';
import { FetchDataParams } from '../../../shared/models/fetch-data.types';
import {
  Attachment,
  Avatar,
  PasswordChange,
  SecurityWordData,
  Theme,
} from '../../../shared/models/shared.types';
import {
  Account,
  LanguageUpdate,
  LogoDelete,
  NavigationCommunityResponse,
  Profile,
  SecurityWordGet,
  User,
  UserInfoData,
  UserLinkChild,
} from '../models/user.types';

// Stores
import { AuthState, useAuthStore } from '../../public/stores/use-auth.store';
import { UserState, useUserStore } from '../stores/use-user.store';
import {
  Change,
  ReplyMessageRequest,
} from '../../dashboard/models/dashboard.types';

export const useUsersHttp = () => {
  const { fetchData, uploadData } = useFetch();

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);

  /**
   * Upload avatar.
   * @param avatarFile Avatar image file
   * @returns AvatarData
   */
  const avatarPost = async (
    avatarFile: File
  ): Promise<Attachment | undefined> => {
    const formData = new FormData();
    formData.append('file', avatarFile);

    if (account && accessToken && formData) {
      return await uploadData(`users/${account.id}/avatar`, {
        method: 'POST',
        formData,
      });
    }
  };

  /**
   * Get users.
   * @returns Users
   */
  const usersGet = async (
    params?: FetchDataParams
  ): Promise<Partial<User>[]> => {
    if (accessToken) {
      return await fetchData(`users`, {
        params,
      });
    }
    return [];
  };

  /**
   * Get user.
   * @returns Http response
   */
  const userGet = async (
    id: string
  ): Promise<
    | {
        account: Account;
        profile: Profile;
        communities?: Community[];
        theme?: Theme;
      }
    | undefined
  > => {
    // if (accessToken) {
    //   // Decode access token to get user id
    //   const decodedJWT: JwtPayload = jwt_decode(accessToken);
    //   return await fetchData(`users/${decodedJWT.id}`);
    if (id) {
      return await fetchData(`users/${id}`);
    }
    return undefined;
  };

  /**
   * Get user account data.
   * @returns Http response
   */
  const userAccountGet = async (): Promise<Account | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/account`);
    }
  };

  /**
   * Update user account data.
   * @param accountData Account data
   * @returns Account data
   */
  const userAccountUpdate = async (
    accountData: Account
  ): Promise<Account | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/account`, {
        method: 'PATCH',
        body: accountData,
      });
    }
  };

  /**
   * Update user language data.
   */
  const userLanguageUpdate = async (
    data: LanguageUpdate
  ): Promise<LanguageUpdate | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/account/language`, {
        method: 'PATCH',
        body: data,
      });
    }
  };

  const userEmailCheck = async (
    inputAccount: Account
  ): Promise<Account | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/email-check`, {
        method: 'POST',
        body: inputAccount,
      });
    }
  };

  /**
   * Send email for update user email .
   * @param inputAccount Account data
   * @returns Account data
   */
  const updateUserEmailPost = async (
    inputAccount: Account
  ): Promise<Account | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/account-post`, {
        method: 'PATCH',
        body: inputAccount,
      });
    }
  };

  /**
   * Update user account data.
   * @param id User token
   * @param email new email
   * @returns Account data
   */
  const updateUserEmailSave = async (data: {
    id: string;
    email: string;
    language: string;
  }): Promise<any> => {
    return await fetchData(
      `users/account-update/${data.id}/${data.email}/${data.language}`,
      { method: 'POST' }
    );
  };

  const userPasswordCheck = async (
    entPassword: string
  ): Promise<boolean | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/password-check`, {
        method: 'PATCH',
        body: entPassword,
      });
    }
  };

  /**
   * Get user communities.
   * @returns Http response
   */
  const userCommunitiesGet = async (): Promise<Community[] | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/communities`);
    }
  };

  /**
   * Update user password.
   * @param param0 Password update data
   * @returns Http response
   */
  const userPasswordUpdate = async (
    passwordUpdateData: PasswordChange
  ): Promise<void> => {
    if (account && passwordUpdateData) {
      return await fetchData(`users/${account.id}/password/change`, {
        method: 'PATCH',
        body: passwordUpdateData,
      });
    }
  };

  /**
   * Get user profile data.
   * @returns Http response
   */
  const userProfileGet = async (): Promise<Profile | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/profile`);
    }
  };

  /**
   * Update user profile data.
   * @param formData Profile data
   * @returns Updated profile data
   */
  const userProfileUpdate = async (
    formData: Profile
  ): Promise<Profile | undefined> => {
    if (account && formData) {
      return await fetchData(`users/${account.id}/profile`, {
        method: 'PATCH',
        body: formData,
      });
    }
  };

  const userProfileNavCommunities = async (): Promise<
    NavigationCommunityResponse | undefined
  > => {
    if (account) {
      return await fetchData(`users/${account.id}/nav/communities`, {
        method: 'GET',
      });
    }
  };

  /**
   * Upload logo.
   * @param avatarFile Avatar image file
   * @returns AvatarData
   */
  const logoPost = async (
    id: string | undefined,
    avatarFile: File
  ): Promise<Attachment | undefined> => {
    const formData = new FormData();
    formData.append('file', avatarFile);

    if (account && accessToken && formData) {
      return await uploadData(`communities/${id}/logo`, {
        method: 'POST',
        formData,
      });
    }
  };

  /**
   * Upload logo.
   * @param avatarFile Avatar image file
   * @returns AvatarData
   */
  const logoDelete = async (
    body: LogoDelete
  ): Promise<Attachment | undefined> => {
    return await fetchData(`communities/${body.id}/logo`, {
      method: 'DELETE',
      body: body.logo,
    });
  };

  /**
   * Update user add to community.
   * @param id User token
   * @param email new email
   * @returns Account data
   */
  const updateUserCommunitySave = async (data: {
    id: string;
    communityLink: string;
  }): Promise<any> => {
    return await fetchData(`users/community/${data.id}/${data.communityLink}`, {
      method: 'POST',
    });
  };

  /**
   * Post user security word.
   * @param data SecurityWordData
   * @returns User
   */
  const securityWordSubmit = async (
    data: SecurityWordData
  ): Promise<SecurityWordGet | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/security-word`, {
        method: 'POST',
        body: data,
      });
    }
  };

  const securityWordGet = async (): Promise<SecurityWordGet | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/security-word`);
    }
  };

  /**
   * Get user changes.
   * @returns Change[]
   */
  const userChangesGet = async (): Promise<Change[] | undefined> => {
    if (account) {
      return await fetchData(`users/${account.id}/changes`);
    }
  };

  /**
   * Post user change read.
   * @returns Change
   */
  const changeReadPost = async (
    id: string
  ): Promise<{ change_id: string } | undefined> => {
    if (account) {
      return await fetchData(`users/changes/read/${id}`, {
        method: 'POST',
      });
    }
  };

  /**
   * Post reply Message.
   * @returns Change
   */
  const replyMessage = async (
    data: ReplyMessageRequest
  ): Promise<Change | undefined> => {
    if (account) {
      return await fetchData(`users/changes/reply`, {
        method: 'POST',
        body: data,
      });
    }
  };

  /**
   * Post user theme (dark / light)
   * @returns theme
   */
  const userThemePost = async (data: {
    theme: Theme;
  }): Promise<{ theme: Theme } | undefined> => {
    if (account) {
      return await fetchData(`users/theme`, {
        method: 'POST',
        body: data,
      });
    }
  };

  /**
   * Get user theme (dark / light)
   * @returns theme
   */
  const userThemeGet = async (): Promise<{ theme: Theme } | undefined> => {
    if (account) {
      return await fetchData(`users/theme`, {
        method: 'GET',
      });
    }
  };

  /**
   * Post user logout
   * @returns void
   */
  const logoutPost = async (): Promise<any> => {
    if (account) {
      return await fetchData(`users/logout`, {
        method: 'POST',
      });
    }
  };

  const userDelete = async (id: string): Promise<any> => {
    return await fetchData(`users/delete`, {
      method: 'DELETE',
      body: { ids: [id] },
    });
  };

  const userInfoUpdate = async (data: any) => {
    return await fetchData(`users/info/update`, {
      method: 'POST',
      body: data,
    });
  };

  const userInfoGet = async (): Promise<UserInfoData> => {
    return await fetchData(`users/info/update`);
  };

  return {
    avatarPost,
    changeReadPost,
    logoDelete,
    logoPost,
    logoutPost,
    replyMessage,
    securityWordGet,
    securityWordSubmit,
    updateUserCommunitySave,
    updateUserEmailPost,
    updateUserEmailSave,
    userAccountGet,
    userAccountUpdate,
    userChangesGet,
    userCommunitiesGet,
    userDelete,
    userEmailCheck,
    userGet,
    userInfoGet,
    userInfoUpdate,
    userLanguageUpdate,
    userPasswordCheck,
    userPasswordUpdate,
    userProfileGet,
    userProfileNavCommunities,
    userProfileUpdate,
    usersGet,
    userThemeGet,
    userThemePost,
  };
};
