import React, { memo, ReactNode, useCallback, useState } from 'react';
import { Box, Button, Grid, Tab, Typography } from '@mui/material';
import clsx from 'clsx';
import styles from '../Layout/Layout.module.scss'; // Share scss files
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Icon } from '../../../shared/ui/Icon/Icon';
import { thinScroll } from '../../../shared/models/shared.types';
import { DashboardViewType } from '../../../modules/dashboard/models/dashboard.types';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { ContactCommunity } from '../../../modules/contacts/models/contacts.types';
import { useTranslation } from 'react-i18next';

type DesktopViewProps = {
  leftComponent: ReactNode;
  rightComponent1: ReactNode;
  rightComponent2: ReactNode;
  rightComponent3: ReactNode;
  rightComponentName1: string;
  rightComponentName2: string;
  selectedCommunity: ContactCommunity;
  viewType: DashboardViewType;
};

export const DesktopView = memo(
  ({
    leftComponent,
    rightComponent1,
    rightComponent2,
    rightComponent3,
    rightComponentName1,
    rightComponentName2,
    selectedCommunity,
    viewType,
  }: DesktopViewProps) => {
    const { t } = useTranslation();
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [tabValue, setTabValue] = useState(
      viewType === DashboardViewType.Community ? '2' : '1'
    );

    const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
    };

    const handleChange = useCallback(
      (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
      },
      []
    );

    const getGroupName = () => {
      const { name, id } = selectedCommunity.selected_group || {};
      const hasCustomName = name !== '';
      switch (id) {
        case 'ADMIN':
        case 'EMPLOYEE':
        case 'MEMBER':
          return hasCustomName ? name : t(`app.${id.toLowerCase()}`);
        default:
          return name;
      }
    };

    const getDashboardTitle = () => {
      const groupName = getGroupName();
      if (groupName) {
        return selectedCommunity.selected_group?.id === 'COMMUNITY' ? (
          <Typography className="font-semibold w-52 truncate">
            {selectedCommunity.name}
          </Typography>
        ) : (
          <Box className="flex flex-row w-52">
            <Typography className="font-semibold shrink-0">
              {selectedCommunity.name} /
            </Typography>
            <Typography className="ml-2 truncate">{groupName}</Typography>
          </Box>
        );
      }
      return '';
    };

    return (
      <>
        {/* Left Component (Width fixed) */}
        {sidebarOpen && (
          <Grid
            item
            className={clsx(
              styles['page-layout-grid-left'],
              'w-dashboard-nav-width' // fixed width in tailwind.config
            )}
          >
            {leftComponent}
          </Grid>
        )}

        {/* Toggle Button */}
        {sidebarOpen ? (
          <IconButtonChevronLeft
            onClick={toggleSidebar}
            left={`${360 - 24 / 2}px`} // $dashboard-nav-width - iconWidth(w-6) / 2
          />
        ) : (
          <IconButtonChevronRight onClick={toggleSidebar} left="0" />
        )}

        {/* Right Component*/}
        <Grid item xs className={styles['page-layout-grid-right']}>
          {/* Right Component 1, 2 (Width 2/3) */}
          <Grid
            item
            xs={12}
            lg={viewType === DashboardViewType.Default ? 8 : 12}
            sx={{
              maxWidth: {
                lg:
                  viewType === DashboardViewType.Default ? '600px' : undefined,
              },
              width: '100%',
            }}
          >
            <div className="w-full">
              <TabContext value={tabValue}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    color: 'text.primary',
                  }}
                >
                  <TabList onChange={handleChange} variant="standard">
                    <DashboardTitle
                      icon={
                        viewType === DashboardViewType.Default
                          ? ['far', 'grid-2']
                          : ['fal', 'users']
                      }
                      logo_url={selectedCommunity.avatar?.url}
                      title={
                        viewType === DashboardViewType.Default ? (
                          <Typography className="font-semibold w-52">
                            Dashboard
                          </Typography>
                        ) : (
                          getDashboardTitle()
                        )
                      }
                    />
                    <Tab
                      label={
                        <Box className="w-fit px-2">{rightComponentName1}</Box>
                      }
                      value="1"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label={
                        <Box className="w-fit px-2 ">{rightComponentName2}</Box>
                      }
                      value="2"
                      sx={{ textTransform: 'none' }}
                    />
                  </TabList>
                </Box>
                <TabPanel
                  value="1"
                  className="w-full h-[calc(100vh-100px)] overflow-y-auto p-0"
                  sx={{
                    ...thinScroll,
                  }}
                >
                  {rightComponent1}
                </TabPanel>
                <TabPanel
                  value="2"
                  className="w-full h-[calc(100vh-100px)] overflow-y-auto p-0"
                  sx={{
                    ...thinScroll,
                  }}
                >
                  {rightComponent2}
                </TabPanel>
              </TabContext>
            </div>
          </Grid>
          {/* Right Component 3 (Width 1/3)
          <Grid item lg={4} className={styles['page-layout-grid-right-end']}>
            {rightComponent3}
          </Grid> */}
        </Grid>
      </>
    );
  }
);

const DashboardTitle = ({
  title,
  icon,
  logo_url,
}: {
  title: ReactNode;
  icon: [IconPrefix, IconName];
  logo_url?: string;
}) => {
  return (
    <Box
      className="font-semibold flex flex-row items-center w-64 px-4 "
      sx={{ color: 'text.primary' }}
    >
      {logo_url ? (
        <img src={logo_url} className="w-6 h-6 mr-2" />
      ) : (
        <Icon icon={icon} classes="mr-2" />
      )}
      {title}
    </Box>
  );
};

export const IconButtonChevronLeft = memo(({ onClick, left, right }: any) => {
  return (
    <Button
      onClick={onClick}
      className={styles['toggle-button']}
      color="inherit"
      sx={{
        color: 'white',
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'text.primary',
        },
      }}
      style={{
        left: left,
        right: right,
      }}
    >
      <Icon color="inherit" icon={['fas', 'chevron-left']} size="inherit" />
    </Button>
  );
});

export const IconButtonChevronRight = memo(({ onClick, left, right }: any) => {
  return (
    <Button
      onClick={onClick}
      className={styles['toggle-button']}
      color="inherit"
      sx={{
        color: 'white',
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'text.primary',
        },
      }}
      style={{
        left: left,
        right: right,
      }}
    >
      <Icon color="inherit" icon={['fas', 'chevron-right']} size="inherit" />
    </Button>
  );
});
