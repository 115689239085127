import { ReactNode, memo, useEffect, useRef, useState } from 'react';
import { TFunctionResult } from 'i18next';
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

// Styles
import styles from './FormDeleteDialog.module.scss';
import { IconButton } from '../../../shared/ui/IconButton/IconButton';
import { DeleteForm, ResultState } from '../../../shared/models/shared.types';
import { InputFlat } from '../../../shared/ui/InputFlat/InputFlat';
import { TextButton } from '../../../shared/ui/TextButton/TextButton';
import { useTranslation } from 'react-i18next';
import delete_request_background from '../../../assets/pictures/delete_request_background.png';
import {
  UserState,
  useUserStore,
} from '../../../modules/user/stores/use-user.store';
import { DeleteInfo } from '../../../modules/user/models/user.types';

type FormDeleteDialogProps = {
  classes?: string;
  disableDividers?: boolean;
  open: boolean;
  title?: string | TFunctionResult | ReactNode;
  infoText?: string | TFunctionResult | ReactNode;
  width?: string;
  widthClassName?: string;
  description?: string | TFunctionResult | ReactNode;
  textButton?: string;
  textMatch?: RegExp;
  hideInputText?: boolean;
  whichDelete?: DeleteForm;
  deleteInformation?: DeleteInfo;
  onClose: () => void;
  onSubmit: () => void;
};

type FormDeleteValues = {
  delete: string;
};

export const FormDeleteDialog = memo((props: FormDeleteDialogProps) => {
  // Component refs
  const contentRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profile] = useUserStore((state: UserState) => [state.profile]);

  // Focus content on open
  useEffect(() => {
    if (props.open) {
      const { current: content } = contentRef;
      if (content !== null) {
        content.focus();
      }
    }
  }, [props.open]);

  const { t } = useTranslation();

  // React hook form validation schema
  const formDeleteValidation = yup.object({
    delete: yup
      .string()
      .matches(
        props.textMatch ?? t('form.common.errors.matches.delete'),
        t('form.common.errors.matches.response')
      )
      .min(
        props.textMatch
          ? props.textMatch.toString().length
          : t('form.common.errors.matches.delete').length,
        t('form.common.errors.matches.response')
      )
      .max(
        props.textMatch
          ? props.textMatch.toString().length
          : t('form.common.errors.matches.delete').length,
        t('form.common.errors.matches.response')
      )
      .required(t('form.common.errors.matches.response')),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<FormDeleteValues>({
    resolver: yupResolver(formDeleteValidation),
  });

  // Make input valid if props.hideInputText is true
  useEffect(() => {
    props.hideInputText &&
      !props.textMatch &&
      setValue('delete', t('form.common.errors.matches.delete'));
  }, [props]);

  useEffect(() => {
    if (props.open === false) {
      setIsLoading(false);
    }
  }, [props.open]);

  return (
    <MuiDialog
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      open={props.open}
      classes={{
        paper: clsx(
          styles['dialog'],
          props.classes && props.classes,
          props.widthClassName
            ? props.widthClassName
            : props.width
            ? undefined
            : styles['dialog-width']
        ),
      }}
      scroll='body'
      onClose={props.onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: 'none',
          margin: 0,
          padding: 0,
          maxWidth: '600px !important',
          //   width,
          width: props.width,
        },
      }}
      // className={clsx(props.classes && props.classes)}
    >
      <IconButton
        classes={styles['dialog-title-close']}
        icon={['fas', 'times']}
        onClick={props.onClose}
      />
      <Box
        component='img'
        className='absolute right-4 top-[46px]'
        src={delete_request_background}
      />
      <DialogTitle
        sx={{
          padding: '1.5rem',
          paddingTop: '2rem',
          height: '120px',
        }}
        className={styles['dialog-title']}
      >
        <div className={styles['dialog-title-container']}>
          <Box
            className='font-semibold text-2xl'
            sx={{ color: 'primary.main' }}
          >
            Hallo {profile?.personal_data?.first_name},
          </Box>
          {props.title && <Box className='text-lg'>{props.title}</Box>}
        </div>
        <Box className={styles['dialog-title-placeholder']}></Box>
      </DialogTitle>

      <Box
        className={styles['form-delete-text']}
        sx={{ bgcolor: '#8bc8f5', minHeight: '120px' }}
      >
        {DeleteForm.Useraccount === props.whichDelete && (
          <>
            <AccountDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}
        {DeleteForm.Tool === props.whichDelete && (
          <>
            <ToolDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}
        {DeleteForm.Template === props.whichDelete && (
          <>
            <TemplateDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}

        {DeleteForm.RemovePersonGroup === props.whichDelete && (
          <>
            <GroupPersonDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}

        {DeleteForm.Section === props.whichDelete && (
          <>
            <TemplateSectionDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}

        {DeleteForm.Element === props.whichDelete && (
          <>
            <TemplateElementDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}
        {DeleteForm.Group === props.whichDelete && (
          <>
            <GroupDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}
        {DeleteForm.Mfe === props.whichDelete && (
          <>
            <MfeDeleteDescription deleteInformation={props.deleteInformation} />
          </>
        )}
        {DeleteForm.Community === props.whichDelete && (
          <>
            <CommunityDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}
        {DeleteForm.RemovePersonCommunity === props.whichDelete && (
          <>
            <RemovePersonCommunityDeleteDescription
              deleteInformation={props.deleteInformation}
            />
          </>
        )}
      </Box>

      {DeleteForm.Useraccount === props.whichDelete && (
        <>
          <Box className='text-xs px-6 pt-2'>
            <AccountDeleteInfoText
              deleteInformation={props.deleteInformation}
            />
          </Box>
        </>
      )}

      <DialogContent
        className={styles['dialog-content']}
        sx={{
          padding: '1.5rem',
        }}
      >
        <Box ref={contentRef}>
          <form
            onSubmit={handleSubmit(props.onSubmit)}
            className={styles['group-create-edit-common']}
          >
            <Box className='font-semibold'>
              Dieser Vorgang kann nicht rückgängig gemacht werden.
            </Box>

            {!props.hideInputText && (
              <>
                <Box className={styles['form-delete-label']}>
                  <Box className={styles['form-delete-label-text']}>
                    Bitte bestätige das Löschen durch Eingabe des Wortes
                    {/* {t('form.common.errors.matches.text1')} */}
                  </Box>
                  <Box className={styles['form-delete-label-match']}>
                    {props.textMatch ?? t('form.common.errors.matches.delete')}
                  </Box>
                  <Box>.</Box>
                </Box>
                <Box className={styles['form-delete-input']}>
                  <InputFlat
                    placeholder={t('tools.detail.form.input.placeholder')}
                    message={errors?.delete && errors.delete.message}
                    register={register('delete')}
                    state={errors?.delete && ResultState.Error}
                  />
                </Box>
              </>
            )}
            <Box className={styles['form-delete-buttons']}>
              <TextButton
                classes={styles['form-delete-buttons-cancel']}
                preset='underline'
                textTransform='none'
                onClick={props.onClose}
              >
                {t('app.cancel')}
              </TextButton>
              <TextButton
                preset='primary'
                // disabled={!!Object.keys(errors).length}
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  props.onSubmit?.();
                }}
                type='submit'
              >
                {props.textButton ?? t('app.actions.delete')}
              </TextButton>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </MuiDialog>
  );
});

type DeleteInfoProps = {
  deleteInformation?: DeleteInfo;
};
const AccountDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn du deinen Account löschst, werden folgende Abhängigkeiten mit
          entfernt bzw. Bestandteile gelöscht:
        </Box>
        {props.deleteInformation &&
          props.deleteInformation.created_communities > 0 && (
            <>
              <Box className='font-semibold mt-3 text-base'>
                Deine {props.deleteInformation.created_communities}{' '}
                Unternehmungen:
              </Box>
            </>
          )}

        <Box className='pl-4 font-normal'>
          {props.deleteInformation &&
            props.deleteInformation.created_communities_templates > 0 && (
              <>
                <Box>
                  - deren{' '}
                  {props.deleteInformation.created_communities_templates}{' '}
                  Tool-Vorlagen
                </Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.created_communities_templates_tools > 0 && (
              <>
                <Box>
                  -{' '}
                  {props.deleteInformation.created_communities_templates_tools}{' '}
                  angelegte Tools
                </Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.created_communities_groups > 0 && (
              <>
                {' '}
                <Box>
                  - {props.deleteInformation.created_communities_groups}{' '}
                  Gruppen/Teams werden gelöscht
                </Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.created_communities_groups > 0 && (
              <>
                {' '}
                <Box>
                  - die Nachrichten von{' '}
                  {props.deleteInformation.created_communities_groups}{' '}
                  Gruppen/Teams
                </Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.connected_users > 0 && (
              <>
                <Box>
                  - {props.deleteInformation.connected_users} Verbindungen zu
                  anderen Personen
                </Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.invited_users > 0 && (
              <>
                {' '}
                <Box>
                  - {props.deleteInformation.invited_users} eingeladene Kontakte
                  werden entfernt
                </Box>
              </>
            )}
          <Box></Box>
        </Box>
        <Box className='font-semibold mt-3 text-base'>
          Darüber hinaus werden:
        </Box>
        <Box className='pl-4 font-normal text'>
          {props.deleteInformation &&
            props.deleteInformation.groups_participant > 0 && (
              <>
                <Box>
                  - deine Nachrichten in{' '}
                  {props.deleteInformation.groups_participant} Gruppen gelöscht
                </Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.user_tools > 0 && (
              <>
                {' '}
                <Box>- {props.deleteInformation.user_tools} Tools gelöscht</Box>
              </>
            )}
          {props.deleteInformation &&
            props.deleteInformation.connected_communities > 0 && (
              <>
                {' '}
                <Box>
                  - deine Verbindungen zu{' '}
                  {props.deleteInformation.connected_communities} Unternehmungen
                  entfernt
                  <Box component='sup' sx={{ fontSize: '8px !important' }}>
                    2
                  </Box>
                </Box>
              </>
            )}
        </Box>
      </Box>
    </>
  );
};

const AccountDeleteInfoText = (props: DeleteInfoProps) => {
  return (
    <>
      {props.deleteInformation &&
        props.deleteInformation.connected_communities > 0 && (
          <>
            {' '}
            <Box sx={{ fontSize: '12px' }}>
              <Box
                component='sup'
                sx={{ fontSize: '8px !important', marginRight: '1px' }}
              >
                2
              </Box>
              Bitte beachte, dass die von dir eingegebenen Daten und Nachrichten
              im Zuge der Verbindung mit einer Unternehmung anonymisiert werden
              aber erhalten bleiben.
            </Box>
          </>
        )}
    </>
  );
};

const ToolDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>Wenn Du dieses Tool löschst,</Box>

        <Box className='font-semibold mt-3 text-base'>
          - verlieren mehrere Personen den Zugriff auf dieses Tool
        </Box>
        <Box className='font-semibold mt-3 text-base'>
          - werden alle Daten gelöscht
        </Box>
      </Box>
    </>
  );
};

const TemplateDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn Du diese Vorlage löschst, werden
        </Box>
        <Box className='pl-4 font-normal'>
          <Box>
            - {props.deleteInformation?.created_communities_templates_tools}{' '}
            angelegte Tools gelöscht
          </Box>
          <Box>- haben mehrere Personen keinen Zugriff mehr</Box>
        </Box>
      </Box>
    </>
  );
};

const TemplateElementDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn Du dieses Element löschst, wirkt sich das auf
        </Box>

        <Box className='font-normal mt-3 text-base'>
          - {props.deleteInformation?.created_communities_templates_tools}{' '}
          angelegte Tools aus
        </Box>
      </Box>
    </>
  );
};

const TemplateOptionDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn Du diese Option löschst, wirkt sich das auf
        </Box>

        <Box className='font-normal mt-3 text-base'>
          - {props.deleteInformation?.created_communities_templates_tools}{' '}
          angelegte Tools aus
        </Box>
      </Box>
    </>
  );
};

const TemplateSectionDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn Du diesen Abschnitt löschst, wirkt sich das auf
        </Box>

        <Box className='font-normal mt-3 text-base'>
          - {props.deleteInformation?.created_communities_templates_tools}{' '}
          angelegte Tools aus
        </Box>
      </Box>
    </>
  );
};

const GroupPersonDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>Wenn Du die Person entfernst,</Box>
        <Box className='pl-4 font-normal'>
          <Box>
            - werden {props.deleteInformation?.created_communities_groups_msgs}{' '}
            Nachrichten der Person gelöscht
          </Box>
          <Box>
            - verliert die Person Zugriff auf{' '}
            {props.deleteInformation?.user_tools} Tools
          </Box>
          <Box>
            - bleibt die Person weiterhin mit deiner Unternehmung verbunden
          </Box>
        </Box>
      </Box>
    </>
  );
};

const GroupDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn du diese Gruppe löschst, werden:
        </Box>
        <Box className='pl-4 font-normal'>
          <Box>
            - {props.deleteInformation?.created_communities_groups_msgs}{' '}
            Nachrichten gelöscht
          </Box>
          <Box>
            - {props.deleteInformation?.user_tools} Tools und deren Inhalte
            gelöscht
          </Box>
          <Box>
            - die {props.deleteInformation?.connected_users} Mitglieder
            verlieren gegebenfalls den Zugriff auf ihre Daten
          </Box>
        </Box>
      </Box>
    </>
  );
};

const MfeDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn du diesen Eintrag löschst, werden:
        </Box>
        <Box className='pl-4 font-normal'>
          <Box>
            - {props.deleteInformation?.created_communities_groups_msgs}{' '}
            Kommentare gelöscht
          </Box>
          <Box>- verlieren mehrere Personen den Zugriff</Box>
        </Box>
      </Box>
    </>
  );
};

const CommunityDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn du diese Unternehmung löschst, werden folgende Abhängigkeiten mit
          entfernt bzw. Bestandteile gelöscht:
        </Box>
        <Box className='pl-4 font-normal'>
          <Box>
            -{' '}
            {props.deleteInformation &&
              props.deleteInformation.created_communities_templates}{' '}
            Tool-Vorlagen
          </Box>
          <Box>
            -{' '}
            {props.deleteInformation &&
              props.deleteInformation.created_communities_templates_tools}{' '}
            angelegte Tools
          </Box>
          <Box>
            -{' '}
            {props.deleteInformation &&
              props.deleteInformation.created_communities_groups}{' '}
            Gruppen/Teams werden gelöscht
          </Box>
          <Box>
            - die Nachrichten von{' '}
            {props.deleteInformation &&
              props.deleteInformation.created_communities_groups}{' '}
            Gruppen/Teams
          </Box>
          <Box>
            -{' '}
            {props.deleteInformation && props.deleteInformation.connected_users}{' '}
            Verbindungen zu anderen Personen
          </Box>
          <Box>
            - {props.deleteInformation && props.deleteInformation.invited_users}{' '}
            eingeladene Kontakte werden entfernt
          </Box>
        </Box>
      </Box>
    </>
  );
};

const RemovePersonCommunityDeleteDescription = (props: DeleteInfoProps) => {
  return (
    <>
      <Box className='flex flex-col'>
        <Box className='font-semibold w-1/2'>
          Wenn du diese Person von deiner Unternehmung trennst, werden:
        </Box>
        <Box className='pl-4 font-normal'>
          <Box>
            -{' '}
            {props.deleteInformation &&
              props.deleteInformation.created_communities_groups_msgs}{' '}
            Nachrichten der Person gelöscht
          </Box>
          <Box>
            -{' '}
            {props.deleteInformation &&
              props.deleteInformation.created_communities_templates_tools}{' '}
            Tools und deren Inhalte gelöscht
          </Box>
        </Box>
      </Box>
    </>
  );
};
