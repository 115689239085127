import { useTranslation } from 'react-i18next';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
// import jwt_decode from 'jwt-decode';

// Hooks
import { useLogout } from '../../public/hooks/use-logout.hook';

// Models
import { Attachment } from '../../../shared/models/shared.types';
import { Profile } from '../models/user.types';

// Store
import { useUserStore } from '../stores/use-user.store';

export const useUsers = () => {
  const { logout } = useLogout();
  const { t } = useTranslation();

  // User store state
  const [profile, setProfile] = useUserStore((state) => [
    state.profile,
    state.setProfile,
  ]);

  /**
   * Update profile avatar.
   * @param image Image
   */
  const avatarPostEffect = (image: Attachment) => {
    const updatedProfile: Profile = {
      address: { ...profile?.address },
      avatar: image,
      personal_data: { ...profile?.personal_data },
    };
    setProfile(updatedProfile);
  };

  /**
   * Returns font awesome icon prop by file mime type.
   * @param mimetype File MIME type
   * @returns Font awesome icon prop
   */
  const iconByMimeTypeGet = (mimetype: string): [IconPrefix, IconName] => {
    switch (mimetype) {
      case 'application/pdf':
        return ['fas', 'file-pdf'];
      case 'application/msexcel':
        return ['fas', 'file-excel'];
      case 'image/jpeg':
        return ['fas', 'file-image'];
      default:
        return ['fas', 'file'];
    }
  };

  /**
   * Handle account update http errors.
   * @param status Http response status code
   * @returns Error message
   */
  const userAccountUpdateHandleError = (status: number): string => {
    switch (status) {
      case 401:
        // Logout if unauthorized
        logout();
        return '';
      case 409:
        return t('form.profile.email.error.conflict') + '';
      default:
        return `Code ${status}: ${t('app.fetch.error.response')}`;
    }
  };

  /**
   * Handle email check http errors.
   * @param status Http response status code
   * @returns Error message
   */
  const userEmailCheckHandleError = (status: number): string => {
    switch (status) {
      case 409:
        return t('form.profile.email.error.conflict') + '';
      default:
        return `Code ${status}: ${t('app.fetch.error.response')}`;
    }
  };

  /**
   * Handle account update post http errors.
   * @param status Http response status code
   * @returns Error message
   */
  const updateUserEmailPostHandleError = (status: number): string => {
    switch (status) {
      case 401:
        // Logout if unauthorized
        logout();
        return '';
      case 409:
        return t('form.profile.email.error.conflict') + '';
      default:
        return `Code ${status}: ${t('app.fetch.error.response')}`;
    }
  };

  /**
   * Handle password update http errors.
   * @param status Http response status code
   * @returns Error message
   */
  const userPasswordUpdateHandleError = (status: number): string => {
    switch (status) {
      case 400:
        return t('user.security.change.error.bad') + '';
      case 401:
        logout();
        return '';
      case 403:
        return t('user.security.change.error.forbidden') + '';
      case 409:
        return t('user.security.change.error.conflict') + '';
      default:
        return `Code ${status}: ${t('app.fetch.error.response')}`;
    }
  };

  /**
   * Handle password check http errors.
   * @param status Http response status code
   * @returns Error message
   */
  const userPasswordCheckError = (status: number): string => {
    switch (status) {
      case 403:
        return t('user.security.change.error.bad') + '';
      default:
        return `Code ${status}: ${t('app.fetch.error.response')}`;
    }
  };

  return {
    avatarPostEffect,
    iconByMimeTypeGet,
    userAccountUpdateHandleError,
    userEmailCheckHandleError,
    updateUserEmailPostHandleError,
    userPasswordUpdateHandleError,
    userPasswordCheckError,
  };
};
