import { Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Box, Dialog as MuiDialog } from '@mui/material';
import clsx from 'clsx';

// Contexts
import { SocketContext } from '../../../../shared/context/socket.context';
import { useStableNavigate } from '../../../../shared/components/StableNavigateContext/StableNavigateContext';

// Components
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { FormDelete } from '../../../../shared/components/FormDelete/FormDelete';
import { GroupTools } from '../GroupTools/GroupTools';
import { GroupUserItem, GroupUsers } from '../GroupUsers/GroupUsers';
import { Icon } from '../../../../shared/ui/Icon/Icon';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { Menu } from '../../../../shared/ui/Menu/Menu';
import { Tooltip } from '../../../../shared/ui/Tooltip/Tooltip';
import { ImageFallback } from '../../../../shared/components/ImageFallback/ImageFallback';
import { GroupCreateEditSettings } from '../GroupCreateEditSettings/GroupCreateEditSettings';
import { Loader } from '../../../../shared/ui/Loader/Loader';
import ContactsContent, {
  ContactsContentFooter,
} from '../../../contacts/components/ContactsContent/ContactsContent';

// Hooks
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useNewsCenter } from '../../hooks/use-news-center.hook';
import { useNewsCenterHttp } from '../../hooks/use-news-center-http.hook';
import { useShared } from '../../../../shared/hooks/use-shared.hook';

// Models
import {
  ActiveState,
  CommunityGroup,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactCommunityIdType,
  ContactsViewType,
} from '../../../contacts/models/contacts.types';
import {
  RoomAction,
  RoomType,
  GroupCreateEditState,
  GroupUser,
  roomAsFavoriteRequest,
  NetworkFavoritesRequest,
} from '../../models/news-center.types';
import {
  AddRoomUsersSocketResponse,
  CrudState,
  DeleteForm,
  ImageFallbackType,
  MenuItem,
  ResultState,
} from '../../../../shared/models/shared.types';
import { DeleteInfo, User } from '../../../user/models/user.types';

// Stores
import { useAuthStore } from '../../../public/stores/use-auth.store';
import { useNewsCenterStore } from '../../stores/use-news-center.store';
import { useUserStore } from '../../../user/stores/use-user.store';

// Styles
import styles from './NewsCenterChatHeader.module.scss';

import { InfoMessageType } from '../../models/message.types';
import { CommunityUserRole } from '../../../communities/models/communities.types';
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';
import { FormDeleteDialog } from '../../../../new_shared/components/FormDeleteDialog/FormDeleteDialog';

type NewsCenterChatHeaderProps = {
  contactCommunity?: ContactCommunity;
  disabledMenu?: boolean;
  roomId?: string;
  type: RoomType;
  onBack: () => void;
  onMessagesAdd: () => void;
  onSettingChange?: () => void;
  onGroupDelete?: (contactCommunity: ContactCommunity) => void;
};

export const NewsCenterChatHeader = (props: NewsCenterChatHeaderProps) => {
  // Context
  const socket = useContext(SocketContext);

  const { fullNameGet } = useShared();
  const { smUp, lgDown, lgUp, mdUp } = useBreakpoints();
  const { handleRetry } = useFetch();
  const navigate = useStableNavigate();

  const {
    directRoomChatPartnerGet,
    directRoomDeleteEffect,
    groupRoomDeleteEffect,
    onInfoMessageSubmit,
    roomActionsGet,
    roomActionsGetInMobile,
  } = useNewsCenter();
  const {
    directRoomDelete,
    groupRoomDelete,
    addRoomAsFavorite,
    removeRoomAsFavorite,
    newsCenterRoomDeleteInfoGet,
  } = useNewsCenterHttp();

  const { addNetworkFavorites, removeNetworkFavorites } = useContactsHttp();
  const { t } = useTranslation();

  // Component state
  const [chatPartner, setChatPartner] = useState<Partial<User> | undefined>(
    undefined
  );
  const [dialogDirectRoomDelete, setDialogDirectRoomDelete] =
    useState<boolean>(false);
  const [dialogGroupRoomDelete, setDialogGroupRoomDelete] =
    useState<boolean>(false);
  const [groupChatUsersSelection, setGroupChatUsersSelection] = useState<
    string[]
  >([]);
  const [roomActions, setRoomActions] = useState<MenuItem[]>([]);
  const [roomActionsInMobile, setRoomActionsInMobile] = useState<MenuItem[]>(
    []
  );
  const [users, setUsers] = useState<GroupUser[]>([]);
  const [videoUrl, setVideoUrl] = useState<string>('https://meet.jit.si/');
  const [dialogUserOpen, setDialogUserOpen] = useState<boolean>(false);
  const [groupRoomCreateEditState, setGroupRoomCreateEditState] =
    useState<GroupCreateEditState>(GroupCreateEditState.Common);
  const [communityGroup, setCommunityGroup] = useState<
    CommunityGroup | undefined
  >(undefined);
  const [deleInformation, setDeleInformation] = useState<
    DeleteInfo | undefined
  >(undefined);

  // Auth store state
  const [accessToken] = useAuthStore((state) => [state.accessToken]);

  // News center store state
  const [
    favoriteRooms,
    selectedRoom,
    dialogGroupRoomCreateEdit,
    setDialogGroupRoomCreateEdit,
    setSelectedRoom,
    setGroupRoomCreateEditSettings,
  ] = useNewsCenterStore((state) => [
    state.favoriteRooms,
    state.selectedRoom,
    state.dialogGroupRoomCreateEdit,
    state.setDialogGroupRoomCreateEdit,
    state.setSelectedRoom,
    state.setGroupRoomCreateEditSettings,
  ]);

  // User store state
  const [account, profile] = useUserStore((state) => [
    state.account,
    state.profile,
  ]);

  // Shared store state
  const [setNotification] = useSharedStore((state) => [state.setNotification]);

  // ################### //
  // MUTATIONS & QUERIES //
  // ################### //

  // DELETE Group room mutation
  const groupRoomDeleteMutation = useMutation(
    (id: string) => groupRoomDelete(id),
    {
      //retry: (failureCount, error: any) => handleRetry(failureCount, error),
    }
  );

  // Delete group room
  useEffect(() => {
    try {
      if (groupRoomDeleteMutation.data) {
        groupRoomDeleteEffect();
        navigate('/newscenter');
      }
      if (groupRoomDeleteMutation.error) {
        // const errRes = groupRoomDeleteMutation.error?.response;
        // if (errRes) {
        //   handleError(errRes.status);
        // }
      }
    } catch (error) {
      console.error('ERROR deleting group room:', error);
    }
    // eslint-disable-next-line
  }, [groupRoomDeleteMutation.data, groupRoomDeleteMutation.error]);

  // DELETE Direct room mutation
  const directRoomDeleteMutation = useMutation((id: string) =>
    directRoomDelete(id)
  );

  // Delete direct room
  useEffect(() => {
    try {
      if (directRoomDeleteMutation.data) {
        directRoomDeleteEffect();
        navigate('/newscenter');
      }
      if (directRoomDeleteMutation.error) {
        // const errRes = directRoomDeleteMutation.error?.response;
        // if (errRes) {
        //   handleError(errRes.status);
        // }
      }
    } catch (error) {
      console.error('ERROR deleting direct room:', error);
    }
    // eslint-disable-next-line
  }, [directRoomDeleteMutation.data, directRoomDeleteMutation.error]);

  const addNetworkFavoritesMutation = useMutation(
    (data: NetworkFavoritesRequest) => addNetworkFavorites(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error, variables) => {
        if (data) {
          switch (variables.get_type) {
            case ContactCommunityGetAction.Community:
            case ContactCommunityGetAction.Admin:
            case ContactCommunityGetAction.Employee:
            case ContactCommunityGetAction.Member:
            case ContactCommunityGetAction.Group:
            case ContactCommunityGetAction.Room:
              if (communityGroup) {
                const updated: CommunityGroup = { ...communityGroup };
                updated.favorite = updated.favorite ? false : true;
                setCommunityGroup(updated);
              }
              break;
            // #TODO expand
            // case ContactCommunityGetAction.Folder:
            //   break;
            // case ContactCommunityGetAction.ToolLink:
            //   break;
            default:
              break;
          }
          if (error) {
          }
        }
      },
    }
  );

  const removeNetworkFavoritesMutation = useMutation(
    (data: NetworkFavoritesRequest) => removeNetworkFavorites(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error, variables) => {
        if (data) {
          switch (variables.get_type) {
            case ContactCommunityGetAction.Community:
            case ContactCommunityGetAction.Admin:
            case ContactCommunityGetAction.Employee:
            case ContactCommunityGetAction.Member:
            case ContactCommunityGetAction.Group:
            case ContactCommunityGetAction.Room:
              if (communityGroup) {
                const updated: CommunityGroup = { ...communityGroup };
                updated.favorite = false;
                setCommunityGroup(updated);
              }
              break;
            default:
              break;
          }
          if (error) {
          }
        }
      },
    }
  );

  const addRoomAsFavoriteMutation = useMutation(
    (data: roomAsFavoriteRequest) => addRoomAsFavorite(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (selectedRoom) {
            let updatedRoom = selectedRoom;
            updatedRoom.favorite = true;
            setSelectedRoom(updatedRoom);
          } else if (communityGroup && account) {
            // const updatedGroup = communityGroup;
            // if (updatedGroup.favorite_user) {
            //   updatedGroup.favorite_user.push({ id: account.id });
            // } else {
            //   updatedGroup.favorite_user = [{ id: account.id }];
            // }
            // setCommunityGroup(updatedGroup);
          }
        }
      },
    }
  );

  const removeRoomAsFavoriteMutation = useMutation(
    (data: roomAsFavoriteRequest) => removeRoomAsFavorite(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (selectedRoom) {
            let updatedRoom = selectedRoom;
            updatedRoom.favorite = false;
            setSelectedRoom(updatedRoom);
          } else if (communityGroup && account) {
            // const updatedGroup = communityGroup;
            // if (updatedGroup.favorite_user) {
            //   updatedGroup.favorite_user = updatedGroup.favorite_user.filter(
            //     (f_user) => f_user.id !== account.id
            //   );
            // }
            // setCommunityGroup(updatedGroup);
          }
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    props.contactCommunity?.selected_group &&
      setCommunityGroup(props.contactCommunity?.selected_group);
  }, [props.contactCommunity?.selected_group]);

  // Component mount effects
  useEffect(() => {
    // Set room actions
    setRoomActions(
      roomActionsGet(
        props.type,
        props.contactCommunity,
        account && account.email.includes('@pengueen.de')
      )
    );
    setRoomActionsInMobile(
      roomActionsGetInMobile(props.type, props.contactCommunity)
    );
    // Set direct room chat partner info
    account &&
      selectedRoom &&
      selectedRoom.type === RoomType.Direct &&
      setChatPartner(directRoomChatPartnerGet(account?.id, selectedRoom.users));

    // eslint-disable-next-line
  }, [
    props.type,
    props.contactCommunity,
    account,
    selectedRoom,
    favoriteRooms,
  ]);

  /**
   * Handler for chat actions.
   * @param action ChatAction
   */
  const onAction = useCallback(
    (action: RoomAction) => {
      if (props.type === RoomType.Group) {
        if (action === RoomAction.Add) {
          // setGroupRoomCreateEditCrudState(CrudState.Update);
          setGroupRoomCreateEditState(GroupCreateEditState.Members);
          setDialogGroupRoomCreateEdit(true);
        }
        action === RoomAction.Delete &&
          selectedRoom &&
          groupDeleteInformationMutation.mutate(selectedRoom.id);
        if (action === RoomAction.Settings) {
          // setGroupRoomCreateEditCrudState(CrudState.Update);
          setGroupRoomCreateEditState(GroupCreateEditState.Common);
          setDialogGroupRoomCreateEdit(true);
        }
        if (action === RoomAction.User) {
          setDialogUserOpen(true);
        }
        if (action === RoomAction.PrintView) {
          window.open(window.location.href + '/printview');
        }
      } else if (props.type === RoomType.Direct) {
        if (action === RoomAction.PrintView) {
          window.open(window.location.href + '/printview');
        } else {
          action === RoomAction.Delete && setDialogDirectRoomDelete(true);
        }
      } else if (props.type === RoomType.Network) {
        if (action === RoomAction.Settings) {
          props.onSettingChange && props.onSettingChange();
        }
        if (action === RoomAction.Delete) {
          props.contactCommunity &&
            props.onGroupDelete &&
            props.onGroupDelete(props.contactCommunity);
        }
      }
    },
    // eslint-disable-next-line
    [props.type, selectedRoom]
  );

  // useEffect(() => {
  //   if (chatPartner) {
  //     const updatedRoom = selectedRoom;
  //     if (updatedRoom) {
  //       let newSetting: GroupRoomSettings = {
  //         description: '',
  //         name: '',
  //       };
  //       newSetting.name = fullNameGet(
  //         chatPartner.profile?.personal_data.first_name,
  //         chatPartner.profile?.personal_data.last_name,
  //         chatPartner.profile?.nickname
  //       )!.toString();
  //       updatedRoom.settings = newSetting;
  //       setSelectedRoom(updatedRoom);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [chatPartner, selectedRoom]);

  // Cast users object on mount
  useEffect(() => {
    const groupUsers: GroupUser[] = [];
    if (selectedRoom?.users) {
      for (const user of selectedRoom.users) {
        if (user.account?.id) {
          groupUsers.push({
            id: user.account.id,
            avatar: user.profile?.avatar,
            email: user.account.email,
            first_name: user.profile?.personal_data.first_name,
            last_name: user.profile?.personal_data.last_name,
            nickname: user.profile?.nickname,
            state: ActiveState.Indirect,
          });
        }
      }
      setUsers(groupUsers);
    }
    // eslint-disable-next-line
  }, [selectedRoom?.users.length]);

  useEffect(() => {
    if (props.type === RoomType.Group && selectedRoom) {
      setVideoUrl(
        'https://meet.jit.si/' +
          selectedRoom.settings?.name +
          'PengueenP4Jitsi' +
          selectedRoom.id
      );
    }

    if (
      props.type === RoomType.Direct &&
      selectedRoom &&
      chatPartner &&
      profile
    ) {
      //Sort participants by first name
      const participants = [
        chatPartner?.profile?.personal_data.first_name ?? 'Namenlos',
        profile?.personal_data.first_name ?? 'Namenlos',
      ];
      const members = participants
        .sort((a: string, b: string) => {
          return a > b ? 1 : -1;
        })
        .join(' x')
        .toString();

      setVideoUrl(
        'https://meet.jit.si/' + members + 'PengueenP4Jitsi' + selectedRoom.id
      );
    } else if (
      chatPartner === undefined &&
      props.type === RoomType.Direct &&
      selectedRoom &&
      account
    ) {
      const participant = directRoomChatPartnerGet(
        account?.id,
        selectedRoom.users
      );
      const participants = [
        participant?.profile?.personal_data.first_name ?? 'Namenlos',
        profile?.personal_data.first_name ?? 'Namenlos',
      ];
      const members = participants
        .sort((a: string, b: string) => {
          return a > b ? 1 : -1;
        })
        .join(' x')
        .toString();

      setVideoUrl(
        'https://meet.jit.si/' + members + 'PengueenP4Jitsi' + selectedRoom.id
      );
      setChatPartner(participant);
    }
    // eslint-disable-next-line
  }, [selectedRoom]);

  // ######### //
  // CALLBACKS //
  // ######### //

  const groupDeleteInformationMutation = useMutation(
    (id: string) => newsCenterRoomDeleteInfoGet(id),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data) => {
        if (data) {
          data && setDeleInformation(data);
          setDialogGroupRoomDelete(true);
        }
      },
    }
  );

  /**
   * Handler to close group room create dialog.
   */
  const onGroupRoomCreateClose = useCallback(() => {
    setGroupRoomCreateEditSettings(undefined);
    setGroupRoomCreateEditState(GroupCreateEditState.Common);
    setDialogGroupRoomCreateEdit(false);

    // eslint-disable-next-line
  }, [dialogGroupRoomCreateEdit]);

  /**
   * Handler to change selection state.
   * @param selection Selected user ids
   */
  const onGroupRoomSelectionChange = useCallback((selection: string[]) => {
    setGroupChatUsersSelection(selection);
  }, []);

  /**
   * Handler to create group room.
   */
  const onGroupRoomSubmit = useCallback(() => {
    if (
      // groupRoomCreateEditCrudState === CrudState.Update &&
      selectedRoom
    ) {
      // Close dialog
      setDialogGroupRoomCreateEdit(false);
      // setGroupRoomCreateEditCrudState(undefined);
      socket.emit(
        'addRoomUsers',
        {
          accessToken,
          participant: groupChatUsersSelection,
          room_id: selectedRoom.id,
        },
        (response: AddRoomUsersSocketResponse) => {
          if (accessToken) {
            let userNames: string[] = [];
            let notAddedUserNames: string[] = [];
            let message: string = '';

            // set user names
            if (response.data?.users) {
              response.data.users.forEach((user) => {
                const invitedUserName = fullNameGet(
                  user.profile.personal_data.first_name,
                  user.profile.personal_data.last_name
                )?.toString()!;
                // send info message
                onInfoMessageSubmit(InfoMessageType.invite, [invitedUserName]);
                userNames.push(invitedUserName);
              });
            }

            // set info message
            if (response.status === ResultState.Success) {
              message = `${t('newscenter.invite.success', {
                userName: userNames.join(', '),
              })}`;
            } else if (response.status === ResultState.Error) {
              if (response.data?.notAddedUsers) {
                response.data.notAddedUsers.forEach((user) => {
                  const username = fullNameGet(
                    user.profile.personal_data.first_name,
                    user.profile.personal_data.last_name
                  )?.toString()!;

                  notAddedUserNames.push(username);
                });
                message = `${t('newscenter.invite.fail', {
                  userName: notAddedUserNames.join(', '),
                })}`;
              }
            }

            // send notification
            setNotification({
              title: message,
              state: response.status,
            });
          }
        }
      );
    }

    // eslint-disable-next-line
  }, [groupChatUsersSelection, selectedRoom]);

  /**
   * Handler to delete a direct room by id.
   */
  const onDirectRoomDelete = useCallback(() => {
    if (selectedRoom && accessToken) {
      // Notification when user leaves the chat room
      onInfoMessageSubmit(
        selectedRoom.permissions?.can_delete_group &&
          selectedRoom.type === RoomType.Group
          ? InfoMessageType.close
          : InfoMessageType.leave
      );
      directRoomDeleteMutation.mutate(selectedRoom.id);
    }

    // eslint-disable-next-line
  }, [selectedRoom]);

  /**
   * Handler to delete a group room by id.
   */
  const onGroupRoomDelete = useCallback(() => {
    if (selectedRoom) {
      if (
        !selectedRoom.settings?.community_id &&
        selectedRoom.users.length > 1
      ) {
        // Notification when user leaves the chat room
        onInfoMessageSubmit(
          selectedRoom.permissions?.can_delete_group &&
            selectedRoom.type === RoomType.Group
            ? InfoMessageType.close
            : InfoMessageType.leave
        );
      }
      groupRoomDeleteMutation.mutate(selectedRoom.id);
    }

    // eslint-disable-next-line
  }, [selectedRoom]);

  return (
    <>
      <Box
        borderColor="border.app"
        className={
          props.type === RoomType.GodParent
            ? styles['godparents-chat-header']
            : styles['news-center-chat-header']
        }
      >
        <div className={styles['news-center-chat-header-info']}>
          {(props.type === RoomType.Direct ||
            props.type === RoomType.ToolChatContact ||
            props.type === RoomType.GodParent) &&
            chatPartner && (
              <>
                <div className={styles['news-center-chat-header-back']}>
                  <IconButton
                    classes={styles['news-center-chat-header-back-content']}
                    icon={['fas', 'chevron-left']}
                    iconSize="small"
                    sxButton={{
                      '& svg': { color: 'text.secondary' },
                      '&:hover': { backgroundColor: 'primary.main' },
                      '&:hover .icon-text-button-text': { color: 'white' },
                      '&:hover .MuiSvgIcon-root': { color: 'white' },
                    }}
                    onClick={props.onBack}
                  />
                </div>
                {chatPartner.profile?.avatar?.url ? (
                  <img
                    alt={
                      '' +
                      fullNameGet(
                        chatPartner.profile?.personal_data.first_name,
                        chatPartner.profile?.personal_data.last_name,
                        chatPartner.profile?.nickname
                      )
                    }
                    src={chatPartner.profile?.avatar.url}
                    className={
                      props.roomId
                        ? styles['godparents-chat-header-info-avatar']
                        : styles['news-center-chat-header-info-avatar']
                    }
                  />
                ) : (
                  <ImageFallback
                    iconSize={lgDown ? '1em' : '1.5em'}
                    sizeClassName={
                      props.roomId
                        ? styles['godparents-chat-header-info-avatar']
                        : styles['news-center-chat-header-info-avatar']
                    }
                    type={ImageFallbackType.Profile}
                  />
                )}

                <div className={styles['news-center-chat-header-info-text']}>
                  {!props.roomId && (
                    <Box
                      color={props.roomId ? 'white' : 'text.secondary'}
                      className={
                        styles['news-center-chat-header-info-text-subtitle']
                      }
                    >
                      {t('newscenter.room.direct.subtitle')}
                    </Box>
                  )}
                  <Box
                    color={
                      props.type === RoomType.GodParent
                        ? 'white'
                        : 'text.primary'
                    }
                    className={styles['news-center-chat-header-info-text-name']}
                  >
                    <div
                      className={
                        styles['news-center-chat-header-info-text-name-detail']
                      }
                    >
                      {fullNameGet(
                        chatPartner.profile?.personal_data.first_name,
                        chatPartner.profile?.personal_data.last_name,
                        chatPartner.profile?.nickname
                      )}
                    </div>

                    {!props.roomId && (
                      <Tooltip
                        title={
                          selectedRoom?.favorite
                            ? t('newscenter.favorite.remove')
                            : t('newscenter.favorite.mark')
                        }
                      >
                        <div
                          className={styles['news-center-chat-header-favorite']}
                          onClick={() => {
                            selectedRoom?.favorite
                              ? removeRoomAsFavoriteMutation.mutate({
                                  room_id: selectedRoom.id,
                                })
                              : selectedRoom &&
                                addRoomAsFavoriteMutation.mutate({
                                  room_id: selectedRoom.id,
                                });
                          }}
                        >
                          <Icon
                            icon={
                              selectedRoom?.favorite
                                ? ['fas', 'heart']
                                : ['fal', 'heart']
                            }
                            sx={{
                              color: selectedRoom?.favorite
                                ? 'error.main'
                                : 'text.secondary',
                            }}
                            size={lgDown ? 'inherit' : 'small'}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </Box>
                  {props.type === RoomType.GodParent && (
                    <Box
                      color="white"
                      className="flex flex-col p-0 text-xs lg:text-sm"
                    >
                      <div> {t('newscenter.godparents.subtitle1')}</div>
                      <div> {t('newscenter.godparents.subtitle2')}</div>
                    </Box>
                  )}
                </div>
              </>
            )}
          {(props.type === RoomType.Group ||
            props.type === RoomType.ToolChatGroup ||
            props.type === RoomType.ToolChatMultiRow) && (
            <>
              <div className={styles['news-center-chat-header-back']}>
                <IconButton
                  classes={styles['news-center-chat-header-back-content']}
                  icon={['fas', 'chevron-left']}
                  iconSize={smUp ? 'small' : 'inherit'}
                  sxButton={{
                    '& svg': { color: 'text.secondary' },
                    '&:hover': { backgroundColor: 'primary.main' },
                    '&:hover .icon-text-button-text': { color: 'white' },
                    '&:hover .MuiSvgIcon-root': { color: 'white' },
                  }}
                  onClick={props.onBack}
                />
              </div>
              <Box
                bgcolor="bg.card"
                className={styles['news-center-chat-header-info-group']}
              >
                <Icon
                  classes={styles['news-center-chat-header-info-hashtag']}
                  icon={['fal', 'hashtag']}
                  size={smUp ? (lgUp ? 'large' : 'medium') : 'inherit'}
                  sx={
                    !smUp
                      ? {
                          color: 'text.secondary',
                          height: '1.7rem',
                          width: '1.7rem',
                        }
                      : !lgUp
                      ? {
                          color: 'text.secondary',
                          height: '2rem',
                          width: '2rem',
                        }
                      : {
                          color: 'text.secondary',
                          height: '2.5rem',
                          width: '2.5rem',
                        }
                  }
                />
              </Box>
              <div className={styles['news-center-chat-header-info-text']}>
                <Box
                  color="text.secondary"
                  className={
                    styles['news-center-chat-header-info-text-subtitle']
                  }
                >
                  <div
                    className={
                      styles[
                        'news-center-chat-header-info-text-subtitle-content'
                      ]
                    }
                  >
                    {t('newscenter.groups.subtitle')}
                  </div>
                </Box>
                <div className={styles['news-center-chat-header-info']}>
                  <Box
                    className={styles['news-center-chat-header-info-text-name']}
                  >
                    {selectedRoom &&
                      selectedRoom.settings?.name &&
                      (selectedRoom.settings?.name === 'undefined'
                        ? t('app.nameless')
                        : selectedRoom.settings?.name)}
                  </Box>

                  <>
                    <Tooltip
                      title={
                        selectedRoom?.favorite
                          ? t('newscenter.favorite.remove')
                          : t('newscenter.favorite.mark')
                      }
                    >
                      <div
                        className={styles['news-center-chat-header-favorite']}
                        onClick={() => {
                          selectedRoom?.favorite
                            ? removeRoomAsFavoriteMutation.mutate({
                                room_id: selectedRoom.id,
                              })
                            : selectedRoom &&
                              addRoomAsFavoriteMutation.mutate({
                                room_id: selectedRoom.id,
                              });
                        }}
                      >
                        <Icon
                          icon={
                            selectedRoom?.favorite
                              ? ['fas', 'heart']
                              : ['fal', 'heart']
                          }
                          sx={{
                            color: selectedRoom?.favorite
                              ? 'error.main'
                              : 'text.secondary',
                          }}
                          size={lgDown ? 'inherit' : 'small'}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={
                        selectedRoom && selectedRoom.settings?.description
                          ? selectedRoom.settings.description
                          : ''
                      }
                    >
                      <IconButton
                        classes={styles['news-center-chat-header-info-tooltip']}
                        icon={['fas', 'info-circle']}
                        padding="0.25rem"
                      />
                    </Tooltip>
                  </>
                </div>
              </div>
            </>
          )}
          {props.type === RoomType.Network && (
            <>
              <div className={styles['network-header-back']}>
                <IconButton
                  classes={styles['news-center-chat-header-back-content']}
                  icon={['fas', 'chevron-left']}
                  iconSize={smUp ? 'small' : 'inherit'}
                  sxButton={{
                    '& svg': { color: 'text.secondary' },
                    '&:hover': { backgroundColor: 'primary.main' },
                    '&:hover .icon-text-button-text': { color: 'white' },
                    '&:hover .MuiSvgIcon-root': { color: 'white' },
                  }}
                  onClick={props.onBack}
                />
              </div>
              <Box
                // bgcolor="bg.card"
                className={styles['news-center-chat-header-info-group']}
              >
                {props.contactCommunity?.avatar?.url ? (
                  <img
                    alt={props.contactCommunity.name}
                    src={props.contactCommunity?.avatar?.url}
                    className={styles['news-center-chat-header-info-avatar']}
                  />
                ) : (
                  <ImageFallback
                    iconSize={lgDown ? '1em' : '1.5em'}
                    sizeClassName={
                      styles['news-center-chat-header-info-avatar']
                    }
                    type={
                      props.contactCommunity?.id ===
                      ContactCommunityIdType.NoCommunity
                        ? ImageFallbackType.Group
                        : ImageFallbackType.Network
                    }
                  />
                )}
              </Box>
              <div className={styles['news-center-chat-header-info-text']}>
                <Box
                  color="text.secondary"
                  className={
                    styles['news-center-chat-header-info-text-subtitle']
                  }
                >
                  <div
                    className={
                      styles[
                        'news-center-chat-header-info-text-subtitle-content'
                      ]
                    }
                  >
                    {props.contactCommunity?.selected_tool
                      ? t('contacts.toolbar.link.title')
                      : props.contactCommunity?.selected_group
                      ? t('community.tools.visibility.group')
                      : props.contactCommunity?.id ===
                        ContactCommunityIdType.All
                      ? t('newscenter.network.title')
                      : props.contactCommunity?.id ===
                        ContactCommunityIdType.None
                      ? t('newscenter.network.title')
                      : t('contacts.community.title')}
                  </div>
                </Box>
                <div className={styles['news-center-chat-header-info']}>
                  <Box
                    className={styles['news-center-chat-header-info-text-name']}
                  >
                    {props.contactCommunity?.id === ContactCommunityIdType.All
                      ? t('contacts.all')
                      : props.contactCommunity?.id ===
                        ContactCommunityIdType.None
                      ? ''
                      : props.contactCommunity?.selected_group?.id ===
                        CommunityUserRole.Admin
                      ? `${props.contactCommunity.name} / ${t('app.admin')}`
                      : props.contactCommunity?.selected_group?.id ===
                        CommunityUserRole.Employee
                      ? `${props.contactCommunity.name} / ${
                          props.contactCommunity?.selected_group?.name !== ''
                            ? props.contactCommunity?.selected_group.name
                            : t('app.employee')
                        }`
                      : props.contactCommunity?.selected_group?.id ===
                        CommunityUserRole.Member
                      ? `${props.contactCommunity.name} / ${
                          props.contactCommunity?.selected_group?.name !== ''
                            ? props.contactCommunity?.selected_group.name
                            : t('app.member')
                        }`
                      : props.contactCommunity?.selected_group?.id ===
                        ContactCommunityGetAction.Community
                      ? props.contactCommunity?.name
                      : props.contactCommunity?.selected_group
                      ? props.contactCommunity?.id ===
                        ContactCommunityIdType.NoCommunity
                        ? `${props.contactCommunity?.selected_group?.name}`
                        : `${props.contactCommunity?.name} / ${props.contactCommunity?.selected_group?.name}`
                      : props.contactCommunity?.name}
                  </Box>
                  {props.contactCommunity?.id !== ContactCommunityIdType.None &&
                    props.contactCommunity?.id !==
                      ContactCommunityIdType.All && (
                      <Tooltip
                        title={
                          communityGroup?.favorite
                            ? t('newscenter.favorite.remove')
                            : t('newscenter.favorite.mark')
                        }
                      >
                        <div
                          className={styles['news-center-chat-header-favorite']}
                          onClick={() => {
                            let getType = ContactCommunityGetAction.Room;
                            if (props.contactCommunity?.id) {
                              if (
                                props.contactCommunity?.id ===
                                ContactCommunityIdType.NoCommunity
                              ) {
                                getType = ContactCommunityGetAction.Room;
                                if (communityGroup) {
                                  communityGroup?.favorite
                                    ? removeNetworkFavoritesMutation.mutate({
                                        group_id: communityGroup.id,
                                        get_type: getType,
                                      })
                                    : addNetworkFavoritesMutation.mutate({
                                        group_id: communityGroup.id,
                                        get_type: getType,
                                      });
                                }
                              } else if (communityGroup) {
                                switch (communityGroup.id) {
                                  case ContactCommunityGetAction.Community:
                                    getType =
                                      ContactCommunityGetAction.Community;
                                    break;
                                  case CommunityUserRole.Admin:
                                    getType = ContactCommunityGetAction.Admin;
                                    break;
                                  case CommunityUserRole.Employee:
                                    getType =
                                      ContactCommunityGetAction.Employee;
                                    break;
                                  case CommunityUserRole.Member:
                                    getType = ContactCommunityGetAction.Member;
                                    break;
                                  default:
                                    getType = ContactCommunityGetAction.Group;
                                    break;
                                }
                                communityGroup?.favorite
                                  ? removeNetworkFavoritesMutation.mutate({
                                      group_id: communityGroup.id,
                                      community_id: props.contactCommunity?.id,
                                      get_type: getType,
                                    })
                                  : addNetworkFavoritesMutation.mutate({
                                      group_id: communityGroup.id,
                                      community_id: props.contactCommunity?.id,
                                      get_type: getType,
                                    });
                              }
                            }
                          }}
                        >
                          <Icon
                            icon={
                              communityGroup?.favorite
                                ? ['fas', 'heart']
                                : ['fal', 'heart']
                            }
                            sx={{
                              color: communityGroup?.favorite
                                ? 'error.main'
                                : 'text.secondary',
                            }}
                            size={lgDown ? 'inherit' : 'small'}
                          />
                        </div>
                      </Tooltip>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={
            props.type === RoomType.GodParent
              ? styles['gotparents-chat-header-menu']
              : styles['news-center-chat-header-menu']
          }
        >
          {selectedRoom &&
            (props.type === RoomType.Group ||
              props.type === RoomType.ToolChatGroup ||
              props.type === RoomType.ToolChatMultiRow) && (
              <div
                className={styles['news-center-chat-header-menu-group-users']}
              >
                {selectedRoom.tools && selectedRoom.tools.length > 0 && (
                  <GroupTools tools={selectedRoom.tools} />
                )}
                {mdUp && (
                  <GroupUsers
                    users={users}
                    groupCreator={selectedRoom.room_creator}
                  />
                )}
              </div>
            )}
          {!props.roomId && (
            <div className={styles['news-center-chat-header-menu-actions']}>
              {props.type !== RoomType.Network && (
                <a href={videoUrl} target="_blank" rel="noreferrer">
                  <IconButton
                    padding={lgDown ? '0 0.5rem' : undefined}
                    classes={
                      styles['news-center-chat-header-menu-actions-tooltip']
                    }
                    icon={['far', 'video']}
                    iconSize={smUp ? 'small' : 'inherit'}
                    preset="popover"
                  />
                </a>
              )}
              {!props.disabledMenu && (
                <Menu
                  iconSize={smUp ? 'small' : 'inherit'}
                  buttonPadding={smUp ? '0.5rem' : '0.69rem 0.5rem'}
                  buttonSx={{ height: '36px' }}
                  buttonText={mdUp ? t('app.actions.title') : ''}
                  items={mdUp ? roomActions : roomActionsInMobile}
                  onClick={onAction}
                />
              )}
            </div>
          )}
          {props.roomId && props.type === RoomType.GodParent && (
            // <Box className="flex flex-col ">
            <Box
              className={styles['godparents-chat-menu']}
              onClick={() => navigate(`/newscenter/private/${props.roomId}`)}
            >
              <IconButton
                sxButton={{
                  '& svg': { color: '#FFFFFF69' },
                  bgcolor: '#FFFFFF31',
                }}
                icon={['fas', 'arrow-up-right-from-square']}
              />
            </Box>
            // <Box className="flex grow"></Box>
            // </Box>
          )}
        </div>
      </Box>
      {/* show Dialog only for Newscenter */}
      {(props.type === RoomType.Direct || props.type === RoomType.Group) && (
        <>
          <Dialog
            classes={clsx(
              groupRoomCreateEditState === GroupCreateEditState.Members &&
                styles['news-center-chat-create']
            )}
            open={dialogGroupRoomCreateEdit}
            title={
              groupRoomCreateEditState === GroupCreateEditState.Common
                ? t('newscenter.groups.create_edit.settings.title.edit')
                : t('newscenter.groups.create_edit.users.title')
            }
            widthClassName={clsx(
              groupRoomCreateEditState === GroupCreateEditState.Members &&
                styles['news-center-chat-create-width']
            )}
            onClose={onGroupRoomCreateClose}
          >
            {groupRoomCreateEditState === GroupCreateEditState.Common && (
              <GroupCreateEditSettings
                newsCenter
                groupRoomCreateEditCrudState={CrudState.Update}
                setGroupRoomCreateEditState={setGroupRoomCreateEditState}
                onClose={onGroupRoomCreateClose}
              />
            )}
            {groupRoomCreateEditState === GroupCreateEditState.Members && (
              // <div className={styles['news-center-chat-create-group-users']}>
              <Suspense fallback={<Loader />}>
                <ContactsContent
                  modal
                  disableImage
                  newsCenter
                  groupId={
                    // groupRoomCreateEditCrudState === CrudState.Update &&
                    selectedRoom ? selectedRoom.id : undefined
                  }
                  type={ContactsViewType.Group}
                  onUsersSelectionChange={onGroupRoomSelectionChange}
                  heightClasses={
                    styles['news-center-chat-create-group-users-height']
                  }
                  paddingClasses={
                    styles['news-center-chat-create-group-users-padding']
                  }
                  footerComponent={
                    <ContactsContentFooter
                      classes={
                        styles['news-center-chat-create-group-users-submit']
                      }
                      forUsers
                      onClick={onGroupRoomSubmit}
                      disabled={groupChatUsersSelection.length < 1}
                      submitButtonTitle={t('contacts.detail.tools.add')}
                    />
                  }
                />
              </Suspense>
              // </div>
            )}
          </Dialog>
          <Dialog
            open={dialogDirectRoomDelete}
            subtitle={t('newscenter.room.direct.delete.subtitle')}
            title={t('newscenter.room.direct.delete.title')}
            onClose={() => setDialogDirectRoomDelete(false)}
          >
            <FormDelete
              text={t('newscenter.room.direct.delete.text')}
              onClose={() => setDialogDirectRoomDelete(false)}
              onSubmit={onDirectRoomDelete}
            />
          </Dialog>
          <FormDeleteDialog
            open={dialogGroupRoomDelete}
            title={
              <Box sx={{ fontSize: '1.25rem', lineHeight: '1.125rem' }}>
                <Box>
                  bist du sicher, dass du diese Gruppe löschen möchtest?
                </Box>
              </Box>
            }
            whichDelete={DeleteForm.Group}
            deleteInformation={deleInformation}
            onClose={() => setDialogGroupRoomDelete(false)}
            onSubmit={onGroupRoomDelete}
          />

          {dialogUserOpen && selectedRoom && lgDown && (
            <MuiDialog open={dialogUserOpen}>
              <Box
                sx={{ borderColor: 'border.app' }}
                className={styles['group-users-container-title']}
              >
                {t('newscenter.room.groups.users.title')}
              </Box>
              <div className={styles['group-users-container-content']}>
                {/* TODO FIX */}
                {users
                  // Sort user list by name
                  .sort((a: GroupUser, b: GroupUser) => {
                    const aFullName =
                      '' + fullNameGet(a.first_name, a.last_name);
                    const bFullName =
                      '' + fullNameGet(b.first_name, b.last_name);
                    return aFullName > bFullName ? 1 : -1;
                  })
                  .map((user) => (
                    <GroupUserItem key={user.id} user={user} />
                  ))}
              </div>
            </MuiDialog>
          )}
        </>
      )}
    </>
  );
};
