import { ReactChild } from 'react';
import { Link } from 'react-router-dom';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

type PrimaryLinkProps = {
  children: ReactChild | TFunctionResult;
  classes?: string;
  textDecorationStyle?: 'dashed';
  to: string;
};

export const PrimaryLink = (props: PrimaryLinkProps) => {
  return (
    <Box
      sx={{
        '& a': {
          color: 'primary.main',
          textDecoration: props.textDecorationStyle && 'underline',
          textDecorationStyle: props.textDecorationStyle,
        },
      }}
      className={clsx(props.classes && props.classes)}
    >
      <Link id="#link" to={props.to}>
        {props.children}
      </Link>
    </Box>
  );
};
