import { CommunityTranslations } from '../models/community.translations.interface';

export const communityTranslationsEN: CommunityTranslations = {
  no_community: {
    detail:
      'Hier kannst Du deine Gemeinschaften verwalten. Gemeinschaften erleichtern dir die Zusammenarbeit. Probiere es jetzt aus und erstelle eine Gemeinschaft. ',
  },
  create: {
    info: {
      end: {
        text: 'An diesem Tag zahlst du für deine Lizenzen, welche du zum Stichtag',
        title: 'Ende des Folgemonats',
      },
      notification: {
        text: 'Erhalte eine Erinnerung, wann dein Testzeitraum endet.',
        title: '5 Tage vor Ablauf',
      },
      start: {
        text: 'Starte deinen kostenlosen Testzeitraum im vollen Funktionsumfang.',
        title: 'Heute',
      },
      text1:
        'Free trial period until {{date}}. Afterwards {{cost}} € per employee:In per month.',
      text2: 'Dann',
      text3: 'pro Monat/Lizenz.',
      text4:
        'The license will not be activated automatically. You can create the community without obligation. ',
      text5: 'To the price list',
      title: 'Wie läuft der Testzeitraum ab?',
    },
    form: {
      logo: 'Logo',
      name: {
        error: 'Es muss ein Gemeinschaftsname eingegeben werden',
        label: 'Wie soll deine Gemeinschaft heißen?',
        placeholder: 'Name der Gemeinschaft',
      },
      sector: 'Welche Branche ist passend?',
      option: {
        name: 'Erweiterte Einstellung',
        info: 'Wir unterscheiden bei Pengueen standardmäßig in die Nutzerrollen "Mitarbeiter:In" und "Mitglied", um bestimmte Berechtigungen zu unterscheiden. Du hast hier die Möglichkeit diese anzupassen. Erfahre hier mehr dazu.',
        employee: 'Mitarbeiter (erweiterte Rechte)',
        member: 'Mitglieder (einfache Rechte)',
        employeeplaceholder: 'Deine Bezeichnung z.B. Vorstand',
        memberplaceholder: 'Deine Bezeichnung z.B. Ratsmitglieder',
        link: 'Verbindungslink',
        link_info:
          'Personen die sich über diesen Link registrieren, werden der Gemeinschaft als {{membersname}} hinzugefügt!',
        headline_email: 'Texte Einladungs-E-Mail',
        headline_accept_email: 'Texte Bestätigungs-E-Mail',
        betreff_einladung_email: 'Betreff der E-Mail',
        headline_einladung_email: 'Überschrift',
        text_einladung_email: 'Text der E-Mail',
        text_einladung_bestaetigung_email: 'Text der E-Mail',
        betreff_einladung_bestaetigung_email: 'Betreff der E-Mail',
      },
    },
    subtitle:
      'Starte deinen kostenlosen Testzeitraum und nutze den vollen Funktionsumfang.',
    title: 'Erstelle deine Gemeinschaft',
    title_button: 'Gemeinschaft erstellen',
  },
  filters: {
    apply: 'Apply',
    button: 'View',
    display: {
      gallery: 'Gallery view',
      list: 'List view',
    },
    labels: {
      contains: 'Name contains',
      order: 'Sort order',
    },
    sort: {
      modified: 'Last modified',
      latest: 'Latest added',
      title: 'Sort by',
      used: 'Most used',
    },
    title: 'View options',
  },
  table: {
    actions: {
      remove: 'Remove',
      title: 'Actions',
      view: 'View',
    },
  },
  tools: {
    filters: {
      field_contains: 'Hilfsmittelfeld enthält',
      group: {
        none: 'Keine',
        tag: 'Stichwort',
        title: 'Group by',
      },
      search: 'Nach Bezeichnung suchen',
    },
    table: {
      category: 'Hilfsmittelkategorie',
      connected_group: 'Verbundene Gruppe',
      field: 'Hilfsmittelfeld',
      folder_tag: 'Ordnerstichwort',
      visibility: 'Sichtbarkeit',
    },
    title: {
      employees: 'Deine Hilfsmittel für deine Mitarbeiter:innen',
      members: 'Deine Hilfsmittel für deine Mitgleider',
      with_tags: 'Hilfsmittel mit Stichwort',
      without_tags: 'Hilfsmittel ohne Stichwort',
    },
    visibility: {
      group: 'Group',
      private: 'Private',
      unrestricted: 'Unrestricted',
    },
  },
  sectors: {
    care_facility: 'Social Institution',
    club: 'Clubs',
    coaching: 'Counseling, Coaching',
    community: 'Community, Towns',
    company: 'Company, SMEs',
    craft_business: 'Craft',
    network: 'Network, Association',
    other: 'Other',
    project: '(Funding) Project, Working Group, etc.',
    school: 'Education Sector, University, Research',
  },
  users: {
    add: {
      submit: 'Hinzufügen',
      subtitle:
        'Füge Personen deiner Gemeinschaft hinzu und weise ihnen die entsprechende Rolle zu.',
      title: 'Zu Deiner Gemeinschaft hinzufügen',
      title_button: 'Zu Gemeinschaft hinzufügen',
    },
    contact: {
      add: {
        subtitle:
          'Bestimme eine/n primäre/n Ansprechpartner:in für dein Mitglied',
        title: 'Primäre/n Ansprechpartner:in bestimmen',
      },
    },
    deputy: {
      title: 'Deputy',
    },
    employees: {
      add: 'Add employee',
      demote: {
        btn: 'Herabstufen',
        confirm: 'MEMBER',
        subtitle: 'Dein:e Mitarbeiter:in wird zum Mitglied.',
        text: 'Jetzt Mitarbeiter:in zum Mitglied machen?',
        title: 'Bestätigung',
      },
      detail: {
        title: 'Employee',
        tools: 'Tools for employees',
      },
      remove: {
        subtitle:
          'Die Verbindung deines Mitarbeiters zu deiner Gemeinschaft wird getrennt. Dein/e Mitarbeiter:in hat keinen Zugriff mehr.',
        text: 'Jetzt Verbindung zum/zur Mitarbeiter:in lösen?',
        title: 'Verbindung zum Mitarbeiter lösen',
      },
      title: 'Your employees',
    },
    filters: {
      admin: 'Pengueen Admin',
      search: 'Search by name',
      visibility: 'Column visibility',
    },
    members: {
      add: 'Add user',
      promote: {
        btn: 'Hochstufen',
        text1: 'Möchtest du das Mitglied',
        text2: 'zum Mitarbeiter:in deiner Gemeinschaft machen?',
        text3:
          'Das Mitglied erhält hierdurch erweiterte Rechte, kann sich zum Beispiel mit anderen Mitgliedern austauschen und Mitglieder einladen.',
        title: 'Mitglied zum/zur Mitarbeiter:in hochstufen?',
      },
      remove: {
        subtitle:
          'Die Verbindung deines Mitgliedes zu deiner Gemeinschaft wird getrennt. Dein Mitglied hat keinen Zugriff mehr.',
        text: 'Jetzt Verbindung zum Mitglied lösen?',
        title: 'Verbindung zum Mitglied löschen',
      },
      title: 'Your members',
    },
    nameless: 'No name specified',
    roles: {
      admin: 'Admin',
      employee: 'Employee',
      member: 'Member',
    },
    table: {
      actions: {
        demote: 'Demote to member',
        message: 'Message',
        remove: 'Disband',
      },
      address: 'Address',
      admin: 'Pengueen Admin',
      email: 'E-Mail',
      invited: 'Invited on',
      name: 'Name',
      phone: 'Phone number',
      tools: 'Tools',
    },
  },
};
