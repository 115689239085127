import { Box } from '@mui/material';
import React from 'react';
import {
  ChangedByCellProps,
  ColumnWidth,
  CustomGridColDef,
  MultiElementColumnGradeParams,
  MultiElementColumnHeaderParams,
  MultiElementColumnStarParams,
  MultiElementRowActions,
  ToolValuesMultiElementRow,
} from '../../../modules/tools/models/tools.types';
import { Icon } from '../../../shared/ui/Icon/Icon';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';
import {
  AttachmentFile,
  Theme,
  Option,
} from '../../../shared/models/shared.types';
import styles from './ToolMultiElementTable.module.scss';
import {
  dateOptions,
  dateTimeOptions,
  timeOptions,
} from '../../../modules/tools/utils/tools.utils';
import clsx from 'clsx';
import { IconButton } from '../../../shared/ui/IconButton/IconButton';
import {
  TemplateElement,
  TemplateElementType,
} from '../../../modules/templates/models/templates.types';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import ColumnStar from '../../../modules/tools/components/ToolMultiElementTable/ColumnStar';
import ColumnGrade from '../../../modules/tools/components/ToolMultiElementTable/ColumnGrade';
import { messageHandleContent } from '../../../new_shared/utils/shared.utils';

export const NormalCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.value && (
      <div
        className={styles['column-text']}
        dangerouslySetInnerHTML={{
          __html: `${params.value}`,
        }}
      />
    )}
  </>
));

export const UploadCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.id !== 'total' && params.value && (
      <>
        {params.value.map((file: AttachmentFile, index: number) => (
          <Tooltip title={file?.name}>
            <div className={styles['upload-item-single']}>
              <a
                style={{ color: '#8ea3b6!important' }}
                href={`${file.url}/${file?.name}`}
                target="_blank"
                download={`${file.name}`}
              >
                {file.url && file.type.includes('image') && (
                  <img
                    alt={file.name}
                    className="upload-item-image object-cover rounded-md shrink-0 w-6 h-6"
                    src={file.url}
                  />
                )}
                {!file.type.includes('image') && (
                  <Icon
                    icon={['fas', 'file']}
                    sx={{ color: 'text.secondary' }}
                    classes="upload-item-image object-cover rounded-md shrink-0 w-6 h-6"
                  />
                )}
                {!file.type.includes('image') && (
                  <Box
                    sx={{ color: 'text.secondary' }}
                    className={styles['upload-item-single-name']}
                  >
                    {file?.name}
                  </Box>
                )}
              </a>
            </div>
          </Tooltip>
        ))}
      </>
    )}
  </>
));

export const DatePickerCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.id !== 'total' && params.value && (
      <Box className="text-xs">
        {`${new Intl.DateTimeFormat('de', dateTimeOptions).format(
          new Date(params.value)
        )}${' Uhr'}`}
      </Box>
    )}
  </>
));

export const DayCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.id !== 'total' && params.value && (
      <Box className="text-xs">
        {`${new Intl.DateTimeFormat('de', dateOptions).format(
          new Date(params.value)
        )}${''}`}
      </Box>
    )}
  </>
));

export const TimeCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.id !== 'total' && params.value && (
      <Box className="text-xs">
        {`${new Intl.DateTimeFormat('de', timeOptions).format(
          new Date(params.value)
        )}${' Uhr'}`}
      </Box>
    )}
  </>
));

export const NumberCell = React.memo(
  ({ params, suffix, hasSuffix, thousandPoint }: ChangedByCellProps) => (
    <>
      {thousandPoint && params.value && (
        <div
          className={styles['column-text']}
          dangerouslySetInnerHTML={{
            __html: `${params.value}`,
          }}
        />
      )}
      {!thousandPoint && params.value && (
        <div
          className={styles['column-text']}
          dangerouslySetInnerHTML={{
            __html: `${params.value}`,
          }}
        />
      )}

      {hasSuffix && suffix && params.value && (
        <div className="ml-1 text-xs">{suffix}</div>
      )}
    </>
  )
);

export const SelectCell = React.memo(
  ({ params, theme }: ChangedByCellProps) => (
    <>
      {params.value && params.value.name && (
        <Box className={styles['column-select']}>
          {params.row &&
            params.field &&
            params.row[params.field]?.options &&
            params.row[params.field].options.map(
              (option: Option, i: number) => (
                <Box
                  key={i}
                  className={styles['column-select-option']}
                  sx={{
                    backgroundImage: `linear-gradient(to right, ${
                      option && option.color ? option.color : ''
                    } 0.75rem, ${
                      theme === Theme.Dark ? '#202020' : 'white'
                    } 0.75rem)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                    fontSize: '10px !important',
                  }}
                >
                  {option && option.name ? option.name : ''}
                </Box>
              )
            )}
        </Box>
      )}
    </>
  )
);

export const ContactCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.value && (
      <>
        <div className={styles['contact-basic']}>
          <Box
            className={styles['contact-basic-info']}
            sx={{ backgroundColor: 'bg.card' }}
          >
            <div className={styles['contact-basic-info-data']}>
              {params.value.avatar ? (
                <img
                  alt={params.value.name}
                  className={styles['contact-basic-info-data-image']}
                  src={params.value.avatar}
                />
              ) : (
                <Box
                  className={clsx(
                    styles['image-fallback'],
                    styles['image-fallback-size']
                  )}
                  sx={{ backgroundColor: 'bg.card' }}
                >
                  <Icon
                    icon={['fal', 'user']}
                    sx={{
                      color: 'text.secondary',
                      height: '0.8em',
                      width: '0.8em',
                    }}
                  />
                </Box>
              )}
              <div className={styles['contact-basic-info-data-name']}>
                {params.value.name}
              </div>
            </div>
          </Box>
          <></>
        </div>
      </>
    )}
  </>
));

export const DataFromOtherToolContactCell = React.memo(
  ({ params, account_id }: ChangedByCellProps) => (
    <>
      {params.value && (
        <>
          <div className={clsx(styles['contact-basic'])}>
            <Box
              className={styles['contact-basic-info']}
              sx={{ backgroundColor: 'bg.card' }}
            >
              <div className={styles['contact-basic-info-data']}>
                {params.value.avatar ? (
                  <img
                    alt={params.value.contactname}
                    className={styles['contact-basic-info-data-image']}
                    src={params.value.avatar}
                  />
                ) : (
                  <Box
                    className={clsx(
                      styles['image-fallback'],
                      styles['image-fallback-size']
                    )}
                    sx={{ backgroundColor: 'bg.card' }}
                  >
                    <Icon
                      icon={['fal', 'user']}
                      sx={{
                        color: 'text.secondary',
                        height: '0.8em',
                        width: '0.8em',
                      }}
                    />
                  </Box>
                )}
                <div className={styles['contact-basic-info-data-name']}>
                  {params.value.contactname}
                </div>
              </div>
            </Box>
            <>
              <IconButton
                classes={styles['contact-basic-button']}
                disabled={params.value.id === account_id}
                icon={['fad', 'messages']}
                preset="secondary"
              />
            </>
          </div>
        </>
      )}
    </>
  )
);

export const ToggleCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.value && (
      <>
        {params.value && (
          <div className={styles['column-text']}>
            {params.value === true || params.value === 'true' ? 'ja' : 'nein'}
          </div>
        )}
        {params.value === '' ||
          (params.value === undefined && (
            <div className={styles['column-text']}>Nein</div>
          ))}
      </>
    )}
  </>
));

export const CreatedByCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.value && (
      <Box className="text-xs">
        {params.row?.created_by}
        <br />
        {`${Intl.DateTimeFormat('de', dateTimeOptions).format(
          new Date(params.row?.created_at)
        )} Uhr`}
      </Box>
    )}
  </>
));

export const ChangedByCell = React.memo(({ params }: ChangedByCellProps) => (
  <>
    {params.value && (
      <Box className="text-xs">
        {params.value}
        <br />
        {`${Intl.DateTimeFormat('de', dateTimeOptions).format(
          new Date(params.row?.changed_at)
        )} Uhr`}
      </Box>
    )}
  </>
));

export const ColumnHeader = React.memo(
  (props: MultiElementColumnHeaderParams) => (
    <div className="flex items-center truncate font-semibold px-2">
      {props.title}
    </div>
  )
);

export const ActionCell = React.memo((props: MultiElementRowActions) => {
  return (
    <Box
      className="flex w-full h-full justify-center"
      sx={{ backgroundColor: '#C0E2FA' }}
      onClick={(e) => e.stopPropagation()}
    >
      {/* Deine Logik für das Rendern der Action-Komponenten */}

      {/* Weitere Action-Komponenten */}
      <IconButton icon={['fal', 'eye']} sxIcon={{ color: 'primary.main' }} />
      {/* {props.tool_chat && props.rowId !== 'total' && (
        <IconButton
          icon={['fal', 'messages']}
          sxIcon={{ color: 'text.secondary' }}
          onClick={() =>
            props.onRowShowChat &&
            props.onRowShowChat({
              roomId: props.roomId,
              rowId: props.rowId,
            })
          }
        />
      )} */}
      {props.values?.can_edit && (
        <>
          <IconButton
            icon={['fal', 'pen']}
            sxIcon={{ color: 'primary.main' }}
            // onClick={() =>onRowEdit(props.rowId)} TODO FIX
          />

          {props.can_delete && (
            <IconButton
              icon={['fal', 'trash']}
              sxIcon={{ color: 'primary.main' }}
              onClick={() => props.onDeleteRow(props.rowId)}
            />
          )}
        </>
      )}
    </Box>
  );
});

export const getColumnForTableInTool = (
  element: TemplateElement,
  colWidth?: number
) => {
  let element_name = element.name || '';

  const disable_export = element?.settings?.export?.disableExport || false;

  let gridDef: GridColDef = {
    field: element.name,
    type: element.type || undefined,
    disableExport: disable_export,
    width: colWidth || 150,
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <ColumnHeader {...params} title={element_name} onToggle={() => {}} />
      );
    },
  };

  if (
    element.type === TemplateElementType.Select ||
    element.type === TemplateElementType.RadioButton ||
    element.type === TemplateElementType.Checkbox
  ) {
    gridDef = {
      ...gridDef,
      valueGetter: (params: GridValueGetterParams) => {
        if (Array.isArray(params.row[params.field])) {
          return params.row[params.field]
            .map((item: { name: any }) => item.name)
            .join(' ');
        }
        if (typeof params.value === 'object') {
          return params.value.name;
        }
        return params.value;
      },
      renderCell: (params: GridRenderCellParams) => {
        params.value = params.row[params.field];
        return <SelectCell params={params} />;
      },
    };
  } else if (element.type === TemplateElementType.Toggle) {
    gridDef = {
      ...gridDef,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.value === true || params.value === 'true') {
          return 'ja true';
        }
        return 'nein false';
      },
      renderCell: (params: GridRenderCellParams) => {
        params.value = params.row[params.field];
        return <ToggleCell params={params} />;
      },
    };
  } else if (element.type === TemplateElementType.MultiElementCreateRow) {
    gridDef = {
      ...gridDef,
      renderCell: (params: GridRenderCellParams) => (
        <CreatedByCell params={params} />
      ),
    };
  } else if (element.type === TemplateElementType.Contact) {
    gridDef = {
      ...gridDef,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value?.name;
      },
      renderCell: (params: GridRenderCellParams) => {
        params.value = params.row[params.field];
        return <ContactCell params={params} />;
      },
    };
  } else if (element.type === TemplateElementType.DatePicker) {
    gridDef = {
      ...gridDef,

      renderCell: (params: GridRenderCellParams) => (
        <DatePickerCell params={params} />
      ),
    };
  } else if (element.type === TemplateElementType.Day) {
    gridDef = {
      ...gridDef,
      renderCell: (params: GridRenderCellParams) => <DayCell params={params} />,
    };
  } else if (element.type === TemplateElementType.Time) {
    gridDef = {
      ...gridDef,
      renderCell: (params: GridRenderCellParams) => (
        <TimeCell params={params} />
      ),
    };
  } else if (element.type === TemplateElementType.Upload) {
    gridDef = {
      ...gridDef,
      valueGetter: (params: GridValueGetterParams) => {
        if (Array.isArray(params.row[params.field])) {
          return params.row[params.field]
            .map((item: { name: any }) => item.name)
            .join(' ');
        }
        return params.value;
      },
      renderCell: (params: GridRenderCellParams) => {
        params.value = params.row[params.field];
        return <UploadCell params={params} />;
      },
    };
  } else if (element.type === TemplateElementType.Number) {
    gridDef = {
      ...gridDef,
      renderCell: (params: GridRenderCellParams) => (
        <NumberCell
          params={params}
          hasSuffix={element?.settings?.advanced?.hasSuffix || false}
          suffix={element.suffix || ''}
          thousandPoint={element.settings?.advanced?.thousandPoint || false}
        />
      ),
    };
  } else if (element.type === TemplateElementType.Star) {
    gridDef.renderCell = (params: GridRenderCellParams) => {
      if (params.id !== 'total') {
        let extendedParams: MultiElementColumnStarParams = {
          ...params,
        };
        if (element && element.vote) {
          extendedParams = {
            ...params,
            vote_element: element,
            // rowId: params.id as string,
            // element: element,
            // toolId: toolId,
            // sectionId: sectionId,
          };
        }
        return <ColumnStar {...extendedParams} />;
      }
    };
    gridDef.renderHeader = (params: GridColumnHeaderParams) => {
      const extendedParams: MultiElementColumnHeaderParams = {
        ...params,
        checked: true,
        title: element_name,
        onToggle: (checked) => {},
      };
      return <ColumnHeader {...extendedParams} />;
    };
    gridDef.width = colWidth
      ? colWidth
      : element.vote && element.vote! < 6
      ? 250
      : 320;
  } else if (element.type === TemplateElementType.Grade) {
    gridDef.renderCell = (params: GridRenderCellParams) => {
      if (params.id !== 'total') {
        let extendedParams: MultiElementColumnGradeParams = {
          ...params,
        };
        if (element && element.vote) {
          extendedParams = {
            ...params,
            vote_element: element,
            //   rowId: params.id as string,
            //   element: element,
            //   toolId: toolId,
            //   sectionId: sectionId,
          };
        }

        return <ColumnGrade {...extendedParams} />;
      }
    };
    gridDef.renderHeader = (params: GridColumnHeaderParams) => {
      const extendedParams: MultiElementColumnHeaderParams = {
        ...params,
        checked: true,
        title: element_name,
        onToggle: (checked) => {},
      };
      return <ColumnHeader {...extendedParams} />;
    };
    gridDef.width = colWidth || 250;
  } else {
    gridDef = {
      ...gridDef,
      renderCell: (params: GridRenderCellParams) => (
        <NormalCell params={params} />
      ),
    };
  }

  return gridDef;
};

export const getColumnForDataFromOtherTool = (
  element: TemplateElement,
  colWidth?: number,
  values?: ToolValuesMultiElementRow[]
) => {
  const cols = [];
  const disable_export = element?.settings?.export?.disableExport || false;

  let gridDef: GridColDef = {
    field: element.name,
    type: element.type || undefined,
    disableExport: disable_export,
    width: colWidth || 150,
    cellClassName: 'super-app-theme--cell',
    headerClassName: 'super-app-theme--cell',
    renderHeader: (params: GridColumnHeaderParams) => {
      return (
        <ColumnHeader {...params} title={element.name} onToggle={() => {}} />
      );
    },
  };

  const getName = (
    values: ToolValuesMultiElementRow[],
    id: string,
    fieldname: string
  ) => {
    let name;

    for (const value of values) {
      if (name !== undefined) {
        continue;
      }
      name = value[fieldname] ?? undefined;
    }
    return name;
  };

  if (
    element.settings?.advanced?.show_contact_at_table_for_data_from_other_tool
  ) {
    gridDef = {
      ...gridDef,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value?.contactname;
      },
      renderCell: (params: GridRenderCellParams) => {
        params.value = params.row[params.field];
        return <DataFromOtherToolContactCell params={params} />;
      },
    };
    cols.push(gridDef);
  }

  // OtherTool GROUP
  if (
    element.settings?.advanced?.show_group_at_table_for_data_from_other_tool
  ) {
    let colGroupData: GridColDef = {
      field: element.name + '3',
      type: TemplateElementType.DataFromOtherToolGroup,
      disableExport: disable_export,
      renderCell: (params: GridRenderCellParams) => {
        if (params.id !== 'total') {
          return (
            <div>
              {params.value && params.value.group_name
                ? params.value.group_name
                : ''}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const extendedParams: MultiElementColumnHeaderParams = {
          ...params,
          checked: true,
          title: 'Gruppe',
          // #TODO: Implement store logic
          onToggle: (checked) => {},
        };
        return <ColumnHeader {...extendedParams} />;
      },
      width: colWidth ?? 150,
      disableColumnMenu: true,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--cell',
    };

    cols.push(colGroupData);
  }
  // OtherTool
  if (element.settings?.advanced?.template_elements_for_data_from_other_tool) {
    const fieldIds =
      element.settings?.advanced?.template_elements_for_data_from_other_tool;
    const arrFieldIdsStr =
      typeof fieldIds == 'string' ? fieldIds.slice(1, -1) : '';
    const arrFieldIdsArr = arrFieldIdsStr.split(',');
    const arrFieldArrStr = arrFieldIdsArr.map((id) => id.trim().slice(1, -1));

    for (let j = 0; j < arrFieldArrStr.length; j++) {
      const name = element.name + (4 + j) || '';
      const value = values
        ? getName(values, arrFieldArrStr[j], name)
        : undefined;

      const colData: CustomGridColDef = {
        field: element.name + (4 + j),
        type: TemplateElementType.DataFromOtherTool,
        disableExport: disable_export,
        tableHeaderName: value?.name ?? '',
        valueGetter: (params) => {
          const value = params.value ?? undefined;
          return value && value.value ? value.value : '';
        },
        renderCell: (params: GridRenderCellParams) => {
          if (params.id !== 'total') {
            return (
              <div
                className={styles['column-text']}
                dangerouslySetInnerHTML={{
                  __html: `${messageHandleContent(params.value)}`,
                }}
              />
            );
          } else {
            return <div></div>;
          }
        },
        renderHeader: (params: GridColumnHeaderParams) => {
          const extendedParams: MultiElementColumnHeaderParams = {
            ...params,
            checked: true,
            title: value && value.name ? value.name : '',
            // #TODO: Implement store logic
            onToggle: (checked) => {},
          };
          return <ColumnHeader {...extendedParams} />;
        },
        width: colWidth ?? 150,
        disableColumnMenu: true,
        cellClassName: 'super-app-theme--cell',
        headerClassName: 'super-app-theme--cell',
      };
      cols.push(colData);
    }
  }
  return cols;
};

export const getColumnsForAdmin = (
  width_created_at?: number,
  width_changed_by?: number
) => {
  const cols = [];

  const colCreatedAt: GridColDef = {
    field: 'created_at',
    type: 'date',
    renderCell: (params: GridRenderCellParams) => (
      <CreatedByCell params={params} />
    ),
    width: width_created_at ?? 200,
    disableColumnMenu: true,
  };
  cols.push(colCreatedAt);

  // Add create change info
  const colChangedBy: GridColDef = {
    field: 'changed_by',
    renderCell: (params: GridRenderCellParams) => (
      <ChangedByCell params={params} />
    ),
    width: width_changed_by ?? 250,
    disableColumnMenu: true,
  };
  cols.push(colChangedBy);

  return cols;
};

export const getColumns = (
  accessToken: string,
  element: TemplateElement,
  isAdmin: boolean,
  toolId: string,
  sectionId: string,
  toolChat: boolean,
  values: any[],
  handleDeleteRowModal?: () => {},
  onRowShowChat?: () => {}
) => {
  let cols: GridColumns = [];

  // get columns width from local storage
  let colWidth;
  let updatedColumnWidths: ColumnWidth[] = [];
  const savedColumnWidths = localStorage.getItem(
    `${accessToken}columnWidths${toolId}${element.id}`
  );
  if (savedColumnWidths) {
    updatedColumnWidths = JSON.parse(savedColumnWidths);
  }

  if (element.elements && element.elements.length > 0) {
    let isCreatorDisplayed = false;

    for (let i = 0; i < element.elements.length; i++) {
      //not_showing_at_table
      if (element.elements![i].settings?.advanced?.not_showing_at_table) {
        continue;
      }
      // set columns width
      const column = updatedColumnWidths.find(
        (col) => col.field === element.elements?.[i].name
      );
      if (column) {
        colWidth = Number(column.width);
      }
      if (element.elements[i].type === TemplateElementType.DataFromOtherTool) {
        const columns_from_other_tool = getColumnForDataFromOtherTool(
          element.elements[i],
          colWidth,
          values
        );
        cols = [...cols, ...columns_from_other_tool];
      } else {
        const column = getColumnForTableInTool(element.elements[i], colWidth);
        cols.push(column);
      }
    }

    if (isAdmin) {
      let width_created_at;
      let width_changed_by;

      const index_created_at = updatedColumnWidths.findIndex(
        (col) => col.field === 'created_by'
      );
      if (index_created_at > -1) {
        width_created_at = Number(updatedColumnWidths[index_created_at].width);
      }
      const index_changed_by = updatedColumnWidths.findIndex(
        (col) => col.field === 'created_by'
      );
      if (index_changed_by > -1) {
        width_changed_by = Number(updatedColumnWidths[index_changed_by].width);
      }
      const colums_for_admin = getColumnsForAdmin(
        width_created_at,
        width_changed_by
      );
      cols = [...cols, ...colums_for_admin];
    }

    // Actions column
    const colActions: GridColDef = {
      // flex: 1,
      field: 'actions',
      headerName: 'Aktionen',
      minWidth: 120,
      filterable: false,
      renderHeader: () => {
        return (
          <Box
            className="flex w-full justify-center"
            sx={{
              minWidth: 120,
              backgroundColor: 'primary.main',
              color: 'background.paper',
            }}
          >
            Aktion
          </Box>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const curRow = values.find((row) => row.id === (params.id as string));
        const paramsWithIds: MultiElementRowActions = {
          rowId: params.id as string,
          element: element,
          toolId: toolId,
          sectionId: sectionId,
          can_delete: element.settings?.advanced?.only_admin_can_remove_posts
            ? curRow?.can_delete
            : true,
          values: curRow,
          roomId: curRow?.roomId,
          onDeleteRow: handleDeleteRowModal,
          tool_chat: toolChat,
          onRowShowChat: onRowShowChat,
        };
        return <ActionCell {...paramsWithIds} />;
      },
      sortable: false,
      disableColumnMenu: true,
    };

    cols.push(colActions);
  }
  return cols;
};
