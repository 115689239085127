import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useResponsive from '../../new_shared/hooks/use-responsive ';
import { Icon } from '../../shared/ui/Icon/Icon';
import styles from './Navigation.module.scss';
import nav_background from '../../assets/pictures/dashboard/navBackground.png';
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import {
  ContactsState,
  useContactsStore,
} from '../../modules/contacts/stores/use-contacts.store';
import { NavCommunity } from './NavCommunity/NavCommunity';
import {
  AccordionExpandedGet,
  AccordionExpansionState,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactsGetFilters,
} from '../../modules/contacts/models/contacts.types';
import { CommunityUserRole } from '../../modules/communities/models/communities.types';
import { useContacts } from '../../modules/contacts/hooks/use-contacts.hook';
import { thinScroll } from '../../shared/models/shared.types';
import { NaviTitle } from './NaviTitle/NaviTitle';
import { useMutation } from 'react-query';
import { useFetch } from '../../shared/hooks/use-fetch.hook';
import { useContactsHttp } from '../../modules/contacts/hooks/use-contacts-http.hook';
import { Loader } from '../../shared/ui/Loader/Loader';
import {
  UserState,
  useUserStore,
} from '../../modules/user/stores/use-user.store';

type NavigationProps = {
  isLoading: boolean;
  selectedItem?: string;
  expanded: AccordionExpansionState;
  setExpanded: React.Dispatch<React.SetStateAction<AccordionExpansionState>>;
  onContactsGet: (
    action: ContactCommunityGetAction,
    params: ContactsGetFilters
  ) => void;
  // expanded: AccordionExpansionState;
  // setExpanded: Dispatch<SetStateAction<AccordionExpansionState>>;
  setContactCommunity: Dispatch<SetStateAction<ContactCommunity>>;
  setMemberRoleCommunity: Dispatch<SetStateAction<CommunityUserRole>>;
};

export const Navigation = memo(
  ({
    isLoading,
    expanded,
    selectedItem,
    onContactsGet,
    setExpanded,
    setContactCommunity,
    setMemberRoleCommunity,
  }: NavigationProps) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const { newContactsNavGet } = useContacts();
    const { handleRetry } = useFetch();
    const [account] = useUserStore((state: UserState) => [state.account]);
    const [navLoading, setNavLoading] = useState(false);
    // Contacts Store State
    const [navCommunities, setNavCommunities, setGroupChats] = useContactsStore(
      (state: ContactsState) => [
        state.navCommunities,
        state.setNavCommunities,
        state.setGroupChats,
      ]
    );

    // Get Nav items
    useEffect(() => {
      if (navCommunities.length === 0) {
        setTimeout(() => newContactsNavGet(), 500); // after get changes
      }
    }, []);

    // Set show Loader
    useEffect(() => {
      if (navCommunities.length === 0) {
        setNavLoading(true);
        setTimeout(() => {
          setNavLoading(false);
        }, 3000);
      } else {
        setNavLoading(false);
      }
    }, [navCommunities.length]);

    return (
      <Box className={styles['navigation-container']} navigation-containe>
        {/* Navigation Contents */}
        <div className={styles['navigation-content']}>
          <Box className="p-6 pr-4 overflow-y-auto grow" sx={{ ...thinScroll }}>
            <NaviTitle title="Meine Gemeinschaften" />

            {/* Nav Community */}
            {navLoading ? (
              <Loader />
            ) : (
              (navCommunities || []).map((community) => (
                <NavCommunity
                  key={community.id}
                  community={community}
                  expanded={expanded}
                  selectedItem={selectedItem}
                  onContactsGet={onContactsGet}
                  setExpanded={setExpanded}
                  setContactCommunity={setContactCommunity}
                  setMemberRoleCommunity={setMemberRoleCommunity}
                />
              ))
            )}
          </Box>
          <Box className={styles['navigation-content-footer']}>
            <Box
              sx={{
                color: 'white',
                backgroundColor: 'primary.main',
                borderColor: 'white',
                border: '1px solid',
              }}
              className={styles['navigation-content-footer-button']}
            >
              <Icon
                classes={styles['navigation-content-footer-button-icon']}
                icon={['fas', 'tools']}
                sx={{ color: 'white' }}
              />
              <span>{'Meine Online Tools'.toUpperCase()}</span>
            </Box>
          </Box>
        </div>

        {/* Background image container */}
        <Box
          className={styles['navigation-background']}
          sx={{
            backgroundColor: isMobile ? 'bg.newNavImage' : undefined,
          }}
        >
          <img src={nav_background} alt="background" />
        </Box>
      </Box>
    );
  }
);
