import {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Contexts
import { SocketContext } from '../../../../shared/context/socket.context';
import { useStableNavigate } from '../../../../shared/components/StableNavigateContext/StableNavigateContext';

// Components
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { Message } from '../Message/Message';
import { MessageQuote } from '../MessageQuote/MessageQuote';
import { NewsCenterChatEditor } from '../NewsCenterChatEditor/NewsCenterChatEditor';
import { NewsCenterChatHeader } from '../NewsCenterChatHeader/NewsCenterChatHeader';
import { FilePreview } from '../FilePreview/FilePreview';
import { Icon } from '../../../../shared/ui/Icon/Icon';

// Hooks
import { useNewsCenter } from '../../hooks/use-news-center.hook';

// Models
import {
  Message as IMessage,
  InfoMessageType,
  MessageAction,
  SocketFile,
} from '../../models/message.types';
import {
  GroupRoomSettings,
  GroupRoomUsersPatchResponse,
  IsTypingResponse,
  Room,
  RoomType,
} from '../../models/news-center.types';
import { SocketEventSubscriptionResponse } from '../../../../shared/models/shared.types';
import { User } from '../../../user/models/user.types';

// Stores
import { AuthState, useAuthStore } from '../../../public/stores/use-auth.store';
import { UserState, useUserStore } from '../../../user/stores/use-user.store';
import {
  NewsCenterState,
  useNewsCenterStore,
} from '../../stores/use-news-center.store';

// Styles
import styles from './NewsCenterChat.module.scss';
import { ToolFavorite } from '../../../dashboard/models/dashboard.types';
import { useMutation } from 'react-query';
import { useContactsHttp } from '../../../contacts/hooks/use-contacts-http.hook';
import { FavoriteTool } from '../../../dashboard/components/WidgetFavoriteTools/WidgetFavoriteTools';

const ImageIsLoading = () => {
  return (
    <div className="flex flex-row justify-end mt-2">
      <Loader classes="flex justify-end" />
      <div className="flex w-10"></div>
    </div>
  );
};

type NewsCenterChatProps = {
  type: RoomType;
  disable_header?: boolean;
  roomId?: string;
  tool_id?: string;
  section_id?: string;
  multi_id?: string;
  row_id?: string;
  // participant_group_id?: string;
};

export const NewsCenterChat = (props: NewsCenterChatProps) => {
  // ######## //
  // CONTEXTS //
  // ######## //
  const socket = useContext(SocketContext);

  // ##### //
  // HOOKS //
  // ##### //

  const navigate = useStableNavigate();
  const {
    directRoomGetEffect,
    groupRoomGetEffect,
    messagesGetEffect,
    messageCreateEffect,
    messageDeleteEffect,
    messageUpdateEffect,
    roomUnreadMessagesGet,
    groupRoomSettingsUpdateEffect,
    groupRoomUsersUpdateEffect,
    groupRoomUserDeleteEffect,
  } = useNewsCenter();
  const { id } = useParams<'id'>();
  const { rowid } = useParams<'rowid'>();
  const { t } = useTranslation();

  // #### //
  // REFS //
  // #### //
  const messagesScrollRef = useRef<null | HTMLDivElement>(null);
  const messagesScrollTopRef = useRef<null | HTMLDivElement>(null);

  // ##### //
  // STATE //
  // ##### //

  // Component state
  const [loading, setLoading] = useState<boolean>(false);
  const [messageEdit, setMessageEdit] = useState<IMessage | undefined>(
    undefined
  );
  const [messageQuote, setMessageQuote] = useState<IMessage | undefined>(
    undefined
  );
  const [messageState, setMessageState] = useState<MessageAction | undefined>(
    undefined
  );
  const [selectedChatUsers, setSelectedRoomUsers] = useState<Partial<User>[]>(
    []
  );
  const [showGroupInfo, setShowGroupInfo] = useState<boolean>(true);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [usersTyping, setUsersTyping] = useState<IsTypingResponse[]>([]);
  const [newMessage, setNewMessage] = useState<boolean | undefined>(false);

  // Auth store state
  const [accessToken] = useAuthStore((state: AuthState) => [state.accessToken]);

  // News center store state
  const [
    tempMessage,
    selectedFiles,
    selectedRoom,
    selectedRoomMessages,
    setTempMessage,
    setSelectedFiles,
    setSelectedRoom,
    setSelectedRoomMessages,
  ] = useNewsCenterStore((state: NewsCenterState) => [
    state.tempMessage,
    state.selectedFiles,
    state.selectedRoom,
    state.selectedRoomMessages,
    state.setTempMessage,
    state.setSelectedFiles,
    state.setSelectedRoom,
    state.setSelectedRoomMessages,
  ]);

  // User store state
  const [account] = useUserStore((state: UserState) => [state.account]);

  // ####### //
  // EFFECTS //
  // ####### //

  // Extend dayjs with LocalizedFormat on mount
  useEffect(() => {
    dayjs.extend(LocalizedFormat);
    dayjs.extend(dayOfYear);
  }, []);

  useEffect(() => {
    setSelectedRoom(undefined);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   // Scroll to Bottom
  //   !tempMessage?.quote && onScrollIntoMessagesView();
  //   // eslint-disable-next-line
  // }, [selectedFiles, tempMessage]);

  // Join socket room on component mount
  useEffect(() => {
    if (account) {
      if ((id && id.length > 0) || props.roomId) {
        socket.emit(
          'joinRoom',
          {
            accessToken,
            room_id: props.roomId ? props.roomId : id ? id : undefined,
            type: props.type,
            tool_id: props.tool_id,
            section_id: props.section_id,
            multi_id: props.multi_id,
            row_id: props.row_id,
          },
          (response: SocketEventSubscriptionResponse) => {
            if (response) {
              unstable_batchedUpdates(() => {
                setUnreadMessages(
                  roomUnreadMessagesGet(account?.id ?? '', selectedRoomMessages)
                    .length
                );
                setMessageEdit(undefined);
                setMessageQuote(undefined);
                setMessageState(undefined);
                setSelectedFiles([]);

                const data = response.data as Room;
                setSelectedRoom(data);
                setSelectedRoomMessages(data.messages ?? []);
                setSelectedRoomUsers(data.users);
                // setUnreadMessages(data.unread_msgs ? data.unread_msgs : 0);

                if (data.type === RoomType.Group) {
                  groupRoomGetEffect(data.id);
                }
                if (data.type === RoomType.Direct) {
                  directRoomGetEffect(data.id);
                }
              });
            }
          }
        );
      }

      return () => {
        // Leave socket room on component unmount
        if ((id && id.length > 0) || props.roomId) {
          socket.emit('leaveRoom', {
            accessToken,
            room_id: id ? id : props.roomId,
          });
        }
        setTempMessage(undefined);
        setUsersTyping([]);
        setSelectedRoom(undefined);
      };
    }
    // eslint-disable-next-line
  }, [
    account,
    id,
    // selectedRoom?.users.length,
    // selectedRoom?.inactive_room,
    props.roomId,
  ]);

  // move scroll to first unread message
  useEffect(() => {
    if (messagesScrollTopRef.current) {
      messagesScrollTopRef.current.scrollIntoView();
    }
  }, [newMessage]);

  // Update room data
  useEffect(() => {
    const updateRoomSettings = (data: {
      room_id: string;
      settings: GroupRoomSettings;
    }) => {
      groupRoomSettingsUpdateEffect(data);
    };

    const addRoomUsers = (data: GroupRoomUsersPatchResponse) => {
      groupRoomUsersUpdateEffect(data);
    };

    const deleteRoomUser = (data: { room_id: string; user_id: string }) => {
      groupRoomUserDeleteEffect(data);
    };

    socket.on('updateRoomSettings', updateRoomSettings);
    socket.on('addRoomUsers', addRoomUsers);
    socket.on('deleteRoomUser', deleteRoomUser);
    return () => {
      socket.off('updateRoomSettings', updateRoomSettings);
      socket.off('addRoomUsers', addRoomUsers);
      socket.off('deleteRoomUser', deleteRoomUser);
    };
    // eslint-disable-next-line
  }, [selectedRoom]);

  // Subscribe to message events
  useEffect(() => {
    // Add message to state
    const createMessage = (data: { message: IMessage; room_id: string }) => {
      messageCreateEffect(data);
      socket.emit('readMessage', {
        accessToken: accessToken,
        message_id: data.message.id,
        room_id: data.room_id,
      });
    };

    // Update message in state
    const updateMessage = (message: IMessage) => {
      messageUpdateEffect(message);
    };

    // Delete message in state
    const deleteMessage = (data: { message_id: string; room_id: string }) => {
      messageDeleteEffect(data.message_id);
    };
    for (const message of selectedRoomMessages) {
    }
    selectedRoomMessages.map((message, index: number) => {
      setNewMessage(
        (index === 0 &&
          !selectedRoomMessages![index].read.find(
            (user) => user.account?.id === account?.id
          )) ||
          (index > 1 &&
            selectedRoomMessages![index - 1].read.find(
              (user) => user.account?.id === account?.id
            ) &&
            !selectedRoomMessages![index].read.find(
              (user) => user.account?.id === account?.id
            ))
      );
      return message;
    });

    // Subscribe on socket events
    socket.on('createMessage', createMessage);
    socket.on('updateMessage', updateMessage);
    socket.on('deleteMessage', deleteMessage);
    return () => {
      socket.off('createMessage', createMessage);
      socket.off('updateMessage', updateMessage);
      socket.off('deleteMessage', deleteMessage);
    };
    // eslint-disable-next-line
  }, [selectedRoomMessages]);

  // Handle isTyping socket event
  useEffect(() => {
    const isTyping = (data: IsTypingResponse) => {
      const userIsTypingIndex = usersTyping.findIndex(
        (user) => user.client_id === data.client_id
      );
      if (data.isTyping && data.user?.id !== account?.id) {
        // Add user to typing array on no match
        if (userIsTypingIndex < 0) {
          const updatedUsersTyping = [...usersTyping];
          updatedUsersTyping.push({
            client_id: data.client_id,
            isTyping: true,
            room_id: data.room_id,
            user: data.user
              ? {
                  id: data.user.id,
                  first_name: data.user.first_name,
                  last_name: data.user.last_name,
                }
              : undefined,
          });
          setUsersTyping(updatedUsersTyping);
        }
      } else {
        // Remove user from typing array on match
        if (userIsTypingIndex > -1) {
          const updatedUsersTyping = [...usersTyping];
          updatedUsersTyping.splice(userIsTypingIndex, 1);
          setUsersTyping(updatedUsersTyping);
        }
      }
    };

    // Subscribe on isTyping socket event
    socket.on('isTyping', isTyping);

    // Unsubscribe on isTyping socket event
    return () => {
      socket.off('isTyping', isTyping);
    };
    // eslint-disable-next-line
  }, [account, usersTyping]);

  /*
   * Set last read message by user
   */
  useEffect(() => {
    try {
      if (selectedRoom && selectedRoom.messages?.length > 0) {
        let updatedRoom = selectedRoom;
        const currUser = account;

        if (updatedRoom.messages && updatedRoom.messages.length > 0) {
          let myMessages: IMessage[] = [];
          updatedRoom.messages.map((message) => {
            message.create_change_info.created_by.account?.id ===
              currUser?.id && myMessages.push(message);
            return message;
          });

          updatedRoom.users = updatedRoom.users.map((user) => {
            if (user.account?.id !== account?.id) {
              let updatedUser = user;
              if (myMessages && myMessages.length > 1) {
                myMessages.map((message, index) => {
                  if (index > 0 && user.account?.id) {
                    if (
                      !myMessages[index]!.read?.some(
                        (el) => el.account?.id === updatedUser.account?.id
                      ) &&
                      myMessages[index - 1]!.read?.some(
                        (el) => el.account?.id === updatedUser.account?.id
                      )
                    ) {
                      updatedUser.read_message_id = myMessages[index - 1]!.id;
                    }
                    if (
                      myMessages[index]!.read.some(
                        (el) => el.account?.id === updatedUser.account?.id
                      )
                    ) {
                      updatedUser.read_message_id = message!.id;
                    }
                  }
                  return message;
                });
              }
              if (
                myMessages.length === 1 &&
                myMessages[0].read.some(
                  (el) => el.account?.id === updatedUser.account?.id
                )
              ) {
                updatedUser.read_message_id = myMessages[0].id;
              }
              return updatedUser;
            }
            return user;
          });
        }
        setSelectedRoom(updatedRoom);
      }
    } catch (e) {
      console.log('ERROR in setting read users', e);
    }
  }, [account, selectedRoom]);

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to navigate back on small devices.
   */
  const onBack = useCallback(() => {
    // navigate(-1);
    navigate('/newscenter', {
      replace: true,
    });
    setSelectedRoom(undefined);
    // eslint-disable-next-line
  }, [navigate]);

  /**
   * Handler to reset editor state.
   */
  const onEditorStateReset = useCallback(() => {
    setMessageEdit(undefined);
    setMessageQuote(undefined);
    setMessageState(undefined);
    // setSelectedFiles([]);
    // eslint-disable-next-line
  }, []);

  /**
   * Handler to delete an image by index.
   * @param index Selected file index
   */
  const onFileDelete = useCallback(
    (index: number) => {
      const updatedSelectedFiles = [...selectedFiles];
      updatedSelectedFiles.splice(index, 1);
      setSelectedFiles(updatedSelectedFiles);
    },
    // eslint-disable-next-line
    [selectedFiles]
  );

  /**
   * Handler to add messages on scroll.
   */
  const onMessagesAdd = useCallback(() => {
    if (selectedRoom) {
      setLoading(true);
      socket.emit(
        'getMessages',
        {
          accessToken,
          room_id: selectedRoom.id,
          skip: selectedRoomMessages.length,
        },
        (response: SocketEventSubscriptionResponse) => {
          // Add new messages to state on success / if data is available
          const messages = response.data as IMessage[];
          messages && messages.length > 0 && messagesGetEffect(messages);
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [selectedRoom, selectedRoomMessages]);

  /**
   * Handler to edit message.
   * @param message Message
   */
  const onMessageEditInit = useCallback(
    (message: IMessage) => {
      // Only unread messages can be edited
      if (message.read.length < 2) {
        // Set edit message for difference check. Insert empty string because editor state got one at the end
        messageQuote && setMessageQuote(undefined);
        setMessageQuote(message.quote);
        setMessageState(MessageAction.Edit);
        setMessageEdit(message);
      }
    },
    // eslint-disable-next-line
    [messageEdit, messageQuote]
  );

  /**
   * Handler to quote message.
   * @param message Message
   */
  const onMessageQuote = useCallback((message: IMessage) => {
    setMessageQuote(message);
    // setEditorState(EditorState.moveFocusToEnd(editorState));
  }, []);

  /**
   * Handler to init last message edit.
   */
  const onSetLastMessageToEdit = useCallback(() => {
    if (selectedRoomMessages && selectedRoomMessages.length > 0) {
      const messages = [...selectedRoomMessages];
      for (const chatMsg of messages.reverse()) {
        if (
          account &&
          chatMsg.create_change_info.created_by.account?.id === account.id
        ) {
          onMessageEditInit(chatMsg);
          break;
        }
      }
    }
    // eslint-disable-next-line
  }, [account, selectedRoomMessages]);

  /**
   * Scrolls into messages view
   */

  const onScrollIntoMessagesView = useCallback(() => {
    if (messagesScrollRef.current) {
      messagesScrollRef.current.scrollIntoView();
      // { behavior: 'smooth' }
    }
  }, [messagesScrollRef]);

  return (
    <Stack
      className={
        props.type === RoomType.GodParent
          ? styles['godparents-chat']
          : props.type === RoomType.ToolChatGroup
          ? styles['tool-chat-group-chat']
          : styles['news-center-chat']
      }
    >
      {selectedRoom && (
        <>
          {!props.disable_header && (
            <NewsCenterChatHeader
              roomId={props.roomId}
              onBack={onBack}
              type={props.type}
              onMessagesAdd={onScrollIntoMessagesView}
            />
          )}
          {props.disable_header && (
            <div className={styles['tool-chat-header']}>
              <Box className={styles['tool-chat-header-title']}>
                {'Kommentare'}
              </Box>
            </div>
          )}
          {selectedRoom.tools_options &&
            selectedRoom.tools_options.length > 0 &&
            props.type !== RoomType.ToolChatGroup &&
            props.type !== RoomType.ToolChatMultiRow && (
              <Box
                sx={{ borderColor: 'border.app' }}
                className={styles['news-center-chat-tools']}
              >
                {selectedRoom.tools_options.map((tool_option) => {
                  return (
                    <FavoriteTool
                      classes={styles['news-center-chat-tools-favorite']}
                      heightClasses="h-full"
                      key={tool_option.id}
                      tool={tool_option}
                    />
                  );
                })}
              </Box>
            )}

          <Box
            bgcolor="bg.chat"
            id="content"
            className={
              props.roomId
                ? styles['godparents-chat-content']
                : styles['news-center-chat-content']
            }
          >
            {props.type === RoomType.GodParent &&
              (!selectedRoomMessages || selectedRoomMessages.length < 1) && (
                <Box className="flex flex-col items-center justify-center p-4 h-full">
                  <Icon icon={['fad', 'messages']} size="large" />
                  <Box className="text-center mt-7">
                    {t('godparentschat.chat.content.text1')}
                    {t('godparentschat.chat.content.text2')}
                    {selectedRoom.settings?.name}
                    {t('godparentschat.chat.content.text3')}
                  </Box>
                  <Box className="mt-3 text-center">
                    {t('godparentschat.chat.content.text4')}
                  </Box>
                </Box>
              )}
            {/* <div
                    className={styles['news-center-chat-content-selected']}
                    id={styles['news-center-chat-id']}
                  > */}

            <InfiniteScroll
              dataLength={selectedRoomMessages.length}
              hasMore
              inverse
              loader={null}
              next={onMessagesAdd}
              scrollableTarget="content"
              scrollThreshold={1}
              style={{ overflowX: 'hidden', overflowY: 'hidden' }}
              className={styles['news-center-chat-content-selected']}
            >
              {selectedRoomMessages &&
                selectedRoomMessages.length > 0 &&
                selectedRoomMessages.map((message, index: number) => {
                  // Checks for first message of a different user compared to last message
                  // Shows user data and new message(s) info

                  const firstNewMessage =
                    (index === 0 &&
                      !selectedRoomMessages![index].read.find(
                        (user) => user.account?.id === account?.id
                      )) ||
                    (index > 1 &&
                      selectedRoomMessages![index - 1].read.find(
                        (user) => user.account?.id === account?.id
                      ) &&
                      !selectedRoomMessages![index].read.find(
                        (user) => user.account?.id === account?.id
                      ) &&
                      !selectedRoomMessages![index].info) ||
                    (index > 1 &&
                      selectedRoomMessages![index - 1].info &&
                      !selectedRoomMessages![index].info &&
                      selectedRoomMessages![index - 2].read.find(
                        (user) => user.account?.id === account?.id
                      ) &&
                      !selectedRoomMessages![index].read.find(
                        (user) => user.account?.id === account?.id
                      ));

                  // Check for message differences to display user data
                  const newDay =
                    index === 0 ||
                    (index > 0 &&
                      dayjs(
                        selectedRoomMessages![index - 1].create_change_info
                          .created_at
                      ).dayOfYear() !==
                        dayjs(
                          selectedRoomMessages![index].create_change_info
                            .created_at
                        ).dayOfYear());
                  const newTime =
                    index === 0 ||
                    (index > 0 &&
                      dayjs(
                        selectedRoomMessages![index - 1].create_change_info
                          .created_at
                      ).hour() !==
                        dayjs(
                          selectedRoomMessages![index].create_change_info
                            .created_at
                        ).hour());
                  const newUser =
                    index === 0 ||
                    (index > 0 &&
                      selectedRoomMessages![index - 1].create_change_info
                        .created_by.account?.id !==
                        selectedRoomMessages![index].create_change_info
                          .created_by.account?.id) ||
                    newDay;
                  let newInfo = false;
                  if (
                    index > 0 &&
                    Object.keys(selectedRoomMessages![index - 1]).includes(
                      'info'
                    ) &&
                    !Object.keys(selectedRoomMessages![index]).includes('info')
                  ) {
                    newInfo = true;
                  }
                  const change_read_user =
                    index === 0 ||
                    (index > 0 &&
                      selectedRoomMessages![index - 1].read.length !==
                        selectedRoomMessages![index].read.length) ||
                    selectedRoom.users.some(
                      (user) => user.read_message_id === message.id
                    );

                  return (
                    <Fragment key={message.id}>
                      {firstNewMessage && (
                        <div
                          ref={messagesScrollTopRef}
                          className={
                            styles['news-center-chat-content-selected-divider']
                          }
                        >
                          <Box
                            borderColor="success.main"
                            className={
                              styles[
                                'news-center-chat-content-selected-divider-border'
                              ]
                            }
                          ></Box>
                          <Box
                            color="success.main"
                            className={
                              styles[
                                'news-center-chat-content-selected-divider-text'
                              ]
                            }
                          >
                            {unreadMessages < 2
                              ? t('newscenter.room.direct.unread_msgs.single')
                              : t('newscenter.room.direct.unread_msgs.multi')}
                          </Box>
                          <Box
                            borderColor="success.main"
                            className={
                              styles[
                                'news-center-chat-content-selected-divider-border'
                              ]
                            }
                          ></Box>
                        </div>
                      )}
                      {newDay && (
                        <div
                          className={
                            styles['news-center-chat-content-selected-divider']
                          }
                        >
                          <Box
                            borderColor="text.secondary"
                            className={
                              styles[
                                'news-center-chat-content-selected-divider-border'
                              ]
                            }
                          ></Box>
                          <Box
                            color="text.secondary"
                            className={
                              styles[
                                'news-center-chat-content-selected-divider-text'
                              ]
                            }
                          >
                            {dayjs(
                              selectedRoomMessages![index].create_change_info
                                .created_at
                            ).format('ll')}
                          </Box>
                          <Box
                            borderColor="text.secondary"
                            className={
                              styles[
                                'news-center-chat-content-selected-divider-border'
                              ]
                            }
                          ></Box>
                        </div>
                      )}

                      <Message
                        index={index}
                        key={message.id}
                        message={message}
                        showUserData={
                          newDay ||
                          newTime ||
                          newUser ||
                          newInfo ||
                          change_read_user
                        }
                        users={selectedChatUsers}
                        onMessageEdit={() => onMessageEditInit(message)}
                        onMessageQuote={() => onMessageQuote(message)}
                      />
                    </Fragment>
                  );
                })}

              {/* Displayed temporarily before the socket response 'createMessage' arrives. */}
              {tempMessage && (
                <>
                  {(tempMessage.content.length > 8 || tempMessage.info) && (
                    <Message
                      index={Math.random()}
                      message={tempMessage}
                      users={selectedChatUsers}
                      onMessageEdit={() => onMessageEditInit(tempMessage)}
                      onMessageQuote={() => onMessageQuote(tempMessage)}
                    />
                  )}
                  {selectedFiles.length > 0 && <ImageIsLoading />}
                </>
              )}
              <div ref={messagesScrollRef}></div>
            </InfiniteScroll>
            <Loader
              classes={styles['news-center-chat-content-loading']}
              sx={{ opacity: loading ? 1 : 0 }}
              // sx={{
              //   opacity:
              //     groupMessagesGetMutation.isLoading ||
              //     privateMessagesGetMutation.isLoading
              //       ? 1
              //       : 0,
              // }}
            />
            {selectedRoom &&
            selectedRoom.type === RoomType.Group &&
            showGroupInfo &&
            props.type === RoomType.Group ? (
              <Box
                bgcolor="primary.light"
                color="primary.main"
                className={styles['news-center-chat-content-info']}
              >
                <div className={styles['news-center-chat-content-info-text']}>
                  <div
                    className={
                      styles['news-center-chat-content-info-text-name']
                    }
                  >{`${t('newscenter.room.groups.info')} ${
                    selectedRoom.settings?.name
                  }`}</div>
                  <Box
                    className={
                      styles['news-center-chat-content-info-text-name']
                    }
                  >
                    {selectedRoom.settings?.description}
                  </Box>
                </div>
                <IconButton
                  buttonHoverColor="primary.light"
                  iconColor="primary"
                  icon={['fas', 'times']}
                  iconSize="small"
                  onClick={() => setShowGroupInfo(false)}
                />
              </Box>
            ) : (
              <Box className={styles['news-center-chat-content-info']}></Box>
            )}
          </Box>
          {usersTyping.length > 0 && (
            <Box
              className={
                props.roomId
                  ? styles['godparents-chat-typing']
                  : styles['news-center-chat-typing']
              }
              sx={{
                color: 'text.secondary',
                opacity: usersTyping.length > 0 ? 1 : 0,
              }}
            >
              {usersTyping.length === 1 && (
                <div>{`${
                  !usersTyping[0].user?.first_name &&
                  !usersTyping[0].user?.last_name
                    ? t('app.nameless')
                    : (usersTyping[0].user?.first_name ?? '') +
                      ' ' +
                      (usersTyping[0].user?.last_name ?? '')
                } ${t('newscenter.room.typing.single')}`}</div>
              )}

              {usersTyping.length === 2 && (
                <div>{`${
                  !usersTyping[0].user?.first_name &&
                  !usersTyping[0].user?.last_name
                    ? t('app.nameless')
                    : (usersTyping[0].user?.first_name ?? '') +
                      ' ' +
                      (usersTyping[0].user?.last_name ?? '')
                } ${t('app.and')} ${
                  !usersTyping[1].user?.first_name &&
                  !usersTyping[1].user?.last_name
                    ? t('app.nameless')
                    : (usersTyping[1].user?.first_name ?? '') +
                      ' ' +
                      (usersTyping[1].user?.last_name ?? '')
                } ${t('newscenter.room.typing.double')}`}</div>
              )}
              {usersTyping.length > 2 && (
                <div>{`${usersTyping.length} ${t(
                  'newscenter.room.typing.multi'
                )}`}</div>
              )}
              <Loader
                classes={styles['news-center-chat-typing-loader']}
                color="text.secondary"
                size={6}
              />
            </Box>
          )}
          <Box
            bgcolor="bg.chat"
            borderColor="border.app"
            className={
              props.roomId
                ? styles['godparents-chat-footer']
                : styles['news-center-chat-footer']
            }
          >
            {messageQuote && (
              <MessageQuote
                message={messageQuote}
                onCloseQuote={() => setMessageQuote(undefined)}
              />
            )}
            <Box
              sx={{
                '& .rdw-editor-main': {
                  borderColor: 'border.app',
                },
              }}
            >
              <NewsCenterChatEditor
                readOnly={
                  props.tool_id || props.row_id
                    ? false
                    : selectedRoom.inactive_room
                }
                roomId={props.roomId}
                messageEdit={messageEdit}
                messageQuote={messageQuote && messageQuote}
                messageState={messageState}
                type={props.type}
                tool_id={props.tool_id}
                row_id={props.row_id}
                onScrollToBottom={onScrollIntoMessagesView}
                onSetSelectedFiles={setSelectedFiles}
                onSetLastMessageToEdit={onSetLastMessageToEdit}
                onStateReset={onEditorStateReset}
                // editorState={editorState}
                // setEditorState={setEditorState}
              ></NewsCenterChatEditor>
            </Box>

            {selectedFiles.length > 0 && (
              <Box className={styles['news-center-chat-footer-selected-files']}>
                {selectedFiles.map((file: SocketFile, index: number) => (
                  <FilePreview
                    key={index}
                    file={file.file}
                    onDelete={() => onFileDelete(index)}
                  />
                ))}
              </Box>
            )}
          </Box>
        </>
      )}
      {!selectedRoom && (
        <Loader classes="items-center justify-center h-full flex" />
      )}
    </Stack>
  );
};

export default memo(NewsCenterChat);
