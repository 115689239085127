import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Drawer } from '@mui/material';
import clsx from 'clsx';

// Components
import { ImageFallback } from '../../../shared/components/ImageFallback/ImageFallback';
import { IconButton } from '../../../shared/ui/IconButton/IconButton';
import { Tooltip } from '../../../shared/ui/Tooltip/Tooltip';

// Models
import { ImageFallbackType, Theme } from '../../../shared/models/shared.types';

// Stores
import {
  UserState,
  useUserStore,
} from '../../../modules/user/stores/use-user.store';

import {
  SharedState,
  useSharedStore,
} from '../../../shared/stores/use-shared.store';

// Styles
import styles from './Header.module.scss';

import { Logo } from '../Logo/Logo';
import { Community } from '../../../modules/communities/models/communities.types';
import useResponsive from '../../hooks/use-responsive ';
import { NavigationToggle } from '../NavigationToggle/NavigationToggle';
import { Link } from 'react-router-dom';
import ProfileDrawer from '../ProfileDrawer/ProfileDrawer';

type HeaderProps = {
  public?: boolean;
  isNewView: boolean;
  toggleNewView: (checked: boolean) => void;
};

export const Header = memo(({ isNewView, toggleNewView }: HeaderProps) => {
  const { isMobile } = useResponsive();
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  // Shared store state
  const [theme] = useSharedStore((state: SharedState) => [state.theme]);
  // User store state
  const [communities] = useUserStore((state: UserState) => [state.communities]);

  const hasOneCommunity =
    communities?.length === 1 && communities[0]?.data.logo?.url;

  return (
    <React.Fragment>
      <ProfileDrawer open={open} setOpen={setOpen} />
      <Box
        bgcolor="bg.newheader"
        borderColor="border.header"
        className={styles['header']}
      >
        <Box className={styles['header-container']}>
          <Box className={styles['header-container-logo']}>
            {hasOneCommunity ? (
              <CommunityLogo
                communities={communities}
                isMobile={isMobile}
                theme={theme}
              />
            ) : (
              <Logo
                imgClasses={styles['header-container-logo-image']}
                isMobile={isMobile}
                theme={theme}
              />
            )}
            <Breadcrumbs />
          </Box>
          <Box className={styles['header-container-navbar']}>
            <NavigationToggle
              isNewView={isNewView}
              toggleNewView={toggleNewView}
            />
            <Rewards rewards={574} />
            <ProfileSetting onClick={() => setOpen(true)} />
          </Box>
        </Box>
      </Box>
      <div className={styles['hedaer-background']}></div>
    </React.Fragment>
  );
});

type CommunityLogoProps = {
  communities: Community[] | undefined;
  theme: Theme;
  isMobile: boolean;
};

const CommunityLogo = memo(
  ({ communities, theme, isMobile }: CommunityLogoProps) => {
    return (
      <div className="flex flex-row items-end">
        {communities && communities[0] && (
          <Link to="/" className="flex">
            <img
              alt="Community Logo"
              src={communities[0]?.data.logo?.url}
              className={styles['header-container-logo-community']}
            />
          </Link>
        )}
        <div className={styles['powered-by']}>
          <Box sx={{ color: 'text.primary', fontSize: '9px!important' }}>
            Powered by
          </Box>
          <Logo
            isMobile={isMobile}
            theme={theme}
            imgClasses={styles['auth-page-logo-small']}
          />
        </div>
      </div>
    );
  }
);

const Breadcrumbs = memo(() => {
  return <div className={styles['header-container-logo-breadcrumbs']}></div>;
});

type RewardsProps = {
  rewards: number;
};

const Rewards = memo(({ rewards }: RewardsProps) => {
  return (
    <Tooltip title={<>Rewards</>}>
      <Box className="cursor-pointer flex flex-row shrink-0 items-center">
        <Box
          sx={{
            padding: '0 0.5rem',
            borderRadius: '1rem',
            backgroundColor: 'primary.main',
            borderColor: 'transparent',
            color: 'white',
            fontSize: '1rem',
            '&:active': {
              borderColor: 'transparent',
            },
            '&:disabled': {
              borderColor: 'transparent',
              color: 'white',
            },
            '&:hover': {
              backgroundColor: 'primary.dark',
              borderColor: 'transparent',
            },
          }}
        >
          {rewards}
        </Box>
        <IconButton
          classes={styles['header-container-navbar-buttons-iconbutton']}
          sxButton={{
            '& svg': {
              color: 'text.secondary',
            },
            bgcolor: 'transparent',
            '&:hover': {
              bgcolor: 'transparent',
            },
          }}
          sxIcon={{
            '&:hover': {
              fontWeight: 'bold',
            },
          }}
          icon={['fal', 'award']}
          iconSize="medium"
        />
      </Box>
    </Tooltip>
  );
});

const ProfileSetting = memo(({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const { isDesktop } = useResponsive();

  // User store state
  const [account, profile, unreadMessages, setUnreadMessages, communities] =
    useUserStore((state: UserState) => [
      state.account,
      state.profile,
      state.unreadMessages,
      state.setUnreadMessages,
      state.communities,
    ]);

  // Shared store state
  const [
    breadcrumbs,
    network,
    dashboard,
    newsCenter,
    workbench,
    userRouter,
    market,
  ] = useSharedStore((state) => [
    state.breadcrumbs,
    state.network,
    state.dashboard,
    state.newsCenter,
    state.workbench,
    state.userRouter,
    state.market,
  ]);

  return (
    <Box
      color="inherit"
      onClick={onClick}
      className={styles['header-container-navbar-drawer']}
    >
      {isDesktop && profile?.personal_data?.first_name && (
        <Box className={styles['header-container-navbar-drawer-name']}>
          {profile.personal_data.first_name}
        </Box>
      )}
      {profile?.avatar?.url ? (
        <img src={profile.avatar.url} alt="Profile" />
      ) : (
        <ImageFallback type={ImageFallbackType.Profile} />
      )}
      <IconButton
        onClick={() => {}}
        classes={clsx(
          styles['header-container-navbar-buttons-iconbutton'],
          styles['setting-icon']
        )}
        sxButton={{
          '& svg': {
            color: 'text.secondary',
          },
          bgcolor: 'transparent',
          '&:hover': {
            bgcolor: 'transparent',
          },
        }}
        iconSize="small"
        icon={['fal', 'gear']}
      />
    </Box>
  );
});
