import { useCallback, useEffect, useState } from 'react';
import { Input } from '@mui/material';
import { TFunctionResult } from 'i18next';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Hooks
import { ToolsState, useToolsStore } from '../../stores/use-tools.store';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useToolsHttp } from '../../hooks/use-tools-http.hook';
import { useMutation } from 'react-query';

// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { ToolValuesElement } from '../../models/tools.types';
import { ResultMessage } from '../../../../shared/ui/ResultMessage/ResultMessage';
import { Loader } from '../../../../shared/ui/Loader/Loader';

// Types
import { ResultState } from '../../../../shared/models/shared.types';
import { TemplateElement } from '../../../templates/models/templates.types';

// Styles
import styles from './ToolElementVoteGrade.module.scss';

type ToolElementVoteGradeProps = {
  classes?: string;
  disabled?: boolean;
  label?: TFunctionResult | string;
  help_text?: string;
  value?: ToolValuesElement;
  vote_element?: TemplateElement;
  sectionId?: string;
  sublabel?: string;
  toolId?: string;
  rowId?: string;
  required?: boolean;
  disable?: boolean;
  onChange?: (value: string) => void;
};

export const ToolElementVoteGrade = (props: ToolElementVoteGradeProps) => {
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const { toolVoteSubmit, toolVoteSubmitMultiRow } = useToolsHttp();

  // Component state
  const [toolValue, setToolValue] = useState<ToolValuesElement | undefined>(
    undefined
  );
  const [showButtons, setShowButtons] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showThanks, setShowThanks] = useState<boolean>(false);
  const [showChangeButton, setShowChangeButton] = useState<boolean>(false);

  // Tools store state
  const [
    hasFullAccess,
    // multiElementRowValuesInit,
    // setMultiElementRowValuesInit,
  ] = useToolsStore((state: ToolsState) => [
    state.hasFullAccess,
    // state.multiElementRowValuesInit,
    // state.setMultiElementRowValuesInit,
  ]);

  // React hook form validation schema
  const voteValueseValidation = yup.object({
    vote: yup
      .number()
      .min(0)
      .max(props.vote_element?.vote ?? 0),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<{ vote: number }>({
    mode: 'onChange',
    resolver: yupResolver(voteValueseValidation),
  });
  const vote = watch('vote');

  // ######### //
  // MUTATIONS //
  // ######### //

  const submitVoteMutation = useMutation((data: any) => toolVoteSubmit(data), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled: (data, error) => {
      if (data) {
        if (toolValue) {
          let updatedToolValue: ToolValuesElement = { ...toolValue };
          updatedToolValue.value = data.vote;
          updatedToolValue.change = data.change;
          updatedToolValue.submit = data.submit;
          updatedToolValue.result = data.result;
          setToolValue(updatedToolValue);
        } else {
          let updatedToolValue: ToolValuesElement = {
            id: data.id,
            value: data.vote,
            change: data.change,
            submit: data.submit,
            result: data.result,
          };
          setToolValue(updatedToolValue);
        }
      }
      if (error && error.response) {
        console.error(
          'Error submit vote value:',
          handleError(error.response.status)
        );
      }
    },
  });

  const submitVoteMultiRowMutation = useMutation(
    (data: any) => toolVoteSubmitMultiRow(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (toolValue) {
            let updatedToolValue: ToolValuesElement = { ...toolValue };
            updatedToolValue.value = data.vote;
            updatedToolValue.change = data.change;
            updatedToolValue.submit = data.submit;
            updatedToolValue.result = data.result;
            setToolValue(updatedToolValue);
          } else {
            let updatedToolValue: ToolValuesElement = {
              id: data.id,
              value: data.vote,
              change: data.change,
              submit: data.submit,
              result: data.result,
            };
            setToolValue(updatedToolValue);
          }
        }
        if (error && error.response) {
          console.error(
            'Error submit vote value:',
            handleError(error.response.status)
          );
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Effects on component mount
  useEffect(() => {
    // Reset editor state on escape press
    const onKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        reset();
      }
    };
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
    // eslint-disable-next-line
  }, []);

  // Set tool value by props on mount
  useEffect(() => {
    if (props.value) {
      setToolValue(props.value);
    }
  }, [props]);

  // Set show vote submit button
  useEffect(() => {
    if (
      // vote &&
      // ((props.value?.value && vote !== Number(props.value.value)) ||
      //   !props.value) &&
      vote?.toString() &&
      vote > -1 &&
      props.vote_element?.vote &&
      vote <= props.vote_element?.vote
    ) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [vote, props]);

  // Set show components
  useEffect(() => {
    if (props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowResult(true);
        setShowForm(false);
      } else {
        setShowResult(false);
        setShowForm(true);
      }
    }
    if (!props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowThanks(true);
        setShowForm(false);
      } else {
        setShowThanks(false);
        setShowForm(true);
      }
    }
    props.vote_element?.settings?.vote?.change
      ? setShowChangeButton(true)
      : setShowChangeButton(false);
  }, [props, toolValue, toolValue?.value]);

  // ######### //
  // CALLBACK //
  // ######### //

  const onChange = useCallback(() => {
    setShowForm(true);
    setShowButtons(true);
    setShowChangeButton(false);
    setShowThanks(false);
    setShowResult(false);
  }, []);

  const onCancel = useCallback(() => {
    reset();
    // eslint-disable-next-line
  }, []);

  const onSave = useCallback(
    (formValues) => {
      setShowButtons(false);
      if (props.rowId) {
        submitVoteMultiRowMutation.mutate({
          id: props.toolId,
          body: {
            id: props.vote_element?.id,
            value: formValues.vote,
          },
          element_id: props.vote_element?.id,
          section_id: props.sectionId,
          row_id: props.rowId,
        });
      } else {
        submitVoteMutation.mutate({
          id: props.toolId,
          body: {
            id: props.vote_element?.id,
            value: formValues.vote,
          },
          element_id: props.vote_element?.id,
          section_id: props.sectionId,
        });
      }
    },
    // eslint-disable-next-line
    [props]
  );

  return (
    <>
      {hasFullAccess ? (
        <>
          <div style={{ marginRight: '10px' }}>
            {toolValue?.value ? <>{toolValue?.value}</> : <>0</>}
          </div>
        </>
      ) : (
        <>
          <div
            className={clsx(
              styles['vote-grade'],
              props.classes && props.classes
            )}
          >
            {props.label && (
              <FormFieldLabel
                required={props.required}
                label={props.label}
                paddingClassName={styles['vote-grade-form-field-label-padding']}
                sublabel={props.sublabel}
                help_text={props.help_text}
              />
            )}
            <div className={styles['vote-grade-row']}>
              {!submitVoteMutation.isLoading && (
                <>
                  {showResult && (
                    <div className={styles['vote-grade-add']}>
                      <div className="mr-4">
                        <div className={styles['vote-grade-input-default']}>
                          <input
                            className={styles['vote-grade-input']}
                            type="number"
                            value={toolValue?.value}
                            readOnly
                          />
                          <span className="ml-1">/</span>{' '}
                          {props.vote_element?.vote}
                        </div>
                      </div>
                      {showResult && showChangeButton && (
                        <div>
                          <TextButton
                            preset="popover"
                            textSize="text-sm"
                            onClick={onChange}
                          >
                            {t('tools.vote.change')}
                          </TextButton>
                        </div>
                      )}
                    </div>
                  )}
                  {showThanks && (
                    <div className={styles['vote-grade-add']}>
                      <div className="mr-4">{t('tools.vote.thanks')}</div>
                      {showThanks && showChangeButton && (
                        <div>
                          <TextButton
                            preset="popover"
                            textSize="text-sm"
                            onClick={onChange}
                          >
                            {t('tools.vote.change')}
                          </TextButton>
                        </div>
                      )}
                    </div>
                  )}
                  {showForm && (
                    <form onSubmit={handleSubmit(onSave)}>
                      <div className={styles['vote-grade-input-default']}>
                        <Input
                          className={styles['vote-grade-input']}
                          disabled={props.disable}
                          // onChange={(event) => {
                          //   props.onChange && props.onChange(event.target.value);
                          // }}
                          type="number"
                          disableUnderline
                          style={{ alignItems: 'center', fontSize: 'small' }}
                          inputProps={{
                            style: { textAlign: 'right' },
                          }}
                          min="0"
                          max={props.vote_element?.vote}
                          defaultValue={toolValue?.value}
                          readOnly={hasFullAccess}
                          {...register('vote', {
                            valueAsNumber: true,
                          })}
                        />
                        <div className={styles['vote-grade-input-number']}>
                          <span className="ml-1">/</span>{' '}
                          {props.vote_element?.vote}
                        </div>
                        {showButtons && (
                          <div className={styles['vote-grade-input-button']}>
                            <IconButton
                              classes="mr-1"
                              icon={['fas', 'times']}
                              preset="popover"
                              iconSize="small"
                              onClick={onCancel}
                            />
                            <IconButton
                              icon={['fas', 'check']}
                              preset="popover"
                              iconSize="small"
                              type="submit"
                            />
                          </div>
                        )}
                      </div>
                      {errors.vote && (
                        <ResultMessage
                          message={t('tools.vote.error.point')}
                          state={ResultState.Error}
                          padding="p-0"
                        />
                      )}
                    </form>
                  )}
                </>
              )}

              {submitVoteMutation.isLoading && <Loader />}
            </div>
          </div>
        </>
      )}
    </>
  );
};
