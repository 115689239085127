import { Box } from '@mui/material';
import { Dispatch, memo, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunityUserRole } from '../../../modules/communities/models/communities.types';
import { useContacts } from '../../../modules/contacts/hooks/use-contacts.hook';
import {
  AccordionExpansionState,
  CommunityNav,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactsGetFilters,
} from '../../../modules/contacts/models/contacts.types';
import { FavoriteLinkType } from '../../../modules/dashboard/models/dashboard.types';
import { GroupCreateEditSettings } from '../../../modules/newscenter/components/GroupCreateEditSettings/GroupCreateEditSettings';
import useResponsive from '../../../new_shared/hooks/use-responsive ';
import { CrudState } from '../../../shared/models/shared.types';
import { Accordion } from '../../../shared/ui/Accordion/Accordion';
import { Dialog } from '../../../shared/ui/Dialog/Dialog';
import { Icon } from '../../../shared/ui/Icon/Icon';
import { NavCommunityButton } from '../NavCommunityButton/NavCommunityButton';
import NavFolder from '../NavFolder/NavFolder';
import { NaviTitle } from '../NaviTitle/NaviTitle';
import styles from './NavCommunity.module.scss';

interface NavCommunityProps {
  community: CommunityNav;

  selectedItem?: string;
  onContactsGet: (
    action: ContactCommunityGetAction,
    params: ContactsGetFilters
  ) => void;
  expanded: AccordionExpansionState;
  setExpanded: Dispatch<SetStateAction<AccordionExpansionState>>;
  setContactCommunity: Dispatch<SetStateAction<ContactCommunity>>;
  setMemberRoleCommunity: Dispatch<SetStateAction<CommunityUserRole>>;
}

export const NavCommunity = memo(
  ({
    community,
    expanded,
    selectedItem,
    onContactsGet,
    setExpanded,
    setMemberRoleCommunity,
  }: NavCommunityProps) => {
    const { t } = useTranslation();
    const { handleAccordionExpand } = useContacts();
    const { isMobile } = useResponsive();

    const [dialogGroupCreate, setDialogGroupCreate] = useState<boolean>(false);

    const communityTitleClick = useCallback(
      (community: ContactCommunity) => {
        onContactsGet(ContactCommunityGetAction.Community, {
          community_id: community.id,
          include_me: true,
        });
        handleAccordionExpand(
          community.id,
          expanded,
          setExpanded,
          FavoriteLinkType.Contacts,
          isMobile,
          true
        );
      },
      [expanded, setExpanded, isMobile]
    );

    return (
      <div key={community.id} className={styles['contacts-nav-community']}>
        <Accordion
          // active={selectedItem === community.id}
          detailsClassName={styles['filter-group-by-navCommunities']}
          disableExpandIcon
          detailsMarginTop="0rem"
          expanded={expanded[community.id] ?? true}
          onSummaryClick={() => communityTitleClick(community)}
          title={
            <div className={styles['contacts-nav-community-summary']}>
              <CommunityLogoName community={community} />
            </div>
          }
        >
          {/* For Admin */}
          {community.member_role === CommunityUserRole.Admin && (
            <NavCommunityButton
              title={t('app.admin')}
              icon={['fal', 'users']}
              classes={styles['contacts-nav-community-button-title']}
              active={'Admin' + community.id === selectedItem}
              // itemName={`${community.name}: ${t('app.admin')}`}
              onClick={() =>
                onContactsGet(ContactCommunityGetAction.Admin, {
                  community_id_admin: community.id,
                  include_me: true,
                })
              }
            />
          )}
          {/* For Employee */}
          {community.member_role !== CommunityUserRole.Member && (
            <NavCommunityButton
              title={
                community.employee_name
                  ? community.employee_name
                  : t('app.employee')
              }
              icon={['fal', 'users']}
              // itemName={`${community.name}: ${
              //   community.employee_name
              //     ? community.employee_name
              //     : t('app.employee')
              // }`}
              active={'Employee' + community.id === selectedItem}
              classes={styles['contacts-nav-community-button-title']}
              onClick={() =>
                onContactsGet(ContactCommunityGetAction.Employee, {
                  community_id_employee: community.id,
                  include_me: true,
                })
              }
            />
          )}
          {/* For Member */}
          {community.member_role === CommunityUserRole.Member ||
            community.member_role === CommunityUserRole.Employee ||
            (community.member_role === CommunityUserRole.Admin && (
              <NavCommunityButton
                title={
                  community.member_name
                    ? community.member_name
                    : t('app.member')
                }
                icon={['fal', 'users']}
                // itemName={`${community.name}: ${
                //   community.member_name
                //     ? community.member_name
                //     : t('app.member')
                // }`}
                active={'Member' + community.id === selectedItem}
                classes={styles['contacts-nav-community-button-title']}
                onClick={() =>
                  onContactsGet(ContactCommunityGetAction.Member, {
                    community_id_member: community.id,
                    include_me: true,
                  })
                }
              />
            ))}

          {((community.groups?.length || 0) > 0 ||
            (community.folders?.length || 0) > 0) && (
            <>
              <NaviTitle classes="ml-4 mt-2" title="Meine Gruppen" />
              {/* Group in folder */}
              <NavFolder
                community={community}
                expanded={expanded}
                selectedItem={selectedItem}
                onContactsGet={onContactsGet}
                setExpanded={setExpanded}
                setMemberRoleCommunity={setMemberRoleCommunity}
              />
              {/* Group not in folder */}
              {community.groups &&
                community.groups.map((group) => {
                  return (
                    <NavCommunityButton
                      key={group.id}
                      title={
                        group.name
                          ? group.name
                          : t('community.tools.visibility.group')
                      }
                      icon={['fal', 'users']}
                      // itemName={`${community.name}: ${
                      //   group.name
                      //     ? group.name
                      //     : t('community.tools.visibility.group')
                      // }`}
                      active={'Group' + group.id === selectedItem}
                      classes={styles['contacts-nav-community-button-title']}
                      onClick={() =>
                        onContactsGet(ContactCommunityGetAction.Group, {
                          community_id_group: group.id,
                          include_me: true,
                        })
                      }
                    />
                  );
                })}
            </>
          )}
          <Box
            className="cursor-pointer text-xs border border-solid rounded-md w-fit py-1.5 px-3 mt-2 ml-2"
            sx={{ borderColor: '#8C9BA9', color: '#8C9BA9' }}
            onClick={() => setDialogGroupCreate(true)}
          >
            Gruppe anlegen
          </Box>
        </Accordion>
        <Dialog
          open={dialogGroupCreate}
          onClose={() => setDialogGroupCreate(false)}
          title={t('newscenter.groups.create_edit.settings.title.create')}
        >
          <GroupCreateEditSettings
            groupRoomCreateEditCrudState={CrudState.Create}
            selectedCommunity={community ?? undefined}
            onClose={() => setDialogGroupCreate(false)}
            isCreate={true}
          />
        </Dialog>
      </div>
    );
  }
);

const CommunityLogoName = memo(
  ({ community }: { community: ContactCommunity }) => {
    return (
      <>
        {community.avatar?.url ? (
          <img alt={'Gemeinschaftsname'} src={community.avatar?.url} />
        ) : (
          <Icon icon={['fas', 'users-rectangle']} />
        )}
        <div className={styles['contacts-nav-community-button-title']}>
          {community.name}
        </div>
      </>
    );
  }
);
