import { memo, useEffect, useMemo, useState } from 'react';
import { TFunctionResult } from 'i18next';
import { UseFormRegisterReturn } from 'react-hook-form';
import { Box, OutlinedInput } from '@mui/material';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { TemplateElementType } from '../../../modules/templates/models/templates.types';
import { FormFieldLabel } from '../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { ResultMessage } from '../../../shared/ui/ResultMessage/ResultMessage';
import { ResultState } from '../../../shared/models/shared.types';
import {
  addCommasToNumber,
  isValidEmailFormat,
  isValidPhoneNumberFormat,
  isValidTimeFormat,
  isValidUrlFormat,
} from '../../../new_shared/utils/shared.utils';
import { InputFlat } from '../../../shared/ui/InputFlat/InputFlat';
import useResponsive from '../../../new_shared/hooks/use-responsive ';

type ToolElementInputProps = {
  classes?: string;
  defaultValue?: string | TFunctionResult;
  disabled?: boolean;
  duration?: boolean;
  help_text?: string;
  initValue?: string;
  label?: string | TFunctionResult;
  multiline?: number;
  optional?: boolean;
  padding?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  required?: boolean;
  sublabel?: string | TFunctionResult;
  suffix?: string;
  thousandPoint?: boolean;
  type: TemplateElementType | null | undefined;
  value?: string;
  widthClassName?: string;
  onCancel: (value: string) => void;
  onChange?: (value: string) => void;
  onSave: (value: string) => void;
};

const ToolElementInput = (props: ToolElementInputProps) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  // Component state
  const [initValue, setInitValue] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  const settings: {
    maxWidth: string | undefined;
    width: string | undefined;
    textAlign: 'right' | 'left';
    type: string;
    message: string;
  } = useMemo(() => {
    return {
      maxWidth: props.type === TemplateElementType.Number ? '300px' : '600px',
      width:
        props.type === TemplateElementType.Number
          ? 'w-48'
          : isMobile
          ? 'w-full'
          : 'w-2/3',
      textAlign: props.type === TemplateElementType.Number ? 'right' : 'left',
      type:
        props.type === TemplateElementType.Number
          ? 'number'
          : props.type === TemplateElementType.EMail
          ? 'email'
          : props.type === TemplateElementType.Telephone
          ? 'tel'
          : props.type === TemplateElementType.Url
          ? 'url'
          : props.type || '',
      message:
        props.type === TemplateElementType.Telephone
          ? 'Die Eingabe ist keine Telefonnummer.'
          : props.type === TemplateElementType.Url
          ? 'Die Eingabe ist keine Url.'
          : props.type === TemplateElementType.EMail
          ? 'Die Eingabe ist keine Email.'
          : props.type === TemplateElementType.Number
          ? 'Die Eingabe entspricht nicht dem Format „hh:mm“.'
          : '',
    };
  }, [props.type, isMobile]);

  // Set initial input value for cancel abbility.
  useEffect(() => {
    if (initValue === '') {
      props.value && setInitValue(props.value);
    }
    props.value && setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (value) {
      switch (props.type) {
        case TemplateElementType.Telephone:
          setIsValid(isValidPhoneNumberFormat(value));
          break;
        case TemplateElementType.Url:
          setIsValid(isValidUrlFormat(value));
          break;
        case TemplateElementType.EMail:
          setIsValid(isValidEmailFormat(value));
          break;
        case TemplateElementType.Number:
          if (props.duration) {
            setIsValid(isValidTimeFormat(value));
          }
          break;
      }
    } else {
      setIsValid(true);
    }
  }, [value]);

  return (
    <Box
      className={clsx(
        props.classes && props.classes,
        props.widthClassName ? props.widthClassName : 'w-full'
      )}
    >
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          optional={props.optional}
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}

      <div className="flex flex-row items-center">
        <InputFlat
          className={'text-sm rounded-sm'}
          widthClassName={settings.width}
          maxWidth={settings.maxWidth}
          placeholder={
            initValue ? initValue : t('tools.detail.form.input.placeholder')
          }
          inputProps={{
            style: { textAlign: settings.textAlign },
          }}
          type={props.duration ? undefined : settings.type}
          onChange={props.onChange}
        />
        {props.suffix && <Box className="ml-2 pt-1">{props.suffix}</Box>}
      </div>
      {!isValid && (
        <ResultMessage message={settings.message} state={ResultState.Error} />
      )}
    </Box>
  );
};

export default memo(ToolElementInput);
