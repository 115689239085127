import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { FormFieldLabel } from '../../../shared/ui/FormFieldLabel/FormFieldLabel';

type ToolElementHeadlineProps = {
  classes?: string;
  label?: string | TFunctionResult;
  sublabel?: string | TFunctionResult;
  help_text?: string;
  widthClassName?: string;
};

export const ToolElementHeadline = (props: ToolElementHeadlineProps) => {
  return (
    <Box
      className={clsx(
        props.classes && props.classes,
        props.widthClassName ? props.widthClassName : 'w-full'
      )}
    >
      {props.label && (
        <FormFieldLabel
          label={props.label}
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
    </Box>
  );
};
