import { memo, ReactChild, ReactNode } from 'react';
import { TFunctionResult } from 'i18next';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import clsx from 'clsx';

// Components
import { Icon } from '../Icon/Icon';

// Styles
import styles from './Accordion.module.scss';

type AccordionProps = {
  active?: boolean;
  children: ReactChild | TFunctionResult | string;
  classes?: string;
  detailsClassName?: string;
  defaultExpanded?: boolean;
  detailsMarginTop?: string;
  detailsPaddingXClassName?: string;
  disableExpandIcon?: boolean;
  disableTitleBold?: boolean;
  expanded?: boolean;
  expandIcon?: ReactNode;
  title: TFunctionResult | string | ReactChild;
  titleSize?: 'small' | 'medium' | 'big';
  summaryPadding?: string;
  unread?: number;
  onSummaryClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const Accordion = (props: AccordionProps) => {
  return (
    <MuiAccordion
      defaultExpanded={props.defaultExpanded}
      disableGutters
      expanded={props.expanded}
      elevation={0}
      sx={{
        backgroundColor: 'transparent',
      }}
      className={clsx(styles['accordion'], props.classes)}
    >
      <AccordionSummary
        expandIcon={
          !props.disableExpandIcon &&
          (props.expandIcon ? (
            props.expandIcon
          ) : (
            <Box className="flex items-center px-1.5">
              <Icon
                icon={['fas', 'chevron-down']}
                sx={{
                  color: 'text.primary',
                }}
              />
            </Box>
          ))
        }
        sx={{
          color: 'text.primary',
          backgroundColor: props.active ? 'bg.card' : undefined,
          '&:hover': {
            backgroundColor: 'bg.hover',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }}
        onClick={props.onSummaryClick}
        className={clsx(
          styles['accordion-summary'],
          props.summaryPadding ?? 'p-1'
        )}
      >
        <Box className={styles['accordion-summary-title-container']}>
          <Box
            className={clsx(
              props.disableTitleBold ? 'font-normal' : 'font-semibold',
              styles['accordion-summary-title'],
              props.titleSize === 'medium' &&
                styles['accordion-summary-title-medium'],
              props.titleSize === 'big' &&
                styles['accordion-summary-title-big'],
              props.disableExpandIcon && 'ml-0'
            )}
          >
            {props.title}
          </Box>
          {props.unread && props.unread > 0 && (
            <Box
              bgcolor="success.light"
              color="success.main"
              className={clsx(styles['chat-item-content-unread'], 'font-bold')}
            >
              {props.unread}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          marginTop: props.detailsMarginTop ? props.detailsMarginTop : '0.5rem',
        }}
        className={clsx(
          styles['accordion-details'],
          props.detailsClassName && props.detailsClassName,
          props.detailsPaddingXClassName ??
            styles['accordion-details-padding-x']
        )}
      >
        {props.children}
      </AccordionDetails>
    </MuiAccordion>
  );
};
