import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';

// Models
import { TemplateElementDatePickerType } from '../../../templates/models/templates.types';

// Styles
import styles from './ToolElementDatePicker.module.scss';
import { TFunctionResult } from 'i18next';

type DatePickerContentProps = {
  classes?: string;
  disabled?: boolean;
  params: TextFieldProps;
  type: TemplateElementDatePickerType;
  value: Date | string | null | any;
  onOpen: () => void;
};

const DatePickerContent = (props: DatePickerContentProps) => {
  const { t } = useTranslation();

  // Extend dayjs with localized format on component mount
  useEffect(() => {
    dayjs.extend(LocalizedFormat);
  }, []);
    
  return (
    <div>
      <TextField
        style={{ opacity: 0, width: 0, height: 0 }}
        {...props.params}
      />
      <Button
        className={clsx(
          styles['tool-element-date-picker'],
          props.classes && props.classes
        )}
        color="inherit"
        disabled={props.disabled}
        sx={{
          backgroundColor: 'bg.card',
          '&:hover': { backgroundColor: 'bg.card' },
        }}
        onClick={props.onOpen}
      >
        <Box
          className={styles['tool-element-date-picker-value']}
          sx={{
            color: props.disabled
              ? 'text.disabled'
              : props.value
              ? 'text.primary'
              : 'text.secondary',
          }}
        >
          {/* Show Placeholder */}
          {props.type === TemplateElementDatePickerType.Time &&
            !props.value &&
            t('app.date.time.placeholder')}

          {/* Show Date */}
          {props.type !== TemplateElementDatePickerType.Time && (
            <>
              {props.value ? (
                <Box
                  className={styles['tool-element-date-picker-value-date']}
                  sx={{ backgroundColor: 'bg.card' }}
                >{`${dayjs(props.value).format('LL')}`}</Box>
              ) : props.type === TemplateElementDatePickerType.Date ? (
                t('app.date.date.placeholder')
              ) : props.type === TemplateElementDatePickerType.DateTime ? (
                t('app.date.date_time.placeholder')
              ) : (
                t('app.date.time.placeholder')
              )}
            </>
          )}

          {/* Show Time */}
          {props.value &&
            (props.type === TemplateElementDatePickerType.Time ||
              props.type === TemplateElementDatePickerType.DateTime) && (
              <Box
                className={clsx(
                  styles['tool-element-date-picker-value-date'],
                  props.type === TemplateElementDatePickerType.DateTime &&
                    styles['tool-element-date-picker-value-time']
                )}
                sx={{ backgroundColor: 'bg.card' }}
              >{`${dayjs(props.value).format('LT')} Uhr`}</Box>
            )}
        </Box>
      </Button>
    </div>
  );
};

type ToolElementDatePickerProps = {
  classes?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  sublabel?: string | TFunctionResult;
  help_text?: string;
  type: TemplateElementDatePickerType;
  value?: any;
  required?: boolean;
  onChange: (value: string | undefined, id?: string) => void;
};

export const ToolElementDatePicker = (props: ToolElementDatePickerProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<Date | null>(null);

  // Extend dayjs with localized format on component mount
  useEffect(() => {
    dayjs.extend(LocalizedFormat);
  }, []);

  // Set initial value
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  /**
   * Handler on date picker change
   * @param newValue Date
   * @param keyboardInputValue Keyboard input value
   */
  const onChange = useCallback(
    (newValue: Date | null, keyboardInputValue?: string | undefined) => {
      setValue(newValue);
      props.onChange(newValue?.toISOString(), props.id && props.id);
    },
    [props]
  );

  return (
    <>
      <FormFieldLabel
        required={props.required}
        label={
          props.label
            ? props.label
            : props.type === TemplateElementDatePickerType.Date
            ? t('app.date.date.title')
            : props.type === TemplateElementDatePickerType.DateTime
            ? t('app.date.date_time.title')
            : t('app.date.time.title')
        }
        paddingClassName={
          styles['tool-element-date-picker-form-field-label-padding']
        }
        sublabel={props.sublabel}
        help_text={props.help_text}
      />
      {props.type === TemplateElementDatePickerType.Date && (
        <DatePicker
          disabled={props.disabled}
          open={open}
          renderInput={(params) => (
            <DatePickerContent
              classes={props.classes}
              disabled={props.disabled}
              params={params}
              type={props.type}
              value={value}
              onOpen={() => setOpen(true)}
            />
          )}
          value={value}
          onChange={onChange}
          onClose={() => setOpen(false)}
        />
      )}
      {props.type === TemplateElementDatePickerType.DateTime && (
        <DateTimePicker
          disabled={props.disabled}
          open={open}
          renderInput={(params) => (
            <DatePickerContent
              classes={props.classes}
              disabled={props.disabled}
              params={params}
              type={props.type}
              value={value}
              onOpen={() => setOpen(true)}
            />
          )}
          value={value}
          onChange={onChange}
          onClose={() => setOpen(false)}
        />
      )}
      {props.type === TemplateElementDatePickerType.Time && (
        <TimePicker
          disabled={props.disabled}
          open={open}
          renderInput={(params) => (
            <DatePickerContent
              classes={props.classes}
              disabled={props.disabled}
              params={params}
              type={props.type}
              value={value}
              onOpen={() => setOpen(true)}
            />
          )}
          value={value}
          onChange={onChange}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default memo(ToolElementDatePicker);
