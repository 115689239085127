import { Layout } from '../../new_shared/components/Layout/Layout';
import { Favorite } from './Favorite/Favorite';
import { Changes } from './Changes/Changes';
import { StartPage } from './StartPage/StartPage';
import { useCallback, useEffect, useState } from 'react';
import {
  AccordionExpandedGet,
  AccordionExpansionState,
  Contact,
  ContactCommunity,
  ContactCommunityGetAction,
  ContactCommunityIdType,
  ContactsGetFilters,
} from '../../modules/contacts/models/contacts.types';
import { CommunityUserRole } from '../../modules/communities/models/communities.types';
import { Navigation } from '../navigation/Navigation';
import { useMutation } from 'react-query';
import { useContactsHttp } from '../../modules/contacts/hooks/use-contacts-http.hook';
import { useFetch } from '../../shared/hooks/use-fetch.hook';
import { useNavigate } from 'react-router-dom';
import {
  Change,
  DashboardViewType,
} from '../../modules/dashboard/models/dashboard.types';
import useResponsive from '../../new_shared/hooks/use-responsive ';
import {
  UserState,
  useUserStore,
} from '../../modules/user/stores/use-user.store';
import { useShared } from '../../shared/hooks/use-shared.hook';
import { useTranslation } from 'react-i18next';
import { useUsersHttp } from '../../modules/user/hooks/use-users-http.hook';
import Tool from '../tool/Tool';

export type DashboardProps = {
  action?: ContactCommunityGetAction;
  viewType: DashboardViewType;
  isNewView: boolean;
  toggleNewView: (checked: boolean) => void;
};

export const Dashboard = ({
  action,
  viewType,
  isNewView,
  toggleNewView,
}: DashboardProps) => {
  const { contactsGet, getNavigationNetwork } = useContactsHttp();
  const { handleError, handleRetry } = useFetch();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { fullNameGet } = useShared();
  const { t } = useTranslation();
  const { userChangesGet } = useUsersHttp();

  // Component States
  const [changes, setChanges] = useState<Change[]>([]);
  const [baseUrl, setBaseUrl] = useState<string>('');
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [expanded, setExpanded] = useState<AccordionExpansionState>({});

  const [showNav, setShowNav] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined
  );
  const [selectedCommunity, setSelectedCommunity] = useState<ContactCommunity>({
    id: ContactCommunityIdType.None,
    name: '',
  });
  const [memberRoleCommunity, setMemberRoleCommunity] =
    useState<CommunityUserRole>(CommunityUserRole.Member);

  // User store state
  const [profile] = useUserStore((state: UserState) => [state.profile]);

  const userName = profile?.personal_data.first_name || 'Pengueen User';

  // ######### //
  // MUTATIONS //
  // ######### //
  const getNavigationNetworkMutation = useMutation(
    (body: AccordionExpandedGet) => getNavigationNetwork(body),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data) {
        if (data) {
          setExpanded(data); // fix localstorage
        }
      },
    }
  );
  const contactsGetMutation = useMutation(
    (filters?: ContactsGetFilters) => contactsGet(filters),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSuccess(data) {
        if (data) {
          setContacts(data.contacts);
          setSelectedCommunity(data.community);
        }
      },
    }
  );
  // GET changes mutation
  const changesGetMutation = useMutation(() => userChangesGet(), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled(data, error) {
      // setChanges(changesGet()); //#TODO delete
      if (data) {
        setChanges(data);
      }
      if (error) {
        setChanges([]);
      }
    },
  });

  // ####### //
  // EFFECTS //
  // ####### //

  useEffect(() => {
    getNavigationNetworkMutation.mutate({
      is_mobile: isMobile,
    });
    changesGetMutation.mutate();
  }, []);

  const onContactsGet = useCallback(
    (action: ContactCommunityGetAction, params: ContactsGetFilters) => {
      setShowNav && setShowNav(false);
      contactsGetMutation.mutate(params);
      let selectedItem = '';
      let baseUrl = '/';
      switch (action) {
        case ContactCommunityGetAction.Community:
          selectedItem = params.community_id ?? '';
          baseUrl = `/dashboard/community/${params.community_id}`;
          break;
        case ContactCommunityGetAction.Admin:
          selectedItem = 'Admin' + params.community_id_admin;
          baseUrl = `/dashboard/community/${params.community_id_admin}/admin`;
          break;
        case ContactCommunityGetAction.Employee:
          selectedItem = 'Employee' + params.community_id_employee;
          baseUrl = `/dashboard/community/${params.community_id_employee}/employee`;
          break;
        case ContactCommunityGetAction.Member:
          selectedItem = 'Member' + params.community_id_member;
          baseUrl = `/dashboard/community/${params.community_id_member}/member`;
          break;
        case ContactCommunityGetAction.Group:
          selectedItem = 'Group' + params.community_id_group;
          baseUrl = `/dashboard/community/group/${params.community_id_group}`;
          break;
        case ContactCommunityGetAction.Folder:
          baseUrl = `/dashboard/community/folder/${params.community_id_folder}`;
          setContacts([]);
          break;
        case ContactCommunityGetAction.FolderGroup:
          selectedItem = 'Group' + params.community_id_group;
          baseUrl = `/dashboard/community/folder/${params.community_id_folder}/group/${params.community_id_group}`;
          break;
        case ContactCommunityGetAction.CommunityLink:
          selectedItem = 'Community' + params.link_id;
          baseUrl = `/dashboard/community/link/${params.link_id}`;
          break;
        case ContactCommunityGetAction.ToolLink:
          selectedItem = 'Link' + params.community_id_tool_link;
          baseUrl = `/dashboard/community/tool/${params.community_id_tool_link}`;
          break;
        case ContactCommunityGetAction.Room:
          selectedItem = params.group_id ?? '';
          baseUrl = `/dashboard/group/${params.group_id}`;
          break;
        default:
          selectedItem = ContactCommunityIdType.None;
          setContacts([]);
          break;
      }
      setSelectedItem(selectedItem);
      setBaseUrl(baseUrl);
      action !== ContactCommunityGetAction.Folder && navigate(baseUrl);
    },
    [setShowNav, contactsGetMutation]
  );

  const getRightComponent1 = () => {
    switch (viewType) {
      case DashboardViewType.Default:
        return (
          <Changes
            username={userName}
            changes={changes}
            isLoading={changesGetMutation.isLoading}
          />
        );
      case DashboardViewType.Community:
        return <div>Community</div>;
    }
  };

  const getRightComponent2 = () => {
    switch (viewType) {
      case DashboardViewType.Default:
        return <Favorite />;
      case DashboardViewType.Community:
        return <Tool />;
    }
  };

  const getRightComponentName1 = () => {
    switch (viewType) {
      case DashboardViewType.Default:
        return 'Aktuelles';
      case DashboardViewType.Community:
        return 'Nachrichten';
      default:
        return '';
    }
  };

  const getRightComponentName2 = () => {
    switch (viewType) {
      case DashboardViewType.Default:
        return 'Favoriten';
      case DashboardViewType.Community:
        return 'Tool';
      default:
        return '';
    }
  };

  return (
    <Layout
      leftComponent={
        <Navigation
          isLoading={getNavigationNetworkMutation.isLoading}
          expanded={expanded}
          setExpanded={setExpanded}
          selectedItem={selectedItem}
          onContactsGet={onContactsGet}
          setContactCommunity={setSelectedCommunity}
          setMemberRoleCommunity={setMemberRoleCommunity}
        />
      }
      rightComponent1={getRightComponent1()}
      rightComponent2={getRightComponent2()}
      rightComponent3={<StartPage />}
      rightComponentName1={getRightComponentName1()}
      rightComponentName2={getRightComponentName2()}
      isNewView={isNewView}
      toggleNewView={toggleNewView}
      viewType={viewType}
    />
  );
};
