import { ReactNode, memo, useEffect, useRef } from 'react';
import { TFunctionResult } from 'i18next';
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

// Styles
import styles from './FormDialog.module.scss';
import { IconButton } from '../../../shared/ui/IconButton/IconButton';
import { ResultState, thinScroll } from '../../../shared/models/shared.types';
import { InputFlat } from '../../../shared/ui/InputFlat/InputFlat';
import { TextButton } from '../../../shared/ui/TextButton/TextButton';
import { useTranslation } from 'react-i18next';
import onboarding_background from '../../../assets/pictures/onboarding_background.png';
import {
  UserState,
  useUserStore,
} from '../../../modules/user/stores/use-user.store';

type FormDialogProps = {
  classes?: string;
  disableDividers?: boolean;
  open: boolean;
  title?: string | TFunctionResult | ReactNode;
  infoText?: string | TFunctionResult | ReactNode;
  width?: string;
  widthClassName?: string;
  content?: string | TFunctionResult | ReactNode;
  footer?: string | TFunctionResult | ReactNode;
  textButton?: string;
  textMatch?: RegExp;
  hideInputText?: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

type FormDeleteValues = {
  delete: string;
};

export const FormDialog = memo((props: FormDialogProps) => {
  // Component refs
  const contentRef = useRef<HTMLDivElement>(null);

  const [profile] = useUserStore((state: UserState) => [state.profile]);

  // Focus content on open
  useEffect(() => {
    if (props.open) {
      const { current: content } = contentRef;
      if (content !== null) {
        content.focus();
      }
    }
  }, [props.open]);

  const { t } = useTranslation();

  // React hook form validation schema
  const formDeleteValidation = yup.object({
    delete: yup
      .string()
      .matches(
        props.textMatch ?? t('form.common.errors.matches.delete'),
        t('form.common.errors.matches.response')
      )
      .min(
        props.textMatch
          ? props.textMatch.toString().length
          : t('form.common.errors.matches.delete').length,
        t('form.common.errors.matches.response')
      )
      .max(
        props.textMatch
          ? props.textMatch.toString().length
          : t('form.common.errors.matches.delete').length,
        t('form.common.errors.matches.response')
      )
      .required(t('form.common.errors.matches.response')),
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<FormDeleteValues>({
    resolver: yupResolver(formDeleteValidation),
  });

  // Make input valid if props.hideInputText is true
  useEffect(() => {
    props.hideInputText &&
      !props.textMatch &&
      setValue('delete', t('form.common.errors.matches.delete'));
  }, [props]);

  return (
    <MuiDialog
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      open={props.open}
      classes={{
        paper: clsx(
          styles['dialog'],
          props.classes && props.classes,
          props.widthClassName
            ? props.widthClassName
            : props.width
            ? undefined
            : styles['dialog-width']
        ),
      }}
      scroll="body"
      onClose={props.onClose}
      sx={{
        '& .MuiPaper-root': {
          backgroundImage: 'none',
          margin: 0,
          padding: 0,
          maxWidth: '600px !important',
          width: props.width,
        },
      }}
    >
      <IconButton
        classes={styles['dialog-title-close']}
        icon={['fas', 'times']}
        onClick={props.onClose}
      />
      <Box
        component="img"
        className="absolute right-4 top-[70px] w-44"
        src={onboarding_background}
      />
      <DialogTitle
        sx={{
          padding: '1.5rem',
          paddingTop: '2rem',
          height: '120px',
        }}
        className={styles['dialog-title']}
      >
        <div className={styles['dialog-title-container']}>
          <Box
            className="font-semibold text-2xl"
            sx={{ color: 'primary.main' }}
          >
            Hallo {profile?.personal_data?.first_name},
          </Box>
          {props.title && <Box className="text-lg">{props.title}</Box>}
        </div>
        <Box className={styles['dialog-title-placeholder']}></Box>
      </DialogTitle>

      <Box
        className={styles['form-delete-text']}
        sx={{ bgcolor: '#8bc8f5', minHeight: '120px' }}
      >
        {props.content}
      </Box>
      <Box className="text-xs px-6 pt-2">{props.infoText}</Box>
      <DialogContent
        className={styles['dialog-content']}
        sx={{
          padding: '1.5rem',
        }}
      >
        <Box ref={contentRef}>
          <Box> {props.footer}</Box>
          <Box className={styles['form-delete-buttons']}>
            <TextButton
              classes={styles['form-delete-buttons-cancel']}
              preset="underline"
              textTransform="none"
              onClick={props.onClose}
            >
              {t('app.cancel')}
            </TextButton>
            <TextButton
              preset="primary"
              // disabled={!!Object.keys(errors).length}
              type="submit"
            >
              {props.textButton ?? t('app.actions.delete')}
            </TextButton>
          </Box>
        </Box>
      </DialogContent>
    </MuiDialog>
  );
});
