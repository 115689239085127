import { useMutation } from 'react-query';
import {
  lazy,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  // useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Loader } from '../../../../shared/ui/Loader/Loader';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';
import { Dialog } from '../../../../shared/ui/Dialog/Dialog';
import { FormDelete } from '../../../../shared/components/FormDelete/FormDelete';

// Hooks
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useToolsHttp } from '../../hooks/use-tools-http.hook';
import { useSharedStore } from '../../../../shared/stores/use-shared.store';
import { useBreakpoints } from '../../../../shared/hooks/use-breakpoints.hook';

// Models
import { TemplateElement } from '../../../templates/models/templates.types';
import {
  ChildrenFileUploadRequest,
  CreateRow,
  CreateRowRequest,
  Tool,
  ToolElementDocumentPostRequest,
  ToolElementImageDeleteRequest,
  ToolElementMultiDocumentPostRequest,
  // ToolMultiElementRowPatchRequest,
  // ToolMultiElementRowPostPatchResponse,
  // ToolMultiElementRowPostRequest,
  ToolSectionElementRowIds,
  ToolValuesMultiElementRow,
  // ToolValuesSection,
} from '../../models/tools.types';
import { CrudState, ResultState } from '../../../../shared/models/shared.types';

// Stores
import { ToolsState, useToolsStore } from '../../stores/use-tools.store';

// Styles
import styles from './ToolMultiElementRowCreateEdit.module.scss';
import { useTools } from '../../hooks/use-tools.hook';
import { unstable_batchedUpdates } from 'react-dom';
import { useContactsStore } from '../../../contacts/stores/use-contacts.store';

// Lazy-load components
const ToolMultiElementChildren = lazy(
  () => import('../ToolMultiElementChildren/ToolMultiElementChildren')
);

type ToolMultiElementRowCreateEditProps = {
  edit?: boolean;
  toolId?: string;
  elementId?: string;
  sectionId?: string;
  element: TemplateElement;
  values?: ToolValuesMultiElementRow;
  seletedRow?: ToolSectionElementRowIds;
  selectedMultiElementRowEdit?: ToolSectionElementRowIds | undefined;
  selectedMultiElementRowCreate?: ToolSectionElementRowIds | undefined;
  firstRow?: boolean;
  state: CrudState;
  // setModalopen: React.Dispatch<
  //   React.SetStateAction<ToolSectionElementRowIds | undefined>
  // >;
  onCancel: () => void;
};

const ToolMultiElementRowCreateEdit = (
  props: ToolMultiElementRowCreateEditProps
) => {
  const { lgDown } = useBreakpoints();
  const { handleError, handleRetry } = useFetch();
  const [setNotification] = useSharedStore((state) => [state.setNotification]);
  const {
    toolMultiElementChildDocumentPost,
    toolMultiElementChildMultiDocumentPost,
    toolMultiElementRowPost,
    toolMultiElementRowPatch,
    toolMultiElementRowDelete,
    toolElementImageDelete,
  } = useToolsHttp();
  const { afterRowCreate, updateTool } = useTools();
  const { t } = useTranslation();

  // Component state
  const [selectedMultiElementRowDelete, setSelectedMultiElementRowDelete] =
    useState<ToolSectionElementRowIds | undefined>(undefined);
  const [uploadRequests, setUploadRequests] = useState<
    ChildrenFileUploadRequest[]
  >([]);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  // Tools store state
  const [tool, setTool, fileToDelete, setFileToDelete] = useToolsStore(
    (state: ToolsState) => [
      state.tool,
      state.setTool,
      state.fileToDelete,
      state.setFileToDelete,
    ]
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Control submit button
  useEffect(() => {
    if (isButtonDisabled) {
      const timeoutId = setTimeout(() => {
        setButtonDisabled(false);
      }, 6000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isButtonDisabled]);

  // ########## //
  // MUTATIONS  //
  // ########## //

  // DELETE Multi element row mutation
  const multiElementRowDeleteMutation = useMutation(
    (data: ToolSectionElementRowIds) => toolMultiElementRowDelete(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data) => {
        if (data) {
          setSelectedUsers([]);
          setSelectedNavGroups([]);
          setContacts([]);
          setSelectedContactId(undefined);
          // Update Row values
          if (tool) {
            const updateTool = {
              ...tool,
              values: tool.values.map((section) =>
                section.id === data.sectionId
                  ? {
                      ...section,
                      elements: section.elements.map((element) =>
                        element.id === data.elementId
                          ? {
                              ...element,
                              rows:
                                element && element.rows
                                  ? element.rows.filter(
                                      (row) => row.id !== data.rowId
                                    )
                                  : [],
                            }
                          : element
                      ),
                    }
                  : section
              ),
            };

            setTool(updateTool);
          }
        }
      },
    }
  );

  // POST Multi element row mutation
  const multiElementRowPostMutation = useMutation(
    (data: CreateRowRequest) => toolMultiElementRowPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          unstable_batchedUpdates(() => {
            setSelectedUsers([]);
            setSelectedNavGroups([]);
            setContacts([]);
            setSelectedContactId(undefined);
            if (data.tool_id && uploadRequests.length > 0) {
              toolMultiElementChildMultiDocumentPostMutation.mutate({
                body: {
                  elementId: data.element_id,
                  rowId: data.row_id,
                  sectionId: data.section_id,
                  toolId: data.tool_id!,
                  rowChildId: uploadRequests[0].childId,
                },
                files: uploadRequests,
              });
            } else {
              setNotification({
                subtitle: 'Ein neuer Eintrag wurde angelegt!',
                title: props.element?.name,
                state: ResultState.Success,
              });
            }

            // Update Row values
            afterRowCreate(data);
          });
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            setNotification({
              subtitle: 'Ein neuer Eintrag konnte nicht angelegt werden!',
              title: props.element?.name,
              state: ResultState.Error,
            });
            handleError(errRes.status);
          }
        }
        props.onCancel();
      },
    }
  );

  // PATCH Multi element row mutation
  const multiElementRowPatchMutation = useMutation(
    (data: CreateRowRequest) => toolMultiElementRowPatch(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          unstable_batchedUpdates(() => {
            if (data.tool_id && uploadRequests.length > 0) {
              setSelectedUsers([]);
              setSelectedNavGroups([]);
              setContacts([]);
              setSelectedContactId(undefined);
              toolMultiElementChildMultiDocumentPostMutation.mutate({
                body: {
                  elementId: data.element_id,
                  rowId: data.row_id,
                  sectionId: data.section_id,
                  toolId: data.tool_id!,
                  rowChildId: uploadRequests[0].childId,
                },
                files: uploadRequests,
              });
            } else {
              //data.tool_id && updateTool(data.tool_id);
              //console.log(data);
              if (tool) {
                const updateTool = {
                  ...tool,
                  values: tool.values.map((section) =>
                    section.id === data.section_id
                      ? {
                          ...section,
                          elements: section.elements.map((element) =>
                            element.id === data.element_id
                              ? {
                                  ...element,
                                  rows:
                                    element && element.rows
                                      ? element.rows.map((row) =>
                                          row.id === data.row_id
                                            ? {
                                                ...row,
                                                ...Object.fromEntries(
                                                  data.rows.map((newRow) => [
                                                    newRow.field_name,
                                                    newRow.value,
                                                  ])
                                                ),
                                              }
                                            : row
                                        )
                                      : [],
                                }
                              : element
                          ),
                        }
                      : section
                  ),
                };

                setTool(updateTool);
              }

              setNotification({
                subtitle: 'Eintrag wurde gespeichert!',
                title: props.element?.name,
                state: ResultState.Success,
              });
            }
          });
        }
        if (error) {
          const errRes = error?.response;
          if (errRes) {
            setNotification({
              subtitle: 'Ein Eintrag konnte nicht gespeichert werden!',
              title: props.element?.name,
              state: ResultState.Error,
            });
            handleError(errRes.status);
          }
        }
        props.onCancel();
      },
    }
  );

  // POST Tool multi element child document post mutation
  const toolMultiElementChildDocumentPostMutation = useMutation(
    (data: ToolElementDocumentPostRequest) =>
      toolMultiElementChildDocumentPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data, error, variables) {
        // setIsToolLoading(false);
        if (data) {
          setUploadRequests([]);
          setNotification({
            subtitle: 'Eintrag wurde gespeichert!',
            title: props.element?.name,
            state: ResultState.Success,
          });

          updateTool(variables.body.toolId);
        }
        if (error) {
          handleError(error.response.status);
        }
      },
    }
  );

  const toolMultiElementChildMultiDocumentPostMutation = useMutation(
    (data: ToolElementMultiDocumentPostRequest) =>
      toolMultiElementChildMultiDocumentPost(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data, error, variables) {
        // setIsToolLoading(false);
        if (data) {
          updateTool(variables.body.toolId);
          setNotification({
            subtitle: 'Eintrag wurde gespeichert!',
            title: props.element?.name,
            state: ResultState.Success,
          });
          setUploadRequests([]);
        }
        if (error) {
          handleError(error.response.status);
        }
      },
    }
  );

  // Request to delete an image when there is only one image
  const toolElementImageDeleteMutation = useMutation(
    (data: ToolElementImageDeleteRequest) => toolElementImageDelete(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled(data, error) {
        // setIsToolLoading(false);
        if (data) {
          // props.seletedRow?.toolId && updateTool(props.seletedRow?.toolId);
        }
        if (error) {
          handleError(error?.response.status);
        }
      },
    }
  );

  //Handler on unmount.
  useEffect(() => {
    return () => {
      setFileToDelete([]);
      setSelectedUsers([]);
      setSelectedNavGroups([]);
      setContacts([]);
      setSelectedContactId(undefined);
    };
  }, []);

  // ######### //
  // CALLBACKS //
  // ######### //
  const childDataStateRef = useRef<any>();
  // Contacts store state
  const [
    setSelectedUsers,
    setSelectedNavGroups,
    setContacts,
    setSelectedContactId,
    // setDeletedNavItem,
  ] = useContactsStore((state) => [
    state.setSelectedUsers,
    state.setSelectedNavGroups,
    state.setContacts,
    state.setSelectedContactId,
    // state.setDeletedNavItem,
  ]);
  /**
   * Handler to save values.
   */
  const onValuesSave = useCallback(() => {
    setSelectedUsers([]);
    setSelectedNavGroups([]);
    setContacts([]);
    setSelectedContactId(undefined);
    setButtonDisabled(true);

    if (childDataStateRef.current) {
      const createRowRequest: CreateRow[] =
        childDataStateRef.current.getCreateRowRequest();

      //handle button click
      if (props.edit && props.selectedMultiElementRowEdit) {
        const newRequest: CreateRowRequest = {
          tool_id: props.selectedMultiElementRowEdit.toolId,
          row_id: props.selectedMultiElementRowEdit.rowId ?? '',
          element_id: props.selectedMultiElementRowEdit.elementId ?? '',
          section_id: props.selectedMultiElementRowEdit.sectionId ?? '',
          rows: createRowRequest,
        };
        multiElementRowPatchMutation.mutate(newRequest);
      } else if (!props.edit && props.selectedMultiElementRowCreate) {
        const newRequest: CreateRowRequest = {
          tool_id: props.selectedMultiElementRowCreate.toolId,
          row_id: props.selectedMultiElementRowCreate.rowId ?? '',
          element_id: props.selectedMultiElementRowCreate.elementId ?? '',
          section_id: props.selectedMultiElementRowCreate.sectionId ?? '',
          rows: createRowRequest,
        };
        multiElementRowPostMutation.mutate(newRequest);
      }
      if (fileToDelete) {
        fileToDelete.map((file) => toolElementImageDeleteMutation.mutate(file));
      }
    }

    // setSelectedMultiElementRowEdit(undefined);
    // setSelectedMultiElementRowCreate(undefined);
  }, [fileToDelete, props]);

  /**
   * Handler to init delete multi element by ids.
   */
  const onRowDeleteInit = useCallback(() => {
    setSelectedMultiElementRowDelete(props.seletedRow);
  }, [props]);

  /**
   * Handler to delete multi element row by ids.
   */
  const onRowDeleteSubmit = useCallback(() => {
    setSelectedMultiElementRowDelete(undefined);
    props.onCancel();
    selectedMultiElementRowDelete &&
      selectedMultiElementRowDelete.rowId &&
      multiElementRowDeleteMutation.mutate(selectedMultiElementRowDelete);
    // eslint-disable-next-line
  }, [selectedMultiElementRowDelete]);

  const onUploadFileSelect = useCallback(
    (file: File, childId: string) => {
      uploadRequests.push({ childId: childId, file: file });
      setUploadRequests(uploadRequests);
    },
    [uploadRequests]
  );

  return (
    <>
      {multiElementRowDeleteMutation.isLoading ||
      multiElementRowPatchMutation.isLoading ||
      multiElementRowPostMutation.isLoading ||
      toolMultiElementChildDocumentPostMutation.isLoading ||
      toolElementImageDeleteMutation.isLoading ? (
        <Loader />
      ) : (
        <div className={styles['tool-multi-element-row-create-edit']}>
          <div
            className={styles['tool-multi-element-row-create-edit-children']}
          >
            {props.element.elements && props.element.elements.length > 0 && (
              <Suspense fallback={<Loader />}>
                <ToolMultiElementChildren
                  modal
                  element={props.element}
                  values={props.values}
                  toolId={tool?.id}
                  // createRowRequest={createRowRequest}
                  // setCreateRowRequest={setCreateRowRequest}
                  // onFieldChange={onFieldChange}
                  ref={childDataStateRef}
                  firstRow={props.firstRow}
                  state={props.state}
                  onUploadFileSelect={onUploadFileSelect}
                />
              </Suspense>
            )}
          </div>
          <div className={styles['tool-multi-element-row-create-edit-buttons']}>
            <TextButton
              preset="secondary"
              onClick={props.onCancel}
              textSize={lgDown ? 'text-xs' : undefined}
            >
              {t('app.cancel')}
            </TextButton>
            {lgDown && props.edit && (
              <>
                <TextButton
                  preset="secondary"
                  onClick={onRowDeleteInit}
                  textSize="text-xs"
                >
                  {t('app.actions.delete')}
                </TextButton>
                <Dialog
                  open={selectedMultiElementRowDelete ? true : false}
                  subtitle={t('tools.detail.multi_element.delete.subtitle')}
                  title={t('tools.detail.multi_element.delete.title')}
                  onClose={() => setSelectedMultiElementRowDelete(undefined)}
                >
                  <FormDelete
                    text={t('tools.detail.multi_element.delete.text')}
                    onClose={() => setSelectedMultiElementRowDelete(undefined)}
                    onSubmit={onRowDeleteSubmit}
                  />
                </Dialog>
              </>
            )}
            <TextButton
              preset="primary"
              onClick={onValuesSave}
              disabled={isButtonDisabled}
              textSize={lgDown ? 'text-xs' : undefined}
            >
              {t('app.save')}
            </TextButton>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(ToolMultiElementRowCreateEdit);
