import { memo } from 'react';
import { TFunctionResult } from 'i18next';
import { Box } from '@mui/material';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';

// Styles
import styles from './ToolElementInfo.module.scss';

type ToolElementInfoProps = {
  classes?: string;
  disabled?: boolean;
  label?: string | TFunctionResult;
  help_text?: string;
  padding?: string;
  placeholder?: string;
  sublabel?: string | TFunctionResult;
  width?: string;
  widthClassName?: string;
  required?: boolean;
};

export const ToolElementInfo = (props: ToolElementInfoProps) => {
  return (
    <Box
      sx={{
        '& .MuiOutlinedInfo-notchedOutline': {
          borderColor: props.disabled ? 'transparent !important' : undefined,
        },
        '& .MuiOutlinedInfo-root': {
          backgroundColor: 'bg.card',
          transitionProperty: 'background-color',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          transitionDuration: '150ms',
        },
        '& .MuiOutlinedInfo-root .MuiOutlinedInfo-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInfo-root:hover': {
          backgroundColor: !props.disabled ? 'bg.card' : undefined,
        },
        '& .MuiOutlinedInfo-root:hover .MuiOutlinedInfo-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInfo-root Info::placeholder, textarea::placeholder': {
          color: 'text.secondary',
          opacity: 1,
        },
        '& .MuiOutlinedInfo-root.Mui-focused .MuiOutlinedInfo-notchedOutline': {
          borderColor: 'primary.main',
        },
        width: props.width,
        '& .MuiOutlinedInfo-Info': {
          padding: props.padding ?? '1rem',
        },
      }}
      className={clsx(
        styles['tool-element-Info'],
        props.classes && props.classes,
        props.widthClassName
          ? props.widthClassName
          : props.width
          ? undefined
          : 'w-full'
      )}
    >
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          paddingClassName={
            styles['tool-element-Info-form-field-label-padding']
          }
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
    </Box>
  );
};

export default memo(ToolElementInfo);
