import { TFunctionResult } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

// Hooks
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../shared/hooks/use-fetch.hook';
import { useToolsHttp } from '../../hooks/use-tools-http.hook';
import { ToolsState, useToolsStore } from '../../stores/use-tools.store';
import { useMutation } from 'react-query';

// Components
import { FormFieldLabel } from '../../../../shared/ui/FormFieldLabel/FormFieldLabel';
import { IconButton } from '../../../../shared/ui/IconButton/IconButton';
import { TextButton } from '../../../../shared/ui/TextButton/TextButton';

// Types
import { ToolValuesElement } from '../../models/tools.types';
import { TemplateElement } from '../../../templates/models/templates.types';

// Styles
import styles from './ToolElementVoteStar.module.scss';
import { Loader } from '../../../../shared/ui/Loader/Loader';

type ToolElementVoteStarProps = {
  classes?: string;
  label?: TFunctionResult | string;
  sublabel?: string;
  help_text?: string;
  value?: ToolValuesElement;
  vote_element?: TemplateElement;
  sectionId?: string;
  toolId?: string;
  rowId?: string;
  required?: boolean;
  disable?: boolean;
  onChange?: (value: number) => void;
};

export const ToolElementVoteStar = (props: ToolElementVoteStarProps) => {
  const { t } = useTranslation();
  const { handleError, handleRetry } = useFetch();
  const { toolVoteSubmit, toolVoteSubmitMultiRow } = useToolsHttp();

  // Component state
  const [toolValue, setToolValue] = useState<ToolValuesElement | undefined>(
    undefined
  );
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showThanks, setShowThanks] = useState<boolean>(false);
  const [showChangeButton, setShowChangeButton] = useState<boolean>(false);
  const [valuesStar, setValuesStar] = useState<number[]>([]);
  const [selectedStar, setSelectedStar] = useState<number>(0);

  // Tools store state
  const [hasFullAccess] = useToolsStore((state: ToolsState) => [
    state.hasFullAccess,
  ]);

  // ######### //
  // MUTATIONS //
  // ######### //

  const submitVoteMutation = useMutation((data: any) => toolVoteSubmit(data), {
    retry: (failureCount, error: any) => handleRetry(failureCount, error),
    onSettled: (data, error) => {
      if (data) {
        if (toolValue) {
          let updatedToolValue: ToolValuesElement = { ...toolValue };
          updatedToolValue.value = data.vote;
          updatedToolValue.change = data.change;
          updatedToolValue.submit = data.submit;
          updatedToolValue.result = data.result;
          setToolValue(updatedToolValue);
          setSelectedStar(data.vote);
        } else {
          let updatedToolValue: ToolValuesElement = {
            id: data.id,
            value: data.vote,
            change: data.change,
            submit: data.submit,
            result: data.result,
          };
          setToolValue(updatedToolValue);
          setSelectedStar(data.vote);
        }
      }
      if (error && error.response) {
        console.error(
          'Error submit vote value:',
          handleError(error.response.status)
        );
      }
    },
  });

  const submitVoteMultiRowMutation = useMutation(
    (data: any) => toolVoteSubmitMultiRow(data),
    {
      retry: (failureCount, error: any) => handleRetry(failureCount, error),
      onSettled: (data, error) => {
        if (data) {
          if (toolValue) {
            let updatedToolValue: ToolValuesElement = { ...toolValue };
            updatedToolValue.value = data.vote;
            updatedToolValue.change = data.change;
            updatedToolValue.submit = data.submit;
            updatedToolValue.result = data.result;
            setToolValue(updatedToolValue);
            setSelectedStar(data.vote);
          } else {
            let updatedToolValue: ToolValuesElement = {
              id: data.id,
              value: data.vote,
              change: data.change,
              submit: data.submit,
              result: data.result,
            };
            setToolValue(updatedToolValue);
            setSelectedStar(data.vote);
          }
        }
        if (error && error.response) {
          console.error(
            'Error submit vote value:',
            handleError(error.response.status)
          );
        }
      },
    }
  );

  // ####### //
  // EFFECTS //
  // ####### //

  // Set tool value by props on mount
  useEffect(() => {
    if (props.value) {
      setToolValue(props.value);
      props.value.value && setSelectedStar(props.value.value);
    }
  }, [props.value]);

  // Set number of stars by vote on mount
  useEffect(() => {
    if (props.vote_element?.vote) {
      const starArray = [];
      for (let i = 0; i < props.vote_element?.vote; i++) {
        starArray.push(1);
      }
      setValuesStar(starArray);
    }
  }, [props.vote_element?.vote]);

  // Set show components
  useEffect(() => {
    if (props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowResult(true);
        setShowForm(false);
      } else {
        setShowResult(false);
        setShowForm(true);
      }
    }
    if (!props.vote_element?.settings?.vote?.result) {
      if (toolValue?.submit) {
        setShowThanks(true);
        setShowForm(false);
      } else {
        setShowThanks(false);
        setShowForm(true);
      }
    }
    props.vote_element?.settings?.vote?.change
      ? setShowChangeButton(true)
      : setShowChangeButton(false);
  }, [props, toolValue]);

  // ######### //
  // CALLBACK //
  // ######### //

  const onChange = useCallback(() => {
    setShowForm(true);
    setShowChangeButton(false);
    setShowThanks(false);
    setShowResult(false);
  }, []);

  // Save selected number of stars and change color
  const onStarClick = useCallback(
    (index: number) => {
      // setSelectedStar(index + 1);
      // props.onChange && props.onChange(index + 1);

      if (props.rowId) {
        submitVoteMultiRowMutation.mutate({
          id: props.toolId,
          body: {
            id: props.vote_element?.id,
            value: index + 1,
          },
          element_id: props.vote_element?.id,
          section_id: props.sectionId,
          row_id: props.rowId,
        });
      } else {
        !hasFullAccess &&
          submitVoteMutation.mutate({
            id: props.toolId,
            body: {
              id: props.vote_element?.id,
              value: index + 1,
            },
            element_id: props.vote_element?.id,
            section_id: props.sectionId,
          });
      }
    },
    // eslint-disable-next-line
    [props, hasFullAccess]
  );

  return (
    <>
      {hasFullAccess ? (
        <>
          <div style={{ marginRight: '10px' }}>
            {toolValue?.value ? <>{toolValue?.value}</> : <>0</>}
            {toolValue ? ' (' + toolValue.counter + ')' : ' (0)'}
          </div>
        </>
      ) : (
        <>
          <div
            className={clsx(
              styles['vote-star'],
              props.classes && props.classes
            )}
          >
            {props.label && (
              <FormFieldLabel
                required={props.required}
                label={props.label}
                paddingClassName={styles['vote-star-form-field-label-padding']}
                sublabel={props.sublabel}
                help_text={props.help_text}
              />
            )}
            <div className={styles['vote-star-row']}>
              {!submitVoteMutation.isLoading && (
                <>
                  {showResult && (
                    <div className="flex flex-row items-center">
                      <div className="inline-flex">
                        {valuesStar.map((value, index) => (
                          <IconButton
                            key={uuidv4()}
                            sxIcon={{
                              color:
                                index < selectedStar
                                  ? '#FFDA3E'
                                  : 'text.secondary',
                            }}
                            icon={
                              index < selectedStar
                                ? ['fas', 'star']
                                : ['fal', 'star']
                            }
                            padding="0.25rem"
                          />
                        ))}
                      </div>
                      {showResult && showChangeButton && (
                        <div>
                          <TextButton
                            preset="popover"
                            textSize="text-sm"
                            onClick={onChange}
                          >
                            {t('tools.vote.change')}
                          </TextButton>
                        </div>
                      )}
                    </div>
                  )}
                  {showThanks && (
                    <div className="flex flex-row items-center ml-2">
                      <div className="mr-4">{t('tools.vote.thanks')}</div>
                      {showThanks && showChangeButton && (
                        <div>
                          <TextButton
                            preset="popover"
                            textSize="text-sm"
                            onClick={onChange}
                          >
                            {t('tools.vote.change')}
                          </TextButton>
                        </div>
                      )}
                    </div>
                  )}
                  {showForm && (
                    <div className="inline-flex">
                      {!props.disable && selectedStar > 0 && (
                        <IconButton
                          classes="text-sm mr-1"
                          icon={['fas', 'times']}
                          iconSize="inherit"
                          padding="0.25rem"
                          sxIcon={{
                            color: 'error.main',
                            bgcolor: 'error.light',
                            // '&:hover': { color: 'error.main', bgcolor: 'error.light' },
                          }}
                          onClick={() => onStarClick(-1)}
                        />
                      )}
                      {valuesStar.map((value, index) => (
                        <IconButton
                          key={uuidv4()}
                          sxIcon={{
                            color:
                              index < selectedStar
                                ? '#FFDA3E'
                                : 'text.secondary',
                          }}
                          icon={
                            index < selectedStar
                              ? ['fas', 'star']
                              : ['fal', 'star']
                          }
                          padding="0.25rem"
                          onClick={
                            props.disable ? () => {} : () => onStarClick(index)
                          }
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
              {submitVoteMutation.isLoading && <Loader />}
            </div>
          </div>
        </>
      )}
    </>
  );
};
