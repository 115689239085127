import { SxProps, Theme } from '@mui/material';
import {
  GridClasses,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

import ea_betreute_person from '../../../assets/pictures/template/ea_betreute-person.svg';
import ea_gemeinschaft from '../../../assets/pictures/template/ea_gemeinschaft.svg';
import ea_ich from '../../../assets/pictures/template/ea_ich.svg';
import ka_allergien from '../../../assets/pictures/template/ka_allergien.svg';
import ka_alllgemeiner_gesundheitszustand from '../../../assets/pictures/template/ka_alllgemeiner_gesundheitszustand.svg';
import ka_ambulante_pflege from '../../../assets/pictures/template/ka_ambulante-pflege.svg';
import ka_andere_versicherung from '../../../assets/pictures/template/ka_andere_versicherung.svg';
import ka_anderer_online_account from '../../../assets/pictures/template/ka_anderer_online_account.svg';
import ka_anderes_fahrzeug from '../../../assets/pictures/template/ka_anderes_fahrzeug.svg';
import ka_anderes_haustier from '../../../assets/pictures/template/ka_anderes_haustier.svg';
import ka_anti_rutsch_produkte from '../../../assets/pictures/template/ka_anti-rutsch-produkte.svg';
import ka_apotheke from '../../../assets/pictures/template/ka_apotheke.svg';
import ka_arztbesuch from '../../../assets/pictures/template/ka_arztbesuch.svg';
import ka_aspiration from '../../../assets/pictures/template/ka_aspiration.svg';
import ka_assessment_dekubitusfaktoren from '../../../assets/pictures/template/ka_assessment_dekubitusfaktoren.svg';
import ka_augenoptik from '../../../assets/pictures/template/ka_augenoptik.svg';
import ka_ausflug_reisen from '../../../assets/pictures/template/ka_ausflug-reisen.svg';
import ka_auto from '../../../assets/pictures/template/ka_auto.svg';
import ka_babyschwimmen from '../../../assets/pictures/template/ka_babyschwimmen.svg';
import ka_bankkonto from '../../../assets/pictures/template/ka_bankkonto.svg';
import ka_barrierefrei_wohnen from '../../../assets/pictures/template/ka_barrierefrei-wohnen.svg';
import ka_bauvorhaben from '../../../assets/pictures/template/ka_bauvorhaben.svg';
import ka_begleitung from '../../../assets/pictures/template/ka_begleitung.svg';
import ka_behoerde from '../../../assets/pictures/template/ka_behoerde.svg';
import ka_beratungsgespraech from '../../../assets/pictures/template/ka_beratungsgespraech.svg';
import ka_berufsunfaehigkeit from '../../../assets/pictures/template/ka_berufsunfaehigkeit.svg';
import ka_besd from '../../../assets/pictures/template/ka_besd.svg';
import ka_besorgungen from '../../../assets/pictures/template/ka_besorgungen.svg';
import ka_bestattung_beratung from '../../../assets/pictures/template/ka_bestattung-beratung.svg';
import ka_beteiligung from '../../../assets/pictures/template/ka_beteiligung.svg';
import ka_betreuung_klinik_pflegeeinrichtung from '../../../assets/pictures/template/ka_betreuung-klinik+pflegeeinrichtung.svg';
import ka_betreuung_zuhause from '../../../assets/pictures/template/ka_betreuung-zuhause.svg';
import ka_betreuungsverfuegung from '../../../assets/pictures/template/ka_betreuungsverfuegung.svg';
import ka_bewegung_lagerung from '../../../assets/pictures/template/ka_bewegung_lagerung.svg';
import ka_bilanzierung_ein_und_ausfuhr from '../../../assets/pictures/template/ka_bilanzierung_ein_und_ausfuhr.svg';
import ka_blindengeld from '../../../assets/pictures/template/ka_blindengeld.svg';
import ka_blumen_beet from '../../../assets/pictures/template/ka_blumen-beet.svg';
import ka_blutdruck from '../../../assets/pictures/template/ka_blutdruck.svg';
import ka_chronische_krankheit from '../../../assets/pictures/template/ka_chronische-krankheit.svg';
import ka_corporate from '../../../assets/pictures/template/ka_corporate.svg';
import ka_dehydration from '../../../assets/pictures/template/ka_dehydration.svg';
import ka_dienstplan from '../../../assets/pictures/template/ka_dienstplan.svg';
import ka_doc from '../../../assets/pictures/template/ka_doc.svg';
import ka_dokument from '../../../assets/pictures/template/ka_dokument.svg';
import ka_drogerie from '../../../assets/pictures/template/ka_drogerie.svg';
import ka_drogerie2 from '../../../assets/pictures/template/ka_drogerie2.svg';
import ka_einkaufsliste from '../../../assets/pictures/template/ka_einkaufsliste.svg';
import ka_einkaufsrabatt from '../../../assets/pictures/template/ka_einkaufsrabatt.svg';
import ka_einkommen from '../../../assets/pictures/template/ka_einkommen.svg';
import ka_einzelunternehmen from '../../../assets/pictures/template/ka_einzelunternehmen.svg';
import ka_eltern from '../../../assets/pictures/template/ka_eltern.svg';
import ka_email from '../../../assets/pictures/template/ka_email.svg';
import ka_energie_strom from '../../../assets/pictures/template/ka_energie+strom.svg';
import ka_fahrrad from '../../../assets/pictures/template/ka_fahrrad.svg';
import ka_fahrtkosten from '../../../assets/pictures/template/ka_fahrtkosten.svg';
import ka_familie from '../../../assets/pictures/template/ka_familie.svg';
import ka_familienmitglied from '../../../assets/pictures/template/ka_familienmitglied.svg';
import ka_feedback_zur_Nutzung from '../../../assets/pictures/template/ka_feedback-zur-Nutzung.svg';
import ka_fische from '../../../assets/pictures/template/ka_fische.svg';
import ka_freie_Betten from '../../../assets/pictures/template/ka_freie-Betten.svg';
import ka_freizeit from '../../../assets/pictures/template/ka_freizeit.svg';
import ka_freizeitsport from '../../../assets/pictures/template/ka_freizeitsport.svg';
import ka_Friseur from '../../../assets/pictures/template/ka_Friseur.svg';
import ka_gefaesstherapie from '../../../assets/pictures/template/ka_gefaesstherapie.svg';
import ka_gehoerlosengeld from '../../../assets/pictures/template/ka_gehoerlosengeld.svg';
import ka_geld from '../../../assets/pictures/template/ka_geld.svg';
import ka_gemeindebus from '../../../assets/pictures/template/ka_gemeindebus.svg';
import ka_Geselligkeit from '../../../assets/pictures/template/ka_Geselligkeit.svg';
import ka_gewichtsverlauf from '../../../assets/pictures/template/ka_gewichtsverlauf.svg';
import ka_grosseltern from '../../../assets/pictures/template/ka_grosseltern.svg';
import ka_gruppen from '../../../assets/pictures/template/ka_gruppen.svg';
import ka_gruppen2 from '../../../assets/pictures/template/ka_gruppen2.svg';
import ka_haus_garten from '../../../assets/pictures/template/ka_haus+garten.svg';
import ka_haushalt from '../../../assets/pictures/template/ka_haushalt.svg';
import ka_haushalt2 from '../../../assets/pictures/template/ka_haushalt2.svg';
import ka_hausnotruf from '../../../assets/pictures/template/ka_hausnotruf.svg';
import ka_hauswirtschaft from '../../../assets/pictures/template/ka_hauswirtschaft.svg';
import ka_hoergeraete from '../../../assets/pictures/template/ka_hoergeraete.svg';
import ka_hund from '../../../assets/pictures/template/ka_hund.svg';
import ka_image from '../../../assets/pictures/template/ka_image.svg';
import ka_immobilien from '../../../assets/pictures/template/ka_immobilien.svg';
import ka_injektionsprotokoll from '../../../assets/pictures/template/ka_injektionsprotokoll.svg';
import ka_instandhaltung from '../../../assets/pictures/template/ka_instandhaltung.svg';
import ka_insulininjektion from '../../../assets/pictures/template/ka_insulininjektion.svg';
import ka_kaffee_kuchen from '../../../assets/pictures/template/ka_kaffee+kuchen.svg';
import ka_katze from '../../../assets/pictures/template/ka_katze.svg';
import ka_keller_dachboden from '../../../assets/pictures/template/ka_keller+dachboden.svg';
import ka_kind from '../../../assets/pictures/template/ka_kind.svg';
import ka_kino_film from '../../../assets/pictures/template/ka_kino+film.svg';
import ka_kleidung from '../../../assets/pictures/template/ka_kleidung.svg';
import ka_kochen from '../../../assets/pictures/template/ka_kochen.svg';
import ka_kompressionsstruempfe from '../../../assets/pictures/template/ka_kompressionsstruempfe.svg';
import ka_kontraktur from '../../../assets/pictures/template/ka_kontraktur.svg';
import ka_konzert from '../../../assets/pictures/template/ka_konzert.svg';
import ka_kosmetik from '../../../assets/pictures/template/ka_kosmetik.svg';
import ka_krankenpflege from '../../../assets/pictures/template/ka_krankenpflege.svg';
import ka_krankentransport from '../../../assets/pictures/template/ka_krankentransport.svg';
import ka_krankenversicherung from '../../../assets/pictures/template/ka_krankenversicherung.svg';
import ka_lebensmittel from '../../../assets/pictures/template/ka_lebensmittel.svg';
import ka_lebensversicherung from '../../../assets/pictures/template/ka_lebensversicherung.svg';
import ka_lieferdienst from '../../../assets/pictures/template/ka_lieferdienst.svg';
import ka_liste from '../../../assets/pictures/template/ka_liste.svg';
import ka_literaturliste from '../../../assets/pictures/template/ka_literaturliste.svg';
import ka_malgruppe from '../../../assets/pictures/template/ka_malgruppe.svg';
import ka_medikamentenliste from '../../../assets/pictures/template/ka_medikamentenliste.svg';
import ka_medikamentenplan from '../../../assets/pictures/template/ka_medikamentenplan.svg';
import ka_motorrad from '../../../assets/pictures/template/ka_motorrad.svg';
import ka_musik_singen from '../../../assets/pictures/template/ka_musik-singen.svg';
import ka_nagelstudio from '../../../assets/pictures/template/ka_nagelstudio.svg';
import ka_obstibation from '../../../assets/pictures/template/ka_obstibation.svg';
import ka_onlineshop from '../../../assets/pictures/template/ka_onlineshop.svg';
import ka_orthopaedie from '../../../assets/pictures/template/ka_orthopaedie.svg';
import ka_partnerschaft from '../../../assets/pictures/template/ka_partnerschaft.svg';
import ka_patient from '../../../assets/pictures/template/ka_patient.svg';
import ka_patientenverfuegung from '../../../assets/pictures/template/ka_patientenverfuegung.svg';
import ka_pdf from '../../../assets/pictures/template/ka_pdf.svg';
import ka_pedikuere from '../../../assets/pictures/template/ka_pedikuere.svg';
import ka_pengueen from '../../../assets/pictures/template/ka_pengueen.svg';
import ka_persoenliche_zuwendung from '../../../assets/pictures/template/ka_persoenliche-zuwendung.svg';
import ka_pferd from '../../../assets/pictures/template/ka_pferd.svg';
import ka_pflege from '../../../assets/pictures/template/ka_pflege.svg';
import ka_pflegegeld from '../../../assets/pictures/template/ka_pflegegeld.svg';
import ka_pflegehilfsmittel from '../../../assets/pictures/template/ka_pflegehilfsmittel.svg';
import ka_pflegeversicherung from '../../../assets/pictures/template/ka_pflegeversicherung.svg';
import ka_planung from '../../../assets/pictures/template/ka_planung.svg';
import ka_priv_haftpflicht from '../../../assets/pictures/template/ka_priv-haftpflicht.svg';
import ka_projekt from '../../../assets/pictures/template/ka_projekt.svg';
import ka_putzen_aufraeumen from '../../../assets/pictures/template/ka_putzen+aufraeumen.svg';
import ka_rasen_hecke from '../../../assets/pictures/template/ka_rasen+hecke.svg';
import ka_rechtberatung from '../../../assets/pictures/template/ka_rechtberatung.svg';
import ka_roller from '../../../assets/pictures/template/ka_roller.svg';
import ka_sanitaetsfachhandel from '../../../assets/pictures/template/ka_sanitaetsfachhandel.svg';
import ka_sauna from '../../../assets/pictures/template/ka_sauna.svg';
import ka_schlaf from '../../../assets/pictures/template/ka_schlaf.svg';
import ka_schliessfach from '../../../assets/pictures/template/ka_schliessfach.svg';
import ka_schmerzerfassung from '../../../assets/pictures/template/ka_schmerzerfassung.svg';
import ka_schmerzprotokoll from '../../../assets/pictures/template/ka_schmerzprotokoll.svg';
import ka_schuelerprofil from '../../../assets/pictures/template/ka_schuelerprofil.svg';
import ka_schulprotokoll from '../../../assets/pictures/template/ka_schulprotokoll.svg';
import ka_schwerbehinderung from '../../../assets/pictures/template/ka_schwerbehinderung.svg';
import ka_screening_dekubitusrisikofaktoren from '../../../assets/pictures/template/ka_screening_dekubitusrisikofaktoren.svg';
import ka_scrum from '../../../assets/pictures/template/ka_scrum.svg';
import ka_serverzugang from '../../../assets/pictures/template/ka_serverzugang.svg';
import ka_service from '../../../assets/pictures/template/ka_service.svg';
import ka_sitzungsprotokoll from '../../../assets/pictures/template/ka_sitzungsprotokoll.svg';
import ka_socialmedia from '../../../assets/pictures/template/ka_socialmedia.svg';
import ka_sonstiges from '../../../assets/pictures/template/ka_sonstiges.svg';
import ka_soorprophylaxe from '../../../assets/pictures/template/ka_soorprophylaxe.svg';
import ka_sparbuch from '../../../assets/pictures/template/ka_sparbuch.svg';
import ka_spazierengehen from '../../../assets/pictures/template/ka_spazierengehen.svg';
import ka_spiel_spass from '../../../assets/pictures/template/ka_spiel+spass.svg';
import ka_spinnen_insekten from '../../../assets/pictures/template/ka_spinnen+insekten.svg';
import ka_sportmedizinischeuntersuchung from '../../../assets/pictures/template/ka_sportmedizinischeuntersuchung.svg';
import ka_sportzahnschutzschiene from '../../../assets/pictures/template/ka_sportzahnschutzschiene.svg';
import ka_sterbehilfe from '../../../assets/pictures/template/ka_sterbehilfe.svg';
import ka_sturzprotokoll from '../../../assets/pictures/template/ka_sturzprotokoll.svg';
import ka_tag_und_nacht from '../../../assets/pictures/template/ka_tag-und-nacht.svg';
import ka_teenager from '../../../assets/pictures/template/ka_teenager.svg';
import ka_termine from '../../../assets/pictures/template/ka_termine.svg';
import ka_theater from '../../../assets/pictures/template/ka_theater.svg';
import ka_thrombose from '../../../assets/pictures/template/ka_thrombose.svg';
import ka_treppenlifte from '../../../assets/pictures/template/ka_treppenlifte.svg';
import ka_umfrage from '../../../assets/pictures/template/ka_umfrage.svg';
import ka_unsere_freundschaft from '../../../assets/pictures/template/ka_unsere-freundschaft.svg';
import ka_unternehmen from '../../../assets/pictures/template/ka_unternehmen.svg';
import ka_unterstuetzung from '../../../assets/pictures/template/ka_unterstuetzung.svg';
import ka_urlaubsliste from '../../../assets/pictures/template/ka_urlaubsliste.svg';
import ka_verein from '../../../assets/pictures/template/ka_verein.svg';
import ka_vermoegen from '../../../assets/pictures/template/ka_vermoegen.svg';
import ka_versorgung from '../../../assets/pictures/template/ka_versorgung.svg';
import ka_vertraege from '../../../assets/pictures/template/ka_vertraege.svg';
import ka_vertretungsplan from '../../../assets/pictures/template/ka_vertretungsplan.svg';
import ka_verwandte from '../../../assets/pictures/template/ka_verwandte.svg';
import ka_vitalzeichen from '../../../assets/pictures/template/ka_vitalzeichen.svg';
import ka_vogel from '../../../assets/pictures/template/ka_vogel.svg';
import ka_Vorlagen_Datenschutz from '../../../assets/pictures/template/ka_Vorlagen_Datenschutz.svg';
import ka_vorsorgevollmacht from '../../../assets/pictures/template/ka_vorsorgevollmacht.svg';
import ka_web_hosting from '../../../assets/pictures/template/ka_web-hosting.svg';
import ka_webseite from '../../../assets/pictures/template/ka_webseite.svg';
import ka_wellness from '../../../assets/pictures/template/ka_wellness.svg';
import ka_wellness_beauty from '../../../assets/pictures/template/ka_wellness+beauty.svg';
import ka_wg_Betreuung from '../../../assets/pictures/template/ka_wg-Betreuung.svg';
import ka_wifi from '../../../assets/pictures/template/ka_wifi.svg';
import ka_wohnen from '../../../assets/pictures/template/ka_wohnen.svg';
import ka_wunderhebung from '../../../assets/pictures/template/ka_wunderhebung.svg';
import ka_wundpflege from '../../../assets/pictures/template/ka_wundpflege.svg';
import ka_xls from '../../../assets/pictures/template/ka_xls.svg';
import ka_zahnmedizin from '../../../assets/pictures/template/ka_zahnmedizin.svg';
import ka_zahnreinigung from '../../../assets/pictures/template/ka_zahnreinigung.svg';
import ka_zur_miete from '../../../assets/pictures/template/ka_zur-miete.svg';

// Models
import {
  Attachment,
  Option,
  Theme as ETheme,
} from '../../../shared/models/shared.types';
import {
  TemplateElement,
  TemplateElementCreateEditState,
  TemplateElementSettings,
  TemplateElementType,
} from '../../templates/models/templates.types';
import {
  ToolValuesElement,
  ToolValuesMultiElementRow,
  ToolValuesSection,
  ToolElementValue,
} from '../models/tools.types';

export const columnPropsAreEqual = (
  prevProps: Readonly<GridRenderCellParams>,
  nextProps: Readonly<GridRenderCellParams>
): boolean => {
  try {
    const prevValue = multiElementChildValueGet(prevProps.field, prevProps.row);
    const nextValue = multiElementChildValueGet(nextProps.field, nextProps.row);
    if (prevValue === nextValue) {
      return true;
    }
  } catch (error) {
    console.error('ERROR on checking prev and next props on column:', error);
  }
  return false;
};

export const dateOptions: any = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const dateExportOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

export const dateTimeOptions: any = {
  hour: 'numeric',
  // weekday: 'long',
  year: 'numeric',
  month: 'short',
  minute: 'numeric',
  day: 'numeric',
};
export const timeOptions: any = {
  hour: 'numeric',
  minute: 'numeric',
};

/**
 * Returns element create edit object by id and element type.
 * @param id Element id
 * @param type Element type
 * @returns Element create edit object
 */
export const elementSettingsGet = (
  type: TemplateElementType,
  childElement?: boolean
): TemplateElementSettings | undefined => {
  switch (type) {
    case TemplateElementType.AutoId:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          autoid_postfix: false,
          autoid_postfix_text: '',
          autoid_prefix: false,
          autoid_prefix_text: '',
        },
        list: {},
        rights: undefined,
      };
    case TemplateElementType.Time:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          not_showing_at_table: false,
          only_admin_can_edit: false,
          required: false,
          show_only_for_admin: false,
          value_show_in_other_tool: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Day:
    case TemplateElementType.DatePicker:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          auto_date: false,
          date_with_time: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          not_showing_at_table: false,
          only_admin_can_edit: false,
          required: false,
          show_only_for_admin: false,
          value_show_in_other_tool: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.DataFromOtherTool:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          auto_select: false,
          contact_card: false,
          not_showing_at_table: false,
          only_admin_can_edit: false,
          required: false,
          show_contact_at_table_for_data_from_other_tool: false,
          show_group_at_table_for_data_from_other_tool: false,
          show_only_for_admin: false,
          template_elements_for_data_from_other_tool: '',
          template_for_data_from_other_tool: '',
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.DataElementFromOtherTool:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          required: false,
          show_only_for_admin: false,
          template_elements_for_data_from_other_tool: '',
        },
        list: {},
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Contact:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          auto_select: false,
          contact_card: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          not_showing_at_table: false,
          only_admin_can_edit: false,
          required: false,
          show_only_for_admin: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.MultiElement:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          folder_for_tool_data: '',
          notification_admins: false,
          notification_community_owner: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          notification_employees: false,
          notification_multielement_affected_person: false,
          notification_multielement_changed: false,
          notification_multielement_created: false,
          notification_multielement_creator: false,
          notification_multielement_emails: false,
          notification_multielement_following_receiver: false,
          notification_tool_creator: false,
          n_posts: 0,
          only_admin_can_edit: false,
          only_admin_can_post: false,
          only_admin_can_remove_posts: false,
          only_edit_own_posts: false,
          only_show_own_posts: false,
          required: false,
          show_only_for_admin: false,
          template_for_data_from_other_tool: '',
        },
        multi_element_table: {
          tile_view: true,
          elements_admin: [],
          elements_visible: [],
          sort_by: {
            id: '',
            order: 'ASC',
          },
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Toggle:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          required: false,
          show_only_for_admin: false,
          toggle_false_string: 'Nein',
          toggle_true_string: 'Ja',
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Checkbox:
    case TemplateElementType.EMail:
    case TemplateElementType.Group:
    case TemplateElementType.Input:
    case TemplateElementType.RadioButton:
    case TemplateElementType.Reminder:
    case TemplateElementType.Textarea:
    case TemplateElementType.Url:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          required: false,
          show_at_arccordion_header: false,
          show_only_for_admin: false,
          value_show_in_other_tool: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Select:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          connected_contact: childElement ? undefined : false,
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          required: false,
          show_only_for_admin: false,
          single_select: false,
          value_show_in_other_tool: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Number:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          duration: false,
          hasSuffix: false,
          integer: false,
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          required: false,
          show_only_for_admin: false,
          thousandPoint: false,
          value_show_in_other_tool: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Star:
    case TemplateElementType.Grade:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          required: false,
          show_at_arccordion_header: false,
          show_only_for_admin: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
        vote: {
          change: false,
          result: false,
        },
      };
    case TemplateElementType.Telephone:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          show_only_for_admin: false,
          value_show_in_other_tool: false,
        },
        list: {},
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.User:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          only_show_employees: false,
          only_show_members: false,
          required: false,
          show_only_for_admin: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Upload:
      return {
        export: {
          disableExport: false,
        },
        advanced: {
          not_showing_at_table: false,
          notification_element_changed: false,
          notification_element_changed_content: false,
          notification_element_changed_title: false,
          only_admin_can_edit: false,
          only_archive: false,
          only_film: false,
          only_image: false,
          direct_download: false,
          only_office: false,
          only_one_image: false,
          required: false,
          show_only_for_admin: false,
          small_image: false,
        },
        list: {
          employees: false,
          members: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
    case TemplateElementType.Decision:
    case TemplateElementType.Grade:
    case TemplateElementType.Headline:
    case TemplateElementType.Image:
    case TemplateElementType.Info:
    case TemplateElementType.Map:
    case TemplateElementType.MultiElementCreateRow:
      return undefined;
    default:
      return {
        export: {
          disableExport: false,
        },
        rights: {
          employee: false,
          member: false,
          pengueen_admin: false,
        },
      };
  }
};

/**
 * Returns element create edit steps by element type.
 * @param elementType Element type
 * @returns Element create edit steps
 */
export const elementCreateStepsGet = (
  elementType: TemplateElementType,
  connected_contact?: boolean
): TemplateElementCreateEditState[] => {
  switch (elementType) {
    case TemplateElementType.Input:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.InputSetting,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Summary,
      ];
    case TemplateElementType.Contact:
    case TemplateElementType.Group:
    case TemplateElementType.Reminder:
    case TemplateElementType.Toggle:
    case TemplateElementType.Upload:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Summary,
      ];
    case TemplateElementType.Checkbox:
    case TemplateElementType.RadioButton:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Options,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Summary,
      ];
    case TemplateElementType.Select:
      if (connected_contact) {
        return [
          TemplateElementCreateEditState.Type,
          TemplateElementCreateEditState.Name,
          TemplateElementCreateEditState.Settings,
          TemplateElementCreateEditState.Summary,
        ];
      } else {
        return [
          TemplateElementCreateEditState.Type,
          TemplateElementCreateEditState.Name,
          TemplateElementCreateEditState.Options,
          TemplateElementCreateEditState.Settings,
          TemplateElementCreateEditState.Summary,
        ];
      }
    case TemplateElementType.Star:
    case TemplateElementType.Grade:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Vote,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Summary,
      ];
    case TemplateElementType.Headline:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Headline,
      ];
    case TemplateElementType.Image:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Image,
        TemplateElementCreateEditState.Summary,
      ];
    case TemplateElementType.Info:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Content,
        TemplateElementCreateEditState.Summary,
      ];

    case TemplateElementType.User:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Summary,
      ];
    case TemplateElementType.MultiElement:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Notification,
        TemplateElementCreateEditState.Summary,
      ];
    default:
      return [
        TemplateElementCreateEditState.Type,
        TemplateElementCreateEditState.Name,
        TemplateElementCreateEditState.Settings,
        TemplateElementCreateEditState.Summary,
      ];
  }
};

/**
 * Returns new state by direction.
 * @param direction Direction
 * @param state ToolElementCreateEditState
 * @param steps ToolElementCreateEditState array steps
 * @returns ToolElementCreateEditState
 */
export const elementCreateEditStateGet = (
  direction: number,
  state: TemplateElementCreateEditState,
  steps: TemplateElementCreateEditState[]
): TemplateElementCreateEditState | undefined => {
  // Get current index in steps
  const index = steps.findIndex((step) => step === state);
  if (direction === -1 && index - 1 > 0) {
    // Get previous state (exclude element type component)
    return steps[index - 1];
  }
  if (direction === 1 && index + 1 < steps.length) {
    // Get next state
    return steps[index + 1];
  }
  return undefined;
};

/**
 * GET Tool element value by values and ids.
 * @param toolValuesSection ToolValuesSection
 * @param sectionId Section id
 * @param elementId Element id
 * @returns Tool element value
 */
export const elementValueGet = (
  toolValuesSection: ToolValuesSection[],
  sectionId: string,
  elementId: string,
  getAllElement?: boolean
): ToolElementValue => {
  const matchedSection = toolValuesSection.find(
    (section) => section.id === sectionId
  );
  if (matchedSection) {
    const matchedElement = matchedSection.elements.find(
      (element) => element.id === elementId
    );

    if (matchedElement) {
      if (getAllElement) {
        return matchedElement;
      }
      // Defalut return value
      return matchedElement.value;
    }
  }
  return undefined;
};

/**
 * Returns mock icons.
 * @returns Icons
 * @deprecated To be removed when backend is available.
 */
export const getMockIcons = (): Attachment[] => {
  return [
    { id: '1', url: ea_betreute_person },
    { id: '2', url: ea_gemeinschaft },
    { id: '3', url: ea_ich },
    { id: '4', url: ka_allergien },
    { id: '5', url: ka_alllgemeiner_gesundheitszustand },
    { id: '6', url: ka_ambulante_pflege },
    { id: '7', url: ka_andere_versicherung },
    { id: '8', url: ka_anderer_online_account },
    { id: '9', url: ka_anderes_fahrzeug },
    { id: '10', url: ka_anderes_haustier },
    { id: '11', url: ka_anti_rutsch_produkte },
    { id: '12', url: ka_apotheke },
    { id: '13', url: ka_arztbesuch },
    { id: '14', url: ka_aspiration },
    { id: '15', url: ka_assessment_dekubitusfaktoren },
    { id: '16', url: ka_augenoptik },
    { id: '17', url: ka_ausflug_reisen },
    { id: '18', url: ka_auto },
    { id: '19', url: ka_babyschwimmen },
    { id: '20', url: ka_bankkonto },
    { id: '21', url: ka_barrierefrei_wohnen },
    { id: '22', url: ka_bauvorhaben },
    { id: '23', url: ka_begleitung },
    { id: '24', url: ka_behoerde },
    { id: '25', url: ka_beratungsgespraech },
    { id: '26', url: ka_berufsunfaehigkeit },
    { id: '27', url: ka_besd },
    { id: '28', url: ka_besorgungen },
    { id: '29', url: ka_bestattung_beratung },
    { id: '30', url: ka_beteiligung },
    { id: '31', url: ka_betreuung_klinik_pflegeeinrichtung },
    { id: '32', url: ka_betreuung_zuhause },
    { id: '33', url: ka_betreuungsverfuegung },
    { id: '34', url: ka_bewegung_lagerung },
    { id: '35', url: ka_bilanzierung_ein_und_ausfuhr },
    { id: '36', url: ka_blindengeld },
    { id: '37', url: ka_blumen_beet },
    { id: '38', url: ka_blutdruck },
    { id: '39', url: ka_chronische_krankheit },
    { id: '40', url: ka_corporate },
    { id: '41', url: ka_dehydration },
    { id: '42', url: ka_dienstplan },
    { id: '43', url: ka_doc },
    { id: '44', url: ka_dokument },
    { id: '45', url: ka_drogerie },
    { id: '46', url: ka_drogerie2 },
    { id: '47', url: ka_einkaufsliste },
    { id: '48', url: ka_einkaufsrabatt },
    { id: '49', url: ka_einkommen },
    { id: '50', url: ka_einzelunternehmen },
    { id: '51', url: ka_eltern },
    { id: '52', url: ka_email },
    { id: '53', url: ka_energie_strom },
    { id: '54', url: ka_fahrrad },
    { id: '55', url: ka_fahrtkosten },
    { id: '56', url: ka_familie },
    { id: '57', url: ka_familienmitglied },
    { id: '58', url: ka_feedback_zur_Nutzung },
    { id: '59', url: ka_fische },
    { id: '60', url: ka_freie_Betten },
    { id: '61', url: ka_freizeit },
    { id: '62', url: ka_freizeitsport },
    { id: '63', url: ka_Friseur },
    { id: '64', url: ka_gefaesstherapie },
    { id: '65', url: ka_gehoerlosengeld },
    { id: '66', url: ka_geld },
    { id: '67', url: ka_gemeindebus },
    { id: '68', url: ka_Geselligkeit },
    { id: '69', url: ka_gewichtsverlauf },
    { id: '70', url: ka_grosseltern },
    { id: '71', url: ka_gruppen },
    { id: '72', url: ka_gruppen2 },
    { id: '73', url: ka_haus_garten },
    { id: '74', url: ka_haushalt },
    { id: '75', url: ka_haushalt2 },
    { id: '76', url: ka_hausnotruf },
    { id: '77', url: ka_hauswirtschaft },
    { id: '78', url: ka_hoergeraete },
    { id: '79', url: ka_hund },
    { id: '80', url: ka_image },
    { id: '81', url: ka_immobilien },
    { id: '82', url: ka_injektionsprotokoll },
    { id: '83', url: ka_instandhaltung },
    { id: '84', url: ka_insulininjektion },
    { id: '85', url: ka_kaffee_kuchen },
    { id: '86', url: ka_katze },
    { id: '87', url: ka_keller_dachboden },
    { id: '88', url: ka_kind },
    { id: '89', url: ka_kino_film },
    { id: '90', url: ka_kleidung },
    { id: '91', url: ka_kochen },
    { id: '92', url: ka_kompressionsstruempfe },
    { id: '93', url: ka_kontraktur },
    { id: '94', url: ka_konzert },
    { id: '95', url: ka_kosmetik },
    { id: '96', url: ka_krankenpflege },
    { id: '97', url: ka_krankentransport },
    { id: '98', url: ka_krankenversicherung },
    { id: '99', url: ka_lebensmittel },
    { id: '100', url: ka_lebensversicherung },
    { id: '101', url: ka_lieferdienst },
    { id: '102', url: ka_liste },
    { id: '103', url: ka_literaturliste },
    { id: '104', url: ka_malgruppe },
    { id: '105', url: ka_medikamentenliste },
    { id: '106', url: ka_medikamentenplan },
    { id: '107', url: ka_motorrad },
    { id: '108', url: ka_musik_singen },
    { id: '109', url: ka_nagelstudio },
    { id: '110', url: ka_obstibation },
    { id: '111', url: ka_onlineshop },
    { id: '112', url: ka_orthopaedie },
    { id: '113', url: ka_partnerschaft },
    { id: '114', url: ka_patient },
    { id: '115', url: ka_patientenverfuegung },
    { id: '116', url: ka_pdf },
    { id: '117', url: ka_pedikuere },
    { id: '118', url: ka_pengueen },
    { id: '119', url: ka_persoenliche_zuwendung },
    { id: '120', url: ka_pferd },
    { id: '121', url: ka_pflege },
    { id: '122', url: ka_pflegegeld },
    { id: '123', url: ka_pflegehilfsmittel },
    { id: '124', url: ka_pflegeversicherung },
    { id: '125', url: ka_planung },
    { id: '126', url: ka_priv_haftpflicht },
    { id: '127', url: ka_projekt },
    { id: '128', url: ka_putzen_aufraeumen },
    { id: '129', url: ka_rasen_hecke },
    { id: '130', url: ka_rechtberatung },
    { id: '131', url: ka_roller },
    { id: '132', url: ka_sanitaetsfachhandel },
    { id: '133', url: ka_sauna },
    { id: '134', url: ka_schlaf },
    { id: '135', url: ka_schliessfach },
    { id: '136', url: ka_schmerzerfassung },
    { id: '137', url: ka_schmerzprotokoll },
    { id: '138', url: ka_schuelerprofil },
    { id: '139', url: ka_schulprotokoll },
    { id: '140', url: ka_schwerbehinderung },
    { id: '141', url: ka_screening_dekubitusrisikofaktoren },
    { id: '142', url: ka_scrum },
    { id: '143', url: ka_serverzugang },
    { id: '144', url: ka_service },
    { id: '145', url: ka_sitzungsprotokoll },
    { id: '146', url: ka_socialmedia },
    { id: '147', url: ka_sonstiges },
    { id: '148', url: ka_soorprophylaxe },
    { id: '149', url: ka_sparbuch },
    { id: '150', url: ka_spazierengehen },
    { id: '151', url: ka_spiel_spass },
    { id: '152', url: ka_spinnen_insekten },
    { id: '153', url: ka_sportmedizinischeuntersuchung },
    { id: '154', url: ka_sportzahnschutzschiene },
    { id: '155', url: ka_sterbehilfe },
    { id: '156', url: ka_sturzprotokoll },
    { id: '157', url: ka_tag_und_nacht },
    { id: '158', url: ka_teenager },
    { id: '159', url: ka_termine },
    { id: '160', url: ka_theater },
    { id: '161', url: ka_thrombose },
    { id: '162', url: ka_treppenlifte },
    { id: '163', url: ka_umfrage },
    { id: '164', url: ka_unsere_freundschaft },
    { id: '165', url: ka_unternehmen },
    { id: '166', url: ka_unterstuetzung },
    { id: '167', url: ka_urlaubsliste },
    { id: '168', url: ka_verein },
    { id: '169', url: ka_vermoegen },
    { id: '170', url: ka_versorgung },
    { id: '171', url: ka_vertraege },
    { id: '172', url: ka_vertretungsplan },
    { id: '173', url: ka_verwandte },
    { id: '174', url: ka_vitalzeichen },
    { id: '175', url: ka_vogel },
    { id: '176', url: ka_Vorlagen_Datenschutz },
    { id: '177', url: ka_vorsorgevollmacht },
    { id: '178', url: ka_web_hosting },
    { id: '179', url: ka_webseite },
    { id: '180', url: ka_wellness },
    { id: '181', url: ka_wellness_beauty },
    { id: '182', url: ka_wg_Betreuung },
    { id: '183', url: ka_wifi },
    { id: '184', url: ka_wohnen },
    { id: '185', url: ka_wunderhebung },
    { id: '186', url: ka_wundpflege },
    { id: '187', url: ka_xls },
    { id: '188', url: ka_zahnmedizin },
    { id: '189', url: ka_zahnreinigung },
    { id: '190', url: ka_zur_miete },
  ];
};

/**
 * GET Multi element child value by child id and multi element row.
 * @param id Child id
 * @param row Multi element row
 * @returns ToolValue
 */
export const multiElementChildValueGet = (
  id: string,
  row: ToolValuesMultiElementRow | undefined,
  getNames?: boolean,
  getAllElement?: boolean,
  getFirstElementName?: boolean,
  compareWithName?: boolean
): ToolElementValue => {
  if (row) {
    if (row.elements) {
      const matchedElement = compareWithName
        ? row.elements.find((element) => element.names === id)
        : row.elements.find((element) => element.id === id);
      if (matchedElement && matchedElement.value) {
        if (true === getNames && Array.isArray(matchedElement.value)) {
          return matchedElement.names;
        }
        if (true === getAllElement) {
          return matchedElement;
        } else if (getFirstElementName && Array.isArray(matchedElement.value)) {
          return matchedElement.value[0].name;
        } else {
          // Default return
          return matchedElement.value;
        }
      }
    }
  }
  return undefined;
};

/**
 * Returns multi element data grid custom classes.
 * @returns Multi element data grid custom classes
 */
export const multiElementDataGridClassesGet = ():
  | Partial<GridClasses>
  | undefined => {
  return {
    columnHeaderTitle: 'multi-element-data-grid-column-header-title',
    columnHeaders: 'multi-element-data-grid-column-headers',
    footerContainer: 'multi-element-data-grid-footer-container',
    root: 'multi-element-data-grid',
    virtualScrollerContent: 'multi-element-data-grid-virtual-scroller-content',
  };
};

/**
 * Returns multi element data grid custom sx.
 * @returns Multi element data grid custom sx
 */
export const multiElementDataGridSxGet = (
  cellSelect?: boolean,
  showSeparator?: boolean
): SxProps<Theme> | undefined => {
  return {
    '& .total': {
      borderColor: 'text.secondary',
      borderTop: '2px solid #ccc',
    },
    '& .total .MuiDataGrid-cellCheckbox': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': { fontWeight: '600' },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders, .MuiDataGrid-virtualScrollerContent':
      {
        borderColor: 'border.light',
        paddingTop: 0,
      },
    '& .MuiDataGrid-cell': {
      cursor: cellSelect ? 'pointer' : 'auto',
      borderColor: 'border.light',
    },
    '& .MuiDataGrid-iconSeparator, .MuiDataGrid-menuIcon': {
      display: showSeparator ? undefined : 'none',
    },
    '& .MuiDataGrid-pinnedColumns--right, .MuiDataGrid-pinnedColumnHeaders--right':
      {
        backgroundColor: 'bg.data',
        boxShadow: 'none',
      },
    '& .super-app-theme--cell': {
      backgroundColor: '#f1f1f1',
    },
  };
};

/**
 * Returns multi element data grid custom sx.
 * @returns Multi element data grid custom sx
 */
export const newMultiElementDataGridSxGet = (): SxProps<Theme> | undefined => {
  return {
    '& .MuiDataGrid-columnHeaderTitle': { fontWeight: '600' },
    '& .MuiDataGrid-cell': {
      padding: 0,
    },
    '& .MuiDataGrid-columnHeader': {
      padding: 0,
    },
  };
};

/**
 * GET MultiElement row values by row id and values object.
 * @param rowId
 * @param toolValuesElement
 * @returns MultiElement row values
 */
export const multiElementRowValuesGet = (
  rowId: string,
  toolValuesElement?: ToolValuesElement
): ToolValuesMultiElementRow | undefined => {
  if (toolValuesElement) {
    const matchedRow = toolValuesElement.rows?.find((row) => row.id === rowId);
    if (matchedRow) {
      return matchedRow;
    }
  }
  return undefined;
};

/**
 * GET MultiElement values by section id, element id and tool values object.
 * @param sectionId
 * @param elementId
 * @param toolValuesSection
 * @returns MultiElement values
 */
export const multiElementValuesGet = (
  sectionId: string,
  elementId: string,
  toolValuesSection: ToolValuesSection[]
): ToolValuesElement | undefined => {
  // Find section by section id
  if (toolValuesSection && toolValuesSection.length > 0) {
    const matchedSection = toolValuesSection.find(
      (section) => section.id === sectionId
    );
    // Find element by element id
    if (matchedSection) {
      const matchedElement = matchedSection.elements.find(
        (element) => element.id === elementId
      );
      // Return tool multi element values on match
      if (matchedElement) {
        return matchedElement;
      }
    }
  }

  return undefined;
};

/**
 * Get select option color by options.
 * @param options Options
 * @param value Value
 * @returns Option color
 */
export const selectOptionColorGet = (
  options: Option[],
  value: string[],
  theme: ETheme
): string | undefined => {
  // Set color only when there is one value
  if (value.length === 1) {
    return options.find((option) => option.value === value[0])?.color;
  } else {
    return theme === ETheme.Dark ? '#2b2b2b' : '#f3f6f9';
  }
};
