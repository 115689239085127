import { CommunityTranslations } from '../models/community.translations.interface';

export const communityTranslationsDE: CommunityTranslations = {
  no_community: {
    detail:
      'Hier kannst Du deine Gemeinschaften verwalten. Gemeinschaften erleichtern dir die Zusammenarbeit. Probiere es jetzt aus und erstelle eine Gemeinschaft. ',
  },
  create: {
    info: {
      end: {
        text: 'An diesem Tag zahlst du für deine Lizenzen, welche du zum Stichtag',
        title: 'Ende des Folgemonats',
      },
      notification: {
        text: 'Erhalte eine Erinnerung, wann dein Testzeitraum endet.',
        title: '5 Tage vor Ablauf',
      },
      start: {
        text: 'Starte deinen kostenlosen Testzeitraum im vollen Funktionsumfang.',
        title: 'Heute',
      },
      text1:
        'Kostenloser Testzeitraum bis {{date}}. Danach {{cost}} € pro Mitarbeiter:In pro Monat.',
      text2: 'Dann',
      text3: 'pro Monat/Lizenz.',
      text4:
        'Es erfolgt keine automatische Aktivierung der Lizenz. Du kannst die Gemeinschaft unverbindlich anlegen. ',
      text5: 'Zur Preisliste',
      title: 'Wie läuft der Testzeitraum ab?',
    },
    form: {
      logo: 'Logo',
      name: {
        error: 'Es muss ein Gemeinschaftsname eingegeben werden',
        label: 'Wie soll deine Gemeinschaft heißen?',
        placeholder: 'Name der Gemeinschaft',
      },
      sector: 'Welche Branche ist passend?',
      option: {
        name: 'Erweiterte Einstellung',
        info: 'Wir unterscheiden bei Pengueen standardmäßig in die Nutzerrollen "Mitarbeiter:In" und "Mitglied", um bestimmte Berechtigungen zu unterscheiden. Du hast hier die Möglichkeit diese anzupassen. Erfahre hier mehr dazu.',
        employee: 'Mitarbeiter (erweiterte Rechte)',
        member: 'Mitglieder (einfache Rechte)',
        employeeplaceholder: 'Deine Bezeichnung z.B. Vorstand',
        memberplaceholder: 'Deine Bezeichnung z.B. Ratsmitglieder',
        link: 'Verbindungslink',
        link_info:
          'Personen die sich über diesen Link registrieren, werden der Gemeinschaft als {{membersname}} hinzugefügt!',
        headline_email: 'Texte Einladungs-E-Mail',
        headline_accept_email: 'Texte Bestätigungs-E-Mail',
        betreff_einladung_email: 'Betreff der E-Mail',
        headline_einladung_email: 'Überschrift',
        text_einladung_email: 'Text der E-Mail',
        text_einladung_bestaetigung_email: 'Text der E-Mail',
        betreff_einladung_bestaetigung_email: 'Betreff der E-Mail',
      },
    },
    subtitle:
      'Starte deinen kostenlosen Testzeitraum und nutze den vollen Funktionsumfang.',
    title: 'Erstelle deine Gemeinschaft',
    title_button: 'Gemeinschaft erstellen',
  },
  filters: {
    apply: 'Anwenden',
    button: 'Ansicht',
    display: {
      gallery: 'Kachelansicht',
      list: 'Listenansicht',
    },
    labels: {
      contains: 'Name enthält',
      order: 'Sortierreihenfolge',
    },
    sort: {
      latest: 'Zuletzt hinzugefügt',
      modified: 'Zuletzt geändert',
      title: 'Sortieren nach',
      used: 'Am häufigsten verwendet',
    },
    title: 'Ansichtsoptionen',
  },
  table: {
    actions: {
      remove: 'Entfernen',
      title: 'Aktionen',
      view: 'Ansehen',
    },
  },
  tools: {
    filters: {
      field_contains: 'Hilfsmittelfeld enthält',
      group: {
        none: 'Keine',
        tag: 'Stichwort',
        title: 'Gruppieren nach',
      },
      search: 'Nach Bezeichnung suchen',
    },
    table: {
      category: 'Hilfsmittelkategorie',
      connected_group: 'Verbundene Gruppe',
      field: 'Hilfsmittelfeld',
      folder_tag: 'Ordnerstichwort',
      visibility: 'Sichtbarkeit',
    },
    title: {
      employees: 'Deine Hilfsmittel für deine Mitarbeiter:innen',
      members: 'Deine Hilfsmittel für deine Mitgleider',
      with_tags: 'Hilfsmittel mit Stichwort',
      without_tags: 'Hilfsmittel ohne Stichwort',
    },
    visibility: {
      group: 'Gruppe',
      private: 'Privat',
      unrestricted: 'Uneingeschränkt',
    },
  },
  sectors: {
    care_facility: 'Soziale Einrichtung',
    club: 'Vereine',
    coaching: 'Beratung, Coaching',
    community: 'Gemeinde, Städte',
    company: 'Unternehmen, KMU',
    craft_business: 'Handwerk',
    network: 'Netzwerk, Verband',
    other: 'Sonstige',
    project: '(Förder-) Projekt, Arbeitskreis o.ä.',
    school: 'Bildungswesen, Universität, Forschung',
  },
  users: {
    add: {
      submit: 'Hinzufügen',
      subtitle:
        'Füge Personen deiner Gemeinschaft hinzu und weise ihnen die entsprechende Rolle zu.',
      title: 'Zu Deiner Gemeinschaft hinzufügen',
      title_button: 'Zu Gemeinschaft hinzufügen',
    },
    contact: {
      add: {
        subtitle:
          'Bestimme eine/n primäre/n Ansprechpartner:in für dein Mitglied',
        title: 'Primäre/n Ansprechpartner:in bestimmen',
      },
    },
    deputy: {
      title: 'Stellvertreter:in',
    },
    employees: {
      add: 'Mitarbeiter:in hinzufügen',
      demote: {
        btn: 'Herabstufen',
        confirm: 'MITGLIED',
        subtitle: 'Dein:e Mitarbeiter:in wird zum Mitglied.',
        text: 'Jetzt Mitarbeiter:in zum Mitglied machen?',
        title: 'Bestätigung',
      },
      detail: {
        title: 'Mitarbeiter:in',
        tools: 'Hilfsmittel für Mitarbeiter:innen',
      },
      remove: {
        subtitle:
          'Die Verbindung deines Mitarbeiters zu deiner Gemeinschaft wird getrennt. Dein/e Mitarbeiter:in hat keinen Zugriff mehr.',
        text: 'Jetzt Verbindung zum/zur Mitarbeiter:in löschen?',
        title: 'Verbindung zum Mitarbeiter löschen',
      },
      title: 'Deine Mitarbeiter:innen',
    },
    filters: {
      admin: 'Pengueen Admin',
      search: 'Nach Namen suchen',
      visibility: 'Spaltensichtbarkeit',
    },
    members: {
      add: 'Mitglied hinzufügen',
      promote: {
        btn: 'Hochstufen',
        text1: 'Möchtest du das Mitglied',
        text2: 'zum Mitarbeiter:in deiner Gemeinschaft machen?',
        text3:
          'Das Mitglied erhält hierdurch erweiterte Rechte, kann sich zum Beispiel mit anderen Mitgliedern austauschen und Mitglieder einladen.',
        title: 'Mitglied zum/zur Mitarbeiter:in hochstufen?',
      },
      remove: {
        subtitle:
          'Die Verbindung deines Mitgliedes zu deiner Gemeinschaft wird getrennt. Dein Mitglied hat keinen Zugriff mehr.',
        text: 'Jetzt Verbindung zum Mitglied löschen?',
        title: 'Verbindung zum Mitglied löschen',
      },
      title: 'Deine Mitglieder',
    },
    nameless: 'Kein Name angegeben',
    roles: {
      admin: 'Admin',
      employee: 'Mitarbeiter',
      member: 'Mitglied',
    },
    table: {
      actions: {
        demote: 'Zum Mitglied machen',
        message: 'Anschreiben',
        remove: 'Verbindung löschen',
      },
      address: 'Adresse',
      admin: 'Pengueen Admin',
      email: 'E-Mail-Adresse',
      invited: 'Eingeladen am',
      name: 'Name',
      phone: 'Telefonnummer',
      tools: 'Hilfsmittel',
    },
  },
};
