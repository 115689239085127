import { ToolsTranslations } from '../models/tools.translations.interface';

export const toolsTranslationsDE: ToolsTranslations = {
  tool_user: {
    subtitle:
      'Inhaber und Administratoren von {{community_name}} können die Inhalte uneingeschränkt bearbeiten.',
    rights_read: 'Leserechte ',
    rights_read_text:
      'Diese Nutzer:Innen sehen das Hilfsmittel in der Leseansicht',
    rights_edit: 'Bearbeitungsrechte',
    rights_edit_text: 'Diese Nutzer:Innen können das Hilfsmittel bearbeiten',
    rights_slots: 'Rechte-Slots',
    rights_slots_text:
      'Diese Nutzer:Innen haben die Sicht- und Bearbeitungsrechte des Rechte-Slots',
  },
  chat: {
    select: {
      title: 'Dein Hilfsmittel Chat',
      subtitle:
        'Nutze diesen Chat, um Benachrichtigungen zum Hilfsmittel zu versenden. Wenn dieser aktiviert ist, können sich alle zugeordneten Nutzer hierüber austauschen und müssen nicht ins Nachrichtencenter wechseln.',
      info: 'Dieser Chat wird dir auch im Nachrichtencenter angezeigt.',
    },
    group: {
      title:
        'Nutze diesen Chat, um Benachrichtigungen zum Hilfsmittel zu versenden. Wenn dieser aktiviert ist können alle zugeordneten Nutzer gegenseitig schreiben',
      subtitle:
        'Wähle die Nutzer aus, mit denen du dich zu diesem Hilfsmittel austauschen möchtest:',
      info: 'Bestehende Gruppen deiner Gemeinschaft',
      notice:
        'Beachte: Du hast dein Hilfsmittel aktuell noch keiner Gemeinschaft zugeordnet.',
    },
    room: {
      title:
        'Hier werden Benachrichtigungen zum Hilfsmittel veröffentlicht und du kannst dich mit den Personen der ausgewählten Gruppe unterhalten.',
    },
  },
  footer: {
    dashboard: 'Zum Dashboard',
    workbench: 'Zur Werkbank',
    auto_save: 'Auto speichern',
    link_preview: 'Link auf Detailansicht',
    sign_up: 'Jetzt bei Pengueen anmelden und nutzen',
  },
  vote: {
    thanks: 'Danke',
    change: 'Ändern',
    error: {
      star: '',
      point: 'Bitte geben Sie einen geeigneten Wert ein.',
    },
    sum: 'Summe',
    sum_total: 'Summe (gesamt) ',
  },
  display: {
    read: 'Leseansicht',
    table: 'Tabelle',
    kanban: 'Kanban',
    tile: 'Kachel',
  },
  drawer: {
    footer: {
      title: 'Finde Vorlagen aus der Community',
      content:
        'Wusstest du schon, dass wir in unserem Marktplatz eine Vielzahl an Vorlagen bieten. Mache dir den Start einfach und schaue in unserem Marktplatz nach Vorlagen 🚀',
      market: 'Zum Marktplatz',
    },
  },
  required_field: {
    text: 'Bitte fülle "{{tool_name}}" vor dem Speichern aus.',
  },
  filters: {
    filterOperatorContains: 'erhält',
    filterOperatorEquals: 'entspricht',
    filterOperatorStartsWith: 'startet mit',
    filterOperatorEndsWith: 'endet mit',
    filterOperatorIsEmpty: 'ist leer',
    filterOperatorIsNotEmpty: 'ist nicht leer',
    filterOperatorIsAnyOf: 'ist eines von',
    filterOperatorIs: 'ist',
    filterOperatorNot: 'ist nicht',
    filterOperatorAfter: 'ist nach',
    filterOperatorOnOrAfter: 'ist am oder nach',
    filterOperatorBefore: 'ist vor',
    filterOperatorOnOrBefore: 'ist am oder vor',
    filterValueAny: 'alle',
    filterValueFalse: 'Nein',
    filterValueTrue: 'Ja',
    filterPanelOperatorOr: 'ODER',
    filterPanelOperatorAnd: 'UND',
    toolbarColumns: 'Spalten',
    toolbarDensity: 'Dichte',
    filterPanelAddFilter: 'Filter hinzufügen',
    filterPanelColumns: 'Spalte',
    filterPanelInputLabel: 'Wert',
    filterPanelInputPlaceholder: 'Schreibe etwas',
    toolbarFiltersTooltipShow: 'Filter anzeigen',
    toolbarFiltersTooltipHide: 'Filter ausblenden',
    toolbarDensityCompact: 'kompakt',
    toolbarDensityComfortable: 'Komfortabel',
    columnsPanelShowAllButton: 'Zeige alles',
    columnsPanelHideAllButton: 'Versteck alles',
    columnsPanelTextFieldPlaceholder: 'Spaltentitel',
    columnsPanelTextFieldLabel: 'Spalte suchen',
    status: {
      draft: 'Entwurf',
      publish: 'Veröffentlicht',
      community: 'Für Community',
      title: 'Status',
    },
    workbench: {
      public: 'Meine Gemeinschaften',
      community: 'Pengueen Community',
      personally: 'Persönliche',
      title: 'Werkbank',
      admin: 'Ich als Admin',
    },
    use_cases: 'Anwendungsfälle',
  },
  settings: {
    title: 'Einstellungen',
    submit: 'Speichern',
    description: 'Bezeichnung',
    description_placeholder: 'z.B. Projekt Sommerfest',
    delete_image: 'Bild wurde gelöscht.',
  },
  detail: {
    link: {
      delete: {
        subtitle: 'Dieser private Link wird gelöscht.',
        text: 'Jetzt dieser Private Link löschen?',
        title: 'Private Link löschen',
        title_menu: 'Löschen',
      },
    },
    actions: {
      add: 'Teilnehmer:innen hinzufügen',
      delete: {
        subtitle:
          'Das Hilfsmittel wird gelöscht und kann nicht wieder hergestellt werden.',
        text: 'Jetzt Hilfsmittel löschen?',
        title: 'Hilfsmittel löschen',
        title_menu: 'Löschen',
      },
      edit: 'In Werkbank bearbeiten',
      settings: 'Einstellungen',
      share: 'Teilen',
    },
    elements: {
      choose: 'Wähle aus ...',
      contact: 'Kontakt auswählen',
      upload: {
        add: 'Dokument hinzufügen',
      },
    },
    form: {
      input: {
        placeholder: 'Schreibe etwas ...',
      },
    },
    multi_element: {
      add: {
        button: 'Eintrag hinzufügen',
        title: 'Neuer Eintrag',
      },
      created_at: 'Erstellt',
      created_by: 'Erstellt von',
      changed_by: 'Geändert',
      delete: {
        subtitle:
          'Der Eintrag wird gelöscht und kann nicht wieder hergestellt werden.',
        text: 'Jetzt Eintrag löschen?',
        title: 'Eintrag löschen',
      },
      download:
        'Klicke hier, um alle heruntergeladenen Elemente des Mehrfacheintrags als .csv herunterzuladen.',
      edit: 'Eintrag bearbeiten',
      onwer: {
        all: 'Alle',
        me: 'Ich',
      },
      search: 'Eintrag suchen',
      filters: {
        title: 'Neuer Filter',
        name_save: 'Wie soll dein Filter heißen?',
        name_submit: 'Filter anlegen',
      },
    },
    navigation: {
      title: 'Inhalt',
    },
    read_edit: {
      edit: 'Bearbeiten',
      link: {
        subtitle: 'Die zugewiesenen Nutzer:innen werden entfernt.',
        title: 'Privaten Link erstellen',
      },
      read: 'Lesen',
      select: 'Nutzer:innen oder Gruppen auswählen',
    },
    slots: {
      title: 'Weitere Slots',
    },
    users: {
      permissions: {
        admin: 'Voller Zugriff',
        read: 'Kann einsehen',
        write: 'Kann bearbeiten',
      },
      remove: {
        subtitle:
          'wird aus dem Hilfsmittel entfernt und verliert seine / ihre Zugriffsrechte',
      },
      title: 'Nutzer:innen mit Zugriff',
    },
  },
  preview: {
    info: {
      created_by: 'Erstellt durch',
      share: {
        button: 'Link zum Teilen kopieren',
        notification: 'Link in die Zwischenablage kopiert.',
        text: 'Du kennst jemanden, den dieses Hilfsmittel interessieren könnte? Dann schicke ihm doch einen Link zur Vorschau und hole ihn mit in die Pengueen Community 🐧',
        title: 'Du kennst jemanden, der genau danach sucht?',
      },
      title: 'Marktplatz von Pengueen',
      text1:
        'Du befindest dich im Marktplatz von Pengueen, deinem Baukasten für Fachsoftware.',
      text2:
        'Die Digitalisierung hat uns fest im Griff. Nur, wo anfangen? Und wie? Monate lang eine Branchensoftware einführen oder doch gleich ein Jahr lang eine maßgeschneiderte Lösung entwickeln lassen? Wie nehme ich meine Mitarbeiter:Innen mit? Was sollte / möchte ich eigentlich digitalisieren?',
      text3:
        'Software etabliert keine Prozesse. Übernimmt keine Verantwortung und schafft keine Mehrwerte. Das tun Menschen. ABER eine gute Software kann – wie ein digitales Werkzeug – Fachkräfte entlasten.',
      text4:
        'Hier bieten unsere Nutzer ihre selbstgebauten digitalen Werkzeuge (Hilfsmittel) an, um dich bei deiner Arbeit zu unterstützen. ',
      text5: 'Schau dich doch mal um.',
      text6: 'Du kriegst das hin. Mit ',
      text7: 'Pengueen!',
    },
    popup: {
      buttons: {
        info: 'Mehr Informationen',
        try: {
          notification: 'Hilfsmittel hinzugefügt.',
          title: 'Jetzt ausprobieren und bei Pengueen anmelden',
          title_mobile: 'Jetzt ausprobieren',
        },
      },
      text1: 'Dies ist eine Vorschau auf das Hilfsmittel',
      text2:
        'Hier bieten unsere Nutzer ihre selbstgebauten Hilfsmittel an, um dich bei deiner Arbeit zu unterstützen. Schau dich doch mal um.',
      text3:
        'Du befindest dich im Marktplatz von Pengueen, deinem Baukasten für Fachsoftware. ',
      title: 'Hinweis',
    },
  },
  title: 'Hilfsmittelvorlage',
  error:
    'Sie haben keinen Zugriff auf dieses Hilfsmittel! Sie werden in 3 Sekunden auf das Dashboard geleitet.',
  error_title: 'Kein Zugriff',
};
