import { DashboardTranslations } from '../models/dashboard.translations.interface';

export const dashboardTranslationsEN: DashboardTranslations = {
  recommendation: {
    title: 'Hilfsmittel aus dem Marktplatz',
    subtitle:
      'Im Marktpatz von Pengueen findest du Hilfsmittel zu den unterschiedlichsten Themen, für verschiedene Branchen. Hilfsmittel die wir besonders gut und hilfreich finden, werden wir dir hier vorstellen. ',
  },
  communities: {
    title: 'Aktuelles von',
  },
  header: {
    explore: 'Gehe auf Entdeckungstour',
    subtitle: 'Lass uns gemeinsam schauen, was aktuell ansteht.',
  },
  widgets: {
    show_more: 'Show All Tools',
    hide_items: 'Show Fewer Tools',
    delete: {
      check: 'Bist du sicher?',
    },
    add: 'Neues Widget hinzufügen (coming soon)',
    community: {
      title: 'Willkommens-Text',
      tooltip:
        'Willkommenstext auf dem Dashboard. Heiße die neuen Mitglieder und Mitarbeiter:Innen deiner Gemeinschaft willkommen.',
    },
    changes: {
      answer: 'Schreibe eine Kurznachricht ...',
      room: {
        invite: '{{user1}} invited you to the {{groupName}} group, ',
        invite_others:
          '{{user1}} invited {{user2}} to the group {{groupName}}., ',
        close:
          '{{user1}} closed the group {{groupName}}. You cannot write new messages to the group., ',
        remove: '{{user1}} removed {{user2}} from the group {{groupName}}., ',
        remove_me: '{{user1}} removed you from the group {{groupName}}, ',
        sticky: 'New message(s) from {{user1}}, ',
        new_message: '{{user1}} wrote a new message in tool {{toolName}}.',
        leave: '{{user1}} left your direct message., ',
        mention: '{{user1}} addressed you personally, ',
        alert: {
          close: {
            title: 'Leave groups',
            subtitle:
              'Are you sure? Your messages will be irrevocably deleted!',
          },
          leave: {
            title: 'Leave Direct Message Room',
            subtitle:
              'Are you sure? All your messages will be irretrievably deleted',
          },
          invite_me: {
            subtitle: 'Are you sure?',
          },
        },
      },
      tool: {
        add_tool_chat_group:
          '{{user1}} has started an exchange for the tool {{toolName}}',
        add_tool_chat_multi_row:
          '{{user1}} started an exchange for the entry {{rowName}}',
        remove_tool_chat_group:
          '{{user1}} has ended the exchange for the tool {{toolName}}',
        create: '{{user1}} created the {{toolName}} tool for {{communityName}}',
        create_no_community: '{{user1}} created the {{toolName}} tool',
        change:
          '{{user1}} made some changes to the {{toolName}} tool for {{communityName}}, ',
        change_no_community:
          '{{user1}} made some changes to the {{toolName}} tool, ',
        change_duplicate:
          '{{user1}} made {{count}} changes to {{toolName}} for {{communityName}}, ',
        change_duplicate_no_community:
          '{{user1}} made {{count}} changes to {{toolName}}, ',
        delete:
          '{{user1}} deleted the {{toolName}} tool from {{communityName}}, ',
        delete_no_community: '{{user1}} deleted the {{toolName}} tool, ',
        right_assigned:
          'You have been given the {{right}} right {{role}} by {{user1}} in the {{toolName}} tool, ',
        right_removed:
          'Your {{right}} right {{role}} has been taken from you by {{user1}} in the tool {{toolName}}, ',
        removed:
          'You have been removed from an entry in the {{toolName}} tool by {{user1}}, ',
        selected:
          'You were named by {{user1}} in an entry in the {{toolName}} tool, ',
        multi_seleted:
          'You have been selected in a multielement {{toolName}}, ',
        multi_assigned:
          'You have been assigned to a multielement {{toolName}}, ',
        multi_removed:
          'You have been removed from a multielement {{toolName}}, ',
        multi_row_add:
          '{{user1}} added a new entry to the tool {{toolName}} in {{tableName}} for {{communityName}}, ',
        multi_rows_add:
          '{{user1}} added new entries to the tool {{toolName}} in {{tableName}} for {{communityName}}, ',
        multi_row_add_no_community:
          '{{user1}} added a new entry to the tool {{toolName}} in {{tableName}}, ',
        multi_row_update:
          '{{user1}} changed an entry on the tool {{toolName}} to {{multiElementName}}. {{columnName}} has been changed from {{oldValue}} to {{newValue}}, ',
        multi_row_update_no_old_value:
          '{{user1}} changed an entry on the tool {{toolName}} to {{multiElementName}}. {{columnName}} has been changed to {{newValue}}, ',
      },
      template: {
        publish:
          '{{userName}} has published the resource template {{toolName}}',
        delete: '{{userName}} has deleted the tool template {{toolName}}',
        change: '{{userName}} has modified the tool template {{toolName}}',
      },
      contact: {
        rejected: '{{user1}} has not accepted your invitation, ',
        accepted:
          '{{user1}} accepted your invitation and is now with Pengueen, ',
        accepted_link:
          '{{user1}} has accepted your invitation via the link to the {{group}}.',
        accepted_link_group:
          '{{user1}} has accepted your invitation via the link to the group {{group}} of the community {{community}}',
        accepted_link_community:
          '{{user1}} has accepted your invitation to the community {{community}} via the link',
        accepted_link_admin:
          '{{user1}} has accepted your invitation to the community {{community}} as an admin via the link',
        accepted_link_employee:
          '{{user1}} has accepted your invitation to the community {{community}} as an employee via the link',
        accepted_link_member:
          '{{user1}} has accepted your invitation to join the community {{community}} as a member via the link',
        community_accepted: 'Invitation from {{user1}} accepted by {{user2}}, ',
        community_invited:
          '{{user1}} has invited you to the community {{community}}, ',
        community_rejected: 'Invitation from {{user1}} expired by {{user2}}, ',
        delete_contact: 'Do you want to delete {{nickname}} from your network?',
      },
      create_change: {
        at: 'um',
        room: {
          direct: 'New message(s) from',
          group: 'New message(s) in',
          text: 'last message on',
        },
        tool: {
          new: {
            text1: 'New tool',
            text2: 'created on',
          },
          updated: {
            text1: 'Change(s) in the Resource',
            text2: 'last modified',
          },
        },
      },
      empty: {
        buttons: {
          market: 'Zum Hilfsmittel-Marktplatz',
          workbench: 'Zur Werkbank',
        },
        text1: 'Scheint als hättest du heute frei 🥳',
        text2:
          'Wir haben einen super Tipp, wie du dich beschäftigen kannst. Was hältst du von Folgendem: Baue doch einfach mal ein Hilfsmittel für ein leidenschaftliches Thema von dir oder eines, welches dir deine Arbeit erleichtert.',
        text3:
          'Dein Vorteil? Du hast ein passgenaues Hilfsmittel für deine Bedürfnisse. Wenn du es in unserem Marktplatz teilst, kannst du anderen, die auch ein solches Hilfsmittel nutzen wollen, helfen.',
        text4: 'Oder gehe einfach auf Entdeckungstour durch Pengueen 🔭',
      },
      filters: {
        title: 'Filters',
      },
      title: 'Was ist seit deinem letzten Log-in passiert?',
    },
    community_todos: {
      title: 'Deine nächsten Schritte in deiner Gemeinschaft',
      users: {
        add: {
          button: "Los geht's!",
          text: 'Füge Personen zu Deiner Gemeinschaft hinzu!',
        },
      },
    },
    favorite_tools: {
      add: 'Hilfsmittel hinzufügen',
      empty: {
        button: 'Zeige mir, wie ich Hilfsmittel als Favoriten markiere',
        text: 'Hier kannst du besonders wichtige oder oft genutzte Hilfsmittel anheften.',
      },
      title: 'Deine Hilfsmittel',
    },
    favorite_users: {
      empty: {
        button: 'Zeige mir, wie ich Personen als Favoriten markiere',
        text: 'Ziehe dir jetzt die wichtigsten Personen und Gruppen direkt auf dein Dashboard. Markiere sie als Favoriten. 🤩',
      },
      title: 'Deine Favoriten – Personen und Gruppen',
    },
    show_all: 'Show all',
  },
  title: 'Dashboard',
};
