import { TFunctionResult } from 'i18next';
import { memo, useEffect, useState } from 'react';
import clsx from 'clsx';

// Components
import { FormFieldLabel } from '../FormFieldLabel/FormFieldLabel';
import RadioButton from '../RadioButton/RadioButton';

// Models
import { Option } from '../../models/shared.types';

// Styles
import styles from './RadioButtonGroup.module.scss';

type RadioButtonGroupProps = {
  classes?: string;
  disabled?: boolean;
  label?: TFunctionResult | string;
  options: Option[];
  sublabel?: string;
  help_text?: string;
  value?: any;
  required?: boolean;
  onChange: (value: any) => void;
};

export const RadioButtonGroup = (props: RadioButtonGroupProps) => {
  const [value, setValue] = useState<any>('');

  // Set value
  useEffect(() => {
    setValue(props.value);
    // eslint-disable-next-line
  }, [props.value]);

  return (
    <div
      className={clsx(
        styles['radio-button-group'],
        props.classes && props.classes
      )}
    >
      {props.label && (
        <FormFieldLabel
          required={props.required}
          label={props.label}
          paddingClassName={
            styles['radio-button-group-form-field-label-padding']
          }
          sublabel={props.sublabel}
          help_text={props.help_text}
        />
      )}
      {props.options.map((option, i) => {
        return (
          <RadioButton
            key={i}
            checked={
              Array.isArray(value)
                ? value.includes(option.value)
                : value === option.value
            }
            color={option.color ?? ''}
            classes={styles['radio-button-group-option']}
            disabled={props.disabled}
            title={option.placeholder}
            onClick={() => {
              props.onChange(option.value);
              setValue(option.value);
            }}
          />
        );
      })}
    </div>
  );
};

export default memo(RadioButtonGroup);
