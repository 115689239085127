import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumnVisibilityModel,
  GridDensity,
  GridFilterModel,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';

// Models
import {
  Attachment,
  AttachmentFile,
  CreateChangeInfo,
  ImageFallbackType,
  Notifications,
  Option,
} from '../../../shared/models/shared.types';
import {
  Template,
  TemplateElement,
  TemplateElementDatePickerType,
  TemplateElementType,
} from '../../templates/models/templates.types';
import { ContactNavGroup } from '../../contacts/models/contacts.types';
import {
  GroupFavorite,
  UserFavorite,
} from '../../dashboard/models/dashboard.types';

export const allowedExtensions = [
  '.png',
  '.jpg',
  '.jpeg',
  '.xlsx',
  '.xls',
  '.docx',
  '.doc',
  '.mp4',
  '.mp3',
  '.m4a',
  '.psd',
  '.ai',
  '.ppt',
  '.wav',
  '.mov',
  '.rar',
  '.eps',
  '.ps',
  '.pptx',
  '.mpe',
  '.pdf',
  '.ics',
  '.rtf',
  '.zip',
];

export enum MultiElementDisplayType {
  Kanban = 'KANBAN',
  Read = 'READ',
  Table = 'TABLE',
  Tile = 'TILE',
}

export enum MultiElementOwnerType {
  All = 'ALL',
  Me = 'ME',
}

export enum ToolDetailAction {
  Add = 'ADD',
  Delete = 'DELETE',
  Edit = 'EDIT',
  Settings = 'SETTINGS',
  Share = 'SHARE',
  Users = 'USERS',
}

export enum ToolPermission {
  Admin = 'ADMIN',
  Read = 'READ',
  Write = 'WRITE',
}

export interface ToolFilterModelRequest {
  tool_id: string;
  element_id: string;
  table_settings: ToolTableSetting[];
}

export interface ToolTableSetting {
  name: string;
  filterModel: GridFilterModel;
  density: GridDensity;
  columnVisibilityModel: GridColumnVisibilityModel | undefined;
}

export interface GetToolChatIdRequest {
  tool_id: string;
  mfe_id?: string;
  set_chat?: boolean;
}

export interface GetToolChatIdResponse {
  room_id?: string;
  groups: Option[];
}

export interface PostToolChatRequest {
  tool_id: string;
  group_id: string;
}

export interface PostToolChatResponse {
  tool_id: string;
  group_id: string;
  room_id: string;
}

export interface ColumnContactType {
  id: string;
  name: string;
}

export interface AutoSaveToolRequest {
  id: string;
  autoSave: boolean;
}

export interface UpdateContactRequest {
  toolid: string;
  sectionid: string;
  rowid: string;
  elementid: string;
  value: string;
}

export interface MultiElementColumnDatePickerParams
  extends GridRenderCellParams {
  type: TemplateElementDatePickerType;
}

export interface MultiElementColumnContactParams extends GridRenderCellParams {
  elementId?: string;
  toolId?: string;
  sectionId: string;
  type?: TemplateElementType | null;
}

export interface MultiElementColumnHeaderParams extends GridColumnHeaderParams {
  checked?: boolean;
  title: string;
  onToggle: (checked: boolean) => void;
}

export interface MultiElementColumnSelectParams extends GridRenderCellParams {
  options?: Option[];
}

export interface MultiElementColumnStarParams extends GridRenderCellParams {
  vote_element?: TemplateElement;
  rowId?: string;
  element?: TemplateElement;
  toolId?: string;
  sectionId?: string;
}

export interface MultiElementColumnToggleParams extends GridRenderCellParams {
  toggle_true_string?: string;
  toggle_false_string?: string;
}

export interface MultiElementColumnGradeParams extends GridRenderCellParams {
  vote_element?: TemplateElement;
  rowId?: string;
  element?: TemplateElement;
  toolId?: string;
  sectionId?: string;
}

export interface MultiElementColumnNumberParams extends GridRenderCellParams {
  element?: TemplateElement;
}

export interface ActionCellProps {
  paramsWithIds: MultiElementRowActions;
}

export interface ChangedByCellProps {
  params: GridRenderCellParams; // Typisiere 'params' korrekt
  hasSuffix?: boolean;
  suffix?: string;
  thousandPoint?: boolean;
}

export interface MultiElementRowActions {
  element: TemplateElement;
  sectionId: string;
  rowId: string;
  toolId: string;
  values?: ToolValuesMultiElementRow;
  can_delete?: boolean;
  roomId?: string;
  onDeleteRow?: any;
}

export interface Tool {
  id?: string;
  autoSave?: boolean;
  chat?: boolean;
  name: string;
  image_header?: Attachment;
  template: Template;
  preview_id?: string;
  users: ToolUser[];
  values: ToolValuesSection[];
  public?: boolean;
  connected_user?: string;
  permission_slot_info?: ToolActivePermissionSlotInformation[];
  tool_chat_group_id?: string;
  tool_comment_group_id?: string;
  tool_permissions?: tool_permission_dto[];
  kanbans?: KanbanInfo[];
}

export interface KanbanInfo {
  mfeid: string;
  kanban: KanbanDto;
}

export interface KanbanDto {
  lanes: KanbanColumnDto[];
}

export interface KanbanColumnDto {
  id: string;
  title: string;
  cards: KanbanCard[];
}

export interface KanbanCard {
  id: string;
  title: string;
  description?: string;
  autoid?: string;
  user: KanbanUser;
}

export interface KanbanUser {
  name: string;
  avatar: string;
}

export interface tool_permission_dto {
  id: string;
  name: string;
  user: boolean;
  permission: ToolPermission;
}

//
export interface ToolActivePermissionSlotInformation {
  id: string;
  delete_id?: string;
  group: GroupFavorite[];
  users: ToolUser[];
}

export interface ToolElementDocumentPostRequestAll {
  body: ToolSectionElementRowIdsAll;
  file: File;
}

export interface ToolAccordionExpansionGetRequest {
  tool_id: string;
  is_mobile: boolean;
}

export interface ToolAccordionExpansionStatePost {
  tool_id: string;
  expandedSection: string[];
  is_mobile: boolean;
}

export interface ToolAccordionExpansionState {
  tool_id: string;
  expandedSection: string[];
}

export interface ToolElementScroll {
  section_id: string;
  element_id: string;
}

export interface ToolElementDocumentPostRequest {
  body: ToolSectionElementRowIds;
  file: File;
}

export interface ToolElementMultiDocumentPostRequest {
  body: ToolSectionElementRowIds;
  files: ChildrenFileUploadRequest[];
}

export interface ToolSettingsPostRequest {
  id?: string;
  file?: File;
  name?: string;
  public?: boolean;
  contact?: string | null;
}

export interface ToolMultiElementRowPostRequest {
  id: string;
  body: {
    [key: string]: any;
  };
  element_id: string;
  section_id: string;
}

export interface ToolMultiElementVoteGetRequest
  extends ToolMultiElementRowPostRequest {
  row_id?: string[];
}

export interface SummaryValue {
  vote_element_name: string;
  vote_selected_value?: number;
  vote_summary_value: number | string;
}

export interface ToolMultiElementRowPostPatchResponse
  extends ToolMultiElementRowPostRequest {
  row_id: string;
  tool_id?: string;
  elements?: ToolValuesElement[];
}

export interface CreateRowRequest {
  tool_id: string;
  row_id: string;
  section_id: string;
  element_id: string;
  rows: CreateRow[];
  row?: ToolValuesMultiElementRow;
}

export interface CreateRow {
  field_name: string;
  child_id: string;
  value: any;
}

export interface ToolSectionElementRowIdsAll {
  element: TemplateElement;
  sectionId: string;
  toolId: string;
  rowId?: string;
}

export interface ToolSectionElementRowIds {
  elementId: string;
  sectionId: string;
  toolId: string;
  rowId?: string;
  rowChildId?: string;
}
export enum ToolUserType {
  Admin = 'ADMIN',
  Employee = 'EMPLOYEE',
  Member = 'MEMBER',
  GroupWithMessage = 'GROUP_WITH_MESSAGE',
  GroupWithoutMessage = 'GROUP_WITHOUT_MESSAGE',
  User = 'USER',
}

export interface ToolUser {
  id: string;
  avatar?: Attachment;
  first_name?: string;
  last_name?: string;
  nickname?: string;
  permission: ToolPermission;
  type?: ImageFallbackType;
}

export interface ToolUsersPatchRequest {
  toolId: string;
  permission: ToolPermission;
  selectedGroups: ContactNavGroup[];
  users: string[]; // User ids to add
}

export interface ToolPermissionSlotUsersPatchRequest {
  toolId?: string;
  permission_slot?: string;
  selectedGroups: ContactNavGroup[];
  users: string[]; // User ids to add
}

export interface TemplatePermissionSlotUsersPatchRequest {
  templateId?: string;
  permission_slot?: string;
  selectedGroups: ContactNavGroup[];
  users: string[]; // User ids to add
}

export interface TemplatePermissionSlotUsersDeleteRequest {
  permission_slot_id?: string;
}

export interface ToolUserPermissionPatchRequestResponse {
  permission: ToolPermission;
  toolId: string;
  userId: string;
}

export interface ToolValuesElement {
  id: string; // Element id
  rows?: ToolValuesMultiElementRow[];
  value?: ToolElementValue;
  counter?: string | number;
  names?: string;
  change?: boolean;
  submit?: boolean;
  result?: boolean;
  required?: boolean;
  child_element?: boolean;
  can_edit?: boolean;
}

export interface ToolValuesPatchRequestBody {
  id: string;
  body: ToolValuesPatchRequest;
  file?: File;
}

export interface ToolValuesPatchRequest {
  notifications: Notifications;
  value: ToolValuesSection[];
}

export interface ToolValuesMultiElementRow {
  [key: string]: any;
  id: string;
  can_edit?: boolean;
  can_delete?: boolean;
  create_id?: string;
  create_change_info?: CreateChangeInfo;
  roomId?: string;
  elements: ToolValuesElement[]; // Multi element children
}

export interface ToolValuesSection {
  id: string; // Section id
  elements: ToolValuesElement[];
  create_id?: string;
}

export interface ToolVoteSubmitResponse {
  create_change_id: CreateChangeInfo;
  id: string;
  vote: number;
  change: boolean;
  submit: boolean;
  result: boolean;
}

export interface ToolVotePostRequest {
  id: string; // section id
  element: {
    id: string; // element id
    value: number;
  };
}

export type ToolMultiElementRowPatchRequest =
  ToolMultiElementRowPostPatchResponse;

export type ToolUsersPatchResponse = Pick<Tool, 'users'>;
export interface ToolPermissionSlotUsersPatchResponse {
  selectedGroups: GroupFavorite[];
  users: UserFavorite[];
}

export type ToolElementValue = AttachmentFile[] | any;

export interface ToolPrivateEditReadLink {
  id: string;
  url: string;
  title: string;
  type?: ToolPermission;
  linkid?: string;
}

export interface ToolLinkRemoveRequestObj {
  toolId?: string;
  linkId: string;
}

export interface ToolLinkPatchRequestObj extends ToolLinkRemoveRequestObj {
  description: string;
}

export interface ToolLinkGetRequestObj {
  toolId?: string;
  permission: ToolPermission;
}

export interface ToolElementImageDeleteRequest {
  id: string;
  sectionId?: string;
  element?: TemplateElement;
  rowId?: string;
}

export interface ToolElementDisplayRequest {
  id: string;
  display?: MultiElementDisplayType;
}

export interface ColumnWidth {
  field: string;
  width: string;
}

export interface ChildrenFileUploadRequest {
  childId: string;
  file: File;
}

export interface CustomGridColDef extends GridColDef {
  // type: GridColType | TemplateElementType;
  hasSuffix?: boolean;
  suffix?: string;
  thousandPoint?: boolean;
  tableHeaderName?: string;
  tableValue?: any;
  disableExport?: boolean;
}
