import DOMPurify from 'dompurify';
import emojiRegex from 'emoji-regex';

export const messageHandleContent = (messageContent: string) => {
  // Replace links outside <a> elements with <a> elements
  function replaceLinks(content: string) {
    const urlRegex = /((https?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)/g;
    return content.replace(urlRegex, function (url: string) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  }

  function replaceEmails(content: string) {
    const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
    return content.replace(emailRegex, function (email: string) {
      return `<a href="mailto:${email}">${email}</a>`;
    });
  }

  // function replacePhonesWithPlus(content: string) {
  //   const phoneRegex =
  //     /(\+\d{1,2}\s?)?(\()?\d{2,3}(\))?[-.\s]?\d{3,4}[-.\s]?\d{3,4}/g;
  //   return content.replace(phoneRegex, function (phone: string) {
  //     return `<span>${phone}</span>`;
  //   });
  // }

  let handledContent = messageContent;

  // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
  handledContent = DOMPurify.sanitize(handledContent);

  // Replace links with <a> elements
  handledContent = replaceLinks(handledContent);
  // Replace emails with <a mailto> elements
  handledContent = replaceEmails(handledContent);

  // Set emoji bigger
  handledContent = emojiHandle(handledContent);

  // Replace phones with <span> elements
  // handledContent = replacePhonesWithPlus(handledContent);

  return handledContent;
};

export const emojiHandle = (message: string) => {
  const emojiRegExp = emojiRegex();
  return message.replace(
    emojiRegExp,
    `<span style="font-size: 1.5em;">$&</span>`
  );
};

/**
 * Handle content. Add commas to number.
 */
export const addCommasToNumber = (content: string) => {
  let handledContent = content;

  // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
  handledContent = DOMPurify.sanitize(handledContent);

  const numStr = String(handledContent);
  let formattedNumber;
  if (numStr.includes(',')) {
    const [beforeComma, afterComma] = numStr.split(',');
    // Add thousand point
    const formattedBeforeComma = beforeComma.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    );
    formattedNumber =
      formattedBeforeComma + (afterComma ? ',' + afterComma : '');
  } else if (numStr.includes('.')) {
    const [beforeComma, afterComma] = numStr.split('.');
    // Add thousand point
    const formattedBeforeComma = beforeComma.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      '.'
    );
    formattedNumber =
      formattedBeforeComma + (afterComma ? ',' + afterComma : '');
  } else {
    formattedNumber = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  return formattedNumber;
};

/**
 * Check PhoneNumber.
 */
export const isValidPhoneNumberFormat = (content: string) => {
  let handledContent = content;

  // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
  handledContent = DOMPurify.sanitize(handledContent);

  const phoneRegex = /^[0-9+()\-\s]+$/;
  return phoneRegex.test(content);
};

/**
 * Check Url.
 */
export const isValidUrlFormat = (content: string) => {
  let handledContent = content;

  // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
  handledContent = DOMPurify.sanitize(handledContent);

  const urlRegex = /^((https?):\/\/[^"<\s]+)(?![^<>]*>|[^"]*?<\/a)$/;
  return urlRegex.test(content);
};

/**
 * Check Email.
 */
export const isValidEmailFormat = (content: string) => {
  let handledContent = content;

  // Sanitise raw HTML to prevent cross-site scripting (XSS) attack
  handledContent = DOMPurify.sanitize(handledContent);

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(content);
};

export const isValidTimeFormat = (time: string) => {
  // Checks whether the input conforms to the hh:mm format
  const regex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
  const matches = time.match(regex);

  // If the input matches the pattern and contains valid hours/minutes
  if (matches) {
    const hours = parseInt(matches[1], 10);
    const minutes = parseInt(matches[2], 10);

    // Check that the hours and minutes are within valid ranges
    if (hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59) {
      return true;
    }
  }

  return false;
};
